/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const gateway = $root.gateway = (() => {

    /**
     * Namespace gateway.
     * @exports gateway
     * @namespace
     */
    const gateway = {};

    /**
     * Action enum.
     * @name gateway.Action
     * @enum {number}
     * @property {number} ActionForward=0 ActionForward value
     * @property {number} ActionSCHi=1 ActionSCHi value
     * @property {number} ActionCSSendMsg=2 ActionCSSendMsg value
     * @property {number} ActionSCSendMsgACK=3 ActionSCSendMsgACK value
     * @property {number} ActionSCRecvMsg=4 ActionSCRecvMsg value
     * @property {number} ActionCSRecvMsgACK=5 ActionCSRecvMsgACK value
     * @property {number} ActionCSReadMsg=6 ActionCSReadMsg value
     * @property {number} ActionSCReadMsg=7 ActionSCReadMsg value
     * @property {number} ActionCSDeleteMsg=8 ActionCSDeleteMsg value
     * @property {number} ActionSCDeleteMsgACK=9 ActionSCDeleteMsgACK value
     * @property {number} ActionSCDeleteMsg=10 ActionSCDeleteMsg value
     * @property {number} ActionCSEditMsg=11 ActionCSEditMsg value
     * @property {number} ActionSCEditMsgACK=12 ActionSCEditMsgACK value
     * @property {number} ActionSCEditMsg=13 ActionSCEditMsg value
     * @property {number} ActionInputtingBegin=14 ActionInputtingBegin value
     * @property {number} ActionInputtingEnd=15 ActionInputtingEnd value
     * @property {number} ActionSCChatChanged=16 ActionSCChatChanged value
     * @property {number} ActionSCWorkerChanged=17 ActionSCWorkerChanged value
     * @property {number} ActionSCUserConnectionChanged=18 ActionSCUserConnectionChanged value
     * @property {number} ActionSCSimSend=19 ActionSCSimSend value
     */
    gateway.Action = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ActionForward"] = 0;
        values[valuesById[1] = "ActionSCHi"] = 1;
        values[valuesById[2] = "ActionCSSendMsg"] = 2;
        values[valuesById[3] = "ActionSCSendMsgACK"] = 3;
        values[valuesById[4] = "ActionSCRecvMsg"] = 4;
        values[valuesById[5] = "ActionCSRecvMsgACK"] = 5;
        values[valuesById[6] = "ActionCSReadMsg"] = 6;
        values[valuesById[7] = "ActionSCReadMsg"] = 7;
        values[valuesById[8] = "ActionCSDeleteMsg"] = 8;
        values[valuesById[9] = "ActionSCDeleteMsgACK"] = 9;
        values[valuesById[10] = "ActionSCDeleteMsg"] = 10;
        values[valuesById[11] = "ActionCSEditMsg"] = 11;
        values[valuesById[12] = "ActionSCEditMsgACK"] = 12;
        values[valuesById[13] = "ActionSCEditMsg"] = 13;
        values[valuesById[14] = "ActionInputtingBegin"] = 14;
        values[valuesById[15] = "ActionInputtingEnd"] = 15;
        values[valuesById[16] = "ActionSCChatChanged"] = 16;
        values[valuesById[17] = "ActionSCWorkerChanged"] = 17;
        values[valuesById[18] = "ActionSCUserConnectionChanged"] = 18;
        values[valuesById[19] = "ActionSCSimSend"] = 19;
        return values;
    })();

    gateway.SCHi = (function() {

        /**
         * Properties of a SCHi.
         * @memberof gateway
         * @interface ISCHi
         * @property {Long|null} [id] SCHi id
         * @property {string|null} [token] SCHi token
         * @property {number|null} [workerId] SCHi workerId
         */

        /**
         * Constructs a new SCHi.
         * @memberof gateway
         * @classdesc Represents a SCHi.
         * @implements ISCHi
         * @constructor
         * @param {gateway.ISCHi=} [properties] Properties to set
         */
        function SCHi(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCHi id.
         * @member {Long} id
         * @memberof gateway.SCHi
         * @instance
         */
        SCHi.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCHi token.
         * @member {string} token
         * @memberof gateway.SCHi
         * @instance
         */
        SCHi.prototype.token = "";

        /**
         * SCHi workerId.
         * @member {number} workerId
         * @memberof gateway.SCHi
         * @instance
         */
        SCHi.prototype.workerId = 0;

        /**
         * Creates a new SCHi instance using the specified properties.
         * @function create
         * @memberof gateway.SCHi
         * @static
         * @param {gateway.ISCHi=} [properties] Properties to set
         * @returns {gateway.SCHi} SCHi instance
         */
        SCHi.create = function create(properties) {
            return new SCHi(properties);
        };

        /**
         * Encodes the specified SCHi message. Does not implicitly {@link gateway.SCHi.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCHi
         * @static
         * @param {gateway.ISCHi} message SCHi message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCHi.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.workerId);
            return writer;
        };

        /**
         * Encodes the specified SCHi message, length delimited. Does not implicitly {@link gateway.SCHi.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCHi
         * @static
         * @param {gateway.ISCHi} message SCHi message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCHi.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCHi message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCHi
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCHi} SCHi
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCHi.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCHi();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int64();
                        break;
                    }
                case 2: {
                        message.token = reader.string();
                        break;
                    }
                case 3: {
                        message.workerId = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCHi message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCHi
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCHi} SCHi
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCHi.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCHi message.
         * @function verify
         * @memberof gateway.SCHi
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCHi.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.workerId != null && message.hasOwnProperty("workerId"))
                if (!$util.isInteger(message.workerId))
                    return "workerId: integer expected";
            return null;
        };

        /**
         * Creates a SCHi message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCHi
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCHi} SCHi
         */
        SCHi.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCHi)
                return object;
            let message = new $root.gateway.SCHi();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
            if (object.token != null)
                message.token = String(object.token);
            if (object.workerId != null)
                message.workerId = object.workerId | 0;
            return message;
        };

        /**
         * Creates a plain object from a SCHi message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCHi
         * @static
         * @param {gateway.SCHi} message SCHi
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCHi.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.token = "";
                object.workerId = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.workerId != null && message.hasOwnProperty("workerId"))
                object.workerId = message.workerId;
            return object;
        };

        /**
         * Converts this SCHi to JSON.
         * @function toJSON
         * @memberof gateway.SCHi
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCHi.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCHi
         * @function getTypeUrl
         * @memberof gateway.SCHi
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCHi.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCHi";
        };

        return SCHi;
    })();

    gateway.CSForward = (function() {

        /**
         * Properties of a CSForward.
         * @memberof gateway
         * @interface ICSForward
         * @property {string|null} [appId] CSForward appId
         * @property {string|null} [url] CSForward url
         * @property {string|null} [verb] CSForward verb
         * @property {Uint8Array|null} [data] CSForward data
         */

        /**
         * Constructs a new CSForward.
         * @memberof gateway
         * @classdesc Represents a CSForward.
         * @implements ICSForward
         * @constructor
         * @param {gateway.ICSForward=} [properties] Properties to set
         */
        function CSForward(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CSForward appId.
         * @member {string} appId
         * @memberof gateway.CSForward
         * @instance
         */
        CSForward.prototype.appId = "";

        /**
         * CSForward url.
         * @member {string} url
         * @memberof gateway.CSForward
         * @instance
         */
        CSForward.prototype.url = "";

        /**
         * CSForward verb.
         * @member {string} verb
         * @memberof gateway.CSForward
         * @instance
         */
        CSForward.prototype.verb = "";

        /**
         * CSForward data.
         * @member {Uint8Array} data
         * @memberof gateway.CSForward
         * @instance
         */
        CSForward.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new CSForward instance using the specified properties.
         * @function create
         * @memberof gateway.CSForward
         * @static
         * @param {gateway.ICSForward=} [properties] Properties to set
         * @returns {gateway.CSForward} CSForward instance
         */
        CSForward.create = function create(properties) {
            return new CSForward(properties);
        };

        /**
         * Encodes the specified CSForward message. Does not implicitly {@link gateway.CSForward.verify|verify} messages.
         * @function encode
         * @memberof gateway.CSForward
         * @static
         * @param {gateway.ICSForward} message CSForward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CSForward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.appId);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.verb != null && Object.hasOwnProperty.call(message, "verb"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.verb);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified CSForward message, length delimited. Does not implicitly {@link gateway.CSForward.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.CSForward
         * @static
         * @param {gateway.ICSForward} message CSForward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CSForward.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CSForward message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.CSForward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.CSForward} CSForward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CSForward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.CSForward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.appId = reader.string();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                case 3: {
                        message.verb = reader.string();
                        break;
                    }
                case 4: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CSForward message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.CSForward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.CSForward} CSForward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CSForward.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CSForward message.
         * @function verify
         * @memberof gateway.CSForward
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CSForward.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.appId != null && message.hasOwnProperty("appId"))
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.verb != null && message.hasOwnProperty("verb"))
                if (!$util.isString(message.verb))
                    return "verb: string expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a CSForward message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.CSForward
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.CSForward} CSForward
         */
        CSForward.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.CSForward)
                return object;
            let message = new $root.gateway.CSForward();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.url != null)
                message.url = String(object.url);
            if (object.verb != null)
                message.verb = String(object.verb);
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a CSForward message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.CSForward
         * @static
         * @param {gateway.CSForward} message CSForward
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CSForward.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.appId = "";
                object.url = "";
                object.verb = "";
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.appId != null && message.hasOwnProperty("appId"))
                object.appId = message.appId;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.verb != null && message.hasOwnProperty("verb"))
                object.verb = message.verb;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this CSForward to JSON.
         * @function toJSON
         * @memberof gateway.CSForward
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CSForward.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CSForward
         * @function getTypeUrl
         * @memberof gateway.CSForward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CSForward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.CSForward";
        };

        return CSForward;
    })();

    gateway.SCForward = (function() {

        /**
         * Properties of a SCForward.
         * @memberof gateway
         * @interface ISCForward
         * @property {string|null} [err] SCForward err
         * @property {Uint8Array|null} [data] SCForward data
         */

        /**
         * Constructs a new SCForward.
         * @memberof gateway
         * @classdesc Represents a SCForward.
         * @implements ISCForward
         * @constructor
         * @param {gateway.ISCForward=} [properties] Properties to set
         */
        function SCForward(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCForward err.
         * @member {string} err
         * @memberof gateway.SCForward
         * @instance
         */
        SCForward.prototype.err = "";

        /**
         * SCForward data.
         * @member {Uint8Array} data
         * @memberof gateway.SCForward
         * @instance
         */
        SCForward.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new SCForward instance using the specified properties.
         * @function create
         * @memberof gateway.SCForward
         * @static
         * @param {gateway.ISCForward=} [properties] Properties to set
         * @returns {gateway.SCForward} SCForward instance
         */
        SCForward.create = function create(properties) {
            return new SCForward(properties);
        };

        /**
         * Encodes the specified SCForward message. Does not implicitly {@link gateway.SCForward.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCForward
         * @static
         * @param {gateway.ISCForward} message SCForward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCForward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.err != null && Object.hasOwnProperty.call(message, "err"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.err);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified SCForward message, length delimited. Does not implicitly {@link gateway.SCForward.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCForward
         * @static
         * @param {gateway.ISCForward} message SCForward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCForward.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCForward message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCForward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCForward} SCForward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCForward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCForward();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.err = reader.string();
                        break;
                    }
                case 3: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCForward message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCForward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCForward} SCForward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCForward.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCForward message.
         * @function verify
         * @memberof gateway.SCForward
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCForward.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.err != null && message.hasOwnProperty("err"))
                if (!$util.isString(message.err))
                    return "err: string expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a SCForward message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCForward
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCForward} SCForward
         */
        SCForward.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCForward)
                return object;
            let message = new $root.gateway.SCForward();
            if (object.err != null)
                message.err = String(object.err);
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a SCForward message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCForward
         * @static
         * @param {gateway.SCForward} message SCForward
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCForward.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.err = "";
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.err != null && message.hasOwnProperty("err"))
                object.err = message.err;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this SCForward to JSON.
         * @function toJSON
         * @memberof gateway.SCForward
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCForward.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCForward
         * @function getTypeUrl
         * @memberof gateway.SCForward
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCForward.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCForward";
        };

        return SCForward;
    })();

    gateway.CSSendMessage = (function() {

        /**
         * Properties of a CSSendMessage.
         * @memberof gateway
         * @interface ICSSendMessage
         * @property {api.common.IMessage|null} [msg] CSSendMessage msg
         */

        /**
         * Constructs a new CSSendMessage.
         * @memberof gateway
         * @classdesc Represents a CSSendMessage.
         * @implements ICSSendMessage
         * @constructor
         * @param {gateway.ICSSendMessage=} [properties] Properties to set
         */
        function CSSendMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CSSendMessage msg.
         * @member {api.common.IMessage|null|undefined} msg
         * @memberof gateway.CSSendMessage
         * @instance
         */
        CSSendMessage.prototype.msg = null;

        /**
         * Creates a new CSSendMessage instance using the specified properties.
         * @function create
         * @memberof gateway.CSSendMessage
         * @static
         * @param {gateway.ICSSendMessage=} [properties] Properties to set
         * @returns {gateway.CSSendMessage} CSSendMessage instance
         */
        CSSendMessage.create = function create(properties) {
            return new CSSendMessage(properties);
        };

        /**
         * Encodes the specified CSSendMessage message. Does not implicitly {@link gateway.CSSendMessage.verify|verify} messages.
         * @function encode
         * @memberof gateway.CSSendMessage
         * @static
         * @param {gateway.ICSSendMessage} message CSSendMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CSSendMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                $root.api.common.Message.encode(message.msg, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CSSendMessage message, length delimited. Does not implicitly {@link gateway.CSSendMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.CSSendMessage
         * @static
         * @param {gateway.ICSSendMessage} message CSSendMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CSSendMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CSSendMessage message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.CSSendMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.CSSendMessage} CSSendMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CSSendMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.CSSendMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msg = $root.api.common.Message.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CSSendMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.CSSendMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.CSSendMessage} CSSendMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CSSendMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CSSendMessage message.
         * @function verify
         * @memberof gateway.CSSendMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CSSendMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msg != null && message.hasOwnProperty("msg")) {
                let error = $root.api.common.Message.verify(message.msg);
                if (error)
                    return "msg." + error;
            }
            return null;
        };

        /**
         * Creates a CSSendMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.CSSendMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.CSSendMessage} CSSendMessage
         */
        CSSendMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.CSSendMessage)
                return object;
            let message = new $root.gateway.CSSendMessage();
            if (object.msg != null) {
                if (typeof object.msg !== "object")
                    throw TypeError(".gateway.CSSendMessage.msg: object expected");
                message.msg = $root.api.common.Message.fromObject(object.msg);
            }
            return message;
        };

        /**
         * Creates a plain object from a CSSendMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.CSSendMessage
         * @static
         * @param {gateway.CSSendMessage} message CSSendMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CSSendMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msg = null;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = $root.api.common.Message.toObject(message.msg, options);
            return object;
        };

        /**
         * Converts this CSSendMessage to JSON.
         * @function toJSON
         * @memberof gateway.CSSendMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CSSendMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CSSendMessage
         * @function getTypeUrl
         * @memberof gateway.CSSendMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CSSendMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.CSSendMessage";
        };

        return CSSendMessage;
    })();

    gateway.SCSendMessage = (function() {

        /**
         * Properties of a SCSendMessage.
         * @memberof gateway
         * @interface ISCSendMessage
         * @property {Long|null} [chatId] SCSendMessage chatId
         * @property {Long|null} [msgId] SCSendMessage msgId
         * @property {google.protobuf.ITimestamp|null} [msgTime] SCSendMessage msgTime
         * @property {string|null} [errMsg] SCSendMessage errMsg
         */

        /**
         * Constructs a new SCSendMessage.
         * @memberof gateway
         * @classdesc Represents a SCSendMessage.
         * @implements ISCSendMessage
         * @constructor
         * @param {gateway.ISCSendMessage=} [properties] Properties to set
         */
        function SCSendMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCSendMessage chatId.
         * @member {Long} chatId
         * @memberof gateway.SCSendMessage
         * @instance
         */
        SCSendMessage.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCSendMessage msgId.
         * @member {Long} msgId
         * @memberof gateway.SCSendMessage
         * @instance
         */
        SCSendMessage.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCSendMessage msgTime.
         * @member {google.protobuf.ITimestamp|null|undefined} msgTime
         * @memberof gateway.SCSendMessage
         * @instance
         */
        SCSendMessage.prototype.msgTime = null;

        /**
         * SCSendMessage errMsg.
         * @member {string} errMsg
         * @memberof gateway.SCSendMessage
         * @instance
         */
        SCSendMessage.prototype.errMsg = "";

        /**
         * Creates a new SCSendMessage instance using the specified properties.
         * @function create
         * @memberof gateway.SCSendMessage
         * @static
         * @param {gateway.ISCSendMessage=} [properties] Properties to set
         * @returns {gateway.SCSendMessage} SCSendMessage instance
         */
        SCSendMessage.create = function create(properties) {
            return new SCSendMessage(properties);
        };

        /**
         * Encodes the specified SCSendMessage message. Does not implicitly {@link gateway.SCSendMessage.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCSendMessage
         * @static
         * @param {gateway.ISCSendMessage} message SCSendMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCSendMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
            if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.msgId);
            if (message.msgTime != null && Object.hasOwnProperty.call(message, "msgTime"))
                $root.google.protobuf.Timestamp.encode(message.msgTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.errMsg != null && Object.hasOwnProperty.call(message, "errMsg"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.errMsg);
            return writer;
        };

        /**
         * Encodes the specified SCSendMessage message, length delimited. Does not implicitly {@link gateway.SCSendMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCSendMessage
         * @static
         * @param {gateway.ISCSendMessage} message SCSendMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCSendMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCSendMessage message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCSendMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCSendMessage} SCSendMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCSendMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCSendMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chatId = reader.int64();
                        break;
                    }
                case 2: {
                        message.msgId = reader.int64();
                        break;
                    }
                case 3: {
                        message.msgTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.errMsg = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCSendMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCSendMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCSendMessage} SCSendMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCSendMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCSendMessage message.
         * @function verify
         * @memberof gateway.SCSendMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCSendMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                    return "chatId: integer|Long expected";
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                    return "msgId: integer|Long expected";
            if (message.msgTime != null && message.hasOwnProperty("msgTime")) {
                let error = $root.google.protobuf.Timestamp.verify(message.msgTime);
                if (error)
                    return "msgTime." + error;
            }
            if (message.errMsg != null && message.hasOwnProperty("errMsg"))
                if (!$util.isString(message.errMsg))
                    return "errMsg: string expected";
            return null;
        };

        /**
         * Creates a SCSendMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCSendMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCSendMessage} SCSendMessage
         */
        SCSendMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCSendMessage)
                return object;
            let message = new $root.gateway.SCSendMessage();
            if (object.chatId != null)
                if ($util.Long)
                    (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                else if (typeof object.chatId === "string")
                    message.chatId = parseInt(object.chatId, 10);
                else if (typeof object.chatId === "number")
                    message.chatId = object.chatId;
                else if (typeof object.chatId === "object")
                    message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
            if (object.msgId != null)
                if ($util.Long)
                    (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                else if (typeof object.msgId === "string")
                    message.msgId = parseInt(object.msgId, 10);
                else if (typeof object.msgId === "number")
                    message.msgId = object.msgId;
                else if (typeof object.msgId === "object")
                    message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
            if (object.msgTime != null) {
                if (typeof object.msgTime !== "object")
                    throw TypeError(".gateway.SCSendMessage.msgTime: object expected");
                message.msgTime = $root.google.protobuf.Timestamp.fromObject(object.msgTime);
            }
            if (object.errMsg != null)
                message.errMsg = String(object.errMsg);
            return message;
        };

        /**
         * Creates a plain object from a SCSendMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCSendMessage
         * @static
         * @param {gateway.SCSendMessage} message SCSendMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCSendMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chatId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.msgId = options.longs === String ? "0" : 0;
                object.msgTime = null;
                object.errMsg = "";
            }
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (typeof message.chatId === "number")
                    object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                else
                    object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (typeof message.msgId === "number")
                    object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                else
                    object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
            if (message.msgTime != null && message.hasOwnProperty("msgTime"))
                object.msgTime = $root.google.protobuf.Timestamp.toObject(message.msgTime, options);
            if (message.errMsg != null && message.hasOwnProperty("errMsg"))
                object.errMsg = message.errMsg;
            return object;
        };

        /**
         * Converts this SCSendMessage to JSON.
         * @function toJSON
         * @memberof gateway.SCSendMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCSendMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCSendMessage
         * @function getTypeUrl
         * @memberof gateway.SCSendMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCSendMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCSendMessage";
        };

        return SCSendMessage;
    })();

    gateway.SCRecvMessage = (function() {

        /**
         * Properties of a SCRecvMessage.
         * @memberof gateway
         * @interface ISCRecvMessage
         * @property {api.common.IMessage|null} [msg] SCRecvMessage msg
         * @property {Long|null} [target] SCRecvMessage target
         */

        /**
         * Constructs a new SCRecvMessage.
         * @memberof gateway
         * @classdesc Represents a SCRecvMessage.
         * @implements ISCRecvMessage
         * @constructor
         * @param {gateway.ISCRecvMessage=} [properties] Properties to set
         */
        function SCRecvMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCRecvMessage msg.
         * @member {api.common.IMessage|null|undefined} msg
         * @memberof gateway.SCRecvMessage
         * @instance
         */
        SCRecvMessage.prototype.msg = null;

        /**
         * SCRecvMessage target.
         * @member {Long} target
         * @memberof gateway.SCRecvMessage
         * @instance
         */
        SCRecvMessage.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SCRecvMessage instance using the specified properties.
         * @function create
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {gateway.ISCRecvMessage=} [properties] Properties to set
         * @returns {gateway.SCRecvMessage} SCRecvMessage instance
         */
        SCRecvMessage.create = function create(properties) {
            return new SCRecvMessage(properties);
        };

        /**
         * Encodes the specified SCRecvMessage message. Does not implicitly {@link gateway.SCRecvMessage.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {gateway.ISCRecvMessage} message SCRecvMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCRecvMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                $root.api.common.Message.encode(message.msg, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.target);
            return writer;
        };

        /**
         * Encodes the specified SCRecvMessage message, length delimited. Does not implicitly {@link gateway.SCRecvMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {gateway.ISCRecvMessage} message SCRecvMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCRecvMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCRecvMessage message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCRecvMessage} SCRecvMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCRecvMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCRecvMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msg = $root.api.common.Message.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.target = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCRecvMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCRecvMessage} SCRecvMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCRecvMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCRecvMessage message.
         * @function verify
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCRecvMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msg != null && message.hasOwnProperty("msg")) {
                let error = $root.api.common.Message.verify(message.msg);
                if (error)
                    return "msg." + error;
            }
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            return null;
        };

        /**
         * Creates a SCRecvMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCRecvMessage} SCRecvMessage
         */
        SCRecvMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCRecvMessage)
                return object;
            let message = new $root.gateway.SCRecvMessage();
            if (object.msg != null) {
                if (typeof object.msg !== "object")
                    throw TypeError(".gateway.SCRecvMessage.msg: object expected");
                message.msg = $root.api.common.Message.fromObject(object.msg);
            }
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SCRecvMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {gateway.SCRecvMessage} message SCRecvMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCRecvMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.msg = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
            }
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = $root.api.common.Message.toObject(message.msg, options);
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            return object;
        };

        /**
         * Converts this SCRecvMessage to JSON.
         * @function toJSON
         * @memberof gateway.SCRecvMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCRecvMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCRecvMessage
         * @function getTypeUrl
         * @memberof gateway.SCRecvMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCRecvMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCRecvMessage";
        };

        return SCRecvMessage;
    })();

    gateway.CSRecvMessage = (function() {

        /**
         * Properties of a CSRecvMessage.
         * @memberof gateway
         * @interface ICSRecvMessage
         * @property {Long|null} [chatId] CSRecvMessage chatId
         * @property {Long|null} [msgId] CSRecvMessage msgId
         */

        /**
         * Constructs a new CSRecvMessage.
         * @memberof gateway
         * @classdesc Represents a CSRecvMessage.
         * @implements ICSRecvMessage
         * @constructor
         * @param {gateway.ICSRecvMessage=} [properties] Properties to set
         */
        function CSRecvMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CSRecvMessage chatId.
         * @member {Long} chatId
         * @memberof gateway.CSRecvMessage
         * @instance
         */
        CSRecvMessage.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CSRecvMessage msgId.
         * @member {Long} msgId
         * @memberof gateway.CSRecvMessage
         * @instance
         */
        CSRecvMessage.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CSRecvMessage instance using the specified properties.
         * @function create
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {gateway.ICSRecvMessage=} [properties] Properties to set
         * @returns {gateway.CSRecvMessage} CSRecvMessage instance
         */
        CSRecvMessage.create = function create(properties) {
            return new CSRecvMessage(properties);
        };

        /**
         * Encodes the specified CSRecvMessage message. Does not implicitly {@link gateway.CSRecvMessage.verify|verify} messages.
         * @function encode
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {gateway.ICSRecvMessage} message CSRecvMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CSRecvMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
            if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.msgId);
            return writer;
        };

        /**
         * Encodes the specified CSRecvMessage message, length delimited. Does not implicitly {@link gateway.CSRecvMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {gateway.ICSRecvMessage} message CSRecvMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CSRecvMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CSRecvMessage message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.CSRecvMessage} CSRecvMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CSRecvMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.CSRecvMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chatId = reader.int64();
                        break;
                    }
                case 2: {
                        message.msgId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CSRecvMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.CSRecvMessage} CSRecvMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CSRecvMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CSRecvMessage message.
         * @function verify
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CSRecvMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                    return "chatId: integer|Long expected";
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                    return "msgId: integer|Long expected";
            return null;
        };

        /**
         * Creates a CSRecvMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.CSRecvMessage} CSRecvMessage
         */
        CSRecvMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.CSRecvMessage)
                return object;
            let message = new $root.gateway.CSRecvMessage();
            if (object.chatId != null)
                if ($util.Long)
                    (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                else if (typeof object.chatId === "string")
                    message.chatId = parseInt(object.chatId, 10);
                else if (typeof object.chatId === "number")
                    message.chatId = object.chatId;
                else if (typeof object.chatId === "object")
                    message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
            if (object.msgId != null)
                if ($util.Long)
                    (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                else if (typeof object.msgId === "string")
                    message.msgId = parseInt(object.msgId, 10);
                else if (typeof object.msgId === "number")
                    message.msgId = object.msgId;
                else if (typeof object.msgId === "object")
                    message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CSRecvMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {gateway.CSRecvMessage} message CSRecvMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CSRecvMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chatId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.msgId = options.longs === String ? "0" : 0;
            }
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (typeof message.chatId === "number")
                    object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                else
                    object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (typeof message.msgId === "number")
                    object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                else
                    object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
            return object;
        };

        /**
         * Converts this CSRecvMessage to JSON.
         * @function toJSON
         * @memberof gateway.CSRecvMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CSRecvMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CSRecvMessage
         * @function getTypeUrl
         * @memberof gateway.CSRecvMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CSRecvMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.CSRecvMessage";
        };

        return CSRecvMessage;
    })();

    gateway.CSReadMessage = (function() {

        /**
         * Properties of a CSReadMessage.
         * @memberof gateway
         * @interface ICSReadMessage
         * @property {Long|null} [chatId] CSReadMessage chatId
         * @property {Long|null} [msgId] CSReadMessage msgId
         */

        /**
         * Constructs a new CSReadMessage.
         * @memberof gateway
         * @classdesc Represents a CSReadMessage.
         * @implements ICSReadMessage
         * @constructor
         * @param {gateway.ICSReadMessage=} [properties] Properties to set
         */
        function CSReadMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CSReadMessage chatId.
         * @member {Long} chatId
         * @memberof gateway.CSReadMessage
         * @instance
         */
        CSReadMessage.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CSReadMessage msgId.
         * @member {Long} msgId
         * @memberof gateway.CSReadMessage
         * @instance
         */
        CSReadMessage.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CSReadMessage instance using the specified properties.
         * @function create
         * @memberof gateway.CSReadMessage
         * @static
         * @param {gateway.ICSReadMessage=} [properties] Properties to set
         * @returns {gateway.CSReadMessage} CSReadMessage instance
         */
        CSReadMessage.create = function create(properties) {
            return new CSReadMessage(properties);
        };

        /**
         * Encodes the specified CSReadMessage message. Does not implicitly {@link gateway.CSReadMessage.verify|verify} messages.
         * @function encode
         * @memberof gateway.CSReadMessage
         * @static
         * @param {gateway.ICSReadMessage} message CSReadMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CSReadMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
            if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.msgId);
            return writer;
        };

        /**
         * Encodes the specified CSReadMessage message, length delimited. Does not implicitly {@link gateway.CSReadMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.CSReadMessage
         * @static
         * @param {gateway.ICSReadMessage} message CSReadMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CSReadMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CSReadMessage message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.CSReadMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.CSReadMessage} CSReadMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CSReadMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.CSReadMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chatId = reader.int64();
                        break;
                    }
                case 2: {
                        message.msgId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CSReadMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.CSReadMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.CSReadMessage} CSReadMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CSReadMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CSReadMessage message.
         * @function verify
         * @memberof gateway.CSReadMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CSReadMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                    return "chatId: integer|Long expected";
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                    return "msgId: integer|Long expected";
            return null;
        };

        /**
         * Creates a CSReadMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.CSReadMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.CSReadMessage} CSReadMessage
         */
        CSReadMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.CSReadMessage)
                return object;
            let message = new $root.gateway.CSReadMessage();
            if (object.chatId != null)
                if ($util.Long)
                    (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                else if (typeof object.chatId === "string")
                    message.chatId = parseInt(object.chatId, 10);
                else if (typeof object.chatId === "number")
                    message.chatId = object.chatId;
                else if (typeof object.chatId === "object")
                    message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
            if (object.msgId != null)
                if ($util.Long)
                    (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                else if (typeof object.msgId === "string")
                    message.msgId = parseInt(object.msgId, 10);
                else if (typeof object.msgId === "number")
                    message.msgId = object.msgId;
                else if (typeof object.msgId === "object")
                    message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CSReadMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.CSReadMessage
         * @static
         * @param {gateway.CSReadMessage} message CSReadMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CSReadMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chatId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.msgId = options.longs === String ? "0" : 0;
            }
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (typeof message.chatId === "number")
                    object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                else
                    object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (typeof message.msgId === "number")
                    object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                else
                    object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
            return object;
        };

        /**
         * Converts this CSReadMessage to JSON.
         * @function toJSON
         * @memberof gateway.CSReadMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CSReadMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CSReadMessage
         * @function getTypeUrl
         * @memberof gateway.CSReadMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CSReadMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.CSReadMessage";
        };

        return CSReadMessage;
    })();

    gateway.SCReadMessage = (function() {

        /**
         * Properties of a SCReadMessage.
         * @memberof gateway
         * @interface ISCReadMessage
         * @property {Long|null} [chatId] SCReadMessage chatId
         * @property {Long|null} [msgId] SCReadMessage msgId
         */

        /**
         * Constructs a new SCReadMessage.
         * @memberof gateway
         * @classdesc Represents a SCReadMessage.
         * @implements ISCReadMessage
         * @constructor
         * @param {gateway.ISCReadMessage=} [properties] Properties to set
         */
        function SCReadMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCReadMessage chatId.
         * @member {Long} chatId
         * @memberof gateway.SCReadMessage
         * @instance
         */
        SCReadMessage.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCReadMessage msgId.
         * @member {Long} msgId
         * @memberof gateway.SCReadMessage
         * @instance
         */
        SCReadMessage.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SCReadMessage instance using the specified properties.
         * @function create
         * @memberof gateway.SCReadMessage
         * @static
         * @param {gateway.ISCReadMessage=} [properties] Properties to set
         * @returns {gateway.SCReadMessage} SCReadMessage instance
         */
        SCReadMessage.create = function create(properties) {
            return new SCReadMessage(properties);
        };

        /**
         * Encodes the specified SCReadMessage message. Does not implicitly {@link gateway.SCReadMessage.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCReadMessage
         * @static
         * @param {gateway.ISCReadMessage} message SCReadMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCReadMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
            if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.msgId);
            return writer;
        };

        /**
         * Encodes the specified SCReadMessage message, length delimited. Does not implicitly {@link gateway.SCReadMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCReadMessage
         * @static
         * @param {gateway.ISCReadMessage} message SCReadMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCReadMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCReadMessage message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCReadMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCReadMessage} SCReadMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCReadMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCReadMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chatId = reader.int64();
                        break;
                    }
                case 2: {
                        message.msgId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCReadMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCReadMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCReadMessage} SCReadMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCReadMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCReadMessage message.
         * @function verify
         * @memberof gateway.SCReadMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCReadMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                    return "chatId: integer|Long expected";
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                    return "msgId: integer|Long expected";
            return null;
        };

        /**
         * Creates a SCReadMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCReadMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCReadMessage} SCReadMessage
         */
        SCReadMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCReadMessage)
                return object;
            let message = new $root.gateway.SCReadMessage();
            if (object.chatId != null)
                if ($util.Long)
                    (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                else if (typeof object.chatId === "string")
                    message.chatId = parseInt(object.chatId, 10);
                else if (typeof object.chatId === "number")
                    message.chatId = object.chatId;
                else if (typeof object.chatId === "object")
                    message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
            if (object.msgId != null)
                if ($util.Long)
                    (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                else if (typeof object.msgId === "string")
                    message.msgId = parseInt(object.msgId, 10);
                else if (typeof object.msgId === "number")
                    message.msgId = object.msgId;
                else if (typeof object.msgId === "object")
                    message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SCReadMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCReadMessage
         * @static
         * @param {gateway.SCReadMessage} message SCReadMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCReadMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chatId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.msgId = options.longs === String ? "0" : 0;
            }
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (typeof message.chatId === "number")
                    object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                else
                    object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (typeof message.msgId === "number")
                    object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                else
                    object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
            return object;
        };

        /**
         * Converts this SCReadMessage to JSON.
         * @function toJSON
         * @memberof gateway.SCReadMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCReadMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCReadMessage
         * @function getTypeUrl
         * @memberof gateway.SCReadMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCReadMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCReadMessage";
        };

        return SCReadMessage;
    })();

    gateway.InputtingBegin = (function() {

        /**
         * Properties of an InputtingBegin.
         * @memberof gateway
         * @interface IInputtingBegin
         * @property {Long|null} [self] InputtingBegin self
         * @property {Long|null} [target] InputtingBegin target
         * @property {api.common.MessageFormat|null} [msgFmt] InputtingBegin msgFmt
         */

        /**
         * Constructs a new InputtingBegin.
         * @memberof gateway
         * @classdesc Represents an InputtingBegin.
         * @implements IInputtingBegin
         * @constructor
         * @param {gateway.IInputtingBegin=} [properties] Properties to set
         */
        function InputtingBegin(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InputtingBegin self.
         * @member {Long} self
         * @memberof gateway.InputtingBegin
         * @instance
         */
        InputtingBegin.prototype.self = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InputtingBegin target.
         * @member {Long} target
         * @memberof gateway.InputtingBegin
         * @instance
         */
        InputtingBegin.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InputtingBegin msgFmt.
         * @member {api.common.MessageFormat} msgFmt
         * @memberof gateway.InputtingBegin
         * @instance
         */
        InputtingBegin.prototype.msgFmt = 0;

        /**
         * Creates a new InputtingBegin instance using the specified properties.
         * @function create
         * @memberof gateway.InputtingBegin
         * @static
         * @param {gateway.IInputtingBegin=} [properties] Properties to set
         * @returns {gateway.InputtingBegin} InputtingBegin instance
         */
        InputtingBegin.create = function create(properties) {
            return new InputtingBegin(properties);
        };

        /**
         * Encodes the specified InputtingBegin message. Does not implicitly {@link gateway.InputtingBegin.verify|verify} messages.
         * @function encode
         * @memberof gateway.InputtingBegin
         * @static
         * @param {gateway.IInputtingBegin} message InputtingBegin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputtingBegin.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.self != null && Object.hasOwnProperty.call(message, "self"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.self);
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.target);
            if (message.msgFmt != null && Object.hasOwnProperty.call(message, "msgFmt"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.msgFmt);
            return writer;
        };

        /**
         * Encodes the specified InputtingBegin message, length delimited. Does not implicitly {@link gateway.InputtingBegin.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.InputtingBegin
         * @static
         * @param {gateway.IInputtingBegin} message InputtingBegin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputtingBegin.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InputtingBegin message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.InputtingBegin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.InputtingBegin} InputtingBegin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputtingBegin.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.InputtingBegin();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.self = reader.int64();
                        break;
                    }
                case 2: {
                        message.target = reader.int64();
                        break;
                    }
                case 3: {
                        message.msgFmt = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InputtingBegin message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.InputtingBegin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.InputtingBegin} InputtingBegin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputtingBegin.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InputtingBegin message.
         * @function verify
         * @memberof gateway.InputtingBegin
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InputtingBegin.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.self != null && message.hasOwnProperty("self"))
                if (!$util.isInteger(message.self) && !(message.self && $util.isInteger(message.self.low) && $util.isInteger(message.self.high)))
                    return "self: integer|Long expected";
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            if (message.msgFmt != null && message.hasOwnProperty("msgFmt"))
                switch (message.msgFmt) {
                default:
                    return "msgFmt: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 6:
                    break;
                }
            return null;
        };

        /**
         * Creates an InputtingBegin message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.InputtingBegin
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.InputtingBegin} InputtingBegin
         */
        InputtingBegin.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.InputtingBegin)
                return object;
            let message = new $root.gateway.InputtingBegin();
            if (object.self != null)
                if ($util.Long)
                    (message.self = $util.Long.fromValue(object.self)).unsigned = false;
                else if (typeof object.self === "string")
                    message.self = parseInt(object.self, 10);
                else if (typeof object.self === "number")
                    message.self = object.self;
                else if (typeof object.self === "object")
                    message.self = new $util.LongBits(object.self.low >>> 0, object.self.high >>> 0).toNumber();
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            switch (object.msgFmt) {
            default:
                if (typeof object.msgFmt === "number") {
                    message.msgFmt = object.msgFmt;
                    break;
                }
                break;
            case "MSG_TEXT":
            case 0:
                message.msgFmt = 0;
                break;
            case "MSG_IMG":
            case 1:
                message.msgFmt = 1;
                break;
            case "MSG_VOICE":
            case 2:
                message.msgFmt = 2;
                break;
            case "MSG_VIDEO":
            case 3:
                message.msgFmt = 3;
                break;
            case "MSG_GEO":
            case 4:
                message.msgFmt = 4;
                break;
            case "MSG_FILE":
            case 6:
                message.msgFmt = 6;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an InputtingBegin message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.InputtingBegin
         * @static
         * @param {gateway.InputtingBegin} message InputtingBegin
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InputtingBegin.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.self = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.self = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
                object.msgFmt = options.enums === String ? "MSG_TEXT" : 0;
            }
            if (message.self != null && message.hasOwnProperty("self"))
                if (typeof message.self === "number")
                    object.self = options.longs === String ? String(message.self) : message.self;
                else
                    object.self = options.longs === String ? $util.Long.prototype.toString.call(message.self) : options.longs === Number ? new $util.LongBits(message.self.low >>> 0, message.self.high >>> 0).toNumber() : message.self;
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            if (message.msgFmt != null && message.hasOwnProperty("msgFmt"))
                object.msgFmt = options.enums === String ? $root.api.common.MessageFormat[message.msgFmt] === undefined ? message.msgFmt : $root.api.common.MessageFormat[message.msgFmt] : message.msgFmt;
            return object;
        };

        /**
         * Converts this InputtingBegin to JSON.
         * @function toJSON
         * @memberof gateway.InputtingBegin
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InputtingBegin.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for InputtingBegin
         * @function getTypeUrl
         * @memberof gateway.InputtingBegin
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InputtingBegin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.InputtingBegin";
        };

        return InputtingBegin;
    })();

    gateway.InputtingEnd = (function() {

        /**
         * Properties of an InputtingEnd.
         * @memberof gateway
         * @interface IInputtingEnd
         * @property {Long|null} [self] InputtingEnd self
         * @property {Long|null} [target] InputtingEnd target
         */

        /**
         * Constructs a new InputtingEnd.
         * @memberof gateway
         * @classdesc Represents an InputtingEnd.
         * @implements IInputtingEnd
         * @constructor
         * @param {gateway.IInputtingEnd=} [properties] Properties to set
         */
        function InputtingEnd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InputtingEnd self.
         * @member {Long} self
         * @memberof gateway.InputtingEnd
         * @instance
         */
        InputtingEnd.prototype.self = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InputtingEnd target.
         * @member {Long} target
         * @memberof gateway.InputtingEnd
         * @instance
         */
        InputtingEnd.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new InputtingEnd instance using the specified properties.
         * @function create
         * @memberof gateway.InputtingEnd
         * @static
         * @param {gateway.IInputtingEnd=} [properties] Properties to set
         * @returns {gateway.InputtingEnd} InputtingEnd instance
         */
        InputtingEnd.create = function create(properties) {
            return new InputtingEnd(properties);
        };

        /**
         * Encodes the specified InputtingEnd message. Does not implicitly {@link gateway.InputtingEnd.verify|verify} messages.
         * @function encode
         * @memberof gateway.InputtingEnd
         * @static
         * @param {gateway.IInputtingEnd} message InputtingEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputtingEnd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.self != null && Object.hasOwnProperty.call(message, "self"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.self);
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.target);
            return writer;
        };

        /**
         * Encodes the specified InputtingEnd message, length delimited. Does not implicitly {@link gateway.InputtingEnd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.InputtingEnd
         * @static
         * @param {gateway.IInputtingEnd} message InputtingEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputtingEnd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InputtingEnd message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.InputtingEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.InputtingEnd} InputtingEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputtingEnd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.InputtingEnd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.self = reader.int64();
                        break;
                    }
                case 2: {
                        message.target = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InputtingEnd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.InputtingEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.InputtingEnd} InputtingEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputtingEnd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InputtingEnd message.
         * @function verify
         * @memberof gateway.InputtingEnd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InputtingEnd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.self != null && message.hasOwnProperty("self"))
                if (!$util.isInteger(message.self) && !(message.self && $util.isInteger(message.self.low) && $util.isInteger(message.self.high)))
                    return "self: integer|Long expected";
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            return null;
        };

        /**
         * Creates an InputtingEnd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.InputtingEnd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.InputtingEnd} InputtingEnd
         */
        InputtingEnd.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.InputtingEnd)
                return object;
            let message = new $root.gateway.InputtingEnd();
            if (object.self != null)
                if ($util.Long)
                    (message.self = $util.Long.fromValue(object.self)).unsigned = false;
                else if (typeof object.self === "string")
                    message.self = parseInt(object.self, 10);
                else if (typeof object.self === "number")
                    message.self = object.self;
                else if (typeof object.self === "object")
                    message.self = new $util.LongBits(object.self.low >>> 0, object.self.high >>> 0).toNumber();
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an InputtingEnd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.InputtingEnd
         * @static
         * @param {gateway.InputtingEnd} message InputtingEnd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InputtingEnd.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.self = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.self = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
            }
            if (message.self != null && message.hasOwnProperty("self"))
                if (typeof message.self === "number")
                    object.self = options.longs === String ? String(message.self) : message.self;
                else
                    object.self = options.longs === String ? $util.Long.prototype.toString.call(message.self) : options.longs === Number ? new $util.LongBits(message.self.low >>> 0, message.self.high >>> 0).toNumber() : message.self;
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            return object;
        };

        /**
         * Converts this InputtingEnd to JSON.
         * @function toJSON
         * @memberof gateway.InputtingEnd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InputtingEnd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for InputtingEnd
         * @function getTypeUrl
         * @memberof gateway.InputtingEnd
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InputtingEnd.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.InputtingEnd";
        };

        return InputtingEnd;
    })();

    gateway.WorkerTransfer = (function() {

        /**
         * Properties of a WorkerTransfer.
         * @memberof gateway
         * @interface IWorkerTransfer
         * @property {api.common.IMessage|null} [msg] WorkerTransfer msg
         */

        /**
         * Constructs a new WorkerTransfer.
         * @memberof gateway
         * @classdesc Represents a WorkerTransfer.
         * @implements IWorkerTransfer
         * @constructor
         * @param {gateway.IWorkerTransfer=} [properties] Properties to set
         */
        function WorkerTransfer(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkerTransfer msg.
         * @member {api.common.IMessage|null|undefined} msg
         * @memberof gateway.WorkerTransfer
         * @instance
         */
        WorkerTransfer.prototype.msg = null;

        /**
         * Creates a new WorkerTransfer instance using the specified properties.
         * @function create
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {gateway.IWorkerTransfer=} [properties] Properties to set
         * @returns {gateway.WorkerTransfer} WorkerTransfer instance
         */
        WorkerTransfer.create = function create(properties) {
            return new WorkerTransfer(properties);
        };

        /**
         * Encodes the specified WorkerTransfer message. Does not implicitly {@link gateway.WorkerTransfer.verify|verify} messages.
         * @function encode
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {gateway.IWorkerTransfer} message WorkerTransfer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkerTransfer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                $root.api.common.Message.encode(message.msg, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkerTransfer message, length delimited. Does not implicitly {@link gateway.WorkerTransfer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {gateway.IWorkerTransfer} message WorkerTransfer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkerTransfer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkerTransfer message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.WorkerTransfer} WorkerTransfer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkerTransfer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.WorkerTransfer();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msg = $root.api.common.Message.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkerTransfer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.WorkerTransfer} WorkerTransfer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkerTransfer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkerTransfer message.
         * @function verify
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkerTransfer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msg != null && message.hasOwnProperty("msg")) {
                let error = $root.api.common.Message.verify(message.msg);
                if (error)
                    return "msg." + error;
            }
            return null;
        };

        /**
         * Creates a WorkerTransfer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.WorkerTransfer} WorkerTransfer
         */
        WorkerTransfer.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.WorkerTransfer)
                return object;
            let message = new $root.gateway.WorkerTransfer();
            if (object.msg != null) {
                if (typeof object.msg !== "object")
                    throw TypeError(".gateway.WorkerTransfer.msg: object expected");
                message.msg = $root.api.common.Message.fromObject(object.msg);
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkerTransfer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {gateway.WorkerTransfer} message WorkerTransfer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkerTransfer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.msg = null;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = $root.api.common.Message.toObject(message.msg, options);
            return object;
        };

        /**
         * Converts this WorkerTransfer to JSON.
         * @function toJSON
         * @memberof gateway.WorkerTransfer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkerTransfer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WorkerTransfer
         * @function getTypeUrl
         * @memberof gateway.WorkerTransfer
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WorkerTransfer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.WorkerTransfer";
        };

        return WorkerTransfer;
    })();

    gateway.SCChatChanged = (function() {

        /**
         * Properties of a SCChatChanged.
         * @memberof gateway
         * @interface ISCChatChanged
         * @property {api.common.ChatState|null} [from] SCChatChanged from
         * @property {api.common.ChatState|null} [to] SCChatChanged to
         * @property {Long|null} [chatId] SCChatChanged chatId
         * @property {api.common.IChatItem|null} [chat] SCChatChanged chat
         * @property {Long|null} [target] SCChatChanged target
         */

        /**
         * Constructs a new SCChatChanged.
         * @memberof gateway
         * @classdesc Represents a SCChatChanged.
         * @implements ISCChatChanged
         * @constructor
         * @param {gateway.ISCChatChanged=} [properties] Properties to set
         */
        function SCChatChanged(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCChatChanged from.
         * @member {api.common.ChatState} from
         * @memberof gateway.SCChatChanged
         * @instance
         */
        SCChatChanged.prototype.from = 0;

        /**
         * SCChatChanged to.
         * @member {api.common.ChatState} to
         * @memberof gateway.SCChatChanged
         * @instance
         */
        SCChatChanged.prototype.to = 0;

        /**
         * SCChatChanged chatId.
         * @member {Long} chatId
         * @memberof gateway.SCChatChanged
         * @instance
         */
        SCChatChanged.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCChatChanged chat.
         * @member {api.common.IChatItem|null|undefined} chat
         * @memberof gateway.SCChatChanged
         * @instance
         */
        SCChatChanged.prototype.chat = null;

        /**
         * SCChatChanged target.
         * @member {Long} target
         * @memberof gateway.SCChatChanged
         * @instance
         */
        SCChatChanged.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SCChatChanged instance using the specified properties.
         * @function create
         * @memberof gateway.SCChatChanged
         * @static
         * @param {gateway.ISCChatChanged=} [properties] Properties to set
         * @returns {gateway.SCChatChanged} SCChatChanged instance
         */
        SCChatChanged.create = function create(properties) {
            return new SCChatChanged(properties);
        };

        /**
         * Encodes the specified SCChatChanged message. Does not implicitly {@link gateway.SCChatChanged.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCChatChanged
         * @static
         * @param {gateway.ISCChatChanged} message SCChatChanged message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCChatChanged.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.from);
            if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.to);
            if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.chatId);
            if (message.chat != null && Object.hasOwnProperty.call(message, "chat"))
                $root.api.common.ChatItem.encode(message.chat, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.target);
            return writer;
        };

        /**
         * Encodes the specified SCChatChanged message, length delimited. Does not implicitly {@link gateway.SCChatChanged.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCChatChanged
         * @static
         * @param {gateway.ISCChatChanged} message SCChatChanged message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCChatChanged.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCChatChanged message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCChatChanged
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCChatChanged} SCChatChanged
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCChatChanged.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCChatChanged();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.from = reader.int32();
                        break;
                    }
                case 2: {
                        message.to = reader.int32();
                        break;
                    }
                case 3: {
                        message.chatId = reader.int64();
                        break;
                    }
                case 4: {
                        message.chat = $root.api.common.ChatItem.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.target = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCChatChanged message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCChatChanged
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCChatChanged} SCChatChanged
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCChatChanged.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCChatChanged message.
         * @function verify
         * @memberof gateway.SCChatChanged
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCChatChanged.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.from != null && message.hasOwnProperty("from"))
                switch (message.from) {
                default:
                    return "from: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.to != null && message.hasOwnProperty("to"))
                switch (message.to) {
                default:
                    return "to: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                    return "chatId: integer|Long expected";
            if (message.chat != null && message.hasOwnProperty("chat")) {
                let error = $root.api.common.ChatItem.verify(message.chat);
                if (error)
                    return "chat." + error;
            }
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            return null;
        };

        /**
         * Creates a SCChatChanged message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCChatChanged
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCChatChanged} SCChatChanged
         */
        SCChatChanged.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCChatChanged)
                return object;
            let message = new $root.gateway.SCChatChanged();
            switch (object.from) {
            default:
                if (typeof object.from === "number") {
                    message.from = object.from;
                    break;
                }
                break;
            case "CHAT_STATE_COMMON":
            case 0:
                message.from = 0;
                break;
            case "CHAT_STATE_TRANSFER":
            case 1:
                message.from = 1;
                break;
            case "CHAT_STATE_UNPROCESSED_3MIN":
            case 2:
                message.from = 2;
                break;
            case "CHAT_STATE_TIMEOUT":
            case 3:
                message.from = 3;
                break;
            case "CHAT_STATE_PROCESSED":
            case 4:
                message.from = 4;
                break;
            case "CHAT_STATE_BLACKLIST_APPLY":
            case 5:
                message.from = 5;
                break;
            case "CHAT_STATE_BLACKLIST_CONFIRMED":
            case 6:
                message.from = 6;
                break;
            }
            switch (object.to) {
            default:
                if (typeof object.to === "number") {
                    message.to = object.to;
                    break;
                }
                break;
            case "CHAT_STATE_COMMON":
            case 0:
                message.to = 0;
                break;
            case "CHAT_STATE_TRANSFER":
            case 1:
                message.to = 1;
                break;
            case "CHAT_STATE_UNPROCESSED_3MIN":
            case 2:
                message.to = 2;
                break;
            case "CHAT_STATE_TIMEOUT":
            case 3:
                message.to = 3;
                break;
            case "CHAT_STATE_PROCESSED":
            case 4:
                message.to = 4;
                break;
            case "CHAT_STATE_BLACKLIST_APPLY":
            case 5:
                message.to = 5;
                break;
            case "CHAT_STATE_BLACKLIST_CONFIRMED":
            case 6:
                message.to = 6;
                break;
            }
            if (object.chatId != null)
                if ($util.Long)
                    (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                else if (typeof object.chatId === "string")
                    message.chatId = parseInt(object.chatId, 10);
                else if (typeof object.chatId === "number")
                    message.chatId = object.chatId;
                else if (typeof object.chatId === "object")
                    message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
            if (object.chat != null) {
                if (typeof object.chat !== "object")
                    throw TypeError(".gateway.SCChatChanged.chat: object expected");
                message.chat = $root.api.common.ChatItem.fromObject(object.chat);
            }
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SCChatChanged message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCChatChanged
         * @static
         * @param {gateway.SCChatChanged} message SCChatChanged
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCChatChanged.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.from = options.enums === String ? "CHAT_STATE_COMMON" : 0;
                object.to = options.enums === String ? "CHAT_STATE_COMMON" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chatId = options.longs === String ? "0" : 0;
                object.chat = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
            }
            if (message.from != null && message.hasOwnProperty("from"))
                object.from = options.enums === String ? $root.api.common.ChatState[message.from] === undefined ? message.from : $root.api.common.ChatState[message.from] : message.from;
            if (message.to != null && message.hasOwnProperty("to"))
                object.to = options.enums === String ? $root.api.common.ChatState[message.to] === undefined ? message.to : $root.api.common.ChatState[message.to] : message.to;
            if (message.chatId != null && message.hasOwnProperty("chatId"))
                if (typeof message.chatId === "number")
                    object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                else
                    object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
            if (message.chat != null && message.hasOwnProperty("chat"))
                object.chat = $root.api.common.ChatItem.toObject(message.chat, options);
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            return object;
        };

        /**
         * Converts this SCChatChanged to JSON.
         * @function toJSON
         * @memberof gateway.SCChatChanged
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCChatChanged.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCChatChanged
         * @function getTypeUrl
         * @memberof gateway.SCChatChanged
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCChatChanged.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCChatChanged";
        };

        return SCChatChanged;
    })();

    gateway.SCUserConnectionChanged = (function() {

        /**
         * Properties of a SCUserConnectionChanged.
         * @memberof gateway
         * @interface ISCUserConnectionChanged
         * @property {Long|null} [clientId] SCUserConnectionChanged clientId
         * @property {api.common.ConnectState|null} [conn] SCUserConnectionChanged conn
         * @property {Long|null} [target] SCUserConnectionChanged target
         */

        /**
         * Constructs a new SCUserConnectionChanged.
         * @memberof gateway
         * @classdesc Represents a SCUserConnectionChanged.
         * @implements ISCUserConnectionChanged
         * @constructor
         * @param {gateway.ISCUserConnectionChanged=} [properties] Properties to set
         */
        function SCUserConnectionChanged(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCUserConnectionChanged clientId.
         * @member {Long} clientId
         * @memberof gateway.SCUserConnectionChanged
         * @instance
         */
        SCUserConnectionChanged.prototype.clientId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCUserConnectionChanged conn.
         * @member {api.common.ConnectState} conn
         * @memberof gateway.SCUserConnectionChanged
         * @instance
         */
        SCUserConnectionChanged.prototype.conn = 0;

        /**
         * SCUserConnectionChanged target.
         * @member {Long} target
         * @memberof gateway.SCUserConnectionChanged
         * @instance
         */
        SCUserConnectionChanged.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SCUserConnectionChanged instance using the specified properties.
         * @function create
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {gateway.ISCUserConnectionChanged=} [properties] Properties to set
         * @returns {gateway.SCUserConnectionChanged} SCUserConnectionChanged instance
         */
        SCUserConnectionChanged.create = function create(properties) {
            return new SCUserConnectionChanged(properties);
        };

        /**
         * Encodes the specified SCUserConnectionChanged message. Does not implicitly {@link gateway.SCUserConnectionChanged.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {gateway.ISCUserConnectionChanged} message SCUserConnectionChanged message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCUserConnectionChanged.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.clientId);
            if (message.conn != null && Object.hasOwnProperty.call(message, "conn"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.conn);
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.target);
            return writer;
        };

        /**
         * Encodes the specified SCUserConnectionChanged message, length delimited. Does not implicitly {@link gateway.SCUserConnectionChanged.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {gateway.ISCUserConnectionChanged} message SCUserConnectionChanged message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCUserConnectionChanged.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCUserConnectionChanged message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCUserConnectionChanged} SCUserConnectionChanged
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCUserConnectionChanged.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCUserConnectionChanged();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.clientId = reader.int64();
                        break;
                    }
                case 2: {
                        message.conn = reader.int32();
                        break;
                    }
                case 3: {
                        message.target = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCUserConnectionChanged message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCUserConnectionChanged} SCUserConnectionChanged
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCUserConnectionChanged.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCUserConnectionChanged message.
         * @function verify
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCUserConnectionChanged.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.clientId != null && message.hasOwnProperty("clientId"))
                if (!$util.isInteger(message.clientId) && !(message.clientId && $util.isInteger(message.clientId.low) && $util.isInteger(message.clientId.high)))
                    return "clientId: integer|Long expected";
            if (message.conn != null && message.hasOwnProperty("conn"))
                switch (message.conn) {
                default:
                    return "conn: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            return null;
        };

        /**
         * Creates a SCUserConnectionChanged message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCUserConnectionChanged} SCUserConnectionChanged
         */
        SCUserConnectionChanged.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCUserConnectionChanged)
                return object;
            let message = new $root.gateway.SCUserConnectionChanged();
            if (object.clientId != null)
                if ($util.Long)
                    (message.clientId = $util.Long.fromValue(object.clientId)).unsigned = false;
                else if (typeof object.clientId === "string")
                    message.clientId = parseInt(object.clientId, 10);
                else if (typeof object.clientId === "number")
                    message.clientId = object.clientId;
                else if (typeof object.clientId === "object")
                    message.clientId = new $util.LongBits(object.clientId.low >>> 0, object.clientId.high >>> 0).toNumber();
            switch (object.conn) {
            default:
                if (typeof object.conn === "number") {
                    message.conn = object.conn;
                    break;
                }
                break;
            case "CONNECT_STATE_OFFLINE":
            case 0:
                message.conn = 0;
                break;
            case "CONNECT_STATE_ONLINE":
            case 1:
                message.conn = 1;
                break;
            }
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SCUserConnectionChanged message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {gateway.SCUserConnectionChanged} message SCUserConnectionChanged
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCUserConnectionChanged.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.clientId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.clientId = options.longs === String ? "0" : 0;
                object.conn = options.enums === String ? "CONNECT_STATE_OFFLINE" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
            }
            if (message.clientId != null && message.hasOwnProperty("clientId"))
                if (typeof message.clientId === "number")
                    object.clientId = options.longs === String ? String(message.clientId) : message.clientId;
                else
                    object.clientId = options.longs === String ? $util.Long.prototype.toString.call(message.clientId) : options.longs === Number ? new $util.LongBits(message.clientId.low >>> 0, message.clientId.high >>> 0).toNumber() : message.clientId;
            if (message.conn != null && message.hasOwnProperty("conn"))
                object.conn = options.enums === String ? $root.api.common.ConnectState[message.conn] === undefined ? message.conn : $root.api.common.ConnectState[message.conn] : message.conn;
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            return object;
        };

        /**
         * Converts this SCUserConnectionChanged to JSON.
         * @function toJSON
         * @memberof gateway.SCUserConnectionChanged
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCUserConnectionChanged.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCUserConnectionChanged
         * @function getTypeUrl
         * @memberof gateway.SCUserConnectionChanged
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCUserConnectionChanged.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCUserConnectionChanged";
        };

        return SCUserConnectionChanged;
    })();

    /**
     * WorkerChangedReason enum.
     * @name gateway.WorkerChangedReason
     * @enum {number}
     * @property {number} WorkerChangedReasonUnknown=0 WorkerChangedReasonUnknown value
     * @property {number} WorkerChangedReasonMissAssignedWorker=1 WorkerChangedReasonMissAssignedWorker value
     * @property {number} WorkerChangedReasonTransferWorker=2 WorkerChangedReasonTransferWorker value
     * @property {number} WorkerChangedReasonWorkerDeleted=3 WorkerChangedReasonWorkerDeleted value
     */
    gateway.WorkerChangedReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WorkerChangedReasonUnknown"] = 0;
        values[valuesById[1] = "WorkerChangedReasonMissAssignedWorker"] = 1;
        values[valuesById[2] = "WorkerChangedReasonTransferWorker"] = 2;
        values[valuesById[3] = "WorkerChangedReasonWorkerDeleted"] = 3;
        return values;
    })();

    gateway.SCWorkerChanged = (function() {

        /**
         * Properties of a SCWorkerChanged.
         * @memberof gateway
         * @interface ISCWorkerChanged
         * @property {number|null} [workerId] SCWorkerChanged workerId
         * @property {string|null} [workerName] SCWorkerChanged workerName
         * @property {string|null} [workerAvatar] SCWorkerChanged workerAvatar
         * @property {Long|null} [target] SCWorkerChanged target
         * @property {gateway.WorkerChangedReason|null} [reason] SCWorkerChanged reason
         */

        /**
         * Constructs a new SCWorkerChanged.
         * @memberof gateway
         * @classdesc Represents a SCWorkerChanged.
         * @implements ISCWorkerChanged
         * @constructor
         * @param {gateway.ISCWorkerChanged=} [properties] Properties to set
         */
        function SCWorkerChanged(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCWorkerChanged workerId.
         * @member {number} workerId
         * @memberof gateway.SCWorkerChanged
         * @instance
         */
        SCWorkerChanged.prototype.workerId = 0;

        /**
         * SCWorkerChanged workerName.
         * @member {string} workerName
         * @memberof gateway.SCWorkerChanged
         * @instance
         */
        SCWorkerChanged.prototype.workerName = "";

        /**
         * SCWorkerChanged workerAvatar.
         * @member {string} workerAvatar
         * @memberof gateway.SCWorkerChanged
         * @instance
         */
        SCWorkerChanged.prototype.workerAvatar = "";

        /**
         * SCWorkerChanged target.
         * @member {Long} target
         * @memberof gateway.SCWorkerChanged
         * @instance
         */
        SCWorkerChanged.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCWorkerChanged reason.
         * @member {gateway.WorkerChangedReason} reason
         * @memberof gateway.SCWorkerChanged
         * @instance
         */
        SCWorkerChanged.prototype.reason = 0;

        /**
         * Creates a new SCWorkerChanged instance using the specified properties.
         * @function create
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {gateway.ISCWorkerChanged=} [properties] Properties to set
         * @returns {gateway.SCWorkerChanged} SCWorkerChanged instance
         */
        SCWorkerChanged.create = function create(properties) {
            return new SCWorkerChanged(properties);
        };

        /**
         * Encodes the specified SCWorkerChanged message. Does not implicitly {@link gateway.SCWorkerChanged.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {gateway.ISCWorkerChanged} message SCWorkerChanged message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCWorkerChanged.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
            if (message.workerName != null && Object.hasOwnProperty.call(message, "workerName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.workerName);
            if (message.workerAvatar != null && Object.hasOwnProperty.call(message, "workerAvatar"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.workerAvatar);
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.target);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.reason);
            return writer;
        };

        /**
         * Encodes the specified SCWorkerChanged message, length delimited. Does not implicitly {@link gateway.SCWorkerChanged.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {gateway.ISCWorkerChanged} message SCWorkerChanged message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCWorkerChanged.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCWorkerChanged message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCWorkerChanged} SCWorkerChanged
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCWorkerChanged.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCWorkerChanged();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.workerId = reader.int32();
                        break;
                    }
                case 2: {
                        message.workerName = reader.string();
                        break;
                    }
                case 3: {
                        message.workerAvatar = reader.string();
                        break;
                    }
                case 4: {
                        message.target = reader.int64();
                        break;
                    }
                case 5: {
                        message.reason = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCWorkerChanged message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCWorkerChanged} SCWorkerChanged
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCWorkerChanged.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCWorkerChanged message.
         * @function verify
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCWorkerChanged.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.workerId != null && message.hasOwnProperty("workerId"))
                if (!$util.isInteger(message.workerId))
                    return "workerId: integer expected";
            if (message.workerName != null && message.hasOwnProperty("workerName"))
                if (!$util.isString(message.workerName))
                    return "workerName: string expected";
            if (message.workerAvatar != null && message.hasOwnProperty("workerAvatar"))
                if (!$util.isString(message.workerAvatar))
                    return "workerAvatar: string expected";
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                switch (message.reason) {
                default:
                    return "reason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a SCWorkerChanged message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCWorkerChanged} SCWorkerChanged
         */
        SCWorkerChanged.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCWorkerChanged)
                return object;
            let message = new $root.gateway.SCWorkerChanged();
            if (object.workerId != null)
                message.workerId = object.workerId | 0;
            if (object.workerName != null)
                message.workerName = String(object.workerName);
            if (object.workerAvatar != null)
                message.workerAvatar = String(object.workerAvatar);
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            switch (object.reason) {
            default:
                if (typeof object.reason === "number") {
                    message.reason = object.reason;
                    break;
                }
                break;
            case "WorkerChangedReasonUnknown":
            case 0:
                message.reason = 0;
                break;
            case "WorkerChangedReasonMissAssignedWorker":
            case 1:
                message.reason = 1;
                break;
            case "WorkerChangedReasonTransferWorker":
            case 2:
                message.reason = 2;
                break;
            case "WorkerChangedReasonWorkerDeleted":
            case 3:
                message.reason = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SCWorkerChanged message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {gateway.SCWorkerChanged} message SCWorkerChanged
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCWorkerChanged.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.workerId = 0;
                object.workerName = "";
                object.workerAvatar = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
                object.reason = options.enums === String ? "WorkerChangedReasonUnknown" : 0;
            }
            if (message.workerId != null && message.hasOwnProperty("workerId"))
                object.workerId = message.workerId;
            if (message.workerName != null && message.hasOwnProperty("workerName"))
                object.workerName = message.workerName;
            if (message.workerAvatar != null && message.hasOwnProperty("workerAvatar"))
                object.workerAvatar = message.workerAvatar;
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = options.enums === String ? $root.gateway.WorkerChangedReason[message.reason] === undefined ? message.reason : $root.gateway.WorkerChangedReason[message.reason] : message.reason;
            return object;
        };

        /**
         * Converts this SCWorkerChanged to JSON.
         * @function toJSON
         * @memberof gateway.SCWorkerChanged
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCWorkerChanged.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCWorkerChanged
         * @function getTypeUrl
         * @memberof gateway.SCWorkerChanged
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCWorkerChanged.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCWorkerChanged";
        };

        return SCWorkerChanged;
    })();

    /**
     * KickReason enum.
     * @name gateway.KickReason
     * @enum {number}
     * @property {number} KickReasonCommon=0 KickReasonCommon value
     * @property {number} KickReasonPermChanged=1 KickReasonPermChanged value
     */
    gateway.KickReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "KickReasonCommon"] = 0;
        values[valuesById[1] = "KickReasonPermChanged"] = 1;
        return values;
    })();

    gateway.SCKick = (function() {

        /**
         * Properties of a SCKick.
         * @memberof gateway
         * @interface ISCKick
         * @property {Long|null} [target] SCKick target
         * @property {gateway.KickReason|null} [reason] SCKick reason
         */

        /**
         * Constructs a new SCKick.
         * @memberof gateway
         * @classdesc Represents a SCKick.
         * @implements ISCKick
         * @constructor
         * @param {gateway.ISCKick=} [properties] Properties to set
         */
        function SCKick(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCKick target.
         * @member {Long} target
         * @memberof gateway.SCKick
         * @instance
         */
        SCKick.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCKick reason.
         * @member {gateway.KickReason} reason
         * @memberof gateway.SCKick
         * @instance
         */
        SCKick.prototype.reason = 0;

        /**
         * Creates a new SCKick instance using the specified properties.
         * @function create
         * @memberof gateway.SCKick
         * @static
         * @param {gateway.ISCKick=} [properties] Properties to set
         * @returns {gateway.SCKick} SCKick instance
         */
        SCKick.create = function create(properties) {
            return new SCKick(properties);
        };

        /**
         * Encodes the specified SCKick message. Does not implicitly {@link gateway.SCKick.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCKick
         * @static
         * @param {gateway.ISCKick} message SCKick message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCKick.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.target);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.reason);
            return writer;
        };

        /**
         * Encodes the specified SCKick message, length delimited. Does not implicitly {@link gateway.SCKick.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCKick
         * @static
         * @param {gateway.ISCKick} message SCKick message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCKick.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCKick message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCKick
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCKick} SCKick
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCKick.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCKick();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.target = reader.int64();
                        break;
                    }
                case 2: {
                        message.reason = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCKick message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCKick
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCKick} SCKick
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCKick.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCKick message.
         * @function verify
         * @memberof gateway.SCKick
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCKick.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                switch (message.reason) {
                default:
                    return "reason: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a SCKick message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCKick
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCKick} SCKick
         */
        SCKick.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCKick)
                return object;
            let message = new $root.gateway.SCKick();
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            switch (object.reason) {
            default:
                if (typeof object.reason === "number") {
                    message.reason = object.reason;
                    break;
                }
                break;
            case "KickReasonCommon":
            case 0:
                message.reason = 0;
                break;
            case "KickReasonPermChanged":
            case 1:
                message.reason = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SCKick message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCKick
         * @static
         * @param {gateway.SCKick} message SCKick
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCKick.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
                object.reason = options.enums === String ? "KickReasonCommon" : 0;
            }
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = options.enums === String ? $root.gateway.KickReason[message.reason] === undefined ? message.reason : $root.gateway.KickReason[message.reason] : message.reason;
            return object;
        };

        /**
         * Converts this SCKick to JSON.
         * @function toJSON
         * @memberof gateway.SCKick
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCKick.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCKick
         * @function getTypeUrl
         * @memberof gateway.SCKick
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCKick.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCKick";
        };

        return SCKick;
    })();

    gateway.SCSimSendMessage = (function() {

        /**
         * Properties of a SCSimSendMessage.
         * @memberof gateway
         * @interface ISCSimSendMessage
         * @property {Array.<api.common.IMessage>|null} [msgs] SCSimSendMessage msgs
         * @property {Long|null} [worker] SCSimSendMessage worker
         * @property {Long|null} [target] SCSimSendMessage target
         */

        /**
         * Constructs a new SCSimSendMessage.
         * @memberof gateway
         * @classdesc Represents a SCSimSendMessage.
         * @implements ISCSimSendMessage
         * @constructor
         * @param {gateway.ISCSimSendMessage=} [properties] Properties to set
         */
        function SCSimSendMessage(properties) {
            this.msgs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCSimSendMessage msgs.
         * @member {Array.<api.common.IMessage>} msgs
         * @memberof gateway.SCSimSendMessage
         * @instance
         */
        SCSimSendMessage.prototype.msgs = $util.emptyArray;

        /**
         * SCSimSendMessage worker.
         * @member {Long} worker
         * @memberof gateway.SCSimSendMessage
         * @instance
         */
        SCSimSendMessage.prototype.worker = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SCSimSendMessage target.
         * @member {Long} target
         * @memberof gateway.SCSimSendMessage
         * @instance
         */
        SCSimSendMessage.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SCSimSendMessage instance using the specified properties.
         * @function create
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {gateway.ISCSimSendMessage=} [properties] Properties to set
         * @returns {gateway.SCSimSendMessage} SCSimSendMessage instance
         */
        SCSimSendMessage.create = function create(properties) {
            return new SCSimSendMessage(properties);
        };

        /**
         * Encodes the specified SCSimSendMessage message. Does not implicitly {@link gateway.SCSimSendMessage.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {gateway.ISCSimSendMessage} message SCSimSendMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCSimSendMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msgs != null && message.msgs.length)
                for (let i = 0; i < message.msgs.length; ++i)
                    $root.api.common.Message.encode(message.msgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.worker != null && Object.hasOwnProperty.call(message, "worker"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.worker);
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.target);
            return writer;
        };

        /**
         * Encodes the specified SCSimSendMessage message, length delimited. Does not implicitly {@link gateway.SCSimSendMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {gateway.ISCSimSendMessage} message SCSimSendMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCSimSendMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCSimSendMessage message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCSimSendMessage} SCSimSendMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCSimSendMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCSimSendMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.msgs && message.msgs.length))
                            message.msgs = [];
                        message.msgs.push($root.api.common.Message.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.worker = reader.int64();
                        break;
                    }
                case 3: {
                        message.target = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCSimSendMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCSimSendMessage} SCSimSendMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCSimSendMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCSimSendMessage message.
         * @function verify
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCSimSendMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msgs != null && message.hasOwnProperty("msgs")) {
                if (!Array.isArray(message.msgs))
                    return "msgs: array expected";
                for (let i = 0; i < message.msgs.length; ++i) {
                    let error = $root.api.common.Message.verify(message.msgs[i]);
                    if (error)
                        return "msgs." + error;
                }
            }
            if (message.worker != null && message.hasOwnProperty("worker"))
                if (!$util.isInteger(message.worker) && !(message.worker && $util.isInteger(message.worker.low) && $util.isInteger(message.worker.high)))
                    return "worker: integer|Long expected";
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            return null;
        };

        /**
         * Creates a SCSimSendMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCSimSendMessage} SCSimSendMessage
         */
        SCSimSendMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCSimSendMessage)
                return object;
            let message = new $root.gateway.SCSimSendMessage();
            if (object.msgs) {
                if (!Array.isArray(object.msgs))
                    throw TypeError(".gateway.SCSimSendMessage.msgs: array expected");
                message.msgs = [];
                for (let i = 0; i < object.msgs.length; ++i) {
                    if (typeof object.msgs[i] !== "object")
                        throw TypeError(".gateway.SCSimSendMessage.msgs: object expected");
                    message.msgs[i] = $root.api.common.Message.fromObject(object.msgs[i]);
                }
            }
            if (object.worker != null)
                if ($util.Long)
                    (message.worker = $util.Long.fromValue(object.worker)).unsigned = false;
                else if (typeof object.worker === "string")
                    message.worker = parseInt(object.worker, 10);
                else if (typeof object.worker === "number")
                    message.worker = object.worker;
                else if (typeof object.worker === "object")
                    message.worker = new $util.LongBits(object.worker.low >>> 0, object.worker.high >>> 0).toNumber();
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SCSimSendMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {gateway.SCSimSendMessage} message SCSimSendMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCSimSendMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.msgs = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.worker = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.worker = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
            }
            if (message.msgs && message.msgs.length) {
                object.msgs = [];
                for (let j = 0; j < message.msgs.length; ++j)
                    object.msgs[j] = $root.api.common.Message.toObject(message.msgs[j], options);
            }
            if (message.worker != null && message.hasOwnProperty("worker"))
                if (typeof message.worker === "number")
                    object.worker = options.longs === String ? String(message.worker) : message.worker;
                else
                    object.worker = options.longs === String ? $util.Long.prototype.toString.call(message.worker) : options.longs === Number ? new $util.LongBits(message.worker.low >>> 0, message.worker.high >>> 0).toNumber() : message.worker;
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            return object;
        };

        /**
         * Converts this SCSimSendMessage to JSON.
         * @function toJSON
         * @memberof gateway.SCSimSendMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCSimSendMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCSimSendMessage
         * @function getTypeUrl
         * @memberof gateway.SCSimSendMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCSimSendMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCSimSendMessage";
        };

        return SCSimSendMessage;
    })();

    gateway.SCSimSendMessageToWorker = (function() {

        /**
         * Properties of a SCSimSendMessageToWorker.
         * @memberof gateway
         * @interface ISCSimSendMessageToWorker
         * @property {Array.<api.common.IMessage>|null} [msgs] SCSimSendMessageToWorker msgs
         * @property {Long|null} [target] SCSimSendMessageToWorker target
         */

        /**
         * Constructs a new SCSimSendMessageToWorker.
         * @memberof gateway
         * @classdesc Represents a SCSimSendMessageToWorker.
         * @implements ISCSimSendMessageToWorker
         * @constructor
         * @param {gateway.ISCSimSendMessageToWorker=} [properties] Properties to set
         */
        function SCSimSendMessageToWorker(properties) {
            this.msgs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SCSimSendMessageToWorker msgs.
         * @member {Array.<api.common.IMessage>} msgs
         * @memberof gateway.SCSimSendMessageToWorker
         * @instance
         */
        SCSimSendMessageToWorker.prototype.msgs = $util.emptyArray;

        /**
         * SCSimSendMessageToWorker target.
         * @member {Long} target
         * @memberof gateway.SCSimSendMessageToWorker
         * @instance
         */
        SCSimSendMessageToWorker.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SCSimSendMessageToWorker instance using the specified properties.
         * @function create
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {gateway.ISCSimSendMessageToWorker=} [properties] Properties to set
         * @returns {gateway.SCSimSendMessageToWorker} SCSimSendMessageToWorker instance
         */
        SCSimSendMessageToWorker.create = function create(properties) {
            return new SCSimSendMessageToWorker(properties);
        };

        /**
         * Encodes the specified SCSimSendMessageToWorker message. Does not implicitly {@link gateway.SCSimSendMessageToWorker.verify|verify} messages.
         * @function encode
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {gateway.ISCSimSendMessageToWorker} message SCSimSendMessageToWorker message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCSimSendMessageToWorker.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msgs != null && message.msgs.length)
                for (let i = 0; i < message.msgs.length; ++i)
                    $root.api.common.Message.encode(message.msgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.target);
            return writer;
        };

        /**
         * Encodes the specified SCSimSendMessageToWorker message, length delimited. Does not implicitly {@link gateway.SCSimSendMessageToWorker.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {gateway.ISCSimSendMessageToWorker} message SCSimSendMessageToWorker message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SCSimSendMessageToWorker.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SCSimSendMessageToWorker message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.SCSimSendMessageToWorker} SCSimSendMessageToWorker
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCSimSendMessageToWorker.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.SCSimSendMessageToWorker();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.msgs && message.msgs.length))
                            message.msgs = [];
                        message.msgs.push($root.api.common.Message.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.target = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SCSimSendMessageToWorker message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.SCSimSendMessageToWorker} SCSimSendMessageToWorker
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SCSimSendMessageToWorker.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SCSimSendMessageToWorker message.
         * @function verify
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SCSimSendMessageToWorker.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msgs != null && message.hasOwnProperty("msgs")) {
                if (!Array.isArray(message.msgs))
                    return "msgs: array expected";
                for (let i = 0; i < message.msgs.length; ++i) {
                    let error = $root.api.common.Message.verify(message.msgs[i]);
                    if (error)
                        return "msgs." + error;
                }
            }
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            return null;
        };

        /**
         * Creates a SCSimSendMessageToWorker message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.SCSimSendMessageToWorker} SCSimSendMessageToWorker
         */
        SCSimSendMessageToWorker.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.SCSimSendMessageToWorker)
                return object;
            let message = new $root.gateway.SCSimSendMessageToWorker();
            if (object.msgs) {
                if (!Array.isArray(object.msgs))
                    throw TypeError(".gateway.SCSimSendMessageToWorker.msgs: array expected");
                message.msgs = [];
                for (let i = 0; i < object.msgs.length; ++i) {
                    if (typeof object.msgs[i] !== "object")
                        throw TypeError(".gateway.SCSimSendMessageToWorker.msgs: object expected");
                    message.msgs[i] = $root.api.common.Message.fromObject(object.msgs[i]);
                }
            }
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SCSimSendMessageToWorker message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {gateway.SCSimSendMessageToWorker} message SCSimSendMessageToWorker
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SCSimSendMessageToWorker.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.msgs = [];
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
            if (message.msgs && message.msgs.length) {
                object.msgs = [];
                for (let j = 0; j < message.msgs.length; ++j)
                    object.msgs[j] = $root.api.common.Message.toObject(message.msgs[j], options);
            }
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            return object;
        };

        /**
         * Converts this SCSimSendMessageToWorker to JSON.
         * @function toJSON
         * @memberof gateway.SCSimSendMessageToWorker
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SCSimSendMessageToWorker.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SCSimSendMessageToWorker
         * @function getTypeUrl
         * @memberof gateway.SCSimSendMessageToWorker
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SCSimSendMessageToWorker.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.SCSimSendMessageToWorker";
        };

        return SCSimSendMessageToWorker;
    })();

    gateway.Payload = (function() {

        /**
         * Properties of a Payload.
         * @memberof gateway
         * @interface IPayload
         * @property {Long|null} [id] Payload id
         * @property {gateway.Action|null} [act] Payload act
         * @property {Uint8Array|null} [data] Payload data
         */

        /**
         * Constructs a new Payload.
         * @memberof gateway
         * @classdesc Represents a Payload.
         * @implements IPayload
         * @constructor
         * @param {gateway.IPayload=} [properties] Properties to set
         */
        function Payload(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Payload id.
         * @member {Long} id
         * @memberof gateway.Payload
         * @instance
         */
        Payload.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Payload act.
         * @member {gateway.Action} act
         * @memberof gateway.Payload
         * @instance
         */
        Payload.prototype.act = 0;

        /**
         * Payload data.
         * @member {Uint8Array} data
         * @memberof gateway.Payload
         * @instance
         */
        Payload.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new Payload instance using the specified properties.
         * @function create
         * @memberof gateway.Payload
         * @static
         * @param {gateway.IPayload=} [properties] Properties to set
         * @returns {gateway.Payload} Payload instance
         */
        Payload.create = function create(properties) {
            return new Payload(properties);
        };

        /**
         * Encodes the specified Payload message. Does not implicitly {@link gateway.Payload.verify|verify} messages.
         * @function encode
         * @memberof gateway.Payload
         * @static
         * @param {gateway.IPayload} message Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
            if (message.act != null && Object.hasOwnProperty.call(message, "act"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.act);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified Payload message, length delimited. Does not implicitly {@link gateway.Payload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.Payload
         * @static
         * @param {gateway.IPayload} message Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Payload message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.Payload} Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.Payload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.act = reader.int32();
                        break;
                    }
                case 3: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Payload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.Payload} Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Payload message.
         * @function verify
         * @memberof gateway.Payload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Payload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.act != null && message.hasOwnProperty("act"))
                switch (message.act) {
                default:
                    return "act: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                    break;
                }
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a Payload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.Payload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.Payload} Payload
         */
        Payload.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.Payload)
                return object;
            let message = new $root.gateway.Payload();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            switch (object.act) {
            default:
                if (typeof object.act === "number") {
                    message.act = object.act;
                    break;
                }
                break;
            case "ActionForward":
            case 0:
                message.act = 0;
                break;
            case "ActionSCHi":
            case 1:
                message.act = 1;
                break;
            case "ActionCSSendMsg":
            case 2:
                message.act = 2;
                break;
            case "ActionSCSendMsgACK":
            case 3:
                message.act = 3;
                break;
            case "ActionSCRecvMsg":
            case 4:
                message.act = 4;
                break;
            case "ActionCSRecvMsgACK":
            case 5:
                message.act = 5;
                break;
            case "ActionCSReadMsg":
            case 6:
                message.act = 6;
                break;
            case "ActionSCReadMsg":
            case 7:
                message.act = 7;
                break;
            case "ActionCSDeleteMsg":
            case 8:
                message.act = 8;
                break;
            case "ActionSCDeleteMsgACK":
            case 9:
                message.act = 9;
                break;
            case "ActionSCDeleteMsg":
            case 10:
                message.act = 10;
                break;
            case "ActionCSEditMsg":
            case 11:
                message.act = 11;
                break;
            case "ActionSCEditMsgACK":
            case 12:
                message.act = 12;
                break;
            case "ActionSCEditMsg":
            case 13:
                message.act = 13;
                break;
            case "ActionInputtingBegin":
            case 14:
                message.act = 14;
                break;
            case "ActionInputtingEnd":
            case 15:
                message.act = 15;
                break;
            case "ActionSCChatChanged":
            case 16:
                message.act = 16;
                break;
            case "ActionSCWorkerChanged":
            case 17:
                message.act = 17;
                break;
            case "ActionSCUserConnectionChanged":
            case 18:
                message.act = 18;
                break;
            case "ActionSCSimSend":
            case 19:
                message.act = 19;
                break;
            }
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a Payload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.Payload
         * @static
         * @param {gateway.Payload} message Payload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Payload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.act = options.enums === String ? "ActionForward" : 0;
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.act != null && message.hasOwnProperty("act"))
                object.act = options.enums === String ? $root.gateway.Action[message.act] === undefined ? message.act : $root.gateway.Action[message.act] : message.act;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this Payload to JSON.
         * @function toJSON
         * @memberof gateway.Payload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Payload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Payload
         * @function getTypeUrl
         * @memberof gateway.Payload
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Payload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.Payload";
        };

        return Payload;
    })();

    gateway.PubSubMessage = (function() {

        /**
         * Properties of a PubSubMessage.
         * @memberof gateway
         * @interface IPubSubMessage
         * @property {Long|null} [target] PubSubMessage target
         * @property {api.common.IMessage|null} [msg] PubSubMessage msg
         */

        /**
         * Constructs a new PubSubMessage.
         * @memberof gateway
         * @classdesc Represents a PubSubMessage.
         * @implements IPubSubMessage
         * @constructor
         * @param {gateway.IPubSubMessage=} [properties] Properties to set
         */
        function PubSubMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PubSubMessage target.
         * @member {Long} target
         * @memberof gateway.PubSubMessage
         * @instance
         */
        PubSubMessage.prototype.target = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PubSubMessage msg.
         * @member {api.common.IMessage|null|undefined} msg
         * @memberof gateway.PubSubMessage
         * @instance
         */
        PubSubMessage.prototype.msg = null;

        /**
         * Creates a new PubSubMessage instance using the specified properties.
         * @function create
         * @memberof gateway.PubSubMessage
         * @static
         * @param {gateway.IPubSubMessage=} [properties] Properties to set
         * @returns {gateway.PubSubMessage} PubSubMessage instance
         */
        PubSubMessage.create = function create(properties) {
            return new PubSubMessage(properties);
        };

        /**
         * Encodes the specified PubSubMessage message. Does not implicitly {@link gateway.PubSubMessage.verify|verify} messages.
         * @function encode
         * @memberof gateway.PubSubMessage
         * @static
         * @param {gateway.IPubSubMessage} message PubSubMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PubSubMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.target);
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                $root.api.common.Message.encode(message.msg, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PubSubMessage message, length delimited. Does not implicitly {@link gateway.PubSubMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof gateway.PubSubMessage
         * @static
         * @param {gateway.IPubSubMessage} message PubSubMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PubSubMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PubSubMessage message from the specified reader or buffer.
         * @function decode
         * @memberof gateway.PubSubMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {gateway.PubSubMessage} PubSubMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PubSubMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.gateway.PubSubMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.target = reader.int64();
                        break;
                    }
                case 2: {
                        message.msg = $root.api.common.Message.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PubSubMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof gateway.PubSubMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {gateway.PubSubMessage} PubSubMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PubSubMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PubSubMessage message.
         * @function verify
         * @memberof gateway.PubSubMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PubSubMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isInteger(message.target) && !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high)))
                    return "target: integer|Long expected";
            if (message.msg != null && message.hasOwnProperty("msg")) {
                let error = $root.api.common.Message.verify(message.msg);
                if (error)
                    return "msg." + error;
            }
            return null;
        };

        /**
         * Creates a PubSubMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof gateway.PubSubMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {gateway.PubSubMessage} PubSubMessage
         */
        PubSubMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.gateway.PubSubMessage)
                return object;
            let message = new $root.gateway.PubSubMessage();
            if (object.target != null)
                if ($util.Long)
                    (message.target = $util.Long.fromValue(object.target)).unsigned = false;
                else if (typeof object.target === "string")
                    message.target = parseInt(object.target, 10);
                else if (typeof object.target === "number")
                    message.target = object.target;
                else if (typeof object.target === "object")
                    message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
            if (object.msg != null) {
                if (typeof object.msg !== "object")
                    throw TypeError(".gateway.PubSubMessage.msg: object expected");
                message.msg = $root.api.common.Message.fromObject(object.msg);
            }
            return message;
        };

        /**
         * Creates a plain object from a PubSubMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof gateway.PubSubMessage
         * @static
         * @param {gateway.PubSubMessage} message PubSubMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PubSubMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.target = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.target = options.longs === String ? "0" : 0;
                object.msg = null;
            }
            if (message.target != null && message.hasOwnProperty("target"))
                if (typeof message.target === "number")
                    object.target = options.longs === String ? String(message.target) : message.target;
                else
                    object.target = options.longs === String ? $util.Long.prototype.toString.call(message.target) : options.longs === Number ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber() : message.target;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = $root.api.common.Message.toObject(message.msg, options);
            return object;
        };

        /**
         * Converts this PubSubMessage to JSON.
         * @function toJSON
         * @memberof gateway.PubSubMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PubSubMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PubSubMessage
         * @function getTypeUrl
         * @memberof gateway.PubSubMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PubSubMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/gateway.PubSubMessage";
        };

        return PubSubMessage;
    })();

    return gateway;
})();

export const api = $root.api = (() => {

    /**
     * Namespace api.
     * @exports api
     * @namespace
     */
    const api = {};

    api.common = (function() {

        /**
         * Namespace common.
         * @memberof api
         * @namespace
         */
        const common = {};

        /**
         * ChatState enum.
         * @name api.common.ChatState
         * @enum {number}
         * @property {number} CHAT_STATE_COMMON=0 CHAT_STATE_COMMON value
         * @property {number} CHAT_STATE_TRANSFER=1 CHAT_STATE_TRANSFER value
         * @property {number} CHAT_STATE_UNPROCESSED_3MIN=2 CHAT_STATE_UNPROCESSED_3MIN value
         * @property {number} CHAT_STATE_TIMEOUT=3 CHAT_STATE_TIMEOUT value
         * @property {number} CHAT_STATE_PROCESSED=4 CHAT_STATE_PROCESSED value
         * @property {number} CHAT_STATE_BLACKLIST_APPLY=5 CHAT_STATE_BLACKLIST_APPLY value
         * @property {number} CHAT_STATE_BLACKLIST_CONFIRMED=6 CHAT_STATE_BLACKLIST_CONFIRMED value
         */
        common.ChatState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CHAT_STATE_COMMON"] = 0;
            values[valuesById[1] = "CHAT_STATE_TRANSFER"] = 1;
            values[valuesById[2] = "CHAT_STATE_UNPROCESSED_3MIN"] = 2;
            values[valuesById[3] = "CHAT_STATE_TIMEOUT"] = 3;
            values[valuesById[4] = "CHAT_STATE_PROCESSED"] = 4;
            values[valuesById[5] = "CHAT_STATE_BLACKLIST_APPLY"] = 5;
            values[valuesById[6] = "CHAT_STATE_BLACKLIST_CONFIRMED"] = 6;
            return values;
        })();

        /**
         * MessageFormat enum.
         * @name api.common.MessageFormat
         * @enum {number}
         * @property {number} MSG_TEXT=0 MSG_TEXT value
         * @property {number} MSG_IMG=1 MSG_IMG value
         * @property {number} MSG_VOICE=2 MSG_VOICE value
         * @property {number} MSG_VIDEO=3 MSG_VIDEO value
         * @property {number} MSG_GEO=4 MSG_GEO value
         * @property {number} MSG_FILE=6 MSG_FILE value
         */
        common.MessageFormat = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MSG_TEXT"] = 0;
            values[valuesById[1] = "MSG_IMG"] = 1;
            values[valuesById[2] = "MSG_VOICE"] = 2;
            values[valuesById[3] = "MSG_VIDEO"] = 3;
            values[valuesById[4] = "MSG_GEO"] = 4;
            values[valuesById[6] = "MSG_FILE"] = 6;
            return values;
        })();

        /**
         * MessageRole enum.
         * @name api.common.MessageRole
         * @enum {number}
         * @property {number} MSG_ROLE_SYSTEM=0 MSG_ROLE_SYSTEM value
         * @property {number} MSG_ROLE_WORKER=1 MSG_ROLE_WORKER value
         * @property {number} MSG_ROLE_CUSTOMER=2 MSG_ROLE_CUSTOMER value
         * @property {number} MSG_ROLE_ANONYMOUS=3 MSG_ROLE_ANONYMOUS value
         */
        common.MessageRole = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MSG_ROLE_SYSTEM"] = 0;
            values[valuesById[1] = "MSG_ROLE_WORKER"] = 1;
            values[valuesById[2] = "MSG_ROLE_CUSTOMER"] = 2;
            values[valuesById[3] = "MSG_ROLE_ANONYMOUS"] = 3;
            return values;
        })();

        common.MessageContent = (function() {

            /**
             * Properties of a MessageContent.
             * @memberof api.common
             * @interface IMessageContent
             * @property {string|null} [data] MessageContent data
             */

            /**
             * Constructs a new MessageContent.
             * @memberof api.common
             * @classdesc Represents a MessageContent.
             * @implements IMessageContent
             * @constructor
             * @param {api.common.IMessageContent=} [properties] Properties to set
             */
            function MessageContent(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageContent data.
             * @member {string} data
             * @memberof api.common.MessageContent
             * @instance
             */
            MessageContent.prototype.data = "";

            /**
             * Creates a new MessageContent instance using the specified properties.
             * @function create
             * @memberof api.common.MessageContent
             * @static
             * @param {api.common.IMessageContent=} [properties] Properties to set
             * @returns {api.common.MessageContent} MessageContent instance
             */
            MessageContent.create = function create(properties) {
                return new MessageContent(properties);
            };

            /**
             * Encodes the specified MessageContent message. Does not implicitly {@link api.common.MessageContent.verify|verify} messages.
             * @function encode
             * @memberof api.common.MessageContent
             * @static
             * @param {api.common.IMessageContent} message MessageContent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageContent.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.data);
                return writer;
            };

            /**
             * Encodes the specified MessageContent message, length delimited. Does not implicitly {@link api.common.MessageContent.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.MessageContent
             * @static
             * @param {api.common.IMessageContent} message MessageContent message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageContent.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageContent message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.MessageContent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.MessageContent} MessageContent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageContent.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.MessageContent();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.data = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageContent message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.MessageContent
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.MessageContent} MessageContent
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageContent.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageContent message.
             * @function verify
             * @memberof api.common.MessageContent
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageContent.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.data != null && message.hasOwnProperty("data"))
                    if (!$util.isString(message.data))
                        return "data: string expected";
                return null;
            };

            /**
             * Creates a MessageContent message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.MessageContent
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.MessageContent} MessageContent
             */
            MessageContent.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.MessageContent)
                    return object;
                let message = new $root.api.common.MessageContent();
                if (object.data != null)
                    message.data = String(object.data);
                return message;
            };

            /**
             * Creates a plain object from a MessageContent message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.MessageContent
             * @static
             * @param {api.common.MessageContent} message MessageContent
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageContent.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.data = "";
                if (message.data != null && message.hasOwnProperty("data"))
                    object.data = message.data;
                return object;
            };

            /**
             * Converts this MessageContent to JSON.
             * @function toJSON
             * @memberof api.common.MessageContent
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageContent.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageContent
             * @function getTypeUrl
             * @memberof api.common.MessageContent
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageContent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.MessageContent";
            };

            return MessageContent;
        })();

        common.MessageImage = (function() {

            /**
             * Properties of a MessageImage.
             * @memberof api.common
             * @interface IMessageImage
             * @property {string|null} [uri] MessageImage uri
             */

            /**
             * Constructs a new MessageImage.
             * @memberof api.common
             * @classdesc Represents a MessageImage.
             * @implements IMessageImage
             * @constructor
             * @param {api.common.IMessageImage=} [properties] Properties to set
             */
            function MessageImage(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageImage uri.
             * @member {string} uri
             * @memberof api.common.MessageImage
             * @instance
             */
            MessageImage.prototype.uri = "";

            /**
             * Creates a new MessageImage instance using the specified properties.
             * @function create
             * @memberof api.common.MessageImage
             * @static
             * @param {api.common.IMessageImage=} [properties] Properties to set
             * @returns {api.common.MessageImage} MessageImage instance
             */
            MessageImage.create = function create(properties) {
                return new MessageImage(properties);
            };

            /**
             * Encodes the specified MessageImage message. Does not implicitly {@link api.common.MessageImage.verify|verify} messages.
             * @function encode
             * @memberof api.common.MessageImage
             * @static
             * @param {api.common.IMessageImage} message MessageImage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageImage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uri != null && Object.hasOwnProperty.call(message, "uri"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uri);
                return writer;
            };

            /**
             * Encodes the specified MessageImage message, length delimited. Does not implicitly {@link api.common.MessageImage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.MessageImage
             * @static
             * @param {api.common.IMessageImage} message MessageImage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageImage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageImage message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.MessageImage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.MessageImage} MessageImage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageImage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.MessageImage();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uri = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageImage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.MessageImage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.MessageImage} MessageImage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageImage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageImage message.
             * @function verify
             * @memberof api.common.MessageImage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageImage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    if (!$util.isString(message.uri))
                        return "uri: string expected";
                return null;
            };

            /**
             * Creates a MessageImage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.MessageImage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.MessageImage} MessageImage
             */
            MessageImage.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.MessageImage)
                    return object;
                let message = new $root.api.common.MessageImage();
                if (object.uri != null)
                    message.uri = String(object.uri);
                return message;
            };

            /**
             * Creates a plain object from a MessageImage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.MessageImage
             * @static
             * @param {api.common.MessageImage} message MessageImage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageImage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.uri = "";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    object.uri = message.uri;
                return object;
            };

            /**
             * Converts this MessageImage to JSON.
             * @function toJSON
             * @memberof api.common.MessageImage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageImage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageImage
             * @function getTypeUrl
             * @memberof api.common.MessageImage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageImage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.MessageImage";
            };

            return MessageImage;
        })();

        common.MessageAudio = (function() {

            /**
             * Properties of a MessageAudio.
             * @memberof api.common
             * @interface IMessageAudio
             * @property {string|null} [uri] MessageAudio uri
             */

            /**
             * Constructs a new MessageAudio.
             * @memberof api.common
             * @classdesc Represents a MessageAudio.
             * @implements IMessageAudio
             * @constructor
             * @param {api.common.IMessageAudio=} [properties] Properties to set
             */
            function MessageAudio(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageAudio uri.
             * @member {string} uri
             * @memberof api.common.MessageAudio
             * @instance
             */
            MessageAudio.prototype.uri = "";

            /**
             * Creates a new MessageAudio instance using the specified properties.
             * @function create
             * @memberof api.common.MessageAudio
             * @static
             * @param {api.common.IMessageAudio=} [properties] Properties to set
             * @returns {api.common.MessageAudio} MessageAudio instance
             */
            MessageAudio.create = function create(properties) {
                return new MessageAudio(properties);
            };

            /**
             * Encodes the specified MessageAudio message. Does not implicitly {@link api.common.MessageAudio.verify|verify} messages.
             * @function encode
             * @memberof api.common.MessageAudio
             * @static
             * @param {api.common.IMessageAudio} message MessageAudio message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageAudio.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uri != null && Object.hasOwnProperty.call(message, "uri"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uri);
                return writer;
            };

            /**
             * Encodes the specified MessageAudio message, length delimited. Does not implicitly {@link api.common.MessageAudio.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.MessageAudio
             * @static
             * @param {api.common.IMessageAudio} message MessageAudio message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageAudio.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageAudio message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.MessageAudio
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.MessageAudio} MessageAudio
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageAudio.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.MessageAudio();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uri = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageAudio message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.MessageAudio
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.MessageAudio} MessageAudio
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageAudio.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageAudio message.
             * @function verify
             * @memberof api.common.MessageAudio
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageAudio.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    if (!$util.isString(message.uri))
                        return "uri: string expected";
                return null;
            };

            /**
             * Creates a MessageAudio message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.MessageAudio
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.MessageAudio} MessageAudio
             */
            MessageAudio.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.MessageAudio)
                    return object;
                let message = new $root.api.common.MessageAudio();
                if (object.uri != null)
                    message.uri = String(object.uri);
                return message;
            };

            /**
             * Creates a plain object from a MessageAudio message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.MessageAudio
             * @static
             * @param {api.common.MessageAudio} message MessageAudio
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageAudio.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.uri = "";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    object.uri = message.uri;
                return object;
            };

            /**
             * Converts this MessageAudio to JSON.
             * @function toJSON
             * @memberof api.common.MessageAudio
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageAudio.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageAudio
             * @function getTypeUrl
             * @memberof api.common.MessageAudio
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageAudio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.MessageAudio";
            };

            return MessageAudio;
        })();

        common.MessageVideo = (function() {

            /**
             * Properties of a MessageVideo.
             * @memberof api.common
             * @interface IMessageVideo
             * @property {string|null} [uri] MessageVideo uri
             */

            /**
             * Constructs a new MessageVideo.
             * @memberof api.common
             * @classdesc Represents a MessageVideo.
             * @implements IMessageVideo
             * @constructor
             * @param {api.common.IMessageVideo=} [properties] Properties to set
             */
            function MessageVideo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageVideo uri.
             * @member {string} uri
             * @memberof api.common.MessageVideo
             * @instance
             */
            MessageVideo.prototype.uri = "";

            /**
             * Creates a new MessageVideo instance using the specified properties.
             * @function create
             * @memberof api.common.MessageVideo
             * @static
             * @param {api.common.IMessageVideo=} [properties] Properties to set
             * @returns {api.common.MessageVideo} MessageVideo instance
             */
            MessageVideo.create = function create(properties) {
                return new MessageVideo(properties);
            };

            /**
             * Encodes the specified MessageVideo message. Does not implicitly {@link api.common.MessageVideo.verify|verify} messages.
             * @function encode
             * @memberof api.common.MessageVideo
             * @static
             * @param {api.common.IMessageVideo} message MessageVideo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageVideo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uri != null && Object.hasOwnProperty.call(message, "uri"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uri);
                return writer;
            };

            /**
             * Encodes the specified MessageVideo message, length delimited. Does not implicitly {@link api.common.MessageVideo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.MessageVideo
             * @static
             * @param {api.common.IMessageVideo} message MessageVideo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageVideo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageVideo message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.MessageVideo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.MessageVideo} MessageVideo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageVideo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.MessageVideo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uri = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageVideo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.MessageVideo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.MessageVideo} MessageVideo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageVideo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageVideo message.
             * @function verify
             * @memberof api.common.MessageVideo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageVideo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    if (!$util.isString(message.uri))
                        return "uri: string expected";
                return null;
            };

            /**
             * Creates a MessageVideo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.MessageVideo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.MessageVideo} MessageVideo
             */
            MessageVideo.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.MessageVideo)
                    return object;
                let message = new $root.api.common.MessageVideo();
                if (object.uri != null)
                    message.uri = String(object.uri);
                return message;
            };

            /**
             * Creates a plain object from a MessageVideo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.MessageVideo
             * @static
             * @param {api.common.MessageVideo} message MessageVideo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageVideo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.uri = "";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    object.uri = message.uri;
                return object;
            };

            /**
             * Converts this MessageVideo to JSON.
             * @function toJSON
             * @memberof api.common.MessageVideo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageVideo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageVideo
             * @function getTypeUrl
             * @memberof api.common.MessageVideo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageVideo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.MessageVideo";
            };

            return MessageVideo;
        })();

        common.MessageGeo = (function() {

            /**
             * Properties of a MessageGeo.
             * @memberof api.common
             * @interface IMessageGeo
             * @property {string|null} [longitude] MessageGeo longitude
             * @property {string|null} [latitude] MessageGeo latitude
             */

            /**
             * Constructs a new MessageGeo.
             * @memberof api.common
             * @classdesc Represents a MessageGeo.
             * @implements IMessageGeo
             * @constructor
             * @param {api.common.IMessageGeo=} [properties] Properties to set
             */
            function MessageGeo(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageGeo longitude.
             * @member {string} longitude
             * @memberof api.common.MessageGeo
             * @instance
             */
            MessageGeo.prototype.longitude = "";

            /**
             * MessageGeo latitude.
             * @member {string} latitude
             * @memberof api.common.MessageGeo
             * @instance
             */
            MessageGeo.prototype.latitude = "";

            /**
             * Creates a new MessageGeo instance using the specified properties.
             * @function create
             * @memberof api.common.MessageGeo
             * @static
             * @param {api.common.IMessageGeo=} [properties] Properties to set
             * @returns {api.common.MessageGeo} MessageGeo instance
             */
            MessageGeo.create = function create(properties) {
                return new MessageGeo(properties);
            };

            /**
             * Encodes the specified MessageGeo message. Does not implicitly {@link api.common.MessageGeo.verify|verify} messages.
             * @function encode
             * @memberof api.common.MessageGeo
             * @static
             * @param {api.common.IMessageGeo} message MessageGeo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageGeo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.longitude);
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.latitude);
                return writer;
            };

            /**
             * Encodes the specified MessageGeo message, length delimited. Does not implicitly {@link api.common.MessageGeo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.MessageGeo
             * @static
             * @param {api.common.IMessageGeo} message MessageGeo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageGeo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageGeo message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.MessageGeo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.MessageGeo} MessageGeo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageGeo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.MessageGeo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.longitude = reader.string();
                            break;
                        }
                    case 2: {
                            message.latitude = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageGeo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.MessageGeo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.MessageGeo} MessageGeo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageGeo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageGeo message.
             * @function verify
             * @memberof api.common.MessageGeo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageGeo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    if (!$util.isString(message.longitude))
                        return "longitude: string expected";
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    if (!$util.isString(message.latitude))
                        return "latitude: string expected";
                return null;
            };

            /**
             * Creates a MessageGeo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.MessageGeo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.MessageGeo} MessageGeo
             */
            MessageGeo.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.MessageGeo)
                    return object;
                let message = new $root.api.common.MessageGeo();
                if (object.longitude != null)
                    message.longitude = String(object.longitude);
                if (object.latitude != null)
                    message.latitude = String(object.latitude);
                return message;
            };

            /**
             * Creates a plain object from a MessageGeo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.MessageGeo
             * @static
             * @param {api.common.MessageGeo} message MessageGeo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageGeo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.longitude = "";
                    object.latitude = "";
                }
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = message.longitude;
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = message.latitude;
                return object;
            };

            /**
             * Converts this MessageGeo to JSON.
             * @function toJSON
             * @memberof api.common.MessageGeo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageGeo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageGeo
             * @function getTypeUrl
             * @memberof api.common.MessageGeo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageGeo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.MessageGeo";
            };

            return MessageGeo;
        })();

        common.MessageFile = (function() {

            /**
             * Properties of a MessageFile.
             * @memberof api.common
             * @interface IMessageFile
             * @property {string|null} [uri] MessageFile uri
             * @property {string|null} [fileName] MessageFile fileName
             * @property {number|null} [size] MessageFile size
             */

            /**
             * Constructs a new MessageFile.
             * @memberof api.common
             * @classdesc Represents a MessageFile.
             * @implements IMessageFile
             * @constructor
             * @param {api.common.IMessageFile=} [properties] Properties to set
             */
            function MessageFile(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageFile uri.
             * @member {string} uri
             * @memberof api.common.MessageFile
             * @instance
             */
            MessageFile.prototype.uri = "";

            /**
             * MessageFile fileName.
             * @member {string} fileName
             * @memberof api.common.MessageFile
             * @instance
             */
            MessageFile.prototype.fileName = "";

            /**
             * MessageFile size.
             * @member {number} size
             * @memberof api.common.MessageFile
             * @instance
             */
            MessageFile.prototype.size = 0;

            /**
             * Creates a new MessageFile instance using the specified properties.
             * @function create
             * @memberof api.common.MessageFile
             * @static
             * @param {api.common.IMessageFile=} [properties] Properties to set
             * @returns {api.common.MessageFile} MessageFile instance
             */
            MessageFile.create = function create(properties) {
                return new MessageFile(properties);
            };

            /**
             * Encodes the specified MessageFile message. Does not implicitly {@link api.common.MessageFile.verify|verify} messages.
             * @function encode
             * @memberof api.common.MessageFile
             * @static
             * @param {api.common.IMessageFile} message MessageFile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageFile.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uri != null && Object.hasOwnProperty.call(message, "uri"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uri);
                if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileName);
                if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.size);
                return writer;
            };

            /**
             * Encodes the specified MessageFile message, length delimited. Does not implicitly {@link api.common.MessageFile.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.MessageFile
             * @static
             * @param {api.common.IMessageFile} message MessageFile message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageFile.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageFile message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.MessageFile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.MessageFile} MessageFile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageFile.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.MessageFile();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uri = reader.string();
                            break;
                        }
                    case 2: {
                            message.fileName = reader.string();
                            break;
                        }
                    case 3: {
                            message.size = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageFile message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.MessageFile
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.MessageFile} MessageFile
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageFile.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageFile message.
             * @function verify
             * @memberof api.common.MessageFile
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageFile.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    if (!$util.isString(message.uri))
                        return "uri: string expected";
                if (message.fileName != null && message.hasOwnProperty("fileName"))
                    if (!$util.isString(message.fileName))
                        return "fileName: string expected";
                if (message.size != null && message.hasOwnProperty("size"))
                    if (!$util.isInteger(message.size))
                        return "size: integer expected";
                return null;
            };

            /**
             * Creates a MessageFile message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.MessageFile
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.MessageFile} MessageFile
             */
            MessageFile.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.MessageFile)
                    return object;
                let message = new $root.api.common.MessageFile();
                if (object.uri != null)
                    message.uri = String(object.uri);
                if (object.fileName != null)
                    message.fileName = String(object.fileName);
                if (object.size != null)
                    message.size = object.size | 0;
                return message;
            };

            /**
             * Creates a plain object from a MessageFile message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.MessageFile
             * @static
             * @param {api.common.MessageFile} message MessageFile
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageFile.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.uri = "";
                    object.fileName = "";
                    object.size = 0;
                }
                if (message.uri != null && message.hasOwnProperty("uri"))
                    object.uri = message.uri;
                if (message.fileName != null && message.hasOwnProperty("fileName"))
                    object.fileName = message.fileName;
                if (message.size != null && message.hasOwnProperty("size"))
                    object.size = message.size;
                return object;
            };

            /**
             * Converts this MessageFile to JSON.
             * @function toJSON
             * @memberof api.common.MessageFile
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageFile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageFile
             * @function getTypeUrl
             * @memberof api.common.MessageFile
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.MessageFile";
            };

            return MessageFile;
        })();

        /**
         * MessageOperate enum.
         * @name api.common.MessageOperate
         * @enum {number}
         * @property {number} MSG_OP_POST=0 MSG_OP_POST value
         * @property {number} MSG_OP_EDIT=1 MSG_OP_EDIT value
         * @property {number} MSG_OP_DELETE=2 MSG_OP_DELETE value
         */
        common.MessageOperate = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MSG_OP_POST"] = 0;
            values[valuesById[1] = "MSG_OP_EDIT"] = 1;
            values[valuesById[2] = "MSG_OP_DELETE"] = 2;
            return values;
        })();

        common.MessageKey = (function() {

            /**
             * Properties of a MessageKey.
             * @memberof api.common
             * @interface IMessageKey
             * @property {Long|null} [chatId] MessageKey chatId
             * @property {Long|null} [msgId] MessageKey msgId
             */

            /**
             * Constructs a new MessageKey.
             * @memberof api.common
             * @classdesc Represents a MessageKey.
             * @implements IMessageKey
             * @constructor
             * @param {api.common.IMessageKey=} [properties] Properties to set
             */
            function MessageKey(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageKey chatId.
             * @member {Long} chatId
             * @memberof api.common.MessageKey
             * @instance
             */
            MessageKey.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MessageKey msgId.
             * @member {Long} msgId
             * @memberof api.common.MessageKey
             * @instance
             */
            MessageKey.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new MessageKey instance using the specified properties.
             * @function create
             * @memberof api.common.MessageKey
             * @static
             * @param {api.common.IMessageKey=} [properties] Properties to set
             * @returns {api.common.MessageKey} MessageKey instance
             */
            MessageKey.create = function create(properties) {
                return new MessageKey(properties);
            };

            /**
             * Encodes the specified MessageKey message. Does not implicitly {@link api.common.MessageKey.verify|verify} messages.
             * @function encode
             * @memberof api.common.MessageKey
             * @static
             * @param {api.common.IMessageKey} message MessageKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageKey.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.msgId);
                return writer;
            };

            /**
             * Encodes the specified MessageKey message, length delimited. Does not implicitly {@link api.common.MessageKey.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.MessageKey
             * @static
             * @param {api.common.IMessageKey} message MessageKey message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageKey.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageKey message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.MessageKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.MessageKey} MessageKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageKey.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.MessageKey();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.msgId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageKey message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.MessageKey
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.MessageKey} MessageKey
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageKey.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageKey message.
             * @function verify
             * @memberof api.common.MessageKey
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageKey.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                        return "msgId: integer|Long expected";
                return null;
            };

            /**
             * Creates a MessageKey message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.MessageKey
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.MessageKey} MessageKey
             */
            MessageKey.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.MessageKey)
                    return object;
                let message = new $root.api.common.MessageKey();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.msgId != null)
                    if ($util.Long)
                        (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                    else if (typeof object.msgId === "string")
                        message.msgId = parseInt(object.msgId, 10);
                    else if (typeof object.msgId === "number")
                        message.msgId = object.msgId;
                    else if (typeof object.msgId === "object")
                        message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a MessageKey message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.MessageKey
             * @static
             * @param {api.common.MessageKey} message MessageKey
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageKey.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.msgId = options.longs === String ? "0" : 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (typeof message.msgId === "number")
                        object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                    else
                        object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
                return object;
            };

            /**
             * Converts this MessageKey to JSON.
             * @function toJSON
             * @memberof api.common.MessageKey
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageKey.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageKey
             * @function getTypeUrl
             * @memberof api.common.MessageKey
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.MessageKey";
            };

            return MessageKey;
        })();

        common.MessageAutoReplyFlag = (function() {

            /**
             * Properties of a MessageAutoReplyFlag.
             * @memberof api.common
             * @interface IMessageAutoReplyFlag
             * @property {Long|null} [id] MessageAutoReplyFlag id
             * @property {number|null} [qaId] MessageAutoReplyFlag qaId
             */

            /**
             * Constructs a new MessageAutoReplyFlag.
             * @memberof api.common
             * @classdesc Represents a MessageAutoReplyFlag.
             * @implements IMessageAutoReplyFlag
             * @constructor
             * @param {api.common.IMessageAutoReplyFlag=} [properties] Properties to set
             */
            function MessageAutoReplyFlag(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageAutoReplyFlag id.
             * @member {Long} id
             * @memberof api.common.MessageAutoReplyFlag
             * @instance
             */
            MessageAutoReplyFlag.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MessageAutoReplyFlag qaId.
             * @member {number} qaId
             * @memberof api.common.MessageAutoReplyFlag
             * @instance
             */
            MessageAutoReplyFlag.prototype.qaId = 0;

            /**
             * Creates a new MessageAutoReplyFlag instance using the specified properties.
             * @function create
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {api.common.IMessageAutoReplyFlag=} [properties] Properties to set
             * @returns {api.common.MessageAutoReplyFlag} MessageAutoReplyFlag instance
             */
            MessageAutoReplyFlag.create = function create(properties) {
                return new MessageAutoReplyFlag(properties);
            };

            /**
             * Encodes the specified MessageAutoReplyFlag message. Does not implicitly {@link api.common.MessageAutoReplyFlag.verify|verify} messages.
             * @function encode
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {api.common.IMessageAutoReplyFlag} message MessageAutoReplyFlag message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageAutoReplyFlag.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.qaId != null && Object.hasOwnProperty.call(message, "qaId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.qaId);
                return writer;
            };

            /**
             * Encodes the specified MessageAutoReplyFlag message, length delimited. Does not implicitly {@link api.common.MessageAutoReplyFlag.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {api.common.IMessageAutoReplyFlag} message MessageAutoReplyFlag message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageAutoReplyFlag.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageAutoReplyFlag message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.MessageAutoReplyFlag} MessageAutoReplyFlag
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageAutoReplyFlag.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.MessageAutoReplyFlag();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.qaId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageAutoReplyFlag message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.MessageAutoReplyFlag} MessageAutoReplyFlag
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageAutoReplyFlag.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageAutoReplyFlag message.
             * @function verify
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageAutoReplyFlag.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.qaId != null && message.hasOwnProperty("qaId"))
                    if (!$util.isInteger(message.qaId))
                        return "qaId: integer expected";
                return null;
            };

            /**
             * Creates a MessageAutoReplyFlag message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.MessageAutoReplyFlag} MessageAutoReplyFlag
             */
            MessageAutoReplyFlag.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.MessageAutoReplyFlag)
                    return object;
                let message = new $root.api.common.MessageAutoReplyFlag();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.qaId != null)
                    message.qaId = object.qaId | 0;
                return message;
            };

            /**
             * Creates a plain object from a MessageAutoReplyFlag message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {api.common.MessageAutoReplyFlag} message MessageAutoReplyFlag
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageAutoReplyFlag.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.qaId = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.qaId != null && message.hasOwnProperty("qaId"))
                    object.qaId = message.qaId;
                return object;
            };

            /**
             * Converts this MessageAutoReplyFlag to JSON.
             * @function toJSON
             * @memberof api.common.MessageAutoReplyFlag
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageAutoReplyFlag.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageAutoReplyFlag
             * @function getTypeUrl
             * @memberof api.common.MessageAutoReplyFlag
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageAutoReplyFlag.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.MessageAutoReplyFlag";
            };

            return MessageAutoReplyFlag;
        })();

        common.Message = (function() {

            /**
             * Properties of a Message.
             * @memberof api.common
             * @interface IMessage
             * @property {Long|null} [chatId] Message chatId
             * @property {Long|null} [msgId] Message msgId
             * @property {google.protobuf.ITimestamp|null} [msgTime] Message msgTime
             * @property {Long|null} [sender] Message sender
             * @property {Long|null} [replyMsgId] Message replyMsgId
             * @property {api.common.MessageOperate|null} [msgOp] Message msgOp
             * @property {number|null} [worker] Message worker
             * @property {api.common.IMessageAutoReplyFlag|null} [autoReply] Message autoReply
             * @property {api.common.IMessageContent|null} [content] Message content
             * @property {api.common.IMessageImage|null} [image] Message image
             * @property {api.common.IMessageAudio|null} [audio] Message audio
             * @property {api.common.IMessageVideo|null} [video] Message video
             * @property {api.common.IMessageGeo|null} [geo] Message geo
             * @property {api.common.IMessageFile|null} [file] Message file
             * @property {api.common.IWorkerTransfer|null} [workerTrans] Message workerTrans
             * @property {api.common.IBlackListApply|null} [blacklistApply] Message blacklistApply
             * @property {api.common.IBlackListConfirm|null} [blacklistConfirm] Message blacklistConfirm
             */

            /**
             * Constructs a new Message.
             * @memberof api.common
             * @classdesc Represents a Message.
             * @implements IMessage
             * @constructor
             * @param {api.common.IMessage=} [properties] Properties to set
             */
            function Message(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Message chatId.
             * @member {Long} chatId
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Message msgId.
             * @member {Long} msgId
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Message msgTime.
             * @member {google.protobuf.ITimestamp|null|undefined} msgTime
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.msgTime = null;

            /**
             * Message sender.
             * @member {Long} sender
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.sender = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Message replyMsgId.
             * @member {Long} replyMsgId
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.replyMsgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Message msgOp.
             * @member {api.common.MessageOperate} msgOp
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.msgOp = 0;

            /**
             * Message worker.
             * @member {number} worker
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.worker = 0;

            /**
             * Message autoReply.
             * @member {api.common.IMessageAutoReplyFlag|null|undefined} autoReply
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.autoReply = null;

            /**
             * Message content.
             * @member {api.common.IMessageContent|null|undefined} content
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.content = null;

            /**
             * Message image.
             * @member {api.common.IMessageImage|null|undefined} image
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.image = null;

            /**
             * Message audio.
             * @member {api.common.IMessageAudio|null|undefined} audio
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.audio = null;

            /**
             * Message video.
             * @member {api.common.IMessageVideo|null|undefined} video
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.video = null;

            /**
             * Message geo.
             * @member {api.common.IMessageGeo|null|undefined} geo
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.geo = null;

            /**
             * Message file.
             * @member {api.common.IMessageFile|null|undefined} file
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.file = null;

            /**
             * Message workerTrans.
             * @member {api.common.IWorkerTransfer|null|undefined} workerTrans
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.workerTrans = null;

            /**
             * Message blacklistApply.
             * @member {api.common.IBlackListApply|null|undefined} blacklistApply
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.blacklistApply = null;

            /**
             * Message blacklistConfirm.
             * @member {api.common.IBlackListConfirm|null|undefined} blacklistConfirm
             * @memberof api.common.Message
             * @instance
             */
            Message.prototype.blacklistConfirm = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * Message payload.
             * @member {"content"|"image"|"audio"|"video"|"geo"|"file"|"workerTrans"|"blacklistApply"|"blacklistConfirm"|undefined} payload
             * @memberof api.common.Message
             * @instance
             */
            Object.defineProperty(Message.prototype, "payload", {
                get: $util.oneOfGetter($oneOfFields = ["content", "image", "audio", "video", "geo", "file", "workerTrans", "blacklistApply", "blacklistConfirm"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Message instance using the specified properties.
             * @function create
             * @memberof api.common.Message
             * @static
             * @param {api.common.IMessage=} [properties] Properties to set
             * @returns {api.common.Message} Message instance
             */
            Message.create = function create(properties) {
                return new Message(properties);
            };

            /**
             * Encodes the specified Message message. Does not implicitly {@link api.common.Message.verify|verify} messages.
             * @function encode
             * @memberof api.common.Message
             * @static
             * @param {api.common.IMessage} message Message message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Message.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.msgId);
                if (message.msgTime != null && Object.hasOwnProperty.call(message, "msgTime"))
                    $root.google.protobuf.Timestamp.encode(message.msgTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.sender != null && Object.hasOwnProperty.call(message, "sender"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.sender);
                if (message.replyMsgId != null && Object.hasOwnProperty.call(message, "replyMsgId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.replyMsgId);
                if (message.msgOp != null && Object.hasOwnProperty.call(message, "msgOp"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.msgOp);
                if (message.worker != null && Object.hasOwnProperty.call(message, "worker"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.worker);
                if (message.autoReply != null && Object.hasOwnProperty.call(message, "autoReply"))
                    $root.api.common.MessageAutoReplyFlag.encode(message.autoReply, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    $root.api.common.MessageContent.encode(message.content, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
                if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                    $root.api.common.MessageImage.encode(message.image, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
                if (message.audio != null && Object.hasOwnProperty.call(message, "audio"))
                    $root.api.common.MessageAudio.encode(message.audio, writer.uint32(/* id 102, wireType 2 =*/818).fork()).ldelim();
                if (message.video != null && Object.hasOwnProperty.call(message, "video"))
                    $root.api.common.MessageVideo.encode(message.video, writer.uint32(/* id 103, wireType 2 =*/826).fork()).ldelim();
                if (message.geo != null && Object.hasOwnProperty.call(message, "geo"))
                    $root.api.common.MessageGeo.encode(message.geo, writer.uint32(/* id 104, wireType 2 =*/834).fork()).ldelim();
                if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                    $root.api.common.MessageFile.encode(message.file, writer.uint32(/* id 105, wireType 2 =*/842).fork()).ldelim();
                if (message.workerTrans != null && Object.hasOwnProperty.call(message, "workerTrans"))
                    $root.api.common.WorkerTransfer.encode(message.workerTrans, writer.uint32(/* id 106, wireType 2 =*/850).fork()).ldelim();
                if (message.blacklistApply != null && Object.hasOwnProperty.call(message, "blacklistApply"))
                    $root.api.common.BlackListApply.encode(message.blacklistApply, writer.uint32(/* id 107, wireType 2 =*/858).fork()).ldelim();
                if (message.blacklistConfirm != null && Object.hasOwnProperty.call(message, "blacklistConfirm"))
                    $root.api.common.BlackListConfirm.encode(message.blacklistConfirm, writer.uint32(/* id 108, wireType 2 =*/866).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Message message, length delimited. Does not implicitly {@link api.common.Message.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Message
             * @static
             * @param {api.common.IMessage} message Message message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Message.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Message message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Message
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Message} Message
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Message.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Message();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.msgId = reader.int64();
                            break;
                        }
                    case 3: {
                            message.msgTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.sender = reader.int64();
                            break;
                        }
                    case 5: {
                            message.replyMsgId = reader.int64();
                            break;
                        }
                    case 6: {
                            message.msgOp = reader.int32();
                            break;
                        }
                    case 7: {
                            message.worker = reader.int32();
                            break;
                        }
                    case 8: {
                            message.autoReply = $root.api.common.MessageAutoReplyFlag.decode(reader, reader.uint32());
                            break;
                        }
                    case 100: {
                            message.content = $root.api.common.MessageContent.decode(reader, reader.uint32());
                            break;
                        }
                    case 101: {
                            message.image = $root.api.common.MessageImage.decode(reader, reader.uint32());
                            break;
                        }
                    case 102: {
                            message.audio = $root.api.common.MessageAudio.decode(reader, reader.uint32());
                            break;
                        }
                    case 103: {
                            message.video = $root.api.common.MessageVideo.decode(reader, reader.uint32());
                            break;
                        }
                    case 104: {
                            message.geo = $root.api.common.MessageGeo.decode(reader, reader.uint32());
                            break;
                        }
                    case 105: {
                            message.file = $root.api.common.MessageFile.decode(reader, reader.uint32());
                            break;
                        }
                    case 106: {
                            message.workerTrans = $root.api.common.WorkerTransfer.decode(reader, reader.uint32());
                            break;
                        }
                    case 107: {
                            message.blacklistApply = $root.api.common.BlackListApply.decode(reader, reader.uint32());
                            break;
                        }
                    case 108: {
                            message.blacklistConfirm = $root.api.common.BlackListConfirm.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Message message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Message
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Message} Message
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Message.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Message message.
             * @function verify
             * @memberof api.common.Message
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Message.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                        return "msgId: integer|Long expected";
                if (message.msgTime != null && message.hasOwnProperty("msgTime")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.msgTime);
                    if (error)
                        return "msgTime." + error;
                }
                if (message.sender != null && message.hasOwnProperty("sender"))
                    if (!$util.isInteger(message.sender) && !(message.sender && $util.isInteger(message.sender.low) && $util.isInteger(message.sender.high)))
                        return "sender: integer|Long expected";
                if (message.replyMsgId != null && message.hasOwnProperty("replyMsgId"))
                    if (!$util.isInteger(message.replyMsgId) && !(message.replyMsgId && $util.isInteger(message.replyMsgId.low) && $util.isInteger(message.replyMsgId.high)))
                        return "replyMsgId: integer|Long expected";
                if (message.msgOp != null && message.hasOwnProperty("msgOp"))
                    switch (message.msgOp) {
                    default:
                        return "msgOp: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.worker != null && message.hasOwnProperty("worker"))
                    if (!$util.isInteger(message.worker))
                        return "worker: integer expected";
                if (message.autoReply != null && message.hasOwnProperty("autoReply")) {
                    let error = $root.api.common.MessageAutoReplyFlag.verify(message.autoReply);
                    if (error)
                        return "autoReply." + error;
                }
                if (message.content != null && message.hasOwnProperty("content")) {
                    properties.payload = 1;
                    {
                        let error = $root.api.common.MessageContent.verify(message.content);
                        if (error)
                            return "content." + error;
                    }
                }
                if (message.image != null && message.hasOwnProperty("image")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.api.common.MessageImage.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                }
                if (message.audio != null && message.hasOwnProperty("audio")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.api.common.MessageAudio.verify(message.audio);
                        if (error)
                            return "audio." + error;
                    }
                }
                if (message.video != null && message.hasOwnProperty("video")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.api.common.MessageVideo.verify(message.video);
                        if (error)
                            return "video." + error;
                    }
                }
                if (message.geo != null && message.hasOwnProperty("geo")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.api.common.MessageGeo.verify(message.geo);
                        if (error)
                            return "geo." + error;
                    }
                }
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.api.common.MessageFile.verify(message.file);
                        if (error)
                            return "file." + error;
                    }
                }
                if (message.workerTrans != null && message.hasOwnProperty("workerTrans")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.api.common.WorkerTransfer.verify(message.workerTrans);
                        if (error)
                            return "workerTrans." + error;
                    }
                }
                if (message.blacklistApply != null && message.hasOwnProperty("blacklistApply")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.api.common.BlackListApply.verify(message.blacklistApply);
                        if (error)
                            return "blacklistApply." + error;
                    }
                }
                if (message.blacklistConfirm != null && message.hasOwnProperty("blacklistConfirm")) {
                    if (properties.payload === 1)
                        return "payload: multiple values";
                    properties.payload = 1;
                    {
                        let error = $root.api.common.BlackListConfirm.verify(message.blacklistConfirm);
                        if (error)
                            return "blacklistConfirm." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Message message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Message
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Message} Message
             */
            Message.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Message)
                    return object;
                let message = new $root.api.common.Message();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.msgId != null)
                    if ($util.Long)
                        (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                    else if (typeof object.msgId === "string")
                        message.msgId = parseInt(object.msgId, 10);
                    else if (typeof object.msgId === "number")
                        message.msgId = object.msgId;
                    else if (typeof object.msgId === "object")
                        message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
                if (object.msgTime != null) {
                    if (typeof object.msgTime !== "object")
                        throw TypeError(".api.common.Message.msgTime: object expected");
                    message.msgTime = $root.google.protobuf.Timestamp.fromObject(object.msgTime);
                }
                if (object.sender != null)
                    if ($util.Long)
                        (message.sender = $util.Long.fromValue(object.sender)).unsigned = false;
                    else if (typeof object.sender === "string")
                        message.sender = parseInt(object.sender, 10);
                    else if (typeof object.sender === "number")
                        message.sender = object.sender;
                    else if (typeof object.sender === "object")
                        message.sender = new $util.LongBits(object.sender.low >>> 0, object.sender.high >>> 0).toNumber();
                if (object.replyMsgId != null)
                    if ($util.Long)
                        (message.replyMsgId = $util.Long.fromValue(object.replyMsgId)).unsigned = false;
                    else if (typeof object.replyMsgId === "string")
                        message.replyMsgId = parseInt(object.replyMsgId, 10);
                    else if (typeof object.replyMsgId === "number")
                        message.replyMsgId = object.replyMsgId;
                    else if (typeof object.replyMsgId === "object")
                        message.replyMsgId = new $util.LongBits(object.replyMsgId.low >>> 0, object.replyMsgId.high >>> 0).toNumber();
                switch (object.msgOp) {
                default:
                    if (typeof object.msgOp === "number") {
                        message.msgOp = object.msgOp;
                        break;
                    }
                    break;
                case "MSG_OP_POST":
                case 0:
                    message.msgOp = 0;
                    break;
                case "MSG_OP_EDIT":
                case 1:
                    message.msgOp = 1;
                    break;
                case "MSG_OP_DELETE":
                case 2:
                    message.msgOp = 2;
                    break;
                }
                if (object.worker != null)
                    message.worker = object.worker | 0;
                if (object.autoReply != null) {
                    if (typeof object.autoReply !== "object")
                        throw TypeError(".api.common.Message.autoReply: object expected");
                    message.autoReply = $root.api.common.MessageAutoReplyFlag.fromObject(object.autoReply);
                }
                if (object.content != null) {
                    if (typeof object.content !== "object")
                        throw TypeError(".api.common.Message.content: object expected");
                    message.content = $root.api.common.MessageContent.fromObject(object.content);
                }
                if (object.image != null) {
                    if (typeof object.image !== "object")
                        throw TypeError(".api.common.Message.image: object expected");
                    message.image = $root.api.common.MessageImage.fromObject(object.image);
                }
                if (object.audio != null) {
                    if (typeof object.audio !== "object")
                        throw TypeError(".api.common.Message.audio: object expected");
                    message.audio = $root.api.common.MessageAudio.fromObject(object.audio);
                }
                if (object.video != null) {
                    if (typeof object.video !== "object")
                        throw TypeError(".api.common.Message.video: object expected");
                    message.video = $root.api.common.MessageVideo.fromObject(object.video);
                }
                if (object.geo != null) {
                    if (typeof object.geo !== "object")
                        throw TypeError(".api.common.Message.geo: object expected");
                    message.geo = $root.api.common.MessageGeo.fromObject(object.geo);
                }
                if (object.file != null) {
                    if (typeof object.file !== "object")
                        throw TypeError(".api.common.Message.file: object expected");
                    message.file = $root.api.common.MessageFile.fromObject(object.file);
                }
                if (object.workerTrans != null) {
                    if (typeof object.workerTrans !== "object")
                        throw TypeError(".api.common.Message.workerTrans: object expected");
                    message.workerTrans = $root.api.common.WorkerTransfer.fromObject(object.workerTrans);
                }
                if (object.blacklistApply != null) {
                    if (typeof object.blacklistApply !== "object")
                        throw TypeError(".api.common.Message.blacklistApply: object expected");
                    message.blacklistApply = $root.api.common.BlackListApply.fromObject(object.blacklistApply);
                }
                if (object.blacklistConfirm != null) {
                    if (typeof object.blacklistConfirm !== "object")
                        throw TypeError(".api.common.Message.blacklistConfirm: object expected");
                    message.blacklistConfirm = $root.api.common.BlackListConfirm.fromObject(object.blacklistConfirm);
                }
                return message;
            };

            /**
             * Creates a plain object from a Message message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Message
             * @static
             * @param {api.common.Message} message Message
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Message.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.msgId = options.longs === String ? "0" : 0;
                    object.msgTime = null;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.sender = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.sender = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.replyMsgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.replyMsgId = options.longs === String ? "0" : 0;
                    object.msgOp = options.enums === String ? "MSG_OP_POST" : 0;
                    object.worker = 0;
                    object.autoReply = null;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (typeof message.msgId === "number")
                        object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                    else
                        object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
                if (message.msgTime != null && message.hasOwnProperty("msgTime"))
                    object.msgTime = $root.google.protobuf.Timestamp.toObject(message.msgTime, options);
                if (message.sender != null && message.hasOwnProperty("sender"))
                    if (typeof message.sender === "number")
                        object.sender = options.longs === String ? String(message.sender) : message.sender;
                    else
                        object.sender = options.longs === String ? $util.Long.prototype.toString.call(message.sender) : options.longs === Number ? new $util.LongBits(message.sender.low >>> 0, message.sender.high >>> 0).toNumber() : message.sender;
                if (message.replyMsgId != null && message.hasOwnProperty("replyMsgId"))
                    if (typeof message.replyMsgId === "number")
                        object.replyMsgId = options.longs === String ? String(message.replyMsgId) : message.replyMsgId;
                    else
                        object.replyMsgId = options.longs === String ? $util.Long.prototype.toString.call(message.replyMsgId) : options.longs === Number ? new $util.LongBits(message.replyMsgId.low >>> 0, message.replyMsgId.high >>> 0).toNumber() : message.replyMsgId;
                if (message.msgOp != null && message.hasOwnProperty("msgOp"))
                    object.msgOp = options.enums === String ? $root.api.common.MessageOperate[message.msgOp] === undefined ? message.msgOp : $root.api.common.MessageOperate[message.msgOp] : message.msgOp;
                if (message.worker != null && message.hasOwnProperty("worker"))
                    object.worker = message.worker;
                if (message.autoReply != null && message.hasOwnProperty("autoReply"))
                    object.autoReply = $root.api.common.MessageAutoReplyFlag.toObject(message.autoReply, options);
                if (message.content != null && message.hasOwnProperty("content")) {
                    object.content = $root.api.common.MessageContent.toObject(message.content, options);
                    if (options.oneofs)
                        object.payload = "content";
                }
                if (message.image != null && message.hasOwnProperty("image")) {
                    object.image = $root.api.common.MessageImage.toObject(message.image, options);
                    if (options.oneofs)
                        object.payload = "image";
                }
                if (message.audio != null && message.hasOwnProperty("audio")) {
                    object.audio = $root.api.common.MessageAudio.toObject(message.audio, options);
                    if (options.oneofs)
                        object.payload = "audio";
                }
                if (message.video != null && message.hasOwnProperty("video")) {
                    object.video = $root.api.common.MessageVideo.toObject(message.video, options);
                    if (options.oneofs)
                        object.payload = "video";
                }
                if (message.geo != null && message.hasOwnProperty("geo")) {
                    object.geo = $root.api.common.MessageGeo.toObject(message.geo, options);
                    if (options.oneofs)
                        object.payload = "geo";
                }
                if (message.file != null && message.hasOwnProperty("file")) {
                    object.file = $root.api.common.MessageFile.toObject(message.file, options);
                    if (options.oneofs)
                        object.payload = "file";
                }
                if (message.workerTrans != null && message.hasOwnProperty("workerTrans")) {
                    object.workerTrans = $root.api.common.WorkerTransfer.toObject(message.workerTrans, options);
                    if (options.oneofs)
                        object.payload = "workerTrans";
                }
                if (message.blacklistApply != null && message.hasOwnProperty("blacklistApply")) {
                    object.blacklistApply = $root.api.common.BlackListApply.toObject(message.blacklistApply, options);
                    if (options.oneofs)
                        object.payload = "blacklistApply";
                }
                if (message.blacklistConfirm != null && message.hasOwnProperty("blacklistConfirm")) {
                    object.blacklistConfirm = $root.api.common.BlackListConfirm.toObject(message.blacklistConfirm, options);
                    if (options.oneofs)
                        object.payload = "blacklistConfirm";
                }
                return object;
            };

            /**
             * Converts this Message to JSON.
             * @function toJSON
             * @memberof api.common.Message
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Message.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Message
             * @function getTypeUrl
             * @memberof api.common.Message
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Message.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Message";
            };

            return Message;
        })();

        common.WorkerTransfer = (function() {

            /**
             * Properties of a WorkerTransfer.
             * @memberof api.common
             * @interface IWorkerTransfer
             * @property {number|null} [workerId] WorkerTransfer workerId
             * @property {string|null} [workerName] WorkerTransfer workerName
             * @property {string|null} [workerAvatar] WorkerTransfer workerAvatar
             */

            /**
             * Constructs a new WorkerTransfer.
             * @memberof api.common
             * @classdesc Represents a WorkerTransfer.
             * @implements IWorkerTransfer
             * @constructor
             * @param {api.common.IWorkerTransfer=} [properties] Properties to set
             */
            function WorkerTransfer(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerTransfer workerId.
             * @member {number} workerId
             * @memberof api.common.WorkerTransfer
             * @instance
             */
            WorkerTransfer.prototype.workerId = 0;

            /**
             * WorkerTransfer workerName.
             * @member {string} workerName
             * @memberof api.common.WorkerTransfer
             * @instance
             */
            WorkerTransfer.prototype.workerName = "";

            /**
             * WorkerTransfer workerAvatar.
             * @member {string} workerAvatar
             * @memberof api.common.WorkerTransfer
             * @instance
             */
            WorkerTransfer.prototype.workerAvatar = "";

            /**
             * Creates a new WorkerTransfer instance using the specified properties.
             * @function create
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {api.common.IWorkerTransfer=} [properties] Properties to set
             * @returns {api.common.WorkerTransfer} WorkerTransfer instance
             */
            WorkerTransfer.create = function create(properties) {
                return new WorkerTransfer(properties);
            };

            /**
             * Encodes the specified WorkerTransfer message. Does not implicitly {@link api.common.WorkerTransfer.verify|verify} messages.
             * @function encode
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {api.common.IWorkerTransfer} message WorkerTransfer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerTransfer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                if (message.workerName != null && Object.hasOwnProperty.call(message, "workerName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.workerName);
                if (message.workerAvatar != null && Object.hasOwnProperty.call(message, "workerAvatar"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.workerAvatar);
                return writer;
            };

            /**
             * Encodes the specified WorkerTransfer message, length delimited. Does not implicitly {@link api.common.WorkerTransfer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {api.common.IWorkerTransfer} message WorkerTransfer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerTransfer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerTransfer message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.WorkerTransfer} WorkerTransfer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerTransfer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.WorkerTransfer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.workerName = reader.string();
                            break;
                        }
                    case 3: {
                            message.workerAvatar = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerTransfer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.WorkerTransfer} WorkerTransfer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerTransfer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerTransfer message.
             * @function verify
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerTransfer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                if (message.workerName != null && message.hasOwnProperty("workerName"))
                    if (!$util.isString(message.workerName))
                        return "workerName: string expected";
                if (message.workerAvatar != null && message.hasOwnProperty("workerAvatar"))
                    if (!$util.isString(message.workerAvatar))
                        return "workerAvatar: string expected";
                return null;
            };

            /**
             * Creates a WorkerTransfer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.WorkerTransfer} WorkerTransfer
             */
            WorkerTransfer.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.WorkerTransfer)
                    return object;
                let message = new $root.api.common.WorkerTransfer();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                if (object.workerName != null)
                    message.workerName = String(object.workerName);
                if (object.workerAvatar != null)
                    message.workerAvatar = String(object.workerAvatar);
                return message;
            };

            /**
             * Creates a plain object from a WorkerTransfer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {api.common.WorkerTransfer} message WorkerTransfer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerTransfer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.workerId = 0;
                    object.workerName = "";
                    object.workerAvatar = "";
                }
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                if (message.workerName != null && message.hasOwnProperty("workerName"))
                    object.workerName = message.workerName;
                if (message.workerAvatar != null && message.hasOwnProperty("workerAvatar"))
                    object.workerAvatar = message.workerAvatar;
                return object;
            };

            /**
             * Converts this WorkerTransfer to JSON.
             * @function toJSON
             * @memberof api.common.WorkerTransfer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerTransfer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerTransfer
             * @function getTypeUrl
             * @memberof api.common.WorkerTransfer
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerTransfer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.WorkerTransfer";
            };

            return WorkerTransfer;
        })();

        common.BlackListApply = (function() {

            /**
             * Properties of a BlackListApply.
             * @memberof api.common
             * @interface IBlackListApply
             * @property {number|null} [workerId] BlackListApply workerId
             */

            /**
             * Constructs a new BlackListApply.
             * @memberof api.common
             * @classdesc Represents a BlackListApply.
             * @implements IBlackListApply
             * @constructor
             * @param {api.common.IBlackListApply=} [properties] Properties to set
             */
            function BlackListApply(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BlackListApply workerId.
             * @member {number} workerId
             * @memberof api.common.BlackListApply
             * @instance
             */
            BlackListApply.prototype.workerId = 0;

            /**
             * Creates a new BlackListApply instance using the specified properties.
             * @function create
             * @memberof api.common.BlackListApply
             * @static
             * @param {api.common.IBlackListApply=} [properties] Properties to set
             * @returns {api.common.BlackListApply} BlackListApply instance
             */
            BlackListApply.create = function create(properties) {
                return new BlackListApply(properties);
            };

            /**
             * Encodes the specified BlackListApply message. Does not implicitly {@link api.common.BlackListApply.verify|verify} messages.
             * @function encode
             * @memberof api.common.BlackListApply
             * @static
             * @param {api.common.IBlackListApply} message BlackListApply message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlackListApply.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified BlackListApply message, length delimited. Does not implicitly {@link api.common.BlackListApply.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.BlackListApply
             * @static
             * @param {api.common.IBlackListApply} message BlackListApply message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlackListApply.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BlackListApply message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.BlackListApply
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.BlackListApply} BlackListApply
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlackListApply.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.BlackListApply();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BlackListApply message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.BlackListApply
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.BlackListApply} BlackListApply
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlackListApply.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BlackListApply message.
             * @function verify
             * @memberof api.common.BlackListApply
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BlackListApply.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a BlackListApply message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.BlackListApply
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.BlackListApply} BlackListApply
             */
            BlackListApply.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.BlackListApply)
                    return object;
                let message = new $root.api.common.BlackListApply();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a BlackListApply message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.BlackListApply
             * @static
             * @param {api.common.BlackListApply} message BlackListApply
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BlackListApply.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.workerId = 0;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this BlackListApply to JSON.
             * @function toJSON
             * @memberof api.common.BlackListApply
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BlackListApply.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BlackListApply
             * @function getTypeUrl
             * @memberof api.common.BlackListApply
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BlackListApply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.BlackListApply";
            };

            return BlackListApply;
        })();

        common.BlackListConfirm = (function() {

            /**
             * Properties of a BlackListConfirm.
             * @memberof api.common
             * @interface IBlackListConfirm
             * @property {number|null} [workerId] BlackListConfirm workerId
             */

            /**
             * Constructs a new BlackListConfirm.
             * @memberof api.common
             * @classdesc Represents a BlackListConfirm.
             * @implements IBlackListConfirm
             * @constructor
             * @param {api.common.IBlackListConfirm=} [properties] Properties to set
             */
            function BlackListConfirm(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BlackListConfirm workerId.
             * @member {number} workerId
             * @memberof api.common.BlackListConfirm
             * @instance
             */
            BlackListConfirm.prototype.workerId = 0;

            /**
             * Creates a new BlackListConfirm instance using the specified properties.
             * @function create
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {api.common.IBlackListConfirm=} [properties] Properties to set
             * @returns {api.common.BlackListConfirm} BlackListConfirm instance
             */
            BlackListConfirm.create = function create(properties) {
                return new BlackListConfirm(properties);
            };

            /**
             * Encodes the specified BlackListConfirm message. Does not implicitly {@link api.common.BlackListConfirm.verify|verify} messages.
             * @function encode
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {api.common.IBlackListConfirm} message BlackListConfirm message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlackListConfirm.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified BlackListConfirm message, length delimited. Does not implicitly {@link api.common.BlackListConfirm.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {api.common.IBlackListConfirm} message BlackListConfirm message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlackListConfirm.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BlackListConfirm message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.BlackListConfirm} BlackListConfirm
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlackListConfirm.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.BlackListConfirm();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BlackListConfirm message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.BlackListConfirm} BlackListConfirm
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlackListConfirm.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BlackListConfirm message.
             * @function verify
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BlackListConfirm.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a BlackListConfirm message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.BlackListConfirm} BlackListConfirm
             */
            BlackListConfirm.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.BlackListConfirm)
                    return object;
                let message = new $root.api.common.BlackListConfirm();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a BlackListConfirm message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {api.common.BlackListConfirm} message BlackListConfirm
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BlackListConfirm.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.workerId = 0;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this BlackListConfirm to JSON.
             * @function toJSON
             * @memberof api.common.BlackListConfirm
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BlackListConfirm.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BlackListConfirm
             * @function getTypeUrl
             * @memberof api.common.BlackListConfirm
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BlackListConfirm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.BlackListConfirm";
            };

            return BlackListConfirm;
        })();

        common.Asset = (function() {

            /**
             * Properties of an Asset.
             * @memberof api.common
             * @interface IAsset
             * @property {string|null} [uri] Asset uri
             */

            /**
             * Constructs a new Asset.
             * @memberof api.common
             * @classdesc Represents an Asset.
             * @implements IAsset
             * @constructor
             * @param {api.common.IAsset=} [properties] Properties to set
             */
            function Asset(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Asset uri.
             * @member {string} uri
             * @memberof api.common.Asset
             * @instance
             */
            Asset.prototype.uri = "";

            /**
             * Creates a new Asset instance using the specified properties.
             * @function create
             * @memberof api.common.Asset
             * @static
             * @param {api.common.IAsset=} [properties] Properties to set
             * @returns {api.common.Asset} Asset instance
             */
            Asset.create = function create(properties) {
                return new Asset(properties);
            };

            /**
             * Encodes the specified Asset message. Does not implicitly {@link api.common.Asset.verify|verify} messages.
             * @function encode
             * @memberof api.common.Asset
             * @static
             * @param {api.common.IAsset} message Asset message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Asset.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uri != null && Object.hasOwnProperty.call(message, "uri"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.uri);
                return writer;
            };

            /**
             * Encodes the specified Asset message, length delimited. Does not implicitly {@link api.common.Asset.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Asset
             * @static
             * @param {api.common.IAsset} message Asset message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Asset.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Asset message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Asset
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Asset} Asset
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Asset.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Asset();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.uri = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Asset message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Asset
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Asset} Asset
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Asset.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Asset message.
             * @function verify
             * @memberof api.common.Asset
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Asset.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    if (!$util.isString(message.uri))
                        return "uri: string expected";
                return null;
            };

            /**
             * Creates an Asset message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Asset
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Asset} Asset
             */
            Asset.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Asset)
                    return object;
                let message = new $root.api.common.Asset();
                if (object.uri != null)
                    message.uri = String(object.uri);
                return message;
            };

            /**
             * Creates a plain object from an Asset message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Asset
             * @static
             * @param {api.common.Asset} message Asset
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Asset.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.uri = "";
                if (message.uri != null && message.hasOwnProperty("uri"))
                    object.uri = message.uri;
                return object;
            };

            /**
             * Converts this Asset to JSON.
             * @function toJSON
             * @memberof api.common.Asset
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Asset.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Asset
             * @function getTypeUrl
             * @memberof api.common.Asset
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Asset.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Asset";
            };

            return Asset;
        })();

        /**
         * AssetUploadState enum.
         * @name api.common.AssetUploadState
         * @enum {number}
         * @property {number} ASSET_UPLOAD_STATE_UNKNOWN=0 ASSET_UPLOAD_STATE_UNKNOWN value
         * @property {number} ASSET_UPLOAD_STATE_PUTTING=1 ASSET_UPLOAD_STATE_PUTTING value
         * @property {number} ASSET_UPLOAD_STATE_PUT=2 ASSET_UPLOAD_STATE_PUT value
         */
        common.AssetUploadState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ASSET_UPLOAD_STATE_UNKNOWN"] = 0;
            values[valuesById[1] = "ASSET_UPLOAD_STATE_PUTTING"] = 1;
            values[valuesById[2] = "ASSET_UPLOAD_STATE_PUT"] = 2;
            return values;
        })();

        /**
         * AssetKind enum.
         * @name api.common.AssetKind
         * @enum {number}
         * @property {number} ASSET_KIND_NONE=0 ASSET_KIND_NONE value
         * @property {number} ASSET_KIND_PUBLIC=1 ASSET_KIND_PUBLIC value
         * @property {number} ASSET_KIND_PRIVATE=2 ASSET_KIND_PRIVATE value
         * @property {number} ASSET_KIND_AVATAR=3 ASSET_KIND_AVATAR value
         * @property {number} ASSET_KIND_SESSION=4 ASSET_KIND_SESSION value
         */
        common.AssetKind = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ASSET_KIND_NONE"] = 0;
            values[valuesById[1] = "ASSET_KIND_PUBLIC"] = 1;
            values[valuesById[2] = "ASSET_KIND_PRIVATE"] = 2;
            values[valuesById[3] = "ASSET_KIND_AVATAR"] = 3;
            values[valuesById[4] = "ASSET_KIND_SESSION"] = 4;
            return values;
        })();

        /**
         * AssetType enum.
         * @name api.common.AssetType
         * @enum {number}
         * @property {number} ASSET_TYPE_UNKNOWN=0 ASSET_TYPE_UNKNOWN value
         * @property {number} ASSET_TYPE_IMAGE=1 ASSET_TYPE_IMAGE value
         * @property {number} ASSET_TYPE_AUDIO=2 ASSET_TYPE_AUDIO value
         * @property {number} ASSET_TYPE_VIDEO=3 ASSET_TYPE_VIDEO value
         * @property {number} ASSET_TYPE_FILE=4 ASSET_TYPE_FILE value
         */
        common.AssetType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ASSET_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "ASSET_TYPE_IMAGE"] = 1;
            values[valuesById[2] = "ASSET_TYPE_AUDIO"] = 2;
            values[valuesById[3] = "ASSET_TYPE_VIDEO"] = 3;
            values[valuesById[4] = "ASSET_TYPE_FILE"] = 4;
            return values;
        })();

        /**
         * AssetContext enum.
         * @name api.common.AssetContext
         * @enum {number}
         * @property {number} ASSET_CONTEXT_UNKNOWN=0 ASSET_CONTEXT_UNKNOWN value
         * @property {number} ASSET_CONTEXT_MESSAGE=1 ASSET_CONTEXT_MESSAGE value
         * @property {number} ASSET_CONTEXT_MOMENT=2 ASSET_CONTEXT_MOMENT value
         * @property {number} ASSET_CONTEXT_PROFILE=3 ASSET_CONTEXT_PROFILE value
         * @property {number} ASSET_CONTEXT_FAVORITE=4 ASSET_CONTEXT_FAVORITE value
         * @property {number} ASSET_CONTEXT_EMOJI=5 ASSET_CONTEXT_EMOJI value
         * @property {number} ASSET_CONTEXT_BULLETIN=6 ASSET_CONTEXT_BULLETIN value
         */
        common.AssetContext = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ASSET_CONTEXT_UNKNOWN"] = 0;
            values[valuesById[1] = "ASSET_CONTEXT_MESSAGE"] = 1;
            values[valuesById[2] = "ASSET_CONTEXT_MOMENT"] = 2;
            values[valuesById[3] = "ASSET_CONTEXT_PROFILE"] = 3;
            values[valuesById[4] = "ASSET_CONTEXT_FAVORITE"] = 4;
            values[valuesById[5] = "ASSET_CONTEXT_EMOJI"] = 5;
            values[valuesById[6] = "ASSET_CONTEXT_BULLETIN"] = 6;
            return values;
        })();

        /**
         * DeviceType enum.
         * @name api.common.DeviceType
         * @enum {number}
         * @property {number} System=0 System value
         * @property {number} Desktop=1 Desktop value
         * @property {number} Ios=2 Ios value
         * @property {number} Android=3 Android value
         */
        common.DeviceType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "System"] = 0;
            values[valuesById[1] = "Desktop"] = 1;
            values[valuesById[2] = "Ios"] = 2;
            values[valuesById[3] = "Android"] = 3;
            return values;
        })();

        /**
         * DistributionType enum.
         * @name api.common.DistributionType
         * @enum {number}
         * @property {number} DISTRIBUTION_LEISURE=0 DISTRIBUTION_LEISURE value
         * @property {number} DISTRIBUTION_BUSY=1 DISTRIBUTION_BUSY value
         * @property {number} DISTRIBUTION_OFFLINE=2 DISTRIBUTION_OFFLINE value
         * @property {number} DISTRIBUTION_NOT_LOGIN=3 DISTRIBUTION_NOT_LOGIN value
         */
        common.DistributionType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DISTRIBUTION_LEISURE"] = 0;
            values[valuesById[1] = "DISTRIBUTION_BUSY"] = 1;
            values[valuesById[2] = "DISTRIBUTION_OFFLINE"] = 2;
            values[valuesById[3] = "DISTRIBUTION_NOT_LOGIN"] = 3;
            return values;
        })();

        /**
         * WorkerPermission enum.
         * @name api.common.WorkerPermission
         * @enum {number}
         * @property {number} WORKER_PERM_NONE=0 WORKER_PERM_NONE value
         * @property {number} WORKER_PERM_TOP=1 WORKER_PERM_TOP value
         * @property {number} WORKER_PERM_ADMIN=2 WORKER_PERM_ADMIN value
         * @property {number} WORKER_PERM_LEADER=4 WORKER_PERM_LEADER value
         * @property {number} WORKER_PERM_FRONTER=8 WORKER_PERM_FRONTER value
         * @property {number} WORKER_PERM_TRANSFER=16 WORKER_PERM_TRANSFER value
         * @property {number} WORKER_PERM_SEARCHER=32 WORKER_PERM_SEARCHER value
         * @property {number} WORKER_PERM_BLACKER=64 WORKER_PERM_BLACKER value
         */
        common.WorkerPermission = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "WORKER_PERM_NONE"] = 0;
            values[valuesById[1] = "WORKER_PERM_TOP"] = 1;
            values[valuesById[2] = "WORKER_PERM_ADMIN"] = 2;
            values[valuesById[4] = "WORKER_PERM_LEADER"] = 4;
            values[valuesById[8] = "WORKER_PERM_FRONTER"] = 8;
            values[valuesById[16] = "WORKER_PERM_TRANSFER"] = 16;
            values[valuesById[32] = "WORKER_PERM_SEARCHER"] = 32;
            values[valuesById[64] = "WORKER_PERM_BLACKER"] = 64;
            return values;
        })();

        /**
         * ConnectState enum.
         * @name api.common.ConnectState
         * @enum {number}
         * @property {number} CONNECT_STATE_OFFLINE=0 CONNECT_STATE_OFFLINE value
         * @property {number} CONNECT_STATE_ONLINE=1 CONNECT_STATE_ONLINE value
         */
        common.ConnectState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CONNECT_STATE_OFFLINE"] = 0;
            values[valuesById[1] = "CONNECT_STATE_ONLINE"] = 1;
            return values;
        })();

        /**
         * OnlineState enum.
         * @name api.common.OnlineState
         * @enum {number}
         * @property {number} ONLINE_STATE_IDLE=0 ONLINE_STATE_IDLE value
         * @property {number} ONLINE_STATE_BUSY=1 ONLINE_STATE_BUSY value
         * @property {number} ONLINE_STATE_AFK=2 ONLINE_STATE_AFK value
         */
        common.OnlineState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ONLINE_STATE_IDLE"] = 0;
            values[valuesById[1] = "ONLINE_STATE_BUSY"] = 1;
            values[valuesById[2] = "ONLINE_STATE_AFK"] = 2;
            return values;
        })();

        common.Worker = (function() {

            /**
             * Properties of a Worker.
             * @memberof api.common
             * @interface IWorker
             * @property {number|null} [workerId] Worker workerId
             * @property {string|null} [account] Worker account
             * @property {Array.<api.common.IWorkerGroup>|null} [group] Worker group
             * @property {number|null} [permMask] Worker permMask
             * @property {string|null} [name] Worker name
             * @property {string|null} [avatar] Worker avatar
             * @property {api.common.OnlineState|null} [onlineState] Worker onlineState
             * @property {string|null} [password] Worker password
             * @property {api.common.ConnectState|null} [connectState] Worker connectState
             */

            /**
             * Constructs a new Worker.
             * @memberof api.common
             * @classdesc Represents a Worker.
             * @implements IWorker
             * @constructor
             * @param {api.common.IWorker=} [properties] Properties to set
             */
            function Worker(properties) {
                this.group = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Worker workerId.
             * @member {number} workerId
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.workerId = 0;

            /**
             * Worker account.
             * @member {string} account
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.account = "";

            /**
             * Worker group.
             * @member {Array.<api.common.IWorkerGroup>} group
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.group = $util.emptyArray;

            /**
             * Worker permMask.
             * @member {number} permMask
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.permMask = 0;

            /**
             * Worker name.
             * @member {string} name
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.name = "";

            /**
             * Worker avatar.
             * @member {string} avatar
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.avatar = "";

            /**
             * Worker onlineState.
             * @member {api.common.OnlineState} onlineState
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.onlineState = 0;

            /**
             * Worker password.
             * @member {string} password
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.password = "";

            /**
             * Worker connectState.
             * @member {api.common.ConnectState} connectState
             * @memberof api.common.Worker
             * @instance
             */
            Worker.prototype.connectState = 0;

            /**
             * Creates a new Worker instance using the specified properties.
             * @function create
             * @memberof api.common.Worker
             * @static
             * @param {api.common.IWorker=} [properties] Properties to set
             * @returns {api.common.Worker} Worker instance
             */
            Worker.create = function create(properties) {
                return new Worker(properties);
            };

            /**
             * Encodes the specified Worker message. Does not implicitly {@link api.common.Worker.verify|verify} messages.
             * @function encode
             * @memberof api.common.Worker
             * @static
             * @param {api.common.IWorker} message Worker message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Worker.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.account);
                if (message.group != null && message.group.length)
                    for (let i = 0; i < message.group.length; ++i)
                        $root.api.common.WorkerGroup.encode(message.group[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.permMask != null && Object.hasOwnProperty.call(message, "permMask"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.permMask);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.avatar);
                if (message.onlineState != null && Object.hasOwnProperty.call(message, "onlineState"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.onlineState);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.password);
                if (message.connectState != null && Object.hasOwnProperty.call(message, "connectState"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.connectState);
                return writer;
            };

            /**
             * Encodes the specified Worker message, length delimited. Does not implicitly {@link api.common.Worker.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Worker
             * @static
             * @param {api.common.IWorker} message Worker message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Worker.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Worker message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Worker
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Worker} Worker
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Worker.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Worker();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.account = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.group && message.group.length))
                                message.group = [];
                            message.group.push($root.api.common.WorkerGroup.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            message.permMask = reader.int32();
                            break;
                        }
                    case 5: {
                            message.name = reader.string();
                            break;
                        }
                    case 6: {
                            message.avatar = reader.string();
                            break;
                        }
                    case 7: {
                            message.onlineState = reader.int32();
                            break;
                        }
                    case 8: {
                            message.password = reader.string();
                            break;
                        }
                    case 9: {
                            message.connectState = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Worker message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Worker
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Worker} Worker
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Worker.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Worker message.
             * @function verify
             * @memberof api.common.Worker
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Worker.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                if (message.account != null && message.hasOwnProperty("account"))
                    if (!$util.isString(message.account))
                        return "account: string expected";
                if (message.group != null && message.hasOwnProperty("group")) {
                    if (!Array.isArray(message.group))
                        return "group: array expected";
                    for (let i = 0; i < message.group.length; ++i) {
                        let error = $root.api.common.WorkerGroup.verify(message.group[i]);
                        if (error)
                            return "group." + error;
                    }
                }
                if (message.permMask != null && message.hasOwnProperty("permMask"))
                    if (!$util.isInteger(message.permMask))
                        return "permMask: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                if (message.onlineState != null && message.hasOwnProperty("onlineState"))
                    switch (message.onlineState) {
                    default:
                        return "onlineState: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.connectState != null && message.hasOwnProperty("connectState"))
                    switch (message.connectState) {
                    default:
                        return "connectState: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a Worker message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Worker
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Worker} Worker
             */
            Worker.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Worker)
                    return object;
                let message = new $root.api.common.Worker();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                if (object.account != null)
                    message.account = String(object.account);
                if (object.group) {
                    if (!Array.isArray(object.group))
                        throw TypeError(".api.common.Worker.group: array expected");
                    message.group = [];
                    for (let i = 0; i < object.group.length; ++i) {
                        if (typeof object.group[i] !== "object")
                            throw TypeError(".api.common.Worker.group: object expected");
                        message.group[i] = $root.api.common.WorkerGroup.fromObject(object.group[i]);
                    }
                }
                if (object.permMask != null)
                    message.permMask = object.permMask | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                switch (object.onlineState) {
                default:
                    if (typeof object.onlineState === "number") {
                        message.onlineState = object.onlineState;
                        break;
                    }
                    break;
                case "ONLINE_STATE_IDLE":
                case 0:
                    message.onlineState = 0;
                    break;
                case "ONLINE_STATE_BUSY":
                case 1:
                    message.onlineState = 1;
                    break;
                case "ONLINE_STATE_AFK":
                case 2:
                    message.onlineState = 2;
                    break;
                }
                if (object.password != null)
                    message.password = String(object.password);
                switch (object.connectState) {
                default:
                    if (typeof object.connectState === "number") {
                        message.connectState = object.connectState;
                        break;
                    }
                    break;
                case "CONNECT_STATE_OFFLINE":
                case 0:
                    message.connectState = 0;
                    break;
                case "CONNECT_STATE_ONLINE":
                case 1:
                    message.connectState = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a Worker message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Worker
             * @static
             * @param {api.common.Worker} message Worker
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Worker.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.group = [];
                if (options.defaults) {
                    object.workerId = 0;
                    object.account = "";
                    object.permMask = 0;
                    object.name = "";
                    object.avatar = "";
                    object.onlineState = options.enums === String ? "ONLINE_STATE_IDLE" : 0;
                    object.password = "";
                    object.connectState = options.enums === String ? "CONNECT_STATE_OFFLINE" : 0;
                }
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = message.account;
                if (message.group && message.group.length) {
                    object.group = [];
                    for (let j = 0; j < message.group.length; ++j)
                        object.group[j] = $root.api.common.WorkerGroup.toObject(message.group[j], options);
                }
                if (message.permMask != null && message.hasOwnProperty("permMask"))
                    object.permMask = message.permMask;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                if (message.onlineState != null && message.hasOwnProperty("onlineState"))
                    object.onlineState = options.enums === String ? $root.api.common.OnlineState[message.onlineState] === undefined ? message.onlineState : $root.api.common.OnlineState[message.onlineState] : message.onlineState;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.connectState != null && message.hasOwnProperty("connectState"))
                    object.connectState = options.enums === String ? $root.api.common.ConnectState[message.connectState] === undefined ? message.connectState : $root.api.common.ConnectState[message.connectState] : message.connectState;
                return object;
            };

            /**
             * Converts this Worker to JSON.
             * @function toJSON
             * @memberof api.common.Worker
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Worker.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Worker
             * @function getTypeUrl
             * @memberof api.common.Worker
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Worker.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Worker";
            };

            return Worker;
        })();

        common.WorkerGroup = (function() {

            /**
             * Properties of a WorkerGroup.
             * @memberof api.common
             * @interface IWorkerGroup
             * @property {Long|null} [id] WorkerGroup id
             * @property {string|null} [name] WorkerGroup name
             * @property {number|null} [priority] WorkerGroup priority
             * @property {number|null} [count] WorkerGroup count
             */

            /**
             * Constructs a new WorkerGroup.
             * @memberof api.common
             * @classdesc Represents a WorkerGroup.
             * @implements IWorkerGroup
             * @constructor
             * @param {api.common.IWorkerGroup=} [properties] Properties to set
             */
            function WorkerGroup(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerGroup id.
             * @member {Long} id
             * @memberof api.common.WorkerGroup
             * @instance
             */
            WorkerGroup.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * WorkerGroup name.
             * @member {string} name
             * @memberof api.common.WorkerGroup
             * @instance
             */
            WorkerGroup.prototype.name = "";

            /**
             * WorkerGroup priority.
             * @member {number} priority
             * @memberof api.common.WorkerGroup
             * @instance
             */
            WorkerGroup.prototype.priority = 0;

            /**
             * WorkerGroup count.
             * @member {number} count
             * @memberof api.common.WorkerGroup
             * @instance
             */
            WorkerGroup.prototype.count = 0;

            /**
             * Creates a new WorkerGroup instance using the specified properties.
             * @function create
             * @memberof api.common.WorkerGroup
             * @static
             * @param {api.common.IWorkerGroup=} [properties] Properties to set
             * @returns {api.common.WorkerGroup} WorkerGroup instance
             */
            WorkerGroup.create = function create(properties) {
                return new WorkerGroup(properties);
            };

            /**
             * Encodes the specified WorkerGroup message. Does not implicitly {@link api.common.WorkerGroup.verify|verify} messages.
             * @function encode
             * @memberof api.common.WorkerGroup
             * @static
             * @param {api.common.IWorkerGroup} message WorkerGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.count);
                return writer;
            };

            /**
             * Encodes the specified WorkerGroup message, length delimited. Does not implicitly {@link api.common.WorkerGroup.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.WorkerGroup
             * @static
             * @param {api.common.IWorkerGroup} message WorkerGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroup.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerGroup message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.WorkerGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.WorkerGroup} WorkerGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.WorkerGroup();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.priority = reader.int32();
                            break;
                        }
                    case 4: {
                            message.count = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerGroup message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.WorkerGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.WorkerGroup} WorkerGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroup.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerGroup message.
             * @function verify
             * @memberof api.common.WorkerGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                return null;
            };

            /**
             * Creates a WorkerGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.WorkerGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.WorkerGroup} WorkerGroup
             */
            WorkerGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.WorkerGroup)
                    return object;
                let message = new $root.api.common.WorkerGroup();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                if (object.count != null)
                    message.count = object.count | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.WorkerGroup
             * @static
             * @param {api.common.WorkerGroup} message WorkerGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.priority = 0;
                    object.count = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                return object;
            };

            /**
             * Converts this WorkerGroup to JSON.
             * @function toJSON
             * @memberof api.common.WorkerGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerGroup
             * @function getTypeUrl
             * @memberof api.common.WorkerGroup
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.WorkerGroup";
            };

            return WorkerGroup;
        })();

        common.Distribution = (function() {

            /**
             * Properties of a Distribution.
             * @memberof api.common
             * @interface IDistribution
             * @property {Long|null} [id] Distribution id
             * @property {string|null} [name] Distribution name
             * @property {number|null} [priority] Distribution priority
             */

            /**
             * Constructs a new Distribution.
             * @memberof api.common
             * @classdesc Represents a Distribution.
             * @implements IDistribution
             * @constructor
             * @param {api.common.IDistribution=} [properties] Properties to set
             */
            function Distribution(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Distribution id.
             * @member {Long} id
             * @memberof api.common.Distribution
             * @instance
             */
            Distribution.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Distribution name.
             * @member {string} name
             * @memberof api.common.Distribution
             * @instance
             */
            Distribution.prototype.name = "";

            /**
             * Distribution priority.
             * @member {number} priority
             * @memberof api.common.Distribution
             * @instance
             */
            Distribution.prototype.priority = 0;

            /**
             * Creates a new Distribution instance using the specified properties.
             * @function create
             * @memberof api.common.Distribution
             * @static
             * @param {api.common.IDistribution=} [properties] Properties to set
             * @returns {api.common.Distribution} Distribution instance
             */
            Distribution.create = function create(properties) {
                return new Distribution(properties);
            };

            /**
             * Encodes the specified Distribution message. Does not implicitly {@link api.common.Distribution.verify|verify} messages.
             * @function encode
             * @memberof api.common.Distribution
             * @static
             * @param {api.common.IDistribution} message Distribution message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Distribution.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified Distribution message, length delimited. Does not implicitly {@link api.common.Distribution.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Distribution
             * @static
             * @param {api.common.IDistribution} message Distribution message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Distribution.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Distribution message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Distribution
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Distribution} Distribution
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Distribution.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Distribution();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Distribution message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Distribution
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Distribution} Distribution
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Distribution.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Distribution message.
             * @function verify
             * @memberof api.common.Distribution
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Distribution.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a Distribution message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Distribution
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Distribution} Distribution
             */
            Distribution.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Distribution)
                    return object;
                let message = new $root.api.common.Distribution();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a Distribution message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Distribution
             * @static
             * @param {api.common.Distribution} message Distribution
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Distribution.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.priority = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this Distribution to JSON.
             * @function toJSON
             * @memberof api.common.Distribution
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Distribution.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Distribution
             * @function getTypeUrl
             * @memberof api.common.Distribution
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Distribution.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Distribution";
            };

            return Distribution;
        })();

        common.TenantClique = (function() {

            /**
             * Properties of a TenantClique.
             * @memberof api.common
             * @interface ITenantClique
             * @property {number|null} [id] TenantClique id
             * @property {string|null} [name] TenantClique name
             * @property {number|null} [priority] TenantClique priority
             */

            /**
             * Constructs a new TenantClique.
             * @memberof api.common
             * @classdesc Represents a TenantClique.
             * @implements ITenantClique
             * @constructor
             * @param {api.common.ITenantClique=} [properties] Properties to set
             */
            function TenantClique(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantClique id.
             * @member {number} id
             * @memberof api.common.TenantClique
             * @instance
             */
            TenantClique.prototype.id = 0;

            /**
             * TenantClique name.
             * @member {string} name
             * @memberof api.common.TenantClique
             * @instance
             */
            TenantClique.prototype.name = "";

            /**
             * TenantClique priority.
             * @member {number} priority
             * @memberof api.common.TenantClique
             * @instance
             */
            TenantClique.prototype.priority = 0;

            /**
             * Creates a new TenantClique instance using the specified properties.
             * @function create
             * @memberof api.common.TenantClique
             * @static
             * @param {api.common.ITenantClique=} [properties] Properties to set
             * @returns {api.common.TenantClique} TenantClique instance
             */
            TenantClique.create = function create(properties) {
                return new TenantClique(properties);
            };

            /**
             * Encodes the specified TenantClique message. Does not implicitly {@link api.common.TenantClique.verify|verify} messages.
             * @function encode
             * @memberof api.common.TenantClique
             * @static
             * @param {api.common.ITenantClique} message TenantClique message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantClique.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified TenantClique message, length delimited. Does not implicitly {@link api.common.TenantClique.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.TenantClique
             * @static
             * @param {api.common.ITenantClique} message TenantClique message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantClique.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantClique message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.TenantClique
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.TenantClique} TenantClique
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantClique.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.TenantClique();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantClique message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.TenantClique
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.TenantClique} TenantClique
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantClique.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantClique message.
             * @function verify
             * @memberof api.common.TenantClique
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantClique.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a TenantClique message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.TenantClique
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.TenantClique} TenantClique
             */
            TenantClique.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.TenantClique)
                    return object;
                let message = new $root.api.common.TenantClique();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a TenantClique message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.TenantClique
             * @static
             * @param {api.common.TenantClique} message TenantClique
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantClique.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.id = 0;
                    object.name = "";
                    object.priority = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this TenantClique to JSON.
             * @function toJSON
             * @memberof api.common.TenantClique
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantClique.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantClique
             * @function getTypeUrl
             * @memberof api.common.TenantClique
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantClique.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.TenantClique";
            };

            return TenantClique;
        })();

        common.BaseResponse = (function() {

            /**
             * Properties of a BaseResponse.
             * @memberof api.common
             * @interface IBaseResponse
             */

            /**
             * Constructs a new BaseResponse.
             * @memberof api.common
             * @classdesc Represents a BaseResponse.
             * @implements IBaseResponse
             * @constructor
             * @param {api.common.IBaseResponse=} [properties] Properties to set
             */
            function BaseResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new BaseResponse instance using the specified properties.
             * @function create
             * @memberof api.common.BaseResponse
             * @static
             * @param {api.common.IBaseResponse=} [properties] Properties to set
             * @returns {api.common.BaseResponse} BaseResponse instance
             */
            BaseResponse.create = function create(properties) {
                return new BaseResponse(properties);
            };

            /**
             * Encodes the specified BaseResponse message. Does not implicitly {@link api.common.BaseResponse.verify|verify} messages.
             * @function encode
             * @memberof api.common.BaseResponse
             * @static
             * @param {api.common.IBaseResponse} message BaseResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BaseResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified BaseResponse message, length delimited. Does not implicitly {@link api.common.BaseResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.BaseResponse
             * @static
             * @param {api.common.IBaseResponse} message BaseResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BaseResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BaseResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.BaseResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.BaseResponse} BaseResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BaseResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.BaseResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BaseResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.BaseResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.BaseResponse} BaseResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BaseResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BaseResponse message.
             * @function verify
             * @memberof api.common.BaseResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BaseResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a BaseResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.BaseResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.BaseResponse} BaseResponse
             */
            BaseResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.BaseResponse)
                    return object;
                return new $root.api.common.BaseResponse();
            };

            /**
             * Creates a plain object from a BaseResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.BaseResponse
             * @static
             * @param {api.common.BaseResponse} message BaseResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BaseResponse.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BaseResponse to JSON.
             * @function toJSON
             * @memberof api.common.BaseResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BaseResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BaseResponse
             * @function getTypeUrl
             * @memberof api.common.BaseResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BaseResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.BaseResponse";
            };

            return BaseResponse;
        })();

        common.Page = (function() {

            /**
             * Properties of a Page.
             * @memberof api.common
             * @interface IPage
             * @property {number|null} [page] Page page
             * @property {number|null} [limit] Page limit
             */

            /**
             * Constructs a new Page.
             * @memberof api.common
             * @classdesc Represents a Page.
             * @implements IPage
             * @constructor
             * @param {api.common.IPage=} [properties] Properties to set
             */
            function Page(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Page page.
             * @member {number} page
             * @memberof api.common.Page
             * @instance
             */
            Page.prototype.page = 0;

            /**
             * Page limit.
             * @member {number} limit
             * @memberof api.common.Page
             * @instance
             */
            Page.prototype.limit = 0;

            /**
             * Creates a new Page instance using the specified properties.
             * @function create
             * @memberof api.common.Page
             * @static
             * @param {api.common.IPage=} [properties] Properties to set
             * @returns {api.common.Page} Page instance
             */
            Page.create = function create(properties) {
                return new Page(properties);
            };

            /**
             * Encodes the specified Page message. Does not implicitly {@link api.common.Page.verify|verify} messages.
             * @function encode
             * @memberof api.common.Page
             * @static
             * @param {api.common.IPage} message Page message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Page.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
                return writer;
            };

            /**
             * Encodes the specified Page message, length delimited. Does not implicitly {@link api.common.Page.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Page
             * @static
             * @param {api.common.IPage} message Page message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Page.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Page message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Page
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Page} Page
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Page.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Page();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.page = reader.int32();
                            break;
                        }
                    case 2: {
                            message.limit = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Page message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Page
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Page} Page
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Page.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Page message.
             * @function verify
             * @memberof api.common.Page
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Page.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.page != null && message.hasOwnProperty("page"))
                    if (!$util.isInteger(message.page))
                        return "page: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                return null;
            };

            /**
             * Creates a Page message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Page
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Page} Page
             */
            Page.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Page)
                    return object;
                let message = new $root.api.common.Page();
                if (object.page != null)
                    message.page = object.page | 0;
                if (object.limit != null)
                    message.limit = object.limit | 0;
                return message;
            };

            /**
             * Creates a plain object from a Page message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Page
             * @static
             * @param {api.common.Page} message Page
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Page.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.page = 0;
                    object.limit = 0;
                }
                if (message.page != null && message.hasOwnProperty("page"))
                    object.page = message.page;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                return object;
            };

            /**
             * Converts this Page to JSON.
             * @function toJSON
             * @memberof api.common.Page
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Page.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Page
             * @function getTypeUrl
             * @memberof api.common.Page
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Page.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Page";
            };

            return Page;
        })();

        common.Batch = (function() {

            /**
             * Properties of a Batch.
             * @memberof api.common
             * @interface IBatch
             * @property {number|null} [offset] Batch offset
             * @property {number|null} [limit] Batch limit
             */

            /**
             * Constructs a new Batch.
             * @memberof api.common
             * @classdesc Represents a Batch.
             * @implements IBatch
             * @constructor
             * @param {api.common.IBatch=} [properties] Properties to set
             */
            function Batch(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Batch offset.
             * @member {number} offset
             * @memberof api.common.Batch
             * @instance
             */
            Batch.prototype.offset = 0;

            /**
             * Batch limit.
             * @member {number} limit
             * @memberof api.common.Batch
             * @instance
             */
            Batch.prototype.limit = 0;

            /**
             * Creates a new Batch instance using the specified properties.
             * @function create
             * @memberof api.common.Batch
             * @static
             * @param {api.common.IBatch=} [properties] Properties to set
             * @returns {api.common.Batch} Batch instance
             */
            Batch.create = function create(properties) {
                return new Batch(properties);
            };

            /**
             * Encodes the specified Batch message. Does not implicitly {@link api.common.Batch.verify|verify} messages.
             * @function encode
             * @memberof api.common.Batch
             * @static
             * @param {api.common.IBatch} message Batch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Batch.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
                return writer;
            };

            /**
             * Encodes the specified Batch message, length delimited. Does not implicitly {@link api.common.Batch.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Batch
             * @static
             * @param {api.common.IBatch} message Batch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Batch.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Batch message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Batch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Batch} Batch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Batch.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Batch();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.offset = reader.int32();
                            break;
                        }
                    case 2: {
                            message.limit = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Batch message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Batch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Batch} Batch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Batch.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Batch message.
             * @function verify
             * @memberof api.common.Batch
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Batch.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                return null;
            };

            /**
             * Creates a Batch message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Batch
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Batch} Batch
             */
            Batch.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Batch)
                    return object;
                let message = new $root.api.common.Batch();
                if (object.offset != null)
                    message.offset = object.offset | 0;
                if (object.limit != null)
                    message.limit = object.limit | 0;
                return message;
            };

            /**
             * Creates a plain object from a Batch message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Batch
             * @static
             * @param {api.common.Batch} message Batch
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Batch.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.offset = 0;
                    object.limit = 0;
                }
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                return object;
            };

            /**
             * Converts this Batch to JSON.
             * @function toJSON
             * @memberof api.common.Batch
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Batch.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Batch
             * @function getTypeUrl
             * @memberof api.common.Batch
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Batch.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Batch";
            };

            return Batch;
        })();

        common.ListInt32 = (function() {

            /**
             * Properties of a ListInt32.
             * @memberof api.common
             * @interface IListInt32
             * @property {Array.<number>|null} [i32] ListInt32 i32
             */

            /**
             * Constructs a new ListInt32.
             * @memberof api.common
             * @classdesc Represents a ListInt32.
             * @implements IListInt32
             * @constructor
             * @param {api.common.IListInt32=} [properties] Properties to set
             */
            function ListInt32(properties) {
                this.i32 = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ListInt32 i32.
             * @member {Array.<number>} i32
             * @memberof api.common.ListInt32
             * @instance
             */
            ListInt32.prototype.i32 = $util.emptyArray;

            /**
             * Creates a new ListInt32 instance using the specified properties.
             * @function create
             * @memberof api.common.ListInt32
             * @static
             * @param {api.common.IListInt32=} [properties] Properties to set
             * @returns {api.common.ListInt32} ListInt32 instance
             */
            ListInt32.create = function create(properties) {
                return new ListInt32(properties);
            };

            /**
             * Encodes the specified ListInt32 message. Does not implicitly {@link api.common.ListInt32.verify|verify} messages.
             * @function encode
             * @memberof api.common.ListInt32
             * @static
             * @param {api.common.IListInt32} message ListInt32 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListInt32.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.i32 != null && message.i32.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.i32.length; ++i)
                        writer.int32(message.i32[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified ListInt32 message, length delimited. Does not implicitly {@link api.common.ListInt32.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.ListInt32
             * @static
             * @param {api.common.IListInt32} message ListInt32 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListInt32.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ListInt32 message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.ListInt32
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.ListInt32} ListInt32
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListInt32.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.ListInt32();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.i32 && message.i32.length))
                                message.i32 = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.i32.push(reader.int32());
                            } else
                                message.i32.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ListInt32 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.ListInt32
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.ListInt32} ListInt32
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListInt32.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ListInt32 message.
             * @function verify
             * @memberof api.common.ListInt32
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListInt32.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.i32 != null && message.hasOwnProperty("i32")) {
                    if (!Array.isArray(message.i32))
                        return "i32: array expected";
                    for (let i = 0; i < message.i32.length; ++i)
                        if (!$util.isInteger(message.i32[i]))
                            return "i32: integer[] expected";
                }
                return null;
            };

            /**
             * Creates a ListInt32 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.ListInt32
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.ListInt32} ListInt32
             */
            ListInt32.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.ListInt32)
                    return object;
                let message = new $root.api.common.ListInt32();
                if (object.i32) {
                    if (!Array.isArray(object.i32))
                        throw TypeError(".api.common.ListInt32.i32: array expected");
                    message.i32 = [];
                    for (let i = 0; i < object.i32.length; ++i)
                        message.i32[i] = object.i32[i] | 0;
                }
                return message;
            };

            /**
             * Creates a plain object from a ListInt32 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.ListInt32
             * @static
             * @param {api.common.ListInt32} message ListInt32
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListInt32.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.i32 = [];
                if (message.i32 && message.i32.length) {
                    object.i32 = [];
                    for (let j = 0; j < message.i32.length; ++j)
                        object.i32[j] = message.i32[j];
                }
                return object;
            };

            /**
             * Converts this ListInt32 to JSON.
             * @function toJSON
             * @memberof api.common.ListInt32
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListInt32.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListInt32
             * @function getTypeUrl
             * @memberof api.common.ListInt32
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListInt32.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.ListInt32";
            };

            return ListInt32;
        })();

        common.ListInt64 = (function() {

            /**
             * Properties of a ListInt64.
             * @memberof api.common
             * @interface IListInt64
             * @property {Array.<Long>|null} [i64] ListInt64 i64
             */

            /**
             * Constructs a new ListInt64.
             * @memberof api.common
             * @classdesc Represents a ListInt64.
             * @implements IListInt64
             * @constructor
             * @param {api.common.IListInt64=} [properties] Properties to set
             */
            function ListInt64(properties) {
                this.i64 = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ListInt64 i64.
             * @member {Array.<Long>} i64
             * @memberof api.common.ListInt64
             * @instance
             */
            ListInt64.prototype.i64 = $util.emptyArray;

            /**
             * Creates a new ListInt64 instance using the specified properties.
             * @function create
             * @memberof api.common.ListInt64
             * @static
             * @param {api.common.IListInt64=} [properties] Properties to set
             * @returns {api.common.ListInt64} ListInt64 instance
             */
            ListInt64.create = function create(properties) {
                return new ListInt64(properties);
            };

            /**
             * Encodes the specified ListInt64 message. Does not implicitly {@link api.common.ListInt64.verify|verify} messages.
             * @function encode
             * @memberof api.common.ListInt64
             * @static
             * @param {api.common.IListInt64} message ListInt64 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListInt64.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.i64 != null && message.i64.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.i64.length; ++i)
                        writer.int64(message.i64[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified ListInt64 message, length delimited. Does not implicitly {@link api.common.ListInt64.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.ListInt64
             * @static
             * @param {api.common.IListInt64} message ListInt64 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListInt64.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ListInt64 message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.ListInt64
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.ListInt64} ListInt64
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListInt64.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.ListInt64();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.i64 && message.i64.length))
                                message.i64 = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.i64.push(reader.int64());
                            } else
                                message.i64.push(reader.int64());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ListInt64 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.ListInt64
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.ListInt64} ListInt64
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListInt64.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ListInt64 message.
             * @function verify
             * @memberof api.common.ListInt64
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListInt64.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.i64 != null && message.hasOwnProperty("i64")) {
                    if (!Array.isArray(message.i64))
                        return "i64: array expected";
                    for (let i = 0; i < message.i64.length; ++i)
                        if (!$util.isInteger(message.i64[i]) && !(message.i64[i] && $util.isInteger(message.i64[i].low) && $util.isInteger(message.i64[i].high)))
                            return "i64: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a ListInt64 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.ListInt64
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.ListInt64} ListInt64
             */
            ListInt64.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.ListInt64)
                    return object;
                let message = new $root.api.common.ListInt64();
                if (object.i64) {
                    if (!Array.isArray(object.i64))
                        throw TypeError(".api.common.ListInt64.i64: array expected");
                    message.i64 = [];
                    for (let i = 0; i < object.i64.length; ++i)
                        if ($util.Long)
                            (message.i64[i] = $util.Long.fromValue(object.i64[i])).unsigned = false;
                        else if (typeof object.i64[i] === "string")
                            message.i64[i] = parseInt(object.i64[i], 10);
                        else if (typeof object.i64[i] === "number")
                            message.i64[i] = object.i64[i];
                        else if (typeof object.i64[i] === "object")
                            message.i64[i] = new $util.LongBits(object.i64[i].low >>> 0, object.i64[i].high >>> 0).toNumber();
                }
                return message;
            };

            /**
             * Creates a plain object from a ListInt64 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.ListInt64
             * @static
             * @param {api.common.ListInt64} message ListInt64
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListInt64.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.i64 = [];
                if (message.i64 && message.i64.length) {
                    object.i64 = [];
                    for (let j = 0; j < message.i64.length; ++j)
                        if (typeof message.i64[j] === "number")
                            object.i64[j] = options.longs === String ? String(message.i64[j]) : message.i64[j];
                        else
                            object.i64[j] = options.longs === String ? $util.Long.prototype.toString.call(message.i64[j]) : options.longs === Number ? new $util.LongBits(message.i64[j].low >>> 0, message.i64[j].high >>> 0).toNumber() : message.i64[j];
                }
                return object;
            };

            /**
             * Converts this ListInt64 to JSON.
             * @function toJSON
             * @memberof api.common.ListInt64
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListInt64.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListInt64
             * @function getTypeUrl
             * @memberof api.common.ListInt64
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListInt64.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.ListInt64";
            };

            return ListInt64;
        })();

        common.ListFloat32 = (function() {

            /**
             * Properties of a ListFloat32.
             * @memberof api.common
             * @interface IListFloat32
             * @property {Array.<number>|null} [f32] ListFloat32 f32
             */

            /**
             * Constructs a new ListFloat32.
             * @memberof api.common
             * @classdesc Represents a ListFloat32.
             * @implements IListFloat32
             * @constructor
             * @param {api.common.IListFloat32=} [properties] Properties to set
             */
            function ListFloat32(properties) {
                this.f32 = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ListFloat32 f32.
             * @member {Array.<number>} f32
             * @memberof api.common.ListFloat32
             * @instance
             */
            ListFloat32.prototype.f32 = $util.emptyArray;

            /**
             * Creates a new ListFloat32 instance using the specified properties.
             * @function create
             * @memberof api.common.ListFloat32
             * @static
             * @param {api.common.IListFloat32=} [properties] Properties to set
             * @returns {api.common.ListFloat32} ListFloat32 instance
             */
            ListFloat32.create = function create(properties) {
                return new ListFloat32(properties);
            };

            /**
             * Encodes the specified ListFloat32 message. Does not implicitly {@link api.common.ListFloat32.verify|verify} messages.
             * @function encode
             * @memberof api.common.ListFloat32
             * @static
             * @param {api.common.IListFloat32} message ListFloat32 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListFloat32.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.f32 != null && message.f32.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.f32.length; ++i)
                        writer.float(message.f32[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified ListFloat32 message, length delimited. Does not implicitly {@link api.common.ListFloat32.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.ListFloat32
             * @static
             * @param {api.common.IListFloat32} message ListFloat32 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListFloat32.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ListFloat32 message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.ListFloat32
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.ListFloat32} ListFloat32
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListFloat32.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.ListFloat32();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.f32 && message.f32.length))
                                message.f32 = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.f32.push(reader.float());
                            } else
                                message.f32.push(reader.float());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ListFloat32 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.ListFloat32
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.ListFloat32} ListFloat32
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListFloat32.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ListFloat32 message.
             * @function verify
             * @memberof api.common.ListFloat32
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListFloat32.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.f32 != null && message.hasOwnProperty("f32")) {
                    if (!Array.isArray(message.f32))
                        return "f32: array expected";
                    for (let i = 0; i < message.f32.length; ++i)
                        if (typeof message.f32[i] !== "number")
                            return "f32: number[] expected";
                }
                return null;
            };

            /**
             * Creates a ListFloat32 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.ListFloat32
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.ListFloat32} ListFloat32
             */
            ListFloat32.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.ListFloat32)
                    return object;
                let message = new $root.api.common.ListFloat32();
                if (object.f32) {
                    if (!Array.isArray(object.f32))
                        throw TypeError(".api.common.ListFloat32.f32: array expected");
                    message.f32 = [];
                    for (let i = 0; i < object.f32.length; ++i)
                        message.f32[i] = Number(object.f32[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a ListFloat32 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.ListFloat32
             * @static
             * @param {api.common.ListFloat32} message ListFloat32
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListFloat32.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.f32 = [];
                if (message.f32 && message.f32.length) {
                    object.f32 = [];
                    for (let j = 0; j < message.f32.length; ++j)
                        object.f32[j] = options.json && !isFinite(message.f32[j]) ? String(message.f32[j]) : message.f32[j];
                }
                return object;
            };

            /**
             * Converts this ListFloat32 to JSON.
             * @function toJSON
             * @memberof api.common.ListFloat32
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListFloat32.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListFloat32
             * @function getTypeUrl
             * @memberof api.common.ListFloat32
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListFloat32.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.ListFloat32";
            };

            return ListFloat32;
        })();

        common.ListFloat64 = (function() {

            /**
             * Properties of a ListFloat64.
             * @memberof api.common
             * @interface IListFloat64
             * @property {Array.<number>|null} [f64] ListFloat64 f64
             */

            /**
             * Constructs a new ListFloat64.
             * @memberof api.common
             * @classdesc Represents a ListFloat64.
             * @implements IListFloat64
             * @constructor
             * @param {api.common.IListFloat64=} [properties] Properties to set
             */
            function ListFloat64(properties) {
                this.f64 = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ListFloat64 f64.
             * @member {Array.<number>} f64
             * @memberof api.common.ListFloat64
             * @instance
             */
            ListFloat64.prototype.f64 = $util.emptyArray;

            /**
             * Creates a new ListFloat64 instance using the specified properties.
             * @function create
             * @memberof api.common.ListFloat64
             * @static
             * @param {api.common.IListFloat64=} [properties] Properties to set
             * @returns {api.common.ListFloat64} ListFloat64 instance
             */
            ListFloat64.create = function create(properties) {
                return new ListFloat64(properties);
            };

            /**
             * Encodes the specified ListFloat64 message. Does not implicitly {@link api.common.ListFloat64.verify|verify} messages.
             * @function encode
             * @memberof api.common.ListFloat64
             * @static
             * @param {api.common.IListFloat64} message ListFloat64 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListFloat64.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.f64 != null && message.f64.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.f64.length; ++i)
                        writer.double(message.f64[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified ListFloat64 message, length delimited. Does not implicitly {@link api.common.ListFloat64.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.ListFloat64
             * @static
             * @param {api.common.IListFloat64} message ListFloat64 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListFloat64.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ListFloat64 message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.ListFloat64
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.ListFloat64} ListFloat64
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListFloat64.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.ListFloat64();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.f64 && message.f64.length))
                                message.f64 = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.f64.push(reader.double());
                            } else
                                message.f64.push(reader.double());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ListFloat64 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.ListFloat64
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.ListFloat64} ListFloat64
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListFloat64.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ListFloat64 message.
             * @function verify
             * @memberof api.common.ListFloat64
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListFloat64.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.f64 != null && message.hasOwnProperty("f64")) {
                    if (!Array.isArray(message.f64))
                        return "f64: array expected";
                    for (let i = 0; i < message.f64.length; ++i)
                        if (typeof message.f64[i] !== "number")
                            return "f64: number[] expected";
                }
                return null;
            };

            /**
             * Creates a ListFloat64 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.ListFloat64
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.ListFloat64} ListFloat64
             */
            ListFloat64.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.ListFloat64)
                    return object;
                let message = new $root.api.common.ListFloat64();
                if (object.f64) {
                    if (!Array.isArray(object.f64))
                        throw TypeError(".api.common.ListFloat64.f64: array expected");
                    message.f64 = [];
                    for (let i = 0; i < object.f64.length; ++i)
                        message.f64[i] = Number(object.f64[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a ListFloat64 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.ListFloat64
             * @static
             * @param {api.common.ListFloat64} message ListFloat64
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListFloat64.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.f64 = [];
                if (message.f64 && message.f64.length) {
                    object.f64 = [];
                    for (let j = 0; j < message.f64.length; ++j)
                        object.f64[j] = options.json && !isFinite(message.f64[j]) ? String(message.f64[j]) : message.f64[j];
                }
                return object;
            };

            /**
             * Converts this ListFloat64 to JSON.
             * @function toJSON
             * @memberof api.common.ListFloat64
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListFloat64.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListFloat64
             * @function getTypeUrl
             * @memberof api.common.ListFloat64
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListFloat64.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.ListFloat64";
            };

            return ListFloat64;
        })();

        common.ListString = (function() {

            /**
             * Properties of a ListString.
             * @memberof api.common
             * @interface IListString
             * @property {Array.<string>|null} [str] ListString str
             */

            /**
             * Constructs a new ListString.
             * @memberof api.common
             * @classdesc Represents a ListString.
             * @implements IListString
             * @constructor
             * @param {api.common.IListString=} [properties] Properties to set
             */
            function ListString(properties) {
                this.str = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ListString str.
             * @member {Array.<string>} str
             * @memberof api.common.ListString
             * @instance
             */
            ListString.prototype.str = $util.emptyArray;

            /**
             * Creates a new ListString instance using the specified properties.
             * @function create
             * @memberof api.common.ListString
             * @static
             * @param {api.common.IListString=} [properties] Properties to set
             * @returns {api.common.ListString} ListString instance
             */
            ListString.create = function create(properties) {
                return new ListString(properties);
            };

            /**
             * Encodes the specified ListString message. Does not implicitly {@link api.common.ListString.verify|verify} messages.
             * @function encode
             * @memberof api.common.ListString
             * @static
             * @param {api.common.IListString} message ListString message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListString.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.str != null && message.str.length)
                    for (let i = 0; i < message.str.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.str[i]);
                return writer;
            };

            /**
             * Encodes the specified ListString message, length delimited. Does not implicitly {@link api.common.ListString.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.ListString
             * @static
             * @param {api.common.IListString} message ListString message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListString.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ListString message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.ListString
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.ListString} ListString
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListString.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.ListString();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.str && message.str.length))
                                message.str = [];
                            message.str.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ListString message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.ListString
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.ListString} ListString
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListString.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ListString message.
             * @function verify
             * @memberof api.common.ListString
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListString.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.str != null && message.hasOwnProperty("str")) {
                    if (!Array.isArray(message.str))
                        return "str: array expected";
                    for (let i = 0; i < message.str.length; ++i)
                        if (!$util.isString(message.str[i]))
                            return "str: string[] expected";
                }
                return null;
            };

            /**
             * Creates a ListString message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.ListString
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.ListString} ListString
             */
            ListString.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.ListString)
                    return object;
                let message = new $root.api.common.ListString();
                if (object.str) {
                    if (!Array.isArray(object.str))
                        throw TypeError(".api.common.ListString.str: array expected");
                    message.str = [];
                    for (let i = 0; i < object.str.length; ++i)
                        message.str[i] = String(object.str[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a ListString message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.ListString
             * @static
             * @param {api.common.ListString} message ListString
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListString.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.str = [];
                if (message.str && message.str.length) {
                    object.str = [];
                    for (let j = 0; j < message.str.length; ++j)
                        object.str[j] = message.str[j];
                }
                return object;
            };

            /**
             * Converts this ListString to JSON.
             * @function toJSON
             * @memberof api.common.ListString
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListString.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ListString
             * @function getTypeUrl
             * @memberof api.common.ListString
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListString.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.ListString";
            };

            return ListString;
        })();

        common.SetInt32 = (function() {

            /**
             * Properties of a SetInt32.
             * @memberof api.common
             * @interface ISetInt32
             * @property {Object.<string,Long>|null} [i32] SetInt32 i32
             */

            /**
             * Constructs a new SetInt32.
             * @memberof api.common
             * @classdesc Represents a SetInt32.
             * @implements ISetInt32
             * @constructor
             * @param {api.common.ISetInt32=} [properties] Properties to set
             */
            function SetInt32(properties) {
                this.i32 = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetInt32 i32.
             * @member {Object.<string,Long>} i32
             * @memberof api.common.SetInt32
             * @instance
             */
            SetInt32.prototype.i32 = $util.emptyObject;

            /**
             * Creates a new SetInt32 instance using the specified properties.
             * @function create
             * @memberof api.common.SetInt32
             * @static
             * @param {api.common.ISetInt32=} [properties] Properties to set
             * @returns {api.common.SetInt32} SetInt32 instance
             */
            SetInt32.create = function create(properties) {
                return new SetInt32(properties);
            };

            /**
             * Encodes the specified SetInt32 message. Does not implicitly {@link api.common.SetInt32.verify|verify} messages.
             * @function encode
             * @memberof api.common.SetInt32
             * @static
             * @param {api.common.ISetInt32} message SetInt32 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetInt32.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.i32 != null && Object.hasOwnProperty.call(message, "i32"))
                    for (let keys = Object.keys(message.i32), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 0 =*/8).int32(keys[i]).uint32(/* id 2, wireType 0 =*/16).int64(message.i32[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SetInt32 message, length delimited. Does not implicitly {@link api.common.SetInt32.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.SetInt32
             * @static
             * @param {api.common.ISetInt32} message SetInt32 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetInt32.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SetInt32 message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.SetInt32
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.SetInt32} SetInt32
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetInt32.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.SetInt32(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (message.i32 === $util.emptyObject)
                                message.i32 = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = 0;
                            value = 0;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.int32();
                                    break;
                                case 2:
                                    value = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.i32[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SetInt32 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.SetInt32
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.SetInt32} SetInt32
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetInt32.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SetInt32 message.
             * @function verify
             * @memberof api.common.SetInt32
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetInt32.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.i32 != null && message.hasOwnProperty("i32")) {
                    if (!$util.isObject(message.i32))
                        return "i32: object expected";
                    let key = Object.keys(message.i32);
                    for (let i = 0; i < key.length; ++i) {
                        if (!$util.key32Re.test(key[i]))
                            return "i32: integer key{k:int32} expected";
                        if (!$util.isInteger(message.i32[key[i]]) && !(message.i32[key[i]] && $util.isInteger(message.i32[key[i]].low) && $util.isInteger(message.i32[key[i]].high)))
                            return "i32: integer|Long{k:int32} expected";
                    }
                }
                return null;
            };

            /**
             * Creates a SetInt32 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.SetInt32
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.SetInt32} SetInt32
             */
            SetInt32.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.SetInt32)
                    return object;
                let message = new $root.api.common.SetInt32();
                if (object.i32) {
                    if (typeof object.i32 !== "object")
                        throw TypeError(".api.common.SetInt32.i32: object expected");
                    message.i32 = {};
                    for (let keys = Object.keys(object.i32), i = 0; i < keys.length; ++i)
                        if ($util.Long)
                            (message.i32[keys[i]] = $util.Long.fromValue(object.i32[keys[i]])).unsigned = false;
                        else if (typeof object.i32[keys[i]] === "string")
                            message.i32[keys[i]] = parseInt(object.i32[keys[i]], 10);
                        else if (typeof object.i32[keys[i]] === "number")
                            message.i32[keys[i]] = object.i32[keys[i]];
                        else if (typeof object.i32[keys[i]] === "object")
                            message.i32[keys[i]] = new $util.LongBits(object.i32[keys[i]].low >>> 0, object.i32[keys[i]].high >>> 0).toNumber();
                }
                return message;
            };

            /**
             * Creates a plain object from a SetInt32 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.SetInt32
             * @static
             * @param {api.common.SetInt32} message SetInt32
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetInt32.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.i32 = {};
                let keys2;
                if (message.i32 && (keys2 = Object.keys(message.i32)).length) {
                    object.i32 = {};
                    for (let j = 0; j < keys2.length; ++j)
                        if (typeof message.i32[keys2[j]] === "number")
                            object.i32[keys2[j]] = options.longs === String ? String(message.i32[keys2[j]]) : message.i32[keys2[j]];
                        else
                            object.i32[keys2[j]] = options.longs === String ? $util.Long.prototype.toString.call(message.i32[keys2[j]]) : options.longs === Number ? new $util.LongBits(message.i32[keys2[j]].low >>> 0, message.i32[keys2[j]].high >>> 0).toNumber() : message.i32[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this SetInt32 to JSON.
             * @function toJSON
             * @memberof api.common.SetInt32
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetInt32.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SetInt32
             * @function getTypeUrl
             * @memberof api.common.SetInt32
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SetInt32.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.SetInt32";
            };

            return SetInt32;
        })();

        common.SetInt64 = (function() {

            /**
             * Properties of a SetInt64.
             * @memberof api.common
             * @interface ISetInt64
             * @property {Object.<string,Long>|null} [i64] SetInt64 i64
             */

            /**
             * Constructs a new SetInt64.
             * @memberof api.common
             * @classdesc Represents a SetInt64.
             * @implements ISetInt64
             * @constructor
             * @param {api.common.ISetInt64=} [properties] Properties to set
             */
            function SetInt64(properties) {
                this.i64 = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetInt64 i64.
             * @member {Object.<string,Long>} i64
             * @memberof api.common.SetInt64
             * @instance
             */
            SetInt64.prototype.i64 = $util.emptyObject;

            /**
             * Creates a new SetInt64 instance using the specified properties.
             * @function create
             * @memberof api.common.SetInt64
             * @static
             * @param {api.common.ISetInt64=} [properties] Properties to set
             * @returns {api.common.SetInt64} SetInt64 instance
             */
            SetInt64.create = function create(properties) {
                return new SetInt64(properties);
            };

            /**
             * Encodes the specified SetInt64 message. Does not implicitly {@link api.common.SetInt64.verify|verify} messages.
             * @function encode
             * @memberof api.common.SetInt64
             * @static
             * @param {api.common.ISetInt64} message SetInt64 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetInt64.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.i64 != null && Object.hasOwnProperty.call(message, "i64"))
                    for (let keys = Object.keys(message.i64), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]).uint32(/* id 2, wireType 0 =*/16).int64(message.i64[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SetInt64 message, length delimited. Does not implicitly {@link api.common.SetInt64.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.SetInt64
             * @static
             * @param {api.common.ISetInt64} message SetInt64 message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetInt64.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SetInt64 message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.SetInt64
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.SetInt64} SetInt64
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetInt64.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.SetInt64(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (message.i64 === $util.emptyObject)
                                message.i64 = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = 0;
                            value = 0;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.int64();
                                    break;
                                case 2:
                                    value = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.i64[typeof key === "object" ? $util.longToHash(key) : key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SetInt64 message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.SetInt64
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.SetInt64} SetInt64
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetInt64.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SetInt64 message.
             * @function verify
             * @memberof api.common.SetInt64
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetInt64.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.i64 != null && message.hasOwnProperty("i64")) {
                    if (!$util.isObject(message.i64))
                        return "i64: object expected";
                    let key = Object.keys(message.i64);
                    for (let i = 0; i < key.length; ++i) {
                        if (!$util.key64Re.test(key[i]))
                            return "i64: integer|Long key{k:int64} expected";
                        if (!$util.isInteger(message.i64[key[i]]) && !(message.i64[key[i]] && $util.isInteger(message.i64[key[i]].low) && $util.isInteger(message.i64[key[i]].high)))
                            return "i64: integer|Long{k:int64} expected";
                    }
                }
                return null;
            };

            /**
             * Creates a SetInt64 message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.SetInt64
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.SetInt64} SetInt64
             */
            SetInt64.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.SetInt64)
                    return object;
                let message = new $root.api.common.SetInt64();
                if (object.i64) {
                    if (typeof object.i64 !== "object")
                        throw TypeError(".api.common.SetInt64.i64: object expected");
                    message.i64 = {};
                    for (let keys = Object.keys(object.i64), i = 0; i < keys.length; ++i)
                        if ($util.Long)
                            (message.i64[keys[i]] = $util.Long.fromValue(object.i64[keys[i]])).unsigned = false;
                        else if (typeof object.i64[keys[i]] === "string")
                            message.i64[keys[i]] = parseInt(object.i64[keys[i]], 10);
                        else if (typeof object.i64[keys[i]] === "number")
                            message.i64[keys[i]] = object.i64[keys[i]];
                        else if (typeof object.i64[keys[i]] === "object")
                            message.i64[keys[i]] = new $util.LongBits(object.i64[keys[i]].low >>> 0, object.i64[keys[i]].high >>> 0).toNumber();
                }
                return message;
            };

            /**
             * Creates a plain object from a SetInt64 message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.SetInt64
             * @static
             * @param {api.common.SetInt64} message SetInt64
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetInt64.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.i64 = {};
                let keys2;
                if (message.i64 && (keys2 = Object.keys(message.i64)).length) {
                    object.i64 = {};
                    for (let j = 0; j < keys2.length; ++j)
                        if (typeof message.i64[keys2[j]] === "number")
                            object.i64[keys2[j]] = options.longs === String ? String(message.i64[keys2[j]]) : message.i64[keys2[j]];
                        else
                            object.i64[keys2[j]] = options.longs === String ? $util.Long.prototype.toString.call(message.i64[keys2[j]]) : options.longs === Number ? new $util.LongBits(message.i64[keys2[j]].low >>> 0, message.i64[keys2[j]].high >>> 0).toNumber() : message.i64[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this SetInt64 to JSON.
             * @function toJSON
             * @memberof api.common.SetInt64
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetInt64.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SetInt64
             * @function getTypeUrl
             * @memberof api.common.SetInt64
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SetInt64.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.SetInt64";
            };

            return SetInt64;
        })();

        common.SetString = (function() {

            /**
             * Properties of a SetString.
             * @memberof api.common
             * @interface ISetString
             * @property {Object.<string,Long>|null} [str] SetString str
             */

            /**
             * Constructs a new SetString.
             * @memberof api.common
             * @classdesc Represents a SetString.
             * @implements ISetString
             * @constructor
             * @param {api.common.ISetString=} [properties] Properties to set
             */
            function SetString(properties) {
                this.str = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetString str.
             * @member {Object.<string,Long>} str
             * @memberof api.common.SetString
             * @instance
             */
            SetString.prototype.str = $util.emptyObject;

            /**
             * Creates a new SetString instance using the specified properties.
             * @function create
             * @memberof api.common.SetString
             * @static
             * @param {api.common.ISetString=} [properties] Properties to set
             * @returns {api.common.SetString} SetString instance
             */
            SetString.create = function create(properties) {
                return new SetString(properties);
            };

            /**
             * Encodes the specified SetString message. Does not implicitly {@link api.common.SetString.verify|verify} messages.
             * @function encode
             * @memberof api.common.SetString
             * @static
             * @param {api.common.ISetString} message SetString message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetString.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.str != null && Object.hasOwnProperty.call(message, "str"))
                    for (let keys = Object.keys(message.str), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int64(message.str[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SetString message, length delimited. Does not implicitly {@link api.common.SetString.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.SetString
             * @static
             * @param {api.common.ISetString} message SetString message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetString.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SetString message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.SetString
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.SetString} SetString
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetString.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.SetString(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (message.str === $util.emptyObject)
                                message.str = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = 0;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.str[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SetString message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.SetString
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.SetString} SetString
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetString.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SetString message.
             * @function verify
             * @memberof api.common.SetString
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetString.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.str != null && message.hasOwnProperty("str")) {
                    if (!$util.isObject(message.str))
                        return "str: object expected";
                    let key = Object.keys(message.str);
                    for (let i = 0; i < key.length; ++i)
                        if (!$util.isInteger(message.str[key[i]]) && !(message.str[key[i]] && $util.isInteger(message.str[key[i]].low) && $util.isInteger(message.str[key[i]].high)))
                            return "str: integer|Long{k:string} expected";
                }
                return null;
            };

            /**
             * Creates a SetString message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.SetString
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.SetString} SetString
             */
            SetString.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.SetString)
                    return object;
                let message = new $root.api.common.SetString();
                if (object.str) {
                    if (typeof object.str !== "object")
                        throw TypeError(".api.common.SetString.str: object expected");
                    message.str = {};
                    for (let keys = Object.keys(object.str), i = 0; i < keys.length; ++i)
                        if ($util.Long)
                            (message.str[keys[i]] = $util.Long.fromValue(object.str[keys[i]])).unsigned = false;
                        else if (typeof object.str[keys[i]] === "string")
                            message.str[keys[i]] = parseInt(object.str[keys[i]], 10);
                        else if (typeof object.str[keys[i]] === "number")
                            message.str[keys[i]] = object.str[keys[i]];
                        else if (typeof object.str[keys[i]] === "object")
                            message.str[keys[i]] = new $util.LongBits(object.str[keys[i]].low >>> 0, object.str[keys[i]].high >>> 0).toNumber();
                }
                return message;
            };

            /**
             * Creates a plain object from a SetString message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.SetString
             * @static
             * @param {api.common.SetString} message SetString
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetString.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.str = {};
                let keys2;
                if (message.str && (keys2 = Object.keys(message.str)).length) {
                    object.str = {};
                    for (let j = 0; j < keys2.length; ++j)
                        if (typeof message.str[keys2[j]] === "number")
                            object.str[keys2[j]] = options.longs === String ? String(message.str[keys2[j]]) : message.str[keys2[j]];
                        else
                            object.str[keys2[j]] = options.longs === String ? $util.Long.prototype.toString.call(message.str[keys2[j]]) : options.longs === Number ? new $util.LongBits(message.str[keys2[j]].low >>> 0, message.str[keys2[j]].high >>> 0).toNumber() : message.str[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this SetString to JSON.
             * @function toJSON
             * @memberof api.common.SetString
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetString.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SetString
             * @function getTypeUrl
             * @memberof api.common.SetString
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SetString.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.SetString";
            };

            return SetString;
        })();

        common.ChatDetail = (function() {

            /**
             * Properties of a ChatDetail.
             * @memberof api.common
             * @interface IChatDetail
             * @property {Long|null} [chatId] ChatDetail chatId
             * @property {number|null} [entranceId] ChatDetail entranceId
             * @property {string|null} [entranceName] ChatDetail entranceName
             * @property {string|null} [platform] ChatDetail platform
             * @property {string|null} [ip] ChatDetail ip
             * @property {google.protobuf.ITimestamp|null} [createAt] ChatDetail createAt
             * @property {google.protobuf.ITimestamp|null} [updateAt] ChatDetail updateAt
             * @property {string|null} [name] ChatDetail name
             * @property {string|null} [avatar] ChatDetail avatar
             * @property {string|null} [nick] ChatDetail nick
             * @property {string|null} [appeal] ChatDetail appeal
             * @property {google.protobuf.ITimestamp|null} [resetAt] ChatDetail resetAt
             */

            /**
             * Constructs a new ChatDetail.
             * @memberof api.common
             * @classdesc Represents a ChatDetail.
             * @implements IChatDetail
             * @constructor
             * @param {api.common.IChatDetail=} [properties] Properties to set
             */
            function ChatDetail(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChatDetail chatId.
             * @member {Long} chatId
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ChatDetail entranceId.
             * @member {number} entranceId
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.entranceId = 0;

            /**
             * ChatDetail entranceName.
             * @member {string} entranceName
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.entranceName = "";

            /**
             * ChatDetail platform.
             * @member {string} platform
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.platform = "";

            /**
             * ChatDetail ip.
             * @member {string} ip
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.ip = "";

            /**
             * ChatDetail createAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createAt
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.createAt = null;

            /**
             * ChatDetail updateAt.
             * @member {google.protobuf.ITimestamp|null|undefined} updateAt
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.updateAt = null;

            /**
             * ChatDetail name.
             * @member {string} name
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.name = "";

            /**
             * ChatDetail avatar.
             * @member {string} avatar
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.avatar = "";

            /**
             * ChatDetail nick.
             * @member {string} nick
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.nick = "";

            /**
             * ChatDetail appeal.
             * @member {string} appeal
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.appeal = "";

            /**
             * ChatDetail resetAt.
             * @member {google.protobuf.ITimestamp|null|undefined} resetAt
             * @memberof api.common.ChatDetail
             * @instance
             */
            ChatDetail.prototype.resetAt = null;

            /**
             * Creates a new ChatDetail instance using the specified properties.
             * @function create
             * @memberof api.common.ChatDetail
             * @static
             * @param {api.common.IChatDetail=} [properties] Properties to set
             * @returns {api.common.ChatDetail} ChatDetail instance
             */
            ChatDetail.create = function create(properties) {
                return new ChatDetail(properties);
            };

            /**
             * Encodes the specified ChatDetail message. Does not implicitly {@link api.common.ChatDetail.verify|verify} messages.
             * @function encode
             * @memberof api.common.ChatDetail
             * @static
             * @param {api.common.IChatDetail} message ChatDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatDetail.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.entranceId != null && Object.hasOwnProperty.call(message, "entranceId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.entranceId);
                if (message.entranceName != null && Object.hasOwnProperty.call(message, "entranceName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.entranceName);
                if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.platform);
                if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.ip);
                if (message.createAt != null && Object.hasOwnProperty.call(message, "createAt"))
                    $root.google.protobuf.Timestamp.encode(message.createAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.updateAt != null && Object.hasOwnProperty.call(message, "updateAt"))
                    $root.google.protobuf.Timestamp.encode(message.updateAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.name);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.avatar);
                if (message.nick != null && Object.hasOwnProperty.call(message, "nick"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.nick);
                if (message.appeal != null && Object.hasOwnProperty.call(message, "appeal"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.appeal);
                if (message.resetAt != null && Object.hasOwnProperty.call(message, "resetAt"))
                    $root.google.protobuf.Timestamp.encode(message.resetAt, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ChatDetail message, length delimited. Does not implicitly {@link api.common.ChatDetail.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.ChatDetail
             * @static
             * @param {api.common.IChatDetail} message ChatDetail message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatDetail.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ChatDetail message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.ChatDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.ChatDetail} ChatDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatDetail.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.ChatDetail();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.entranceId = reader.uint32();
                            break;
                        }
                    case 3: {
                            message.entranceName = reader.string();
                            break;
                        }
                    case 4: {
                            message.platform = reader.string();
                            break;
                        }
                    case 5: {
                            message.ip = reader.string();
                            break;
                        }
                    case 6: {
                            message.createAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.updateAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.name = reader.string();
                            break;
                        }
                    case 9: {
                            message.avatar = reader.string();
                            break;
                        }
                    case 10: {
                            message.nick = reader.string();
                            break;
                        }
                    case 11: {
                            message.appeal = reader.string();
                            break;
                        }
                    case 12: {
                            message.resetAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ChatDetail message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.ChatDetail
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.ChatDetail} ChatDetail
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatDetail.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ChatDetail message.
             * @function verify
             * @memberof api.common.ChatDetail
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChatDetail.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.entranceId != null && message.hasOwnProperty("entranceId"))
                    if (!$util.isInteger(message.entranceId))
                        return "entranceId: integer expected";
                if (message.entranceName != null && message.hasOwnProperty("entranceName"))
                    if (!$util.isString(message.entranceName))
                        return "entranceName: string expected";
                if (message.platform != null && message.hasOwnProperty("platform"))
                    if (!$util.isString(message.platform))
                        return "platform: string expected";
                if (message.ip != null && message.hasOwnProperty("ip"))
                    if (!$util.isString(message.ip))
                        return "ip: string expected";
                if (message.createAt != null && message.hasOwnProperty("createAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.createAt);
                    if (error)
                        return "createAt." + error;
                }
                if (message.updateAt != null && message.hasOwnProperty("updateAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.updateAt);
                    if (error)
                        return "updateAt." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                if (message.nick != null && message.hasOwnProperty("nick"))
                    if (!$util.isString(message.nick))
                        return "nick: string expected";
                if (message.appeal != null && message.hasOwnProperty("appeal"))
                    if (!$util.isString(message.appeal))
                        return "appeal: string expected";
                if (message.resetAt != null && message.hasOwnProperty("resetAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.resetAt);
                    if (error)
                        return "resetAt." + error;
                }
                return null;
            };

            /**
             * Creates a ChatDetail message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.ChatDetail
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.ChatDetail} ChatDetail
             */
            ChatDetail.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.ChatDetail)
                    return object;
                let message = new $root.api.common.ChatDetail();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.entranceId != null)
                    message.entranceId = object.entranceId >>> 0;
                if (object.entranceName != null)
                    message.entranceName = String(object.entranceName);
                if (object.platform != null)
                    message.platform = String(object.platform);
                if (object.ip != null)
                    message.ip = String(object.ip);
                if (object.createAt != null) {
                    if (typeof object.createAt !== "object")
                        throw TypeError(".api.common.ChatDetail.createAt: object expected");
                    message.createAt = $root.google.protobuf.Timestamp.fromObject(object.createAt);
                }
                if (object.updateAt != null) {
                    if (typeof object.updateAt !== "object")
                        throw TypeError(".api.common.ChatDetail.updateAt: object expected");
                    message.updateAt = $root.google.protobuf.Timestamp.fromObject(object.updateAt);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                if (object.nick != null)
                    message.nick = String(object.nick);
                if (object.appeal != null)
                    message.appeal = String(object.appeal);
                if (object.resetAt != null) {
                    if (typeof object.resetAt !== "object")
                        throw TypeError(".api.common.ChatDetail.resetAt: object expected");
                    message.resetAt = $root.google.protobuf.Timestamp.fromObject(object.resetAt);
                }
                return message;
            };

            /**
             * Creates a plain object from a ChatDetail message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.ChatDetail
             * @static
             * @param {api.common.ChatDetail} message ChatDetail
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChatDetail.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.entranceId = 0;
                    object.entranceName = "";
                    object.platform = "";
                    object.ip = "";
                    object.createAt = null;
                    object.updateAt = null;
                    object.name = "";
                    object.avatar = "";
                    object.nick = "";
                    object.appeal = "";
                    object.resetAt = null;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.entranceId != null && message.hasOwnProperty("entranceId"))
                    object.entranceId = message.entranceId;
                if (message.entranceName != null && message.hasOwnProperty("entranceName"))
                    object.entranceName = message.entranceName;
                if (message.platform != null && message.hasOwnProperty("platform"))
                    object.platform = message.platform;
                if (message.ip != null && message.hasOwnProperty("ip"))
                    object.ip = message.ip;
                if (message.createAt != null && message.hasOwnProperty("createAt"))
                    object.createAt = $root.google.protobuf.Timestamp.toObject(message.createAt, options);
                if (message.updateAt != null && message.hasOwnProperty("updateAt"))
                    object.updateAt = $root.google.protobuf.Timestamp.toObject(message.updateAt, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                if (message.nick != null && message.hasOwnProperty("nick"))
                    object.nick = message.nick;
                if (message.appeal != null && message.hasOwnProperty("appeal"))
                    object.appeal = message.appeal;
                if (message.resetAt != null && message.hasOwnProperty("resetAt"))
                    object.resetAt = $root.google.protobuf.Timestamp.toObject(message.resetAt, options);
                return object;
            };

            /**
             * Converts this ChatDetail to JSON.
             * @function toJSON
             * @memberof api.common.ChatDetail
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChatDetail.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChatDetail
             * @function getTypeUrl
             * @memberof api.common.ChatDetail
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChatDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.ChatDetail";
            };

            return ChatDetail;
        })();

        common.ChatItem = (function() {

            /**
             * Properties of a ChatItem.
             * @memberof api.common
             * @interface IChatItem
             * @property {Long|null} [chatId] ChatItem chatId
             * @property {api.common.ChatState|null} [state] ChatItem state
             * @property {number|null} [unread] ChatItem unread
             * @property {api.common.IMessage|null} [latestMsg] ChatItem latestMsg
             * @property {google.protobuf.ITimestamp|null} [createAt] ChatItem createAt
             * @property {google.protobuf.ITimestamp|null} [serviceAt] ChatItem serviceAt
             * @property {api.common.IChatDetail|null} [detail] ChatItem detail
             * @property {google.protobuf.ITimestamp|null} [resetAt] ChatItem resetAt
             */

            /**
             * Constructs a new ChatItem.
             * @memberof api.common
             * @classdesc Represents a ChatItem.
             * @implements IChatItem
             * @constructor
             * @param {api.common.IChatItem=} [properties] Properties to set
             */
            function ChatItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChatItem chatId.
             * @member {Long} chatId
             * @memberof api.common.ChatItem
             * @instance
             */
            ChatItem.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ChatItem state.
             * @member {api.common.ChatState} state
             * @memberof api.common.ChatItem
             * @instance
             */
            ChatItem.prototype.state = 0;

            /**
             * ChatItem unread.
             * @member {number} unread
             * @memberof api.common.ChatItem
             * @instance
             */
            ChatItem.prototype.unread = 0;

            /**
             * ChatItem latestMsg.
             * @member {api.common.IMessage|null|undefined} latestMsg
             * @memberof api.common.ChatItem
             * @instance
             */
            ChatItem.prototype.latestMsg = null;

            /**
             * ChatItem createAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createAt
             * @memberof api.common.ChatItem
             * @instance
             */
            ChatItem.prototype.createAt = null;

            /**
             * ChatItem serviceAt.
             * @member {google.protobuf.ITimestamp|null|undefined} serviceAt
             * @memberof api.common.ChatItem
             * @instance
             */
            ChatItem.prototype.serviceAt = null;

            /**
             * ChatItem detail.
             * @member {api.common.IChatDetail|null|undefined} detail
             * @memberof api.common.ChatItem
             * @instance
             */
            ChatItem.prototype.detail = null;

            /**
             * ChatItem resetAt.
             * @member {google.protobuf.ITimestamp|null|undefined} resetAt
             * @memberof api.common.ChatItem
             * @instance
             */
            ChatItem.prototype.resetAt = null;

            /**
             * Creates a new ChatItem instance using the specified properties.
             * @function create
             * @memberof api.common.ChatItem
             * @static
             * @param {api.common.IChatItem=} [properties] Properties to set
             * @returns {api.common.ChatItem} ChatItem instance
             */
            ChatItem.create = function create(properties) {
                return new ChatItem(properties);
            };

            /**
             * Encodes the specified ChatItem message. Does not implicitly {@link api.common.ChatItem.verify|verify} messages.
             * @function encode
             * @memberof api.common.ChatItem
             * @static
             * @param {api.common.IChatItem} message ChatItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
                if (message.unread != null && Object.hasOwnProperty.call(message, "unread"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.unread);
                if (message.latestMsg != null && Object.hasOwnProperty.call(message, "latestMsg"))
                    $root.api.common.Message.encode(message.latestMsg, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.createAt != null && Object.hasOwnProperty.call(message, "createAt"))
                    $root.google.protobuf.Timestamp.encode(message.createAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.serviceAt != null && Object.hasOwnProperty.call(message, "serviceAt"))
                    $root.google.protobuf.Timestamp.encode(message.serviceAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                    $root.api.common.ChatDetail.encode(message.detail, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.resetAt != null && Object.hasOwnProperty.call(message, "resetAt"))
                    $root.google.protobuf.Timestamp.encode(message.resetAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ChatItem message, length delimited. Does not implicitly {@link api.common.ChatItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.ChatItem
             * @static
             * @param {api.common.IChatItem} message ChatItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ChatItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.ChatItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.ChatItem} ChatItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.ChatItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.state = reader.int32();
                            break;
                        }
                    case 3: {
                            message.unread = reader.int32();
                            break;
                        }
                    case 4: {
                            message.latestMsg = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.createAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.serviceAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.detail = $root.api.common.ChatDetail.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.resetAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ChatItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.ChatItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.ChatItem} ChatItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ChatItem message.
             * @function verify
             * @memberof api.common.ChatItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChatItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.state != null && message.hasOwnProperty("state"))
                    switch (message.state) {
                    default:
                        return "state: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.unread != null && message.hasOwnProperty("unread"))
                    if (!$util.isInteger(message.unread))
                        return "unread: integer expected";
                if (message.latestMsg != null && message.hasOwnProperty("latestMsg")) {
                    let error = $root.api.common.Message.verify(message.latestMsg);
                    if (error)
                        return "latestMsg." + error;
                }
                if (message.createAt != null && message.hasOwnProperty("createAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.createAt);
                    if (error)
                        return "createAt." + error;
                }
                if (message.serviceAt != null && message.hasOwnProperty("serviceAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.serviceAt);
                    if (error)
                        return "serviceAt." + error;
                }
                if (message.detail != null && message.hasOwnProperty("detail")) {
                    let error = $root.api.common.ChatDetail.verify(message.detail);
                    if (error)
                        return "detail." + error;
                }
                if (message.resetAt != null && message.hasOwnProperty("resetAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.resetAt);
                    if (error)
                        return "resetAt." + error;
                }
                return null;
            };

            /**
             * Creates a ChatItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.ChatItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.ChatItem} ChatItem
             */
            ChatItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.ChatItem)
                    return object;
                let message = new $root.api.common.ChatItem();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                switch (object.state) {
                default:
                    if (typeof object.state === "number") {
                        message.state = object.state;
                        break;
                    }
                    break;
                case "CHAT_STATE_COMMON":
                case 0:
                    message.state = 0;
                    break;
                case "CHAT_STATE_TRANSFER":
                case 1:
                    message.state = 1;
                    break;
                case "CHAT_STATE_UNPROCESSED_3MIN":
                case 2:
                    message.state = 2;
                    break;
                case "CHAT_STATE_TIMEOUT":
                case 3:
                    message.state = 3;
                    break;
                case "CHAT_STATE_PROCESSED":
                case 4:
                    message.state = 4;
                    break;
                case "CHAT_STATE_BLACKLIST_APPLY":
                case 5:
                    message.state = 5;
                    break;
                case "CHAT_STATE_BLACKLIST_CONFIRMED":
                case 6:
                    message.state = 6;
                    break;
                }
                if (object.unread != null)
                    message.unread = object.unread | 0;
                if (object.latestMsg != null) {
                    if (typeof object.latestMsg !== "object")
                        throw TypeError(".api.common.ChatItem.latestMsg: object expected");
                    message.latestMsg = $root.api.common.Message.fromObject(object.latestMsg);
                }
                if (object.createAt != null) {
                    if (typeof object.createAt !== "object")
                        throw TypeError(".api.common.ChatItem.createAt: object expected");
                    message.createAt = $root.google.protobuf.Timestamp.fromObject(object.createAt);
                }
                if (object.serviceAt != null) {
                    if (typeof object.serviceAt !== "object")
                        throw TypeError(".api.common.ChatItem.serviceAt: object expected");
                    message.serviceAt = $root.google.protobuf.Timestamp.fromObject(object.serviceAt);
                }
                if (object.detail != null) {
                    if (typeof object.detail !== "object")
                        throw TypeError(".api.common.ChatItem.detail: object expected");
                    message.detail = $root.api.common.ChatDetail.fromObject(object.detail);
                }
                if (object.resetAt != null) {
                    if (typeof object.resetAt !== "object")
                        throw TypeError(".api.common.ChatItem.resetAt: object expected");
                    message.resetAt = $root.google.protobuf.Timestamp.fromObject(object.resetAt);
                }
                return message;
            };

            /**
             * Creates a plain object from a ChatItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.ChatItem
             * @static
             * @param {api.common.ChatItem} message ChatItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChatItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.state = options.enums === String ? "CHAT_STATE_COMMON" : 0;
                    object.unread = 0;
                    object.latestMsg = null;
                    object.createAt = null;
                    object.serviceAt = null;
                    object.detail = null;
                    object.resetAt = null;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.state != null && message.hasOwnProperty("state"))
                    object.state = options.enums === String ? $root.api.common.ChatState[message.state] === undefined ? message.state : $root.api.common.ChatState[message.state] : message.state;
                if (message.unread != null && message.hasOwnProperty("unread"))
                    object.unread = message.unread;
                if (message.latestMsg != null && message.hasOwnProperty("latestMsg"))
                    object.latestMsg = $root.api.common.Message.toObject(message.latestMsg, options);
                if (message.createAt != null && message.hasOwnProperty("createAt"))
                    object.createAt = $root.google.protobuf.Timestamp.toObject(message.createAt, options);
                if (message.serviceAt != null && message.hasOwnProperty("serviceAt"))
                    object.serviceAt = $root.google.protobuf.Timestamp.toObject(message.serviceAt, options);
                if (message.detail != null && message.hasOwnProperty("detail"))
                    object.detail = $root.api.common.ChatDetail.toObject(message.detail, options);
                if (message.resetAt != null && message.hasOwnProperty("resetAt"))
                    object.resetAt = $root.google.protobuf.Timestamp.toObject(message.resetAt, options);
                return object;
            };

            /**
             * Converts this ChatItem to JSON.
             * @function toJSON
             * @memberof api.common.ChatItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChatItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChatItem
             * @function getTypeUrl
             * @memberof api.common.ChatItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChatItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.ChatItem";
            };

            return ChatItem;
        })();

        /**
         * ClientType enum.
         * @name api.common.ClientType
         * @enum {number}
         * @property {number} CLIENT_TYPE_UNKNOWN=0 CLIENT_TYPE_UNKNOWN value
         * @property {number} CLIENT_TYPE_WORKER_BEGIN=1 CLIENT_TYPE_WORKER_BEGIN value
         * @property {number} CLIENT_TYPE_WORKER_PC=2 CLIENT_TYPE_WORKER_PC value
         * @property {number} CLIENT_TYPE_WORKER_END=100 CLIENT_TYPE_WORKER_END value
         * @property {number} CLIENT_TYPE_USER_BEGIN=101 CLIENT_TYPE_USER_BEGIN value
         * @property {number} CLIENT_TYPE_USER_H5=102 CLIENT_TYPE_USER_H5 value
         * @property {number} CLIENT_TYPE_USER_WEB=103 CLIENT_TYPE_USER_WEB value
         * @property {number} CLIENT_TYPE_USER_END=200 CLIENT_TYPE_USER_END value
         */
        common.ClientType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CLIENT_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "CLIENT_TYPE_WORKER_BEGIN"] = 1;
            values[valuesById[2] = "CLIENT_TYPE_WORKER_PC"] = 2;
            values[valuesById[100] = "CLIENT_TYPE_WORKER_END"] = 100;
            values[valuesById[101] = "CLIENT_TYPE_USER_BEGIN"] = 101;
            values[valuesById[102] = "CLIENT_TYPE_USER_H5"] = 102;
            values[valuesById[103] = "CLIENT_TYPE_USER_WEB"] = 103;
            values[valuesById[200] = "CLIENT_TYPE_USER_END"] = 200;
            return values;
        })();

        common.Entrance = (function() {

            /**
             * Properties of an Entrance.
             * @memberof api.common
             * @interface IEntrance
             * @property {number|null} [entranceId] Entrance entranceId
             * @property {string|null} [name] Entrance name
             * @property {string|null} [nick] Entrance nick
             * @property {string|null} [avatar] Entrance avatar
             * @property {string|null} [guide] Entrance guide
             * @property {Array.<number>|null} [consultIds] Entrance consultIds
             * @property {string|null} [changeDefaultTime] Entrance changeDefaultTime
             * @property {string|null} [h5Link] Entrance h5Link
             * @property {string|null} [webLink] Entrance webLink
             * @property {string|null} [internalParameters] Entrance internalParameters
             * @property {string|null} [appParameters] Entrance appParameters
             * @property {string|null} [accessLink] Entrance accessLink
             * @property {number|null} [defaultConsultId] Entrance defaultConsultId
             * @property {string|null} [certificate] Entrance certificate
             * @property {Array.<string>|null} [workerName] Entrance workerName
             */

            /**
             * Constructs a new Entrance.
             * @memberof api.common
             * @classdesc Represents an Entrance.
             * @implements IEntrance
             * @constructor
             * @param {api.common.IEntrance=} [properties] Properties to set
             */
            function Entrance(properties) {
                this.consultIds = [];
                this.workerName = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Entrance entranceId.
             * @member {number} entranceId
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.entranceId = 0;

            /**
             * Entrance name.
             * @member {string} name
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.name = "";

            /**
             * Entrance nick.
             * @member {string} nick
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.nick = "";

            /**
             * Entrance avatar.
             * @member {string} avatar
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.avatar = "";

            /**
             * Entrance guide.
             * @member {string} guide
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.guide = "";

            /**
             * Entrance consultIds.
             * @member {Array.<number>} consultIds
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.consultIds = $util.emptyArray;

            /**
             * Entrance changeDefaultTime.
             * @member {string} changeDefaultTime
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.changeDefaultTime = "";

            /**
             * Entrance h5Link.
             * @member {string} h5Link
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.h5Link = "";

            /**
             * Entrance webLink.
             * @member {string} webLink
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.webLink = "";

            /**
             * Entrance internalParameters.
             * @member {string} internalParameters
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.internalParameters = "";

            /**
             * Entrance appParameters.
             * @member {string} appParameters
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.appParameters = "";

            /**
             * Entrance accessLink.
             * @member {string} accessLink
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.accessLink = "";

            /**
             * Entrance defaultConsultId.
             * @member {number} defaultConsultId
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.defaultConsultId = 0;

            /**
             * Entrance certificate.
             * @member {string} certificate
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.certificate = "";

            /**
             * Entrance workerName.
             * @member {Array.<string>} workerName
             * @memberof api.common.Entrance
             * @instance
             */
            Entrance.prototype.workerName = $util.emptyArray;

            /**
             * Creates a new Entrance instance using the specified properties.
             * @function create
             * @memberof api.common.Entrance
             * @static
             * @param {api.common.IEntrance=} [properties] Properties to set
             * @returns {api.common.Entrance} Entrance instance
             */
            Entrance.create = function create(properties) {
                return new Entrance(properties);
            };

            /**
             * Encodes the specified Entrance message. Does not implicitly {@link api.common.Entrance.verify|verify} messages.
             * @function encode
             * @memberof api.common.Entrance
             * @static
             * @param {api.common.IEntrance} message Entrance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Entrance.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.entranceId != null && Object.hasOwnProperty.call(message, "entranceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.entranceId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.nick != null && Object.hasOwnProperty.call(message, "nick"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.nick);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.avatar);
                if (message.guide != null && Object.hasOwnProperty.call(message, "guide"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.guide);
                if (message.consultIds != null && message.consultIds.length) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork();
                    for (let i = 0; i < message.consultIds.length; ++i)
                        writer.int32(message.consultIds[i]);
                    writer.ldelim();
                }
                if (message.changeDefaultTime != null && Object.hasOwnProperty.call(message, "changeDefaultTime"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.changeDefaultTime);
                if (message.h5Link != null && Object.hasOwnProperty.call(message, "h5Link"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.h5Link);
                if (message.webLink != null && Object.hasOwnProperty.call(message, "webLink"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.webLink);
                if (message.internalParameters != null && Object.hasOwnProperty.call(message, "internalParameters"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.internalParameters);
                if (message.appParameters != null && Object.hasOwnProperty.call(message, "appParameters"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.appParameters);
                if (message.accessLink != null && Object.hasOwnProperty.call(message, "accessLink"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.accessLink);
                if (message.defaultConsultId != null && Object.hasOwnProperty.call(message, "defaultConsultId"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.defaultConsultId);
                if (message.certificate != null && Object.hasOwnProperty.call(message, "certificate"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.certificate);
                if (message.workerName != null && message.workerName.length)
                    for (let i = 0; i < message.workerName.length; ++i)
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.workerName[i]);
                return writer;
            };

            /**
             * Encodes the specified Entrance message, length delimited. Does not implicitly {@link api.common.Entrance.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Entrance
             * @static
             * @param {api.common.IEntrance} message Entrance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Entrance.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Entrance message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Entrance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Entrance} Entrance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Entrance.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Entrance();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.entranceId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.nick = reader.string();
                            break;
                        }
                    case 4: {
                            message.avatar = reader.string();
                            break;
                        }
                    case 5: {
                            message.guide = reader.string();
                            break;
                        }
                    case 6: {
                            if (!(message.consultIds && message.consultIds.length))
                                message.consultIds = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.consultIds.push(reader.int32());
                            } else
                                message.consultIds.push(reader.int32());
                            break;
                        }
                    case 7: {
                            message.changeDefaultTime = reader.string();
                            break;
                        }
                    case 8: {
                            message.h5Link = reader.string();
                            break;
                        }
                    case 9: {
                            message.webLink = reader.string();
                            break;
                        }
                    case 10: {
                            message.internalParameters = reader.string();
                            break;
                        }
                    case 11: {
                            message.appParameters = reader.string();
                            break;
                        }
                    case 12: {
                            message.accessLink = reader.string();
                            break;
                        }
                    case 13: {
                            message.defaultConsultId = reader.int32();
                            break;
                        }
                    case 14: {
                            message.certificate = reader.string();
                            break;
                        }
                    case 15: {
                            if (!(message.workerName && message.workerName.length))
                                message.workerName = [];
                            message.workerName.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Entrance message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Entrance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Entrance} Entrance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Entrance.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Entrance message.
             * @function verify
             * @memberof api.common.Entrance
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Entrance.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.entranceId != null && message.hasOwnProperty("entranceId"))
                    if (!$util.isInteger(message.entranceId))
                        return "entranceId: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.nick != null && message.hasOwnProperty("nick"))
                    if (!$util.isString(message.nick))
                        return "nick: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                if (message.guide != null && message.hasOwnProperty("guide"))
                    if (!$util.isString(message.guide))
                        return "guide: string expected";
                if (message.consultIds != null && message.hasOwnProperty("consultIds")) {
                    if (!Array.isArray(message.consultIds))
                        return "consultIds: array expected";
                    for (let i = 0; i < message.consultIds.length; ++i)
                        if (!$util.isInteger(message.consultIds[i]))
                            return "consultIds: integer[] expected";
                }
                if (message.changeDefaultTime != null && message.hasOwnProperty("changeDefaultTime"))
                    if (!$util.isString(message.changeDefaultTime))
                        return "changeDefaultTime: string expected";
                if (message.h5Link != null && message.hasOwnProperty("h5Link"))
                    if (!$util.isString(message.h5Link))
                        return "h5Link: string expected";
                if (message.webLink != null && message.hasOwnProperty("webLink"))
                    if (!$util.isString(message.webLink))
                        return "webLink: string expected";
                if (message.internalParameters != null && message.hasOwnProperty("internalParameters"))
                    if (!$util.isString(message.internalParameters))
                        return "internalParameters: string expected";
                if (message.appParameters != null && message.hasOwnProperty("appParameters"))
                    if (!$util.isString(message.appParameters))
                        return "appParameters: string expected";
                if (message.accessLink != null && message.hasOwnProperty("accessLink"))
                    if (!$util.isString(message.accessLink))
                        return "accessLink: string expected";
                if (message.defaultConsultId != null && message.hasOwnProperty("defaultConsultId"))
                    if (!$util.isInteger(message.defaultConsultId))
                        return "defaultConsultId: integer expected";
                if (message.certificate != null && message.hasOwnProperty("certificate"))
                    if (!$util.isString(message.certificate))
                        return "certificate: string expected";
                if (message.workerName != null && message.hasOwnProperty("workerName")) {
                    if (!Array.isArray(message.workerName))
                        return "workerName: array expected";
                    for (let i = 0; i < message.workerName.length; ++i)
                        if (!$util.isString(message.workerName[i]))
                            return "workerName: string[] expected";
                }
                return null;
            };

            /**
             * Creates an Entrance message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Entrance
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Entrance} Entrance
             */
            Entrance.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Entrance)
                    return object;
                let message = new $root.api.common.Entrance();
                if (object.entranceId != null)
                    message.entranceId = object.entranceId >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.nick != null)
                    message.nick = String(object.nick);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                if (object.guide != null)
                    message.guide = String(object.guide);
                if (object.consultIds) {
                    if (!Array.isArray(object.consultIds))
                        throw TypeError(".api.common.Entrance.consultIds: array expected");
                    message.consultIds = [];
                    for (let i = 0; i < object.consultIds.length; ++i)
                        message.consultIds[i] = object.consultIds[i] | 0;
                }
                if (object.changeDefaultTime != null)
                    message.changeDefaultTime = String(object.changeDefaultTime);
                if (object.h5Link != null)
                    message.h5Link = String(object.h5Link);
                if (object.webLink != null)
                    message.webLink = String(object.webLink);
                if (object.internalParameters != null)
                    message.internalParameters = String(object.internalParameters);
                if (object.appParameters != null)
                    message.appParameters = String(object.appParameters);
                if (object.accessLink != null)
                    message.accessLink = String(object.accessLink);
                if (object.defaultConsultId != null)
                    message.defaultConsultId = object.defaultConsultId | 0;
                if (object.certificate != null)
                    message.certificate = String(object.certificate);
                if (object.workerName) {
                    if (!Array.isArray(object.workerName))
                        throw TypeError(".api.common.Entrance.workerName: array expected");
                    message.workerName = [];
                    for (let i = 0; i < object.workerName.length; ++i)
                        message.workerName[i] = String(object.workerName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from an Entrance message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Entrance
             * @static
             * @param {api.common.Entrance} message Entrance
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Entrance.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.consultIds = [];
                    object.workerName = [];
                }
                if (options.defaults) {
                    object.entranceId = 0;
                    object.name = "";
                    object.nick = "";
                    object.avatar = "";
                    object.guide = "";
                    object.changeDefaultTime = "";
                    object.h5Link = "";
                    object.webLink = "";
                    object.internalParameters = "";
                    object.appParameters = "";
                    object.accessLink = "";
                    object.defaultConsultId = 0;
                    object.certificate = "";
                }
                if (message.entranceId != null && message.hasOwnProperty("entranceId"))
                    object.entranceId = message.entranceId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.nick != null && message.hasOwnProperty("nick"))
                    object.nick = message.nick;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                if (message.guide != null && message.hasOwnProperty("guide"))
                    object.guide = message.guide;
                if (message.consultIds && message.consultIds.length) {
                    object.consultIds = [];
                    for (let j = 0; j < message.consultIds.length; ++j)
                        object.consultIds[j] = message.consultIds[j];
                }
                if (message.changeDefaultTime != null && message.hasOwnProperty("changeDefaultTime"))
                    object.changeDefaultTime = message.changeDefaultTime;
                if (message.h5Link != null && message.hasOwnProperty("h5Link"))
                    object.h5Link = message.h5Link;
                if (message.webLink != null && message.hasOwnProperty("webLink"))
                    object.webLink = message.webLink;
                if (message.internalParameters != null && message.hasOwnProperty("internalParameters"))
                    object.internalParameters = message.internalParameters;
                if (message.appParameters != null && message.hasOwnProperty("appParameters"))
                    object.appParameters = message.appParameters;
                if (message.accessLink != null && message.hasOwnProperty("accessLink"))
                    object.accessLink = message.accessLink;
                if (message.defaultConsultId != null && message.hasOwnProperty("defaultConsultId"))
                    object.defaultConsultId = message.defaultConsultId;
                if (message.certificate != null && message.hasOwnProperty("certificate"))
                    object.certificate = message.certificate;
                if (message.workerName && message.workerName.length) {
                    object.workerName = [];
                    for (let j = 0; j < message.workerName.length; ++j)
                        object.workerName[j] = message.workerName[j];
                }
                return object;
            };

            /**
             * Converts this Entrance to JSON.
             * @function toJSON
             * @memberof api.common.Entrance
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Entrance.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Entrance
             * @function getTypeUrl
             * @memberof api.common.Entrance
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Entrance.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Entrance";
            };

            return Entrance;
        })();

        common.Consult = (function() {

            /**
             * Properties of a Consult.
             * @memberof api.common
             * @interface IConsult
             * @property {number|null} [consultId] Consult consultId
             * @property {string|null} [name] Consult name
             * @property {string|null} [guide] Consult guide
             * @property {Array.<Long>|null} [workerGroupIds] Consult workerGroupIds
             * @property {Array.<number>|null} [workerIds] Consult workerIds
             * @property {string|null} [workerGroupNames] Consult workerGroupNames
             * @property {string|null} [workerNames] Consult workerNames
             */

            /**
             * Constructs a new Consult.
             * @memberof api.common
             * @classdesc Represents a Consult.
             * @implements IConsult
             * @constructor
             * @param {api.common.IConsult=} [properties] Properties to set
             */
            function Consult(properties) {
                this.workerGroupIds = [];
                this.workerIds = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Consult consultId.
             * @member {number} consultId
             * @memberof api.common.Consult
             * @instance
             */
            Consult.prototype.consultId = 0;

            /**
             * Consult name.
             * @member {string} name
             * @memberof api.common.Consult
             * @instance
             */
            Consult.prototype.name = "";

            /**
             * Consult guide.
             * @member {string} guide
             * @memberof api.common.Consult
             * @instance
             */
            Consult.prototype.guide = "";

            /**
             * Consult workerGroupIds.
             * @member {Array.<Long>} workerGroupIds
             * @memberof api.common.Consult
             * @instance
             */
            Consult.prototype.workerGroupIds = $util.emptyArray;

            /**
             * Consult workerIds.
             * @member {Array.<number>} workerIds
             * @memberof api.common.Consult
             * @instance
             */
            Consult.prototype.workerIds = $util.emptyArray;

            /**
             * Consult workerGroupNames.
             * @member {string} workerGroupNames
             * @memberof api.common.Consult
             * @instance
             */
            Consult.prototype.workerGroupNames = "";

            /**
             * Consult workerNames.
             * @member {string} workerNames
             * @memberof api.common.Consult
             * @instance
             */
            Consult.prototype.workerNames = "";

            /**
             * Creates a new Consult instance using the specified properties.
             * @function create
             * @memberof api.common.Consult
             * @static
             * @param {api.common.IConsult=} [properties] Properties to set
             * @returns {api.common.Consult} Consult instance
             */
            Consult.create = function create(properties) {
                return new Consult(properties);
            };

            /**
             * Encodes the specified Consult message. Does not implicitly {@link api.common.Consult.verify|verify} messages.
             * @function encode
             * @memberof api.common.Consult
             * @static
             * @param {api.common.IConsult} message Consult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Consult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.consultId != null && Object.hasOwnProperty.call(message, "consultId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.consultId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.guide != null && Object.hasOwnProperty.call(message, "guide"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.guide);
                if (message.workerGroupIds != null && message.workerGroupIds.length) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork();
                    for (let i = 0; i < message.workerGroupIds.length; ++i)
                        writer.int64(message.workerGroupIds[i]);
                    writer.ldelim();
                }
                if (message.workerIds != null && message.workerIds.length) {
                    writer.uint32(/* id 5, wireType 2 =*/42).fork();
                    for (let i = 0; i < message.workerIds.length; ++i)
                        writer.int32(message.workerIds[i]);
                    writer.ldelim();
                }
                if (message.workerGroupNames != null && Object.hasOwnProperty.call(message, "workerGroupNames"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.workerGroupNames);
                if (message.workerNames != null && Object.hasOwnProperty.call(message, "workerNames"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.workerNames);
                return writer;
            };

            /**
             * Encodes the specified Consult message, length delimited. Does not implicitly {@link api.common.Consult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Consult
             * @static
             * @param {api.common.IConsult} message Consult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Consult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Consult message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Consult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Consult} Consult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Consult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Consult();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.consultId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.guide = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.workerGroupIds && message.workerGroupIds.length))
                                message.workerGroupIds = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.workerGroupIds.push(reader.int64());
                            } else
                                message.workerGroupIds.push(reader.int64());
                            break;
                        }
                    case 5: {
                            if (!(message.workerIds && message.workerIds.length))
                                message.workerIds = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.workerIds.push(reader.int32());
                            } else
                                message.workerIds.push(reader.int32());
                            break;
                        }
                    case 6: {
                            message.workerGroupNames = reader.string();
                            break;
                        }
                    case 7: {
                            message.workerNames = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Consult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Consult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Consult} Consult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Consult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Consult message.
             * @function verify
             * @memberof api.common.Consult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Consult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.consultId != null && message.hasOwnProperty("consultId"))
                    if (!$util.isInteger(message.consultId))
                        return "consultId: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.guide != null && message.hasOwnProperty("guide"))
                    if (!$util.isString(message.guide))
                        return "guide: string expected";
                if (message.workerGroupIds != null && message.hasOwnProperty("workerGroupIds")) {
                    if (!Array.isArray(message.workerGroupIds))
                        return "workerGroupIds: array expected";
                    for (let i = 0; i < message.workerGroupIds.length; ++i)
                        if (!$util.isInteger(message.workerGroupIds[i]) && !(message.workerGroupIds[i] && $util.isInteger(message.workerGroupIds[i].low) && $util.isInteger(message.workerGroupIds[i].high)))
                            return "workerGroupIds: integer|Long[] expected";
                }
                if (message.workerIds != null && message.hasOwnProperty("workerIds")) {
                    if (!Array.isArray(message.workerIds))
                        return "workerIds: array expected";
                    for (let i = 0; i < message.workerIds.length; ++i)
                        if (!$util.isInteger(message.workerIds[i]))
                            return "workerIds: integer[] expected";
                }
                if (message.workerGroupNames != null && message.hasOwnProperty("workerGroupNames"))
                    if (!$util.isString(message.workerGroupNames))
                        return "workerGroupNames: string expected";
                if (message.workerNames != null && message.hasOwnProperty("workerNames"))
                    if (!$util.isString(message.workerNames))
                        return "workerNames: string expected";
                return null;
            };

            /**
             * Creates a Consult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Consult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Consult} Consult
             */
            Consult.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Consult)
                    return object;
                let message = new $root.api.common.Consult();
                if (object.consultId != null)
                    message.consultId = object.consultId >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.guide != null)
                    message.guide = String(object.guide);
                if (object.workerGroupIds) {
                    if (!Array.isArray(object.workerGroupIds))
                        throw TypeError(".api.common.Consult.workerGroupIds: array expected");
                    message.workerGroupIds = [];
                    for (let i = 0; i < object.workerGroupIds.length; ++i)
                        if ($util.Long)
                            (message.workerGroupIds[i] = $util.Long.fromValue(object.workerGroupIds[i])).unsigned = false;
                        else if (typeof object.workerGroupIds[i] === "string")
                            message.workerGroupIds[i] = parseInt(object.workerGroupIds[i], 10);
                        else if (typeof object.workerGroupIds[i] === "number")
                            message.workerGroupIds[i] = object.workerGroupIds[i];
                        else if (typeof object.workerGroupIds[i] === "object")
                            message.workerGroupIds[i] = new $util.LongBits(object.workerGroupIds[i].low >>> 0, object.workerGroupIds[i].high >>> 0).toNumber();
                }
                if (object.workerIds) {
                    if (!Array.isArray(object.workerIds))
                        throw TypeError(".api.common.Consult.workerIds: array expected");
                    message.workerIds = [];
                    for (let i = 0; i < object.workerIds.length; ++i)
                        message.workerIds[i] = object.workerIds[i] | 0;
                }
                if (object.workerGroupNames != null)
                    message.workerGroupNames = String(object.workerGroupNames);
                if (object.workerNames != null)
                    message.workerNames = String(object.workerNames);
                return message;
            };

            /**
             * Creates a plain object from a Consult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Consult
             * @static
             * @param {api.common.Consult} message Consult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Consult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.workerGroupIds = [];
                    object.workerIds = [];
                }
                if (options.defaults) {
                    object.consultId = 0;
                    object.name = "";
                    object.guide = "";
                    object.workerGroupNames = "";
                    object.workerNames = "";
                }
                if (message.consultId != null && message.hasOwnProperty("consultId"))
                    object.consultId = message.consultId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.guide != null && message.hasOwnProperty("guide"))
                    object.guide = message.guide;
                if (message.workerGroupIds && message.workerGroupIds.length) {
                    object.workerGroupIds = [];
                    for (let j = 0; j < message.workerGroupIds.length; ++j)
                        if (typeof message.workerGroupIds[j] === "number")
                            object.workerGroupIds[j] = options.longs === String ? String(message.workerGroupIds[j]) : message.workerGroupIds[j];
                        else
                            object.workerGroupIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.workerGroupIds[j]) : options.longs === Number ? new $util.LongBits(message.workerGroupIds[j].low >>> 0, message.workerGroupIds[j].high >>> 0).toNumber() : message.workerGroupIds[j];
                }
                if (message.workerIds && message.workerIds.length) {
                    object.workerIds = [];
                    for (let j = 0; j < message.workerIds.length; ++j)
                        object.workerIds[j] = message.workerIds[j];
                }
                if (message.workerGroupNames != null && message.hasOwnProperty("workerGroupNames"))
                    object.workerGroupNames = message.workerGroupNames;
                if (message.workerNames != null && message.hasOwnProperty("workerNames"))
                    object.workerNames = message.workerNames;
                return object;
            };

            /**
             * Converts this Consult to JSON.
             * @function toJSON
             * @memberof api.common.Consult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Consult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Consult
             * @function getTypeUrl
             * @memberof api.common.Consult
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Consult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Consult";
            };

            return Consult;
        })();

        /**
         * TransportType enum.
         * @name api.common.TransportType
         * @enum {number}
         * @property {number} TRANSPORT_HTTP=0 TRANSPORT_HTTP value
         * @property {number} TRANSPORT_HTTPS=3 TRANSPORT_HTTPS value
         * @property {number} TRANSPORT_H2C=5 TRANSPORT_H2C value
         * @property {number} TRANSPORT_HTTP3=30 TRANSPORT_HTTP3 value
         */
        common.TransportType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TRANSPORT_HTTP"] = 0;
            values[valuesById[3] = "TRANSPORT_HTTPS"] = 3;
            values[valuesById[5] = "TRANSPORT_H2C"] = 5;
            values[valuesById[30] = "TRANSPORT_HTTP3"] = 30;
            return values;
        })();

        /**
         * AddressType enum.
         * @name api.common.AddressType
         * @enum {number}
         * @property {number} ADDRES_DOMAIN=0 ADDRES_DOMAIN value
         * @property {number} ADDRES_IPV4=1 ADDRES_IPV4 value
         * @property {number} addres_ipv6=2 addres_ipv6 value
         */
        common.AddressType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ADDRES_DOMAIN"] = 0;
            values[valuesById[1] = "ADDRES_IPV4"] = 1;
            values[valuesById[2] = "addres_ipv6"] = 2;
            return values;
        })();

        common.Note = (function() {

            /**
             * Properties of a Note.
             * @memberof api.common
             * @interface INote
             * @property {api.common.IMessage|null} [raw] Note raw
             * @property {string|null} [name] Note name
             */

            /**
             * Constructs a new Note.
             * @memberof api.common
             * @classdesc Represents a Note.
             * @implements INote
             * @constructor
             * @param {api.common.INote=} [properties] Properties to set
             */
            function Note(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Note raw.
             * @member {api.common.IMessage|null|undefined} raw
             * @memberof api.common.Note
             * @instance
             */
            Note.prototype.raw = null;

            /**
             * Note name.
             * @member {string} name
             * @memberof api.common.Note
             * @instance
             */
            Note.prototype.name = "";

            /**
             * Creates a new Note instance using the specified properties.
             * @function create
             * @memberof api.common.Note
             * @static
             * @param {api.common.INote=} [properties] Properties to set
             * @returns {api.common.Note} Note instance
             */
            Note.create = function create(properties) {
                return new Note(properties);
            };

            /**
             * Encodes the specified Note message. Does not implicitly {@link api.common.Note.verify|verify} messages.
             * @function encode
             * @memberof api.common.Note
             * @static
             * @param {api.common.INote} message Note message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Note.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                    $root.api.common.Message.encode(message.raw, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified Note message, length delimited. Does not implicitly {@link api.common.Note.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Note
             * @static
             * @param {api.common.INote} message Note message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Note.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Note message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Note
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Note} Note
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Note.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Note();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.raw = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Note message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Note
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Note} Note
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Note.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Note message.
             * @function verify
             * @memberof api.common.Note
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Note.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.raw != null && message.hasOwnProperty("raw")) {
                    let error = $root.api.common.Message.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a Note message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Note
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Note} Note
             */
            Note.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Note)
                    return object;
                let message = new $root.api.common.Note();
                if (object.raw != null) {
                    if (typeof object.raw !== "object")
                        throw TypeError(".api.common.Note.raw: object expected");
                    message.raw = $root.api.common.Message.fromObject(object.raw);
                }
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a Note message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Note
             * @static
             * @param {api.common.Note} message Note
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Note.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.raw = null;
                    object.name = "";
                }
                if (message.raw != null && message.hasOwnProperty("raw"))
                    object.raw = $root.api.common.Message.toObject(message.raw, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this Note to JSON.
             * @function toJSON
             * @memberof api.common.Note
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Note.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Note
             * @function getTypeUrl
             * @memberof api.common.Note
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Note.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Note";
            };

            return Note;
        })();

        common.Notes = (function() {

            /**
             * Properties of a Notes.
             * @memberof api.common
             * @interface INotes
             * @property {Object.<string,api.common.INote>|null} [notes] Notes notes
             */

            /**
             * Constructs a new Notes.
             * @memberof api.common
             * @classdesc Represents a Notes.
             * @implements INotes
             * @constructor
             * @param {api.common.INotes=} [properties] Properties to set
             */
            function Notes(properties) {
                this.notes = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Notes notes.
             * @member {Object.<string,api.common.INote>} notes
             * @memberof api.common.Notes
             * @instance
             */
            Notes.prototype.notes = $util.emptyObject;

            /**
             * Creates a new Notes instance using the specified properties.
             * @function create
             * @memberof api.common.Notes
             * @static
             * @param {api.common.INotes=} [properties] Properties to set
             * @returns {api.common.Notes} Notes instance
             */
            Notes.create = function create(properties) {
                return new Notes(properties);
            };

            /**
             * Encodes the specified Notes message. Does not implicitly {@link api.common.Notes.verify|verify} messages.
             * @function encode
             * @memberof api.common.Notes
             * @static
             * @param {api.common.INotes} message Notes message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Notes.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.notes != null && Object.hasOwnProperty.call(message, "notes"))
                    for (let keys = Object.keys(message.notes), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.api.common.Note.encode(message.notes[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };

            /**
             * Encodes the specified Notes message, length delimited. Does not implicitly {@link api.common.Notes.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Notes
             * @static
             * @param {api.common.INotes} message Notes message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Notes.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Notes message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Notes
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Notes} Notes
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Notes.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Notes(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (message.notes === $util.emptyObject)
                                message.notes = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = null;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = $root.api.common.Note.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.notes[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Notes message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Notes
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Notes} Notes
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Notes.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Notes message.
             * @function verify
             * @memberof api.common.Notes
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Notes.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.notes != null && message.hasOwnProperty("notes")) {
                    if (!$util.isObject(message.notes))
                        return "notes: object expected";
                    let key = Object.keys(message.notes);
                    for (let i = 0; i < key.length; ++i) {
                        let error = $root.api.common.Note.verify(message.notes[key[i]]);
                        if (error)
                            return "notes." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Notes message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Notes
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Notes} Notes
             */
            Notes.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Notes)
                    return object;
                let message = new $root.api.common.Notes();
                if (object.notes) {
                    if (typeof object.notes !== "object")
                        throw TypeError(".api.common.Notes.notes: object expected");
                    message.notes = {};
                    for (let keys = Object.keys(object.notes), i = 0; i < keys.length; ++i) {
                        if (typeof object.notes[keys[i]] !== "object")
                            throw TypeError(".api.common.Notes.notes: object expected");
                        message.notes[keys[i]] = $root.api.common.Note.fromObject(object.notes[keys[i]]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Notes message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Notes
             * @static
             * @param {api.common.Notes} message Notes
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Notes.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.notes = {};
                let keys2;
                if (message.notes && (keys2 = Object.keys(message.notes)).length) {
                    object.notes = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.notes[keys2[j]] = $root.api.common.Note.toObject(message.notes[keys2[j]], options);
                }
                return object;
            };

            /**
             * Converts this Notes to JSON.
             * @function toJSON
             * @memberof api.common.Notes
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Notes.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Notes
             * @function getTypeUrl
             * @memberof api.common.Notes
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Notes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Notes";
            };

            return Notes;
        })();

        /**
         * Operate enum.
         * @name api.common.Operate
         * @enum {number}
         * @property {number} Get=0 Get value
         * @property {number} Set=1 Set value
         * @property {number} UnSet=2 UnSet value
         */
        common.Operate = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "Get"] = 0;
            values[valuesById[1] = "Set"] = 1;
            values[valuesById[2] = "UnSet"] = 2;
            return values;
        })();

        common.PhoneNumber = (function() {

            /**
             * Properties of a PhoneNumber.
             * @memberof api.common
             * @interface IPhoneNumber
             * @property {number|null} [countryCode] PhoneNumber countryCode
             * @property {Long|null} [nationalNumber] PhoneNumber nationalNumber
             * @property {string|null} [maskedNationalNumber] PhoneNumber maskedNationalNumber
             */

            /**
             * Constructs a new PhoneNumber.
             * @memberof api.common
             * @classdesc Represents a PhoneNumber.
             * @implements IPhoneNumber
             * @constructor
             * @param {api.common.IPhoneNumber=} [properties] Properties to set
             */
            function PhoneNumber(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PhoneNumber countryCode.
             * @member {number} countryCode
             * @memberof api.common.PhoneNumber
             * @instance
             */
            PhoneNumber.prototype.countryCode = 0;

            /**
             * PhoneNumber nationalNumber.
             * @member {Long} nationalNumber
             * @memberof api.common.PhoneNumber
             * @instance
             */
            PhoneNumber.prototype.nationalNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PhoneNumber maskedNationalNumber.
             * @member {string} maskedNationalNumber
             * @memberof api.common.PhoneNumber
             * @instance
             */
            PhoneNumber.prototype.maskedNationalNumber = "";

            /**
             * Creates a new PhoneNumber instance using the specified properties.
             * @function create
             * @memberof api.common.PhoneNumber
             * @static
             * @param {api.common.IPhoneNumber=} [properties] Properties to set
             * @returns {api.common.PhoneNumber} PhoneNumber instance
             */
            PhoneNumber.create = function create(properties) {
                return new PhoneNumber(properties);
            };

            /**
             * Encodes the specified PhoneNumber message. Does not implicitly {@link api.common.PhoneNumber.verify|verify} messages.
             * @function encode
             * @memberof api.common.PhoneNumber
             * @static
             * @param {api.common.IPhoneNumber} message PhoneNumber message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhoneNumber.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.countryCode);
                if (message.nationalNumber != null && Object.hasOwnProperty.call(message, "nationalNumber"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.nationalNumber);
                if (message.maskedNationalNumber != null && Object.hasOwnProperty.call(message, "maskedNationalNumber"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.maskedNationalNumber);
                return writer;
            };

            /**
             * Encodes the specified PhoneNumber message, length delimited. Does not implicitly {@link api.common.PhoneNumber.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.PhoneNumber
             * @static
             * @param {api.common.IPhoneNumber} message PhoneNumber message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhoneNumber.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PhoneNumber message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.PhoneNumber
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.PhoneNumber} PhoneNumber
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhoneNumber.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.PhoneNumber();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.countryCode = reader.int32();
                            break;
                        }
                    case 2: {
                            message.nationalNumber = reader.int64();
                            break;
                        }
                    case 3: {
                            message.maskedNationalNumber = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PhoneNumber message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.PhoneNumber
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.PhoneNumber} PhoneNumber
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhoneNumber.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PhoneNumber message.
             * @function verify
             * @memberof api.common.PhoneNumber
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PhoneNumber.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                    if (!$util.isInteger(message.countryCode))
                        return "countryCode: integer expected";
                if (message.nationalNumber != null && message.hasOwnProperty("nationalNumber"))
                    if (!$util.isInteger(message.nationalNumber) && !(message.nationalNumber && $util.isInteger(message.nationalNumber.low) && $util.isInteger(message.nationalNumber.high)))
                        return "nationalNumber: integer|Long expected";
                if (message.maskedNationalNumber != null && message.hasOwnProperty("maskedNationalNumber"))
                    if (!$util.isString(message.maskedNationalNumber))
                        return "maskedNationalNumber: string expected";
                return null;
            };

            /**
             * Creates a PhoneNumber message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.PhoneNumber
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.PhoneNumber} PhoneNumber
             */
            PhoneNumber.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.PhoneNumber)
                    return object;
                let message = new $root.api.common.PhoneNumber();
                if (object.countryCode != null)
                    message.countryCode = object.countryCode | 0;
                if (object.nationalNumber != null)
                    if ($util.Long)
                        (message.nationalNumber = $util.Long.fromValue(object.nationalNumber)).unsigned = false;
                    else if (typeof object.nationalNumber === "string")
                        message.nationalNumber = parseInt(object.nationalNumber, 10);
                    else if (typeof object.nationalNumber === "number")
                        message.nationalNumber = object.nationalNumber;
                    else if (typeof object.nationalNumber === "object")
                        message.nationalNumber = new $util.LongBits(object.nationalNumber.low >>> 0, object.nationalNumber.high >>> 0).toNumber();
                if (object.maskedNationalNumber != null)
                    message.maskedNationalNumber = String(object.maskedNationalNumber);
                return message;
            };

            /**
             * Creates a plain object from a PhoneNumber message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.PhoneNumber
             * @static
             * @param {api.common.PhoneNumber} message PhoneNumber
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PhoneNumber.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.countryCode = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.nationalNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.nationalNumber = options.longs === String ? "0" : 0;
                    object.maskedNationalNumber = "";
                }
                if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                    object.countryCode = message.countryCode;
                if (message.nationalNumber != null && message.hasOwnProperty("nationalNumber"))
                    if (typeof message.nationalNumber === "number")
                        object.nationalNumber = options.longs === String ? String(message.nationalNumber) : message.nationalNumber;
                    else
                        object.nationalNumber = options.longs === String ? $util.Long.prototype.toString.call(message.nationalNumber) : options.longs === Number ? new $util.LongBits(message.nationalNumber.low >>> 0, message.nationalNumber.high >>> 0).toNumber() : message.nationalNumber;
                if (message.maskedNationalNumber != null && message.hasOwnProperty("maskedNationalNumber"))
                    object.maskedNationalNumber = message.maskedNationalNumber;
                return object;
            };

            /**
             * Converts this PhoneNumber to JSON.
             * @function toJSON
             * @memberof api.common.PhoneNumber
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PhoneNumber.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PhoneNumber
             * @function getTypeUrl
             * @memberof api.common.PhoneNumber
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PhoneNumber.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.PhoneNumber";
            };

            return PhoneNumber;
        })();

        /**
         * QRCodeCAT enum.
         * @name api.common.QRCodeCAT
         * @enum {number}
         * @property {number} QR_CODE_CAT_UNKNOWN=0 QR_CODE_CAT_UNKNOWN value
         * @property {number} QR_CODE_CAT_USER_CARD=1 QR_CODE_CAT_USER_CARD value
         * @property {number} QR_CODE_CAT_USER_CARD_LITE=2 QR_CODE_CAT_USER_CARD_LITE value
         * @property {number} QR_CODE_CAT_GROUP_CARD=3 QR_CODE_CAT_GROUP_CARD value
         * @property {number} QR_CODE_CAT_GROUP_CARD_LITE=4 QR_CODE_CAT_GROUP_CARD_LITE value
         */
        common.QRCodeCAT = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "QR_CODE_CAT_UNKNOWN"] = 0;
            values[valuesById[1] = "QR_CODE_CAT_USER_CARD"] = 1;
            values[valuesById[2] = "QR_CODE_CAT_USER_CARD_LITE"] = 2;
            values[valuesById[3] = "QR_CODE_CAT_GROUP_CARD"] = 3;
            values[valuesById[4] = "QR_CODE_CAT_GROUP_CARD_LITE"] = 4;
            return values;
        })();

        /**
         * QRCodeType enum.
         * @name api.common.QRCodeType
         * @enum {number}
         * @property {number} QR_CODE_TYPE_UNKNOWN=0 QR_CODE_TYPE_UNKNOWN value
         * @property {number} QR_CODE_TYPE_STATIC=1 QR_CODE_TYPE_STATIC value
         * @property {number} QR_CODE_TYPE_DYNAMIC=2 QR_CODE_TYPE_DYNAMIC value
         */
        common.QRCodeType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "QR_CODE_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "QR_CODE_TYPE_STATIC"] = 1;
            values[valuesById[2] = "QR_CODE_TYPE_DYNAMIC"] = 2;
            return values;
        })();

        common.QuickReply = (function() {

            /**
             * Properties of a QuickReply.
             * @memberof api.common
             * @interface IQuickReply
             * @property {string|null} [name] QuickReply name
             * @property {number|null} [priority] QuickReply priority
             * @property {Array.<api.common.IMessage>|null} [items] QuickReply items
             * @property {string|null} [content] QuickReply content
             */

            /**
             * Constructs a new QuickReply.
             * @memberof api.common
             * @classdesc Represents a QuickReply.
             * @implements IQuickReply
             * @constructor
             * @param {api.common.IQuickReply=} [properties] Properties to set
             */
            function QuickReply(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReply name.
             * @member {string} name
             * @memberof api.common.QuickReply
             * @instance
             */
            QuickReply.prototype.name = "";

            /**
             * QuickReply priority.
             * @member {number} priority
             * @memberof api.common.QuickReply
             * @instance
             */
            QuickReply.prototype.priority = 0;

            /**
             * QuickReply items.
             * @member {Array.<api.common.IMessage>} items
             * @memberof api.common.QuickReply
             * @instance
             */
            QuickReply.prototype.items = $util.emptyArray;

            /**
             * QuickReply content.
             * @member {string} content
             * @memberof api.common.QuickReply
             * @instance
             */
            QuickReply.prototype.content = "";

            /**
             * Creates a new QuickReply instance using the specified properties.
             * @function create
             * @memberof api.common.QuickReply
             * @static
             * @param {api.common.IQuickReply=} [properties] Properties to set
             * @returns {api.common.QuickReply} QuickReply instance
             */
            QuickReply.create = function create(properties) {
                return new QuickReply(properties);
            };

            /**
             * Encodes the specified QuickReply message. Does not implicitly {@link api.common.QuickReply.verify|verify} messages.
             * @function encode
             * @memberof api.common.QuickReply
             * @static
             * @param {api.common.IQuickReply} message QuickReply message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReply.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.priority);
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.common.Message.encode(message.items[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.content);
                return writer;
            };

            /**
             * Encodes the specified QuickReply message, length delimited. Does not implicitly {@link api.common.QuickReply.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.QuickReply
             * @static
             * @param {api.common.IQuickReply} message QuickReply message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReply.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReply message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.QuickReply
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.QuickReply} QuickReply
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReply.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.QuickReply();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.priority = reader.int32();
                            break;
                        }
                    case 3: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.common.Message.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            message.content = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReply message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.QuickReply
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.QuickReply} QuickReply
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReply.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReply message.
             * @function verify
             * @memberof api.common.QuickReply
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReply.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.common.Message.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                return null;
            };

            /**
             * Creates a QuickReply message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.QuickReply
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.QuickReply} QuickReply
             */
            QuickReply.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.QuickReply)
                    return object;
                let message = new $root.api.common.QuickReply();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.common.QuickReply.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.common.QuickReply.items: object expected");
                        message.items[i] = $root.api.common.Message.fromObject(object.items[i]);
                    }
                }
                if (object.content != null)
                    message.content = String(object.content);
                return message;
            };

            /**
             * Creates a plain object from a QuickReply message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.QuickReply
             * @static
             * @param {api.common.QuickReply} message QuickReply
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReply.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults) {
                    object.name = "";
                    object.priority = 0;
                    object.content = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.common.Message.toObject(message.items[j], options);
                }
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                return object;
            };

            /**
             * Converts this QuickReply to JSON.
             * @function toJSON
             * @memberof api.common.QuickReply
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReply.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReply
             * @function getTypeUrl
             * @memberof api.common.QuickReply
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.QuickReply";
            };

            return QuickReply;
        })();

        common.QuickReplyWorkerStore = (function() {

            /**
             * Properties of a QuickReplyWorkerStore.
             * @memberof api.common
             * @interface IQuickReplyWorkerStore
             * @property {Object.<string,string>|null} [quickReplyId] QuickReplyWorkerStore quickReplyId
             */

            /**
             * Constructs a new QuickReplyWorkerStore.
             * @memberof api.common
             * @classdesc Represents a QuickReplyWorkerStore.
             * @implements IQuickReplyWorkerStore
             * @constructor
             * @param {api.common.IQuickReplyWorkerStore=} [properties] Properties to set
             */
            function QuickReplyWorkerStore(properties) {
                this.quickReplyId = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyWorkerStore quickReplyId.
             * @member {Object.<string,string>} quickReplyId
             * @memberof api.common.QuickReplyWorkerStore
             * @instance
             */
            QuickReplyWorkerStore.prototype.quickReplyId = $util.emptyObject;

            /**
             * Creates a new QuickReplyWorkerStore instance using the specified properties.
             * @function create
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {api.common.IQuickReplyWorkerStore=} [properties] Properties to set
             * @returns {api.common.QuickReplyWorkerStore} QuickReplyWorkerStore instance
             */
            QuickReplyWorkerStore.create = function create(properties) {
                return new QuickReplyWorkerStore(properties);
            };

            /**
             * Encodes the specified QuickReplyWorkerStore message. Does not implicitly {@link api.common.QuickReplyWorkerStore.verify|verify} messages.
             * @function encode
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {api.common.IQuickReplyWorkerStore} message QuickReplyWorkerStore message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyWorkerStore.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.quickReplyId != null && Object.hasOwnProperty.call(message, "quickReplyId"))
                    for (let keys = Object.keys(message.quickReplyId), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.quickReplyId[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuickReplyWorkerStore message, length delimited. Does not implicitly {@link api.common.QuickReplyWorkerStore.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {api.common.IQuickReplyWorkerStore} message QuickReplyWorkerStore message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyWorkerStore.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyWorkerStore message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.QuickReplyWorkerStore} QuickReplyWorkerStore
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyWorkerStore.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.QuickReplyWorkerStore(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3: {
                            if (message.quickReplyId === $util.emptyObject)
                                message.quickReplyId = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = 0;
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.int64();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.quickReplyId[typeof key === "object" ? $util.longToHash(key) : key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyWorkerStore message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.QuickReplyWorkerStore} QuickReplyWorkerStore
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyWorkerStore.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyWorkerStore message.
             * @function verify
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyWorkerStore.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.quickReplyId != null && message.hasOwnProperty("quickReplyId")) {
                    if (!$util.isObject(message.quickReplyId))
                        return "quickReplyId: object expected";
                    let key = Object.keys(message.quickReplyId);
                    for (let i = 0; i < key.length; ++i) {
                        if (!$util.key64Re.test(key[i]))
                            return "quickReplyId: integer|Long key{k:int64} expected";
                        if (!$util.isString(message.quickReplyId[key[i]]))
                            return "quickReplyId: string{k:int64} expected";
                    }
                }
                return null;
            };

            /**
             * Creates a QuickReplyWorkerStore message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.QuickReplyWorkerStore} QuickReplyWorkerStore
             */
            QuickReplyWorkerStore.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.QuickReplyWorkerStore)
                    return object;
                let message = new $root.api.common.QuickReplyWorkerStore();
                if (object.quickReplyId) {
                    if (typeof object.quickReplyId !== "object")
                        throw TypeError(".api.common.QuickReplyWorkerStore.quickReplyId: object expected");
                    message.quickReplyId = {};
                    for (let keys = Object.keys(object.quickReplyId), i = 0; i < keys.length; ++i)
                        message.quickReplyId[keys[i]] = String(object.quickReplyId[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyWorkerStore message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {api.common.QuickReplyWorkerStore} message QuickReplyWorkerStore
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyWorkerStore.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.quickReplyId = {};
                let keys2;
                if (message.quickReplyId && (keys2 = Object.keys(message.quickReplyId)).length) {
                    object.quickReplyId = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.quickReplyId[keys2[j]] = message.quickReplyId[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this QuickReplyWorkerStore to JSON.
             * @function toJSON
             * @memberof api.common.QuickReplyWorkerStore
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyWorkerStore.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyWorkerStore
             * @function getTypeUrl
             * @memberof api.common.QuickReplyWorkerStore
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyWorkerStore.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.QuickReplyWorkerStore";
            };

            return QuickReplyWorkerStore;
        })();

        common.QuickReplyGroup = (function() {

            /**
             * Properties of a QuickReplyGroup.
             * @memberof api.common
             * @interface IQuickReplyGroup
             * @property {number|null} [priority] QuickReplyGroup priority
             * @property {Object.<string,string>|null} [quickReplyId] QuickReplyGroup quickReplyId
             */

            /**
             * Constructs a new QuickReplyGroup.
             * @memberof api.common
             * @classdesc Represents a QuickReplyGroup.
             * @implements IQuickReplyGroup
             * @constructor
             * @param {api.common.IQuickReplyGroup=} [properties] Properties to set
             */
            function QuickReplyGroup(properties) {
                this.quickReplyId = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyGroup priority.
             * @member {number} priority
             * @memberof api.common.QuickReplyGroup
             * @instance
             */
            QuickReplyGroup.prototype.priority = 0;

            /**
             * QuickReplyGroup quickReplyId.
             * @member {Object.<string,string>} quickReplyId
             * @memberof api.common.QuickReplyGroup
             * @instance
             */
            QuickReplyGroup.prototype.quickReplyId = $util.emptyObject;

            /**
             * Creates a new QuickReplyGroup instance using the specified properties.
             * @function create
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {api.common.IQuickReplyGroup=} [properties] Properties to set
             * @returns {api.common.QuickReplyGroup} QuickReplyGroup instance
             */
            QuickReplyGroup.create = function create(properties) {
                return new QuickReplyGroup(properties);
            };

            /**
             * Encodes the specified QuickReplyGroup message. Does not implicitly {@link api.common.QuickReplyGroup.verify|verify} messages.
             * @function encode
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {api.common.IQuickReplyGroup} message QuickReplyGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.priority);
                if (message.quickReplyId != null && Object.hasOwnProperty.call(message, "quickReplyId"))
                    for (let keys = Object.keys(message.quickReplyId), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.quickReplyId[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuickReplyGroup message, length delimited. Does not implicitly {@link api.common.QuickReplyGroup.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {api.common.IQuickReplyGroup} message QuickReplyGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroup.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyGroup message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.QuickReplyGroup} QuickReplyGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.QuickReplyGroup(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.priority = reader.int32();
                            break;
                        }
                    case 3: {
                            if (message.quickReplyId === $util.emptyObject)
                                message.quickReplyId = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = 0;
                            value = "";
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.int64();
                                    break;
                                case 2:
                                    value = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.quickReplyId[typeof key === "object" ? $util.longToHash(key) : key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyGroup message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.QuickReplyGroup} QuickReplyGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroup.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyGroup message.
             * @function verify
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                if (message.quickReplyId != null && message.hasOwnProperty("quickReplyId")) {
                    if (!$util.isObject(message.quickReplyId))
                        return "quickReplyId: object expected";
                    let key = Object.keys(message.quickReplyId);
                    for (let i = 0; i < key.length; ++i) {
                        if (!$util.key64Re.test(key[i]))
                            return "quickReplyId: integer|Long key{k:int64} expected";
                        if (!$util.isString(message.quickReplyId[key[i]]))
                            return "quickReplyId: string{k:int64} expected";
                    }
                }
                return null;
            };

            /**
             * Creates a QuickReplyGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.QuickReplyGroup} QuickReplyGroup
             */
            QuickReplyGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.QuickReplyGroup)
                    return object;
                let message = new $root.api.common.QuickReplyGroup();
                if (object.priority != null)
                    message.priority = object.priority | 0;
                if (object.quickReplyId) {
                    if (typeof object.quickReplyId !== "object")
                        throw TypeError(".api.common.QuickReplyGroup.quickReplyId: object expected");
                    message.quickReplyId = {};
                    for (let keys = Object.keys(object.quickReplyId), i = 0; i < keys.length; ++i)
                        message.quickReplyId[keys[i]] = String(object.quickReplyId[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {api.common.QuickReplyGroup} message QuickReplyGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.quickReplyId = {};
                if (options.defaults)
                    object.priority = 0;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                let keys2;
                if (message.quickReplyId && (keys2 = Object.keys(message.quickReplyId)).length) {
                    object.quickReplyId = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.quickReplyId[keys2[j]] = message.quickReplyId[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this QuickReplyGroup to JSON.
             * @function toJSON
             * @memberof api.common.QuickReplyGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyGroup
             * @function getTypeUrl
             * @memberof api.common.QuickReplyGroup
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.QuickReplyGroup";
            };

            return QuickReplyGroup;
        })();

        common.QuickReplyGroupStore = (function() {

            /**
             * Properties of a QuickReplyGroupStore.
             * @memberof api.common
             * @interface IQuickReplyGroupStore
             * @property {Object.<string,api.common.IQuickReplyGroup>|null} [store] QuickReplyGroupStore store
             */

            /**
             * Constructs a new QuickReplyGroupStore.
             * @memberof api.common
             * @classdesc Represents a QuickReplyGroupStore.
             * @implements IQuickReplyGroupStore
             * @constructor
             * @param {api.common.IQuickReplyGroupStore=} [properties] Properties to set
             */
            function QuickReplyGroupStore(properties) {
                this.store = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyGroupStore store.
             * @member {Object.<string,api.common.IQuickReplyGroup>} store
             * @memberof api.common.QuickReplyGroupStore
             * @instance
             */
            QuickReplyGroupStore.prototype.store = $util.emptyObject;

            /**
             * Creates a new QuickReplyGroupStore instance using the specified properties.
             * @function create
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {api.common.IQuickReplyGroupStore=} [properties] Properties to set
             * @returns {api.common.QuickReplyGroupStore} QuickReplyGroupStore instance
             */
            QuickReplyGroupStore.create = function create(properties) {
                return new QuickReplyGroupStore(properties);
            };

            /**
             * Encodes the specified QuickReplyGroupStore message. Does not implicitly {@link api.common.QuickReplyGroupStore.verify|verify} messages.
             * @function encode
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {api.common.IQuickReplyGroupStore} message QuickReplyGroupStore message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupStore.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.store != null && Object.hasOwnProperty.call(message, "store"))
                    for (let keys = Object.keys(message.store), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.api.common.QuickReplyGroup.encode(message.store[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };

            /**
             * Encodes the specified QuickReplyGroupStore message, length delimited. Does not implicitly {@link api.common.QuickReplyGroupStore.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {api.common.IQuickReplyGroupStore} message QuickReplyGroupStore message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupStore.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyGroupStore message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.QuickReplyGroupStore} QuickReplyGroupStore
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupStore.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.QuickReplyGroupStore(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (message.store === $util.emptyObject)
                                message.store = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = null;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = $root.api.common.QuickReplyGroup.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.store[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyGroupStore message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.QuickReplyGroupStore} QuickReplyGroupStore
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupStore.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyGroupStore message.
             * @function verify
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyGroupStore.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.store != null && message.hasOwnProperty("store")) {
                    if (!$util.isObject(message.store))
                        return "store: object expected";
                    let key = Object.keys(message.store);
                    for (let i = 0; i < key.length; ++i) {
                        let error = $root.api.common.QuickReplyGroup.verify(message.store[key[i]]);
                        if (error)
                            return "store." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QuickReplyGroupStore message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.QuickReplyGroupStore} QuickReplyGroupStore
             */
            QuickReplyGroupStore.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.QuickReplyGroupStore)
                    return object;
                let message = new $root.api.common.QuickReplyGroupStore();
                if (object.store) {
                    if (typeof object.store !== "object")
                        throw TypeError(".api.common.QuickReplyGroupStore.store: object expected");
                    message.store = {};
                    for (let keys = Object.keys(object.store), i = 0; i < keys.length; ++i) {
                        if (typeof object.store[keys[i]] !== "object")
                            throw TypeError(".api.common.QuickReplyGroupStore.store: object expected");
                        message.store[keys[i]] = $root.api.common.QuickReplyGroup.fromObject(object.store[keys[i]]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyGroupStore message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {api.common.QuickReplyGroupStore} message QuickReplyGroupStore
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyGroupStore.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.store = {};
                let keys2;
                if (message.store && (keys2 = Object.keys(message.store)).length) {
                    object.store = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.store[keys2[j]] = $root.api.common.QuickReplyGroup.toObject(message.store[keys2[j]], options);
                }
                return object;
            };

            /**
             * Converts this QuickReplyGroupStore to JSON.
             * @function toJSON
             * @memberof api.common.QuickReplyGroupStore
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyGroupStore.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyGroupStore
             * @function getTypeUrl
             * @memberof api.common.QuickReplyGroupStore
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyGroupStore.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.QuickReplyGroupStore";
            };

            return QuickReplyGroupStore;
        })();

        common.QuestionAnswer = (function() {

            /**
             * Properties of a QuestionAnswer.
             * @memberof api.common
             * @interface IQuestionAnswer
             * @property {number|null} [id] QuestionAnswer id
             * @property {api.common.IMessage|null} [question] QuestionAnswer question
             * @property {string|null} [content] QuestionAnswer content
             * @property {Array.<api.common.IMessage>|null} [answer] QuestionAnswer answer
             */

            /**
             * Constructs a new QuestionAnswer.
             * @memberof api.common
             * @classdesc Represents a QuestionAnswer.
             * @implements IQuestionAnswer
             * @constructor
             * @param {api.common.IQuestionAnswer=} [properties] Properties to set
             */
            function QuestionAnswer(properties) {
                this.answer = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuestionAnswer id.
             * @member {number} id
             * @memberof api.common.QuestionAnswer
             * @instance
             */
            QuestionAnswer.prototype.id = 0;

            /**
             * QuestionAnswer question.
             * @member {api.common.IMessage|null|undefined} question
             * @memberof api.common.QuestionAnswer
             * @instance
             */
            QuestionAnswer.prototype.question = null;

            /**
             * QuestionAnswer content.
             * @member {string} content
             * @memberof api.common.QuestionAnswer
             * @instance
             */
            QuestionAnswer.prototype.content = "";

            /**
             * QuestionAnswer answer.
             * @member {Array.<api.common.IMessage>} answer
             * @memberof api.common.QuestionAnswer
             * @instance
             */
            QuestionAnswer.prototype.answer = $util.emptyArray;

            /**
             * Creates a new QuestionAnswer instance using the specified properties.
             * @function create
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {api.common.IQuestionAnswer=} [properties] Properties to set
             * @returns {api.common.QuestionAnswer} QuestionAnswer instance
             */
            QuestionAnswer.create = function create(properties) {
                return new QuestionAnswer(properties);
            };

            /**
             * Encodes the specified QuestionAnswer message. Does not implicitly {@link api.common.QuestionAnswer.verify|verify} messages.
             * @function encode
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {api.common.IQuestionAnswer} message QuestionAnswer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuestionAnswer.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
                if (message.question != null && Object.hasOwnProperty.call(message, "question"))
                    $root.api.common.Message.encode(message.question, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.content);
                if (message.answer != null && message.answer.length)
                    for (let i = 0; i < message.answer.length; ++i)
                        $root.api.common.Message.encode(message.answer[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuestionAnswer message, length delimited. Does not implicitly {@link api.common.QuestionAnswer.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {api.common.IQuestionAnswer} message QuestionAnswer message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuestionAnswer.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuestionAnswer message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.QuestionAnswer} QuestionAnswer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuestionAnswer.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.QuestionAnswer();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int32();
                            break;
                        }
                    case 2: {
                            message.question = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.content = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.answer && message.answer.length))
                                message.answer = [];
                            message.answer.push($root.api.common.Message.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuestionAnswer message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.QuestionAnswer} QuestionAnswer
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuestionAnswer.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuestionAnswer message.
             * @function verify
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuestionAnswer.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                if (message.question != null && message.hasOwnProperty("question")) {
                    let error = $root.api.common.Message.verify(message.question);
                    if (error)
                        return "question." + error;
                }
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                if (message.answer != null && message.hasOwnProperty("answer")) {
                    if (!Array.isArray(message.answer))
                        return "answer: array expected";
                    for (let i = 0; i < message.answer.length; ++i) {
                        let error = $root.api.common.Message.verify(message.answer[i]);
                        if (error)
                            return "answer." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QuestionAnswer message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.QuestionAnswer} QuestionAnswer
             */
            QuestionAnswer.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.QuestionAnswer)
                    return object;
                let message = new $root.api.common.QuestionAnswer();
                if (object.id != null)
                    message.id = object.id | 0;
                if (object.question != null) {
                    if (typeof object.question !== "object")
                        throw TypeError(".api.common.QuestionAnswer.question: object expected");
                    message.question = $root.api.common.Message.fromObject(object.question);
                }
                if (object.content != null)
                    message.content = String(object.content);
                if (object.answer) {
                    if (!Array.isArray(object.answer))
                        throw TypeError(".api.common.QuestionAnswer.answer: array expected");
                    message.answer = [];
                    for (let i = 0; i < object.answer.length; ++i) {
                        if (typeof object.answer[i] !== "object")
                            throw TypeError(".api.common.QuestionAnswer.answer: object expected");
                        message.answer[i] = $root.api.common.Message.fromObject(object.answer[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QuestionAnswer message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {api.common.QuestionAnswer} message QuestionAnswer
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuestionAnswer.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.answer = [];
                if (options.defaults) {
                    object.id = 0;
                    object.question = null;
                    object.content = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.question != null && message.hasOwnProperty("question"))
                    object.question = $root.api.common.Message.toObject(message.question, options);
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                if (message.answer && message.answer.length) {
                    object.answer = [];
                    for (let j = 0; j < message.answer.length; ++j)
                        object.answer[j] = $root.api.common.Message.toObject(message.answer[j], options);
                }
                return object;
            };

            /**
             * Converts this QuestionAnswer to JSON.
             * @function toJSON
             * @memberof api.common.QuestionAnswer
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuestionAnswer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuestionAnswer
             * @function getTypeUrl
             * @memberof api.common.QuestionAnswer
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuestionAnswer.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.QuestionAnswer";
            };

            return QuestionAnswer;
        })();

        common.AutoReplyItem = (function() {

            /**
             * Properties of an AutoReplyItem.
             * @memberof api.common
             * @interface IAutoReplyItem
             * @property {Long|null} [id] AutoReplyItem id
             * @property {string|null} [name] AutoReplyItem name
             * @property {string|null} [title] AutoReplyItem title
             * @property {Array.<api.common.IQuestionAnswer>|null} [qa] AutoReplyItem qa
             * @property {number|null} [delaySec] AutoReplyItem delaySec
             * @property {Array.<number>|null} [workerId] AutoReplyItem workerId
             * @property {Array.<string>|null} [workerNames] AutoReplyItem workerNames
             */

            /**
             * Constructs a new AutoReplyItem.
             * @memberof api.common
             * @classdesc Represents an AutoReplyItem.
             * @implements IAutoReplyItem
             * @constructor
             * @param {api.common.IAutoReplyItem=} [properties] Properties to set
             */
            function AutoReplyItem(properties) {
                this.qa = [];
                this.workerId = [];
                this.workerNames = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AutoReplyItem id.
             * @member {Long} id
             * @memberof api.common.AutoReplyItem
             * @instance
             */
            AutoReplyItem.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * AutoReplyItem name.
             * @member {string} name
             * @memberof api.common.AutoReplyItem
             * @instance
             */
            AutoReplyItem.prototype.name = "";

            /**
             * AutoReplyItem title.
             * @member {string|null|undefined} title
             * @memberof api.common.AutoReplyItem
             * @instance
             */
            AutoReplyItem.prototype.title = null;

            /**
             * AutoReplyItem qa.
             * @member {Array.<api.common.IQuestionAnswer>} qa
             * @memberof api.common.AutoReplyItem
             * @instance
             */
            AutoReplyItem.prototype.qa = $util.emptyArray;

            /**
             * AutoReplyItem delaySec.
             * @member {number} delaySec
             * @memberof api.common.AutoReplyItem
             * @instance
             */
            AutoReplyItem.prototype.delaySec = 0;

            /**
             * AutoReplyItem workerId.
             * @member {Array.<number>} workerId
             * @memberof api.common.AutoReplyItem
             * @instance
             */
            AutoReplyItem.prototype.workerId = $util.emptyArray;

            /**
             * AutoReplyItem workerNames.
             * @member {Array.<string>} workerNames
             * @memberof api.common.AutoReplyItem
             * @instance
             */
            AutoReplyItem.prototype.workerNames = $util.emptyArray;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * AutoReplyItem _title.
             * @member {"title"|undefined} _title
             * @memberof api.common.AutoReplyItem
             * @instance
             */
            Object.defineProperty(AutoReplyItem.prototype, "_title", {
                get: $util.oneOfGetter($oneOfFields = ["title"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new AutoReplyItem instance using the specified properties.
             * @function create
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {api.common.IAutoReplyItem=} [properties] Properties to set
             * @returns {api.common.AutoReplyItem} AutoReplyItem instance
             */
            AutoReplyItem.create = function create(properties) {
                return new AutoReplyItem(properties);
            };

            /**
             * Encodes the specified AutoReplyItem message. Does not implicitly {@link api.common.AutoReplyItem.verify|verify} messages.
             * @function encode
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {api.common.IAutoReplyItem} message AutoReplyItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
                if (message.qa != null && message.qa.length)
                    for (let i = 0; i < message.qa.length; ++i)
                        $root.api.common.QuestionAnswer.encode(message.qa[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.delaySec != null && Object.hasOwnProperty.call(message, "delaySec"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.delaySec);
                if (message.workerId != null && message.workerId.length) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork();
                    for (let i = 0; i < message.workerId.length; ++i)
                        writer.int32(message.workerId[i]);
                    writer.ldelim();
                }
                if (message.workerNames != null && message.workerNames.length)
                    for (let i = 0; i < message.workerNames.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.workerNames[i]);
                return writer;
            };

            /**
             * Encodes the specified AutoReplyItem message, length delimited. Does not implicitly {@link api.common.AutoReplyItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {api.common.IAutoReplyItem} message AutoReplyItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AutoReplyItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.AutoReplyItem} AutoReplyItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.AutoReplyItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.title = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.qa && message.qa.length))
                                message.qa = [];
                            message.qa.push($root.api.common.QuestionAnswer.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            message.delaySec = reader.double();
                            break;
                        }
                    case 6: {
                            if (!(message.workerId && message.workerId.length))
                                message.workerId = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.workerId.push(reader.int32());
                            } else
                                message.workerId.push(reader.int32());
                            break;
                        }
                    case 7: {
                            if (!(message.workerNames && message.workerNames.length))
                                message.workerNames = [];
                            message.workerNames.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AutoReplyItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.AutoReplyItem} AutoReplyItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AutoReplyItem message.
             * @function verify
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AutoReplyItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.title != null && message.hasOwnProperty("title")) {
                    properties._title = 1;
                    if (!$util.isString(message.title))
                        return "title: string expected";
                }
                if (message.qa != null && message.hasOwnProperty("qa")) {
                    if (!Array.isArray(message.qa))
                        return "qa: array expected";
                    for (let i = 0; i < message.qa.length; ++i) {
                        let error = $root.api.common.QuestionAnswer.verify(message.qa[i]);
                        if (error)
                            return "qa." + error;
                    }
                }
                if (message.delaySec != null && message.hasOwnProperty("delaySec"))
                    if (typeof message.delaySec !== "number")
                        return "delaySec: number expected";
                if (message.workerId != null && message.hasOwnProperty("workerId")) {
                    if (!Array.isArray(message.workerId))
                        return "workerId: array expected";
                    for (let i = 0; i < message.workerId.length; ++i)
                        if (!$util.isInteger(message.workerId[i]))
                            return "workerId: integer[] expected";
                }
                if (message.workerNames != null && message.hasOwnProperty("workerNames")) {
                    if (!Array.isArray(message.workerNames))
                        return "workerNames: array expected";
                    for (let i = 0; i < message.workerNames.length; ++i)
                        if (!$util.isString(message.workerNames[i]))
                            return "workerNames: string[] expected";
                }
                return null;
            };

            /**
             * Creates an AutoReplyItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.AutoReplyItem} AutoReplyItem
             */
            AutoReplyItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.AutoReplyItem)
                    return object;
                let message = new $root.api.common.AutoReplyItem();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.title != null)
                    message.title = String(object.title);
                if (object.qa) {
                    if (!Array.isArray(object.qa))
                        throw TypeError(".api.common.AutoReplyItem.qa: array expected");
                    message.qa = [];
                    for (let i = 0; i < object.qa.length; ++i) {
                        if (typeof object.qa[i] !== "object")
                            throw TypeError(".api.common.AutoReplyItem.qa: object expected");
                        message.qa[i] = $root.api.common.QuestionAnswer.fromObject(object.qa[i]);
                    }
                }
                if (object.delaySec != null)
                    message.delaySec = Number(object.delaySec);
                if (object.workerId) {
                    if (!Array.isArray(object.workerId))
                        throw TypeError(".api.common.AutoReplyItem.workerId: array expected");
                    message.workerId = [];
                    for (let i = 0; i < object.workerId.length; ++i)
                        message.workerId[i] = object.workerId[i] | 0;
                }
                if (object.workerNames) {
                    if (!Array.isArray(object.workerNames))
                        throw TypeError(".api.common.AutoReplyItem.workerNames: array expected");
                    message.workerNames = [];
                    for (let i = 0; i < object.workerNames.length; ++i)
                        message.workerNames[i] = String(object.workerNames[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from an AutoReplyItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {api.common.AutoReplyItem} message AutoReplyItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AutoReplyItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.qa = [];
                    object.workerId = [];
                    object.workerNames = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.delaySec = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.title != null && message.hasOwnProperty("title")) {
                    object.title = message.title;
                    if (options.oneofs)
                        object._title = "title";
                }
                if (message.qa && message.qa.length) {
                    object.qa = [];
                    for (let j = 0; j < message.qa.length; ++j)
                        object.qa[j] = $root.api.common.QuestionAnswer.toObject(message.qa[j], options);
                }
                if (message.delaySec != null && message.hasOwnProperty("delaySec"))
                    object.delaySec = options.json && !isFinite(message.delaySec) ? String(message.delaySec) : message.delaySec;
                if (message.workerId && message.workerId.length) {
                    object.workerId = [];
                    for (let j = 0; j < message.workerId.length; ++j)
                        object.workerId[j] = message.workerId[j];
                }
                if (message.workerNames && message.workerNames.length) {
                    object.workerNames = [];
                    for (let j = 0; j < message.workerNames.length; ++j)
                        object.workerNames[j] = message.workerNames[j];
                }
                return object;
            };

            /**
             * Converts this AutoReplyItem to JSON.
             * @function toJSON
             * @memberof api.common.AutoReplyItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AutoReplyItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AutoReplyItem
             * @function getTypeUrl
             * @memberof api.common.AutoReplyItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AutoReplyItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.AutoReplyItem";
            };

            return AutoReplyItem;
        })();

        /**
         * ValidatorType enum.
         * @name api.common.ValidatorType
         * @enum {number}
         * @property {number} VALIDATOR_TYPE_UNKNOWN=0 VALIDATOR_TYPE_UNKNOWN value
         * @property {number} VALIDATOR_TYPE_SMS=1 VALIDATOR_TYPE_SMS value
         * @property {number} VALIDATOR_TYPE_CAPTCHA=2 VALIDATOR_TYPE_CAPTCHA value
         */
        common.ValidatorType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "VALIDATOR_TYPE_UNKNOWN"] = 0;
            values[valuesById[1] = "VALIDATOR_TYPE_SMS"] = 1;
            values[valuesById[2] = "VALIDATOR_TYPE_CAPTCHA"] = 2;
            return values;
        })();

        /**
         * VerifyFor enum.
         * @name api.common.VerifyFor
         * @enum {number}
         * @property {number} VERIFY_FOR_UNKNOWN=0 VERIFY_FOR_UNKNOWN value
         * @property {number} VERIFY_FOR_RESET_PASSWORD=1 VERIFY_FOR_RESET_PASSWORD value
         * @property {number} VERIFY_FOR_RESET_PHONE=2 VERIFY_FOR_RESET_PHONE value
         * @property {number} VERIFY_FOR_RESET_EMAIL=3 VERIFY_FOR_RESET_EMAIL value
         * @property {number} VERIFY_FOR_RESET_FINANCE_PASSWORD=4 VERIFY_FOR_RESET_FINANCE_PASSWORD value
         * @property {number} VERIFY_FOR_REGISTER=5 VERIFY_FOR_REGISTER value
         * @property {number} VERIFY_FOR_LOGIN=6 VERIFY_FOR_LOGIN value
         * @property {number} VERIFY_FOR_BIND=7 VERIFY_FOR_BIND value
         * @property {number} VERIFY_FOR_BACKSTAGE_RESET_PASSWORD=101 VERIFY_FOR_BACKSTAGE_RESET_PASSWORD value
         */
        common.VerifyFor = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "VERIFY_FOR_UNKNOWN"] = 0;
            values[valuesById[1] = "VERIFY_FOR_RESET_PASSWORD"] = 1;
            values[valuesById[2] = "VERIFY_FOR_RESET_PHONE"] = 2;
            values[valuesById[3] = "VERIFY_FOR_RESET_EMAIL"] = 3;
            values[valuesById[4] = "VERIFY_FOR_RESET_FINANCE_PASSWORD"] = 4;
            values[valuesById[5] = "VERIFY_FOR_REGISTER"] = 5;
            values[valuesById[6] = "VERIFY_FOR_LOGIN"] = 6;
            values[valuesById[7] = "VERIFY_FOR_BIND"] = 7;
            values[valuesById[101] = "VERIFY_FOR_BACKSTAGE_RESET_PASSWORD"] = 101;
            return values;
        })();

        common.Verify = (function() {

            /**
             * Properties of a Verify.
             * @memberof api.common
             * @interface IVerify
             * @property {api.common.IVerifyBySMS|null} [sms] Verify sms
             * @property {api.common.IVerifyByPassword|null} [pwd] Verify pwd
             * @property {api.common.IVerifyByCaptcha|null} [captcha] Verify captcha
             */

            /**
             * Constructs a new Verify.
             * @memberof api.common
             * @classdesc Represents a Verify.
             * @implements IVerify
             * @constructor
             * @param {api.common.IVerify=} [properties] Properties to set
             */
            function Verify(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Verify sms.
             * @member {api.common.IVerifyBySMS|null|undefined} sms
             * @memberof api.common.Verify
             * @instance
             */
            Verify.prototype.sms = null;

            /**
             * Verify pwd.
             * @member {api.common.IVerifyByPassword|null|undefined} pwd
             * @memberof api.common.Verify
             * @instance
             */
            Verify.prototype.pwd = null;

            /**
             * Verify captcha.
             * @member {api.common.IVerifyByCaptcha|null|undefined} captcha
             * @memberof api.common.Verify
             * @instance
             */
            Verify.prototype.captcha = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * Verify way.
             * @member {"sms"|"pwd"|"captcha"|undefined} way
             * @memberof api.common.Verify
             * @instance
             */
            Object.defineProperty(Verify.prototype, "way", {
                get: $util.oneOfGetter($oneOfFields = ["sms", "pwd", "captcha"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Verify instance using the specified properties.
             * @function create
             * @memberof api.common.Verify
             * @static
             * @param {api.common.IVerify=} [properties] Properties to set
             * @returns {api.common.Verify} Verify instance
             */
            Verify.create = function create(properties) {
                return new Verify(properties);
            };

            /**
             * Encodes the specified Verify message. Does not implicitly {@link api.common.Verify.verify|verify} messages.
             * @function encode
             * @memberof api.common.Verify
             * @static
             * @param {api.common.IVerify} message Verify message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Verify.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sms != null && Object.hasOwnProperty.call(message, "sms"))
                    $root.api.common.VerifyBySMS.encode(message.sms, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.pwd != null && Object.hasOwnProperty.call(message, "pwd"))
                    $root.api.common.VerifyByPassword.encode(message.pwd, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.captcha != null && Object.hasOwnProperty.call(message, "captcha"))
                    $root.api.common.VerifyByCaptcha.encode(message.captcha, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Verify message, length delimited. Does not implicitly {@link api.common.Verify.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.Verify
             * @static
             * @param {api.common.IVerify} message Verify message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Verify.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Verify message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.Verify
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.Verify} Verify
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Verify.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.Verify();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.sms = $root.api.common.VerifyBySMS.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.pwd = $root.api.common.VerifyByPassword.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.captcha = $root.api.common.VerifyByCaptcha.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Verify message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.Verify
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.Verify} Verify
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Verify.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Verify message.
             * @function verify
             * @memberof api.common.Verify
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Verify.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.sms != null && message.hasOwnProperty("sms")) {
                    properties.way = 1;
                    {
                        let error = $root.api.common.VerifyBySMS.verify(message.sms);
                        if (error)
                            return "sms." + error;
                    }
                }
                if (message.pwd != null && message.hasOwnProperty("pwd")) {
                    if (properties.way === 1)
                        return "way: multiple values";
                    properties.way = 1;
                    {
                        let error = $root.api.common.VerifyByPassword.verify(message.pwd);
                        if (error)
                            return "pwd." + error;
                    }
                }
                if (message.captcha != null && message.hasOwnProperty("captcha")) {
                    if (properties.way === 1)
                        return "way: multiple values";
                    properties.way = 1;
                    {
                        let error = $root.api.common.VerifyByCaptcha.verify(message.captcha);
                        if (error)
                            return "captcha." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Verify message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.Verify
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.Verify} Verify
             */
            Verify.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.Verify)
                    return object;
                let message = new $root.api.common.Verify();
                if (object.sms != null) {
                    if (typeof object.sms !== "object")
                        throw TypeError(".api.common.Verify.sms: object expected");
                    message.sms = $root.api.common.VerifyBySMS.fromObject(object.sms);
                }
                if (object.pwd != null) {
                    if (typeof object.pwd !== "object")
                        throw TypeError(".api.common.Verify.pwd: object expected");
                    message.pwd = $root.api.common.VerifyByPassword.fromObject(object.pwd);
                }
                if (object.captcha != null) {
                    if (typeof object.captcha !== "object")
                        throw TypeError(".api.common.Verify.captcha: object expected");
                    message.captcha = $root.api.common.VerifyByCaptcha.fromObject(object.captcha);
                }
                return message;
            };

            /**
             * Creates a plain object from a Verify message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.Verify
             * @static
             * @param {api.common.Verify} message Verify
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Verify.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.sms != null && message.hasOwnProperty("sms")) {
                    object.sms = $root.api.common.VerifyBySMS.toObject(message.sms, options);
                    if (options.oneofs)
                        object.way = "sms";
                }
                if (message.pwd != null && message.hasOwnProperty("pwd")) {
                    object.pwd = $root.api.common.VerifyByPassword.toObject(message.pwd, options);
                    if (options.oneofs)
                        object.way = "pwd";
                }
                if (message.captcha != null && message.hasOwnProperty("captcha")) {
                    object.captcha = $root.api.common.VerifyByCaptcha.toObject(message.captcha, options);
                    if (options.oneofs)
                        object.way = "captcha";
                }
                return object;
            };

            /**
             * Converts this Verify to JSON.
             * @function toJSON
             * @memberof api.common.Verify
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Verify.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Verify
             * @function getTypeUrl
             * @memberof api.common.Verify
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Verify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.Verify";
            };

            return Verify;
        })();

        common.VerifyBySMS = (function() {

            /**
             * Properties of a VerifyBySMS.
             * @memberof api.common
             * @interface IVerifyBySMS
             * @property {string|null} [key] VerifyBySMS key
             */

            /**
             * Constructs a new VerifyBySMS.
             * @memberof api.common
             * @classdesc Represents a VerifyBySMS.
             * @implements IVerifyBySMS
             * @constructor
             * @param {api.common.IVerifyBySMS=} [properties] Properties to set
             */
            function VerifyBySMS(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * VerifyBySMS key.
             * @member {string} key
             * @memberof api.common.VerifyBySMS
             * @instance
             */
            VerifyBySMS.prototype.key = "";

            /**
             * Creates a new VerifyBySMS instance using the specified properties.
             * @function create
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {api.common.IVerifyBySMS=} [properties] Properties to set
             * @returns {api.common.VerifyBySMS} VerifyBySMS instance
             */
            VerifyBySMS.create = function create(properties) {
                return new VerifyBySMS(properties);
            };

            /**
             * Encodes the specified VerifyBySMS message. Does not implicitly {@link api.common.VerifyBySMS.verify|verify} messages.
             * @function encode
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {api.common.IVerifyBySMS} message VerifyBySMS message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyBySMS.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                return writer;
            };

            /**
             * Encodes the specified VerifyBySMS message, length delimited. Does not implicitly {@link api.common.VerifyBySMS.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {api.common.IVerifyBySMS} message VerifyBySMS message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyBySMS.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VerifyBySMS message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.VerifyBySMS} VerifyBySMS
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyBySMS.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.VerifyBySMS();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.key = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VerifyBySMS message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.VerifyBySMS} VerifyBySMS
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyBySMS.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VerifyBySMS message.
             * @function verify
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VerifyBySMS.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.key != null && message.hasOwnProperty("key"))
                    if (!$util.isString(message.key))
                        return "key: string expected";
                return null;
            };

            /**
             * Creates a VerifyBySMS message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.VerifyBySMS} VerifyBySMS
             */
            VerifyBySMS.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.VerifyBySMS)
                    return object;
                let message = new $root.api.common.VerifyBySMS();
                if (object.key != null)
                    message.key = String(object.key);
                return message;
            };

            /**
             * Creates a plain object from a VerifyBySMS message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {api.common.VerifyBySMS} message VerifyBySMS
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VerifyBySMS.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.key = "";
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                return object;
            };

            /**
             * Converts this VerifyBySMS to JSON.
             * @function toJSON
             * @memberof api.common.VerifyBySMS
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VerifyBySMS.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for VerifyBySMS
             * @function getTypeUrl
             * @memberof api.common.VerifyBySMS
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VerifyBySMS.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.VerifyBySMS";
            };

            return VerifyBySMS;
        })();

        common.VerifyByPassword = (function() {

            /**
             * Properties of a VerifyByPassword.
             * @memberof api.common
             * @interface IVerifyByPassword
             * @property {string|null} [passwd] VerifyByPassword passwd
             */

            /**
             * Constructs a new VerifyByPassword.
             * @memberof api.common
             * @classdesc Represents a VerifyByPassword.
             * @implements IVerifyByPassword
             * @constructor
             * @param {api.common.IVerifyByPassword=} [properties] Properties to set
             */
            function VerifyByPassword(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * VerifyByPassword passwd.
             * @member {string} passwd
             * @memberof api.common.VerifyByPassword
             * @instance
             */
            VerifyByPassword.prototype.passwd = "";

            /**
             * Creates a new VerifyByPassword instance using the specified properties.
             * @function create
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {api.common.IVerifyByPassword=} [properties] Properties to set
             * @returns {api.common.VerifyByPassword} VerifyByPassword instance
             */
            VerifyByPassword.create = function create(properties) {
                return new VerifyByPassword(properties);
            };

            /**
             * Encodes the specified VerifyByPassword message. Does not implicitly {@link api.common.VerifyByPassword.verify|verify} messages.
             * @function encode
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {api.common.IVerifyByPassword} message VerifyByPassword message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyByPassword.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.passwd != null && Object.hasOwnProperty.call(message, "passwd"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.passwd);
                return writer;
            };

            /**
             * Encodes the specified VerifyByPassword message, length delimited. Does not implicitly {@link api.common.VerifyByPassword.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {api.common.IVerifyByPassword} message VerifyByPassword message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyByPassword.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VerifyByPassword message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.VerifyByPassword} VerifyByPassword
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyByPassword.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.VerifyByPassword();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.passwd = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VerifyByPassword message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.VerifyByPassword} VerifyByPassword
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyByPassword.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VerifyByPassword message.
             * @function verify
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VerifyByPassword.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.passwd != null && message.hasOwnProperty("passwd"))
                    if (!$util.isString(message.passwd))
                        return "passwd: string expected";
                return null;
            };

            /**
             * Creates a VerifyByPassword message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.VerifyByPassword} VerifyByPassword
             */
            VerifyByPassword.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.VerifyByPassword)
                    return object;
                let message = new $root.api.common.VerifyByPassword();
                if (object.passwd != null)
                    message.passwd = String(object.passwd);
                return message;
            };

            /**
             * Creates a plain object from a VerifyByPassword message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {api.common.VerifyByPassword} message VerifyByPassword
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VerifyByPassword.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.passwd = "";
                if (message.passwd != null && message.hasOwnProperty("passwd"))
                    object.passwd = message.passwd;
                return object;
            };

            /**
             * Converts this VerifyByPassword to JSON.
             * @function toJSON
             * @memberof api.common.VerifyByPassword
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VerifyByPassword.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for VerifyByPassword
             * @function getTypeUrl
             * @memberof api.common.VerifyByPassword
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VerifyByPassword.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.VerifyByPassword";
            };

            return VerifyByPassword;
        })();

        common.VerifyByCaptcha = (function() {

            /**
             * Properties of a VerifyByCaptcha.
             * @memberof api.common
             * @interface IVerifyByCaptcha
             * @property {string|null} [key] VerifyByCaptcha key
             * @property {string|null} [answer] VerifyByCaptcha answer
             */

            /**
             * Constructs a new VerifyByCaptcha.
             * @memberof api.common
             * @classdesc Represents a VerifyByCaptcha.
             * @implements IVerifyByCaptcha
             * @constructor
             * @param {api.common.IVerifyByCaptcha=} [properties] Properties to set
             */
            function VerifyByCaptcha(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * VerifyByCaptcha key.
             * @member {string} key
             * @memberof api.common.VerifyByCaptcha
             * @instance
             */
            VerifyByCaptcha.prototype.key = "";

            /**
             * VerifyByCaptcha answer.
             * @member {string} answer
             * @memberof api.common.VerifyByCaptcha
             * @instance
             */
            VerifyByCaptcha.prototype.answer = "";

            /**
             * Creates a new VerifyByCaptcha instance using the specified properties.
             * @function create
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {api.common.IVerifyByCaptcha=} [properties] Properties to set
             * @returns {api.common.VerifyByCaptcha} VerifyByCaptcha instance
             */
            VerifyByCaptcha.create = function create(properties) {
                return new VerifyByCaptcha(properties);
            };

            /**
             * Encodes the specified VerifyByCaptcha message. Does not implicitly {@link api.common.VerifyByCaptcha.verify|verify} messages.
             * @function encode
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {api.common.IVerifyByCaptcha} message VerifyByCaptcha message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyByCaptcha.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                if (message.answer != null && Object.hasOwnProperty.call(message, "answer"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.answer);
                return writer;
            };

            /**
             * Encodes the specified VerifyByCaptcha message, length delimited. Does not implicitly {@link api.common.VerifyByCaptcha.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {api.common.IVerifyByCaptcha} message VerifyByCaptcha message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyByCaptcha.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VerifyByCaptcha message from the specified reader or buffer.
             * @function decode
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.common.VerifyByCaptcha} VerifyByCaptcha
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyByCaptcha.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.common.VerifyByCaptcha();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.key = reader.string();
                            break;
                        }
                    case 2: {
                            message.answer = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VerifyByCaptcha message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.common.VerifyByCaptcha} VerifyByCaptcha
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyByCaptcha.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VerifyByCaptcha message.
             * @function verify
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VerifyByCaptcha.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.key != null && message.hasOwnProperty("key"))
                    if (!$util.isString(message.key))
                        return "key: string expected";
                if (message.answer != null && message.hasOwnProperty("answer"))
                    if (!$util.isString(message.answer))
                        return "answer: string expected";
                return null;
            };

            /**
             * Creates a VerifyByCaptcha message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.common.VerifyByCaptcha} VerifyByCaptcha
             */
            VerifyByCaptcha.fromObject = function fromObject(object) {
                if (object instanceof $root.api.common.VerifyByCaptcha)
                    return object;
                let message = new $root.api.common.VerifyByCaptcha();
                if (object.key != null)
                    message.key = String(object.key);
                if (object.answer != null)
                    message.answer = String(object.answer);
                return message;
            };

            /**
             * Creates a plain object from a VerifyByCaptcha message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {api.common.VerifyByCaptcha} message VerifyByCaptcha
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VerifyByCaptcha.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.key = "";
                    object.answer = "";
                }
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.answer != null && message.hasOwnProperty("answer"))
                    object.answer = message.answer;
                return object;
            };

            /**
             * Converts this VerifyByCaptcha to JSON.
             * @function toJSON
             * @memberof api.common.VerifyByCaptcha
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VerifyByCaptcha.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for VerifyByCaptcha
             * @function getTypeUrl
             * @memberof api.common.VerifyByCaptcha
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VerifyByCaptcha.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.common.VerifyByCaptcha";
            };

            return VerifyByCaptcha;
        })();

        return common;
    })();

    /**
     * AuthenticationRole enum.
     * @name api.AuthenticationRole
     * @enum {number}
     * @property {number} AUTH_ROLE_NONE=0 AUTH_ROLE_NONE value
     * @property {number} AUTH_ROLE_ACCOUNT=1 AUTH_ROLE_ACCOUNT value
     * @property {number} AUTH_ROLE_DEVICE=2 AUTH_ROLE_DEVICE value
     * @property {number} AUTH_ROLE_SERVICE=4 AUTH_ROLE_SERVICE value
     * @property {number} AUTH_ROLE_EXTERNAL=8 AUTH_ROLE_EXTERNAL value
     * @property {number} AUTH_ROLE_TENANT=16 AUTH_ROLE_TENANT value
     * @property {number} AUTH_ROLE_ADMIN=32 AUTH_ROLE_ADMIN value
     * @property {number} AUTH_ROLE_PUBLIC=64 AUTH_ROLE_PUBLIC value
     */
    api.AuthenticationRole = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "AUTH_ROLE_NONE"] = 0;
        values[valuesById[1] = "AUTH_ROLE_ACCOUNT"] = 1;
        values[valuesById[2] = "AUTH_ROLE_DEVICE"] = 2;
        values[valuesById[4] = "AUTH_ROLE_SERVICE"] = 4;
        values[valuesById[8] = "AUTH_ROLE_EXTERNAL"] = 8;
        values[valuesById[16] = "AUTH_ROLE_TENANT"] = 16;
        values[valuesById[32] = "AUTH_ROLE_ADMIN"] = 32;
        values[valuesById[64] = "AUTH_ROLE_PUBLIC"] = 64;
        return values;
    })();

    /**
     * PayloadType enum.
     * @name api.PayloadType
     * @enum {number}
     * @property {number} JSON_PAYLOAD=0 JSON_PAYLOAD value
     * @property {number} BINARY_PAYLOAD=1 BINARY_PAYLOAD value
     */
    api.PayloadType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "JSON_PAYLOAD"] = 0;
        values[valuesById[1] = "BINARY_PAYLOAD"] = 1;
        return values;
    })();

    api.Payload = (function() {

        /**
         * Properties of a Payload.
         * @memberof api
         * @interface IPayload
         * @property {api.PayloadType|null} [type] Payload type
         * @property {Array.<api.IExample>|null} [examples] Payload examples
         */

        /**
         * Constructs a new Payload.
         * @memberof api
         * @classdesc Represents a Payload.
         * @implements IPayload
         * @constructor
         * @param {api.IPayload=} [properties] Properties to set
         */
        function Payload(properties) {
            this.examples = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Payload type.
         * @member {api.PayloadType} type
         * @memberof api.Payload
         * @instance
         */
        Payload.prototype.type = 0;

        /**
         * Payload examples.
         * @member {Array.<api.IExample>} examples
         * @memberof api.Payload
         * @instance
         */
        Payload.prototype.examples = $util.emptyArray;

        /**
         * Creates a new Payload instance using the specified properties.
         * @function create
         * @memberof api.Payload
         * @static
         * @param {api.IPayload=} [properties] Properties to set
         * @returns {api.Payload} Payload instance
         */
        Payload.create = function create(properties) {
            return new Payload(properties);
        };

        /**
         * Encodes the specified Payload message. Does not implicitly {@link api.Payload.verify|verify} messages.
         * @function encode
         * @memberof api.Payload
         * @static
         * @param {api.IPayload} message Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.examples != null && message.examples.length)
                for (let i = 0; i < message.examples.length; ++i)
                    $root.api.Example.encode(message.examples[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Payload message, length delimited. Does not implicitly {@link api.Payload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Payload
         * @static
         * @param {api.IPayload} message Payload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Payload message from the specified reader or buffer.
         * @function decode
         * @memberof api.Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Payload} Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Payload();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        if (!(message.examples && message.examples.length))
                            message.examples = [];
                        message.examples.push($root.api.Example.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Payload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Payload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Payload} Payload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Payload message.
         * @function verify
         * @memberof api.Payload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Payload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.examples != null && message.hasOwnProperty("examples")) {
                if (!Array.isArray(message.examples))
                    return "examples: array expected";
                for (let i = 0; i < message.examples.length; ++i) {
                    let error = $root.api.Example.verify(message.examples[i]);
                    if (error)
                        return "examples." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Payload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Payload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Payload} Payload
         */
        Payload.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Payload)
                return object;
            let message = new $root.api.Payload();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "JSON_PAYLOAD":
            case 0:
                message.type = 0;
                break;
            case "BINARY_PAYLOAD":
            case 1:
                message.type = 1;
                break;
            }
            if (object.examples) {
                if (!Array.isArray(object.examples))
                    throw TypeError(".api.Payload.examples: array expected");
                message.examples = [];
                for (let i = 0; i < object.examples.length; ++i) {
                    if (typeof object.examples[i] !== "object")
                        throw TypeError(".api.Payload.examples: object expected");
                    message.examples[i] = $root.api.Example.fromObject(object.examples[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Payload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Payload
         * @static
         * @param {api.Payload} message Payload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Payload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.examples = [];
            if (options.defaults)
                object.type = options.enums === String ? "JSON_PAYLOAD" : 0;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.api.PayloadType[message.type] === undefined ? message.type : $root.api.PayloadType[message.type] : message.type;
            if (message.examples && message.examples.length) {
                object.examples = [];
                for (let j = 0; j < message.examples.length; ++j)
                    object.examples[j] = $root.api.Example.toObject(message.examples[j], options);
            }
            return object;
        };

        /**
         * Converts this Payload to JSON.
         * @function toJSON
         * @memberof api.Payload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Payload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Payload
         * @function getTypeUrl
         * @memberof api.Payload
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Payload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.Payload";
        };

        return Payload;
    })();

    api.ExternalDocumentation = (function() {

        /**
         * Properties of an ExternalDocumentation.
         * @memberof api
         * @interface IExternalDocumentation
         * @property {string|null} [description] ExternalDocumentation description
         * @property {string|null} [url] ExternalDocumentation url
         */

        /**
         * Constructs a new ExternalDocumentation.
         * @memberof api
         * @classdesc Represents an ExternalDocumentation.
         * @implements IExternalDocumentation
         * @constructor
         * @param {api.IExternalDocumentation=} [properties] Properties to set
         */
        function ExternalDocumentation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExternalDocumentation description.
         * @member {string|null|undefined} description
         * @memberof api.ExternalDocumentation
         * @instance
         */
        ExternalDocumentation.prototype.description = null;

        /**
         * ExternalDocumentation url.
         * @member {string|null|undefined} url
         * @memberof api.ExternalDocumentation
         * @instance
         */
        ExternalDocumentation.prototype.url = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ExternalDocumentation _description.
         * @member {"description"|undefined} _description
         * @memberof api.ExternalDocumentation
         * @instance
         */
        Object.defineProperty(ExternalDocumentation.prototype, "_description", {
            get: $util.oneOfGetter($oneOfFields = ["description"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ExternalDocumentation _url.
         * @member {"url"|undefined} _url
         * @memberof api.ExternalDocumentation
         * @instance
         */
        Object.defineProperty(ExternalDocumentation.prototype, "_url", {
            get: $util.oneOfGetter($oneOfFields = ["url"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ExternalDocumentation instance using the specified properties.
         * @function create
         * @memberof api.ExternalDocumentation
         * @static
         * @param {api.IExternalDocumentation=} [properties] Properties to set
         * @returns {api.ExternalDocumentation} ExternalDocumentation instance
         */
        ExternalDocumentation.create = function create(properties) {
            return new ExternalDocumentation(properties);
        };

        /**
         * Encodes the specified ExternalDocumentation message. Does not implicitly {@link api.ExternalDocumentation.verify|verify} messages.
         * @function encode
         * @memberof api.ExternalDocumentation
         * @static
         * @param {api.IExternalDocumentation} message ExternalDocumentation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExternalDocumentation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.description);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            return writer;
        };

        /**
         * Encodes the specified ExternalDocumentation message, length delimited. Does not implicitly {@link api.ExternalDocumentation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ExternalDocumentation
         * @static
         * @param {api.IExternalDocumentation} message ExternalDocumentation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExternalDocumentation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExternalDocumentation message from the specified reader or buffer.
         * @function decode
         * @memberof api.ExternalDocumentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ExternalDocumentation} ExternalDocumentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExternalDocumentation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ExternalDocumentation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.description = reader.string();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExternalDocumentation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ExternalDocumentation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ExternalDocumentation} ExternalDocumentation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExternalDocumentation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExternalDocumentation message.
         * @function verify
         * @memberof api.ExternalDocumentation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExternalDocumentation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.description != null && message.hasOwnProperty("description")) {
                properties._description = 1;
                if (!$util.isString(message.description))
                    return "description: string expected";
            }
            if (message.url != null && message.hasOwnProperty("url")) {
                properties._url = 1;
                if (!$util.isString(message.url))
                    return "url: string expected";
            }
            return null;
        };

        /**
         * Creates an ExternalDocumentation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ExternalDocumentation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ExternalDocumentation} ExternalDocumentation
         */
        ExternalDocumentation.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ExternalDocumentation)
                return object;
            let message = new $root.api.ExternalDocumentation();
            if (object.description != null)
                message.description = String(object.description);
            if (object.url != null)
                message.url = String(object.url);
            return message;
        };

        /**
         * Creates a plain object from an ExternalDocumentation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ExternalDocumentation
         * @static
         * @param {api.ExternalDocumentation} message ExternalDocumentation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExternalDocumentation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.description != null && message.hasOwnProperty("description")) {
                object.description = message.description;
                if (options.oneofs)
                    object._description = "description";
            }
            if (message.url != null && message.hasOwnProperty("url")) {
                object.url = message.url;
                if (options.oneofs)
                    object._url = "url";
            }
            return object;
        };

        /**
         * Converts this ExternalDocumentation to JSON.
         * @function toJSON
         * @memberof api.ExternalDocumentation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExternalDocumentation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExternalDocumentation
         * @function getTypeUrl
         * @memberof api.ExternalDocumentation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExternalDocumentation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ExternalDocumentation";
        };

        return ExternalDocumentation;
    })();

    api.Example = (function() {

        /**
         * Properties of an Example.
         * @memberof api
         * @interface IExample
         * @property {string|null} [summary] Example summary
         * @property {string|null} [description] Example description
         * @property {string|null} [value] Example value
         * @property {string|null} [externalValue] Example externalValue
         */

        /**
         * Constructs a new Example.
         * @memberof api
         * @classdesc Represents an Example.
         * @implements IExample
         * @constructor
         * @param {api.IExample=} [properties] Properties to set
         */
        function Example(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Example summary.
         * @member {string|null|undefined} summary
         * @memberof api.Example
         * @instance
         */
        Example.prototype.summary = null;

        /**
         * Example description.
         * @member {string|null|undefined} description
         * @memberof api.Example
         * @instance
         */
        Example.prototype.description = null;

        /**
         * Example value.
         * @member {string|null|undefined} value
         * @memberof api.Example
         * @instance
         */
        Example.prototype.value = null;

        /**
         * Example externalValue.
         * @member {string|null|undefined} externalValue
         * @memberof api.Example
         * @instance
         */
        Example.prototype.externalValue = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Example _summary.
         * @member {"summary"|undefined} _summary
         * @memberof api.Example
         * @instance
         */
        Object.defineProperty(Example.prototype, "_summary", {
            get: $util.oneOfGetter($oneOfFields = ["summary"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Example _description.
         * @member {"description"|undefined} _description
         * @memberof api.Example
         * @instance
         */
        Object.defineProperty(Example.prototype, "_description", {
            get: $util.oneOfGetter($oneOfFields = ["description"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Example _value.
         * @member {"value"|undefined} _value
         * @memberof api.Example
         * @instance
         */
        Object.defineProperty(Example.prototype, "_value", {
            get: $util.oneOfGetter($oneOfFields = ["value"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Example _externalValue.
         * @member {"externalValue"|undefined} _externalValue
         * @memberof api.Example
         * @instance
         */
        Object.defineProperty(Example.prototype, "_externalValue", {
            get: $util.oneOfGetter($oneOfFields = ["externalValue"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Example instance using the specified properties.
         * @function create
         * @memberof api.Example
         * @static
         * @param {api.IExample=} [properties] Properties to set
         * @returns {api.Example} Example instance
         */
        Example.create = function create(properties) {
            return new Example(properties);
        };

        /**
         * Encodes the specified Example message. Does not implicitly {@link api.Example.verify|verify} messages.
         * @function encode
         * @memberof api.Example
         * @static
         * @param {api.IExample} message Example message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Example.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.summary);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.externalValue != null && Object.hasOwnProperty.call(message, "externalValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.externalValue);
            return writer;
        };

        /**
         * Encodes the specified Example message, length delimited. Does not implicitly {@link api.Example.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Example
         * @static
         * @param {api.IExample} message Example message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Example.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Example message from the specified reader or buffer.
         * @function decode
         * @memberof api.Example
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Example} Example
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Example.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Example();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.summary = reader.string();
                        break;
                    }
                case 2: {
                        message.description = reader.string();
                        break;
                    }
                case 3: {
                        message.value = reader.string();
                        break;
                    }
                case 4: {
                        message.externalValue = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Example message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Example
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Example} Example
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Example.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Example message.
         * @function verify
         * @memberof api.Example
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Example.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.summary != null && message.hasOwnProperty("summary")) {
                properties._summary = 1;
                if (!$util.isString(message.summary))
                    return "summary: string expected";
            }
            if (message.description != null && message.hasOwnProperty("description")) {
                properties._description = 1;
                if (!$util.isString(message.description))
                    return "description: string expected";
            }
            if (message.value != null && message.hasOwnProperty("value")) {
                properties._value = 1;
                if (!$util.isString(message.value))
                    return "value: string expected";
            }
            if (message.externalValue != null && message.hasOwnProperty("externalValue")) {
                properties._externalValue = 1;
                if (!$util.isString(message.externalValue))
                    return "externalValue: string expected";
            }
            return null;
        };

        /**
         * Creates an Example message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Example
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Example} Example
         */
        Example.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Example)
                return object;
            let message = new $root.api.Example();
            if (object.summary != null)
                message.summary = String(object.summary);
            if (object.description != null)
                message.description = String(object.description);
            if (object.value != null)
                message.value = String(object.value);
            if (object.externalValue != null)
                message.externalValue = String(object.externalValue);
            return message;
        };

        /**
         * Creates a plain object from an Example message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Example
         * @static
         * @param {api.Example} message Example
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Example.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.summary != null && message.hasOwnProperty("summary")) {
                object.summary = message.summary;
                if (options.oneofs)
                    object._summary = "summary";
            }
            if (message.description != null && message.hasOwnProperty("description")) {
                object.description = message.description;
                if (options.oneofs)
                    object._description = "description";
            }
            if (message.value != null && message.hasOwnProperty("value")) {
                object.value = message.value;
                if (options.oneofs)
                    object._value = "value";
            }
            if (message.externalValue != null && message.hasOwnProperty("externalValue")) {
                object.externalValue = message.externalValue;
                if (options.oneofs)
                    object._externalValue = "externalValue";
            }
            return object;
        };

        /**
         * Converts this Example to JSON.
         * @function toJSON
         * @memberof api.Example
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Example.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Example
         * @function getTypeUrl
         * @memberof api.Example
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Example.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.Example";
        };

        return Example;
    })();

    api.MessageCache = (function() {

        /**
         * Properties of a MessageCache.
         * @memberof api
         * @interface IMessageCache
         * @property {string|null} [name] MessageCache name
         * @property {string|null} [id] MessageCache id
         * @property {string|null} [tag] MessageCache tag
         */

        /**
         * Constructs a new MessageCache.
         * @memberof api
         * @classdesc Represents a MessageCache.
         * @implements IMessageCache
         * @constructor
         * @param {api.IMessageCache=} [properties] Properties to set
         */
        function MessageCache(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageCache name.
         * @member {string|null|undefined} name
         * @memberof api.MessageCache
         * @instance
         */
        MessageCache.prototype.name = null;

        /**
         * MessageCache id.
         * @member {string|null|undefined} id
         * @memberof api.MessageCache
         * @instance
         */
        MessageCache.prototype.id = null;

        /**
         * MessageCache tag.
         * @member {string|null|undefined} tag
         * @memberof api.MessageCache
         * @instance
         */
        MessageCache.prototype.tag = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MessageCache _name.
         * @member {"name"|undefined} _name
         * @memberof api.MessageCache
         * @instance
         */
        Object.defineProperty(MessageCache.prototype, "_name", {
            get: $util.oneOfGetter($oneOfFields = ["name"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MessageCache _id.
         * @member {"id"|undefined} _id
         * @memberof api.MessageCache
         * @instance
         */
        Object.defineProperty(MessageCache.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MessageCache _tag.
         * @member {"tag"|undefined} _tag
         * @memberof api.MessageCache
         * @instance
         */
        Object.defineProperty(MessageCache.prototype, "_tag", {
            get: $util.oneOfGetter($oneOfFields = ["tag"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MessageCache instance using the specified properties.
         * @function create
         * @memberof api.MessageCache
         * @static
         * @param {api.IMessageCache=} [properties] Properties to set
         * @returns {api.MessageCache} MessageCache instance
         */
        MessageCache.create = function create(properties) {
            return new MessageCache(properties);
        };

        /**
         * Encodes the specified MessageCache message. Does not implicitly {@link api.MessageCache.verify|verify} messages.
         * @function encode
         * @memberof api.MessageCache
         * @static
         * @param {api.IMessageCache} message MessageCache message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageCache.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tag);
            return writer;
        };

        /**
         * Encodes the specified MessageCache message, length delimited. Does not implicitly {@link api.MessageCache.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MessageCache
         * @static
         * @param {api.IMessageCache} message MessageCache message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageCache.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MessageCache message from the specified reader or buffer.
         * @function decode
         * @memberof api.MessageCache
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MessageCache} MessageCache
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageCache.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MessageCache();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.id = reader.string();
                        break;
                    }
                case 3: {
                        message.tag = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MessageCache message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MessageCache
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MessageCache} MessageCache
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageCache.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MessageCache message.
         * @function verify
         * @memberof api.MessageCache
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MessageCache.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                properties._name = 1;
                if (!$util.isString(message.name))
                    return "name: string expected";
            }
            if (message.id != null && message.hasOwnProperty("id")) {
                properties._id = 1;
                if (!$util.isString(message.id))
                    return "id: string expected";
            }
            if (message.tag != null && message.hasOwnProperty("tag")) {
                properties._tag = 1;
                if (!$util.isString(message.tag))
                    return "tag: string expected";
            }
            return null;
        };

        /**
         * Creates a MessageCache message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MessageCache
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MessageCache} MessageCache
         */
        MessageCache.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MessageCache)
                return object;
            let message = new $root.api.MessageCache();
            if (object.name != null)
                message.name = String(object.name);
            if (object.id != null)
                message.id = String(object.id);
            if (object.tag != null)
                message.tag = String(object.tag);
            return message;
        };

        /**
         * Creates a plain object from a MessageCache message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MessageCache
         * @static
         * @param {api.MessageCache} message MessageCache
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MessageCache.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.name != null && message.hasOwnProperty("name")) {
                object.name = message.name;
                if (options.oneofs)
                    object._name = "name";
            }
            if (message.id != null && message.hasOwnProperty("id")) {
                object.id = message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.tag != null && message.hasOwnProperty("tag")) {
                object.tag = message.tag;
                if (options.oneofs)
                    object._tag = "tag";
            }
            return object;
        };

        /**
         * Converts this MessageCache to JSON.
         * @function toJSON
         * @memberof api.MessageCache
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MessageCache.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MessageCache
         * @function getTypeUrl
         * @memberof api.MessageCache
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageCache.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MessageCache";
        };

        return MessageCache;
    })();

    api.InfoOptions = (function() {

        /**
         * Properties of an InfoOptions.
         * @memberof api
         * @interface IInfoOptions
         * @property {string|null} [appId] InfoOptions appId
         * @property {Array.<string>|null} [authors] InfoOptions authors
         * @property {boolean|null} [inheritAuthors] InfoOptions inheritAuthors
         * @property {string|null} [date] InfoOptions date
         * @property {Array.<string>|null} [tags] InfoOptions tags
         * @property {Array.<api.AuthenticationRole>|null} [auth] InfoOptions auth
         * @property {string|null} [path] InfoOptions path
         * @property {number|null} [qos] InfoOptions qos
         */

        /**
         * Constructs a new InfoOptions.
         * @memberof api
         * @classdesc Represents an InfoOptions.
         * @implements IInfoOptions
         * @constructor
         * @param {api.IInfoOptions=} [properties] Properties to set
         */
        function InfoOptions(properties) {
            this.authors = [];
            this.tags = [];
            this.auth = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InfoOptions appId.
         * @member {string|null|undefined} appId
         * @memberof api.InfoOptions
         * @instance
         */
        InfoOptions.prototype.appId = null;

        /**
         * InfoOptions authors.
         * @member {Array.<string>} authors
         * @memberof api.InfoOptions
         * @instance
         */
        InfoOptions.prototype.authors = $util.emptyArray;

        /**
         * InfoOptions inheritAuthors.
         * @member {boolean|null|undefined} inheritAuthors
         * @memberof api.InfoOptions
         * @instance
         */
        InfoOptions.prototype.inheritAuthors = null;

        /**
         * InfoOptions date.
         * @member {string|null|undefined} date
         * @memberof api.InfoOptions
         * @instance
         */
        InfoOptions.prototype.date = null;

        /**
         * InfoOptions tags.
         * @member {Array.<string>} tags
         * @memberof api.InfoOptions
         * @instance
         */
        InfoOptions.prototype.tags = $util.emptyArray;

        /**
         * InfoOptions auth.
         * @member {Array.<api.AuthenticationRole>} auth
         * @memberof api.InfoOptions
         * @instance
         */
        InfoOptions.prototype.auth = $util.emptyArray;

        /**
         * InfoOptions path.
         * @member {string|null|undefined} path
         * @memberof api.InfoOptions
         * @instance
         */
        InfoOptions.prototype.path = null;

        /**
         * InfoOptions qos.
         * @member {number|null|undefined} qos
         * @memberof api.InfoOptions
         * @instance
         */
        InfoOptions.prototype.qos = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * InfoOptions _appId.
         * @member {"appId"|undefined} _appId
         * @memberof api.InfoOptions
         * @instance
         */
        Object.defineProperty(InfoOptions.prototype, "_appId", {
            get: $util.oneOfGetter($oneOfFields = ["appId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InfoOptions _inheritAuthors.
         * @member {"inheritAuthors"|undefined} _inheritAuthors
         * @memberof api.InfoOptions
         * @instance
         */
        Object.defineProperty(InfoOptions.prototype, "_inheritAuthors", {
            get: $util.oneOfGetter($oneOfFields = ["inheritAuthors"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InfoOptions _date.
         * @member {"date"|undefined} _date
         * @memberof api.InfoOptions
         * @instance
         */
        Object.defineProperty(InfoOptions.prototype, "_date", {
            get: $util.oneOfGetter($oneOfFields = ["date"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InfoOptions _path.
         * @member {"path"|undefined} _path
         * @memberof api.InfoOptions
         * @instance
         */
        Object.defineProperty(InfoOptions.prototype, "_path", {
            get: $util.oneOfGetter($oneOfFields = ["path"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * InfoOptions _qos.
         * @member {"qos"|undefined} _qos
         * @memberof api.InfoOptions
         * @instance
         */
        Object.defineProperty(InfoOptions.prototype, "_qos", {
            get: $util.oneOfGetter($oneOfFields = ["qos"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new InfoOptions instance using the specified properties.
         * @function create
         * @memberof api.InfoOptions
         * @static
         * @param {api.IInfoOptions=} [properties] Properties to set
         * @returns {api.InfoOptions} InfoOptions instance
         */
        InfoOptions.create = function create(properties) {
            return new InfoOptions(properties);
        };

        /**
         * Encodes the specified InfoOptions message. Does not implicitly {@link api.InfoOptions.verify|verify} messages.
         * @function encode
         * @memberof api.InfoOptions
         * @static
         * @param {api.IInfoOptions} message InfoOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InfoOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.appId != null && Object.hasOwnProperty.call(message, "appId"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.appId);
            if (message.authors != null && message.authors.length)
                for (let i = 0; i < message.authors.length; ++i)
                    writer.uint32(/* id 100, wireType 2 =*/802).string(message.authors[i]);
            if (message.inheritAuthors != null && Object.hasOwnProperty.call(message, "inheritAuthors"))
                writer.uint32(/* id 101, wireType 0 =*/808).bool(message.inheritAuthors);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 102, wireType 2 =*/818).string(message.date);
            if (message.tags != null && message.tags.length)
                for (let i = 0; i < message.tags.length; ++i)
                    writer.uint32(/* id 103, wireType 2 =*/826).string(message.tags[i]);
            if (message.auth != null && message.auth.length) {
                writer.uint32(/* id 201, wireType 2 =*/1610).fork();
                for (let i = 0; i < message.auth.length; ++i)
                    writer.int32(message.auth[i]);
                writer.ldelim();
            }
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 202, wireType 2 =*/1618).string(message.path);
            if (message.qos != null && Object.hasOwnProperty.call(message, "qos"))
                writer.uint32(/* id 203, wireType 0 =*/1624).int32(message.qos);
            return writer;
        };

        /**
         * Encodes the specified InfoOptions message, length delimited. Does not implicitly {@link api.InfoOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.InfoOptions
         * @static
         * @param {api.IInfoOptions} message InfoOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InfoOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InfoOptions message from the specified reader or buffer.
         * @function decode
         * @memberof api.InfoOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.InfoOptions} InfoOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InfoOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.InfoOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 13: {
                        message.appId = reader.string();
                        break;
                    }
                case 100: {
                        if (!(message.authors && message.authors.length))
                            message.authors = [];
                        message.authors.push(reader.string());
                        break;
                    }
                case 101: {
                        message.inheritAuthors = reader.bool();
                        break;
                    }
                case 102: {
                        message.date = reader.string();
                        break;
                    }
                case 103: {
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    }
                case 201: {
                        if (!(message.auth && message.auth.length))
                            message.auth = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.auth.push(reader.int32());
                        } else
                            message.auth.push(reader.int32());
                        break;
                    }
                case 202: {
                        message.path = reader.string();
                        break;
                    }
                case 203: {
                        message.qos = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InfoOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.InfoOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.InfoOptions} InfoOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InfoOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InfoOptions message.
         * @function verify
         * @memberof api.InfoOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InfoOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.appId != null && message.hasOwnProperty("appId")) {
                properties._appId = 1;
                if (!$util.isString(message.appId))
                    return "appId: string expected";
            }
            if (message.authors != null && message.hasOwnProperty("authors")) {
                if (!Array.isArray(message.authors))
                    return "authors: array expected";
                for (let i = 0; i < message.authors.length; ++i)
                    if (!$util.isString(message.authors[i]))
                        return "authors: string[] expected";
            }
            if (message.inheritAuthors != null && message.hasOwnProperty("inheritAuthors")) {
                properties._inheritAuthors = 1;
                if (typeof message.inheritAuthors !== "boolean")
                    return "inheritAuthors: boolean expected";
            }
            if (message.date != null && message.hasOwnProperty("date")) {
                properties._date = 1;
                if (!$util.isString(message.date))
                    return "date: string expected";
            }
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (let i = 0; i < message.tags.length; ++i)
                    if (!$util.isString(message.tags[i]))
                        return "tags: string[] expected";
            }
            if (message.auth != null && message.hasOwnProperty("auth")) {
                if (!Array.isArray(message.auth))
                    return "auth: array expected";
                for (let i = 0; i < message.auth.length; ++i)
                    switch (message.auth[i]) {
                    default:
                        return "auth: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                    case 8:
                    case 16:
                    case 32:
                    case 64:
                        break;
                    }
            }
            if (message.path != null && message.hasOwnProperty("path")) {
                properties._path = 1;
                if (!$util.isString(message.path))
                    return "path: string expected";
            }
            if (message.qos != null && message.hasOwnProperty("qos")) {
                properties._qos = 1;
                if (!$util.isInteger(message.qos))
                    return "qos: integer expected";
            }
            return null;
        };

        /**
         * Creates an InfoOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.InfoOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.InfoOptions} InfoOptions
         */
        InfoOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.api.InfoOptions)
                return object;
            let message = new $root.api.InfoOptions();
            if (object.appId != null)
                message.appId = String(object.appId);
            if (object.authors) {
                if (!Array.isArray(object.authors))
                    throw TypeError(".api.InfoOptions.authors: array expected");
                message.authors = [];
                for (let i = 0; i < object.authors.length; ++i)
                    message.authors[i] = String(object.authors[i]);
            }
            if (object.inheritAuthors != null)
                message.inheritAuthors = Boolean(object.inheritAuthors);
            if (object.date != null)
                message.date = String(object.date);
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".api.InfoOptions.tags: array expected");
                message.tags = [];
                for (let i = 0; i < object.tags.length; ++i)
                    message.tags[i] = String(object.tags[i]);
            }
            if (object.auth) {
                if (!Array.isArray(object.auth))
                    throw TypeError(".api.InfoOptions.auth: array expected");
                message.auth = [];
                for (let i = 0; i < object.auth.length; ++i)
                    switch (object.auth[i]) {
                    default:
                        if (typeof object.auth[i] === "number") {
                            message.auth[i] = object.auth[i];
                            break;
                        }
                    case "AUTH_ROLE_NONE":
                    case 0:
                        message.auth[i] = 0;
                        break;
                    case "AUTH_ROLE_ACCOUNT":
                    case 1:
                        message.auth[i] = 1;
                        break;
                    case "AUTH_ROLE_DEVICE":
                    case 2:
                        message.auth[i] = 2;
                        break;
                    case "AUTH_ROLE_SERVICE":
                    case 4:
                        message.auth[i] = 4;
                        break;
                    case "AUTH_ROLE_EXTERNAL":
                    case 8:
                        message.auth[i] = 8;
                        break;
                    case "AUTH_ROLE_TENANT":
                    case 16:
                        message.auth[i] = 16;
                        break;
                    case "AUTH_ROLE_ADMIN":
                    case 32:
                        message.auth[i] = 32;
                        break;
                    case "AUTH_ROLE_PUBLIC":
                    case 64:
                        message.auth[i] = 64;
                        break;
                    }
            }
            if (object.path != null)
                message.path = String(object.path);
            if (object.qos != null)
                message.qos = object.qos | 0;
            return message;
        };

        /**
         * Creates a plain object from an InfoOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.InfoOptions
         * @static
         * @param {api.InfoOptions} message InfoOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InfoOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.authors = [];
                object.tags = [];
                object.auth = [];
            }
            if (message.appId != null && message.hasOwnProperty("appId")) {
                object.appId = message.appId;
                if (options.oneofs)
                    object._appId = "appId";
            }
            if (message.authors && message.authors.length) {
                object.authors = [];
                for (let j = 0; j < message.authors.length; ++j)
                    object.authors[j] = message.authors[j];
            }
            if (message.inheritAuthors != null && message.hasOwnProperty("inheritAuthors")) {
                object.inheritAuthors = message.inheritAuthors;
                if (options.oneofs)
                    object._inheritAuthors = "inheritAuthors";
            }
            if (message.date != null && message.hasOwnProperty("date")) {
                object.date = message.date;
                if (options.oneofs)
                    object._date = "date";
            }
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (let j = 0; j < message.tags.length; ++j)
                    object.tags[j] = message.tags[j];
            }
            if (message.auth && message.auth.length) {
                object.auth = [];
                for (let j = 0; j < message.auth.length; ++j)
                    object.auth[j] = options.enums === String ? $root.api.AuthenticationRole[message.auth[j]] === undefined ? message.auth[j] : $root.api.AuthenticationRole[message.auth[j]] : message.auth[j];
            }
            if (message.path != null && message.hasOwnProperty("path")) {
                object.path = message.path;
                if (options.oneofs)
                    object._path = "path";
            }
            if (message.qos != null && message.hasOwnProperty("qos")) {
                object.qos = message.qos;
                if (options.oneofs)
                    object._qos = "qos";
            }
            return object;
        };

        /**
         * Converts this InfoOptions to JSON.
         * @function toJSON
         * @memberof api.InfoOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InfoOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for InfoOptions
         * @function getTypeUrl
         * @memberof api.InfoOptions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InfoOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.InfoOptions";
        };

        return InfoOptions;
    })();

    api.ServiceOptions = (function() {

        /**
         * Properties of a ServiceOptions.
         * @memberof api
         * @interface IServiceOptions
         * @property {string|null} [summary] ServiceOptions summary
         * @property {Array.<string>|null} [authors] ServiceOptions authors
         * @property {boolean|null} [inheritAuthors] ServiceOptions inheritAuthors
         * @property {string|null} [date] ServiceOptions date
         * @property {Array.<string>|null} [tags] ServiceOptions tags
         * @property {Array.<api.AuthenticationRole>|null} [auth] ServiceOptions auth
         * @property {string|null} [path] ServiceOptions path
         * @property {number|null} [qos] ServiceOptions qos
         * @property {api.IMessageCache|null} [cache] ServiceOptions cache
         */

        /**
         * Constructs a new ServiceOptions.
         * @memberof api
         * @classdesc Represents a ServiceOptions.
         * @implements IServiceOptions
         * @constructor
         * @param {api.IServiceOptions=} [properties] Properties to set
         */
        function ServiceOptions(properties) {
            this.authors = [];
            this.tags = [];
            this.auth = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServiceOptions summary.
         * @member {string|null|undefined} summary
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.summary = null;

        /**
         * ServiceOptions authors.
         * @member {Array.<string>} authors
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.authors = $util.emptyArray;

        /**
         * ServiceOptions inheritAuthors.
         * @member {boolean|null|undefined} inheritAuthors
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.inheritAuthors = null;

        /**
         * ServiceOptions date.
         * @member {string|null|undefined} date
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.date = null;

        /**
         * ServiceOptions tags.
         * @member {Array.<string>} tags
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.tags = $util.emptyArray;

        /**
         * ServiceOptions auth.
         * @member {Array.<api.AuthenticationRole>} auth
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.auth = $util.emptyArray;

        /**
         * ServiceOptions path.
         * @member {string|null|undefined} path
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.path = null;

        /**
         * ServiceOptions qos.
         * @member {number|null|undefined} qos
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.qos = null;

        /**
         * ServiceOptions cache.
         * @member {api.IMessageCache|null|undefined} cache
         * @memberof api.ServiceOptions
         * @instance
         */
        ServiceOptions.prototype.cache = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ServiceOptions _summary.
         * @member {"summary"|undefined} _summary
         * @memberof api.ServiceOptions
         * @instance
         */
        Object.defineProperty(ServiceOptions.prototype, "_summary", {
            get: $util.oneOfGetter($oneOfFields = ["summary"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ServiceOptions _inheritAuthors.
         * @member {"inheritAuthors"|undefined} _inheritAuthors
         * @memberof api.ServiceOptions
         * @instance
         */
        Object.defineProperty(ServiceOptions.prototype, "_inheritAuthors", {
            get: $util.oneOfGetter($oneOfFields = ["inheritAuthors"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ServiceOptions _date.
         * @member {"date"|undefined} _date
         * @memberof api.ServiceOptions
         * @instance
         */
        Object.defineProperty(ServiceOptions.prototype, "_date", {
            get: $util.oneOfGetter($oneOfFields = ["date"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ServiceOptions _path.
         * @member {"path"|undefined} _path
         * @memberof api.ServiceOptions
         * @instance
         */
        Object.defineProperty(ServiceOptions.prototype, "_path", {
            get: $util.oneOfGetter($oneOfFields = ["path"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ServiceOptions _qos.
         * @member {"qos"|undefined} _qos
         * @memberof api.ServiceOptions
         * @instance
         */
        Object.defineProperty(ServiceOptions.prototype, "_qos", {
            get: $util.oneOfGetter($oneOfFields = ["qos"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ServiceOptions _cache.
         * @member {"cache"|undefined} _cache
         * @memberof api.ServiceOptions
         * @instance
         */
        Object.defineProperty(ServiceOptions.prototype, "_cache", {
            get: $util.oneOfGetter($oneOfFields = ["cache"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ServiceOptions instance using the specified properties.
         * @function create
         * @memberof api.ServiceOptions
         * @static
         * @param {api.IServiceOptions=} [properties] Properties to set
         * @returns {api.ServiceOptions} ServiceOptions instance
         */
        ServiceOptions.create = function create(properties) {
            return new ServiceOptions(properties);
        };

        /**
         * Encodes the specified ServiceOptions message. Does not implicitly {@link api.ServiceOptions.verify|verify} messages.
         * @function encode
         * @memberof api.ServiceOptions
         * @static
         * @param {api.IServiceOptions} message ServiceOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.summary);
            if (message.authors != null && message.authors.length)
                for (let i = 0; i < message.authors.length; ++i)
                    writer.uint32(/* id 100, wireType 2 =*/802).string(message.authors[i]);
            if (message.inheritAuthors != null && Object.hasOwnProperty.call(message, "inheritAuthors"))
                writer.uint32(/* id 101, wireType 0 =*/808).bool(message.inheritAuthors);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 102, wireType 2 =*/818).string(message.date);
            if (message.tags != null && message.tags.length)
                for (let i = 0; i < message.tags.length; ++i)
                    writer.uint32(/* id 103, wireType 2 =*/826).string(message.tags[i]);
            if (message.auth != null && message.auth.length) {
                writer.uint32(/* id 201, wireType 2 =*/1610).fork();
                for (let i = 0; i < message.auth.length; ++i)
                    writer.int32(message.auth[i]);
                writer.ldelim();
            }
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 202, wireType 2 =*/1618).string(message.path);
            if (message.qos != null && Object.hasOwnProperty.call(message, "qos"))
                writer.uint32(/* id 203, wireType 0 =*/1624).int32(message.qos);
            if (message.cache != null && Object.hasOwnProperty.call(message, "cache"))
                $root.api.MessageCache.encode(message.cache, writer.uint32(/* id 204, wireType 2 =*/1634).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link api.ServiceOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ServiceOptions
         * @static
         * @param {api.IServiceOptions} message ServiceOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServiceOptions message from the specified reader or buffer.
         * @function decode
         * @memberof api.ServiceOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ServiceOptions} ServiceOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ServiceOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.summary = reader.string();
                        break;
                    }
                case 100: {
                        if (!(message.authors && message.authors.length))
                            message.authors = [];
                        message.authors.push(reader.string());
                        break;
                    }
                case 101: {
                        message.inheritAuthors = reader.bool();
                        break;
                    }
                case 102: {
                        message.date = reader.string();
                        break;
                    }
                case 103: {
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    }
                case 201: {
                        if (!(message.auth && message.auth.length))
                            message.auth = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.auth.push(reader.int32());
                        } else
                            message.auth.push(reader.int32());
                        break;
                    }
                case 202: {
                        message.path = reader.string();
                        break;
                    }
                case 203: {
                        message.qos = reader.int32();
                        break;
                    }
                case 204: {
                        message.cache = $root.api.MessageCache.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ServiceOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ServiceOptions} ServiceOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServiceOptions message.
         * @function verify
         * @memberof api.ServiceOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServiceOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.summary != null && message.hasOwnProperty("summary")) {
                properties._summary = 1;
                if (!$util.isString(message.summary))
                    return "summary: string expected";
            }
            if (message.authors != null && message.hasOwnProperty("authors")) {
                if (!Array.isArray(message.authors))
                    return "authors: array expected";
                for (let i = 0; i < message.authors.length; ++i)
                    if (!$util.isString(message.authors[i]))
                        return "authors: string[] expected";
            }
            if (message.inheritAuthors != null && message.hasOwnProperty("inheritAuthors")) {
                properties._inheritAuthors = 1;
                if (typeof message.inheritAuthors !== "boolean")
                    return "inheritAuthors: boolean expected";
            }
            if (message.date != null && message.hasOwnProperty("date")) {
                properties._date = 1;
                if (!$util.isString(message.date))
                    return "date: string expected";
            }
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (let i = 0; i < message.tags.length; ++i)
                    if (!$util.isString(message.tags[i]))
                        return "tags: string[] expected";
            }
            if (message.auth != null && message.hasOwnProperty("auth")) {
                if (!Array.isArray(message.auth))
                    return "auth: array expected";
                for (let i = 0; i < message.auth.length; ++i)
                    switch (message.auth[i]) {
                    default:
                        return "auth: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                    case 8:
                    case 16:
                    case 32:
                    case 64:
                        break;
                    }
            }
            if (message.path != null && message.hasOwnProperty("path")) {
                properties._path = 1;
                if (!$util.isString(message.path))
                    return "path: string expected";
            }
            if (message.qos != null && message.hasOwnProperty("qos")) {
                properties._qos = 1;
                if (!$util.isInteger(message.qos))
                    return "qos: integer expected";
            }
            if (message.cache != null && message.hasOwnProperty("cache")) {
                properties._cache = 1;
                {
                    let error = $root.api.MessageCache.verify(message.cache);
                    if (error)
                        return "cache." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ServiceOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ServiceOptions} ServiceOptions
         */
        ServiceOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ServiceOptions)
                return object;
            let message = new $root.api.ServiceOptions();
            if (object.summary != null)
                message.summary = String(object.summary);
            if (object.authors) {
                if (!Array.isArray(object.authors))
                    throw TypeError(".api.ServiceOptions.authors: array expected");
                message.authors = [];
                for (let i = 0; i < object.authors.length; ++i)
                    message.authors[i] = String(object.authors[i]);
            }
            if (object.inheritAuthors != null)
                message.inheritAuthors = Boolean(object.inheritAuthors);
            if (object.date != null)
                message.date = String(object.date);
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".api.ServiceOptions.tags: array expected");
                message.tags = [];
                for (let i = 0; i < object.tags.length; ++i)
                    message.tags[i] = String(object.tags[i]);
            }
            if (object.auth) {
                if (!Array.isArray(object.auth))
                    throw TypeError(".api.ServiceOptions.auth: array expected");
                message.auth = [];
                for (let i = 0; i < object.auth.length; ++i)
                    switch (object.auth[i]) {
                    default:
                        if (typeof object.auth[i] === "number") {
                            message.auth[i] = object.auth[i];
                            break;
                        }
                    case "AUTH_ROLE_NONE":
                    case 0:
                        message.auth[i] = 0;
                        break;
                    case "AUTH_ROLE_ACCOUNT":
                    case 1:
                        message.auth[i] = 1;
                        break;
                    case "AUTH_ROLE_DEVICE":
                    case 2:
                        message.auth[i] = 2;
                        break;
                    case "AUTH_ROLE_SERVICE":
                    case 4:
                        message.auth[i] = 4;
                        break;
                    case "AUTH_ROLE_EXTERNAL":
                    case 8:
                        message.auth[i] = 8;
                        break;
                    case "AUTH_ROLE_TENANT":
                    case 16:
                        message.auth[i] = 16;
                        break;
                    case "AUTH_ROLE_ADMIN":
                    case 32:
                        message.auth[i] = 32;
                        break;
                    case "AUTH_ROLE_PUBLIC":
                    case 64:
                        message.auth[i] = 64;
                        break;
                    }
            }
            if (object.path != null)
                message.path = String(object.path);
            if (object.qos != null)
                message.qos = object.qos | 0;
            if (object.cache != null) {
                if (typeof object.cache !== "object")
                    throw TypeError(".api.ServiceOptions.cache: object expected");
                message.cache = $root.api.MessageCache.fromObject(object.cache);
            }
            return message;
        };

        /**
         * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ServiceOptions
         * @static
         * @param {api.ServiceOptions} message ServiceOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.authors = [];
                object.tags = [];
                object.auth = [];
            }
            if (message.summary != null && message.hasOwnProperty("summary")) {
                object.summary = message.summary;
                if (options.oneofs)
                    object._summary = "summary";
            }
            if (message.authors && message.authors.length) {
                object.authors = [];
                for (let j = 0; j < message.authors.length; ++j)
                    object.authors[j] = message.authors[j];
            }
            if (message.inheritAuthors != null && message.hasOwnProperty("inheritAuthors")) {
                object.inheritAuthors = message.inheritAuthors;
                if (options.oneofs)
                    object._inheritAuthors = "inheritAuthors";
            }
            if (message.date != null && message.hasOwnProperty("date")) {
                object.date = message.date;
                if (options.oneofs)
                    object._date = "date";
            }
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (let j = 0; j < message.tags.length; ++j)
                    object.tags[j] = message.tags[j];
            }
            if (message.auth && message.auth.length) {
                object.auth = [];
                for (let j = 0; j < message.auth.length; ++j)
                    object.auth[j] = options.enums === String ? $root.api.AuthenticationRole[message.auth[j]] === undefined ? message.auth[j] : $root.api.AuthenticationRole[message.auth[j]] : message.auth[j];
            }
            if (message.path != null && message.hasOwnProperty("path")) {
                object.path = message.path;
                if (options.oneofs)
                    object._path = "path";
            }
            if (message.qos != null && message.hasOwnProperty("qos")) {
                object.qos = message.qos;
                if (options.oneofs)
                    object._qos = "qos";
            }
            if (message.cache != null && message.hasOwnProperty("cache")) {
                object.cache = $root.api.MessageCache.toObject(message.cache, options);
                if (options.oneofs)
                    object._cache = "cache";
            }
            return object;
        };

        /**
         * Converts this ServiceOptions to JSON.
         * @function toJSON
         * @memberof api.ServiceOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ServiceOptions
         * @function getTypeUrl
         * @memberof api.ServiceOptions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ServiceOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.ServiceOptions";
        };

        return ServiceOptions;
    })();

    api.MethodOptions = (function() {

        /**
         * Properties of a MethodOptions.
         * @memberof api
         * @interface IMethodOptions
         * @property {number|null} [id] MethodOptions id
         * @property {string|null} [summary] MethodOptions summary
         * @property {boolean|null} [ready] MethodOptions ready
         * @property {Array.<string>|null} [authors] MethodOptions authors
         * @property {boolean|null} [inheritAuthors] MethodOptions inheritAuthors
         * @property {string|null} [date] MethodOptions date
         * @property {Array.<string>|null} [tags] MethodOptions tags
         * @property {Array.<api.AuthenticationRole>|null} [auth] MethodOptions auth
         * @property {string|null} [path] MethodOptions path
         * @property {number|null} [qos] MethodOptions qos
         * @property {api.IMessageCache|null} [cache] MethodOptions cache
         * @property {boolean|null} [readonly] MethodOptions readonly
         * @property {api.IPayload|null} ["in"] MethodOptions in
         * @property {api.IPayload|null} [out] MethodOptions out
         * @property {Array.<api.IExternalDocumentation>|null} [docs] MethodOptions docs
         */

        /**
         * Constructs a new MethodOptions.
         * @memberof api
         * @classdesc Represents a MethodOptions.
         * @implements IMethodOptions
         * @constructor
         * @param {api.IMethodOptions=} [properties] Properties to set
         */
        function MethodOptions(properties) {
            this.authors = [];
            this.tags = [];
            this.auth = [];
            this.docs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MethodOptions id.
         * @member {number} id
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.id = 0;

        /**
         * MethodOptions summary.
         * @member {string|null|undefined} summary
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.summary = null;

        /**
         * MethodOptions ready.
         * @member {boolean|null|undefined} ready
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.ready = null;

        /**
         * MethodOptions authors.
         * @member {Array.<string>} authors
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.authors = $util.emptyArray;

        /**
         * MethodOptions inheritAuthors.
         * @member {boolean|null|undefined} inheritAuthors
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.inheritAuthors = null;

        /**
         * MethodOptions date.
         * @member {string|null|undefined} date
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.date = null;

        /**
         * MethodOptions tags.
         * @member {Array.<string>} tags
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.tags = $util.emptyArray;

        /**
         * MethodOptions auth.
         * @member {Array.<api.AuthenticationRole>} auth
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.auth = $util.emptyArray;

        /**
         * MethodOptions path.
         * @member {string|null|undefined} path
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.path = null;

        /**
         * MethodOptions qos.
         * @member {number|null|undefined} qos
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.qos = null;

        /**
         * MethodOptions cache.
         * @member {api.IMessageCache|null|undefined} cache
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.cache = null;

        /**
         * MethodOptions readonly.
         * @member {boolean|null|undefined} readonly
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.readonly = null;

        /**
         * MethodOptions in.
         * @member {api.IPayload|null|undefined} in
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype["in"] = null;

        /**
         * MethodOptions out.
         * @member {api.IPayload|null|undefined} out
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.out = null;

        /**
         * MethodOptions docs.
         * @member {Array.<api.IExternalDocumentation>} docs
         * @memberof api.MethodOptions
         * @instance
         */
        MethodOptions.prototype.docs = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MethodOptions _summary.
         * @member {"summary"|undefined} _summary
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_summary", {
            get: $util.oneOfGetter($oneOfFields = ["summary"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _ready.
         * @member {"ready"|undefined} _ready
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_ready", {
            get: $util.oneOfGetter($oneOfFields = ["ready"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _inheritAuthors.
         * @member {"inheritAuthors"|undefined} _inheritAuthors
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_inheritAuthors", {
            get: $util.oneOfGetter($oneOfFields = ["inheritAuthors"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _date.
         * @member {"date"|undefined} _date
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_date", {
            get: $util.oneOfGetter($oneOfFields = ["date"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _path.
         * @member {"path"|undefined} _path
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_path", {
            get: $util.oneOfGetter($oneOfFields = ["path"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _qos.
         * @member {"qos"|undefined} _qos
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_qos", {
            get: $util.oneOfGetter($oneOfFields = ["qos"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _cache.
         * @member {"cache"|undefined} _cache
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_cache", {
            get: $util.oneOfGetter($oneOfFields = ["cache"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _readonly.
         * @member {"readonly"|undefined} _readonly
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_readonly", {
            get: $util.oneOfGetter($oneOfFields = ["readonly"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _in.
         * @member {"in"|undefined} _in
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_in", {
            get: $util.oneOfGetter($oneOfFields = ["in"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * MethodOptions _out.
         * @member {"out"|undefined} _out
         * @memberof api.MethodOptions
         * @instance
         */
        Object.defineProperty(MethodOptions.prototype, "_out", {
            get: $util.oneOfGetter($oneOfFields = ["out"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MethodOptions instance using the specified properties.
         * @function create
         * @memberof api.MethodOptions
         * @static
         * @param {api.IMethodOptions=} [properties] Properties to set
         * @returns {api.MethodOptions} MethodOptions instance
         */
        MethodOptions.create = function create(properties) {
            return new MethodOptions(properties);
        };

        /**
         * Encodes the specified MethodOptions message. Does not implicitly {@link api.MethodOptions.verify|verify} messages.
         * @function encode
         * @memberof api.MethodOptions
         * @static
         * @param {api.IMethodOptions} message MethodOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MethodOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
            if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.summary);
            if (message.ready != null && Object.hasOwnProperty.call(message, "ready"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.ready);
            if (message.readonly != null && Object.hasOwnProperty.call(message, "readonly"))
                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.readonly);
            if (message["in"] != null && Object.hasOwnProperty.call(message, "in"))
                $root.api.Payload.encode(message["in"], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.out != null && Object.hasOwnProperty.call(message, "out"))
                $root.api.Payload.encode(message.out, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.docs != null && message.docs.length)
                for (let i = 0; i < message.docs.length; ++i)
                    $root.api.ExternalDocumentation.encode(message.docs[i], writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.authors != null && message.authors.length)
                for (let i = 0; i < message.authors.length; ++i)
                    writer.uint32(/* id 100, wireType 2 =*/802).string(message.authors[i]);
            if (message.inheritAuthors != null && Object.hasOwnProperty.call(message, "inheritAuthors"))
                writer.uint32(/* id 101, wireType 0 =*/808).bool(message.inheritAuthors);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 102, wireType 2 =*/818).string(message.date);
            if (message.tags != null && message.tags.length)
                for (let i = 0; i < message.tags.length; ++i)
                    writer.uint32(/* id 103, wireType 2 =*/826).string(message.tags[i]);
            if (message.auth != null && message.auth.length) {
                writer.uint32(/* id 201, wireType 2 =*/1610).fork();
                for (let i = 0; i < message.auth.length; ++i)
                    writer.int32(message.auth[i]);
                writer.ldelim();
            }
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 202, wireType 2 =*/1618).string(message.path);
            if (message.qos != null && Object.hasOwnProperty.call(message, "qos"))
                writer.uint32(/* id 203, wireType 0 =*/1624).int32(message.qos);
            if (message.cache != null && Object.hasOwnProperty.call(message, "cache"))
                $root.api.MessageCache.encode(message.cache, writer.uint32(/* id 204, wireType 2 =*/1634).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link api.MethodOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MethodOptions
         * @static
         * @param {api.IMethodOptions} message MethodOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MethodOptions message from the specified reader or buffer.
         * @function decode
         * @memberof api.MethodOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MethodOptions} MethodOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MethodOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MethodOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint32();
                        break;
                    }
                case 2: {
                        message.summary = reader.string();
                        break;
                    }
                case 3: {
                        message.ready = reader.bool();
                        break;
                    }
                case 100: {
                        if (!(message.authors && message.authors.length))
                            message.authors = [];
                        message.authors.push(reader.string());
                        break;
                    }
                case 101: {
                        message.inheritAuthors = reader.bool();
                        break;
                    }
                case 102: {
                        message.date = reader.string();
                        break;
                    }
                case 103: {
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    }
                case 201: {
                        if (!(message.auth && message.auth.length))
                            message.auth = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.auth.push(reader.int32());
                        } else
                            message.auth.push(reader.int32());
                        break;
                    }
                case 202: {
                        message.path = reader.string();
                        break;
                    }
                case 203: {
                        message.qos = reader.int32();
                        break;
                    }
                case 204: {
                        message.cache = $root.api.MessageCache.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.readonly = reader.bool();
                        break;
                    }
                case 21: {
                        message["in"] = $root.api.Payload.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.out = $root.api.Payload.decode(reader, reader.uint32());
                        break;
                    }
                case 50: {
                        if (!(message.docs && message.docs.length))
                            message.docs = [];
                        message.docs.push($root.api.ExternalDocumentation.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MethodOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MethodOptions} MethodOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MethodOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MethodOptions message.
         * @function verify
         * @memberof api.MethodOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MethodOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id))
                    return "id: integer expected";
            if (message.summary != null && message.hasOwnProperty("summary")) {
                properties._summary = 1;
                if (!$util.isString(message.summary))
                    return "summary: string expected";
            }
            if (message.ready != null && message.hasOwnProperty("ready")) {
                properties._ready = 1;
                if (typeof message.ready !== "boolean")
                    return "ready: boolean expected";
            }
            if (message.authors != null && message.hasOwnProperty("authors")) {
                if (!Array.isArray(message.authors))
                    return "authors: array expected";
                for (let i = 0; i < message.authors.length; ++i)
                    if (!$util.isString(message.authors[i]))
                        return "authors: string[] expected";
            }
            if (message.inheritAuthors != null && message.hasOwnProperty("inheritAuthors")) {
                properties._inheritAuthors = 1;
                if (typeof message.inheritAuthors !== "boolean")
                    return "inheritAuthors: boolean expected";
            }
            if (message.date != null && message.hasOwnProperty("date")) {
                properties._date = 1;
                if (!$util.isString(message.date))
                    return "date: string expected";
            }
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (let i = 0; i < message.tags.length; ++i)
                    if (!$util.isString(message.tags[i]))
                        return "tags: string[] expected";
            }
            if (message.auth != null && message.hasOwnProperty("auth")) {
                if (!Array.isArray(message.auth))
                    return "auth: array expected";
                for (let i = 0; i < message.auth.length; ++i)
                    switch (message.auth[i]) {
                    default:
                        return "auth: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                    case 8:
                    case 16:
                    case 32:
                    case 64:
                        break;
                    }
            }
            if (message.path != null && message.hasOwnProperty("path")) {
                properties._path = 1;
                if (!$util.isString(message.path))
                    return "path: string expected";
            }
            if (message.qos != null && message.hasOwnProperty("qos")) {
                properties._qos = 1;
                if (!$util.isInteger(message.qos))
                    return "qos: integer expected";
            }
            if (message.cache != null && message.hasOwnProperty("cache")) {
                properties._cache = 1;
                {
                    let error = $root.api.MessageCache.verify(message.cache);
                    if (error)
                        return "cache." + error;
                }
            }
            if (message.readonly != null && message.hasOwnProperty("readonly")) {
                properties._readonly = 1;
                if (typeof message.readonly !== "boolean")
                    return "readonly: boolean expected";
            }
            if (message["in"] != null && message.hasOwnProperty("in")) {
                properties._in = 1;
                {
                    let error = $root.api.Payload.verify(message["in"]);
                    if (error)
                        return "in." + error;
                }
            }
            if (message.out != null && message.hasOwnProperty("out")) {
                properties._out = 1;
                {
                    let error = $root.api.Payload.verify(message.out);
                    if (error)
                        return "out." + error;
                }
            }
            if (message.docs != null && message.hasOwnProperty("docs")) {
                if (!Array.isArray(message.docs))
                    return "docs: array expected";
                for (let i = 0; i < message.docs.length; ++i) {
                    let error = $root.api.ExternalDocumentation.verify(message.docs[i]);
                    if (error)
                        return "docs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MethodOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MethodOptions} MethodOptions
         */
        MethodOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MethodOptions)
                return object;
            let message = new $root.api.MethodOptions();
            if (object.id != null)
                message.id = object.id >>> 0;
            if (object.summary != null)
                message.summary = String(object.summary);
            if (object.ready != null)
                message.ready = Boolean(object.ready);
            if (object.authors) {
                if (!Array.isArray(object.authors))
                    throw TypeError(".api.MethodOptions.authors: array expected");
                message.authors = [];
                for (let i = 0; i < object.authors.length; ++i)
                    message.authors[i] = String(object.authors[i]);
            }
            if (object.inheritAuthors != null)
                message.inheritAuthors = Boolean(object.inheritAuthors);
            if (object.date != null)
                message.date = String(object.date);
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".api.MethodOptions.tags: array expected");
                message.tags = [];
                for (let i = 0; i < object.tags.length; ++i)
                    message.tags[i] = String(object.tags[i]);
            }
            if (object.auth) {
                if (!Array.isArray(object.auth))
                    throw TypeError(".api.MethodOptions.auth: array expected");
                message.auth = [];
                for (let i = 0; i < object.auth.length; ++i)
                    switch (object.auth[i]) {
                    default:
                        if (typeof object.auth[i] === "number") {
                            message.auth[i] = object.auth[i];
                            break;
                        }
                    case "AUTH_ROLE_NONE":
                    case 0:
                        message.auth[i] = 0;
                        break;
                    case "AUTH_ROLE_ACCOUNT":
                    case 1:
                        message.auth[i] = 1;
                        break;
                    case "AUTH_ROLE_DEVICE":
                    case 2:
                        message.auth[i] = 2;
                        break;
                    case "AUTH_ROLE_SERVICE":
                    case 4:
                        message.auth[i] = 4;
                        break;
                    case "AUTH_ROLE_EXTERNAL":
                    case 8:
                        message.auth[i] = 8;
                        break;
                    case "AUTH_ROLE_TENANT":
                    case 16:
                        message.auth[i] = 16;
                        break;
                    case "AUTH_ROLE_ADMIN":
                    case 32:
                        message.auth[i] = 32;
                        break;
                    case "AUTH_ROLE_PUBLIC":
                    case 64:
                        message.auth[i] = 64;
                        break;
                    }
            }
            if (object.path != null)
                message.path = String(object.path);
            if (object.qos != null)
                message.qos = object.qos | 0;
            if (object.cache != null) {
                if (typeof object.cache !== "object")
                    throw TypeError(".api.MethodOptions.cache: object expected");
                message.cache = $root.api.MessageCache.fromObject(object.cache);
            }
            if (object.readonly != null)
                message.readonly = Boolean(object.readonly);
            if (object["in"] != null) {
                if (typeof object["in"] !== "object")
                    throw TypeError(".api.MethodOptions.in: object expected");
                message["in"] = $root.api.Payload.fromObject(object["in"]);
            }
            if (object.out != null) {
                if (typeof object.out !== "object")
                    throw TypeError(".api.MethodOptions.out: object expected");
                message.out = $root.api.Payload.fromObject(object.out);
            }
            if (object.docs) {
                if (!Array.isArray(object.docs))
                    throw TypeError(".api.MethodOptions.docs: array expected");
                message.docs = [];
                for (let i = 0; i < object.docs.length; ++i) {
                    if (typeof object.docs[i] !== "object")
                        throw TypeError(".api.MethodOptions.docs: object expected");
                    message.docs[i] = $root.api.ExternalDocumentation.fromObject(object.docs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MethodOptions
         * @static
         * @param {api.MethodOptions} message MethodOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MethodOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.docs = [];
                object.authors = [];
                object.tags = [];
                object.auth = [];
            }
            if (options.defaults)
                object.id = 0;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.summary != null && message.hasOwnProperty("summary")) {
                object.summary = message.summary;
                if (options.oneofs)
                    object._summary = "summary";
            }
            if (message.ready != null && message.hasOwnProperty("ready")) {
                object.ready = message.ready;
                if (options.oneofs)
                    object._ready = "ready";
            }
            if (message.readonly != null && message.hasOwnProperty("readonly")) {
                object.readonly = message.readonly;
                if (options.oneofs)
                    object._readonly = "readonly";
            }
            if (message["in"] != null && message.hasOwnProperty("in")) {
                object["in"] = $root.api.Payload.toObject(message["in"], options);
                if (options.oneofs)
                    object._in = "in";
            }
            if (message.out != null && message.hasOwnProperty("out")) {
                object.out = $root.api.Payload.toObject(message.out, options);
                if (options.oneofs)
                    object._out = "out";
            }
            if (message.docs && message.docs.length) {
                object.docs = [];
                for (let j = 0; j < message.docs.length; ++j)
                    object.docs[j] = $root.api.ExternalDocumentation.toObject(message.docs[j], options);
            }
            if (message.authors && message.authors.length) {
                object.authors = [];
                for (let j = 0; j < message.authors.length; ++j)
                    object.authors[j] = message.authors[j];
            }
            if (message.inheritAuthors != null && message.hasOwnProperty("inheritAuthors")) {
                object.inheritAuthors = message.inheritAuthors;
                if (options.oneofs)
                    object._inheritAuthors = "inheritAuthors";
            }
            if (message.date != null && message.hasOwnProperty("date")) {
                object.date = message.date;
                if (options.oneofs)
                    object._date = "date";
            }
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (let j = 0; j < message.tags.length; ++j)
                    object.tags[j] = message.tags[j];
            }
            if (message.auth && message.auth.length) {
                object.auth = [];
                for (let j = 0; j < message.auth.length; ++j)
                    object.auth[j] = options.enums === String ? $root.api.AuthenticationRole[message.auth[j]] === undefined ? message.auth[j] : $root.api.AuthenticationRole[message.auth[j]] : message.auth[j];
            }
            if (message.path != null && message.hasOwnProperty("path")) {
                object.path = message.path;
                if (options.oneofs)
                    object._path = "path";
            }
            if (message.qos != null && message.hasOwnProperty("qos")) {
                object.qos = message.qos;
                if (options.oneofs)
                    object._qos = "qos";
            }
            if (message.cache != null && message.hasOwnProperty("cache")) {
                object.cache = $root.api.MessageCache.toObject(message.cache, options);
                if (options.oneofs)
                    object._cache = "cache";
            }
            return object;
        };

        /**
         * Converts this MethodOptions to JSON.
         * @function toJSON
         * @memberof api.MethodOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MethodOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MethodOptions
         * @function getTypeUrl
         * @memberof api.MethodOptions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MethodOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MethodOptions";
        };

        return MethodOptions;
    })();

    api.PubSubOptions = (function() {

        /**
         * Properties of a PubSubOptions.
         * @memberof api
         * @interface IPubSubOptions
         * @property {string|null} [name] PubSubOptions name
         * @property {string|null} [topic] PubSubOptions topic
         * @property {string|null} [route] PubSubOptions route
         * @property {boolean|null} [retry] PubSubOptions retry
         * @property {string|null} [contentType] PubSubOptions contentType
         * @property {string|null} [match] PubSubOptions match
         * @property {number|null} [priority] PubSubOptions priority
         * @property {boolean|null} [disableTopicValidation] PubSubOptions disableTopicValidation
         */

        /**
         * Constructs a new PubSubOptions.
         * @memberof api
         * @classdesc Represents a PubSubOptions.
         * @implements IPubSubOptions
         * @constructor
         * @param {api.IPubSubOptions=} [properties] Properties to set
         */
        function PubSubOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PubSubOptions name.
         * @member {string} name
         * @memberof api.PubSubOptions
         * @instance
         */
        PubSubOptions.prototype.name = "";

        /**
         * PubSubOptions topic.
         * @member {string} topic
         * @memberof api.PubSubOptions
         * @instance
         */
        PubSubOptions.prototype.topic = "";

        /**
         * PubSubOptions route.
         * @member {string} route
         * @memberof api.PubSubOptions
         * @instance
         */
        PubSubOptions.prototype.route = "";

        /**
         * PubSubOptions retry.
         * @member {boolean} retry
         * @memberof api.PubSubOptions
         * @instance
         */
        PubSubOptions.prototype.retry = false;

        /**
         * PubSubOptions contentType.
         * @member {string} contentType
         * @memberof api.PubSubOptions
         * @instance
         */
        PubSubOptions.prototype.contentType = "";

        /**
         * PubSubOptions match.
         * @member {string} match
         * @memberof api.PubSubOptions
         * @instance
         */
        PubSubOptions.prototype.match = "";

        /**
         * PubSubOptions priority.
         * @member {number} priority
         * @memberof api.PubSubOptions
         * @instance
         */
        PubSubOptions.prototype.priority = 0;

        /**
         * PubSubOptions disableTopicValidation.
         * @member {boolean} disableTopicValidation
         * @memberof api.PubSubOptions
         * @instance
         */
        PubSubOptions.prototype.disableTopicValidation = false;

        /**
         * Creates a new PubSubOptions instance using the specified properties.
         * @function create
         * @memberof api.PubSubOptions
         * @static
         * @param {api.IPubSubOptions=} [properties] Properties to set
         * @returns {api.PubSubOptions} PubSubOptions instance
         */
        PubSubOptions.create = function create(properties) {
            return new PubSubOptions(properties);
        };

        /**
         * Encodes the specified PubSubOptions message. Does not implicitly {@link api.PubSubOptions.verify|verify} messages.
         * @function encode
         * @memberof api.PubSubOptions
         * @static
         * @param {api.IPubSubOptions} message PubSubOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PubSubOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.topic);
            if (message.route != null && Object.hasOwnProperty.call(message, "route"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.route);
            if (message.retry != null && Object.hasOwnProperty.call(message, "retry"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.retry);
            if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.contentType);
            if (message.match != null && Object.hasOwnProperty.call(message, "match"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.match);
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.priority);
            if (message.disableTopicValidation != null && Object.hasOwnProperty.call(message, "disableTopicValidation"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.disableTopicValidation);
            return writer;
        };

        /**
         * Encodes the specified PubSubOptions message, length delimited. Does not implicitly {@link api.PubSubOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PubSubOptions
         * @static
         * @param {api.IPubSubOptions} message PubSubOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PubSubOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PubSubOptions message from the specified reader or buffer.
         * @function decode
         * @memberof api.PubSubOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PubSubOptions} PubSubOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PubSubOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PubSubOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.topic = reader.string();
                        break;
                    }
                case 3: {
                        message.route = reader.string();
                        break;
                    }
                case 4: {
                        message.retry = reader.bool();
                        break;
                    }
                case 5: {
                        message.contentType = reader.string();
                        break;
                    }
                case 6: {
                        message.match = reader.string();
                        break;
                    }
                case 7: {
                        message.priority = reader.int32();
                        break;
                    }
                case 8: {
                        message.disableTopicValidation = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PubSubOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PubSubOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PubSubOptions} PubSubOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PubSubOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PubSubOptions message.
         * @function verify
         * @memberof api.PubSubOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PubSubOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.topic != null && message.hasOwnProperty("topic"))
                if (!$util.isString(message.topic))
                    return "topic: string expected";
            if (message.route != null && message.hasOwnProperty("route"))
                if (!$util.isString(message.route))
                    return "route: string expected";
            if (message.retry != null && message.hasOwnProperty("retry"))
                if (typeof message.retry !== "boolean")
                    return "retry: boolean expected";
            if (message.contentType != null && message.hasOwnProperty("contentType"))
                if (!$util.isString(message.contentType))
                    return "contentType: string expected";
            if (message.match != null && message.hasOwnProperty("match"))
                if (!$util.isString(message.match))
                    return "match: string expected";
            if (message.priority != null && message.hasOwnProperty("priority"))
                if (!$util.isInteger(message.priority))
                    return "priority: integer expected";
            if (message.disableTopicValidation != null && message.hasOwnProperty("disableTopicValidation"))
                if (typeof message.disableTopicValidation !== "boolean")
                    return "disableTopicValidation: boolean expected";
            return null;
        };

        /**
         * Creates a PubSubOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PubSubOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PubSubOptions} PubSubOptions
         */
        PubSubOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PubSubOptions)
                return object;
            let message = new $root.api.PubSubOptions();
            if (object.name != null)
                message.name = String(object.name);
            if (object.topic != null)
                message.topic = String(object.topic);
            if (object.route != null)
                message.route = String(object.route);
            if (object.retry != null)
                message.retry = Boolean(object.retry);
            if (object.contentType != null)
                message.contentType = String(object.contentType);
            if (object.match != null)
                message.match = String(object.match);
            if (object.priority != null)
                message.priority = object.priority | 0;
            if (object.disableTopicValidation != null)
                message.disableTopicValidation = Boolean(object.disableTopicValidation);
            return message;
        };

        /**
         * Creates a plain object from a PubSubOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PubSubOptions
         * @static
         * @param {api.PubSubOptions} message PubSubOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PubSubOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.topic = "";
                object.route = "";
                object.retry = false;
                object.contentType = "";
                object.match = "";
                object.priority = 0;
                object.disableTopicValidation = false;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.topic != null && message.hasOwnProperty("topic"))
                object.topic = message.topic;
            if (message.route != null && message.hasOwnProperty("route"))
                object.route = message.route;
            if (message.retry != null && message.hasOwnProperty("retry"))
                object.retry = message.retry;
            if (message.contentType != null && message.hasOwnProperty("contentType"))
                object.contentType = message.contentType;
            if (message.match != null && message.hasOwnProperty("match"))
                object.match = message.match;
            if (message.priority != null && message.hasOwnProperty("priority"))
                object.priority = message.priority;
            if (message.disableTopicValidation != null && message.hasOwnProperty("disableTopicValidation"))
                object.disableTopicValidation = message.disableTopicValidation;
            return object;
        };

        /**
         * Converts this PubSubOptions to JSON.
         * @function toJSON
         * @memberof api.PubSubOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PubSubOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PubSubOptions
         * @function getTypeUrl
         * @memberof api.PubSubOptions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PubSubOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.PubSubOptions";
        };

        return PubSubOptions;
    })();

    api.MessageOptions = (function() {

        /**
         * Properties of a MessageOptions.
         * @memberof api
         * @interface IMessageOptions
         * @property {string|null} [summary] MessageOptions summary
         */

        /**
         * Constructs a new MessageOptions.
         * @memberof api
         * @classdesc Represents a MessageOptions.
         * @implements IMessageOptions
         * @constructor
         * @param {api.IMessageOptions=} [properties] Properties to set
         */
        function MessageOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageOptions summary.
         * @member {string|null|undefined} summary
         * @memberof api.MessageOptions
         * @instance
         */
        MessageOptions.prototype.summary = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MessageOptions _summary.
         * @member {"summary"|undefined} _summary
         * @memberof api.MessageOptions
         * @instance
         */
        Object.defineProperty(MessageOptions.prototype, "_summary", {
            get: $util.oneOfGetter($oneOfFields = ["summary"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MessageOptions instance using the specified properties.
         * @function create
         * @memberof api.MessageOptions
         * @static
         * @param {api.IMessageOptions=} [properties] Properties to set
         * @returns {api.MessageOptions} MessageOptions instance
         */
        MessageOptions.create = function create(properties) {
            return new MessageOptions(properties);
        };

        /**
         * Encodes the specified MessageOptions message. Does not implicitly {@link api.MessageOptions.verify|verify} messages.
         * @function encode
         * @memberof api.MessageOptions
         * @static
         * @param {api.IMessageOptions} message MessageOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.summary);
            return writer;
        };

        /**
         * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link api.MessageOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MessageOptions
         * @static
         * @param {api.IMessageOptions} message MessageOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MessageOptions message from the specified reader or buffer.
         * @function decode
         * @memberof api.MessageOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MessageOptions} MessageOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MessageOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.summary = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MessageOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MessageOptions} MessageOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MessageOptions message.
         * @function verify
         * @memberof api.MessageOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MessageOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.summary != null && message.hasOwnProperty("summary")) {
                properties._summary = 1;
                if (!$util.isString(message.summary))
                    return "summary: string expected";
            }
            return null;
        };

        /**
         * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MessageOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MessageOptions} MessageOptions
         */
        MessageOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MessageOptions)
                return object;
            let message = new $root.api.MessageOptions();
            if (object.summary != null)
                message.summary = String(object.summary);
            return message;
        };

        /**
         * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MessageOptions
         * @static
         * @param {api.MessageOptions} message MessageOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MessageOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.summary != null && message.hasOwnProperty("summary")) {
                object.summary = message.summary;
                if (options.oneofs)
                    object._summary = "summary";
            }
            return object;
        };

        /**
         * Converts this MessageOptions to JSON.
         * @function toJSON
         * @memberof api.MessageOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MessageOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MessageOptions
         * @function getTypeUrl
         * @memberof api.MessageOptions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.MessageOptions";
        };

        return MessageOptions;
    })();

    api.FieldOptions = (function() {

        /**
         * Properties of a FieldOptions.
         * @memberof api
         * @interface IFieldOptions
         * @property {string|null} [summary] FieldOptions summary
         * @property {boolean|null} ["in"] FieldOptions in
         * @property {boolean|null} [out] FieldOptions out
         * @property {boolean|null} [required] FieldOptions required
         * @property {string|null} [example] FieldOptions example
         * @property {Long|null} [minimum] FieldOptions minimum
         * @property {boolean|null} [exclusiveMinimum] FieldOptions exclusiveMinimum
         * @property {Long|null} [maximum] FieldOptions maximum
         * @property {boolean|null} [exclusiveMaximum] FieldOptions exclusiveMaximum
         * @property {Long|null} [multipleOf] FieldOptions multipleOf
         * @property {number|null} [maxLength] FieldOptions maxLength
         * @property {number|null} [minLength] FieldOptions minLength
         * @property {string|null} [pattern] FieldOptions pattern
         * @property {number|null} [maxItems] FieldOptions maxItems
         * @property {number|null} [minItems] FieldOptions minItems
         * @property {boolean|null} [uniqueItems] FieldOptions uniqueItems
         */

        /**
         * Constructs a new FieldOptions.
         * @memberof api
         * @classdesc Represents a FieldOptions.
         * @implements IFieldOptions
         * @constructor
         * @param {api.IFieldOptions=} [properties] Properties to set
         */
        function FieldOptions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FieldOptions summary.
         * @member {string|null|undefined} summary
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.summary = null;

        /**
         * FieldOptions in.
         * @member {boolean|null|undefined} in
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype["in"] = null;

        /**
         * FieldOptions out.
         * @member {boolean|null|undefined} out
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.out = null;

        /**
         * FieldOptions required.
         * @member {boolean|null|undefined} required
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.required = null;

        /**
         * FieldOptions example.
         * @member {string|null|undefined} example
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.example = null;

        /**
         * FieldOptions minimum.
         * @member {Long|null|undefined} minimum
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.minimum = null;

        /**
         * FieldOptions exclusiveMinimum.
         * @member {boolean|null|undefined} exclusiveMinimum
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.exclusiveMinimum = null;

        /**
         * FieldOptions maximum.
         * @member {Long|null|undefined} maximum
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.maximum = null;

        /**
         * FieldOptions exclusiveMaximum.
         * @member {boolean|null|undefined} exclusiveMaximum
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.exclusiveMaximum = null;

        /**
         * FieldOptions multipleOf.
         * @member {Long|null|undefined} multipleOf
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.multipleOf = null;

        /**
         * FieldOptions maxLength.
         * @member {number|null|undefined} maxLength
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.maxLength = null;

        /**
         * FieldOptions minLength.
         * @member {number|null|undefined} minLength
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.minLength = null;

        /**
         * FieldOptions pattern.
         * @member {string|null|undefined} pattern
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.pattern = null;

        /**
         * FieldOptions maxItems.
         * @member {number|null|undefined} maxItems
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.maxItems = null;

        /**
         * FieldOptions minItems.
         * @member {number|null|undefined} minItems
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.minItems = null;

        /**
         * FieldOptions uniqueItems.
         * @member {boolean|null|undefined} uniqueItems
         * @memberof api.FieldOptions
         * @instance
         */
        FieldOptions.prototype.uniqueItems = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * FieldOptions _summary.
         * @member {"summary"|undefined} _summary
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_summary", {
            get: $util.oneOfGetter($oneOfFields = ["summary"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _in.
         * @member {"in"|undefined} _in
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_in", {
            get: $util.oneOfGetter($oneOfFields = ["in"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _out.
         * @member {"out"|undefined} _out
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_out", {
            get: $util.oneOfGetter($oneOfFields = ["out"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _required.
         * @member {"required"|undefined} _required
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_required", {
            get: $util.oneOfGetter($oneOfFields = ["required"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _example.
         * @member {"example"|undefined} _example
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_example", {
            get: $util.oneOfGetter($oneOfFields = ["example"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _minimum.
         * @member {"minimum"|undefined} _minimum
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_minimum", {
            get: $util.oneOfGetter($oneOfFields = ["minimum"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _exclusiveMinimum.
         * @member {"exclusiveMinimum"|undefined} _exclusiveMinimum
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_exclusiveMinimum", {
            get: $util.oneOfGetter($oneOfFields = ["exclusiveMinimum"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _maximum.
         * @member {"maximum"|undefined} _maximum
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_maximum", {
            get: $util.oneOfGetter($oneOfFields = ["maximum"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _exclusiveMaximum.
         * @member {"exclusiveMaximum"|undefined} _exclusiveMaximum
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_exclusiveMaximum", {
            get: $util.oneOfGetter($oneOfFields = ["exclusiveMaximum"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _multipleOf.
         * @member {"multipleOf"|undefined} _multipleOf
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_multipleOf", {
            get: $util.oneOfGetter($oneOfFields = ["multipleOf"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _maxLength.
         * @member {"maxLength"|undefined} _maxLength
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_maxLength", {
            get: $util.oneOfGetter($oneOfFields = ["maxLength"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _minLength.
         * @member {"minLength"|undefined} _minLength
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_minLength", {
            get: $util.oneOfGetter($oneOfFields = ["minLength"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _pattern.
         * @member {"pattern"|undefined} _pattern
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_pattern", {
            get: $util.oneOfGetter($oneOfFields = ["pattern"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _maxItems.
         * @member {"maxItems"|undefined} _maxItems
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_maxItems", {
            get: $util.oneOfGetter($oneOfFields = ["maxItems"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _minItems.
         * @member {"minItems"|undefined} _minItems
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_minItems", {
            get: $util.oneOfGetter($oneOfFields = ["minItems"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FieldOptions _uniqueItems.
         * @member {"uniqueItems"|undefined} _uniqueItems
         * @memberof api.FieldOptions
         * @instance
         */
        Object.defineProperty(FieldOptions.prototype, "_uniqueItems", {
            get: $util.oneOfGetter($oneOfFields = ["uniqueItems"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FieldOptions instance using the specified properties.
         * @function create
         * @memberof api.FieldOptions
         * @static
         * @param {api.IFieldOptions=} [properties] Properties to set
         * @returns {api.FieldOptions} FieldOptions instance
         */
        FieldOptions.create = function create(properties) {
            return new FieldOptions(properties);
        };

        /**
         * Encodes the specified FieldOptions message. Does not implicitly {@link api.FieldOptions.verify|verify} messages.
         * @function encode
         * @memberof api.FieldOptions
         * @static
         * @param {api.IFieldOptions} message FieldOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FieldOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.summary);
            if (message["in"] != null && Object.hasOwnProperty.call(message, "in"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message["in"]);
            if (message.out != null && Object.hasOwnProperty.call(message, "out"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.out);
            if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.required);
            if (message.example != null && Object.hasOwnProperty.call(message, "example"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.example);
            if (message.minimum != null && Object.hasOwnProperty.call(message, "minimum"))
                writer.uint32(/* id 10, wireType 0 =*/80).int64(message.minimum);
            if (message.exclusiveMinimum != null && Object.hasOwnProperty.call(message, "exclusiveMinimum"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.exclusiveMinimum);
            if (message.maximum != null && Object.hasOwnProperty.call(message, "maximum"))
                writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.maximum);
            if (message.exclusiveMaximum != null && Object.hasOwnProperty.call(message, "exclusiveMaximum"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.exclusiveMaximum);
            if (message.multipleOf != null && Object.hasOwnProperty.call(message, "multipleOf"))
                writer.uint32(/* id 14, wireType 0 =*/112).int64(message.multipleOf);
            if (message.maxLength != null && Object.hasOwnProperty.call(message, "maxLength"))
                writer.uint32(/* id 20, wireType 0 =*/160).uint32(message.maxLength);
            if (message.minLength != null && Object.hasOwnProperty.call(message, "minLength"))
                writer.uint32(/* id 21, wireType 0 =*/168).uint32(message.minLength);
            if (message.pattern != null && Object.hasOwnProperty.call(message, "pattern"))
                writer.uint32(/* id 30, wireType 2 =*/242).string(message.pattern);
            if (message.maxItems != null && Object.hasOwnProperty.call(message, "maxItems"))
                writer.uint32(/* id 40, wireType 0 =*/320).uint32(message.maxItems);
            if (message.minItems != null && Object.hasOwnProperty.call(message, "minItems"))
                writer.uint32(/* id 41, wireType 0 =*/328).uint32(message.minItems);
            if (message.uniqueItems != null && Object.hasOwnProperty.call(message, "uniqueItems"))
                writer.uint32(/* id 42, wireType 0 =*/336).bool(message.uniqueItems);
            return writer;
        };

        /**
         * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link api.FieldOptions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.FieldOptions
         * @static
         * @param {api.IFieldOptions} message FieldOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FieldOptions message from the specified reader or buffer.
         * @function decode
         * @memberof api.FieldOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.FieldOptions} FieldOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FieldOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.FieldOptions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.summary = reader.string();
                        break;
                    }
                case 2: {
                        message["in"] = reader.bool();
                        break;
                    }
                case 3: {
                        message.out = reader.bool();
                        break;
                    }
                case 5: {
                        message.required = reader.bool();
                        break;
                    }
                case 6: {
                        message.example = reader.string();
                        break;
                    }
                case 10: {
                        message.minimum = reader.int64();
                        break;
                    }
                case 11: {
                        message.exclusiveMinimum = reader.bool();
                        break;
                    }
                case 12: {
                        message.maximum = reader.uint64();
                        break;
                    }
                case 13: {
                        message.exclusiveMaximum = reader.bool();
                        break;
                    }
                case 14: {
                        message.multipleOf = reader.int64();
                        break;
                    }
                case 20: {
                        message.maxLength = reader.uint32();
                        break;
                    }
                case 21: {
                        message.minLength = reader.uint32();
                        break;
                    }
                case 30: {
                        message.pattern = reader.string();
                        break;
                    }
                case 40: {
                        message.maxItems = reader.uint32();
                        break;
                    }
                case 41: {
                        message.minItems = reader.uint32();
                        break;
                    }
                case 42: {
                        message.uniqueItems = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.FieldOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.FieldOptions} FieldOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FieldOptions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FieldOptions message.
         * @function verify
         * @memberof api.FieldOptions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FieldOptions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.summary != null && message.hasOwnProperty("summary")) {
                properties._summary = 1;
                if (!$util.isString(message.summary))
                    return "summary: string expected";
            }
            if (message["in"] != null && message.hasOwnProperty("in")) {
                properties._in = 1;
                if (typeof message["in"] !== "boolean")
                    return "in: boolean expected";
            }
            if (message.out != null && message.hasOwnProperty("out")) {
                properties._out = 1;
                if (typeof message.out !== "boolean")
                    return "out: boolean expected";
            }
            if (message.required != null && message.hasOwnProperty("required")) {
                properties._required = 1;
                if (typeof message.required !== "boolean")
                    return "required: boolean expected";
            }
            if (message.example != null && message.hasOwnProperty("example")) {
                properties._example = 1;
                if (!$util.isString(message.example))
                    return "example: string expected";
            }
            if (message.minimum != null && message.hasOwnProperty("minimum")) {
                properties._minimum = 1;
                if (!$util.isInteger(message.minimum) && !(message.minimum && $util.isInteger(message.minimum.low) && $util.isInteger(message.minimum.high)))
                    return "minimum: integer|Long expected";
            }
            if (message.exclusiveMinimum != null && message.hasOwnProperty("exclusiveMinimum")) {
                properties._exclusiveMinimum = 1;
                if (typeof message.exclusiveMinimum !== "boolean")
                    return "exclusiveMinimum: boolean expected";
            }
            if (message.maximum != null && message.hasOwnProperty("maximum")) {
                properties._maximum = 1;
                if (!$util.isInteger(message.maximum) && !(message.maximum && $util.isInteger(message.maximum.low) && $util.isInteger(message.maximum.high)))
                    return "maximum: integer|Long expected";
            }
            if (message.exclusiveMaximum != null && message.hasOwnProperty("exclusiveMaximum")) {
                properties._exclusiveMaximum = 1;
                if (typeof message.exclusiveMaximum !== "boolean")
                    return "exclusiveMaximum: boolean expected";
            }
            if (message.multipleOf != null && message.hasOwnProperty("multipleOf")) {
                properties._multipleOf = 1;
                if (!$util.isInteger(message.multipleOf) && !(message.multipleOf && $util.isInteger(message.multipleOf.low) && $util.isInteger(message.multipleOf.high)))
                    return "multipleOf: integer|Long expected";
            }
            if (message.maxLength != null && message.hasOwnProperty("maxLength")) {
                properties._maxLength = 1;
                if (!$util.isInteger(message.maxLength))
                    return "maxLength: integer expected";
            }
            if (message.minLength != null && message.hasOwnProperty("minLength")) {
                properties._minLength = 1;
                if (!$util.isInteger(message.minLength))
                    return "minLength: integer expected";
            }
            if (message.pattern != null && message.hasOwnProperty("pattern")) {
                properties._pattern = 1;
                if (!$util.isString(message.pattern))
                    return "pattern: string expected";
            }
            if (message.maxItems != null && message.hasOwnProperty("maxItems")) {
                properties._maxItems = 1;
                if (!$util.isInteger(message.maxItems))
                    return "maxItems: integer expected";
            }
            if (message.minItems != null && message.hasOwnProperty("minItems")) {
                properties._minItems = 1;
                if (!$util.isInteger(message.minItems))
                    return "minItems: integer expected";
            }
            if (message.uniqueItems != null && message.hasOwnProperty("uniqueItems")) {
                properties._uniqueItems = 1;
                if (typeof message.uniqueItems !== "boolean")
                    return "uniqueItems: boolean expected";
            }
            return null;
        };

        /**
         * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.FieldOptions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.FieldOptions} FieldOptions
         */
        FieldOptions.fromObject = function fromObject(object) {
            if (object instanceof $root.api.FieldOptions)
                return object;
            let message = new $root.api.FieldOptions();
            if (object.summary != null)
                message.summary = String(object.summary);
            if (object["in"] != null)
                message["in"] = Boolean(object["in"]);
            if (object.out != null)
                message.out = Boolean(object.out);
            if (object.required != null)
                message.required = Boolean(object.required);
            if (object.example != null)
                message.example = String(object.example);
            if (object.minimum != null)
                if ($util.Long)
                    (message.minimum = $util.Long.fromValue(object.minimum)).unsigned = false;
                else if (typeof object.minimum === "string")
                    message.minimum = parseInt(object.minimum, 10);
                else if (typeof object.minimum === "number")
                    message.minimum = object.minimum;
                else if (typeof object.minimum === "object")
                    message.minimum = new $util.LongBits(object.minimum.low >>> 0, object.minimum.high >>> 0).toNumber();
            if (object.exclusiveMinimum != null)
                message.exclusiveMinimum = Boolean(object.exclusiveMinimum);
            if (object.maximum != null)
                if ($util.Long)
                    (message.maximum = $util.Long.fromValue(object.maximum)).unsigned = true;
                else if (typeof object.maximum === "string")
                    message.maximum = parseInt(object.maximum, 10);
                else if (typeof object.maximum === "number")
                    message.maximum = object.maximum;
                else if (typeof object.maximum === "object")
                    message.maximum = new $util.LongBits(object.maximum.low >>> 0, object.maximum.high >>> 0).toNumber(true);
            if (object.exclusiveMaximum != null)
                message.exclusiveMaximum = Boolean(object.exclusiveMaximum);
            if (object.multipleOf != null)
                if ($util.Long)
                    (message.multipleOf = $util.Long.fromValue(object.multipleOf)).unsigned = false;
                else if (typeof object.multipleOf === "string")
                    message.multipleOf = parseInt(object.multipleOf, 10);
                else if (typeof object.multipleOf === "number")
                    message.multipleOf = object.multipleOf;
                else if (typeof object.multipleOf === "object")
                    message.multipleOf = new $util.LongBits(object.multipleOf.low >>> 0, object.multipleOf.high >>> 0).toNumber();
            if (object.maxLength != null)
                message.maxLength = object.maxLength >>> 0;
            if (object.minLength != null)
                message.minLength = object.minLength >>> 0;
            if (object.pattern != null)
                message.pattern = String(object.pattern);
            if (object.maxItems != null)
                message.maxItems = object.maxItems >>> 0;
            if (object.minItems != null)
                message.minItems = object.minItems >>> 0;
            if (object.uniqueItems != null)
                message.uniqueItems = Boolean(object.uniqueItems);
            return message;
        };

        /**
         * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.FieldOptions
         * @static
         * @param {api.FieldOptions} message FieldOptions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FieldOptions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.summary != null && message.hasOwnProperty("summary")) {
                object.summary = message.summary;
                if (options.oneofs)
                    object._summary = "summary";
            }
            if (message["in"] != null && message.hasOwnProperty("in")) {
                object["in"] = message["in"];
                if (options.oneofs)
                    object._in = "in";
            }
            if (message.out != null && message.hasOwnProperty("out")) {
                object.out = message.out;
                if (options.oneofs)
                    object._out = "out";
            }
            if (message.required != null && message.hasOwnProperty("required")) {
                object.required = message.required;
                if (options.oneofs)
                    object._required = "required";
            }
            if (message.example != null && message.hasOwnProperty("example")) {
                object.example = message.example;
                if (options.oneofs)
                    object._example = "example";
            }
            if (message.minimum != null && message.hasOwnProperty("minimum")) {
                if (typeof message.minimum === "number")
                    object.minimum = options.longs === String ? String(message.minimum) : message.minimum;
                else
                    object.minimum = options.longs === String ? $util.Long.prototype.toString.call(message.minimum) : options.longs === Number ? new $util.LongBits(message.minimum.low >>> 0, message.minimum.high >>> 0).toNumber() : message.minimum;
                if (options.oneofs)
                    object._minimum = "minimum";
            }
            if (message.exclusiveMinimum != null && message.hasOwnProperty("exclusiveMinimum")) {
                object.exclusiveMinimum = message.exclusiveMinimum;
                if (options.oneofs)
                    object._exclusiveMinimum = "exclusiveMinimum";
            }
            if (message.maximum != null && message.hasOwnProperty("maximum")) {
                if (typeof message.maximum === "number")
                    object.maximum = options.longs === String ? String(message.maximum) : message.maximum;
                else
                    object.maximum = options.longs === String ? $util.Long.prototype.toString.call(message.maximum) : options.longs === Number ? new $util.LongBits(message.maximum.low >>> 0, message.maximum.high >>> 0).toNumber(true) : message.maximum;
                if (options.oneofs)
                    object._maximum = "maximum";
            }
            if (message.exclusiveMaximum != null && message.hasOwnProperty("exclusiveMaximum")) {
                object.exclusiveMaximum = message.exclusiveMaximum;
                if (options.oneofs)
                    object._exclusiveMaximum = "exclusiveMaximum";
            }
            if (message.multipleOf != null && message.hasOwnProperty("multipleOf")) {
                if (typeof message.multipleOf === "number")
                    object.multipleOf = options.longs === String ? String(message.multipleOf) : message.multipleOf;
                else
                    object.multipleOf = options.longs === String ? $util.Long.prototype.toString.call(message.multipleOf) : options.longs === Number ? new $util.LongBits(message.multipleOf.low >>> 0, message.multipleOf.high >>> 0).toNumber() : message.multipleOf;
                if (options.oneofs)
                    object._multipleOf = "multipleOf";
            }
            if (message.maxLength != null && message.hasOwnProperty("maxLength")) {
                object.maxLength = message.maxLength;
                if (options.oneofs)
                    object._maxLength = "maxLength";
            }
            if (message.minLength != null && message.hasOwnProperty("minLength")) {
                object.minLength = message.minLength;
                if (options.oneofs)
                    object._minLength = "minLength";
            }
            if (message.pattern != null && message.hasOwnProperty("pattern")) {
                object.pattern = message.pattern;
                if (options.oneofs)
                    object._pattern = "pattern";
            }
            if (message.maxItems != null && message.hasOwnProperty("maxItems")) {
                object.maxItems = message.maxItems;
                if (options.oneofs)
                    object._maxItems = "maxItems";
            }
            if (message.minItems != null && message.hasOwnProperty("minItems")) {
                object.minItems = message.minItems;
                if (options.oneofs)
                    object._minItems = "minItems";
            }
            if (message.uniqueItems != null && message.hasOwnProperty("uniqueItems")) {
                object.uniqueItems = message.uniqueItems;
                if (options.oneofs)
                    object._uniqueItems = "uniqueItems";
            }
            return object;
        };

        /**
         * Converts this FieldOptions to JSON.
         * @function toJSON
         * @memberof api.FieldOptions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FieldOptions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FieldOptions
         * @function getTypeUrl
         * @memberof api.FieldOptions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FieldOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/api.FieldOptions";
        };

        return FieldOptions;
    })();

    api.asset = (function() {

        /**
         * Namespace asset.
         * @memberof api
         * @namespace
         */
        const asset = {};

        asset.Asset = (function() {

            /**
             * Constructs a new Asset service.
             * @memberof api.asset
             * @classdesc Represents an Asset
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Asset(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Asset.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Asset;

            /**
             * Creates new Asset service using the specified rpc implementation.
             * @function create
             * @memberof api.asset.Asset
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Asset} RPC service. Useful where requests and/or responses are streamed.
             */
            Asset.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.asset.Asset#placeholder}.
             * @memberof api.asset.Asset
             * @typedef PlaceholderCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Placeholder.
             * @function placeholder
             * @memberof api.asset.Asset
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.asset.Asset.PlaceholderCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Asset.prototype.placeholder = function placeholder(request, callback) {
                return this.rpcCall(placeholder, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Placeholder" });

            /**
             * Calls Placeholder.
             * @function placeholder
             * @memberof api.asset.Asset
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return Asset;
        })();

        return asset;
    })();

    api.core = (function() {

        /**
         * Namespace core.
         * @memberof api
         * @namespace
         */
        const core = {};

        /**
         * AutoReplyField enum.
         * @name api.core.AutoReplyField
         * @enum {number}
         * @property {number} AUTO_REPLY_FIELD_ALL=0 AUTO_REPLY_FIELD_ALL value
         * @property {number} AUTO_REPLY_FIELD_NAME=1 AUTO_REPLY_FIELD_NAME value
         */
        core.AutoReplyField = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "AUTO_REPLY_FIELD_ALL"] = 0;
            values[valuesById[1] = "AUTO_REPLY_FIELD_NAME"] = 1;
            return values;
        })();

        core.AutoReplyTenantResponse = (function() {

            /**
             * Properties of an AutoReplyTenantResponse.
             * @memberof api.core
             * @interface IAutoReplyTenantResponse
             * @property {Array.<api.common.IAutoReplyItem>|null} [items] AutoReplyTenantResponse items
             */

            /**
             * Constructs a new AutoReplyTenantResponse.
             * @memberof api.core
             * @classdesc Represents an AutoReplyTenantResponse.
             * @implements IAutoReplyTenantResponse
             * @constructor
             * @param {api.core.IAutoReplyTenantResponse=} [properties] Properties to set
             */
            function AutoReplyTenantResponse(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AutoReplyTenantResponse items.
             * @member {Array.<api.common.IAutoReplyItem>} items
             * @memberof api.core.AutoReplyTenantResponse
             * @instance
             */
            AutoReplyTenantResponse.prototype.items = $util.emptyArray;

            /**
             * Creates a new AutoReplyTenantResponse instance using the specified properties.
             * @function create
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {api.core.IAutoReplyTenantResponse=} [properties] Properties to set
             * @returns {api.core.AutoReplyTenantResponse} AutoReplyTenantResponse instance
             */
            AutoReplyTenantResponse.create = function create(properties) {
                return new AutoReplyTenantResponse(properties);
            };

            /**
             * Encodes the specified AutoReplyTenantResponse message. Does not implicitly {@link api.core.AutoReplyTenantResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {api.core.IAutoReplyTenantResponse} message AutoReplyTenantResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyTenantResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.common.AutoReplyItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AutoReplyTenantResponse message, length delimited. Does not implicitly {@link api.core.AutoReplyTenantResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {api.core.IAutoReplyTenantResponse} message AutoReplyTenantResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyTenantResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AutoReplyTenantResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AutoReplyTenantResponse} AutoReplyTenantResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyTenantResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AutoReplyTenantResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.common.AutoReplyItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AutoReplyTenantResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AutoReplyTenantResponse} AutoReplyTenantResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyTenantResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AutoReplyTenantResponse message.
             * @function verify
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AutoReplyTenantResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.common.AutoReplyItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AutoReplyTenantResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AutoReplyTenantResponse} AutoReplyTenantResponse
             */
            AutoReplyTenantResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AutoReplyTenantResponse)
                    return object;
                let message = new $root.api.core.AutoReplyTenantResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.core.AutoReplyTenantResponse.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.core.AutoReplyTenantResponse.items: object expected");
                        message.items[i] = $root.api.common.AutoReplyItem.fromObject(object.items[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AutoReplyTenantResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {api.core.AutoReplyTenantResponse} message AutoReplyTenantResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AutoReplyTenantResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.common.AutoReplyItem.toObject(message.items[j], options);
                }
                return object;
            };

            /**
             * Converts this AutoReplyTenantResponse to JSON.
             * @function toJSON
             * @memberof api.core.AutoReplyTenantResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AutoReplyTenantResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AutoReplyTenantResponse
             * @function getTypeUrl
             * @memberof api.core.AutoReplyTenantResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AutoReplyTenantResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AutoReplyTenantResponse";
            };

            return AutoReplyTenantResponse;
        })();

        core.AutoReplyWorkerRequest = (function() {

            /**
             * Properties of an AutoReplyWorkerRequest.
             * @memberof api.core
             * @interface IAutoReplyWorkerRequest
             * @property {api.core.AutoReplyField|null} [field] AutoReplyWorkerRequest field
             * @property {Array.<number>|null} [workerId] AutoReplyWorkerRequest workerId
             */

            /**
             * Constructs a new AutoReplyWorkerRequest.
             * @memberof api.core
             * @classdesc Represents an AutoReplyWorkerRequest.
             * @implements IAutoReplyWorkerRequest
             * @constructor
             * @param {api.core.IAutoReplyWorkerRequest=} [properties] Properties to set
             */
            function AutoReplyWorkerRequest(properties) {
                this.workerId = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AutoReplyWorkerRequest field.
             * @member {api.core.AutoReplyField} field
             * @memberof api.core.AutoReplyWorkerRequest
             * @instance
             */
            AutoReplyWorkerRequest.prototype.field = 0;

            /**
             * AutoReplyWorkerRequest workerId.
             * @member {Array.<number>} workerId
             * @memberof api.core.AutoReplyWorkerRequest
             * @instance
             */
            AutoReplyWorkerRequest.prototype.workerId = $util.emptyArray;

            /**
             * Creates a new AutoReplyWorkerRequest instance using the specified properties.
             * @function create
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {api.core.IAutoReplyWorkerRequest=} [properties] Properties to set
             * @returns {api.core.AutoReplyWorkerRequest} AutoReplyWorkerRequest instance
             */
            AutoReplyWorkerRequest.create = function create(properties) {
                return new AutoReplyWorkerRequest(properties);
            };

            /**
             * Encodes the specified AutoReplyWorkerRequest message. Does not implicitly {@link api.core.AutoReplyWorkerRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {api.core.IAutoReplyWorkerRequest} message AutoReplyWorkerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyWorkerRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.field != null && Object.hasOwnProperty.call(message, "field"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.field);
                if (message.workerId != null && message.workerId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (let i = 0; i < message.workerId.length; ++i)
                        writer.int32(message.workerId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified AutoReplyWorkerRequest message, length delimited. Does not implicitly {@link api.core.AutoReplyWorkerRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {api.core.IAutoReplyWorkerRequest} message AutoReplyWorkerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AutoReplyWorkerRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AutoReplyWorkerRequest} AutoReplyWorkerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyWorkerRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AutoReplyWorkerRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.field = reader.int32();
                            break;
                        }
                    case 2: {
                            if (!(message.workerId && message.workerId.length))
                                message.workerId = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.workerId.push(reader.int32());
                            } else
                                message.workerId.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AutoReplyWorkerRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AutoReplyWorkerRequest} AutoReplyWorkerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AutoReplyWorkerRequest message.
             * @function verify
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AutoReplyWorkerRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.field != null && message.hasOwnProperty("field"))
                    switch (message.field) {
                    default:
                        return "field: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.workerId != null && message.hasOwnProperty("workerId")) {
                    if (!Array.isArray(message.workerId))
                        return "workerId: array expected";
                    for (let i = 0; i < message.workerId.length; ++i)
                        if (!$util.isInteger(message.workerId[i]))
                            return "workerId: integer[] expected";
                }
                return null;
            };

            /**
             * Creates an AutoReplyWorkerRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AutoReplyWorkerRequest} AutoReplyWorkerRequest
             */
            AutoReplyWorkerRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AutoReplyWorkerRequest)
                    return object;
                let message = new $root.api.core.AutoReplyWorkerRequest();
                switch (object.field) {
                default:
                    if (typeof object.field === "number") {
                        message.field = object.field;
                        break;
                    }
                    break;
                case "AUTO_REPLY_FIELD_ALL":
                case 0:
                    message.field = 0;
                    break;
                case "AUTO_REPLY_FIELD_NAME":
                case 1:
                    message.field = 1;
                    break;
                }
                if (object.workerId) {
                    if (!Array.isArray(object.workerId))
                        throw TypeError(".api.core.AutoReplyWorkerRequest.workerId: array expected");
                    message.workerId = [];
                    for (let i = 0; i < object.workerId.length; ++i)
                        message.workerId[i] = object.workerId[i] | 0;
                }
                return message;
            };

            /**
             * Creates a plain object from an AutoReplyWorkerRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {api.core.AutoReplyWorkerRequest} message AutoReplyWorkerRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AutoReplyWorkerRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.workerId = [];
                if (options.defaults)
                    object.field = options.enums === String ? "AUTO_REPLY_FIELD_ALL" : 0;
                if (message.field != null && message.hasOwnProperty("field"))
                    object.field = options.enums === String ? $root.api.core.AutoReplyField[message.field] === undefined ? message.field : $root.api.core.AutoReplyField[message.field] : message.field;
                if (message.workerId && message.workerId.length) {
                    object.workerId = [];
                    for (let j = 0; j < message.workerId.length; ++j)
                        object.workerId[j] = message.workerId[j];
                }
                return object;
            };

            /**
             * Converts this AutoReplyWorkerRequest to JSON.
             * @function toJSON
             * @memberof api.core.AutoReplyWorkerRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AutoReplyWorkerRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AutoReplyWorkerRequest
             * @function getTypeUrl
             * @memberof api.core.AutoReplyWorkerRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AutoReplyWorkerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AutoReplyWorkerRequest";
            };

            return AutoReplyWorkerRequest;
        })();

        core.AutoReplyWorkerResponse = (function() {

            /**
             * Properties of an AutoReplyWorkerResponse.
             * @memberof api.core
             * @interface IAutoReplyWorkerResponse
             * @property {api.core.AutoReplyField|null} [field] AutoReplyWorkerResponse field
             * @property {Object.<string,api.common.IAutoReplyItem>|null} [items] AutoReplyWorkerResponse items
             */

            /**
             * Constructs a new AutoReplyWorkerResponse.
             * @memberof api.core
             * @classdesc Represents an AutoReplyWorkerResponse.
             * @implements IAutoReplyWorkerResponse
             * @constructor
             * @param {api.core.IAutoReplyWorkerResponse=} [properties] Properties to set
             */
            function AutoReplyWorkerResponse(properties) {
                this.items = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AutoReplyWorkerResponse field.
             * @member {api.core.AutoReplyField} field
             * @memberof api.core.AutoReplyWorkerResponse
             * @instance
             */
            AutoReplyWorkerResponse.prototype.field = 0;

            /**
             * AutoReplyWorkerResponse items.
             * @member {Object.<string,api.common.IAutoReplyItem>} items
             * @memberof api.core.AutoReplyWorkerResponse
             * @instance
             */
            AutoReplyWorkerResponse.prototype.items = $util.emptyObject;

            /**
             * Creates a new AutoReplyWorkerResponse instance using the specified properties.
             * @function create
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {api.core.IAutoReplyWorkerResponse=} [properties] Properties to set
             * @returns {api.core.AutoReplyWorkerResponse} AutoReplyWorkerResponse instance
             */
            AutoReplyWorkerResponse.create = function create(properties) {
                return new AutoReplyWorkerResponse(properties);
            };

            /**
             * Encodes the specified AutoReplyWorkerResponse message. Does not implicitly {@link api.core.AutoReplyWorkerResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {api.core.IAutoReplyWorkerResponse} message AutoReplyWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyWorkerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.field != null && Object.hasOwnProperty.call(message, "field"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.field);
                if (message.items != null && Object.hasOwnProperty.call(message, "items"))
                    for (let keys = Object.keys(message.items), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 0 =*/8).int32(keys[i]);
                        $root.api.common.AutoReplyItem.encode(message.items[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };

            /**
             * Encodes the specified AutoReplyWorkerResponse message, length delimited. Does not implicitly {@link api.core.AutoReplyWorkerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {api.core.IAutoReplyWorkerResponse} message AutoReplyWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyWorkerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AutoReplyWorkerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AutoReplyWorkerResponse} AutoReplyWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyWorkerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AutoReplyWorkerResponse(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.field = reader.int32();
                            break;
                        }
                    case 2: {
                            if (message.items === $util.emptyObject)
                                message.items = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = 0;
                            value = null;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.int32();
                                    break;
                                case 2:
                                    value = $root.api.common.AutoReplyItem.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.items[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AutoReplyWorkerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AutoReplyWorkerResponse} AutoReplyWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyWorkerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AutoReplyWorkerResponse message.
             * @function verify
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AutoReplyWorkerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.field != null && message.hasOwnProperty("field"))
                    switch (message.field) {
                    default:
                        return "field: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!$util.isObject(message.items))
                        return "items: object expected";
                    let key = Object.keys(message.items);
                    for (let i = 0; i < key.length; ++i) {
                        if (!$util.key32Re.test(key[i]))
                            return "items: integer key{k:int32} expected";
                        {
                            let error = $root.api.common.AutoReplyItem.verify(message.items[key[i]]);
                            if (error)
                                return "items." + error;
                        }
                    }
                }
                return null;
            };

            /**
             * Creates an AutoReplyWorkerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AutoReplyWorkerResponse} AutoReplyWorkerResponse
             */
            AutoReplyWorkerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AutoReplyWorkerResponse)
                    return object;
                let message = new $root.api.core.AutoReplyWorkerResponse();
                switch (object.field) {
                default:
                    if (typeof object.field === "number") {
                        message.field = object.field;
                        break;
                    }
                    break;
                case "AUTO_REPLY_FIELD_ALL":
                case 0:
                    message.field = 0;
                    break;
                case "AUTO_REPLY_FIELD_NAME":
                case 1:
                    message.field = 1;
                    break;
                }
                if (object.items) {
                    if (typeof object.items !== "object")
                        throw TypeError(".api.core.AutoReplyWorkerResponse.items: object expected");
                    message.items = {};
                    for (let keys = Object.keys(object.items), i = 0; i < keys.length; ++i) {
                        if (typeof object.items[keys[i]] !== "object")
                            throw TypeError(".api.core.AutoReplyWorkerResponse.items: object expected");
                        message.items[keys[i]] = $root.api.common.AutoReplyItem.fromObject(object.items[keys[i]]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AutoReplyWorkerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {api.core.AutoReplyWorkerResponse} message AutoReplyWorkerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AutoReplyWorkerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.items = {};
                if (options.defaults)
                    object.field = options.enums === String ? "AUTO_REPLY_FIELD_ALL" : 0;
                if (message.field != null && message.hasOwnProperty("field"))
                    object.field = options.enums === String ? $root.api.core.AutoReplyField[message.field] === undefined ? message.field : $root.api.core.AutoReplyField[message.field] : message.field;
                let keys2;
                if (message.items && (keys2 = Object.keys(message.items)).length) {
                    object.items = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.items[keys2[j]] = $root.api.common.AutoReplyItem.toObject(message.items[keys2[j]], options);
                }
                return object;
            };

            /**
             * Converts this AutoReplyWorkerResponse to JSON.
             * @function toJSON
             * @memberof api.core.AutoReplyWorkerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AutoReplyWorkerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AutoReplyWorkerResponse
             * @function getTypeUrl
             * @memberof api.core.AutoReplyWorkerResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AutoReplyWorkerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AutoReplyWorkerResponse";
            };

            return AutoReplyWorkerResponse;
        })();

        core.AutoReplyCreateRequest = (function() {

            /**
             * Properties of an AutoReplyCreateRequest.
             * @memberof api.core
             * @interface IAutoReplyCreateRequest
             * @property {api.common.IAutoReplyItem|null} [item] AutoReplyCreateRequest item
             * @property {Array.<number>|null} [workerId] AutoReplyCreateRequest workerId
             */

            /**
             * Constructs a new AutoReplyCreateRequest.
             * @memberof api.core
             * @classdesc Represents an AutoReplyCreateRequest.
             * @implements IAutoReplyCreateRequest
             * @constructor
             * @param {api.core.IAutoReplyCreateRequest=} [properties] Properties to set
             */
            function AutoReplyCreateRequest(properties) {
                this.workerId = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AutoReplyCreateRequest item.
             * @member {api.common.IAutoReplyItem|null|undefined} item
             * @memberof api.core.AutoReplyCreateRequest
             * @instance
             */
            AutoReplyCreateRequest.prototype.item = null;

            /**
             * AutoReplyCreateRequest workerId.
             * @member {Array.<number>} workerId
             * @memberof api.core.AutoReplyCreateRequest
             * @instance
             */
            AutoReplyCreateRequest.prototype.workerId = $util.emptyArray;

            /**
             * Creates a new AutoReplyCreateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {api.core.IAutoReplyCreateRequest=} [properties] Properties to set
             * @returns {api.core.AutoReplyCreateRequest} AutoReplyCreateRequest instance
             */
            AutoReplyCreateRequest.create = function create(properties) {
                return new AutoReplyCreateRequest(properties);
            };

            /**
             * Encodes the specified AutoReplyCreateRequest message. Does not implicitly {@link api.core.AutoReplyCreateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {api.core.IAutoReplyCreateRequest} message AutoReplyCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyCreateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                    $root.api.common.AutoReplyItem.encode(message.item, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.workerId != null && message.workerId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (let i = 0; i < message.workerId.length; ++i)
                        writer.int32(message.workerId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified AutoReplyCreateRequest message, length delimited. Does not implicitly {@link api.core.AutoReplyCreateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {api.core.IAutoReplyCreateRequest} message AutoReplyCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyCreateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AutoReplyCreateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AutoReplyCreateRequest} AutoReplyCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyCreateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AutoReplyCreateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.item = $root.api.common.AutoReplyItem.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.workerId && message.workerId.length))
                                message.workerId = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.workerId.push(reader.int32());
                            } else
                                message.workerId.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AutoReplyCreateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AutoReplyCreateRequest} AutoReplyCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyCreateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AutoReplyCreateRequest message.
             * @function verify
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AutoReplyCreateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.item != null && message.hasOwnProperty("item")) {
                    let error = $root.api.common.AutoReplyItem.verify(message.item);
                    if (error)
                        return "item." + error;
                }
                if (message.workerId != null && message.hasOwnProperty("workerId")) {
                    if (!Array.isArray(message.workerId))
                        return "workerId: array expected";
                    for (let i = 0; i < message.workerId.length; ++i)
                        if (!$util.isInteger(message.workerId[i]))
                            return "workerId: integer[] expected";
                }
                return null;
            };

            /**
             * Creates an AutoReplyCreateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AutoReplyCreateRequest} AutoReplyCreateRequest
             */
            AutoReplyCreateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AutoReplyCreateRequest)
                    return object;
                let message = new $root.api.core.AutoReplyCreateRequest();
                if (object.item != null) {
                    if (typeof object.item !== "object")
                        throw TypeError(".api.core.AutoReplyCreateRequest.item: object expected");
                    message.item = $root.api.common.AutoReplyItem.fromObject(object.item);
                }
                if (object.workerId) {
                    if (!Array.isArray(object.workerId))
                        throw TypeError(".api.core.AutoReplyCreateRequest.workerId: array expected");
                    message.workerId = [];
                    for (let i = 0; i < object.workerId.length; ++i)
                        message.workerId[i] = object.workerId[i] | 0;
                }
                return message;
            };

            /**
             * Creates a plain object from an AutoReplyCreateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {api.core.AutoReplyCreateRequest} message AutoReplyCreateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AutoReplyCreateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.workerId = [];
                if (options.defaults)
                    object.item = null;
                if (message.item != null && message.hasOwnProperty("item"))
                    object.item = $root.api.common.AutoReplyItem.toObject(message.item, options);
                if (message.workerId && message.workerId.length) {
                    object.workerId = [];
                    for (let j = 0; j < message.workerId.length; ++j)
                        object.workerId[j] = message.workerId[j];
                }
                return object;
            };

            /**
             * Converts this AutoReplyCreateRequest to JSON.
             * @function toJSON
             * @memberof api.core.AutoReplyCreateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AutoReplyCreateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AutoReplyCreateRequest
             * @function getTypeUrl
             * @memberof api.core.AutoReplyCreateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AutoReplyCreateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AutoReplyCreateRequest";
            };

            return AutoReplyCreateRequest;
        })();

        core.AutoReplyCreateResponse = (function() {

            /**
             * Properties of an AutoReplyCreateResponse.
             * @memberof api.core
             * @interface IAutoReplyCreateResponse
             * @property {Long|null} [id] AutoReplyCreateResponse id
             */

            /**
             * Constructs a new AutoReplyCreateResponse.
             * @memberof api.core
             * @classdesc Represents an AutoReplyCreateResponse.
             * @implements IAutoReplyCreateResponse
             * @constructor
             * @param {api.core.IAutoReplyCreateResponse=} [properties] Properties to set
             */
            function AutoReplyCreateResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AutoReplyCreateResponse id.
             * @member {Long} id
             * @memberof api.core.AutoReplyCreateResponse
             * @instance
             */
            AutoReplyCreateResponse.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new AutoReplyCreateResponse instance using the specified properties.
             * @function create
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {api.core.IAutoReplyCreateResponse=} [properties] Properties to set
             * @returns {api.core.AutoReplyCreateResponse} AutoReplyCreateResponse instance
             */
            AutoReplyCreateResponse.create = function create(properties) {
                return new AutoReplyCreateResponse(properties);
            };

            /**
             * Encodes the specified AutoReplyCreateResponse message. Does not implicitly {@link api.core.AutoReplyCreateResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {api.core.IAutoReplyCreateResponse} message AutoReplyCreateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyCreateResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                return writer;
            };

            /**
             * Encodes the specified AutoReplyCreateResponse message, length delimited. Does not implicitly {@link api.core.AutoReplyCreateResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {api.core.IAutoReplyCreateResponse} message AutoReplyCreateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyCreateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AutoReplyCreateResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AutoReplyCreateResponse} AutoReplyCreateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyCreateResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AutoReplyCreateResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AutoReplyCreateResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AutoReplyCreateResponse} AutoReplyCreateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyCreateResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AutoReplyCreateResponse message.
             * @function verify
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AutoReplyCreateResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                return null;
            };

            /**
             * Creates an AutoReplyCreateResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AutoReplyCreateResponse} AutoReplyCreateResponse
             */
            AutoReplyCreateResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AutoReplyCreateResponse)
                    return object;
                let message = new $root.api.core.AutoReplyCreateResponse();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an AutoReplyCreateResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {api.core.AutoReplyCreateResponse} message AutoReplyCreateResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AutoReplyCreateResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                return object;
            };

            /**
             * Converts this AutoReplyCreateResponse to JSON.
             * @function toJSON
             * @memberof api.core.AutoReplyCreateResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AutoReplyCreateResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AutoReplyCreateResponse
             * @function getTypeUrl
             * @memberof api.core.AutoReplyCreateResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AutoReplyCreateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AutoReplyCreateResponse";
            };

            return AutoReplyCreateResponse;
        })();

        core.AutoReplyDeleteRequest = (function() {

            /**
             * Properties of an AutoReplyDeleteRequest.
             * @memberof api.core
             * @interface IAutoReplyDeleteRequest
             * @property {Long|null} [id] AutoReplyDeleteRequest id
             */

            /**
             * Constructs a new AutoReplyDeleteRequest.
             * @memberof api.core
             * @classdesc Represents an AutoReplyDeleteRequest.
             * @implements IAutoReplyDeleteRequest
             * @constructor
             * @param {api.core.IAutoReplyDeleteRequest=} [properties] Properties to set
             */
            function AutoReplyDeleteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AutoReplyDeleteRequest id.
             * @member {Long} id
             * @memberof api.core.AutoReplyDeleteRequest
             * @instance
             */
            AutoReplyDeleteRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new AutoReplyDeleteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {api.core.IAutoReplyDeleteRequest=} [properties] Properties to set
             * @returns {api.core.AutoReplyDeleteRequest} AutoReplyDeleteRequest instance
             */
            AutoReplyDeleteRequest.create = function create(properties) {
                return new AutoReplyDeleteRequest(properties);
            };

            /**
             * Encodes the specified AutoReplyDeleteRequest message. Does not implicitly {@link api.core.AutoReplyDeleteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {api.core.IAutoReplyDeleteRequest} message AutoReplyDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyDeleteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                return writer;
            };

            /**
             * Encodes the specified AutoReplyDeleteRequest message, length delimited. Does not implicitly {@link api.core.AutoReplyDeleteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {api.core.IAutoReplyDeleteRequest} message AutoReplyDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyDeleteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AutoReplyDeleteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AutoReplyDeleteRequest} AutoReplyDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyDeleteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AutoReplyDeleteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AutoReplyDeleteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AutoReplyDeleteRequest} AutoReplyDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyDeleteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AutoReplyDeleteRequest message.
             * @function verify
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AutoReplyDeleteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                return null;
            };

            /**
             * Creates an AutoReplyDeleteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AutoReplyDeleteRequest} AutoReplyDeleteRequest
             */
            AutoReplyDeleteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AutoReplyDeleteRequest)
                    return object;
                let message = new $root.api.core.AutoReplyDeleteRequest();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an AutoReplyDeleteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {api.core.AutoReplyDeleteRequest} message AutoReplyDeleteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AutoReplyDeleteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                return object;
            };

            /**
             * Converts this AutoReplyDeleteRequest to JSON.
             * @function toJSON
             * @memberof api.core.AutoReplyDeleteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AutoReplyDeleteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AutoReplyDeleteRequest
             * @function getTypeUrl
             * @memberof api.core.AutoReplyDeleteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AutoReplyDeleteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AutoReplyDeleteRequest";
            };

            return AutoReplyDeleteRequest;
        })();

        core.AutoReplyUpdateRequest = (function() {

            /**
             * Properties of an AutoReplyUpdateRequest.
             * @memberof api.core
             * @interface IAutoReplyUpdateRequest
             * @property {api.common.IAutoReplyItem|null} [item] AutoReplyUpdateRequest item
             * @property {Array.<number>|null} [workerId] AutoReplyUpdateRequest workerId
             */

            /**
             * Constructs a new AutoReplyUpdateRequest.
             * @memberof api.core
             * @classdesc Represents an AutoReplyUpdateRequest.
             * @implements IAutoReplyUpdateRequest
             * @constructor
             * @param {api.core.IAutoReplyUpdateRequest=} [properties] Properties to set
             */
            function AutoReplyUpdateRequest(properties) {
                this.workerId = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AutoReplyUpdateRequest item.
             * @member {api.common.IAutoReplyItem|null|undefined} item
             * @memberof api.core.AutoReplyUpdateRequest
             * @instance
             */
            AutoReplyUpdateRequest.prototype.item = null;

            /**
             * AutoReplyUpdateRequest workerId.
             * @member {Array.<number>} workerId
             * @memberof api.core.AutoReplyUpdateRequest
             * @instance
             */
            AutoReplyUpdateRequest.prototype.workerId = $util.emptyArray;

            /**
             * Creates a new AutoReplyUpdateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {api.core.IAutoReplyUpdateRequest=} [properties] Properties to set
             * @returns {api.core.AutoReplyUpdateRequest} AutoReplyUpdateRequest instance
             */
            AutoReplyUpdateRequest.create = function create(properties) {
                return new AutoReplyUpdateRequest(properties);
            };

            /**
             * Encodes the specified AutoReplyUpdateRequest message. Does not implicitly {@link api.core.AutoReplyUpdateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {api.core.IAutoReplyUpdateRequest} message AutoReplyUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyUpdateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                    $root.api.common.AutoReplyItem.encode(message.item, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.workerId != null && message.workerId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (let i = 0; i < message.workerId.length; ++i)
                        writer.int32(message.workerId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified AutoReplyUpdateRequest message, length delimited. Does not implicitly {@link api.core.AutoReplyUpdateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {api.core.IAutoReplyUpdateRequest} message AutoReplyUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AutoReplyUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AutoReplyUpdateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AutoReplyUpdateRequest} AutoReplyUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyUpdateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AutoReplyUpdateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.item = $root.api.common.AutoReplyItem.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.workerId && message.workerId.length))
                                message.workerId = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.workerId.push(reader.int32());
                            } else
                                message.workerId.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AutoReplyUpdateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AutoReplyUpdateRequest} AutoReplyUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AutoReplyUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AutoReplyUpdateRequest message.
             * @function verify
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AutoReplyUpdateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.item != null && message.hasOwnProperty("item")) {
                    let error = $root.api.common.AutoReplyItem.verify(message.item);
                    if (error)
                        return "item." + error;
                }
                if (message.workerId != null && message.hasOwnProperty("workerId")) {
                    if (!Array.isArray(message.workerId))
                        return "workerId: array expected";
                    for (let i = 0; i < message.workerId.length; ++i)
                        if (!$util.isInteger(message.workerId[i]))
                            return "workerId: integer[] expected";
                }
                return null;
            };

            /**
             * Creates an AutoReplyUpdateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AutoReplyUpdateRequest} AutoReplyUpdateRequest
             */
            AutoReplyUpdateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AutoReplyUpdateRequest)
                    return object;
                let message = new $root.api.core.AutoReplyUpdateRequest();
                if (object.item != null) {
                    if (typeof object.item !== "object")
                        throw TypeError(".api.core.AutoReplyUpdateRequest.item: object expected");
                    message.item = $root.api.common.AutoReplyItem.fromObject(object.item);
                }
                if (object.workerId) {
                    if (!Array.isArray(object.workerId))
                        throw TypeError(".api.core.AutoReplyUpdateRequest.workerId: array expected");
                    message.workerId = [];
                    for (let i = 0; i < object.workerId.length; ++i)
                        message.workerId[i] = object.workerId[i] | 0;
                }
                return message;
            };

            /**
             * Creates a plain object from an AutoReplyUpdateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {api.core.AutoReplyUpdateRequest} message AutoReplyUpdateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AutoReplyUpdateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.workerId = [];
                if (options.defaults)
                    object.item = null;
                if (message.item != null && message.hasOwnProperty("item"))
                    object.item = $root.api.common.AutoReplyItem.toObject(message.item, options);
                if (message.workerId && message.workerId.length) {
                    object.workerId = [];
                    for (let j = 0; j < message.workerId.length; ++j)
                        object.workerId[j] = message.workerId[j];
                }
                return object;
            };

            /**
             * Converts this AutoReplyUpdateRequest to JSON.
             * @function toJSON
             * @memberof api.core.AutoReplyUpdateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AutoReplyUpdateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AutoReplyUpdateRequest
             * @function getTypeUrl
             * @memberof api.core.AutoReplyUpdateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AutoReplyUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AutoReplyUpdateRequest";
            };

            return AutoReplyUpdateRequest;
        })();

        core.AutoReply = (function() {

            /**
             * Constructs a new AutoReply service.
             * @memberof api.core
             * @classdesc Represents an AutoReply
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function AutoReply(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (AutoReply.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AutoReply;

            /**
             * Creates new AutoReply service using the specified rpc implementation.
             * @function create
             * @memberof api.core.AutoReply
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {AutoReply} RPC service. Useful where requests and/or responses are streamed.
             */
            AutoReply.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.AutoReply#queryTenant}.
             * @memberof api.core.AutoReply
             * @typedef QueryTenantCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.AutoReplyTenantResponse} [response] AutoReplyTenantResponse
             */

            /**
             * Calls QueryTenant.
             * @function queryTenant
             * @memberof api.core.AutoReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.AutoReply.QueryTenantCallback} callback Node-style callback called with the error, if any, and AutoReplyTenantResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AutoReply.prototype.queryTenant = function queryTenant(request, callback) {
                return this.rpcCall(queryTenant, $root.google.protobuf.Empty, $root.api.core.AutoReplyTenantResponse, request, callback);
            }, "name", { value: "QueryTenant" });

            /**
             * Calls QueryTenant.
             * @function queryTenant
             * @memberof api.core.AutoReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.AutoReplyTenantResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.AutoReply#queryWorker}.
             * @memberof api.core.AutoReply
             * @typedef QueryWorkerCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.AutoReplyWorkerResponse} [response] AutoReplyWorkerResponse
             */

            /**
             * Calls QueryWorker.
             * @function queryWorker
             * @memberof api.core.AutoReply
             * @instance
             * @param {api.core.IAutoReplyWorkerRequest} request AutoReplyWorkerRequest message or plain object
             * @param {api.core.AutoReply.QueryWorkerCallback} callback Node-style callback called with the error, if any, and AutoReplyWorkerResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AutoReply.prototype.queryWorker = function queryWorker(request, callback) {
                return this.rpcCall(queryWorker, $root.api.core.AutoReplyWorkerRequest, $root.api.core.AutoReplyWorkerResponse, request, callback);
            }, "name", { value: "QueryWorker" });

            /**
             * Calls QueryWorker.
             * @function queryWorker
             * @memberof api.core.AutoReply
             * @instance
             * @param {api.core.IAutoReplyWorkerRequest} request AutoReplyWorkerRequest message or plain object
             * @returns {Promise<api.core.AutoReplyWorkerResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.AutoReply#create}.
             * @memberof api.core.AutoReply
             * @typedef CreateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.AutoReplyCreateResponse} [response] AutoReplyCreateResponse
             */

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.AutoReply
             * @instance
             * @param {api.core.IAutoReplyCreateRequest} request AutoReplyCreateRequest message or plain object
             * @param {api.core.AutoReply.CreateCallback} callback Node-style callback called with the error, if any, and AutoReplyCreateResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AutoReply.prototype.create = function create(request, callback) {
                return this.rpcCall(create, $root.api.core.AutoReplyCreateRequest, $root.api.core.AutoReplyCreateResponse, request, callback);
            }, "name", { value: "Create" });

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.AutoReply
             * @instance
             * @param {api.core.IAutoReplyCreateRequest} request AutoReplyCreateRequest message or plain object
             * @returns {Promise<api.core.AutoReplyCreateResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.AutoReply#delete_}.
             * @memberof api.core.AutoReply
             * @typedef DeleteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Delete.
             * @function delete
             * @memberof api.core.AutoReply
             * @instance
             * @param {api.core.IAutoReplyDeleteRequest} request AutoReplyDeleteRequest message or plain object
             * @param {api.core.AutoReply.DeleteCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AutoReply.prototype["delete"] = function delete_(request, callback) {
                return this.rpcCall(delete_, $root.api.core.AutoReplyDeleteRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Delete" });

            /**
             * Calls Delete.
             * @function delete
             * @memberof api.core.AutoReply
             * @instance
             * @param {api.core.IAutoReplyDeleteRequest} request AutoReplyDeleteRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.AutoReply#update}.
             * @memberof api.core.AutoReply
             * @typedef UpdateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Update.
             * @function update
             * @memberof api.core.AutoReply
             * @instance
             * @param {api.core.IAutoReplyUpdateRequest} request AutoReplyUpdateRequest message or plain object
             * @param {api.core.AutoReply.UpdateCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(AutoReply.prototype.update = function update(request, callback) {
                return this.rpcCall(update, $root.api.core.AutoReplyUpdateRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Update" });

            /**
             * Calls Update.
             * @function update
             * @memberof api.core.AutoReply
             * @instance
             * @param {api.core.IAutoReplyUpdateRequest} request AutoReplyUpdateRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return AutoReply;
        })();

        core.ChatListQueryRequest = (function() {

            /**
             * Properties of a ChatListQueryRequest.
             * @memberof api.core
             * @interface IChatListQueryRequest
             * @property {number|null} [workerId] ChatListQueryRequest workerId
             */

            /**
             * Constructs a new ChatListQueryRequest.
             * @memberof api.core
             * @classdesc Represents a ChatListQueryRequest.
             * @implements IChatListQueryRequest
             * @constructor
             * @param {api.core.IChatListQueryRequest=} [properties] Properties to set
             */
            function ChatListQueryRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChatListQueryRequest workerId.
             * @member {number} workerId
             * @memberof api.core.ChatListQueryRequest
             * @instance
             */
            ChatListQueryRequest.prototype.workerId = 0;

            /**
             * Creates a new ChatListQueryRequest instance using the specified properties.
             * @function create
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {api.core.IChatListQueryRequest=} [properties] Properties to set
             * @returns {api.core.ChatListQueryRequest} ChatListQueryRequest instance
             */
            ChatListQueryRequest.create = function create(properties) {
                return new ChatListQueryRequest(properties);
            };

            /**
             * Encodes the specified ChatListQueryRequest message. Does not implicitly {@link api.core.ChatListQueryRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {api.core.IChatListQueryRequest} message ChatListQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatListQueryRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified ChatListQueryRequest message, length delimited. Does not implicitly {@link api.core.ChatListQueryRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {api.core.IChatListQueryRequest} message ChatListQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatListQueryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ChatListQueryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.ChatListQueryRequest} ChatListQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatListQueryRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.ChatListQueryRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ChatListQueryRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.ChatListQueryRequest} ChatListQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatListQueryRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ChatListQueryRequest message.
             * @function verify
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChatListQueryRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a ChatListQueryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.ChatListQueryRequest} ChatListQueryRequest
             */
            ChatListQueryRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.ChatListQueryRequest)
                    return object;
                let message = new $root.api.core.ChatListQueryRequest();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a ChatListQueryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {api.core.ChatListQueryRequest} message ChatListQueryRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChatListQueryRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.workerId = 0;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this ChatListQueryRequest to JSON.
             * @function toJSON
             * @memberof api.core.ChatListQueryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChatListQueryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChatListQueryRequest
             * @function getTypeUrl
             * @memberof api.core.ChatListQueryRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChatListQueryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.ChatListQueryRequest";
            };

            return ChatListQueryRequest;
        })();

        core.ChatListQueryResponse = (function() {

            /**
             * Properties of a ChatListQueryResponse.
             * @memberof api.core
             * @interface IChatListQueryResponse
             * @property {Array.<api.common.IChatItem>|null} [chats] ChatListQueryResponse chats
             */

            /**
             * Constructs a new ChatListQueryResponse.
             * @memberof api.core
             * @classdesc Represents a ChatListQueryResponse.
             * @implements IChatListQueryResponse
             * @constructor
             * @param {api.core.IChatListQueryResponse=} [properties] Properties to set
             */
            function ChatListQueryResponse(properties) {
                this.chats = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChatListQueryResponse chats.
             * @member {Array.<api.common.IChatItem>} chats
             * @memberof api.core.ChatListQueryResponse
             * @instance
             */
            ChatListQueryResponse.prototype.chats = $util.emptyArray;

            /**
             * Creates a new ChatListQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {api.core.IChatListQueryResponse=} [properties] Properties to set
             * @returns {api.core.ChatListQueryResponse} ChatListQueryResponse instance
             */
            ChatListQueryResponse.create = function create(properties) {
                return new ChatListQueryResponse(properties);
            };

            /**
             * Encodes the specified ChatListQueryResponse message. Does not implicitly {@link api.core.ChatListQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {api.core.IChatListQueryResponse} message ChatListQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatListQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chats != null && message.chats.length)
                    for (let i = 0; i < message.chats.length; ++i)
                        $root.api.common.ChatItem.encode(message.chats[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ChatListQueryResponse message, length delimited. Does not implicitly {@link api.core.ChatListQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {api.core.IChatListQueryResponse} message ChatListQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatListQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ChatListQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.ChatListQueryResponse} ChatListQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatListQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.ChatListQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.chats && message.chats.length))
                                message.chats = [];
                            message.chats.push($root.api.common.ChatItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ChatListQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.ChatListQueryResponse} ChatListQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatListQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ChatListQueryResponse message.
             * @function verify
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChatListQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chats != null && message.hasOwnProperty("chats")) {
                    if (!Array.isArray(message.chats))
                        return "chats: array expected";
                    for (let i = 0; i < message.chats.length; ++i) {
                        let error = $root.api.common.ChatItem.verify(message.chats[i]);
                        if (error)
                            return "chats." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ChatListQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.ChatListQueryResponse} ChatListQueryResponse
             */
            ChatListQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.ChatListQueryResponse)
                    return object;
                let message = new $root.api.core.ChatListQueryResponse();
                if (object.chats) {
                    if (!Array.isArray(object.chats))
                        throw TypeError(".api.core.ChatListQueryResponse.chats: array expected");
                    message.chats = [];
                    for (let i = 0; i < object.chats.length; ++i) {
                        if (typeof object.chats[i] !== "object")
                            throw TypeError(".api.core.ChatListQueryResponse.chats: object expected");
                        message.chats[i] = $root.api.common.ChatItem.fromObject(object.chats[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ChatListQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {api.core.ChatListQueryResponse} message ChatListQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChatListQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.chats = [];
                if (message.chats && message.chats.length) {
                    object.chats = [];
                    for (let j = 0; j < message.chats.length; ++j)
                        object.chats[j] = $root.api.common.ChatItem.toObject(message.chats[j], options);
                }
                return object;
            };

            /**
             * Converts this ChatListQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.ChatListQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChatListQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChatListQueryResponse
             * @function getTypeUrl
             * @memberof api.core.ChatListQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChatListQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.ChatListQueryResponse";
            };

            return ChatListQueryResponse;
        })();

        core.ChatMarkReadRequest = (function() {

            /**
             * Properties of a ChatMarkReadRequest.
             * @memberof api.core
             * @interface IChatMarkReadRequest
             * @property {Long|null} [chatId] ChatMarkReadRequest chatId
             */

            /**
             * Constructs a new ChatMarkReadRequest.
             * @memberof api.core
             * @classdesc Represents a ChatMarkReadRequest.
             * @implements IChatMarkReadRequest
             * @constructor
             * @param {api.core.IChatMarkReadRequest=} [properties] Properties to set
             */
            function ChatMarkReadRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChatMarkReadRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.ChatMarkReadRequest
             * @instance
             */
            ChatMarkReadRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new ChatMarkReadRequest instance using the specified properties.
             * @function create
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {api.core.IChatMarkReadRequest=} [properties] Properties to set
             * @returns {api.core.ChatMarkReadRequest} ChatMarkReadRequest instance
             */
            ChatMarkReadRequest.create = function create(properties) {
                return new ChatMarkReadRequest(properties);
            };

            /**
             * Encodes the specified ChatMarkReadRequest message. Does not implicitly {@link api.core.ChatMarkReadRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {api.core.IChatMarkReadRequest} message ChatMarkReadRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatMarkReadRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                return writer;
            };

            /**
             * Encodes the specified ChatMarkReadRequest message, length delimited. Does not implicitly {@link api.core.ChatMarkReadRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {api.core.IChatMarkReadRequest} message ChatMarkReadRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChatMarkReadRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ChatMarkReadRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.ChatMarkReadRequest} ChatMarkReadRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatMarkReadRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.ChatMarkReadRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ChatMarkReadRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.ChatMarkReadRequest} ChatMarkReadRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChatMarkReadRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ChatMarkReadRequest message.
             * @function verify
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChatMarkReadRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a ChatMarkReadRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.ChatMarkReadRequest} ChatMarkReadRequest
             */
            ChatMarkReadRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.ChatMarkReadRequest)
                    return object;
                let message = new $root.api.core.ChatMarkReadRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a ChatMarkReadRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {api.core.ChatMarkReadRequest} message ChatMarkReadRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChatMarkReadRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                return object;
            };

            /**
             * Converts this ChatMarkReadRequest to JSON.
             * @function toJSON
             * @memberof api.core.ChatMarkReadRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChatMarkReadRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChatMarkReadRequest
             * @function getTypeUrl
             * @memberof api.core.ChatMarkReadRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChatMarkReadRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.ChatMarkReadRequest";
            };

            return ChatMarkReadRequest;
        })();

        core.OrphanResponse = (function() {

            /**
             * Properties of an OrphanResponse.
             * @memberof api.core
             * @interface IOrphanResponse
             * @property {number|null} [workerId] OrphanResponse workerId
             * @property {string|null} [nick] OrphanResponse nick
             * @property {string|null} [avatar] OrphanResponse avatar
             */

            /**
             * Constructs a new OrphanResponse.
             * @memberof api.core
             * @classdesc Represents an OrphanResponse.
             * @implements IOrphanResponse
             * @constructor
             * @param {api.core.IOrphanResponse=} [properties] Properties to set
             */
            function OrphanResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrphanResponse workerId.
             * @member {number} workerId
             * @memberof api.core.OrphanResponse
             * @instance
             */
            OrphanResponse.prototype.workerId = 0;

            /**
             * OrphanResponse nick.
             * @member {string} nick
             * @memberof api.core.OrphanResponse
             * @instance
             */
            OrphanResponse.prototype.nick = "";

            /**
             * OrphanResponse avatar.
             * @member {string} avatar
             * @memberof api.core.OrphanResponse
             * @instance
             */
            OrphanResponse.prototype.avatar = "";

            /**
             * Creates a new OrphanResponse instance using the specified properties.
             * @function create
             * @memberof api.core.OrphanResponse
             * @static
             * @param {api.core.IOrphanResponse=} [properties] Properties to set
             * @returns {api.core.OrphanResponse} OrphanResponse instance
             */
            OrphanResponse.create = function create(properties) {
                return new OrphanResponse(properties);
            };

            /**
             * Encodes the specified OrphanResponse message. Does not implicitly {@link api.core.OrphanResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.OrphanResponse
             * @static
             * @param {api.core.IOrphanResponse} message OrphanResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrphanResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                if (message.nick != null && Object.hasOwnProperty.call(message, "nick"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.nick);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.avatar);
                return writer;
            };

            /**
             * Encodes the specified OrphanResponse message, length delimited. Does not implicitly {@link api.core.OrphanResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.OrphanResponse
             * @static
             * @param {api.core.IOrphanResponse} message OrphanResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrphanResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrphanResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.OrphanResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.OrphanResponse} OrphanResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrphanResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.OrphanResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.nick = reader.string();
                            break;
                        }
                    case 3: {
                            message.avatar = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrphanResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.OrphanResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.OrphanResponse} OrphanResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrphanResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrphanResponse message.
             * @function verify
             * @memberof api.core.OrphanResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrphanResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                if (message.nick != null && message.hasOwnProperty("nick"))
                    if (!$util.isString(message.nick))
                        return "nick: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                return null;
            };

            /**
             * Creates an OrphanResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.OrphanResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.OrphanResponse} OrphanResponse
             */
            OrphanResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.OrphanResponse)
                    return object;
                let message = new $root.api.core.OrphanResponse();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                if (object.nick != null)
                    message.nick = String(object.nick);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                return message;
            };

            /**
             * Creates a plain object from an OrphanResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.OrphanResponse
             * @static
             * @param {api.core.OrphanResponse} message OrphanResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrphanResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.workerId = 0;
                    object.nick = "";
                    object.avatar = "";
                }
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                if (message.nick != null && message.hasOwnProperty("nick"))
                    object.nick = message.nick;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                return object;
            };

            /**
             * Converts this OrphanResponse to JSON.
             * @function toJSON
             * @memberof api.core.OrphanResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrphanResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrphanResponse
             * @function getTypeUrl
             * @memberof api.core.OrphanResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrphanResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.OrphanResponse";
            };

            return OrphanResponse;
        })();

        core.MarkRepliedRequest = (function() {

            /**
             * Properties of a MarkRepliedRequest.
             * @memberof api.core
             * @interface IMarkRepliedRequest
             * @property {Long|null} [chatId] MarkRepliedRequest chatId
             */

            /**
             * Constructs a new MarkRepliedRequest.
             * @memberof api.core
             * @classdesc Represents a MarkRepliedRequest.
             * @implements IMarkRepliedRequest
             * @constructor
             * @param {api.core.IMarkRepliedRequest=} [properties] Properties to set
             */
            function MarkRepliedRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MarkRepliedRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.MarkRepliedRequest
             * @instance
             */
            MarkRepliedRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new MarkRepliedRequest instance using the specified properties.
             * @function create
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {api.core.IMarkRepliedRequest=} [properties] Properties to set
             * @returns {api.core.MarkRepliedRequest} MarkRepliedRequest instance
             */
            MarkRepliedRequest.create = function create(properties) {
                return new MarkRepliedRequest(properties);
            };

            /**
             * Encodes the specified MarkRepliedRequest message. Does not implicitly {@link api.core.MarkRepliedRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {api.core.IMarkRepliedRequest} message MarkRepliedRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkRepliedRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                return writer;
            };

            /**
             * Encodes the specified MarkRepliedRequest message, length delimited. Does not implicitly {@link api.core.MarkRepliedRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {api.core.IMarkRepliedRequest} message MarkRepliedRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkRepliedRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MarkRepliedRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.MarkRepliedRequest} MarkRepliedRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkRepliedRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.MarkRepliedRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MarkRepliedRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.MarkRepliedRequest} MarkRepliedRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkRepliedRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MarkRepliedRequest message.
             * @function verify
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarkRepliedRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a MarkRepliedRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.MarkRepliedRequest} MarkRepliedRequest
             */
            MarkRepliedRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.MarkRepliedRequest)
                    return object;
                let message = new $root.api.core.MarkRepliedRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a MarkRepliedRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {api.core.MarkRepliedRequest} message MarkRepliedRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarkRepliedRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                return object;
            };

            /**
             * Converts this MarkRepliedRequest to JSON.
             * @function toJSON
             * @memberof api.core.MarkRepliedRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarkRepliedRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MarkRepliedRequest
             * @function getTypeUrl
             * @memberof api.core.MarkRepliedRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MarkRepliedRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.MarkRepliedRequest";
            };

            return MarkRepliedRequest;
        })();

        core.Chat = (function() {

            /**
             * Constructs a new Chat service.
             * @memberof api.core
             * @classdesc Represents a Chat
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Chat(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Chat.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Chat;

            /**
             * Creates new Chat service using the specified rpc implementation.
             * @function create
             * @memberof api.core.Chat
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Chat} RPC service. Useful where requests and/or responses are streamed.
             */
            Chat.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.Chat#query}.
             * @memberof api.core.Chat
             * @typedef QueryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.ChatListQueryResponse} [response] ChatListQueryResponse
             */

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.Chat
             * @instance
             * @param {api.core.IChatListQueryRequest} request ChatListQueryRequest message or plain object
             * @param {api.core.Chat.QueryCallback} callback Node-style callback called with the error, if any, and ChatListQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Chat.prototype.query = function query(request, callback) {
                return this.rpcCall(query, $root.api.core.ChatListQueryRequest, $root.api.core.ChatListQueryResponse, request, callback);
            }, "name", { value: "Query" });

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.Chat
             * @instance
             * @param {api.core.IChatListQueryRequest} request ChatListQueryRequest message or plain object
             * @returns {Promise<api.core.ChatListQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Chat#markRead}.
             * @memberof api.core.Chat
             * @typedef MarkReadCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls MarkRead.
             * @function markRead
             * @memberof api.core.Chat
             * @instance
             * @param {api.core.IChatMarkReadRequest} request ChatMarkReadRequest message or plain object
             * @param {api.core.Chat.MarkReadCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Chat.prototype.markRead = function markRead(request, callback) {
                return this.rpcCall(markRead, $root.api.core.ChatMarkReadRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "MarkRead" });

            /**
             * Calls MarkRead.
             * @function markRead
             * @memberof api.core.Chat
             * @instance
             * @param {api.core.IChatMarkReadRequest} request ChatMarkReadRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Chat#orphan}.
             * @memberof api.core.Chat
             * @typedef OrphanCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.OrphanResponse} [response] OrphanResponse
             */

            /**
             * Calls Orphan.
             * @function orphan
             * @memberof api.core.Chat
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Chat.OrphanCallback} callback Node-style callback called with the error, if any, and OrphanResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Chat.prototype.orphan = function orphan(request, callback) {
                return this.rpcCall(orphan, $root.google.protobuf.Empty, $root.api.core.OrphanResponse, request, callback);
            }, "name", { value: "Orphan" });

            /**
             * Calls Orphan.
             * @function orphan
             * @memberof api.core.Chat
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.OrphanResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Chat#markReplied}.
             * @memberof api.core.Chat
             * @typedef MarkRepliedCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls MarkReplied.
             * @function markReplied
             * @memberof api.core.Chat
             * @instance
             * @param {api.core.IMarkRepliedRequest} request MarkRepliedRequest message or plain object
             * @param {api.core.Chat.MarkRepliedCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Chat.prototype.markReplied = function markReplied(request, callback) {
                return this.rpcCall(markReplied, $root.api.core.MarkRepliedRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "MarkReplied" });

            /**
             * Calls MarkReplied.
             * @function markReplied
             * @memberof api.core.Chat
             * @instance
             * @param {api.core.IMarkRepliedRequest} request MarkRepliedRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Chat#resetChatState}.
             * @memberof api.core.Chat
             * @typedef ResetChatStateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls ResetChatState.
             * @function resetChatState
             * @memberof api.core.Chat
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Chat.ResetChatStateCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Chat.prototype.resetChatState = function resetChatState(request, callback) {
                return this.rpcCall(resetChatState, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "ResetChatState" });

            /**
             * Calls ResetChatState.
             * @function resetChatState
             * @memberof api.core.Chat
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return Chat;
        })();

        core.AssignWorkerRequest = (function() {

            /**
             * Properties of an AssignWorkerRequest.
             * @memberof api.core
             * @interface IAssignWorkerRequest
             * @property {number|null} [consultId] AssignWorkerRequest consultId
             */

            /**
             * Constructs a new AssignWorkerRequest.
             * @memberof api.core
             * @classdesc Represents an AssignWorkerRequest.
             * @implements IAssignWorkerRequest
             * @constructor
             * @param {api.core.IAssignWorkerRequest=} [properties] Properties to set
             */
            function AssignWorkerRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AssignWorkerRequest consultId.
             * @member {number} consultId
             * @memberof api.core.AssignWorkerRequest
             * @instance
             */
            AssignWorkerRequest.prototype.consultId = 0;

            /**
             * Creates a new AssignWorkerRequest instance using the specified properties.
             * @function create
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {api.core.IAssignWorkerRequest=} [properties] Properties to set
             * @returns {api.core.AssignWorkerRequest} AssignWorkerRequest instance
             */
            AssignWorkerRequest.create = function create(properties) {
                return new AssignWorkerRequest(properties);
            };

            /**
             * Encodes the specified AssignWorkerRequest message. Does not implicitly {@link api.core.AssignWorkerRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {api.core.IAssignWorkerRequest} message AssignWorkerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AssignWorkerRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.consultId != null && Object.hasOwnProperty.call(message, "consultId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.consultId);
                return writer;
            };

            /**
             * Encodes the specified AssignWorkerRequest message, length delimited. Does not implicitly {@link api.core.AssignWorkerRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {api.core.IAssignWorkerRequest} message AssignWorkerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AssignWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AssignWorkerRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AssignWorkerRequest} AssignWorkerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AssignWorkerRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AssignWorkerRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.consultId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AssignWorkerRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AssignWorkerRequest} AssignWorkerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AssignWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AssignWorkerRequest message.
             * @function verify
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AssignWorkerRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.consultId != null && message.hasOwnProperty("consultId"))
                    if (!$util.isInteger(message.consultId))
                        return "consultId: integer expected";
                return null;
            };

            /**
             * Creates an AssignWorkerRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AssignWorkerRequest} AssignWorkerRequest
             */
            AssignWorkerRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AssignWorkerRequest)
                    return object;
                let message = new $root.api.core.AssignWorkerRequest();
                if (object.consultId != null)
                    message.consultId = object.consultId >>> 0;
                return message;
            };

            /**
             * Creates a plain object from an AssignWorkerRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {api.core.AssignWorkerRequest} message AssignWorkerRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AssignWorkerRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.consultId = 0;
                if (message.consultId != null && message.hasOwnProperty("consultId"))
                    object.consultId = message.consultId;
                return object;
            };

            /**
             * Converts this AssignWorkerRequest to JSON.
             * @function toJSON
             * @memberof api.core.AssignWorkerRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AssignWorkerRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AssignWorkerRequest
             * @function getTypeUrl
             * @memberof api.core.AssignWorkerRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AssignWorkerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AssignWorkerRequest";
            };

            return AssignWorkerRequest;
        })();

        core.AssignWorkerResponse = (function() {

            /**
             * Properties of an AssignWorkerResponse.
             * @memberof api.core
             * @interface IAssignWorkerResponse
             * @property {string|null} [nick] AssignWorkerResponse nick
             * @property {string|null} [avatar] AssignWorkerResponse avatar
             * @property {number|null} [workerId] AssignWorkerResponse workerId
             */

            /**
             * Constructs a new AssignWorkerResponse.
             * @memberof api.core
             * @classdesc Represents an AssignWorkerResponse.
             * @implements IAssignWorkerResponse
             * @constructor
             * @param {api.core.IAssignWorkerResponse=} [properties] Properties to set
             */
            function AssignWorkerResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AssignWorkerResponse nick.
             * @member {string} nick
             * @memberof api.core.AssignWorkerResponse
             * @instance
             */
            AssignWorkerResponse.prototype.nick = "";

            /**
             * AssignWorkerResponse avatar.
             * @member {string} avatar
             * @memberof api.core.AssignWorkerResponse
             * @instance
             */
            AssignWorkerResponse.prototype.avatar = "";

            /**
             * AssignWorkerResponse workerId.
             * @member {number} workerId
             * @memberof api.core.AssignWorkerResponse
             * @instance
             */
            AssignWorkerResponse.prototype.workerId = 0;

            /**
             * Creates a new AssignWorkerResponse instance using the specified properties.
             * @function create
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {api.core.IAssignWorkerResponse=} [properties] Properties to set
             * @returns {api.core.AssignWorkerResponse} AssignWorkerResponse instance
             */
            AssignWorkerResponse.create = function create(properties) {
                return new AssignWorkerResponse(properties);
            };

            /**
             * Encodes the specified AssignWorkerResponse message. Does not implicitly {@link api.core.AssignWorkerResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {api.core.IAssignWorkerResponse} message AssignWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AssignWorkerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.nick != null && Object.hasOwnProperty.call(message, "nick"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.nick);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.avatar);
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified AssignWorkerResponse message, length delimited. Does not implicitly {@link api.core.AssignWorkerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {api.core.IAssignWorkerResponse} message AssignWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AssignWorkerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AssignWorkerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AssignWorkerResponse} AssignWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AssignWorkerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AssignWorkerResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.nick = reader.string();
                            break;
                        }
                    case 2: {
                            message.avatar = reader.string();
                            break;
                        }
                    case 3: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AssignWorkerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AssignWorkerResponse} AssignWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AssignWorkerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AssignWorkerResponse message.
             * @function verify
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AssignWorkerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.nick != null && message.hasOwnProperty("nick"))
                    if (!$util.isString(message.nick))
                        return "nick: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates an AssignWorkerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AssignWorkerResponse} AssignWorkerResponse
             */
            AssignWorkerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AssignWorkerResponse)
                    return object;
                let message = new $root.api.core.AssignWorkerResponse();
                if (object.nick != null)
                    message.nick = String(object.nick);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from an AssignWorkerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {api.core.AssignWorkerResponse} message AssignWorkerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AssignWorkerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.nick = "";
                    object.avatar = "";
                    object.workerId = 0;
                }
                if (message.nick != null && message.hasOwnProperty("nick"))
                    object.nick = message.nick;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this AssignWorkerResponse to JSON.
             * @function toJSON
             * @memberof api.core.AssignWorkerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AssignWorkerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AssignWorkerResponse
             * @function getTypeUrl
             * @memberof api.core.AssignWorkerResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AssignWorkerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AssignWorkerResponse";
            };

            return AssignWorkerResponse;
        })();

        core.QueryEntranceResponse = (function() {

            /**
             * Properties of a QueryEntranceResponse.
             * @memberof api.core
             * @interface IQueryEntranceResponse
             * @property {string|null} [name] QueryEntranceResponse name
             * @property {string|null} [nick] QueryEntranceResponse nick
             * @property {string|null} [avatar] QueryEntranceResponse avatar
             * @property {string|null} [guide] QueryEntranceResponse guide
             * @property {number|null} [defaultConsultId] QueryEntranceResponse defaultConsultId
             * @property {string|null} [changeDefaultTime] QueryEntranceResponse changeDefaultTime
             * @property {Array.<api.core.IConsultItem>|null} [consults] QueryEntranceResponse consults
             */

            /**
             * Constructs a new QueryEntranceResponse.
             * @memberof api.core
             * @classdesc Represents a QueryEntranceResponse.
             * @implements IQueryEntranceResponse
             * @constructor
             * @param {api.core.IQueryEntranceResponse=} [properties] Properties to set
             */
            function QueryEntranceResponse(properties) {
                this.consults = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryEntranceResponse name.
             * @member {string} name
             * @memberof api.core.QueryEntranceResponse
             * @instance
             */
            QueryEntranceResponse.prototype.name = "";

            /**
             * QueryEntranceResponse nick.
             * @member {string} nick
             * @memberof api.core.QueryEntranceResponse
             * @instance
             */
            QueryEntranceResponse.prototype.nick = "";

            /**
             * QueryEntranceResponse avatar.
             * @member {string} avatar
             * @memberof api.core.QueryEntranceResponse
             * @instance
             */
            QueryEntranceResponse.prototype.avatar = "";

            /**
             * QueryEntranceResponse guide.
             * @member {string} guide
             * @memberof api.core.QueryEntranceResponse
             * @instance
             */
            QueryEntranceResponse.prototype.guide = "";

            /**
             * QueryEntranceResponse defaultConsultId.
             * @member {number} defaultConsultId
             * @memberof api.core.QueryEntranceResponse
             * @instance
             */
            QueryEntranceResponse.prototype.defaultConsultId = 0;

            /**
             * QueryEntranceResponse changeDefaultTime.
             * @member {string} changeDefaultTime
             * @memberof api.core.QueryEntranceResponse
             * @instance
             */
            QueryEntranceResponse.prototype.changeDefaultTime = "";

            /**
             * QueryEntranceResponse consults.
             * @member {Array.<api.core.IConsultItem>} consults
             * @memberof api.core.QueryEntranceResponse
             * @instance
             */
            QueryEntranceResponse.prototype.consults = $util.emptyArray;

            /**
             * Creates a new QueryEntranceResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {api.core.IQueryEntranceResponse=} [properties] Properties to set
             * @returns {api.core.QueryEntranceResponse} QueryEntranceResponse instance
             */
            QueryEntranceResponse.create = function create(properties) {
                return new QueryEntranceResponse(properties);
            };

            /**
             * Encodes the specified QueryEntranceResponse message. Does not implicitly {@link api.core.QueryEntranceResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {api.core.IQueryEntranceResponse} message QueryEntranceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryEntranceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.nick != null && Object.hasOwnProperty.call(message, "nick"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.nick);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.avatar);
                if (message.guide != null && Object.hasOwnProperty.call(message, "guide"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.guide);
                if (message.defaultConsultId != null && Object.hasOwnProperty.call(message, "defaultConsultId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.defaultConsultId);
                if (message.changeDefaultTime != null && Object.hasOwnProperty.call(message, "changeDefaultTime"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.changeDefaultTime);
                if (message.consults != null && message.consults.length)
                    for (let i = 0; i < message.consults.length; ++i)
                        $root.api.core.ConsultItem.encode(message.consults[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryEntranceResponse message, length delimited. Does not implicitly {@link api.core.QueryEntranceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {api.core.IQueryEntranceResponse} message QueryEntranceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryEntranceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryEntranceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryEntranceResponse} QueryEntranceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryEntranceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryEntranceResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.nick = reader.string();
                            break;
                        }
                    case 3: {
                            message.avatar = reader.string();
                            break;
                        }
                    case 4: {
                            message.guide = reader.string();
                            break;
                        }
                    case 5: {
                            message.defaultConsultId = reader.int32();
                            break;
                        }
                    case 6: {
                            message.changeDefaultTime = reader.string();
                            break;
                        }
                    case 7: {
                            if (!(message.consults && message.consults.length))
                                message.consults = [];
                            message.consults.push($root.api.core.ConsultItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryEntranceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryEntranceResponse} QueryEntranceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryEntranceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryEntranceResponse message.
             * @function verify
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryEntranceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.nick != null && message.hasOwnProperty("nick"))
                    if (!$util.isString(message.nick))
                        return "nick: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                if (message.guide != null && message.hasOwnProperty("guide"))
                    if (!$util.isString(message.guide))
                        return "guide: string expected";
                if (message.defaultConsultId != null && message.hasOwnProperty("defaultConsultId"))
                    if (!$util.isInteger(message.defaultConsultId))
                        return "defaultConsultId: integer expected";
                if (message.changeDefaultTime != null && message.hasOwnProperty("changeDefaultTime"))
                    if (!$util.isString(message.changeDefaultTime))
                        return "changeDefaultTime: string expected";
                if (message.consults != null && message.hasOwnProperty("consults")) {
                    if (!Array.isArray(message.consults))
                        return "consults: array expected";
                    for (let i = 0; i < message.consults.length; ++i) {
                        let error = $root.api.core.ConsultItem.verify(message.consults[i]);
                        if (error)
                            return "consults." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QueryEntranceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryEntranceResponse} QueryEntranceResponse
             */
            QueryEntranceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryEntranceResponse)
                    return object;
                let message = new $root.api.core.QueryEntranceResponse();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.nick != null)
                    message.nick = String(object.nick);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                if (object.guide != null)
                    message.guide = String(object.guide);
                if (object.defaultConsultId != null)
                    message.defaultConsultId = object.defaultConsultId | 0;
                if (object.changeDefaultTime != null)
                    message.changeDefaultTime = String(object.changeDefaultTime);
                if (object.consults) {
                    if (!Array.isArray(object.consults))
                        throw TypeError(".api.core.QueryEntranceResponse.consults: array expected");
                    message.consults = [];
                    for (let i = 0; i < object.consults.length; ++i) {
                        if (typeof object.consults[i] !== "object")
                            throw TypeError(".api.core.QueryEntranceResponse.consults: object expected");
                        message.consults[i] = $root.api.core.ConsultItem.fromObject(object.consults[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryEntranceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {api.core.QueryEntranceResponse} message QueryEntranceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryEntranceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.consults = [];
                if (options.defaults) {
                    object.name = "";
                    object.nick = "";
                    object.avatar = "";
                    object.guide = "";
                    object.defaultConsultId = 0;
                    object.changeDefaultTime = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.nick != null && message.hasOwnProperty("nick"))
                    object.nick = message.nick;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                if (message.guide != null && message.hasOwnProperty("guide"))
                    object.guide = message.guide;
                if (message.defaultConsultId != null && message.hasOwnProperty("defaultConsultId"))
                    object.defaultConsultId = message.defaultConsultId;
                if (message.changeDefaultTime != null && message.hasOwnProperty("changeDefaultTime"))
                    object.changeDefaultTime = message.changeDefaultTime;
                if (message.consults && message.consults.length) {
                    object.consults = [];
                    for (let j = 0; j < message.consults.length; ++j)
                        object.consults[j] = $root.api.core.ConsultItem.toObject(message.consults[j], options);
                }
                return object;
            };

            /**
             * Converts this QueryEntranceResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryEntranceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryEntranceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryEntranceResponse
             * @function getTypeUrl
             * @memberof api.core.QueryEntranceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryEntranceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryEntranceResponse";
            };

            return QueryEntranceResponse;
        })();

        core.QueryAutoReplyResponse = (function() {

            /**
             * Properties of a QueryAutoReplyResponse.
             * @memberof api.core
             * @interface IQueryAutoReplyResponse
             * @property {api.common.IAutoReplyItem|null} [autoReplyItem] QueryAutoReplyResponse autoReplyItem
             */

            /**
             * Constructs a new QueryAutoReplyResponse.
             * @memberof api.core
             * @classdesc Represents a QueryAutoReplyResponse.
             * @implements IQueryAutoReplyResponse
             * @constructor
             * @param {api.core.IQueryAutoReplyResponse=} [properties] Properties to set
             */
            function QueryAutoReplyResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryAutoReplyResponse autoReplyItem.
             * @member {api.common.IAutoReplyItem|null|undefined} autoReplyItem
             * @memberof api.core.QueryAutoReplyResponse
             * @instance
             */
            QueryAutoReplyResponse.prototype.autoReplyItem = null;

            /**
             * Creates a new QueryAutoReplyResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {api.core.IQueryAutoReplyResponse=} [properties] Properties to set
             * @returns {api.core.QueryAutoReplyResponse} QueryAutoReplyResponse instance
             */
            QueryAutoReplyResponse.create = function create(properties) {
                return new QueryAutoReplyResponse(properties);
            };

            /**
             * Encodes the specified QueryAutoReplyResponse message. Does not implicitly {@link api.core.QueryAutoReplyResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {api.core.IQueryAutoReplyResponse} message QueryAutoReplyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryAutoReplyResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.autoReplyItem != null && Object.hasOwnProperty.call(message, "autoReplyItem"))
                    $root.api.common.AutoReplyItem.encode(message.autoReplyItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryAutoReplyResponse message, length delimited. Does not implicitly {@link api.core.QueryAutoReplyResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {api.core.IQueryAutoReplyResponse} message QueryAutoReplyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryAutoReplyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryAutoReplyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryAutoReplyResponse} QueryAutoReplyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryAutoReplyResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryAutoReplyResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.autoReplyItem = $root.api.common.AutoReplyItem.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryAutoReplyResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryAutoReplyResponse} QueryAutoReplyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryAutoReplyResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryAutoReplyResponse message.
             * @function verify
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryAutoReplyResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.autoReplyItem != null && message.hasOwnProperty("autoReplyItem")) {
                    let error = $root.api.common.AutoReplyItem.verify(message.autoReplyItem);
                    if (error)
                        return "autoReplyItem." + error;
                }
                return null;
            };

            /**
             * Creates a QueryAutoReplyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryAutoReplyResponse} QueryAutoReplyResponse
             */
            QueryAutoReplyResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryAutoReplyResponse)
                    return object;
                let message = new $root.api.core.QueryAutoReplyResponse();
                if (object.autoReplyItem != null) {
                    if (typeof object.autoReplyItem !== "object")
                        throw TypeError(".api.core.QueryAutoReplyResponse.autoReplyItem: object expected");
                    message.autoReplyItem = $root.api.common.AutoReplyItem.fromObject(object.autoReplyItem);
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryAutoReplyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {api.core.QueryAutoReplyResponse} message QueryAutoReplyResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryAutoReplyResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.autoReplyItem = null;
                if (message.autoReplyItem != null && message.hasOwnProperty("autoReplyItem"))
                    object.autoReplyItem = $root.api.common.AutoReplyItem.toObject(message.autoReplyItem, options);
                return object;
            };

            /**
             * Converts this QueryAutoReplyResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryAutoReplyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryAutoReplyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryAutoReplyResponse
             * @function getTypeUrl
             * @memberof api.core.QueryAutoReplyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryAutoReplyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryAutoReplyResponse";
            };

            return QueryAutoReplyResponse;
        })();

        core.BindWorkerResponse = (function() {

            /**
             * Properties of a BindWorkerResponse.
             * @memberof api.core
             * @interface IBindWorkerResponse
             * @property {string|null} [nick] BindWorkerResponse nick
             * @property {string|null} [avatar] BindWorkerResponse avatar
             * @property {number|null} [workerId] BindWorkerResponse workerId
             */

            /**
             * Constructs a new BindWorkerResponse.
             * @memberof api.core
             * @classdesc Represents a BindWorkerResponse.
             * @implements IBindWorkerResponse
             * @constructor
             * @param {api.core.IBindWorkerResponse=} [properties] Properties to set
             */
            function BindWorkerResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BindWorkerResponse nick.
             * @member {string} nick
             * @memberof api.core.BindWorkerResponse
             * @instance
             */
            BindWorkerResponse.prototype.nick = "";

            /**
             * BindWorkerResponse avatar.
             * @member {string} avatar
             * @memberof api.core.BindWorkerResponse
             * @instance
             */
            BindWorkerResponse.prototype.avatar = "";

            /**
             * BindWorkerResponse workerId.
             * @member {number} workerId
             * @memberof api.core.BindWorkerResponse
             * @instance
             */
            BindWorkerResponse.prototype.workerId = 0;

            /**
             * Creates a new BindWorkerResponse instance using the specified properties.
             * @function create
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {api.core.IBindWorkerResponse=} [properties] Properties to set
             * @returns {api.core.BindWorkerResponse} BindWorkerResponse instance
             */
            BindWorkerResponse.create = function create(properties) {
                return new BindWorkerResponse(properties);
            };

            /**
             * Encodes the specified BindWorkerResponse message. Does not implicitly {@link api.core.BindWorkerResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {api.core.IBindWorkerResponse} message BindWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BindWorkerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.nick != null && Object.hasOwnProperty.call(message, "nick"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.nick);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.avatar);
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified BindWorkerResponse message, length delimited. Does not implicitly {@link api.core.BindWorkerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {api.core.IBindWorkerResponse} message BindWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BindWorkerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BindWorkerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.BindWorkerResponse} BindWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BindWorkerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.BindWorkerResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.nick = reader.string();
                            break;
                        }
                    case 2: {
                            message.avatar = reader.string();
                            break;
                        }
                    case 3: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BindWorkerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.BindWorkerResponse} BindWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BindWorkerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BindWorkerResponse message.
             * @function verify
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BindWorkerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.nick != null && message.hasOwnProperty("nick"))
                    if (!$util.isString(message.nick))
                        return "nick: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a BindWorkerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.BindWorkerResponse} BindWorkerResponse
             */
            BindWorkerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.BindWorkerResponse)
                    return object;
                let message = new $root.api.core.BindWorkerResponse();
                if (object.nick != null)
                    message.nick = String(object.nick);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a BindWorkerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {api.core.BindWorkerResponse} message BindWorkerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BindWorkerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.nick = "";
                    object.avatar = "";
                    object.workerId = 0;
                }
                if (message.nick != null && message.hasOwnProperty("nick"))
                    object.nick = message.nick;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this BindWorkerResponse to JSON.
             * @function toJSON
             * @memberof api.core.BindWorkerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BindWorkerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BindWorkerResponse
             * @function getTypeUrl
             * @memberof api.core.BindWorkerResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BindWorkerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.BindWorkerResponse";
            };

            return BindWorkerResponse;
        })();

        core.ConsultItem = (function() {

            /**
             * Properties of a ConsultItem.
             * @memberof api.core
             * @interface IConsultItem
             * @property {number|null} [consultId] ConsultItem consultId
             * @property {string|null} [name] ConsultItem name
             * @property {string|null} [guide] ConsultItem guide
             */

            /**
             * Constructs a new ConsultItem.
             * @memberof api.core
             * @classdesc Represents a ConsultItem.
             * @implements IConsultItem
             * @constructor
             * @param {api.core.IConsultItem=} [properties] Properties to set
             */
            function ConsultItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConsultItem consultId.
             * @member {number} consultId
             * @memberof api.core.ConsultItem
             * @instance
             */
            ConsultItem.prototype.consultId = 0;

            /**
             * ConsultItem name.
             * @member {string} name
             * @memberof api.core.ConsultItem
             * @instance
             */
            ConsultItem.prototype.name = "";

            /**
             * ConsultItem guide.
             * @member {string} guide
             * @memberof api.core.ConsultItem
             * @instance
             */
            ConsultItem.prototype.guide = "";

            /**
             * Creates a new ConsultItem instance using the specified properties.
             * @function create
             * @memberof api.core.ConsultItem
             * @static
             * @param {api.core.IConsultItem=} [properties] Properties to set
             * @returns {api.core.ConsultItem} ConsultItem instance
             */
            ConsultItem.create = function create(properties) {
                return new ConsultItem(properties);
            };

            /**
             * Encodes the specified ConsultItem message. Does not implicitly {@link api.core.ConsultItem.verify|verify} messages.
             * @function encode
             * @memberof api.core.ConsultItem
             * @static
             * @param {api.core.IConsultItem} message ConsultItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConsultItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.consultId != null && Object.hasOwnProperty.call(message, "consultId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.consultId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.guide != null && Object.hasOwnProperty.call(message, "guide"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.guide);
                return writer;
            };

            /**
             * Encodes the specified ConsultItem message, length delimited. Does not implicitly {@link api.core.ConsultItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.ConsultItem
             * @static
             * @param {api.core.IConsultItem} message ConsultItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConsultItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ConsultItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.ConsultItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.ConsultItem} ConsultItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConsultItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.ConsultItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.consultId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.guide = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ConsultItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.ConsultItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.ConsultItem} ConsultItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConsultItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ConsultItem message.
             * @function verify
             * @memberof api.core.ConsultItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConsultItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.consultId != null && message.hasOwnProperty("consultId"))
                    if (!$util.isInteger(message.consultId))
                        return "consultId: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.guide != null && message.hasOwnProperty("guide"))
                    if (!$util.isString(message.guide))
                        return "guide: string expected";
                return null;
            };

            /**
             * Creates a ConsultItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.ConsultItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.ConsultItem} ConsultItem
             */
            ConsultItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.ConsultItem)
                    return object;
                let message = new $root.api.core.ConsultItem();
                if (object.consultId != null)
                    message.consultId = object.consultId >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.guide != null)
                    message.guide = String(object.guide);
                return message;
            };

            /**
             * Creates a plain object from a ConsultItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.ConsultItem
             * @static
             * @param {api.core.ConsultItem} message ConsultItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConsultItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.consultId = 0;
                    object.name = "";
                    object.guide = "";
                }
                if (message.consultId != null && message.hasOwnProperty("consultId"))
                    object.consultId = message.consultId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.guide != null && message.hasOwnProperty("guide"))
                    object.guide = message.guide;
                return object;
            };

            /**
             * Converts this ConsultItem to JSON.
             * @function toJSON
             * @memberof api.core.ConsultItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConsultItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ConsultItem
             * @function getTypeUrl
             * @memberof api.core.ConsultItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConsultItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.ConsultItem";
            };

            return ConsultItem;
        })();

        core.CreateAnonUserRequest = (function() {

            /**
             * Properties of a CreateAnonUserRequest.
             * @memberof api.core
             * @interface ICreateAnonUserRequest
             * @property {number|null} [tenantId] CreateAnonUserRequest tenantId
             * @property {number|null} [entranceId] CreateAnonUserRequest entranceId
             * @property {string|null} [ip] CreateAnonUserRequest ip
             */

            /**
             * Constructs a new CreateAnonUserRequest.
             * @memberof api.core
             * @classdesc Represents a CreateAnonUserRequest.
             * @implements ICreateAnonUserRequest
             * @constructor
             * @param {api.core.ICreateAnonUserRequest=} [properties] Properties to set
             */
            function CreateAnonUserRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateAnonUserRequest tenantId.
             * @member {number} tenantId
             * @memberof api.core.CreateAnonUserRequest
             * @instance
             */
            CreateAnonUserRequest.prototype.tenantId = 0;

            /**
             * CreateAnonUserRequest entranceId.
             * @member {number} entranceId
             * @memberof api.core.CreateAnonUserRequest
             * @instance
             */
            CreateAnonUserRequest.prototype.entranceId = 0;

            /**
             * CreateAnonUserRequest ip.
             * @member {string} ip
             * @memberof api.core.CreateAnonUserRequest
             * @instance
             */
            CreateAnonUserRequest.prototype.ip = "";

            /**
             * Creates a new CreateAnonUserRequest instance using the specified properties.
             * @function create
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {api.core.ICreateAnonUserRequest=} [properties] Properties to set
             * @returns {api.core.CreateAnonUserRequest} CreateAnonUserRequest instance
             */
            CreateAnonUserRequest.create = function create(properties) {
                return new CreateAnonUserRequest(properties);
            };

            /**
             * Encodes the specified CreateAnonUserRequest message. Does not implicitly {@link api.core.CreateAnonUserRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {api.core.ICreateAnonUserRequest} message CreateAnonUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAnonUserRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tenantId);
                if (message.entranceId != null && Object.hasOwnProperty.call(message, "entranceId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.entranceId);
                if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.ip);
                return writer;
            };

            /**
             * Encodes the specified CreateAnonUserRequest message, length delimited. Does not implicitly {@link api.core.CreateAnonUserRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {api.core.ICreateAnonUserRequest} message CreateAnonUserRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAnonUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateAnonUserRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateAnonUserRequest} CreateAnonUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAnonUserRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateAnonUserRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tenantId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.entranceId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.ip = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateAnonUserRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateAnonUserRequest} CreateAnonUserRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAnonUserRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateAnonUserRequest message.
             * @function verify
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateAnonUserRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    if (!$util.isInteger(message.tenantId))
                        return "tenantId: integer expected";
                if (message.entranceId != null && message.hasOwnProperty("entranceId"))
                    if (!$util.isInteger(message.entranceId))
                        return "entranceId: integer expected";
                if (message.ip != null && message.hasOwnProperty("ip"))
                    if (!$util.isString(message.ip))
                        return "ip: string expected";
                return null;
            };

            /**
             * Creates a CreateAnonUserRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateAnonUserRequest} CreateAnonUserRequest
             */
            CreateAnonUserRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateAnonUserRequest)
                    return object;
                let message = new $root.api.core.CreateAnonUserRequest();
                if (object.tenantId != null)
                    message.tenantId = object.tenantId | 0;
                if (object.entranceId != null)
                    message.entranceId = object.entranceId | 0;
                if (object.ip != null)
                    message.ip = String(object.ip);
                return message;
            };

            /**
             * Creates a plain object from a CreateAnonUserRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {api.core.CreateAnonUserRequest} message CreateAnonUserRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAnonUserRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.tenantId = 0;
                    object.entranceId = 0;
                    object.ip = "";
                }
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    object.tenantId = message.tenantId;
                if (message.entranceId != null && message.hasOwnProperty("entranceId"))
                    object.entranceId = message.entranceId;
                if (message.ip != null && message.hasOwnProperty("ip"))
                    object.ip = message.ip;
                return object;
            };

            /**
             * Converts this CreateAnonUserRequest to JSON.
             * @function toJSON
             * @memberof api.core.CreateAnonUserRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAnonUserRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateAnonUserRequest
             * @function getTypeUrl
             * @memberof api.core.CreateAnonUserRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateAnonUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateAnonUserRequest";
            };

            return CreateAnonUserRequest;
        })();

        core.CreateAnonUserResponse = (function() {

            /**
             * Properties of a CreateAnonUserResponse.
             * @memberof api.core
             * @interface ICreateAnonUserResponse
             * @property {string|null} [token] CreateAnonUserResponse token
             * @property {string|null} [name] CreateAnonUserResponse name
             * @property {string|null} [avatar] CreateAnonUserResponse avatar
             */

            /**
             * Constructs a new CreateAnonUserResponse.
             * @memberof api.core
             * @classdesc Represents a CreateAnonUserResponse.
             * @implements ICreateAnonUserResponse
             * @constructor
             * @param {api.core.ICreateAnonUserResponse=} [properties] Properties to set
             */
            function CreateAnonUserResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateAnonUserResponse token.
             * @member {string} token
             * @memberof api.core.CreateAnonUserResponse
             * @instance
             */
            CreateAnonUserResponse.prototype.token = "";

            /**
             * CreateAnonUserResponse name.
             * @member {string} name
             * @memberof api.core.CreateAnonUserResponse
             * @instance
             */
            CreateAnonUserResponse.prototype.name = "";

            /**
             * CreateAnonUserResponse avatar.
             * @member {string} avatar
             * @memberof api.core.CreateAnonUserResponse
             * @instance
             */
            CreateAnonUserResponse.prototype.avatar = "";

            /**
             * Creates a new CreateAnonUserResponse instance using the specified properties.
             * @function create
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {api.core.ICreateAnonUserResponse=} [properties] Properties to set
             * @returns {api.core.CreateAnonUserResponse} CreateAnonUserResponse instance
             */
            CreateAnonUserResponse.create = function create(properties) {
                return new CreateAnonUserResponse(properties);
            };

            /**
             * Encodes the specified CreateAnonUserResponse message. Does not implicitly {@link api.core.CreateAnonUserResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {api.core.ICreateAnonUserResponse} message CreateAnonUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAnonUserResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.avatar);
                return writer;
            };

            /**
             * Encodes the specified CreateAnonUserResponse message, length delimited. Does not implicitly {@link api.core.CreateAnonUserResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {api.core.ICreateAnonUserResponse} message CreateAnonUserResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAnonUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateAnonUserResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateAnonUserResponse} CreateAnonUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAnonUserResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateAnonUserResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.token = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.avatar = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateAnonUserResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateAnonUserResponse} CreateAnonUserResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAnonUserResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateAnonUserResponse message.
             * @function verify
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateAnonUserResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                return null;
            };

            /**
             * Creates a CreateAnonUserResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateAnonUserResponse} CreateAnonUserResponse
             */
            CreateAnonUserResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateAnonUserResponse)
                    return object;
                let message = new $root.api.core.CreateAnonUserResponse();
                if (object.token != null)
                    message.token = String(object.token);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                return message;
            };

            /**
             * Creates a plain object from a CreateAnonUserResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {api.core.CreateAnonUserResponse} message CreateAnonUserResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAnonUserResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.token = "";
                    object.name = "";
                    object.avatar = "";
                }
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                return object;
            };

            /**
             * Converts this CreateAnonUserResponse to JSON.
             * @function toJSON
             * @memberof api.core.CreateAnonUserResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAnonUserResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateAnonUserResponse
             * @function getTypeUrl
             * @memberof api.core.CreateAnonUserResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateAnonUserResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateAnonUserResponse";
            };

            return CreateAnonUserResponse;
        })();

        core.CustomerDataRequest = (function() {

            /**
             * Properties of a CustomerDataRequest.
             * @memberof api.core
             * @interface ICustomerDataRequest
             * @property {Long|null} [chatId] CustomerDataRequest chatId
             */

            /**
             * Constructs a new CustomerDataRequest.
             * @memberof api.core
             * @classdesc Represents a CustomerDataRequest.
             * @implements ICustomerDataRequest
             * @constructor
             * @param {api.core.ICustomerDataRequest=} [properties] Properties to set
             */
            function CustomerDataRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CustomerDataRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.CustomerDataRequest
             * @instance
             */
            CustomerDataRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new CustomerDataRequest instance using the specified properties.
             * @function create
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {api.core.ICustomerDataRequest=} [properties] Properties to set
             * @returns {api.core.CustomerDataRequest} CustomerDataRequest instance
             */
            CustomerDataRequest.create = function create(properties) {
                return new CustomerDataRequest(properties);
            };

            /**
             * Encodes the specified CustomerDataRequest message. Does not implicitly {@link api.core.CustomerDataRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {api.core.ICustomerDataRequest} message CustomerDataRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomerDataRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                return writer;
            };

            /**
             * Encodes the specified CustomerDataRequest message, length delimited. Does not implicitly {@link api.core.CustomerDataRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {api.core.ICustomerDataRequest} message CustomerDataRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomerDataRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CustomerDataRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CustomerDataRequest} CustomerDataRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomerDataRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CustomerDataRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CustomerDataRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CustomerDataRequest} CustomerDataRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomerDataRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CustomerDataRequest message.
             * @function verify
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CustomerDataRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a CustomerDataRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CustomerDataRequest} CustomerDataRequest
             */
            CustomerDataRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CustomerDataRequest)
                    return object;
                let message = new $root.api.core.CustomerDataRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a CustomerDataRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {api.core.CustomerDataRequest} message CustomerDataRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CustomerDataRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                return object;
            };

            /**
             * Converts this CustomerDataRequest to JSON.
             * @function toJSON
             * @memberof api.core.CustomerDataRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CustomerDataRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CustomerDataRequest
             * @function getTypeUrl
             * @memberof api.core.CustomerDataRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CustomerDataRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CustomerDataRequest";
            };

            return CustomerDataRequest;
        })();

        core.CustomerDataResponse = (function() {

            /**
             * Properties of a CustomerDataResponse.
             * @memberof api.core
             * @interface ICustomerDataResponse
             * @property {string|null} [name] CustomerDataResponse name
             * @property {string|null} [userSource] CustomerDataResponse userSource
             * @property {string|null} [ipAddress] CustomerDataResponse ipAddress
             * @property {google.protobuf.ITimestamp|null} [firstContactTime] CustomerDataResponse firstContactTime
             * @property {google.protobuf.ITimestamp|null} [lastContactTime] CustomerDataResponse lastContactTime
             * @property {string|null} [userName] CustomerDataResponse userName
             * @property {string|null} [userAppeal] CustomerDataResponse userAppeal
             */

            /**
             * Constructs a new CustomerDataResponse.
             * @memberof api.core
             * @classdesc Represents a CustomerDataResponse.
             * @implements ICustomerDataResponse
             * @constructor
             * @param {api.core.ICustomerDataResponse=} [properties] Properties to set
             */
            function CustomerDataResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CustomerDataResponse name.
             * @member {string} name
             * @memberof api.core.CustomerDataResponse
             * @instance
             */
            CustomerDataResponse.prototype.name = "";

            /**
             * CustomerDataResponse userSource.
             * @member {string} userSource
             * @memberof api.core.CustomerDataResponse
             * @instance
             */
            CustomerDataResponse.prototype.userSource = "";

            /**
             * CustomerDataResponse ipAddress.
             * @member {string} ipAddress
             * @memberof api.core.CustomerDataResponse
             * @instance
             */
            CustomerDataResponse.prototype.ipAddress = "";

            /**
             * CustomerDataResponse firstContactTime.
             * @member {google.protobuf.ITimestamp|null|undefined} firstContactTime
             * @memberof api.core.CustomerDataResponse
             * @instance
             */
            CustomerDataResponse.prototype.firstContactTime = null;

            /**
             * CustomerDataResponse lastContactTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastContactTime
             * @memberof api.core.CustomerDataResponse
             * @instance
             */
            CustomerDataResponse.prototype.lastContactTime = null;

            /**
             * CustomerDataResponse userName.
             * @member {string} userName
             * @memberof api.core.CustomerDataResponse
             * @instance
             */
            CustomerDataResponse.prototype.userName = "";

            /**
             * CustomerDataResponse userAppeal.
             * @member {string} userAppeal
             * @memberof api.core.CustomerDataResponse
             * @instance
             */
            CustomerDataResponse.prototype.userAppeal = "";

            /**
             * Creates a new CustomerDataResponse instance using the specified properties.
             * @function create
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {api.core.ICustomerDataResponse=} [properties] Properties to set
             * @returns {api.core.CustomerDataResponse} CustomerDataResponse instance
             */
            CustomerDataResponse.create = function create(properties) {
                return new CustomerDataResponse(properties);
            };

            /**
             * Encodes the specified CustomerDataResponse message. Does not implicitly {@link api.core.CustomerDataResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {api.core.ICustomerDataResponse} message CustomerDataResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomerDataResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.userSource != null && Object.hasOwnProperty.call(message, "userSource"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.userSource);
                if (message.ipAddress != null && Object.hasOwnProperty.call(message, "ipAddress"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.ipAddress);
                if (message.firstContactTime != null && Object.hasOwnProperty.call(message, "firstContactTime"))
                    $root.google.protobuf.Timestamp.encode(message.firstContactTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.lastContactTime != null && Object.hasOwnProperty.call(message, "lastContactTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastContactTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.userName);
                if (message.userAppeal != null && Object.hasOwnProperty.call(message, "userAppeal"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.userAppeal);
                return writer;
            };

            /**
             * Encodes the specified CustomerDataResponse message, length delimited. Does not implicitly {@link api.core.CustomerDataResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {api.core.ICustomerDataResponse} message CustomerDataResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomerDataResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CustomerDataResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CustomerDataResponse} CustomerDataResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomerDataResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CustomerDataResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.userSource = reader.string();
                            break;
                        }
                    case 3: {
                            message.ipAddress = reader.string();
                            break;
                        }
                    case 4: {
                            message.firstContactTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.lastContactTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.userName = reader.string();
                            break;
                        }
                    case 7: {
                            message.userAppeal = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CustomerDataResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CustomerDataResponse} CustomerDataResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomerDataResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CustomerDataResponse message.
             * @function verify
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CustomerDataResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.userSource != null && message.hasOwnProperty("userSource"))
                    if (!$util.isString(message.userSource))
                        return "userSource: string expected";
                if (message.ipAddress != null && message.hasOwnProperty("ipAddress"))
                    if (!$util.isString(message.ipAddress))
                        return "ipAddress: string expected";
                if (message.firstContactTime != null && message.hasOwnProperty("firstContactTime")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.firstContactTime);
                    if (error)
                        return "firstContactTime." + error;
                }
                if (message.lastContactTime != null && message.hasOwnProperty("lastContactTime")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.lastContactTime);
                    if (error)
                        return "lastContactTime." + error;
                }
                if (message.userName != null && message.hasOwnProperty("userName"))
                    if (!$util.isString(message.userName))
                        return "userName: string expected";
                if (message.userAppeal != null && message.hasOwnProperty("userAppeal"))
                    if (!$util.isString(message.userAppeal))
                        return "userAppeal: string expected";
                return null;
            };

            /**
             * Creates a CustomerDataResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CustomerDataResponse} CustomerDataResponse
             */
            CustomerDataResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CustomerDataResponse)
                    return object;
                let message = new $root.api.core.CustomerDataResponse();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.userSource != null)
                    message.userSource = String(object.userSource);
                if (object.ipAddress != null)
                    message.ipAddress = String(object.ipAddress);
                if (object.firstContactTime != null) {
                    if (typeof object.firstContactTime !== "object")
                        throw TypeError(".api.core.CustomerDataResponse.firstContactTime: object expected");
                    message.firstContactTime = $root.google.protobuf.Timestamp.fromObject(object.firstContactTime);
                }
                if (object.lastContactTime != null) {
                    if (typeof object.lastContactTime !== "object")
                        throw TypeError(".api.core.CustomerDataResponse.lastContactTime: object expected");
                    message.lastContactTime = $root.google.protobuf.Timestamp.fromObject(object.lastContactTime);
                }
                if (object.userName != null)
                    message.userName = String(object.userName);
                if (object.userAppeal != null)
                    message.userAppeal = String(object.userAppeal);
                return message;
            };

            /**
             * Creates a plain object from a CustomerDataResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {api.core.CustomerDataResponse} message CustomerDataResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CustomerDataResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.userSource = "";
                    object.ipAddress = "";
                    object.firstContactTime = null;
                    object.lastContactTime = null;
                    object.userName = "";
                    object.userAppeal = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.userSource != null && message.hasOwnProperty("userSource"))
                    object.userSource = message.userSource;
                if (message.ipAddress != null && message.hasOwnProperty("ipAddress"))
                    object.ipAddress = message.ipAddress;
                if (message.firstContactTime != null && message.hasOwnProperty("firstContactTime"))
                    object.firstContactTime = $root.google.protobuf.Timestamp.toObject(message.firstContactTime, options);
                if (message.lastContactTime != null && message.hasOwnProperty("lastContactTime"))
                    object.lastContactTime = $root.google.protobuf.Timestamp.toObject(message.lastContactTime, options);
                if (message.userName != null && message.hasOwnProperty("userName"))
                    object.userName = message.userName;
                if (message.userAppeal != null && message.hasOwnProperty("userAppeal"))
                    object.userAppeal = message.userAppeal;
                return object;
            };

            /**
             * Converts this CustomerDataResponse to JSON.
             * @function toJSON
             * @memberof api.core.CustomerDataResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CustomerDataResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CustomerDataResponse
             * @function getTypeUrl
             * @memberof api.core.CustomerDataResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CustomerDataResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CustomerDataResponse";
            };

            return CustomerDataResponse;
        })();

        core.QueryWorkerRequest = (function() {

            /**
             * Properties of a QueryWorkerRequest.
             * @memberof api.core
             * @interface IQueryWorkerRequest
             * @property {number|null} [workerId] QueryWorkerRequest workerId
             */

            /**
             * Constructs a new QueryWorkerRequest.
             * @memberof api.core
             * @classdesc Represents a QueryWorkerRequest.
             * @implements IQueryWorkerRequest
             * @constructor
             * @param {api.core.IQueryWorkerRequest=} [properties] Properties to set
             */
            function QueryWorkerRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryWorkerRequest workerId.
             * @member {number} workerId
             * @memberof api.core.QueryWorkerRequest
             * @instance
             */
            QueryWorkerRequest.prototype.workerId = 0;

            /**
             * Creates a new QueryWorkerRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {api.core.IQueryWorkerRequest=} [properties] Properties to set
             * @returns {api.core.QueryWorkerRequest} QueryWorkerRequest instance
             */
            QueryWorkerRequest.create = function create(properties) {
                return new QueryWorkerRequest(properties);
            };

            /**
             * Encodes the specified QueryWorkerRequest message. Does not implicitly {@link api.core.QueryWorkerRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {api.core.IQueryWorkerRequest} message QueryWorkerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryWorkerRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified QueryWorkerRequest message, length delimited. Does not implicitly {@link api.core.QueryWorkerRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {api.core.IQueryWorkerRequest} message QueryWorkerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryWorkerRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryWorkerRequest} QueryWorkerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryWorkerRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryWorkerRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryWorkerRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryWorkerRequest} QueryWorkerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryWorkerRequest message.
             * @function verify
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryWorkerRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a QueryWorkerRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryWorkerRequest} QueryWorkerRequest
             */
            QueryWorkerRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryWorkerRequest)
                    return object;
                let message = new $root.api.core.QueryWorkerRequest();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a QueryWorkerRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {api.core.QueryWorkerRequest} message QueryWorkerRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryWorkerRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.workerId = 0;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this QueryWorkerRequest to JSON.
             * @function toJSON
             * @memberof api.core.QueryWorkerRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryWorkerRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryWorkerRequest
             * @function getTypeUrl
             * @memberof api.core.QueryWorkerRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryWorkerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryWorkerRequest";
            };

            return QueryWorkerRequest;
        })();

        core.QueryWorkerResponse = (function() {

            /**
             * Properties of a QueryWorkerResponse.
             * @memberof api.core
             * @interface IQueryWorkerResponse
             * @property {string|null} [workerName] QueryWorkerResponse workerName
             * @property {string|null} [workerAvatar] QueryWorkerResponse workerAvatar
             */

            /**
             * Constructs a new QueryWorkerResponse.
             * @memberof api.core
             * @classdesc Represents a QueryWorkerResponse.
             * @implements IQueryWorkerResponse
             * @constructor
             * @param {api.core.IQueryWorkerResponse=} [properties] Properties to set
             */
            function QueryWorkerResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryWorkerResponse workerName.
             * @member {string} workerName
             * @memberof api.core.QueryWorkerResponse
             * @instance
             */
            QueryWorkerResponse.prototype.workerName = "";

            /**
             * QueryWorkerResponse workerAvatar.
             * @member {string} workerAvatar
             * @memberof api.core.QueryWorkerResponse
             * @instance
             */
            QueryWorkerResponse.prototype.workerAvatar = "";

            /**
             * Creates a new QueryWorkerResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {api.core.IQueryWorkerResponse=} [properties] Properties to set
             * @returns {api.core.QueryWorkerResponse} QueryWorkerResponse instance
             */
            QueryWorkerResponse.create = function create(properties) {
                return new QueryWorkerResponse(properties);
            };

            /**
             * Encodes the specified QueryWorkerResponse message. Does not implicitly {@link api.core.QueryWorkerResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {api.core.IQueryWorkerResponse} message QueryWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryWorkerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerName != null && Object.hasOwnProperty.call(message, "workerName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.workerName);
                if (message.workerAvatar != null && Object.hasOwnProperty.call(message, "workerAvatar"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.workerAvatar);
                return writer;
            };

            /**
             * Encodes the specified QueryWorkerResponse message, length delimited. Does not implicitly {@link api.core.QueryWorkerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {api.core.IQueryWorkerResponse} message QueryWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryWorkerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryWorkerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryWorkerResponse} QueryWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryWorkerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryWorkerResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerName = reader.string();
                            break;
                        }
                    case 2: {
                            message.workerAvatar = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryWorkerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryWorkerResponse} QueryWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryWorkerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryWorkerResponse message.
             * @function verify
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryWorkerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerName != null && message.hasOwnProperty("workerName"))
                    if (!$util.isString(message.workerName))
                        return "workerName: string expected";
                if (message.workerAvatar != null && message.hasOwnProperty("workerAvatar"))
                    if (!$util.isString(message.workerAvatar))
                        return "workerAvatar: string expected";
                return null;
            };

            /**
             * Creates a QueryWorkerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryWorkerResponse} QueryWorkerResponse
             */
            QueryWorkerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryWorkerResponse)
                    return object;
                let message = new $root.api.core.QueryWorkerResponse();
                if (object.workerName != null)
                    message.workerName = String(object.workerName);
                if (object.workerAvatar != null)
                    message.workerAvatar = String(object.workerAvatar);
                return message;
            };

            /**
             * Creates a plain object from a QueryWorkerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {api.core.QueryWorkerResponse} message QueryWorkerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryWorkerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.workerName = "";
                    object.workerAvatar = "";
                }
                if (message.workerName != null && message.hasOwnProperty("workerName"))
                    object.workerName = message.workerName;
                if (message.workerAvatar != null && message.hasOwnProperty("workerAvatar"))
                    object.workerAvatar = message.workerAvatar;
                return object;
            };

            /**
             * Converts this QueryWorkerResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryWorkerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryWorkerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryWorkerResponse
             * @function getTypeUrl
             * @memberof api.core.QueryWorkerResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryWorkerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryWorkerResponse";
            };

            return QueryWorkerResponse;
        })();

        core.QueryChatRequest = (function() {

            /**
             * Properties of a QueryChatRequest.
             * @memberof api.core
             * @interface IQueryChatRequest
             * @property {Array.<Long>|null} [chatId] QueryChatRequest chatId
             */

            /**
             * Constructs a new QueryChatRequest.
             * @memberof api.core
             * @classdesc Represents a QueryChatRequest.
             * @implements IQueryChatRequest
             * @constructor
             * @param {api.core.IQueryChatRequest=} [properties] Properties to set
             */
            function QueryChatRequest(properties) {
                this.chatId = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryChatRequest chatId.
             * @member {Array.<Long>} chatId
             * @memberof api.core.QueryChatRequest
             * @instance
             */
            QueryChatRequest.prototype.chatId = $util.emptyArray;

            /**
             * Creates a new QueryChatRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {api.core.IQueryChatRequest=} [properties] Properties to set
             * @returns {api.core.QueryChatRequest} QueryChatRequest instance
             */
            QueryChatRequest.create = function create(properties) {
                return new QueryChatRequest(properties);
            };

            /**
             * Encodes the specified QueryChatRequest message. Does not implicitly {@link api.core.QueryChatRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {api.core.IQueryChatRequest} message QueryChatRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryChatRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && message.chatId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.chatId.length; ++i)
                        writer.int64(message.chatId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified QueryChatRequest message, length delimited. Does not implicitly {@link api.core.QueryChatRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {api.core.IQueryChatRequest} message QueryChatRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryChatRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryChatRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryChatRequest} QueryChatRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryChatRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryChatRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.chatId && message.chatId.length))
                                message.chatId = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.chatId.push(reader.int64());
                            } else
                                message.chatId.push(reader.int64());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryChatRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryChatRequest} QueryChatRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryChatRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryChatRequest message.
             * @function verify
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryChatRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId")) {
                    if (!Array.isArray(message.chatId))
                        return "chatId: array expected";
                    for (let i = 0; i < message.chatId.length; ++i)
                        if (!$util.isInteger(message.chatId[i]) && !(message.chatId[i] && $util.isInteger(message.chatId[i].low) && $util.isInteger(message.chatId[i].high)))
                            return "chatId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a QueryChatRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryChatRequest} QueryChatRequest
             */
            QueryChatRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryChatRequest)
                    return object;
                let message = new $root.api.core.QueryChatRequest();
                if (object.chatId) {
                    if (!Array.isArray(object.chatId))
                        throw TypeError(".api.core.QueryChatRequest.chatId: array expected");
                    message.chatId = [];
                    for (let i = 0; i < object.chatId.length; ++i)
                        if ($util.Long)
                            (message.chatId[i] = $util.Long.fromValue(object.chatId[i])).unsigned = false;
                        else if (typeof object.chatId[i] === "string")
                            message.chatId[i] = parseInt(object.chatId[i], 10);
                        else if (typeof object.chatId[i] === "number")
                            message.chatId[i] = object.chatId[i];
                        else if (typeof object.chatId[i] === "object")
                            message.chatId[i] = new $util.LongBits(object.chatId[i].low >>> 0, object.chatId[i].high >>> 0).toNumber();
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryChatRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {api.core.QueryChatRequest} message QueryChatRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryChatRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.chatId = [];
                if (message.chatId && message.chatId.length) {
                    object.chatId = [];
                    for (let j = 0; j < message.chatId.length; ++j)
                        if (typeof message.chatId[j] === "number")
                            object.chatId[j] = options.longs === String ? String(message.chatId[j]) : message.chatId[j];
                        else
                            object.chatId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.chatId[j]) : options.longs === Number ? new $util.LongBits(message.chatId[j].low >>> 0, message.chatId[j].high >>> 0).toNumber() : message.chatId[j];
                }
                return object;
            };

            /**
             * Converts this QueryChatRequest to JSON.
             * @function toJSON
             * @memberof api.core.QueryChatRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryChatRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryChatRequest
             * @function getTypeUrl
             * @memberof api.core.QueryChatRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryChatRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryChatRequest";
            };

            return QueryChatRequest;
        })();

        core.QueryChatResponse = (function() {

            /**
             * Properties of a QueryChatResponse.
             * @memberof api.core
             * @interface IQueryChatResponse
             * @property {Array.<api.common.IChatDetail>|null} [chats] QueryChatResponse chats
             */

            /**
             * Constructs a new QueryChatResponse.
             * @memberof api.core
             * @classdesc Represents a QueryChatResponse.
             * @implements IQueryChatResponse
             * @constructor
             * @param {api.core.IQueryChatResponse=} [properties] Properties to set
             */
            function QueryChatResponse(properties) {
                this.chats = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryChatResponse chats.
             * @member {Array.<api.common.IChatDetail>} chats
             * @memberof api.core.QueryChatResponse
             * @instance
             */
            QueryChatResponse.prototype.chats = $util.emptyArray;

            /**
             * Creates a new QueryChatResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {api.core.IQueryChatResponse=} [properties] Properties to set
             * @returns {api.core.QueryChatResponse} QueryChatResponse instance
             */
            QueryChatResponse.create = function create(properties) {
                return new QueryChatResponse(properties);
            };

            /**
             * Encodes the specified QueryChatResponse message. Does not implicitly {@link api.core.QueryChatResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {api.core.IQueryChatResponse} message QueryChatResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryChatResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chats != null && message.chats.length)
                    for (let i = 0; i < message.chats.length; ++i)
                        $root.api.common.ChatDetail.encode(message.chats[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryChatResponse message, length delimited. Does not implicitly {@link api.core.QueryChatResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {api.core.IQueryChatResponse} message QueryChatResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryChatResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryChatResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryChatResponse} QueryChatResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryChatResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryChatResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.chats && message.chats.length))
                                message.chats = [];
                            message.chats.push($root.api.common.ChatDetail.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryChatResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryChatResponse} QueryChatResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryChatResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryChatResponse message.
             * @function verify
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryChatResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chats != null && message.hasOwnProperty("chats")) {
                    if (!Array.isArray(message.chats))
                        return "chats: array expected";
                    for (let i = 0; i < message.chats.length; ++i) {
                        let error = $root.api.common.ChatDetail.verify(message.chats[i]);
                        if (error)
                            return "chats." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QueryChatResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryChatResponse} QueryChatResponse
             */
            QueryChatResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryChatResponse)
                    return object;
                let message = new $root.api.core.QueryChatResponse();
                if (object.chats) {
                    if (!Array.isArray(object.chats))
                        throw TypeError(".api.core.QueryChatResponse.chats: array expected");
                    message.chats = [];
                    for (let i = 0; i < object.chats.length; ++i) {
                        if (typeof object.chats[i] !== "object")
                            throw TypeError(".api.core.QueryChatResponse.chats: object expected");
                        message.chats[i] = $root.api.common.ChatDetail.fromObject(object.chats[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryChatResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {api.core.QueryChatResponse} message QueryChatResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryChatResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.chats = [];
                if (message.chats && message.chats.length) {
                    object.chats = [];
                    for (let j = 0; j < message.chats.length; ++j)
                        object.chats[j] = $root.api.common.ChatDetail.toObject(message.chats[j], options);
                }
                return object;
            };

            /**
             * Converts this QueryChatResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryChatResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryChatResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryChatResponse
             * @function getTypeUrl
             * @memberof api.core.QueryChatResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryChatResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryChatResponse";
            };

            return QueryChatResponse;
        })();

        core.UpdateUserAppealRequest = (function() {

            /**
             * Properties of an UpdateUserAppealRequest.
             * @memberof api.core
             * @interface IUpdateUserAppealRequest
             * @property {Long|null} [chatId] UpdateUserAppealRequest chatId
             * @property {string|null} [userName] UpdateUserAppealRequest userName
             * @property {string|null} [userAppeal] UpdateUserAppealRequest userAppeal
             */

            /**
             * Constructs a new UpdateUserAppealRequest.
             * @memberof api.core
             * @classdesc Represents an UpdateUserAppealRequest.
             * @implements IUpdateUserAppealRequest
             * @constructor
             * @param {api.core.IUpdateUserAppealRequest=} [properties] Properties to set
             */
            function UpdateUserAppealRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateUserAppealRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.UpdateUserAppealRequest
             * @instance
             */
            UpdateUserAppealRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateUserAppealRequest userName.
             * @member {string} userName
             * @memberof api.core.UpdateUserAppealRequest
             * @instance
             */
            UpdateUserAppealRequest.prototype.userName = "";

            /**
             * UpdateUserAppealRequest userAppeal.
             * @member {string} userAppeal
             * @memberof api.core.UpdateUserAppealRequest
             * @instance
             */
            UpdateUserAppealRequest.prototype.userAppeal = "";

            /**
             * Creates a new UpdateUserAppealRequest instance using the specified properties.
             * @function create
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {api.core.IUpdateUserAppealRequest=} [properties] Properties to set
             * @returns {api.core.UpdateUserAppealRequest} UpdateUserAppealRequest instance
             */
            UpdateUserAppealRequest.create = function create(properties) {
                return new UpdateUserAppealRequest(properties);
            };

            /**
             * Encodes the specified UpdateUserAppealRequest message. Does not implicitly {@link api.core.UpdateUserAppealRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {api.core.IUpdateUserAppealRequest} message UpdateUserAppealRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserAppealRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.userName);
                if (message.userAppeal != null && Object.hasOwnProperty.call(message, "userAppeal"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.userAppeal);
                return writer;
            };

            /**
             * Encodes the specified UpdateUserAppealRequest message, length delimited. Does not implicitly {@link api.core.UpdateUserAppealRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {api.core.IUpdateUserAppealRequest} message UpdateUserAppealRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserAppealRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateUserAppealRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.UpdateUserAppealRequest} UpdateUserAppealRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserAppealRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.UpdateUserAppealRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.userName = reader.string();
                            break;
                        }
                    case 3: {
                            message.userAppeal = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateUserAppealRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.UpdateUserAppealRequest} UpdateUserAppealRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserAppealRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateUserAppealRequest message.
             * @function verify
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateUserAppealRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.userName != null && message.hasOwnProperty("userName"))
                    if (!$util.isString(message.userName))
                        return "userName: string expected";
                if (message.userAppeal != null && message.hasOwnProperty("userAppeal"))
                    if (!$util.isString(message.userAppeal))
                        return "userAppeal: string expected";
                return null;
            };

            /**
             * Creates an UpdateUserAppealRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.UpdateUserAppealRequest} UpdateUserAppealRequest
             */
            UpdateUserAppealRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.UpdateUserAppealRequest)
                    return object;
                let message = new $root.api.core.UpdateUserAppealRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.userName != null)
                    message.userName = String(object.userName);
                if (object.userAppeal != null)
                    message.userAppeal = String(object.userAppeal);
                return message;
            };

            /**
             * Creates a plain object from an UpdateUserAppealRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {api.core.UpdateUserAppealRequest} message UpdateUserAppealRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserAppealRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.userName = "";
                    object.userAppeal = "";
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.userName != null && message.hasOwnProperty("userName"))
                    object.userName = message.userName;
                if (message.userAppeal != null && message.hasOwnProperty("userAppeal"))
                    object.userAppeal = message.userAppeal;
                return object;
            };

            /**
             * Converts this UpdateUserAppealRequest to JSON.
             * @function toJSON
             * @memberof api.core.UpdateUserAppealRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserAppealRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserAppealRequest
             * @function getTypeUrl
             * @memberof api.core.UpdateUserAppealRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserAppealRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.UpdateUserAppealRequest";
            };

            return UpdateUserAppealRequest;
        })();

        core.PingPongRequest = (function() {

            /**
             * Properties of a PingPongRequest.
             * @memberof api.core
             * @interface IPingPongRequest
             * @property {Long|null} [local] PingPongRequest local
             */

            /**
             * Constructs a new PingPongRequest.
             * @memberof api.core
             * @classdesc Represents a PingPongRequest.
             * @implements IPingPongRequest
             * @constructor
             * @param {api.core.IPingPongRequest=} [properties] Properties to set
             */
            function PingPongRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PingPongRequest local.
             * @member {Long} local
             * @memberof api.core.PingPongRequest
             * @instance
             */
            PingPongRequest.prototype.local = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new PingPongRequest instance using the specified properties.
             * @function create
             * @memberof api.core.PingPongRequest
             * @static
             * @param {api.core.IPingPongRequest=} [properties] Properties to set
             * @returns {api.core.PingPongRequest} PingPongRequest instance
             */
            PingPongRequest.create = function create(properties) {
                return new PingPongRequest(properties);
            };

            /**
             * Encodes the specified PingPongRequest message. Does not implicitly {@link api.core.PingPongRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.PingPongRequest
             * @static
             * @param {api.core.IPingPongRequest} message PingPongRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PingPongRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.local != null && Object.hasOwnProperty.call(message, "local"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.local);
                return writer;
            };

            /**
             * Encodes the specified PingPongRequest message, length delimited. Does not implicitly {@link api.core.PingPongRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.PingPongRequest
             * @static
             * @param {api.core.IPingPongRequest} message PingPongRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PingPongRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PingPongRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.PingPongRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.PingPongRequest} PingPongRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PingPongRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.PingPongRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.local = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PingPongRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.PingPongRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.PingPongRequest} PingPongRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PingPongRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PingPongRequest message.
             * @function verify
             * @memberof api.core.PingPongRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PingPongRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.local != null && message.hasOwnProperty("local"))
                    if (!$util.isInteger(message.local) && !(message.local && $util.isInteger(message.local.low) && $util.isInteger(message.local.high)))
                        return "local: integer|Long expected";
                return null;
            };

            /**
             * Creates a PingPongRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.PingPongRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.PingPongRequest} PingPongRequest
             */
            PingPongRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.PingPongRequest)
                    return object;
                let message = new $root.api.core.PingPongRequest();
                if (object.local != null)
                    if ($util.Long)
                        (message.local = $util.Long.fromValue(object.local)).unsigned = false;
                    else if (typeof object.local === "string")
                        message.local = parseInt(object.local, 10);
                    else if (typeof object.local === "number")
                        message.local = object.local;
                    else if (typeof object.local === "object")
                        message.local = new $util.LongBits(object.local.low >>> 0, object.local.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a PingPongRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.PingPongRequest
             * @static
             * @param {api.core.PingPongRequest} message PingPongRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PingPongRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.local = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.local = options.longs === String ? "0" : 0;
                if (message.local != null && message.hasOwnProperty("local"))
                    if (typeof message.local === "number")
                        object.local = options.longs === String ? String(message.local) : message.local;
                    else
                        object.local = options.longs === String ? $util.Long.prototype.toString.call(message.local) : options.longs === Number ? new $util.LongBits(message.local.low >>> 0, message.local.high >>> 0).toNumber() : message.local;
                return object;
            };

            /**
             * Converts this PingPongRequest to JSON.
             * @function toJSON
             * @memberof api.core.PingPongRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PingPongRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PingPongRequest
             * @function getTypeUrl
             * @memberof api.core.PingPongRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PingPongRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.PingPongRequest";
            };

            return PingPongRequest;
        })();

        core.PingPongResponse = (function() {

            /**
             * Properties of a PingPongResponse.
             * @memberof api.core
             * @interface IPingPongResponse
             * @property {Long|null} [local] PingPongResponse local
             */

            /**
             * Constructs a new PingPongResponse.
             * @memberof api.core
             * @classdesc Represents a PingPongResponse.
             * @implements IPingPongResponse
             * @constructor
             * @param {api.core.IPingPongResponse=} [properties] Properties to set
             */
            function PingPongResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PingPongResponse local.
             * @member {Long} local
             * @memberof api.core.PingPongResponse
             * @instance
             */
            PingPongResponse.prototype.local = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new PingPongResponse instance using the specified properties.
             * @function create
             * @memberof api.core.PingPongResponse
             * @static
             * @param {api.core.IPingPongResponse=} [properties] Properties to set
             * @returns {api.core.PingPongResponse} PingPongResponse instance
             */
            PingPongResponse.create = function create(properties) {
                return new PingPongResponse(properties);
            };

            /**
             * Encodes the specified PingPongResponse message. Does not implicitly {@link api.core.PingPongResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.PingPongResponse
             * @static
             * @param {api.core.IPingPongResponse} message PingPongResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PingPongResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.local != null && Object.hasOwnProperty.call(message, "local"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.local);
                return writer;
            };

            /**
             * Encodes the specified PingPongResponse message, length delimited. Does not implicitly {@link api.core.PingPongResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.PingPongResponse
             * @static
             * @param {api.core.IPingPongResponse} message PingPongResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PingPongResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PingPongResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.PingPongResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.PingPongResponse} PingPongResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PingPongResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.PingPongResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.local = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PingPongResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.PingPongResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.PingPongResponse} PingPongResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PingPongResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PingPongResponse message.
             * @function verify
             * @memberof api.core.PingPongResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PingPongResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.local != null && message.hasOwnProperty("local"))
                    if (!$util.isInteger(message.local) && !(message.local && $util.isInteger(message.local.low) && $util.isInteger(message.local.high)))
                        return "local: integer|Long expected";
                return null;
            };

            /**
             * Creates a PingPongResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.PingPongResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.PingPongResponse} PingPongResponse
             */
            PingPongResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.PingPongResponse)
                    return object;
                let message = new $root.api.core.PingPongResponse();
                if (object.local != null)
                    if ($util.Long)
                        (message.local = $util.Long.fromValue(object.local)).unsigned = false;
                    else if (typeof object.local === "string")
                        message.local = parseInt(object.local, 10);
                    else if (typeof object.local === "number")
                        message.local = object.local;
                    else if (typeof object.local === "object")
                        message.local = new $util.LongBits(object.local.low >>> 0, object.local.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a PingPongResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.PingPongResponse
             * @static
             * @param {api.core.PingPongResponse} message PingPongResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PingPongResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.local = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.local = options.longs === String ? "0" : 0;
                if (message.local != null && message.hasOwnProperty("local"))
                    if (typeof message.local === "number")
                        object.local = options.longs === String ? String(message.local) : message.local;
                    else
                        object.local = options.longs === String ? $util.Long.prototype.toString.call(message.local) : options.longs === Number ? new $util.LongBits(message.local.low >>> 0, message.local.high >>> 0).toNumber() : message.local;
                return object;
            };

            /**
             * Converts this PingPongResponse to JSON.
             * @function toJSON
             * @memberof api.core.PingPongResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PingPongResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PingPongResponse
             * @function getTypeUrl
             * @memberof api.core.PingPongResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PingPongResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.PingPongResponse";
            };

            return PingPongResponse;
        })();

        core.ReplyRequest = (function() {

            /**
             * Properties of a ReplyRequest.
             * @memberof api.core
             * @interface IReplyRequest
             * @property {Long|null} [msgId] ReplyRequest msgId
             */

            /**
             * Constructs a new ReplyRequest.
             * @memberof api.core
             * @classdesc Represents a ReplyRequest.
             * @implements IReplyRequest
             * @constructor
             * @param {api.core.IReplyRequest=} [properties] Properties to set
             */
            function ReplyRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReplyRequest msgId.
             * @member {Long} msgId
             * @memberof api.core.ReplyRequest
             * @instance
             */
            ReplyRequest.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new ReplyRequest instance using the specified properties.
             * @function create
             * @memberof api.core.ReplyRequest
             * @static
             * @param {api.core.IReplyRequest=} [properties] Properties to set
             * @returns {api.core.ReplyRequest} ReplyRequest instance
             */
            ReplyRequest.create = function create(properties) {
                return new ReplyRequest(properties);
            };

            /**
             * Encodes the specified ReplyRequest message. Does not implicitly {@link api.core.ReplyRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.ReplyRequest
             * @static
             * @param {api.core.IReplyRequest} message ReplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReplyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.msgId);
                return writer;
            };

            /**
             * Encodes the specified ReplyRequest message, length delimited. Does not implicitly {@link api.core.ReplyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.ReplyRequest
             * @static
             * @param {api.core.IReplyRequest} message ReplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReplyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReplyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.ReplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.ReplyRequest} ReplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReplyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.ReplyRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.msgId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReplyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.ReplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.ReplyRequest} ReplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReplyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReplyRequest message.
             * @function verify
             * @memberof api.core.ReplyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReplyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                        return "msgId: integer|Long expected";
                return null;
            };

            /**
             * Creates a ReplyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.ReplyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.ReplyRequest} ReplyRequest
             */
            ReplyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.ReplyRequest)
                    return object;
                let message = new $root.api.core.ReplyRequest();
                if (object.msgId != null)
                    if ($util.Long)
                        (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                    else if (typeof object.msgId === "string")
                        message.msgId = parseInt(object.msgId, 10);
                    else if (typeof object.msgId === "number")
                        message.msgId = object.msgId;
                    else if (typeof object.msgId === "object")
                        message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a ReplyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.ReplyRequest
             * @static
             * @param {api.core.ReplyRequest} message ReplyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReplyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.msgId = options.longs === String ? "0" : 0;
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (typeof message.msgId === "number")
                        object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                    else
                        object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
                return object;
            };

            /**
             * Converts this ReplyRequest to JSON.
             * @function toJSON
             * @memberof api.core.ReplyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReplyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReplyRequest
             * @function getTypeUrl
             * @memberof api.core.ReplyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReplyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.ReplyRequest";
            };

            return ReplyRequest;
        })();

        core.Front = (function() {

            /**
             * Constructs a new Front service.
             * @memberof api.core
             * @classdesc Represents a Front
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Front(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Front.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Front;

            /**
             * Creates new Front service using the specified rpc implementation.
             * @function create
             * @memberof api.core.Front
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Front} RPC service. Useful where requests and/or responses are streamed.
             */
            Front.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.Front#customerInfo}.
             * @memberof api.core.Front
             * @typedef CustomerInfoCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.CustomerDataResponse} [response] CustomerDataResponse
             */

            /**
             * Calls CustomerInfo.
             * @function customerInfo
             * @memberof api.core.Front
             * @instance
             * @param {api.core.ICustomerDataRequest} request CustomerDataRequest message or plain object
             * @param {api.core.Front.CustomerInfoCallback} callback Node-style callback called with the error, if any, and CustomerDataResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.customerInfo = function customerInfo(request, callback) {
                return this.rpcCall(customerInfo, $root.api.core.CustomerDataRequest, $root.api.core.CustomerDataResponse, request, callback);
            }, "name", { value: "CustomerInfo" });

            /**
             * Calls CustomerInfo.
             * @function customerInfo
             * @memberof api.core.Front
             * @instance
             * @param {api.core.ICustomerDataRequest} request CustomerDataRequest message or plain object
             * @returns {Promise<api.core.CustomerDataResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#assignWorker}.
             * @memberof api.core.Front
             * @typedef AssignWorkerCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.AssignWorkerResponse} [response] AssignWorkerResponse
             */

            /**
             * Calls AssignWorker.
             * @function assignWorker
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IAssignWorkerRequest} request AssignWorkerRequest message or plain object
             * @param {api.core.Front.AssignWorkerCallback} callback Node-style callback called with the error, if any, and AssignWorkerResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.assignWorker = function assignWorker(request, callback) {
                return this.rpcCall(assignWorker, $root.api.core.AssignWorkerRequest, $root.api.core.AssignWorkerResponse, request, callback);
            }, "name", { value: "AssignWorker" });

            /**
             * Calls AssignWorker.
             * @function assignWorker
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IAssignWorkerRequest} request AssignWorkerRequest message or plain object
             * @returns {Promise<api.core.AssignWorkerResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#queryEntrance}.
             * @memberof api.core.Front
             * @typedef QueryEntranceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryEntranceResponse} [response] QueryEntranceResponse
             */

            /**
             * Calls QueryEntrance.
             * @function queryEntrance
             * @memberof api.core.Front
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Front.QueryEntranceCallback} callback Node-style callback called with the error, if any, and QueryEntranceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.queryEntrance = function queryEntrance(request, callback) {
                return this.rpcCall(queryEntrance, $root.google.protobuf.Empty, $root.api.core.QueryEntranceResponse, request, callback);
            }, "name", { value: "QueryEntrance" });

            /**
             * Calls QueryEntrance.
             * @function queryEntrance
             * @memberof api.core.Front
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.QueryEntranceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#queryAutoReply}.
             * @memberof api.core.Front
             * @typedef QueryAutoReplyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryAutoReplyResponse} [response] QueryAutoReplyResponse
             */

            /**
             * Calls QueryAutoReply.
             * @function queryAutoReply
             * @memberof api.core.Front
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Front.QueryAutoReplyCallback} callback Node-style callback called with the error, if any, and QueryAutoReplyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.queryAutoReply = function queryAutoReply(request, callback) {
                return this.rpcCall(queryAutoReply, $root.google.protobuf.Empty, $root.api.core.QueryAutoReplyResponse, request, callback);
            }, "name", { value: "QueryAutoReply" });

            /**
             * Calls QueryAutoReply.
             * @function queryAutoReply
             * @memberof api.core.Front
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.QueryAutoReplyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#bindWorker}.
             * @memberof api.core.Front
             * @typedef BindWorkerCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.BindWorkerResponse} [response] BindWorkerResponse
             */

            /**
             * Calls BindWorker.
             * @function bindWorker
             * @memberof api.core.Front
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Front.BindWorkerCallback} callback Node-style callback called with the error, if any, and BindWorkerResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.bindWorker = function bindWorker(request, callback) {
                return this.rpcCall(bindWorker, $root.google.protobuf.Empty, $root.api.core.BindWorkerResponse, request, callback);
            }, "name", { value: "BindWorker" });

            /**
             * Calls BindWorker.
             * @function bindWorker
             * @memberof api.core.Front
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.BindWorkerResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#createAnonUser}.
             * @memberof api.core.Front
             * @typedef CreateAnonUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.CreateAnonUserResponse} [response] CreateAnonUserResponse
             */

            /**
             * Calls CreateAnonUser.
             * @function createAnonUser
             * @memberof api.core.Front
             * @instance
             * @param {api.core.ICreateAnonUserRequest} request CreateAnonUserRequest message or plain object
             * @param {api.core.Front.CreateAnonUserCallback} callback Node-style callback called with the error, if any, and CreateAnonUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.createAnonUser = function createAnonUser(request, callback) {
                return this.rpcCall(createAnonUser, $root.api.core.CreateAnonUserRequest, $root.api.core.CreateAnonUserResponse, request, callback);
            }, "name", { value: "CreateAnonUser" });

            /**
             * Calls CreateAnonUser.
             * @function createAnonUser
             * @memberof api.core.Front
             * @instance
             * @param {api.core.ICreateAnonUserRequest} request CreateAnonUserRequest message or plain object
             * @returns {Promise<api.core.CreateAnonUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#queryWorker}.
             * @memberof api.core.Front
             * @typedef QueryWorkerCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryWorkerResponse} [response] QueryWorkerResponse
             */

            /**
             * Calls QueryWorker.
             * @function queryWorker
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IQueryWorkerRequest} request QueryWorkerRequest message or plain object
             * @param {api.core.Front.QueryWorkerCallback} callback Node-style callback called with the error, if any, and QueryWorkerResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.queryWorker = function queryWorker(request, callback) {
                return this.rpcCall(queryWorker, $root.api.core.QueryWorkerRequest, $root.api.core.QueryWorkerResponse, request, callback);
            }, "name", { value: "QueryWorker" });

            /**
             * Calls QueryWorker.
             * @function queryWorker
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IQueryWorkerRequest} request QueryWorkerRequest message or plain object
             * @returns {Promise<api.core.QueryWorkerResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#queryChat}.
             * @memberof api.core.Front
             * @typedef QueryChatCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryChatResponse} [response] QueryChatResponse
             */

            /**
             * Calls QueryChat.
             * @function queryChat
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IQueryChatRequest} request QueryChatRequest message or plain object
             * @param {api.core.Front.QueryChatCallback} callback Node-style callback called with the error, if any, and QueryChatResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.queryChat = function queryChat(request, callback) {
                return this.rpcCall(queryChat, $root.api.core.QueryChatRequest, $root.api.core.QueryChatResponse, request, callback);
            }, "name", { value: "QueryChat" });

            /**
             * Calls QueryChat.
             * @function queryChat
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IQueryChatRequest} request QueryChatRequest message or plain object
             * @returns {Promise<api.core.QueryChatResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#updateUserAppeal}.
             * @memberof api.core.Front
             * @typedef UpdateUserAppealCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls UpdateUserAppeal.
             * @function updateUserAppeal
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IUpdateUserAppealRequest} request UpdateUserAppealRequest message or plain object
             * @param {api.core.Front.UpdateUserAppealCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.updateUserAppeal = function updateUserAppeal(request, callback) {
                return this.rpcCall(updateUserAppeal, $root.api.core.UpdateUserAppealRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "UpdateUserAppeal" });

            /**
             * Calls UpdateUserAppeal.
             * @function updateUserAppeal
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IUpdateUserAppealRequest} request UpdateUserAppealRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#pingPong}.
             * @memberof api.core.Front
             * @typedef PingPongCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.PingPongResponse} [response] PingPongResponse
             */

            /**
             * Calls PingPong.
             * @function pingPong
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IPingPongRequest} request PingPongRequest message or plain object
             * @param {api.core.Front.PingPongCallback} callback Node-style callback called with the error, if any, and PingPongResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.pingPong = function pingPong(request, callback) {
                return this.rpcCall(pingPong, $root.api.core.PingPongRequest, $root.api.core.PingPongResponse, request, callback);
            }, "name", { value: "PingPong" });

            /**
             * Calls PingPong.
             * @function pingPong
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IPingPongRequest} request PingPongRequest message or plain object
             * @returns {Promise<api.core.PingPongResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Front#replyPls}.
             * @memberof api.core.Front
             * @typedef ReplyPlsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls ReplyPls.
             * @function replyPls
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IReplyRequest} request ReplyRequest message or plain object
             * @param {api.core.Front.ReplyPlsCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Front.prototype.replyPls = function replyPls(request, callback) {
                return this.rpcCall(replyPls, $root.api.core.ReplyRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "ReplyPls" });

            /**
             * Calls ReplyPls.
             * @function replyPls
             * @memberof api.core.Front
             * @instance
             * @param {api.core.IReplyRequest} request ReplyRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return Front;
        })();

        core.QueryConsultResponse = (function() {

            /**
             * Properties of a QueryConsultResponse.
             * @memberof api.core
             * @interface IQueryConsultResponse
             * @property {Array.<api.common.IConsult>|null} [consultArr] QueryConsultResponse consultArr
             */

            /**
             * Constructs a new QueryConsultResponse.
             * @memberof api.core
             * @classdesc Represents a QueryConsultResponse.
             * @implements IQueryConsultResponse
             * @constructor
             * @param {api.core.IQueryConsultResponse=} [properties] Properties to set
             */
            function QueryConsultResponse(properties) {
                this.consultArr = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryConsultResponse consultArr.
             * @member {Array.<api.common.IConsult>} consultArr
             * @memberof api.core.QueryConsultResponse
             * @instance
             */
            QueryConsultResponse.prototype.consultArr = $util.emptyArray;

            /**
             * Creates a new QueryConsultResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {api.core.IQueryConsultResponse=} [properties] Properties to set
             * @returns {api.core.QueryConsultResponse} QueryConsultResponse instance
             */
            QueryConsultResponse.create = function create(properties) {
                return new QueryConsultResponse(properties);
            };

            /**
             * Encodes the specified QueryConsultResponse message. Does not implicitly {@link api.core.QueryConsultResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {api.core.IQueryConsultResponse} message QueryConsultResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryConsultResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.consultArr != null && message.consultArr.length)
                    for (let i = 0; i < message.consultArr.length; ++i)
                        $root.api.common.Consult.encode(message.consultArr[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryConsultResponse message, length delimited. Does not implicitly {@link api.core.QueryConsultResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {api.core.IQueryConsultResponse} message QueryConsultResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryConsultResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryConsultResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryConsultResponse} QueryConsultResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryConsultResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryConsultResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.consultArr && message.consultArr.length))
                                message.consultArr = [];
                            message.consultArr.push($root.api.common.Consult.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryConsultResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryConsultResponse} QueryConsultResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryConsultResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryConsultResponse message.
             * @function verify
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryConsultResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.consultArr != null && message.hasOwnProperty("consultArr")) {
                    if (!Array.isArray(message.consultArr))
                        return "consultArr: array expected";
                    for (let i = 0; i < message.consultArr.length; ++i) {
                        let error = $root.api.common.Consult.verify(message.consultArr[i]);
                        if (error)
                            return "consultArr." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QueryConsultResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryConsultResponse} QueryConsultResponse
             */
            QueryConsultResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryConsultResponse)
                    return object;
                let message = new $root.api.core.QueryConsultResponse();
                if (object.consultArr) {
                    if (!Array.isArray(object.consultArr))
                        throw TypeError(".api.core.QueryConsultResponse.consultArr: array expected");
                    message.consultArr = [];
                    for (let i = 0; i < object.consultArr.length; ++i) {
                        if (typeof object.consultArr[i] !== "object")
                            throw TypeError(".api.core.QueryConsultResponse.consultArr: object expected");
                        message.consultArr[i] = $root.api.common.Consult.fromObject(object.consultArr[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryConsultResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {api.core.QueryConsultResponse} message QueryConsultResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryConsultResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.consultArr = [];
                if (message.consultArr && message.consultArr.length) {
                    object.consultArr = [];
                    for (let j = 0; j < message.consultArr.length; ++j)
                        object.consultArr[j] = $root.api.common.Consult.toObject(message.consultArr[j], options);
                }
                return object;
            };

            /**
             * Converts this QueryConsultResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryConsultResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryConsultResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryConsultResponse
             * @function getTypeUrl
             * @memberof api.core.QueryConsultResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryConsultResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryConsultResponse";
            };

            return QueryConsultResponse;
        })();

        core.CreateConsultRequest = (function() {

            /**
             * Properties of a CreateConsultRequest.
             * @memberof api.core
             * @interface ICreateConsultRequest
             * @property {api.common.IConsult|null} [consult] CreateConsultRequest consult
             */

            /**
             * Constructs a new CreateConsultRequest.
             * @memberof api.core
             * @classdesc Represents a CreateConsultRequest.
             * @implements ICreateConsultRequest
             * @constructor
             * @param {api.core.ICreateConsultRequest=} [properties] Properties to set
             */
            function CreateConsultRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateConsultRequest consult.
             * @member {api.common.IConsult|null|undefined} consult
             * @memberof api.core.CreateConsultRequest
             * @instance
             */
            CreateConsultRequest.prototype.consult = null;

            /**
             * Creates a new CreateConsultRequest instance using the specified properties.
             * @function create
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {api.core.ICreateConsultRequest=} [properties] Properties to set
             * @returns {api.core.CreateConsultRequest} CreateConsultRequest instance
             */
            CreateConsultRequest.create = function create(properties) {
                return new CreateConsultRequest(properties);
            };

            /**
             * Encodes the specified CreateConsultRequest message. Does not implicitly {@link api.core.CreateConsultRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {api.core.ICreateConsultRequest} message CreateConsultRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateConsultRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.consult != null && Object.hasOwnProperty.call(message, "consult"))
                    $root.api.common.Consult.encode(message.consult, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CreateConsultRequest message, length delimited. Does not implicitly {@link api.core.CreateConsultRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {api.core.ICreateConsultRequest} message CreateConsultRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateConsultRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateConsultRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateConsultRequest} CreateConsultRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateConsultRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateConsultRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.consult = $root.api.common.Consult.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateConsultRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateConsultRequest} CreateConsultRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateConsultRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateConsultRequest message.
             * @function verify
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateConsultRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.consult != null && message.hasOwnProperty("consult")) {
                    let error = $root.api.common.Consult.verify(message.consult);
                    if (error)
                        return "consult." + error;
                }
                return null;
            };

            /**
             * Creates a CreateConsultRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateConsultRequest} CreateConsultRequest
             */
            CreateConsultRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateConsultRequest)
                    return object;
                let message = new $root.api.core.CreateConsultRequest();
                if (object.consult != null) {
                    if (typeof object.consult !== "object")
                        throw TypeError(".api.core.CreateConsultRequest.consult: object expected");
                    message.consult = $root.api.common.Consult.fromObject(object.consult);
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateConsultRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {api.core.CreateConsultRequest} message CreateConsultRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateConsultRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.consult = null;
                if (message.consult != null && message.hasOwnProperty("consult"))
                    object.consult = $root.api.common.Consult.toObject(message.consult, options);
                return object;
            };

            /**
             * Converts this CreateConsultRequest to JSON.
             * @function toJSON
             * @memberof api.core.CreateConsultRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateConsultRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateConsultRequest
             * @function getTypeUrl
             * @memberof api.core.CreateConsultRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateConsultRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateConsultRequest";
            };

            return CreateConsultRequest;
        })();

        core.CreateConsultResponse = (function() {

            /**
             * Properties of a CreateConsultResponse.
             * @memberof api.core
             * @interface ICreateConsultResponse
             * @property {number|null} [id] CreateConsultResponse id
             */

            /**
             * Constructs a new CreateConsultResponse.
             * @memberof api.core
             * @classdesc Represents a CreateConsultResponse.
             * @implements ICreateConsultResponse
             * @constructor
             * @param {api.core.ICreateConsultResponse=} [properties] Properties to set
             */
            function CreateConsultResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateConsultResponse id.
             * @member {number} id
             * @memberof api.core.CreateConsultResponse
             * @instance
             */
            CreateConsultResponse.prototype.id = 0;

            /**
             * Creates a new CreateConsultResponse instance using the specified properties.
             * @function create
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {api.core.ICreateConsultResponse=} [properties] Properties to set
             * @returns {api.core.CreateConsultResponse} CreateConsultResponse instance
             */
            CreateConsultResponse.create = function create(properties) {
                return new CreateConsultResponse(properties);
            };

            /**
             * Encodes the specified CreateConsultResponse message. Does not implicitly {@link api.core.CreateConsultResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {api.core.ICreateConsultResponse} message CreateConsultResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateConsultResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };

            /**
             * Encodes the specified CreateConsultResponse message, length delimited. Does not implicitly {@link api.core.CreateConsultResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {api.core.ICreateConsultResponse} message CreateConsultResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateConsultResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateConsultResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateConsultResponse} CreateConsultResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateConsultResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateConsultResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateConsultResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateConsultResponse} CreateConsultResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateConsultResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateConsultResponse message.
             * @function verify
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateConsultResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };

            /**
             * Creates a CreateConsultResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateConsultResponse} CreateConsultResponse
             */
            CreateConsultResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateConsultResponse)
                    return object;
                let message = new $root.api.core.CreateConsultResponse();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a CreateConsultResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {api.core.CreateConsultResponse} message CreateConsultResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateConsultResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this CreateConsultResponse to JSON.
             * @function toJSON
             * @memberof api.core.CreateConsultResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateConsultResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateConsultResponse
             * @function getTypeUrl
             * @memberof api.core.CreateConsultResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateConsultResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateConsultResponse";
            };

            return CreateConsultResponse;
        })();

        core.UpdateConsultRequest = (function() {

            /**
             * Properties of an UpdateConsultRequest.
             * @memberof api.core
             * @interface IUpdateConsultRequest
             * @property {api.common.IConsult|null} [consult] UpdateConsultRequest consult
             */

            /**
             * Constructs a new UpdateConsultRequest.
             * @memberof api.core
             * @classdesc Represents an UpdateConsultRequest.
             * @implements IUpdateConsultRequest
             * @constructor
             * @param {api.core.IUpdateConsultRequest=} [properties] Properties to set
             */
            function UpdateConsultRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateConsultRequest consult.
             * @member {api.common.IConsult|null|undefined} consult
             * @memberof api.core.UpdateConsultRequest
             * @instance
             */
            UpdateConsultRequest.prototype.consult = null;

            /**
             * Creates a new UpdateConsultRequest instance using the specified properties.
             * @function create
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {api.core.IUpdateConsultRequest=} [properties] Properties to set
             * @returns {api.core.UpdateConsultRequest} UpdateConsultRequest instance
             */
            UpdateConsultRequest.create = function create(properties) {
                return new UpdateConsultRequest(properties);
            };

            /**
             * Encodes the specified UpdateConsultRequest message. Does not implicitly {@link api.core.UpdateConsultRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {api.core.IUpdateConsultRequest} message UpdateConsultRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateConsultRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.consult != null && Object.hasOwnProperty.call(message, "consult"))
                    $root.api.common.Consult.encode(message.consult, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateConsultRequest message, length delimited. Does not implicitly {@link api.core.UpdateConsultRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {api.core.IUpdateConsultRequest} message UpdateConsultRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateConsultRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateConsultRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.UpdateConsultRequest} UpdateConsultRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateConsultRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.UpdateConsultRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.consult = $root.api.common.Consult.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateConsultRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.UpdateConsultRequest} UpdateConsultRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateConsultRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateConsultRequest message.
             * @function verify
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateConsultRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.consult != null && message.hasOwnProperty("consult")) {
                    let error = $root.api.common.Consult.verify(message.consult);
                    if (error)
                        return "consult." + error;
                }
                return null;
            };

            /**
             * Creates an UpdateConsultRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.UpdateConsultRequest} UpdateConsultRequest
             */
            UpdateConsultRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.UpdateConsultRequest)
                    return object;
                let message = new $root.api.core.UpdateConsultRequest();
                if (object.consult != null) {
                    if (typeof object.consult !== "object")
                        throw TypeError(".api.core.UpdateConsultRequest.consult: object expected");
                    message.consult = $root.api.common.Consult.fromObject(object.consult);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateConsultRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {api.core.UpdateConsultRequest} message UpdateConsultRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateConsultRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.consult = null;
                if (message.consult != null && message.hasOwnProperty("consult"))
                    object.consult = $root.api.common.Consult.toObject(message.consult, options);
                return object;
            };

            /**
             * Converts this UpdateConsultRequest to JSON.
             * @function toJSON
             * @memberof api.core.UpdateConsultRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateConsultRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateConsultRequest
             * @function getTypeUrl
             * @memberof api.core.UpdateConsultRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateConsultRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.UpdateConsultRequest";
            };

            return UpdateConsultRequest;
        })();

        core.DeleteConsultRequest = (function() {

            /**
             * Properties of a DeleteConsultRequest.
             * @memberof api.core
             * @interface IDeleteConsultRequest
             * @property {number|null} [id] DeleteConsultRequest id
             */

            /**
             * Constructs a new DeleteConsultRequest.
             * @memberof api.core
             * @classdesc Represents a DeleteConsultRequest.
             * @implements IDeleteConsultRequest
             * @constructor
             * @param {api.core.IDeleteConsultRequest=} [properties] Properties to set
             */
            function DeleteConsultRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteConsultRequest id.
             * @member {number} id
             * @memberof api.core.DeleteConsultRequest
             * @instance
             */
            DeleteConsultRequest.prototype.id = 0;

            /**
             * Creates a new DeleteConsultRequest instance using the specified properties.
             * @function create
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {api.core.IDeleteConsultRequest=} [properties] Properties to set
             * @returns {api.core.DeleteConsultRequest} DeleteConsultRequest instance
             */
            DeleteConsultRequest.create = function create(properties) {
                return new DeleteConsultRequest(properties);
            };

            /**
             * Encodes the specified DeleteConsultRequest message. Does not implicitly {@link api.core.DeleteConsultRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {api.core.IDeleteConsultRequest} message DeleteConsultRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteConsultRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };

            /**
             * Encodes the specified DeleteConsultRequest message, length delimited. Does not implicitly {@link api.core.DeleteConsultRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {api.core.IDeleteConsultRequest} message DeleteConsultRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteConsultRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteConsultRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DeleteConsultRequest} DeleteConsultRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteConsultRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DeleteConsultRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteConsultRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DeleteConsultRequest} DeleteConsultRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteConsultRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteConsultRequest message.
             * @function verify
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteConsultRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };

            /**
             * Creates a DeleteConsultRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DeleteConsultRequest} DeleteConsultRequest
             */
            DeleteConsultRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DeleteConsultRequest)
                    return object;
                let message = new $root.api.core.DeleteConsultRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeleteConsultRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {api.core.DeleteConsultRequest} message DeleteConsultRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteConsultRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this DeleteConsultRequest to JSON.
             * @function toJSON
             * @memberof api.core.DeleteConsultRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteConsultRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteConsultRequest
             * @function getTypeUrl
             * @memberof api.core.DeleteConsultRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteConsultRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DeleteConsultRequest";
            };

            return DeleteConsultRequest;
        })();

        core.Consult = (function() {

            /**
             * Constructs a new Consult service.
             * @memberof api.core
             * @classdesc Represents a Consult
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Consult(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Consult.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Consult;

            /**
             * Creates new Consult service using the specified rpc implementation.
             * @function create
             * @memberof api.core.Consult
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Consult} RPC service. Useful where requests and/or responses are streamed.
             */
            Consult.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.Consult#queryConsult}.
             * @memberof api.core.Consult
             * @typedef QueryConsultCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryConsultResponse} [response] QueryConsultResponse
             */

            /**
             * Calls QueryConsult.
             * @function queryConsult
             * @memberof api.core.Consult
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Consult.QueryConsultCallback} callback Node-style callback called with the error, if any, and QueryConsultResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Consult.prototype.queryConsult = function queryConsult(request, callback) {
                return this.rpcCall(queryConsult, $root.google.protobuf.Empty, $root.api.core.QueryConsultResponse, request, callback);
            }, "name", { value: "QueryConsult" });

            /**
             * Calls QueryConsult.
             * @function queryConsult
             * @memberof api.core.Consult
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.QueryConsultResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Consult#createConsult}.
             * @memberof api.core.Consult
             * @typedef CreateConsultCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.CreateConsultResponse} [response] CreateConsultResponse
             */

            /**
             * Calls CreateConsult.
             * @function createConsult
             * @memberof api.core.Consult
             * @instance
             * @param {api.core.ICreateConsultRequest} request CreateConsultRequest message or plain object
             * @param {api.core.Consult.CreateConsultCallback} callback Node-style callback called with the error, if any, and CreateConsultResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Consult.prototype.createConsult = function createConsult(request, callback) {
                return this.rpcCall(createConsult, $root.api.core.CreateConsultRequest, $root.api.core.CreateConsultResponse, request, callback);
            }, "name", { value: "CreateConsult" });

            /**
             * Calls CreateConsult.
             * @function createConsult
             * @memberof api.core.Consult
             * @instance
             * @param {api.core.ICreateConsultRequest} request CreateConsultRequest message or plain object
             * @returns {Promise<api.core.CreateConsultResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Consult#updateConsult}.
             * @memberof api.core.Consult
             * @typedef UpdateConsultCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls UpdateConsult.
             * @function updateConsult
             * @memberof api.core.Consult
             * @instance
             * @param {api.core.IUpdateConsultRequest} request UpdateConsultRequest message or plain object
             * @param {api.core.Consult.UpdateConsultCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Consult.prototype.updateConsult = function updateConsult(request, callback) {
                return this.rpcCall(updateConsult, $root.api.core.UpdateConsultRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "UpdateConsult" });

            /**
             * Calls UpdateConsult.
             * @function updateConsult
             * @memberof api.core.Consult
             * @instance
             * @param {api.core.IUpdateConsultRequest} request UpdateConsultRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Consult#deleteConsult}.
             * @memberof api.core.Consult
             * @typedef DeleteConsultCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteConsult.
             * @function deleteConsult
             * @memberof api.core.Consult
             * @instance
             * @param {api.core.IDeleteConsultRequest} request DeleteConsultRequest message or plain object
             * @param {api.core.Consult.DeleteConsultCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Consult.prototype.deleteConsult = function deleteConsult(request, callback) {
                return this.rpcCall(deleteConsult, $root.api.core.DeleteConsultRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteConsult" });

            /**
             * Calls DeleteConsult.
             * @function deleteConsult
             * @memberof api.core.Consult
             * @instance
             * @param {api.core.IDeleteConsultRequest} request DeleteConsultRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return Consult;
        })();

        core.EntranceQueryResponse = (function() {

            /**
             * Properties of an EntranceQueryResponse.
             * @memberof api.core
             * @interface IEntranceQueryResponse
             * @property {Array.<api.common.IEntrance>|null} [entrances] EntranceQueryResponse entrances
             */

            /**
             * Constructs a new EntranceQueryResponse.
             * @memberof api.core
             * @classdesc Represents an EntranceQueryResponse.
             * @implements IEntranceQueryResponse
             * @constructor
             * @param {api.core.IEntranceQueryResponse=} [properties] Properties to set
             */
            function EntranceQueryResponse(properties) {
                this.entrances = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EntranceQueryResponse entrances.
             * @member {Array.<api.common.IEntrance>} entrances
             * @memberof api.core.EntranceQueryResponse
             * @instance
             */
            EntranceQueryResponse.prototype.entrances = $util.emptyArray;

            /**
             * Creates a new EntranceQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {api.core.IEntranceQueryResponse=} [properties] Properties to set
             * @returns {api.core.EntranceQueryResponse} EntranceQueryResponse instance
             */
            EntranceQueryResponse.create = function create(properties) {
                return new EntranceQueryResponse(properties);
            };

            /**
             * Encodes the specified EntranceQueryResponse message. Does not implicitly {@link api.core.EntranceQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {api.core.IEntranceQueryResponse} message EntranceQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EntranceQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.entrances != null && message.entrances.length)
                    for (let i = 0; i < message.entrances.length; ++i)
                        $root.api.common.Entrance.encode(message.entrances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EntranceQueryResponse message, length delimited. Does not implicitly {@link api.core.EntranceQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {api.core.IEntranceQueryResponse} message EntranceQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EntranceQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EntranceQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.EntranceQueryResponse} EntranceQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EntranceQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.EntranceQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.entrances && message.entrances.length))
                                message.entrances = [];
                            message.entrances.push($root.api.common.Entrance.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EntranceQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.EntranceQueryResponse} EntranceQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EntranceQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EntranceQueryResponse message.
             * @function verify
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EntranceQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.entrances != null && message.hasOwnProperty("entrances")) {
                    if (!Array.isArray(message.entrances))
                        return "entrances: array expected";
                    for (let i = 0; i < message.entrances.length; ++i) {
                        let error = $root.api.common.Entrance.verify(message.entrances[i]);
                        if (error)
                            return "entrances." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EntranceQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.EntranceQueryResponse} EntranceQueryResponse
             */
            EntranceQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.EntranceQueryResponse)
                    return object;
                let message = new $root.api.core.EntranceQueryResponse();
                if (object.entrances) {
                    if (!Array.isArray(object.entrances))
                        throw TypeError(".api.core.EntranceQueryResponse.entrances: array expected");
                    message.entrances = [];
                    for (let i = 0; i < object.entrances.length; ++i) {
                        if (typeof object.entrances[i] !== "object")
                            throw TypeError(".api.core.EntranceQueryResponse.entrances: object expected");
                        message.entrances[i] = $root.api.common.Entrance.fromObject(object.entrances[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EntranceQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {api.core.EntranceQueryResponse} message EntranceQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EntranceQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.entrances = [];
                if (message.entrances && message.entrances.length) {
                    object.entrances = [];
                    for (let j = 0; j < message.entrances.length; ++j)
                        object.entrances[j] = $root.api.common.Entrance.toObject(message.entrances[j], options);
                }
                return object;
            };

            /**
             * Converts this EntranceQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.EntranceQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EntranceQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EntranceQueryResponse
             * @function getTypeUrl
             * @memberof api.core.EntranceQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EntranceQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.EntranceQueryResponse";
            };

            return EntranceQueryResponse;
        })();

        core.DomainItem = (function() {

            /**
             * Properties of a DomainItem.
             * @memberof api.core
             * @interface IDomainItem
             * @property {string|null} [domainName] DomainItem domainName
             */

            /**
             * Constructs a new DomainItem.
             * @memberof api.core
             * @classdesc Represents a DomainItem.
             * @implements IDomainItem
             * @constructor
             * @param {api.core.IDomainItem=} [properties] Properties to set
             */
            function DomainItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DomainItem domainName.
             * @member {string} domainName
             * @memberof api.core.DomainItem
             * @instance
             */
            DomainItem.prototype.domainName = "";

            /**
             * Creates a new DomainItem instance using the specified properties.
             * @function create
             * @memberof api.core.DomainItem
             * @static
             * @param {api.core.IDomainItem=} [properties] Properties to set
             * @returns {api.core.DomainItem} DomainItem instance
             */
            DomainItem.create = function create(properties) {
                return new DomainItem(properties);
            };

            /**
             * Encodes the specified DomainItem message. Does not implicitly {@link api.core.DomainItem.verify|verify} messages.
             * @function encode
             * @memberof api.core.DomainItem
             * @static
             * @param {api.core.IDomainItem} message DomainItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.domainName != null && Object.hasOwnProperty.call(message, "domainName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.domainName);
                return writer;
            };

            /**
             * Encodes the specified DomainItem message, length delimited. Does not implicitly {@link api.core.DomainItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DomainItem
             * @static
             * @param {api.core.IDomainItem} message DomainItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DomainItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DomainItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DomainItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DomainItem} DomainItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DomainItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.domainName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DomainItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DomainItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DomainItem} DomainItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DomainItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DomainItem message.
             * @function verify
             * @memberof api.core.DomainItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DomainItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.domainName != null && message.hasOwnProperty("domainName"))
                    if (!$util.isString(message.domainName))
                        return "domainName: string expected";
                return null;
            };

            /**
             * Creates a DomainItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DomainItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DomainItem} DomainItem
             */
            DomainItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DomainItem)
                    return object;
                let message = new $root.api.core.DomainItem();
                if (object.domainName != null)
                    message.domainName = String(object.domainName);
                return message;
            };

            /**
             * Creates a plain object from a DomainItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DomainItem
             * @static
             * @param {api.core.DomainItem} message DomainItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DomainItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.domainName = "";
                if (message.domainName != null && message.hasOwnProperty("domainName"))
                    object.domainName = message.domainName;
                return object;
            };

            /**
             * Converts this DomainItem to JSON.
             * @function toJSON
             * @memberof api.core.DomainItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DomainItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DomainItem
             * @function getTypeUrl
             * @memberof api.core.DomainItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DomainItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DomainItem";
            };

            return DomainItem;
        })();

        core.DownloadDomainResponse = (function() {

            /**
             * Properties of a DownloadDomainResponse.
             * @memberof api.core
             * @interface IDownloadDomainResponse
             * @property {Array.<api.core.IDomainItem>|null} [domainItem] DownloadDomainResponse domainItem
             */

            /**
             * Constructs a new DownloadDomainResponse.
             * @memberof api.core
             * @classdesc Represents a DownloadDomainResponse.
             * @implements IDownloadDomainResponse
             * @constructor
             * @param {api.core.IDownloadDomainResponse=} [properties] Properties to set
             */
            function DownloadDomainResponse(properties) {
                this.domainItem = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DownloadDomainResponse domainItem.
             * @member {Array.<api.core.IDomainItem>} domainItem
             * @memberof api.core.DownloadDomainResponse
             * @instance
             */
            DownloadDomainResponse.prototype.domainItem = $util.emptyArray;

            /**
             * Creates a new DownloadDomainResponse instance using the specified properties.
             * @function create
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {api.core.IDownloadDomainResponse=} [properties] Properties to set
             * @returns {api.core.DownloadDomainResponse} DownloadDomainResponse instance
             */
            DownloadDomainResponse.create = function create(properties) {
                return new DownloadDomainResponse(properties);
            };

            /**
             * Encodes the specified DownloadDomainResponse message. Does not implicitly {@link api.core.DownloadDomainResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {api.core.IDownloadDomainResponse} message DownloadDomainResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DownloadDomainResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.domainItem != null && message.domainItem.length)
                    for (let i = 0; i < message.domainItem.length; ++i)
                        $root.api.core.DomainItem.encode(message.domainItem[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DownloadDomainResponse message, length delimited. Does not implicitly {@link api.core.DownloadDomainResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {api.core.IDownloadDomainResponse} message DownloadDomainResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DownloadDomainResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DownloadDomainResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DownloadDomainResponse} DownloadDomainResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DownloadDomainResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DownloadDomainResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.domainItem && message.domainItem.length))
                                message.domainItem = [];
                            message.domainItem.push($root.api.core.DomainItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DownloadDomainResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DownloadDomainResponse} DownloadDomainResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DownloadDomainResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DownloadDomainResponse message.
             * @function verify
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DownloadDomainResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.domainItem != null && message.hasOwnProperty("domainItem")) {
                    if (!Array.isArray(message.domainItem))
                        return "domainItem: array expected";
                    for (let i = 0; i < message.domainItem.length; ++i) {
                        let error = $root.api.core.DomainItem.verify(message.domainItem[i]);
                        if (error)
                            return "domainItem." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a DownloadDomainResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DownloadDomainResponse} DownloadDomainResponse
             */
            DownloadDomainResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DownloadDomainResponse)
                    return object;
                let message = new $root.api.core.DownloadDomainResponse();
                if (object.domainItem) {
                    if (!Array.isArray(object.domainItem))
                        throw TypeError(".api.core.DownloadDomainResponse.domainItem: array expected");
                    message.domainItem = [];
                    for (let i = 0; i < object.domainItem.length; ++i) {
                        if (typeof object.domainItem[i] !== "object")
                            throw TypeError(".api.core.DownloadDomainResponse.domainItem: object expected");
                        message.domainItem[i] = $root.api.core.DomainItem.fromObject(object.domainItem[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a DownloadDomainResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {api.core.DownloadDomainResponse} message DownloadDomainResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DownloadDomainResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.domainItem = [];
                if (message.domainItem && message.domainItem.length) {
                    object.domainItem = [];
                    for (let j = 0; j < message.domainItem.length; ++j)
                        object.domainItem[j] = $root.api.core.DomainItem.toObject(message.domainItem[j], options);
                }
                return object;
            };

            /**
             * Converts this DownloadDomainResponse to JSON.
             * @function toJSON
             * @memberof api.core.DownloadDomainResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DownloadDomainResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DownloadDomainResponse
             * @function getTypeUrl
             * @memberof api.core.DownloadDomainResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DownloadDomainResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DownloadDomainResponse";
            };

            return DownloadDomainResponse;
        })();

        core.CreateEntranceRequest = (function() {

            /**
             * Properties of a CreateEntranceRequest.
             * @memberof api.core
             * @interface ICreateEntranceRequest
             * @property {api.common.IEntrance|null} [entrances] CreateEntranceRequest entrances
             */

            /**
             * Constructs a new CreateEntranceRequest.
             * @memberof api.core
             * @classdesc Represents a CreateEntranceRequest.
             * @implements ICreateEntranceRequest
             * @constructor
             * @param {api.core.ICreateEntranceRequest=} [properties] Properties to set
             */
            function CreateEntranceRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateEntranceRequest entrances.
             * @member {api.common.IEntrance|null|undefined} entrances
             * @memberof api.core.CreateEntranceRequest
             * @instance
             */
            CreateEntranceRequest.prototype.entrances = null;

            /**
             * Creates a new CreateEntranceRequest instance using the specified properties.
             * @function create
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {api.core.ICreateEntranceRequest=} [properties] Properties to set
             * @returns {api.core.CreateEntranceRequest} CreateEntranceRequest instance
             */
            CreateEntranceRequest.create = function create(properties) {
                return new CreateEntranceRequest(properties);
            };

            /**
             * Encodes the specified CreateEntranceRequest message. Does not implicitly {@link api.core.CreateEntranceRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {api.core.ICreateEntranceRequest} message CreateEntranceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateEntranceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.entrances != null && Object.hasOwnProperty.call(message, "entrances"))
                    $root.api.common.Entrance.encode(message.entrances, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CreateEntranceRequest message, length delimited. Does not implicitly {@link api.core.CreateEntranceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {api.core.ICreateEntranceRequest} message CreateEntranceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateEntranceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateEntranceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateEntranceRequest} CreateEntranceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateEntranceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateEntranceRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.entrances = $root.api.common.Entrance.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateEntranceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateEntranceRequest} CreateEntranceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateEntranceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateEntranceRequest message.
             * @function verify
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateEntranceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.entrances != null && message.hasOwnProperty("entrances")) {
                    let error = $root.api.common.Entrance.verify(message.entrances);
                    if (error)
                        return "entrances." + error;
                }
                return null;
            };

            /**
             * Creates a CreateEntranceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateEntranceRequest} CreateEntranceRequest
             */
            CreateEntranceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateEntranceRequest)
                    return object;
                let message = new $root.api.core.CreateEntranceRequest();
                if (object.entrances != null) {
                    if (typeof object.entrances !== "object")
                        throw TypeError(".api.core.CreateEntranceRequest.entrances: object expected");
                    message.entrances = $root.api.common.Entrance.fromObject(object.entrances);
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateEntranceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {api.core.CreateEntranceRequest} message CreateEntranceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateEntranceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.entrances = null;
                if (message.entrances != null && message.hasOwnProperty("entrances"))
                    object.entrances = $root.api.common.Entrance.toObject(message.entrances, options);
                return object;
            };

            /**
             * Converts this CreateEntranceRequest to JSON.
             * @function toJSON
             * @memberof api.core.CreateEntranceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateEntranceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateEntranceRequest
             * @function getTypeUrl
             * @memberof api.core.CreateEntranceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateEntranceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateEntranceRequest";
            };

            return CreateEntranceRequest;
        })();

        core.CreateEntranceResponse = (function() {

            /**
             * Properties of a CreateEntranceResponse.
             * @memberof api.core
             * @interface ICreateEntranceResponse
             * @property {number|null} [id] CreateEntranceResponse id
             */

            /**
             * Constructs a new CreateEntranceResponse.
             * @memberof api.core
             * @classdesc Represents a CreateEntranceResponse.
             * @implements ICreateEntranceResponse
             * @constructor
             * @param {api.core.ICreateEntranceResponse=} [properties] Properties to set
             */
            function CreateEntranceResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateEntranceResponse id.
             * @member {number} id
             * @memberof api.core.CreateEntranceResponse
             * @instance
             */
            CreateEntranceResponse.prototype.id = 0;

            /**
             * Creates a new CreateEntranceResponse instance using the specified properties.
             * @function create
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {api.core.ICreateEntranceResponse=} [properties] Properties to set
             * @returns {api.core.CreateEntranceResponse} CreateEntranceResponse instance
             */
            CreateEntranceResponse.create = function create(properties) {
                return new CreateEntranceResponse(properties);
            };

            /**
             * Encodes the specified CreateEntranceResponse message. Does not implicitly {@link api.core.CreateEntranceResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {api.core.ICreateEntranceResponse} message CreateEntranceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateEntranceResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };

            /**
             * Encodes the specified CreateEntranceResponse message, length delimited. Does not implicitly {@link api.core.CreateEntranceResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {api.core.ICreateEntranceResponse} message CreateEntranceResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateEntranceResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateEntranceResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateEntranceResponse} CreateEntranceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateEntranceResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateEntranceResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateEntranceResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateEntranceResponse} CreateEntranceResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateEntranceResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateEntranceResponse message.
             * @function verify
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateEntranceResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };

            /**
             * Creates a CreateEntranceResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateEntranceResponse} CreateEntranceResponse
             */
            CreateEntranceResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateEntranceResponse)
                    return object;
                let message = new $root.api.core.CreateEntranceResponse();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a CreateEntranceResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {api.core.CreateEntranceResponse} message CreateEntranceResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateEntranceResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this CreateEntranceResponse to JSON.
             * @function toJSON
             * @memberof api.core.CreateEntranceResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateEntranceResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateEntranceResponse
             * @function getTypeUrl
             * @memberof api.core.CreateEntranceResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateEntranceResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateEntranceResponse";
            };

            return CreateEntranceResponse;
        })();

        core.UpdateEntranceRequest = (function() {

            /**
             * Properties of an UpdateEntranceRequest.
             * @memberof api.core
             * @interface IUpdateEntranceRequest
             * @property {api.common.IEntrance|null} [entrances] UpdateEntranceRequest entrances
             */

            /**
             * Constructs a new UpdateEntranceRequest.
             * @memberof api.core
             * @classdesc Represents an UpdateEntranceRequest.
             * @implements IUpdateEntranceRequest
             * @constructor
             * @param {api.core.IUpdateEntranceRequest=} [properties] Properties to set
             */
            function UpdateEntranceRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateEntranceRequest entrances.
             * @member {api.common.IEntrance|null|undefined} entrances
             * @memberof api.core.UpdateEntranceRequest
             * @instance
             */
            UpdateEntranceRequest.prototype.entrances = null;

            /**
             * Creates a new UpdateEntranceRequest instance using the specified properties.
             * @function create
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {api.core.IUpdateEntranceRequest=} [properties] Properties to set
             * @returns {api.core.UpdateEntranceRequest} UpdateEntranceRequest instance
             */
            UpdateEntranceRequest.create = function create(properties) {
                return new UpdateEntranceRequest(properties);
            };

            /**
             * Encodes the specified UpdateEntranceRequest message. Does not implicitly {@link api.core.UpdateEntranceRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {api.core.IUpdateEntranceRequest} message UpdateEntranceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEntranceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.entrances != null && Object.hasOwnProperty.call(message, "entrances"))
                    $root.api.common.Entrance.encode(message.entrances, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateEntranceRequest message, length delimited. Does not implicitly {@link api.core.UpdateEntranceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {api.core.IUpdateEntranceRequest} message UpdateEntranceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateEntranceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateEntranceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.UpdateEntranceRequest} UpdateEntranceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEntranceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.UpdateEntranceRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.entrances = $root.api.common.Entrance.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateEntranceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.UpdateEntranceRequest} UpdateEntranceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateEntranceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateEntranceRequest message.
             * @function verify
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateEntranceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.entrances != null && message.hasOwnProperty("entrances")) {
                    let error = $root.api.common.Entrance.verify(message.entrances);
                    if (error)
                        return "entrances." + error;
                }
                return null;
            };

            /**
             * Creates an UpdateEntranceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.UpdateEntranceRequest} UpdateEntranceRequest
             */
            UpdateEntranceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.UpdateEntranceRequest)
                    return object;
                let message = new $root.api.core.UpdateEntranceRequest();
                if (object.entrances != null) {
                    if (typeof object.entrances !== "object")
                        throw TypeError(".api.core.UpdateEntranceRequest.entrances: object expected");
                    message.entrances = $root.api.common.Entrance.fromObject(object.entrances);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateEntranceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {api.core.UpdateEntranceRequest} message UpdateEntranceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateEntranceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.entrances = null;
                if (message.entrances != null && message.hasOwnProperty("entrances"))
                    object.entrances = $root.api.common.Entrance.toObject(message.entrances, options);
                return object;
            };

            /**
             * Converts this UpdateEntranceRequest to JSON.
             * @function toJSON
             * @memberof api.core.UpdateEntranceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateEntranceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateEntranceRequest
             * @function getTypeUrl
             * @memberof api.core.UpdateEntranceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateEntranceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.UpdateEntranceRequest";
            };

            return UpdateEntranceRequest;
        })();

        core.DeleteEntranceRequest = (function() {

            /**
             * Properties of a DeleteEntranceRequest.
             * @memberof api.core
             * @interface IDeleteEntranceRequest
             * @property {number|null} [id] DeleteEntranceRequest id
             */

            /**
             * Constructs a new DeleteEntranceRequest.
             * @memberof api.core
             * @classdesc Represents a DeleteEntranceRequest.
             * @implements IDeleteEntranceRequest
             * @constructor
             * @param {api.core.IDeleteEntranceRequest=} [properties] Properties to set
             */
            function DeleteEntranceRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteEntranceRequest id.
             * @member {number} id
             * @memberof api.core.DeleteEntranceRequest
             * @instance
             */
            DeleteEntranceRequest.prototype.id = 0;

            /**
             * Creates a new DeleteEntranceRequest instance using the specified properties.
             * @function create
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {api.core.IDeleteEntranceRequest=} [properties] Properties to set
             * @returns {api.core.DeleteEntranceRequest} DeleteEntranceRequest instance
             */
            DeleteEntranceRequest.create = function create(properties) {
                return new DeleteEntranceRequest(properties);
            };

            /**
             * Encodes the specified DeleteEntranceRequest message. Does not implicitly {@link api.core.DeleteEntranceRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {api.core.IDeleteEntranceRequest} message DeleteEntranceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteEntranceRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.id);
                return writer;
            };

            /**
             * Encodes the specified DeleteEntranceRequest message, length delimited. Does not implicitly {@link api.core.DeleteEntranceRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {api.core.IDeleteEntranceRequest} message DeleteEntranceRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteEntranceRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteEntranceRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DeleteEntranceRequest} DeleteEntranceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteEntranceRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DeleteEntranceRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteEntranceRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DeleteEntranceRequest} DeleteEntranceRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteEntranceRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteEntranceRequest message.
             * @function verify
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteEntranceRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id))
                        return "id: integer expected";
                return null;
            };

            /**
             * Creates a DeleteEntranceRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DeleteEntranceRequest} DeleteEntranceRequest
             */
            DeleteEntranceRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DeleteEntranceRequest)
                    return object;
                let message = new $root.api.core.DeleteEntranceRequest();
                if (object.id != null)
                    message.id = object.id >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeleteEntranceRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {api.core.DeleteEntranceRequest} message DeleteEntranceRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteEntranceRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this DeleteEntranceRequest to JSON.
             * @function toJSON
             * @memberof api.core.DeleteEntranceRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteEntranceRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteEntranceRequest
             * @function getTypeUrl
             * @memberof api.core.DeleteEntranceRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteEntranceRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DeleteEntranceRequest";
            };

            return DeleteEntranceRequest;
        })();

        core.GetDistributionResponse = (function() {

            /**
             * Properties of a GetDistributionResponse.
             * @memberof api.core
             * @interface IGetDistributionResponse
             * @property {number|null} [tenantId] GetDistributionResponse tenantId
             * @property {string|null} [distributionType] GetDistributionResponse distributionType
             */

            /**
             * Constructs a new GetDistributionResponse.
             * @memberof api.core
             * @classdesc Represents a GetDistributionResponse.
             * @implements IGetDistributionResponse
             * @constructor
             * @param {api.core.IGetDistributionResponse=} [properties] Properties to set
             */
            function GetDistributionResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetDistributionResponse tenantId.
             * @member {number} tenantId
             * @memberof api.core.GetDistributionResponse
             * @instance
             */
            GetDistributionResponse.prototype.tenantId = 0;

            /**
             * GetDistributionResponse distributionType.
             * @member {string} distributionType
             * @memberof api.core.GetDistributionResponse
             * @instance
             */
            GetDistributionResponse.prototype.distributionType = "";

            /**
             * Creates a new GetDistributionResponse instance using the specified properties.
             * @function create
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {api.core.IGetDistributionResponse=} [properties] Properties to set
             * @returns {api.core.GetDistributionResponse} GetDistributionResponse instance
             */
            GetDistributionResponse.create = function create(properties) {
                return new GetDistributionResponse(properties);
            };

            /**
             * Encodes the specified GetDistributionResponse message. Does not implicitly {@link api.core.GetDistributionResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {api.core.IGetDistributionResponse} message GetDistributionResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDistributionResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tenantId);
                if (message.distributionType != null && Object.hasOwnProperty.call(message, "distributionType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.distributionType);
                return writer;
            };

            /**
             * Encodes the specified GetDistributionResponse message, length delimited. Does not implicitly {@link api.core.GetDistributionResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {api.core.IGetDistributionResponse} message GetDistributionResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDistributionResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetDistributionResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.GetDistributionResponse} GetDistributionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDistributionResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.GetDistributionResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tenantId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.distributionType = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetDistributionResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.GetDistributionResponse} GetDistributionResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDistributionResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetDistributionResponse message.
             * @function verify
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetDistributionResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    if (!$util.isInteger(message.tenantId))
                        return "tenantId: integer expected";
                if (message.distributionType != null && message.hasOwnProperty("distributionType"))
                    if (!$util.isString(message.distributionType))
                        return "distributionType: string expected";
                return null;
            };

            /**
             * Creates a GetDistributionResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.GetDistributionResponse} GetDistributionResponse
             */
            GetDistributionResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.GetDistributionResponse)
                    return object;
                let message = new $root.api.core.GetDistributionResponse();
                if (object.tenantId != null)
                    message.tenantId = object.tenantId | 0;
                if (object.distributionType != null)
                    message.distributionType = String(object.distributionType);
                return message;
            };

            /**
             * Creates a plain object from a GetDistributionResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {api.core.GetDistributionResponse} message GetDistributionResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetDistributionResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.tenantId = 0;
                    object.distributionType = "";
                }
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    object.tenantId = message.tenantId;
                if (message.distributionType != null && message.hasOwnProperty("distributionType"))
                    object.distributionType = message.distributionType;
                return object;
            };

            /**
             * Converts this GetDistributionResponse to JSON.
             * @function toJSON
             * @memberof api.core.GetDistributionResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetDistributionResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetDistributionResponse
             * @function getTypeUrl
             * @memberof api.core.GetDistributionResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetDistributionResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.GetDistributionResponse";
            };

            return GetDistributionResponse;
        })();

        core.SaveDistributionRequest = (function() {

            /**
             * Properties of a SaveDistributionRequest.
             * @memberof api.core
             * @interface ISaveDistributionRequest
             * @property {Array.<api.common.DistributionType>|null} [distributionType] SaveDistributionRequest distributionType
             */

            /**
             * Constructs a new SaveDistributionRequest.
             * @memberof api.core
             * @classdesc Represents a SaveDistributionRequest.
             * @implements ISaveDistributionRequest
             * @constructor
             * @param {api.core.ISaveDistributionRequest=} [properties] Properties to set
             */
            function SaveDistributionRequest(properties) {
                this.distributionType = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SaveDistributionRequest distributionType.
             * @member {Array.<api.common.DistributionType>} distributionType
             * @memberof api.core.SaveDistributionRequest
             * @instance
             */
            SaveDistributionRequest.prototype.distributionType = $util.emptyArray;

            /**
             * Creates a new SaveDistributionRequest instance using the specified properties.
             * @function create
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {api.core.ISaveDistributionRequest=} [properties] Properties to set
             * @returns {api.core.SaveDistributionRequest} SaveDistributionRequest instance
             */
            SaveDistributionRequest.create = function create(properties) {
                return new SaveDistributionRequest(properties);
            };

            /**
             * Encodes the specified SaveDistributionRequest message. Does not implicitly {@link api.core.SaveDistributionRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {api.core.ISaveDistributionRequest} message SaveDistributionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveDistributionRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.distributionType != null && message.distributionType.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (let i = 0; i < message.distributionType.length; ++i)
                        writer.int32(message.distributionType[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified SaveDistributionRequest message, length delimited. Does not implicitly {@link api.core.SaveDistributionRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {api.core.ISaveDistributionRequest} message SaveDistributionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveDistributionRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SaveDistributionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.SaveDistributionRequest} SaveDistributionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveDistributionRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.SaveDistributionRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 3: {
                            if (!(message.distributionType && message.distributionType.length))
                                message.distributionType = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.distributionType.push(reader.int32());
                            } else
                                message.distributionType.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SaveDistributionRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.SaveDistributionRequest} SaveDistributionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveDistributionRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SaveDistributionRequest message.
             * @function verify
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SaveDistributionRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.distributionType != null && message.hasOwnProperty("distributionType")) {
                    if (!Array.isArray(message.distributionType))
                        return "distributionType: array expected";
                    for (let i = 0; i < message.distributionType.length; ++i)
                        switch (message.distributionType[i]) {
                        default:
                            return "distributionType: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates a SaveDistributionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.SaveDistributionRequest} SaveDistributionRequest
             */
            SaveDistributionRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.SaveDistributionRequest)
                    return object;
                let message = new $root.api.core.SaveDistributionRequest();
                if (object.distributionType) {
                    if (!Array.isArray(object.distributionType))
                        throw TypeError(".api.core.SaveDistributionRequest.distributionType: array expected");
                    message.distributionType = [];
                    for (let i = 0; i < object.distributionType.length; ++i)
                        switch (object.distributionType[i]) {
                        default:
                            if (typeof object.distributionType[i] === "number") {
                                message.distributionType[i] = object.distributionType[i];
                                break;
                            }
                        case "DISTRIBUTION_LEISURE":
                        case 0:
                            message.distributionType[i] = 0;
                            break;
                        case "DISTRIBUTION_BUSY":
                        case 1:
                            message.distributionType[i] = 1;
                            break;
                        case "DISTRIBUTION_OFFLINE":
                        case 2:
                            message.distributionType[i] = 2;
                            break;
                        case "DISTRIBUTION_NOT_LOGIN":
                        case 3:
                            message.distributionType[i] = 3;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from a SaveDistributionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {api.core.SaveDistributionRequest} message SaveDistributionRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveDistributionRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.distributionType = [];
                if (message.distributionType && message.distributionType.length) {
                    object.distributionType = [];
                    for (let j = 0; j < message.distributionType.length; ++j)
                        object.distributionType[j] = options.enums === String ? $root.api.common.DistributionType[message.distributionType[j]] === undefined ? message.distributionType[j] : $root.api.common.DistributionType[message.distributionType[j]] : message.distributionType[j];
                }
                return object;
            };

            /**
             * Converts this SaveDistributionRequest to JSON.
             * @function toJSON
             * @memberof api.core.SaveDistributionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveDistributionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveDistributionRequest
             * @function getTypeUrl
             * @memberof api.core.SaveDistributionRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveDistributionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.SaveDistributionRequest";
            };

            return SaveDistributionRequest;
        })();

        core.EntranceExistsRequest = (function() {

            /**
             * Properties of an EntranceExistsRequest.
             * @memberof api.core
             * @interface IEntranceExistsRequest
             * @property {number|null} [entranceId] EntranceExistsRequest entranceId
             * @property {Long|null} [clientId] EntranceExistsRequest clientId
             */

            /**
             * Constructs a new EntranceExistsRequest.
             * @memberof api.core
             * @classdesc Represents an EntranceExistsRequest.
             * @implements IEntranceExistsRequest
             * @constructor
             * @param {api.core.IEntranceExistsRequest=} [properties] Properties to set
             */
            function EntranceExistsRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EntranceExistsRequest entranceId.
             * @member {number|null|undefined} entranceId
             * @memberof api.core.EntranceExistsRequest
             * @instance
             */
            EntranceExistsRequest.prototype.entranceId = null;

            /**
             * EntranceExistsRequest clientId.
             * @member {Long|null|undefined} clientId
             * @memberof api.core.EntranceExistsRequest
             * @instance
             */
            EntranceExistsRequest.prototype.clientId = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * EntranceExistsRequest based.
             * @member {"entranceId"|"clientId"|undefined} based
             * @memberof api.core.EntranceExistsRequest
             * @instance
             */
            Object.defineProperty(EntranceExistsRequest.prototype, "based", {
                get: $util.oneOfGetter($oneOfFields = ["entranceId", "clientId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new EntranceExistsRequest instance using the specified properties.
             * @function create
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {api.core.IEntranceExistsRequest=} [properties] Properties to set
             * @returns {api.core.EntranceExistsRequest} EntranceExistsRequest instance
             */
            EntranceExistsRequest.create = function create(properties) {
                return new EntranceExistsRequest(properties);
            };

            /**
             * Encodes the specified EntranceExistsRequest message. Does not implicitly {@link api.core.EntranceExistsRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {api.core.IEntranceExistsRequest} message EntranceExistsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EntranceExistsRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.entranceId != null && Object.hasOwnProperty.call(message, "entranceId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.entranceId);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.clientId);
                return writer;
            };

            /**
             * Encodes the specified EntranceExistsRequest message, length delimited. Does not implicitly {@link api.core.EntranceExistsRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {api.core.IEntranceExistsRequest} message EntranceExistsRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EntranceExistsRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EntranceExistsRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.EntranceExistsRequest} EntranceExistsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EntranceExistsRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.EntranceExistsRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.entranceId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.clientId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EntranceExistsRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.EntranceExistsRequest} EntranceExistsRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EntranceExistsRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EntranceExistsRequest message.
             * @function verify
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EntranceExistsRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.entranceId != null && message.hasOwnProperty("entranceId")) {
                    properties.based = 1;
                    if (!$util.isInteger(message.entranceId))
                        return "entranceId: integer expected";
                }
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    if (properties.based === 1)
                        return "based: multiple values";
                    properties.based = 1;
                    if (!$util.isInteger(message.clientId) && !(message.clientId && $util.isInteger(message.clientId.low) && $util.isInteger(message.clientId.high)))
                        return "clientId: integer|Long expected";
                }
                return null;
            };

            /**
             * Creates an EntranceExistsRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.EntranceExistsRequest} EntranceExistsRequest
             */
            EntranceExistsRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.EntranceExistsRequest)
                    return object;
                let message = new $root.api.core.EntranceExistsRequest();
                if (object.entranceId != null)
                    message.entranceId = object.entranceId | 0;
                if (object.clientId != null)
                    if ($util.Long)
                        (message.clientId = $util.Long.fromValue(object.clientId)).unsigned = false;
                    else if (typeof object.clientId === "string")
                        message.clientId = parseInt(object.clientId, 10);
                    else if (typeof object.clientId === "number")
                        message.clientId = object.clientId;
                    else if (typeof object.clientId === "object")
                        message.clientId = new $util.LongBits(object.clientId.low >>> 0, object.clientId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an EntranceExistsRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {api.core.EntranceExistsRequest} message EntranceExistsRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EntranceExistsRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (message.entranceId != null && message.hasOwnProperty("entranceId")) {
                    object.entranceId = message.entranceId;
                    if (options.oneofs)
                        object.based = "entranceId";
                }
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    if (typeof message.clientId === "number")
                        object.clientId = options.longs === String ? String(message.clientId) : message.clientId;
                    else
                        object.clientId = options.longs === String ? $util.Long.prototype.toString.call(message.clientId) : options.longs === Number ? new $util.LongBits(message.clientId.low >>> 0, message.clientId.high >>> 0).toNumber() : message.clientId;
                    if (options.oneofs)
                        object.based = "clientId";
                }
                return object;
            };

            /**
             * Converts this EntranceExistsRequest to JSON.
             * @function toJSON
             * @memberof api.core.EntranceExistsRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EntranceExistsRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EntranceExistsRequest
             * @function getTypeUrl
             * @memberof api.core.EntranceExistsRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EntranceExistsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.EntranceExistsRequest";
            };

            return EntranceExistsRequest;
        })();

        core.Entrance = (function() {

            /**
             * Constructs a new Entrance service.
             * @memberof api.core
             * @classdesc Represents an Entrance
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Entrance(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Entrance.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Entrance;

            /**
             * Creates new Entrance service using the specified rpc implementation.
             * @function create
             * @memberof api.core.Entrance
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Entrance} RPC service. Useful where requests and/or responses are streamed.
             */
            Entrance.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.Entrance#getDistributionMechanism}.
             * @memberof api.core.Entrance
             * @typedef GetDistributionMechanismCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.GetDistributionResponse} [response] GetDistributionResponse
             */

            /**
             * Calls GetDistributionMechanism.
             * @function getDistributionMechanism
             * @memberof api.core.Entrance
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Entrance.GetDistributionMechanismCallback} callback Node-style callback called with the error, if any, and GetDistributionResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Entrance.prototype.getDistributionMechanism = function getDistributionMechanism(request, callback) {
                return this.rpcCall(getDistributionMechanism, $root.google.protobuf.Empty, $root.api.core.GetDistributionResponse, request, callback);
            }, "name", { value: "GetDistributionMechanism" });

            /**
             * Calls GetDistributionMechanism.
             * @function getDistributionMechanism
             * @memberof api.core.Entrance
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.GetDistributionResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Entrance#saveDistributionMechanism}.
             * @memberof api.core.Entrance
             * @typedef SaveDistributionMechanismCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.DownloadDomainResponse} [response] DownloadDomainResponse
             */

            /**
             * Calls SaveDistributionMechanism.
             * @function saveDistributionMechanism
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.ISaveDistributionRequest} request SaveDistributionRequest message or plain object
             * @param {api.core.Entrance.SaveDistributionMechanismCallback} callback Node-style callback called with the error, if any, and DownloadDomainResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Entrance.prototype.saveDistributionMechanism = function saveDistributionMechanism(request, callback) {
                return this.rpcCall(saveDistributionMechanism, $root.api.core.SaveDistributionRequest, $root.api.core.DownloadDomainResponse, request, callback);
            }, "name", { value: "SaveDistributionMechanism" });

            /**
             * Calls SaveDistributionMechanism.
             * @function saveDistributionMechanism
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.ISaveDistributionRequest} request SaveDistributionRequest message or plain object
             * @returns {Promise<api.core.DownloadDomainResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Entrance#downloadDomain}.
             * @memberof api.core.Entrance
             * @typedef DownloadDomainCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.DownloadDomainResponse} [response] DownloadDomainResponse
             */

            /**
             * Calls DownloadDomain.
             * @function downloadDomain
             * @memberof api.core.Entrance
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Entrance.DownloadDomainCallback} callback Node-style callback called with the error, if any, and DownloadDomainResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Entrance.prototype.downloadDomain = function downloadDomain(request, callback) {
                return this.rpcCall(downloadDomain, $root.google.protobuf.Empty, $root.api.core.DownloadDomainResponse, request, callback);
            }, "name", { value: "DownloadDomain" });

            /**
             * Calls DownloadDomain.
             * @function downloadDomain
             * @memberof api.core.Entrance
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.DownloadDomainResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Entrance#queryEntrance}.
             * @memberof api.core.Entrance
             * @typedef QueryEntranceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.EntranceQueryResponse} [response] EntranceQueryResponse
             */

            /**
             * Calls QueryEntrance.
             * @function queryEntrance
             * @memberof api.core.Entrance
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Entrance.QueryEntranceCallback} callback Node-style callback called with the error, if any, and EntranceQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Entrance.prototype.queryEntrance = function queryEntrance(request, callback) {
                return this.rpcCall(queryEntrance, $root.google.protobuf.Empty, $root.api.core.EntranceQueryResponse, request, callback);
            }, "name", { value: "QueryEntrance" });

            /**
             * Calls QueryEntrance.
             * @function queryEntrance
             * @memberof api.core.Entrance
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.EntranceQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Entrance#createEntrance}.
             * @memberof api.core.Entrance
             * @typedef CreateEntranceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.CreateEntranceResponse} [response] CreateEntranceResponse
             */

            /**
             * Calls CreateEntrance.
             * @function createEntrance
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.ICreateEntranceRequest} request CreateEntranceRequest message or plain object
             * @param {api.core.Entrance.CreateEntranceCallback} callback Node-style callback called with the error, if any, and CreateEntranceResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Entrance.prototype.createEntrance = function createEntrance(request, callback) {
                return this.rpcCall(createEntrance, $root.api.core.CreateEntranceRequest, $root.api.core.CreateEntranceResponse, request, callback);
            }, "name", { value: "CreateEntrance" });

            /**
             * Calls CreateEntrance.
             * @function createEntrance
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.ICreateEntranceRequest} request CreateEntranceRequest message or plain object
             * @returns {Promise<api.core.CreateEntranceResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Entrance#updateEntrance}.
             * @memberof api.core.Entrance
             * @typedef UpdateEntranceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls UpdateEntrance.
             * @function updateEntrance
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.IUpdateEntranceRequest} request UpdateEntranceRequest message or plain object
             * @param {api.core.Entrance.UpdateEntranceCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Entrance.prototype.updateEntrance = function updateEntrance(request, callback) {
                return this.rpcCall(updateEntrance, $root.api.core.UpdateEntranceRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "UpdateEntrance" });

            /**
             * Calls UpdateEntrance.
             * @function updateEntrance
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.IUpdateEntranceRequest} request UpdateEntranceRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Entrance#deleteEntrance}.
             * @memberof api.core.Entrance
             * @typedef DeleteEntranceCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteEntrance.
             * @function deleteEntrance
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.IDeleteEntranceRequest} request DeleteEntranceRequest message or plain object
             * @param {api.core.Entrance.DeleteEntranceCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Entrance.prototype.deleteEntrance = function deleteEntrance(request, callback) {
                return this.rpcCall(deleteEntrance, $root.api.core.DeleteEntranceRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteEntrance" });

            /**
             * Calls DeleteEntrance.
             * @function deleteEntrance
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.IDeleteEntranceRequest} request DeleteEntranceRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Entrance#exists}.
             * @memberof api.core.Entrance
             * @typedef ExistsCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Exists.
             * @function exists
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.IEntranceExistsRequest} request EntranceExistsRequest message or plain object
             * @param {api.core.Entrance.ExistsCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Entrance.prototype.exists = function exists(request, callback) {
                return this.rpcCall(exists, $root.api.core.EntranceExistsRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Exists" });

            /**
             * Calls Exists.
             * @function exists
             * @memberof api.core.Entrance
             * @instance
             * @param {api.core.IEntranceExistsRequest} request EntranceExistsRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return Entrance;
        })();

        core.GatewayNodeRequest = (function() {

            /**
             * Properties of a GatewayNodeRequest.
             * @memberof api.core
             * @interface IGatewayNodeRequest
             * @property {number|null} [gatewayId] GatewayNodeRequest gatewayId
             */

            /**
             * Constructs a new GatewayNodeRequest.
             * @memberof api.core
             * @classdesc Represents a GatewayNodeRequest.
             * @implements IGatewayNodeRequest
             * @constructor
             * @param {api.core.IGatewayNodeRequest=} [properties] Properties to set
             */
            function GatewayNodeRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GatewayNodeRequest gatewayId.
             * @member {number} gatewayId
             * @memberof api.core.GatewayNodeRequest
             * @instance
             */
            GatewayNodeRequest.prototype.gatewayId = 0;

            /**
             * Creates a new GatewayNodeRequest instance using the specified properties.
             * @function create
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {api.core.IGatewayNodeRequest=} [properties] Properties to set
             * @returns {api.core.GatewayNodeRequest} GatewayNodeRequest instance
             */
            GatewayNodeRequest.create = function create(properties) {
                return new GatewayNodeRequest(properties);
            };

            /**
             * Encodes the specified GatewayNodeRequest message. Does not implicitly {@link api.core.GatewayNodeRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {api.core.IGatewayNodeRequest} message GatewayNodeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayNodeRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.gatewayId != null && Object.hasOwnProperty.call(message, "gatewayId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gatewayId);
                return writer;
            };

            /**
             * Encodes the specified GatewayNodeRequest message, length delimited. Does not implicitly {@link api.core.GatewayNodeRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {api.core.IGatewayNodeRequest} message GatewayNodeRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayNodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GatewayNodeRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.GatewayNodeRequest} GatewayNodeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayNodeRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.GatewayNodeRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.gatewayId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GatewayNodeRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.GatewayNodeRequest} GatewayNodeRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayNodeRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GatewayNodeRequest message.
             * @function verify
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GatewayNodeRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    if (!$util.isInteger(message.gatewayId))
                        return "gatewayId: integer expected";
                return null;
            };

            /**
             * Creates a GatewayNodeRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.GatewayNodeRequest} GatewayNodeRequest
             */
            GatewayNodeRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.GatewayNodeRequest)
                    return object;
                let message = new $root.api.core.GatewayNodeRequest();
                if (object.gatewayId != null)
                    message.gatewayId = object.gatewayId | 0;
                return message;
            };

            /**
             * Creates a plain object from a GatewayNodeRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {api.core.GatewayNodeRequest} message GatewayNodeRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GatewayNodeRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.gatewayId = 0;
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    object.gatewayId = message.gatewayId;
                return object;
            };

            /**
             * Converts this GatewayNodeRequest to JSON.
             * @function toJSON
             * @memberof api.core.GatewayNodeRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GatewayNodeRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GatewayNodeRequest
             * @function getTypeUrl
             * @memberof api.core.GatewayNodeRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GatewayNodeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.GatewayNodeRequest";
            };

            return GatewayNodeRequest;
        })();

        core.GatewayRegisterRequest = (function() {

            /**
             * Properties of a GatewayRegisterRequest.
             * @memberof api.core
             * @interface IGatewayRegisterRequest
             * @property {number|null} [gatewayId] GatewayRegisterRequest gatewayId
             * @property {api.common.ClientType|null} [clientType] GatewayRegisterRequest clientType
             */

            /**
             * Constructs a new GatewayRegisterRequest.
             * @memberof api.core
             * @classdesc Represents a GatewayRegisterRequest.
             * @implements IGatewayRegisterRequest
             * @constructor
             * @param {api.core.IGatewayRegisterRequest=} [properties] Properties to set
             */
            function GatewayRegisterRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GatewayRegisterRequest gatewayId.
             * @member {number} gatewayId
             * @memberof api.core.GatewayRegisterRequest
             * @instance
             */
            GatewayRegisterRequest.prototype.gatewayId = 0;

            /**
             * GatewayRegisterRequest clientType.
             * @member {api.common.ClientType} clientType
             * @memberof api.core.GatewayRegisterRequest
             * @instance
             */
            GatewayRegisterRequest.prototype.clientType = 0;

            /**
             * Creates a new GatewayRegisterRequest instance using the specified properties.
             * @function create
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {api.core.IGatewayRegisterRequest=} [properties] Properties to set
             * @returns {api.core.GatewayRegisterRequest} GatewayRegisterRequest instance
             */
            GatewayRegisterRequest.create = function create(properties) {
                return new GatewayRegisterRequest(properties);
            };

            /**
             * Encodes the specified GatewayRegisterRequest message. Does not implicitly {@link api.core.GatewayRegisterRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {api.core.IGatewayRegisterRequest} message GatewayRegisterRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayRegisterRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.gatewayId != null && Object.hasOwnProperty.call(message, "gatewayId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gatewayId);
                if (message.clientType != null && Object.hasOwnProperty.call(message, "clientType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.clientType);
                return writer;
            };

            /**
             * Encodes the specified GatewayRegisterRequest message, length delimited. Does not implicitly {@link api.core.GatewayRegisterRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {api.core.IGatewayRegisterRequest} message GatewayRegisterRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayRegisterRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GatewayRegisterRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.GatewayRegisterRequest} GatewayRegisterRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayRegisterRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.GatewayRegisterRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.gatewayId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.clientType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GatewayRegisterRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.GatewayRegisterRequest} GatewayRegisterRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayRegisterRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GatewayRegisterRequest message.
             * @function verify
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GatewayRegisterRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    if (!$util.isInteger(message.gatewayId))
                        return "gatewayId: integer expected";
                if (message.clientType != null && message.hasOwnProperty("clientType"))
                    switch (message.clientType) {
                    default:
                        return "clientType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 100:
                    case 101:
                    case 102:
                    case 103:
                    case 200:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GatewayRegisterRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.GatewayRegisterRequest} GatewayRegisterRequest
             */
            GatewayRegisterRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.GatewayRegisterRequest)
                    return object;
                let message = new $root.api.core.GatewayRegisterRequest();
                if (object.gatewayId != null)
                    message.gatewayId = object.gatewayId | 0;
                switch (object.clientType) {
                default:
                    if (typeof object.clientType === "number") {
                        message.clientType = object.clientType;
                        break;
                    }
                    break;
                case "CLIENT_TYPE_UNKNOWN":
                case 0:
                    message.clientType = 0;
                    break;
                case "CLIENT_TYPE_WORKER_BEGIN":
                case 1:
                    message.clientType = 1;
                    break;
                case "CLIENT_TYPE_WORKER_PC":
                case 2:
                    message.clientType = 2;
                    break;
                case "CLIENT_TYPE_WORKER_END":
                case 100:
                    message.clientType = 100;
                    break;
                case "CLIENT_TYPE_USER_BEGIN":
                case 101:
                    message.clientType = 101;
                    break;
                case "CLIENT_TYPE_USER_H5":
                case 102:
                    message.clientType = 102;
                    break;
                case "CLIENT_TYPE_USER_WEB":
                case 103:
                    message.clientType = 103;
                    break;
                case "CLIENT_TYPE_USER_END":
                case 200:
                    message.clientType = 200;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GatewayRegisterRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {api.core.GatewayRegisterRequest} message GatewayRegisterRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GatewayRegisterRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.gatewayId = 0;
                    object.clientType = options.enums === String ? "CLIENT_TYPE_UNKNOWN" : 0;
                }
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    object.gatewayId = message.gatewayId;
                if (message.clientType != null && message.hasOwnProperty("clientType"))
                    object.clientType = options.enums === String ? $root.api.common.ClientType[message.clientType] === undefined ? message.clientType : $root.api.common.ClientType[message.clientType] : message.clientType;
                return object;
            };

            /**
             * Converts this GatewayRegisterRequest to JSON.
             * @function toJSON
             * @memberof api.core.GatewayRegisterRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GatewayRegisterRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GatewayRegisterRequest
             * @function getTypeUrl
             * @memberof api.core.GatewayRegisterRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GatewayRegisterRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.GatewayRegisterRequest";
            };

            return GatewayRegisterRequest;
        })();

        core.GatewayRegisterResponse = (function() {

            /**
             * Properties of a GatewayRegisterResponse.
             * @memberof api.core
             * @interface IGatewayRegisterResponse
             * @property {Long|null} [websocketId] GatewayRegisterResponse websocketId
             * @property {Long|null} [websocketTime] GatewayRegisterResponse websocketTime
             * @property {boolean|null} [websocketFlag] GatewayRegisterResponse websocketFlag
             * @property {Long|null} [lastSocketId] GatewayRegisterResponse lastSocketId
             * @property {Long|null} [lastSocketTime] GatewayRegisterResponse lastSocketTime
             */

            /**
             * Constructs a new GatewayRegisterResponse.
             * @memberof api.core
             * @classdesc Represents a GatewayRegisterResponse.
             * @implements IGatewayRegisterResponse
             * @constructor
             * @param {api.core.IGatewayRegisterResponse=} [properties] Properties to set
             */
            function GatewayRegisterResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GatewayRegisterResponse websocketId.
             * @member {Long} websocketId
             * @memberof api.core.GatewayRegisterResponse
             * @instance
             */
            GatewayRegisterResponse.prototype.websocketId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GatewayRegisterResponse websocketTime.
             * @member {Long} websocketTime
             * @memberof api.core.GatewayRegisterResponse
             * @instance
             */
            GatewayRegisterResponse.prototype.websocketTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GatewayRegisterResponse websocketFlag.
             * @member {boolean} websocketFlag
             * @memberof api.core.GatewayRegisterResponse
             * @instance
             */
            GatewayRegisterResponse.prototype.websocketFlag = false;

            /**
             * GatewayRegisterResponse lastSocketId.
             * @member {Long} lastSocketId
             * @memberof api.core.GatewayRegisterResponse
             * @instance
             */
            GatewayRegisterResponse.prototype.lastSocketId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GatewayRegisterResponse lastSocketTime.
             * @member {Long} lastSocketTime
             * @memberof api.core.GatewayRegisterResponse
             * @instance
             */
            GatewayRegisterResponse.prototype.lastSocketTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new GatewayRegisterResponse instance using the specified properties.
             * @function create
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {api.core.IGatewayRegisterResponse=} [properties] Properties to set
             * @returns {api.core.GatewayRegisterResponse} GatewayRegisterResponse instance
             */
            GatewayRegisterResponse.create = function create(properties) {
                return new GatewayRegisterResponse(properties);
            };

            /**
             * Encodes the specified GatewayRegisterResponse message. Does not implicitly {@link api.core.GatewayRegisterResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {api.core.IGatewayRegisterResponse} message GatewayRegisterResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayRegisterResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.websocketId != null && Object.hasOwnProperty.call(message, "websocketId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.websocketId);
                if (message.websocketTime != null && Object.hasOwnProperty.call(message, "websocketTime"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.websocketTime);
                if (message.websocketFlag != null && Object.hasOwnProperty.call(message, "websocketFlag"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.websocketFlag);
                if (message.lastSocketId != null && Object.hasOwnProperty.call(message, "lastSocketId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.lastSocketId);
                if (message.lastSocketTime != null && Object.hasOwnProperty.call(message, "lastSocketTime"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.lastSocketTime);
                return writer;
            };

            /**
             * Encodes the specified GatewayRegisterResponse message, length delimited. Does not implicitly {@link api.core.GatewayRegisterResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {api.core.IGatewayRegisterResponse} message GatewayRegisterResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayRegisterResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GatewayRegisterResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.GatewayRegisterResponse} GatewayRegisterResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayRegisterResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.GatewayRegisterResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.websocketId = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.websocketTime = reader.int64();
                            break;
                        }
                    case 3: {
                            message.websocketFlag = reader.bool();
                            break;
                        }
                    case 4: {
                            message.lastSocketId = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.lastSocketTime = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GatewayRegisterResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.GatewayRegisterResponse} GatewayRegisterResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayRegisterResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GatewayRegisterResponse message.
             * @function verify
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GatewayRegisterResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.websocketId != null && message.hasOwnProperty("websocketId"))
                    if (!$util.isInteger(message.websocketId) && !(message.websocketId && $util.isInteger(message.websocketId.low) && $util.isInteger(message.websocketId.high)))
                        return "websocketId: integer|Long expected";
                if (message.websocketTime != null && message.hasOwnProperty("websocketTime"))
                    if (!$util.isInteger(message.websocketTime) && !(message.websocketTime && $util.isInteger(message.websocketTime.low) && $util.isInteger(message.websocketTime.high)))
                        return "websocketTime: integer|Long expected";
                if (message.websocketFlag != null && message.hasOwnProperty("websocketFlag"))
                    if (typeof message.websocketFlag !== "boolean")
                        return "websocketFlag: boolean expected";
                if (message.lastSocketId != null && message.hasOwnProperty("lastSocketId"))
                    if (!$util.isInteger(message.lastSocketId) && !(message.lastSocketId && $util.isInteger(message.lastSocketId.low) && $util.isInteger(message.lastSocketId.high)))
                        return "lastSocketId: integer|Long expected";
                if (message.lastSocketTime != null && message.hasOwnProperty("lastSocketTime"))
                    if (!$util.isInteger(message.lastSocketTime) && !(message.lastSocketTime && $util.isInteger(message.lastSocketTime.low) && $util.isInteger(message.lastSocketTime.high)))
                        return "lastSocketTime: integer|Long expected";
                return null;
            };

            /**
             * Creates a GatewayRegisterResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.GatewayRegisterResponse} GatewayRegisterResponse
             */
            GatewayRegisterResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.GatewayRegisterResponse)
                    return object;
                let message = new $root.api.core.GatewayRegisterResponse();
                if (object.websocketId != null)
                    if ($util.Long)
                        (message.websocketId = $util.Long.fromValue(object.websocketId)).unsigned = true;
                    else if (typeof object.websocketId === "string")
                        message.websocketId = parseInt(object.websocketId, 10);
                    else if (typeof object.websocketId === "number")
                        message.websocketId = object.websocketId;
                    else if (typeof object.websocketId === "object")
                        message.websocketId = new $util.LongBits(object.websocketId.low >>> 0, object.websocketId.high >>> 0).toNumber(true);
                if (object.websocketTime != null)
                    if ($util.Long)
                        (message.websocketTime = $util.Long.fromValue(object.websocketTime)).unsigned = false;
                    else if (typeof object.websocketTime === "string")
                        message.websocketTime = parseInt(object.websocketTime, 10);
                    else if (typeof object.websocketTime === "number")
                        message.websocketTime = object.websocketTime;
                    else if (typeof object.websocketTime === "object")
                        message.websocketTime = new $util.LongBits(object.websocketTime.low >>> 0, object.websocketTime.high >>> 0).toNumber();
                if (object.websocketFlag != null)
                    message.websocketFlag = Boolean(object.websocketFlag);
                if (object.lastSocketId != null)
                    if ($util.Long)
                        (message.lastSocketId = $util.Long.fromValue(object.lastSocketId)).unsigned = true;
                    else if (typeof object.lastSocketId === "string")
                        message.lastSocketId = parseInt(object.lastSocketId, 10);
                    else if (typeof object.lastSocketId === "number")
                        message.lastSocketId = object.lastSocketId;
                    else if (typeof object.lastSocketId === "object")
                        message.lastSocketId = new $util.LongBits(object.lastSocketId.low >>> 0, object.lastSocketId.high >>> 0).toNumber(true);
                if (object.lastSocketTime != null)
                    if ($util.Long)
                        (message.lastSocketTime = $util.Long.fromValue(object.lastSocketTime)).unsigned = false;
                    else if (typeof object.lastSocketTime === "string")
                        message.lastSocketTime = parseInt(object.lastSocketTime, 10);
                    else if (typeof object.lastSocketTime === "number")
                        message.lastSocketTime = object.lastSocketTime;
                    else if (typeof object.lastSocketTime === "object")
                        message.lastSocketTime = new $util.LongBits(object.lastSocketTime.low >>> 0, object.lastSocketTime.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a GatewayRegisterResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {api.core.GatewayRegisterResponse} message GatewayRegisterResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GatewayRegisterResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.websocketId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.websocketId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.websocketTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.websocketTime = options.longs === String ? "0" : 0;
                    object.websocketFlag = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.lastSocketId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastSocketId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.lastSocketTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastSocketTime = options.longs === String ? "0" : 0;
                }
                if (message.websocketId != null && message.hasOwnProperty("websocketId"))
                    if (typeof message.websocketId === "number")
                        object.websocketId = options.longs === String ? String(message.websocketId) : message.websocketId;
                    else
                        object.websocketId = options.longs === String ? $util.Long.prototype.toString.call(message.websocketId) : options.longs === Number ? new $util.LongBits(message.websocketId.low >>> 0, message.websocketId.high >>> 0).toNumber(true) : message.websocketId;
                if (message.websocketTime != null && message.hasOwnProperty("websocketTime"))
                    if (typeof message.websocketTime === "number")
                        object.websocketTime = options.longs === String ? String(message.websocketTime) : message.websocketTime;
                    else
                        object.websocketTime = options.longs === String ? $util.Long.prototype.toString.call(message.websocketTime) : options.longs === Number ? new $util.LongBits(message.websocketTime.low >>> 0, message.websocketTime.high >>> 0).toNumber() : message.websocketTime;
                if (message.websocketFlag != null && message.hasOwnProperty("websocketFlag"))
                    object.websocketFlag = message.websocketFlag;
                if (message.lastSocketId != null && message.hasOwnProperty("lastSocketId"))
                    if (typeof message.lastSocketId === "number")
                        object.lastSocketId = options.longs === String ? String(message.lastSocketId) : message.lastSocketId;
                    else
                        object.lastSocketId = options.longs === String ? $util.Long.prototype.toString.call(message.lastSocketId) : options.longs === Number ? new $util.LongBits(message.lastSocketId.low >>> 0, message.lastSocketId.high >>> 0).toNumber(true) : message.lastSocketId;
                if (message.lastSocketTime != null && message.hasOwnProperty("lastSocketTime"))
                    if (typeof message.lastSocketTime === "number")
                        object.lastSocketTime = options.longs === String ? String(message.lastSocketTime) : message.lastSocketTime;
                    else
                        object.lastSocketTime = options.longs === String ? $util.Long.prototype.toString.call(message.lastSocketTime) : options.longs === Number ? new $util.LongBits(message.lastSocketTime.low >>> 0, message.lastSocketTime.high >>> 0).toNumber() : message.lastSocketTime;
                return object;
            };

            /**
             * Converts this GatewayRegisterResponse to JSON.
             * @function toJSON
             * @memberof api.core.GatewayRegisterResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GatewayRegisterResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GatewayRegisterResponse
             * @function getTypeUrl
             * @memberof api.core.GatewayRegisterResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GatewayRegisterResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.GatewayRegisterResponse";
            };

            return GatewayRegisterResponse;
        })();

        core.OnlineTenantWorkers = (function() {

            /**
             * Properties of an OnlineTenantWorkers.
             * @memberof api.core
             * @interface IOnlineTenantWorkers
             * @property {number|null} [tenant] OnlineTenantWorkers tenant
             * @property {Array.<number>|null} [workers] OnlineTenantWorkers workers
             */

            /**
             * Constructs a new OnlineTenantWorkers.
             * @memberof api.core
             * @classdesc Represents an OnlineTenantWorkers.
             * @implements IOnlineTenantWorkers
             * @constructor
             * @param {api.core.IOnlineTenantWorkers=} [properties] Properties to set
             */
            function OnlineTenantWorkers(properties) {
                this.workers = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OnlineTenantWorkers tenant.
             * @member {number} tenant
             * @memberof api.core.OnlineTenantWorkers
             * @instance
             */
            OnlineTenantWorkers.prototype.tenant = 0;

            /**
             * OnlineTenantWorkers workers.
             * @member {Array.<number>} workers
             * @memberof api.core.OnlineTenantWorkers
             * @instance
             */
            OnlineTenantWorkers.prototype.workers = $util.emptyArray;

            /**
             * Creates a new OnlineTenantWorkers instance using the specified properties.
             * @function create
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {api.core.IOnlineTenantWorkers=} [properties] Properties to set
             * @returns {api.core.OnlineTenantWorkers} OnlineTenantWorkers instance
             */
            OnlineTenantWorkers.create = function create(properties) {
                return new OnlineTenantWorkers(properties);
            };

            /**
             * Encodes the specified OnlineTenantWorkers message. Does not implicitly {@link api.core.OnlineTenantWorkers.verify|verify} messages.
             * @function encode
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {api.core.IOnlineTenantWorkers} message OnlineTenantWorkers message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OnlineTenantWorkers.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tenant != null && Object.hasOwnProperty.call(message, "tenant"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tenant);
                if (message.workers != null && message.workers.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (let i = 0; i < message.workers.length; ++i)
                        writer.int32(message.workers[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified OnlineTenantWorkers message, length delimited. Does not implicitly {@link api.core.OnlineTenantWorkers.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {api.core.IOnlineTenantWorkers} message OnlineTenantWorkers message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OnlineTenantWorkers.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OnlineTenantWorkers message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.OnlineTenantWorkers} OnlineTenantWorkers
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OnlineTenantWorkers.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.OnlineTenantWorkers();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tenant = reader.int32();
                            break;
                        }
                    case 2: {
                            if (!(message.workers && message.workers.length))
                                message.workers = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.workers.push(reader.int32());
                            } else
                                message.workers.push(reader.int32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OnlineTenantWorkers message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.OnlineTenantWorkers} OnlineTenantWorkers
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OnlineTenantWorkers.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OnlineTenantWorkers message.
             * @function verify
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OnlineTenantWorkers.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tenant != null && message.hasOwnProperty("tenant"))
                    if (!$util.isInteger(message.tenant))
                        return "tenant: integer expected";
                if (message.workers != null && message.hasOwnProperty("workers")) {
                    if (!Array.isArray(message.workers))
                        return "workers: array expected";
                    for (let i = 0; i < message.workers.length; ++i)
                        if (!$util.isInteger(message.workers[i]))
                            return "workers: integer[] expected";
                }
                return null;
            };

            /**
             * Creates an OnlineTenantWorkers message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.OnlineTenantWorkers} OnlineTenantWorkers
             */
            OnlineTenantWorkers.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.OnlineTenantWorkers)
                    return object;
                let message = new $root.api.core.OnlineTenantWorkers();
                if (object.tenant != null)
                    message.tenant = object.tenant | 0;
                if (object.workers) {
                    if (!Array.isArray(object.workers))
                        throw TypeError(".api.core.OnlineTenantWorkers.workers: array expected");
                    message.workers = [];
                    for (let i = 0; i < object.workers.length; ++i)
                        message.workers[i] = object.workers[i] | 0;
                }
                return message;
            };

            /**
             * Creates a plain object from an OnlineTenantWorkers message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {api.core.OnlineTenantWorkers} message OnlineTenantWorkers
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OnlineTenantWorkers.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.workers = [];
                if (options.defaults)
                    object.tenant = 0;
                if (message.tenant != null && message.hasOwnProperty("tenant"))
                    object.tenant = message.tenant;
                if (message.workers && message.workers.length) {
                    object.workers = [];
                    for (let j = 0; j < message.workers.length; ++j)
                        object.workers[j] = message.workers[j];
                }
                return object;
            };

            /**
             * Converts this OnlineTenantWorkers to JSON.
             * @function toJSON
             * @memberof api.core.OnlineTenantWorkers
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OnlineTenantWorkers.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OnlineTenantWorkers
             * @function getTypeUrl
             * @memberof api.core.OnlineTenantWorkers
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OnlineTenantWorkers.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.OnlineTenantWorkers";
            };

            return OnlineTenantWorkers;
        })();

        core.GatewayBeaconRequest = (function() {

            /**
             * Properties of a GatewayBeaconRequest.
             * @memberof api.core
             * @interface IGatewayBeaconRequest
             * @property {number|null} [gatewayId] GatewayBeaconRequest gatewayId
             * @property {number|null} [duration] GatewayBeaconRequest duration
             * @property {Array.<api.core.IOnlineTenantWorkers>|null} [tenants] GatewayBeaconRequest tenants
             */

            /**
             * Constructs a new GatewayBeaconRequest.
             * @memberof api.core
             * @classdesc Represents a GatewayBeaconRequest.
             * @implements IGatewayBeaconRequest
             * @constructor
             * @param {api.core.IGatewayBeaconRequest=} [properties] Properties to set
             */
            function GatewayBeaconRequest(properties) {
                this.tenants = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GatewayBeaconRequest gatewayId.
             * @member {number} gatewayId
             * @memberof api.core.GatewayBeaconRequest
             * @instance
             */
            GatewayBeaconRequest.prototype.gatewayId = 0;

            /**
             * GatewayBeaconRequest duration.
             * @member {number} duration
             * @memberof api.core.GatewayBeaconRequest
             * @instance
             */
            GatewayBeaconRequest.prototype.duration = 0;

            /**
             * GatewayBeaconRequest tenants.
             * @member {Array.<api.core.IOnlineTenantWorkers>} tenants
             * @memberof api.core.GatewayBeaconRequest
             * @instance
             */
            GatewayBeaconRequest.prototype.tenants = $util.emptyArray;

            /**
             * Creates a new GatewayBeaconRequest instance using the specified properties.
             * @function create
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {api.core.IGatewayBeaconRequest=} [properties] Properties to set
             * @returns {api.core.GatewayBeaconRequest} GatewayBeaconRequest instance
             */
            GatewayBeaconRequest.create = function create(properties) {
                return new GatewayBeaconRequest(properties);
            };

            /**
             * Encodes the specified GatewayBeaconRequest message. Does not implicitly {@link api.core.GatewayBeaconRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {api.core.IGatewayBeaconRequest} message GatewayBeaconRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayBeaconRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.gatewayId != null && Object.hasOwnProperty.call(message, "gatewayId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gatewayId);
                if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.duration);
                if (message.tenants != null && message.tenants.length)
                    for (let i = 0; i < message.tenants.length; ++i)
                        $root.api.core.OnlineTenantWorkers.encode(message.tenants[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GatewayBeaconRequest message, length delimited. Does not implicitly {@link api.core.GatewayBeaconRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {api.core.IGatewayBeaconRequest} message GatewayBeaconRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayBeaconRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GatewayBeaconRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.GatewayBeaconRequest} GatewayBeaconRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayBeaconRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.GatewayBeaconRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.gatewayId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.duration = reader.int32();
                            break;
                        }
                    case 3: {
                            if (!(message.tenants && message.tenants.length))
                                message.tenants = [];
                            message.tenants.push($root.api.core.OnlineTenantWorkers.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GatewayBeaconRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.GatewayBeaconRequest} GatewayBeaconRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayBeaconRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GatewayBeaconRequest message.
             * @function verify
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GatewayBeaconRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    if (!$util.isInteger(message.gatewayId))
                        return "gatewayId: integer expected";
                if (message.duration != null && message.hasOwnProperty("duration"))
                    if (!$util.isInteger(message.duration))
                        return "duration: integer expected";
                if (message.tenants != null && message.hasOwnProperty("tenants")) {
                    if (!Array.isArray(message.tenants))
                        return "tenants: array expected";
                    for (let i = 0; i < message.tenants.length; ++i) {
                        let error = $root.api.core.OnlineTenantWorkers.verify(message.tenants[i]);
                        if (error)
                            return "tenants." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GatewayBeaconRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.GatewayBeaconRequest} GatewayBeaconRequest
             */
            GatewayBeaconRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.GatewayBeaconRequest)
                    return object;
                let message = new $root.api.core.GatewayBeaconRequest();
                if (object.gatewayId != null)
                    message.gatewayId = object.gatewayId | 0;
                if (object.duration != null)
                    message.duration = object.duration | 0;
                if (object.tenants) {
                    if (!Array.isArray(object.tenants))
                        throw TypeError(".api.core.GatewayBeaconRequest.tenants: array expected");
                    message.tenants = [];
                    for (let i = 0; i < object.tenants.length; ++i) {
                        if (typeof object.tenants[i] !== "object")
                            throw TypeError(".api.core.GatewayBeaconRequest.tenants: object expected");
                        message.tenants[i] = $root.api.core.OnlineTenantWorkers.fromObject(object.tenants[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GatewayBeaconRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {api.core.GatewayBeaconRequest} message GatewayBeaconRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GatewayBeaconRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.tenants = [];
                if (options.defaults) {
                    object.gatewayId = 0;
                    object.duration = 0;
                }
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    object.gatewayId = message.gatewayId;
                if (message.duration != null && message.hasOwnProperty("duration"))
                    object.duration = message.duration;
                if (message.tenants && message.tenants.length) {
                    object.tenants = [];
                    for (let j = 0; j < message.tenants.length; ++j)
                        object.tenants[j] = $root.api.core.OnlineTenantWorkers.toObject(message.tenants[j], options);
                }
                return object;
            };

            /**
             * Converts this GatewayBeaconRequest to JSON.
             * @function toJSON
             * @memberof api.core.GatewayBeaconRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GatewayBeaconRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GatewayBeaconRequest
             * @function getTypeUrl
             * @memberof api.core.GatewayBeaconRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GatewayBeaconRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.GatewayBeaconRequest";
            };

            return GatewayBeaconRequest;
        })();

        core.GatewayTeardownRequest = (function() {

            /**
             * Properties of a GatewayTeardownRequest.
             * @memberof api.core
             * @interface IGatewayTeardownRequest
             * @property {number|null} [gatewayId] GatewayTeardownRequest gatewayId
             * @property {boolean|null} [gatewayOffline] GatewayTeardownRequest gatewayOffline
             * @property {Long|null} [websocketTime] GatewayTeardownRequest websocketTime
             */

            /**
             * Constructs a new GatewayTeardownRequest.
             * @memberof api.core
             * @classdesc Represents a GatewayTeardownRequest.
             * @implements IGatewayTeardownRequest
             * @constructor
             * @param {api.core.IGatewayTeardownRequest=} [properties] Properties to set
             */
            function GatewayTeardownRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GatewayTeardownRequest gatewayId.
             * @member {number} gatewayId
             * @memberof api.core.GatewayTeardownRequest
             * @instance
             */
            GatewayTeardownRequest.prototype.gatewayId = 0;

            /**
             * GatewayTeardownRequest gatewayOffline.
             * @member {boolean} gatewayOffline
             * @memberof api.core.GatewayTeardownRequest
             * @instance
             */
            GatewayTeardownRequest.prototype.gatewayOffline = false;

            /**
             * GatewayTeardownRequest websocketTime.
             * @member {Long} websocketTime
             * @memberof api.core.GatewayTeardownRequest
             * @instance
             */
            GatewayTeardownRequest.prototype.websocketTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new GatewayTeardownRequest instance using the specified properties.
             * @function create
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {api.core.IGatewayTeardownRequest=} [properties] Properties to set
             * @returns {api.core.GatewayTeardownRequest} GatewayTeardownRequest instance
             */
            GatewayTeardownRequest.create = function create(properties) {
                return new GatewayTeardownRequest(properties);
            };

            /**
             * Encodes the specified GatewayTeardownRequest message. Does not implicitly {@link api.core.GatewayTeardownRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {api.core.IGatewayTeardownRequest} message GatewayTeardownRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayTeardownRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.gatewayId != null && Object.hasOwnProperty.call(message, "gatewayId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gatewayId);
                if (message.gatewayOffline != null && Object.hasOwnProperty.call(message, "gatewayOffline"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.gatewayOffline);
                if (message.websocketTime != null && Object.hasOwnProperty.call(message, "websocketTime"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.websocketTime);
                return writer;
            };

            /**
             * Encodes the specified GatewayTeardownRequest message, length delimited. Does not implicitly {@link api.core.GatewayTeardownRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {api.core.IGatewayTeardownRequest} message GatewayTeardownRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GatewayTeardownRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GatewayTeardownRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.GatewayTeardownRequest} GatewayTeardownRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayTeardownRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.GatewayTeardownRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.gatewayId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.gatewayOffline = reader.bool();
                            break;
                        }
                    case 3: {
                            message.websocketTime = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GatewayTeardownRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.GatewayTeardownRequest} GatewayTeardownRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GatewayTeardownRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GatewayTeardownRequest message.
             * @function verify
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GatewayTeardownRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    if (!$util.isInteger(message.gatewayId))
                        return "gatewayId: integer expected";
                if (message.gatewayOffline != null && message.hasOwnProperty("gatewayOffline"))
                    if (typeof message.gatewayOffline !== "boolean")
                        return "gatewayOffline: boolean expected";
                if (message.websocketTime != null && message.hasOwnProperty("websocketTime"))
                    if (!$util.isInteger(message.websocketTime) && !(message.websocketTime && $util.isInteger(message.websocketTime.low) && $util.isInteger(message.websocketTime.high)))
                        return "websocketTime: integer|Long expected";
                return null;
            };

            /**
             * Creates a GatewayTeardownRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.GatewayTeardownRequest} GatewayTeardownRequest
             */
            GatewayTeardownRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.GatewayTeardownRequest)
                    return object;
                let message = new $root.api.core.GatewayTeardownRequest();
                if (object.gatewayId != null)
                    message.gatewayId = object.gatewayId | 0;
                if (object.gatewayOffline != null)
                    message.gatewayOffline = Boolean(object.gatewayOffline);
                if (object.websocketTime != null)
                    if ($util.Long)
                        (message.websocketTime = $util.Long.fromValue(object.websocketTime)).unsigned = false;
                    else if (typeof object.websocketTime === "string")
                        message.websocketTime = parseInt(object.websocketTime, 10);
                    else if (typeof object.websocketTime === "number")
                        message.websocketTime = object.websocketTime;
                    else if (typeof object.websocketTime === "object")
                        message.websocketTime = new $util.LongBits(object.websocketTime.low >>> 0, object.websocketTime.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a GatewayTeardownRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {api.core.GatewayTeardownRequest} message GatewayTeardownRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GatewayTeardownRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.gatewayId = 0;
                    object.gatewayOffline = false;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.websocketTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.websocketTime = options.longs === String ? "0" : 0;
                }
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    object.gatewayId = message.gatewayId;
                if (message.gatewayOffline != null && message.hasOwnProperty("gatewayOffline"))
                    object.gatewayOffline = message.gatewayOffline;
                if (message.websocketTime != null && message.hasOwnProperty("websocketTime"))
                    if (typeof message.websocketTime === "number")
                        object.websocketTime = options.longs === String ? String(message.websocketTime) : message.websocketTime;
                    else
                        object.websocketTime = options.longs === String ? $util.Long.prototype.toString.call(message.websocketTime) : options.longs === Number ? new $util.LongBits(message.websocketTime.low >>> 0, message.websocketTime.high >>> 0).toNumber() : message.websocketTime;
                return object;
            };

            /**
             * Converts this GatewayTeardownRequest to JSON.
             * @function toJSON
             * @memberof api.core.GatewayTeardownRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GatewayTeardownRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GatewayTeardownRequest
             * @function getTypeUrl
             * @memberof api.core.GatewayTeardownRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GatewayTeardownRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.GatewayTeardownRequest";
            };

            return GatewayTeardownRequest;
        })();

        core.NewWorkerConnectRequest = (function() {

            /**
             * Properties of a NewWorkerConnectRequest.
             * @memberof api.core
             * @interface INewWorkerConnectRequest
             * @property {number|null} [gatewayId] NewWorkerConnectRequest gatewayId
             * @property {string|null} [query] NewWorkerConnectRequest query
             * @property {string|null} [ip] NewWorkerConnectRequest ip
             */

            /**
             * Constructs a new NewWorkerConnectRequest.
             * @memberof api.core
             * @classdesc Represents a NewWorkerConnectRequest.
             * @implements INewWorkerConnectRequest
             * @constructor
             * @param {api.core.INewWorkerConnectRequest=} [properties] Properties to set
             */
            function NewWorkerConnectRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NewWorkerConnectRequest gatewayId.
             * @member {number} gatewayId
             * @memberof api.core.NewWorkerConnectRequest
             * @instance
             */
            NewWorkerConnectRequest.prototype.gatewayId = 0;

            /**
             * NewWorkerConnectRequest query.
             * @member {string} query
             * @memberof api.core.NewWorkerConnectRequest
             * @instance
             */
            NewWorkerConnectRequest.prototype.query = "";

            /**
             * NewWorkerConnectRequest ip.
             * @member {string} ip
             * @memberof api.core.NewWorkerConnectRequest
             * @instance
             */
            NewWorkerConnectRequest.prototype.ip = "";

            /**
             * Creates a new NewWorkerConnectRequest instance using the specified properties.
             * @function create
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {api.core.INewWorkerConnectRequest=} [properties] Properties to set
             * @returns {api.core.NewWorkerConnectRequest} NewWorkerConnectRequest instance
             */
            NewWorkerConnectRequest.create = function create(properties) {
                return new NewWorkerConnectRequest(properties);
            };

            /**
             * Encodes the specified NewWorkerConnectRequest message. Does not implicitly {@link api.core.NewWorkerConnectRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {api.core.INewWorkerConnectRequest} message NewWorkerConnectRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewWorkerConnectRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.gatewayId != null && Object.hasOwnProperty.call(message, "gatewayId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gatewayId);
                if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.query);
                if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.ip);
                return writer;
            };

            /**
             * Encodes the specified NewWorkerConnectRequest message, length delimited. Does not implicitly {@link api.core.NewWorkerConnectRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {api.core.INewWorkerConnectRequest} message NewWorkerConnectRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewWorkerConnectRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NewWorkerConnectRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.NewWorkerConnectRequest} NewWorkerConnectRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewWorkerConnectRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.NewWorkerConnectRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.gatewayId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.query = reader.string();
                            break;
                        }
                    case 3: {
                            message.ip = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NewWorkerConnectRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.NewWorkerConnectRequest} NewWorkerConnectRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewWorkerConnectRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NewWorkerConnectRequest message.
             * @function verify
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewWorkerConnectRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    if (!$util.isInteger(message.gatewayId))
                        return "gatewayId: integer expected";
                if (message.query != null && message.hasOwnProperty("query"))
                    if (!$util.isString(message.query))
                        return "query: string expected";
                if (message.ip != null && message.hasOwnProperty("ip"))
                    if (!$util.isString(message.ip))
                        return "ip: string expected";
                return null;
            };

            /**
             * Creates a NewWorkerConnectRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.NewWorkerConnectRequest} NewWorkerConnectRequest
             */
            NewWorkerConnectRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.NewWorkerConnectRequest)
                    return object;
                let message = new $root.api.core.NewWorkerConnectRequest();
                if (object.gatewayId != null)
                    message.gatewayId = object.gatewayId | 0;
                if (object.query != null)
                    message.query = String(object.query);
                if (object.ip != null)
                    message.ip = String(object.ip);
                return message;
            };

            /**
             * Creates a plain object from a NewWorkerConnectRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {api.core.NewWorkerConnectRequest} message NewWorkerConnectRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewWorkerConnectRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.gatewayId = 0;
                    object.query = "";
                    object.ip = "";
                }
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    object.gatewayId = message.gatewayId;
                if (message.query != null && message.hasOwnProperty("query"))
                    object.query = message.query;
                if (message.ip != null && message.hasOwnProperty("ip"))
                    object.ip = message.ip;
                return object;
            };

            /**
             * Converts this NewWorkerConnectRequest to JSON.
             * @function toJSON
             * @memberof api.core.NewWorkerConnectRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewWorkerConnectRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NewWorkerConnectRequest
             * @function getTypeUrl
             * @memberof api.core.NewWorkerConnectRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewWorkerConnectRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.NewWorkerConnectRequest";
            };

            return NewWorkerConnectRequest;
        })();

        core.NewWorkerConnectResponse = (function() {

            /**
             * Properties of a NewWorkerConnectResponse.
             * @memberof api.core
             * @interface INewWorkerConnectResponse
             * @property {Long|null} [clientId] NewWorkerConnectResponse clientId
             * @property {string|null} [token] NewWorkerConnectResponse token
             * @property {Long|null} [websocketId] NewWorkerConnectResponse websocketId
             * @property {Long|null} [websocketTime] NewWorkerConnectResponse websocketTime
             * @property {boolean|null} [websocketFlag] NewWorkerConnectResponse websocketFlag
             */

            /**
             * Constructs a new NewWorkerConnectResponse.
             * @memberof api.core
             * @classdesc Represents a NewWorkerConnectResponse.
             * @implements INewWorkerConnectResponse
             * @constructor
             * @param {api.core.INewWorkerConnectResponse=} [properties] Properties to set
             */
            function NewWorkerConnectResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NewWorkerConnectResponse clientId.
             * @member {Long} clientId
             * @memberof api.core.NewWorkerConnectResponse
             * @instance
             */
            NewWorkerConnectResponse.prototype.clientId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * NewWorkerConnectResponse token.
             * @member {string} token
             * @memberof api.core.NewWorkerConnectResponse
             * @instance
             */
            NewWorkerConnectResponse.prototype.token = "";

            /**
             * NewWorkerConnectResponse websocketId.
             * @member {Long} websocketId
             * @memberof api.core.NewWorkerConnectResponse
             * @instance
             */
            NewWorkerConnectResponse.prototype.websocketId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * NewWorkerConnectResponse websocketTime.
             * @member {Long} websocketTime
             * @memberof api.core.NewWorkerConnectResponse
             * @instance
             */
            NewWorkerConnectResponse.prototype.websocketTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * NewWorkerConnectResponse websocketFlag.
             * @member {boolean} websocketFlag
             * @memberof api.core.NewWorkerConnectResponse
             * @instance
             */
            NewWorkerConnectResponse.prototype.websocketFlag = false;

            /**
             * Creates a new NewWorkerConnectResponse instance using the specified properties.
             * @function create
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {api.core.INewWorkerConnectResponse=} [properties] Properties to set
             * @returns {api.core.NewWorkerConnectResponse} NewWorkerConnectResponse instance
             */
            NewWorkerConnectResponse.create = function create(properties) {
                return new NewWorkerConnectResponse(properties);
            };

            /**
             * Encodes the specified NewWorkerConnectResponse message. Does not implicitly {@link api.core.NewWorkerConnectResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {api.core.INewWorkerConnectResponse} message NewWorkerConnectResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewWorkerConnectResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.clientId);
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
                if (message.websocketId != null && Object.hasOwnProperty.call(message, "websocketId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.websocketId);
                if (message.websocketTime != null && Object.hasOwnProperty.call(message, "websocketTime"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.websocketTime);
                if (message.websocketFlag != null && Object.hasOwnProperty.call(message, "websocketFlag"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.websocketFlag);
                return writer;
            };

            /**
             * Encodes the specified NewWorkerConnectResponse message, length delimited. Does not implicitly {@link api.core.NewWorkerConnectResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {api.core.INewWorkerConnectResponse} message NewWorkerConnectResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewWorkerConnectResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NewWorkerConnectResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.NewWorkerConnectResponse} NewWorkerConnectResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewWorkerConnectResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.NewWorkerConnectResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.clientId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.token = reader.string();
                            break;
                        }
                    case 3: {
                            message.websocketId = reader.uint64();
                            break;
                        }
                    case 4: {
                            message.websocketTime = reader.int64();
                            break;
                        }
                    case 5: {
                            message.websocketFlag = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NewWorkerConnectResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.NewWorkerConnectResponse} NewWorkerConnectResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewWorkerConnectResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NewWorkerConnectResponse message.
             * @function verify
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewWorkerConnectResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (!$util.isInteger(message.clientId) && !(message.clientId && $util.isInteger(message.clientId.low) && $util.isInteger(message.clientId.high)))
                        return "clientId: integer|Long expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.websocketId != null && message.hasOwnProperty("websocketId"))
                    if (!$util.isInteger(message.websocketId) && !(message.websocketId && $util.isInteger(message.websocketId.low) && $util.isInteger(message.websocketId.high)))
                        return "websocketId: integer|Long expected";
                if (message.websocketTime != null && message.hasOwnProperty("websocketTime"))
                    if (!$util.isInteger(message.websocketTime) && !(message.websocketTime && $util.isInteger(message.websocketTime.low) && $util.isInteger(message.websocketTime.high)))
                        return "websocketTime: integer|Long expected";
                if (message.websocketFlag != null && message.hasOwnProperty("websocketFlag"))
                    if (typeof message.websocketFlag !== "boolean")
                        return "websocketFlag: boolean expected";
                return null;
            };

            /**
             * Creates a NewWorkerConnectResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.NewWorkerConnectResponse} NewWorkerConnectResponse
             */
            NewWorkerConnectResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.NewWorkerConnectResponse)
                    return object;
                let message = new $root.api.core.NewWorkerConnectResponse();
                if (object.clientId != null)
                    if ($util.Long)
                        (message.clientId = $util.Long.fromValue(object.clientId)).unsigned = false;
                    else if (typeof object.clientId === "string")
                        message.clientId = parseInt(object.clientId, 10);
                    else if (typeof object.clientId === "number")
                        message.clientId = object.clientId;
                    else if (typeof object.clientId === "object")
                        message.clientId = new $util.LongBits(object.clientId.low >>> 0, object.clientId.high >>> 0).toNumber();
                if (object.token != null)
                    message.token = String(object.token);
                if (object.websocketId != null)
                    if ($util.Long)
                        (message.websocketId = $util.Long.fromValue(object.websocketId)).unsigned = true;
                    else if (typeof object.websocketId === "string")
                        message.websocketId = parseInt(object.websocketId, 10);
                    else if (typeof object.websocketId === "number")
                        message.websocketId = object.websocketId;
                    else if (typeof object.websocketId === "object")
                        message.websocketId = new $util.LongBits(object.websocketId.low >>> 0, object.websocketId.high >>> 0).toNumber(true);
                if (object.websocketTime != null)
                    if ($util.Long)
                        (message.websocketTime = $util.Long.fromValue(object.websocketTime)).unsigned = false;
                    else if (typeof object.websocketTime === "string")
                        message.websocketTime = parseInt(object.websocketTime, 10);
                    else if (typeof object.websocketTime === "number")
                        message.websocketTime = object.websocketTime;
                    else if (typeof object.websocketTime === "object")
                        message.websocketTime = new $util.LongBits(object.websocketTime.low >>> 0, object.websocketTime.high >>> 0).toNumber();
                if (object.websocketFlag != null)
                    message.websocketFlag = Boolean(object.websocketFlag);
                return message;
            };

            /**
             * Creates a plain object from a NewWorkerConnectResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {api.core.NewWorkerConnectResponse} message NewWorkerConnectResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewWorkerConnectResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.clientId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.clientId = options.longs === String ? "0" : 0;
                    object.token = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.websocketId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.websocketId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.websocketTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.websocketTime = options.longs === String ? "0" : 0;
                    object.websocketFlag = false;
                }
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (typeof message.clientId === "number")
                        object.clientId = options.longs === String ? String(message.clientId) : message.clientId;
                    else
                        object.clientId = options.longs === String ? $util.Long.prototype.toString.call(message.clientId) : options.longs === Number ? new $util.LongBits(message.clientId.low >>> 0, message.clientId.high >>> 0).toNumber() : message.clientId;
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.websocketId != null && message.hasOwnProperty("websocketId"))
                    if (typeof message.websocketId === "number")
                        object.websocketId = options.longs === String ? String(message.websocketId) : message.websocketId;
                    else
                        object.websocketId = options.longs === String ? $util.Long.prototype.toString.call(message.websocketId) : options.longs === Number ? new $util.LongBits(message.websocketId.low >>> 0, message.websocketId.high >>> 0).toNumber(true) : message.websocketId;
                if (message.websocketTime != null && message.hasOwnProperty("websocketTime"))
                    if (typeof message.websocketTime === "number")
                        object.websocketTime = options.longs === String ? String(message.websocketTime) : message.websocketTime;
                    else
                        object.websocketTime = options.longs === String ? $util.Long.prototype.toString.call(message.websocketTime) : options.longs === Number ? new $util.LongBits(message.websocketTime.low >>> 0, message.websocketTime.high >>> 0).toNumber() : message.websocketTime;
                if (message.websocketFlag != null && message.hasOwnProperty("websocketFlag"))
                    object.websocketFlag = message.websocketFlag;
                return object;
            };

            /**
             * Converts this NewWorkerConnectResponse to JSON.
             * @function toJSON
             * @memberof api.core.NewWorkerConnectResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewWorkerConnectResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NewWorkerConnectResponse
             * @function getTypeUrl
             * @memberof api.core.NewWorkerConnectResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewWorkerConnectResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.NewWorkerConnectResponse";
            };

            return NewWorkerConnectResponse;
        })();

        core.NewUserConnectRequest = (function() {

            /**
             * Properties of a NewUserConnectRequest.
             * @memberof api.core
             * @interface INewUserConnectRequest
             * @property {number|null} [gatewayId] NewUserConnectRequest gatewayId
             * @property {string|null} [query] NewUserConnectRequest query
             * @property {string|null} [ip] NewUserConnectRequest ip
             */

            /**
             * Constructs a new NewUserConnectRequest.
             * @memberof api.core
             * @classdesc Represents a NewUserConnectRequest.
             * @implements INewUserConnectRequest
             * @constructor
             * @param {api.core.INewUserConnectRequest=} [properties] Properties to set
             */
            function NewUserConnectRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NewUserConnectRequest gatewayId.
             * @member {number} gatewayId
             * @memberof api.core.NewUserConnectRequest
             * @instance
             */
            NewUserConnectRequest.prototype.gatewayId = 0;

            /**
             * NewUserConnectRequest query.
             * @member {string} query
             * @memberof api.core.NewUserConnectRequest
             * @instance
             */
            NewUserConnectRequest.prototype.query = "";

            /**
             * NewUserConnectRequest ip.
             * @member {string} ip
             * @memberof api.core.NewUserConnectRequest
             * @instance
             */
            NewUserConnectRequest.prototype.ip = "";

            /**
             * Creates a new NewUserConnectRequest instance using the specified properties.
             * @function create
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {api.core.INewUserConnectRequest=} [properties] Properties to set
             * @returns {api.core.NewUserConnectRequest} NewUserConnectRequest instance
             */
            NewUserConnectRequest.create = function create(properties) {
                return new NewUserConnectRequest(properties);
            };

            /**
             * Encodes the specified NewUserConnectRequest message. Does not implicitly {@link api.core.NewUserConnectRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {api.core.INewUserConnectRequest} message NewUserConnectRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewUserConnectRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.gatewayId != null && Object.hasOwnProperty.call(message, "gatewayId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.gatewayId);
                if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.query);
                if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.ip);
                return writer;
            };

            /**
             * Encodes the specified NewUserConnectRequest message, length delimited. Does not implicitly {@link api.core.NewUserConnectRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {api.core.INewUserConnectRequest} message NewUserConnectRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewUserConnectRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NewUserConnectRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.NewUserConnectRequest} NewUserConnectRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewUserConnectRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.NewUserConnectRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.gatewayId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.query = reader.string();
                            break;
                        }
                    case 3: {
                            message.ip = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NewUserConnectRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.NewUserConnectRequest} NewUserConnectRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewUserConnectRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NewUserConnectRequest message.
             * @function verify
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewUserConnectRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    if (!$util.isInteger(message.gatewayId))
                        return "gatewayId: integer expected";
                if (message.query != null && message.hasOwnProperty("query"))
                    if (!$util.isString(message.query))
                        return "query: string expected";
                if (message.ip != null && message.hasOwnProperty("ip"))
                    if (!$util.isString(message.ip))
                        return "ip: string expected";
                return null;
            };

            /**
             * Creates a NewUserConnectRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.NewUserConnectRequest} NewUserConnectRequest
             */
            NewUserConnectRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.NewUserConnectRequest)
                    return object;
                let message = new $root.api.core.NewUserConnectRequest();
                if (object.gatewayId != null)
                    message.gatewayId = object.gatewayId | 0;
                if (object.query != null)
                    message.query = String(object.query);
                if (object.ip != null)
                    message.ip = String(object.ip);
                return message;
            };

            /**
             * Creates a plain object from a NewUserConnectRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {api.core.NewUserConnectRequest} message NewUserConnectRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewUserConnectRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.gatewayId = 0;
                    object.query = "";
                    object.ip = "";
                }
                if (message.gatewayId != null && message.hasOwnProperty("gatewayId"))
                    object.gatewayId = message.gatewayId;
                if (message.query != null && message.hasOwnProperty("query"))
                    object.query = message.query;
                if (message.ip != null && message.hasOwnProperty("ip"))
                    object.ip = message.ip;
                return object;
            };

            /**
             * Converts this NewUserConnectRequest to JSON.
             * @function toJSON
             * @memberof api.core.NewUserConnectRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewUserConnectRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NewUserConnectRequest
             * @function getTypeUrl
             * @memberof api.core.NewUserConnectRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewUserConnectRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.NewUserConnectRequest";
            };

            return NewUserConnectRequest;
        })();

        core.NewUserConnectResponse = (function() {

            /**
             * Properties of a NewUserConnectResponse.
             * @memberof api.core
             * @interface INewUserConnectResponse
             * @property {Long|null} [clientId] NewUserConnectResponse clientId
             * @property {string|null} [token] NewUserConnectResponse token
             * @property {number|null} [workerId] NewUserConnectResponse workerId
             * @property {Long|null} [websocketId] NewUserConnectResponse websocketId
             * @property {Long|null} [websocketTime] NewUserConnectResponse websocketTime
             * @property {boolean|null} [websocketFlag] NewUserConnectResponse websocketFlag
             */

            /**
             * Constructs a new NewUserConnectResponse.
             * @memberof api.core
             * @classdesc Represents a NewUserConnectResponse.
             * @implements INewUserConnectResponse
             * @constructor
             * @param {api.core.INewUserConnectResponse=} [properties] Properties to set
             */
            function NewUserConnectResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NewUserConnectResponse clientId.
             * @member {Long} clientId
             * @memberof api.core.NewUserConnectResponse
             * @instance
             */
            NewUserConnectResponse.prototype.clientId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * NewUserConnectResponse token.
             * @member {string} token
             * @memberof api.core.NewUserConnectResponse
             * @instance
             */
            NewUserConnectResponse.prototype.token = "";

            /**
             * NewUserConnectResponse workerId.
             * @member {number} workerId
             * @memberof api.core.NewUserConnectResponse
             * @instance
             */
            NewUserConnectResponse.prototype.workerId = 0;

            /**
             * NewUserConnectResponse websocketId.
             * @member {Long} websocketId
             * @memberof api.core.NewUserConnectResponse
             * @instance
             */
            NewUserConnectResponse.prototype.websocketId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * NewUserConnectResponse websocketTime.
             * @member {Long} websocketTime
             * @memberof api.core.NewUserConnectResponse
             * @instance
             */
            NewUserConnectResponse.prototype.websocketTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * NewUserConnectResponse websocketFlag.
             * @member {boolean} websocketFlag
             * @memberof api.core.NewUserConnectResponse
             * @instance
             */
            NewUserConnectResponse.prototype.websocketFlag = false;

            /**
             * Creates a new NewUserConnectResponse instance using the specified properties.
             * @function create
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {api.core.INewUserConnectResponse=} [properties] Properties to set
             * @returns {api.core.NewUserConnectResponse} NewUserConnectResponse instance
             */
            NewUserConnectResponse.create = function create(properties) {
                return new NewUserConnectResponse(properties);
            };

            /**
             * Encodes the specified NewUserConnectResponse message. Does not implicitly {@link api.core.NewUserConnectResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {api.core.INewUserConnectResponse} message NewUserConnectResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewUserConnectResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.clientId);
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.workerId);
                if (message.websocketId != null && Object.hasOwnProperty.call(message, "websocketId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.websocketId);
                if (message.websocketTime != null && Object.hasOwnProperty.call(message, "websocketTime"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.websocketTime);
                if (message.websocketFlag != null && Object.hasOwnProperty.call(message, "websocketFlag"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.websocketFlag);
                return writer;
            };

            /**
             * Encodes the specified NewUserConnectResponse message, length delimited. Does not implicitly {@link api.core.NewUserConnectResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {api.core.INewUserConnectResponse} message NewUserConnectResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewUserConnectResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NewUserConnectResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.NewUserConnectResponse} NewUserConnectResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewUserConnectResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.NewUserConnectResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.clientId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.token = reader.string();
                            break;
                        }
                    case 3: {
                            message.workerId = reader.int32();
                            break;
                        }
                    case 4: {
                            message.websocketId = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.websocketTime = reader.int64();
                            break;
                        }
                    case 6: {
                            message.websocketFlag = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NewUserConnectResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.NewUserConnectResponse} NewUserConnectResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewUserConnectResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NewUserConnectResponse message.
             * @function verify
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewUserConnectResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (!$util.isInteger(message.clientId) && !(message.clientId && $util.isInteger(message.clientId.low) && $util.isInteger(message.clientId.high)))
                        return "clientId: integer|Long expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                if (message.websocketId != null && message.hasOwnProperty("websocketId"))
                    if (!$util.isInteger(message.websocketId) && !(message.websocketId && $util.isInteger(message.websocketId.low) && $util.isInteger(message.websocketId.high)))
                        return "websocketId: integer|Long expected";
                if (message.websocketTime != null && message.hasOwnProperty("websocketTime"))
                    if (!$util.isInteger(message.websocketTime) && !(message.websocketTime && $util.isInteger(message.websocketTime.low) && $util.isInteger(message.websocketTime.high)))
                        return "websocketTime: integer|Long expected";
                if (message.websocketFlag != null && message.hasOwnProperty("websocketFlag"))
                    if (typeof message.websocketFlag !== "boolean")
                        return "websocketFlag: boolean expected";
                return null;
            };

            /**
             * Creates a NewUserConnectResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.NewUserConnectResponse} NewUserConnectResponse
             */
            NewUserConnectResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.NewUserConnectResponse)
                    return object;
                let message = new $root.api.core.NewUserConnectResponse();
                if (object.clientId != null)
                    if ($util.Long)
                        (message.clientId = $util.Long.fromValue(object.clientId)).unsigned = false;
                    else if (typeof object.clientId === "string")
                        message.clientId = parseInt(object.clientId, 10);
                    else if (typeof object.clientId === "number")
                        message.clientId = object.clientId;
                    else if (typeof object.clientId === "object")
                        message.clientId = new $util.LongBits(object.clientId.low >>> 0, object.clientId.high >>> 0).toNumber();
                if (object.token != null)
                    message.token = String(object.token);
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                if (object.websocketId != null)
                    if ($util.Long)
                        (message.websocketId = $util.Long.fromValue(object.websocketId)).unsigned = true;
                    else if (typeof object.websocketId === "string")
                        message.websocketId = parseInt(object.websocketId, 10);
                    else if (typeof object.websocketId === "number")
                        message.websocketId = object.websocketId;
                    else if (typeof object.websocketId === "object")
                        message.websocketId = new $util.LongBits(object.websocketId.low >>> 0, object.websocketId.high >>> 0).toNumber(true);
                if (object.websocketTime != null)
                    if ($util.Long)
                        (message.websocketTime = $util.Long.fromValue(object.websocketTime)).unsigned = false;
                    else if (typeof object.websocketTime === "string")
                        message.websocketTime = parseInt(object.websocketTime, 10);
                    else if (typeof object.websocketTime === "number")
                        message.websocketTime = object.websocketTime;
                    else if (typeof object.websocketTime === "object")
                        message.websocketTime = new $util.LongBits(object.websocketTime.low >>> 0, object.websocketTime.high >>> 0).toNumber();
                if (object.websocketFlag != null)
                    message.websocketFlag = Boolean(object.websocketFlag);
                return message;
            };

            /**
             * Creates a plain object from a NewUserConnectResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {api.core.NewUserConnectResponse} message NewUserConnectResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewUserConnectResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.clientId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.clientId = options.longs === String ? "0" : 0;
                    object.token = "";
                    object.workerId = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.websocketId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.websocketId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.websocketTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.websocketTime = options.longs === String ? "0" : 0;
                    object.websocketFlag = false;
                }
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (typeof message.clientId === "number")
                        object.clientId = options.longs === String ? String(message.clientId) : message.clientId;
                    else
                        object.clientId = options.longs === String ? $util.Long.prototype.toString.call(message.clientId) : options.longs === Number ? new $util.LongBits(message.clientId.low >>> 0, message.clientId.high >>> 0).toNumber() : message.clientId;
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                if (message.websocketId != null && message.hasOwnProperty("websocketId"))
                    if (typeof message.websocketId === "number")
                        object.websocketId = options.longs === String ? String(message.websocketId) : message.websocketId;
                    else
                        object.websocketId = options.longs === String ? $util.Long.prototype.toString.call(message.websocketId) : options.longs === Number ? new $util.LongBits(message.websocketId.low >>> 0, message.websocketId.high >>> 0).toNumber(true) : message.websocketId;
                if (message.websocketTime != null && message.hasOwnProperty("websocketTime"))
                    if (typeof message.websocketTime === "number")
                        object.websocketTime = options.longs === String ? String(message.websocketTime) : message.websocketTime;
                    else
                        object.websocketTime = options.longs === String ? $util.Long.prototype.toString.call(message.websocketTime) : options.longs === Number ? new $util.LongBits(message.websocketTime.low >>> 0, message.websocketTime.high >>> 0).toNumber() : message.websocketTime;
                if (message.websocketFlag != null && message.hasOwnProperty("websocketFlag"))
                    object.websocketFlag = message.websocketFlag;
                return object;
            };

            /**
             * Converts this NewUserConnectResponse to JSON.
             * @function toJSON
             * @memberof api.core.NewUserConnectResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewUserConnectResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NewUserConnectResponse
             * @function getTypeUrl
             * @memberof api.core.NewUserConnectResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewUserConnectResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.NewUserConnectResponse";
            };

            return NewUserConnectResponse;
        })();

        core.NewDisconnectRequest = (function() {

            /**
             * Properties of a NewDisconnectRequest.
             * @memberof api.core
             * @interface INewDisconnectRequest
             * @property {Array.<Long>|null} [clientId] NewDisconnectRequest clientId
             */

            /**
             * Constructs a new NewDisconnectRequest.
             * @memberof api.core
             * @classdesc Represents a NewDisconnectRequest.
             * @implements INewDisconnectRequest
             * @constructor
             * @param {api.core.INewDisconnectRequest=} [properties] Properties to set
             */
            function NewDisconnectRequest(properties) {
                this.clientId = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NewDisconnectRequest clientId.
             * @member {Array.<Long>} clientId
             * @memberof api.core.NewDisconnectRequest
             * @instance
             */
            NewDisconnectRequest.prototype.clientId = $util.emptyArray;

            /**
             * Creates a new NewDisconnectRequest instance using the specified properties.
             * @function create
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {api.core.INewDisconnectRequest=} [properties] Properties to set
             * @returns {api.core.NewDisconnectRequest} NewDisconnectRequest instance
             */
            NewDisconnectRequest.create = function create(properties) {
                return new NewDisconnectRequest(properties);
            };

            /**
             * Encodes the specified NewDisconnectRequest message. Does not implicitly {@link api.core.NewDisconnectRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {api.core.INewDisconnectRequest} message NewDisconnectRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewDisconnectRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clientId != null && message.clientId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (let i = 0; i < message.clientId.length; ++i)
                        writer.int64(message.clientId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Encodes the specified NewDisconnectRequest message, length delimited. Does not implicitly {@link api.core.NewDisconnectRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {api.core.INewDisconnectRequest} message NewDisconnectRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NewDisconnectRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NewDisconnectRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.NewDisconnectRequest} NewDisconnectRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewDisconnectRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.NewDisconnectRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.clientId && message.clientId.length))
                                message.clientId = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.clientId.push(reader.int64());
                            } else
                                message.clientId.push(reader.int64());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NewDisconnectRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.NewDisconnectRequest} NewDisconnectRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NewDisconnectRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NewDisconnectRequest message.
             * @function verify
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NewDisconnectRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clientId != null && message.hasOwnProperty("clientId")) {
                    if (!Array.isArray(message.clientId))
                        return "clientId: array expected";
                    for (let i = 0; i < message.clientId.length; ++i)
                        if (!$util.isInteger(message.clientId[i]) && !(message.clientId[i] && $util.isInteger(message.clientId[i].low) && $util.isInteger(message.clientId[i].high)))
                            return "clientId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a NewDisconnectRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.NewDisconnectRequest} NewDisconnectRequest
             */
            NewDisconnectRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.NewDisconnectRequest)
                    return object;
                let message = new $root.api.core.NewDisconnectRequest();
                if (object.clientId) {
                    if (!Array.isArray(object.clientId))
                        throw TypeError(".api.core.NewDisconnectRequest.clientId: array expected");
                    message.clientId = [];
                    for (let i = 0; i < object.clientId.length; ++i)
                        if ($util.Long)
                            (message.clientId[i] = $util.Long.fromValue(object.clientId[i])).unsigned = false;
                        else if (typeof object.clientId[i] === "string")
                            message.clientId[i] = parseInt(object.clientId[i], 10);
                        else if (typeof object.clientId[i] === "number")
                            message.clientId[i] = object.clientId[i];
                        else if (typeof object.clientId[i] === "object")
                            message.clientId[i] = new $util.LongBits(object.clientId[i].low >>> 0, object.clientId[i].high >>> 0).toNumber();
                }
                return message;
            };

            /**
             * Creates a plain object from a NewDisconnectRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {api.core.NewDisconnectRequest} message NewDisconnectRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NewDisconnectRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.clientId = [];
                if (message.clientId && message.clientId.length) {
                    object.clientId = [];
                    for (let j = 0; j < message.clientId.length; ++j)
                        if (typeof message.clientId[j] === "number")
                            object.clientId[j] = options.longs === String ? String(message.clientId[j]) : message.clientId[j];
                        else
                            object.clientId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.clientId[j]) : options.longs === Number ? new $util.LongBits(message.clientId[j].low >>> 0, message.clientId[j].high >>> 0).toNumber() : message.clientId[j];
                }
                return object;
            };

            /**
             * Converts this NewDisconnectRequest to JSON.
             * @function toJSON
             * @memberof api.core.NewDisconnectRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NewDisconnectRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NewDisconnectRequest
             * @function getTypeUrl
             * @memberof api.core.NewDisconnectRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NewDisconnectRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.NewDisconnectRequest";
            };

            return NewDisconnectRequest;
        })();

        core.GatewayManager = (function() {

            /**
             * Constructs a new GatewayManager service.
             * @memberof api.core
             * @classdesc Represents a GatewayManager
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function GatewayManager(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (GatewayManager.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GatewayManager;

            /**
             * Creates new GatewayManager service using the specified rpc implementation.
             * @function create
             * @memberof api.core.GatewayManager
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {GatewayManager} RPC service. Useful where requests and/or responses are streamed.
             */
            GatewayManager.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.GatewayManager#beacon}.
             * @memberof api.core.GatewayManager
             * @typedef BeaconCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Beacon.
             * @function beacon
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayBeaconRequest} request GatewayBeaconRequest message or plain object
             * @param {api.core.GatewayManager.BeaconCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(GatewayManager.prototype.beacon = function beacon(request, callback) {
                return this.rpcCall(beacon, $root.api.core.GatewayBeaconRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Beacon" });

            /**
             * Calls Beacon.
             * @function beacon
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayBeaconRequest} request GatewayBeaconRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.GatewayManager#register}.
             * @memberof api.core.GatewayManager
             * @typedef RegisterCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.GatewayRegisterResponse} [response] GatewayRegisterResponse
             */

            /**
             * Calls Register.
             * @function register
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayRegisterRequest} request GatewayRegisterRequest message or plain object
             * @param {api.core.GatewayManager.RegisterCallback} callback Node-style callback called with the error, if any, and GatewayRegisterResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(GatewayManager.prototype.register = function register(request, callback) {
                return this.rpcCall(register, $root.api.core.GatewayRegisterRequest, $root.api.core.GatewayRegisterResponse, request, callback);
            }, "name", { value: "Register" });

            /**
             * Calls Register.
             * @function register
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayRegisterRequest} request GatewayRegisterRequest message or plain object
             * @returns {Promise<api.core.GatewayRegisterResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.GatewayManager#teardown}.
             * @memberof api.core.GatewayManager
             * @typedef TeardownCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Teardown.
             * @function teardown
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayTeardownRequest} request GatewayTeardownRequest message or plain object
             * @param {api.core.GatewayManager.TeardownCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(GatewayManager.prototype.teardown = function teardown(request, callback) {
                return this.rpcCall(teardown, $root.api.core.GatewayTeardownRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Teardown" });

            /**
             * Calls Teardown.
             * @function teardown
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayTeardownRequest} request GatewayTeardownRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.GatewayManager#pubSubSYN}.
             * @memberof api.core.GatewayManager
             * @typedef PubSubSYNCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls PubSubSYN.
             * @function pubSubSYN
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayNodeRequest} request GatewayNodeRequest message or plain object
             * @param {api.core.GatewayManager.PubSubSYNCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(GatewayManager.prototype.pubSubSYN = function pubSubSYN(request, callback) {
                return this.rpcCall(pubSubSYN, $root.api.core.GatewayNodeRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "PubSubSYN" });

            /**
             * Calls PubSubSYN.
             * @function pubSubSYN
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayNodeRequest} request GatewayNodeRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.GatewayManager#pubSubACK}.
             * @memberof api.core.GatewayManager
             * @typedef PubSubACKCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls PubSubACK.
             * @function pubSubACK
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayNodeRequest} request GatewayNodeRequest message or plain object
             * @param {api.core.GatewayManager.PubSubACKCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(GatewayManager.prototype.pubSubACK = function pubSubACK(request, callback) {
                return this.rpcCall(pubSubACK, $root.api.core.GatewayNodeRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "PubSubACK" });

            /**
             * Calls PubSubACK.
             * @function pubSubACK
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.IGatewayNodeRequest} request GatewayNodeRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.GatewayManager#newWorkerConnect}.
             * @memberof api.core.GatewayManager
             * @typedef NewWorkerConnectCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.NewWorkerConnectResponse} [response] NewWorkerConnectResponse
             */

            /**
             * Calls NewWorkerConnect.
             * @function newWorkerConnect
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.INewWorkerConnectRequest} request NewWorkerConnectRequest message or plain object
             * @param {api.core.GatewayManager.NewWorkerConnectCallback} callback Node-style callback called with the error, if any, and NewWorkerConnectResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(GatewayManager.prototype.newWorkerConnect = function newWorkerConnect(request, callback) {
                return this.rpcCall(newWorkerConnect, $root.api.core.NewWorkerConnectRequest, $root.api.core.NewWorkerConnectResponse, request, callback);
            }, "name", { value: "NewWorkerConnect" });

            /**
             * Calls NewWorkerConnect.
             * @function newWorkerConnect
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.INewWorkerConnectRequest} request NewWorkerConnectRequest message or plain object
             * @returns {Promise<api.core.NewWorkerConnectResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.GatewayManager#newUserConnect}.
             * @memberof api.core.GatewayManager
             * @typedef NewUserConnectCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.NewUserConnectResponse} [response] NewUserConnectResponse
             */

            /**
             * Calls NewUserConnect.
             * @function newUserConnect
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.INewUserConnectRequest} request NewUserConnectRequest message or plain object
             * @param {api.core.GatewayManager.NewUserConnectCallback} callback Node-style callback called with the error, if any, and NewUserConnectResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(GatewayManager.prototype.newUserConnect = function newUserConnect(request, callback) {
                return this.rpcCall(newUserConnect, $root.api.core.NewUserConnectRequest, $root.api.core.NewUserConnectResponse, request, callback);
            }, "name", { value: "NewUserConnect" });

            /**
             * Calls NewUserConnect.
             * @function newUserConnect
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.INewUserConnectRequest} request NewUserConnectRequest message or plain object
             * @returns {Promise<api.core.NewUserConnectResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.GatewayManager#newDisconnect}.
             * @memberof api.core.GatewayManager
             * @typedef NewDisconnectCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls NewDisconnect.
             * @function newDisconnect
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.INewDisconnectRequest} request NewDisconnectRequest message or plain object
             * @param {api.core.GatewayManager.NewDisconnectCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(GatewayManager.prototype.newDisconnect = function newDisconnect(request, callback) {
                return this.rpcCall(newDisconnect, $root.api.core.NewDisconnectRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "NewDisconnect" });

            /**
             * Calls NewDisconnect.
             * @function newDisconnect
             * @memberof api.core.GatewayManager
             * @instance
             * @param {api.core.INewDisconnectRequest} request NewDisconnectRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return GatewayManager;
        })();

        core.MessageSyncRequest = (function() {

            /**
             * Properties of a MessageSyncRequest.
             * @memberof api.core
             * @interface IMessageSyncRequest
             * @property {Long|null} [chatId] MessageSyncRequest chatId
             * @property {Long|null} [msgId] MessageSyncRequest msgId
             * @property {number|null} [count] MessageSyncRequest count
             * @property {boolean|null} [withLastOne] MessageSyncRequest withLastOne
             * @property {number|null} [workerId] MessageSyncRequest workerId
             */

            /**
             * Constructs a new MessageSyncRequest.
             * @memberof api.core
             * @classdesc Represents a MessageSyncRequest.
             * @implements IMessageSyncRequest
             * @constructor
             * @param {api.core.IMessageSyncRequest=} [properties] Properties to set
             */
            function MessageSyncRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageSyncRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.MessageSyncRequest
             * @instance
             */
            MessageSyncRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MessageSyncRequest msgId.
             * @member {Long} msgId
             * @memberof api.core.MessageSyncRequest
             * @instance
             */
            MessageSyncRequest.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MessageSyncRequest count.
             * @member {number} count
             * @memberof api.core.MessageSyncRequest
             * @instance
             */
            MessageSyncRequest.prototype.count = 0;

            /**
             * MessageSyncRequest withLastOne.
             * @member {boolean} withLastOne
             * @memberof api.core.MessageSyncRequest
             * @instance
             */
            MessageSyncRequest.prototype.withLastOne = false;

            /**
             * MessageSyncRequest workerId.
             * @member {number} workerId
             * @memberof api.core.MessageSyncRequest
             * @instance
             */
            MessageSyncRequest.prototype.workerId = 0;

            /**
             * Creates a new MessageSyncRequest instance using the specified properties.
             * @function create
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {api.core.IMessageSyncRequest=} [properties] Properties to set
             * @returns {api.core.MessageSyncRequest} MessageSyncRequest instance
             */
            MessageSyncRequest.create = function create(properties) {
                return new MessageSyncRequest(properties);
            };

            /**
             * Encodes the specified MessageSyncRequest message. Does not implicitly {@link api.core.MessageSyncRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {api.core.IMessageSyncRequest} message MessageSyncRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageSyncRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.msgId);
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.count);
                if (message.withLastOne != null && Object.hasOwnProperty.call(message, "withLastOne"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.withLastOne);
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified MessageSyncRequest message, length delimited. Does not implicitly {@link api.core.MessageSyncRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {api.core.IMessageSyncRequest} message MessageSyncRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageSyncRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageSyncRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.MessageSyncRequest} MessageSyncRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageSyncRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.MessageSyncRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.msgId = reader.int64();
                            break;
                        }
                    case 3: {
                            message.count = reader.int32();
                            break;
                        }
                    case 4: {
                            message.withLastOne = reader.bool();
                            break;
                        }
                    case 5: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageSyncRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.MessageSyncRequest} MessageSyncRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageSyncRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageSyncRequest message.
             * @function verify
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageSyncRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                        return "msgId: integer|Long expected";
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                if (message.withLastOne != null && message.hasOwnProperty("withLastOne"))
                    if (typeof message.withLastOne !== "boolean")
                        return "withLastOne: boolean expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a MessageSyncRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.MessageSyncRequest} MessageSyncRequest
             */
            MessageSyncRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.MessageSyncRequest)
                    return object;
                let message = new $root.api.core.MessageSyncRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.msgId != null)
                    if ($util.Long)
                        (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                    else if (typeof object.msgId === "string")
                        message.msgId = parseInt(object.msgId, 10);
                    else if (typeof object.msgId === "number")
                        message.msgId = object.msgId;
                    else if (typeof object.msgId === "object")
                        message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
                if (object.count != null)
                    message.count = object.count | 0;
                if (object.withLastOne != null)
                    message.withLastOne = Boolean(object.withLastOne);
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a MessageSyncRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {api.core.MessageSyncRequest} message MessageSyncRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageSyncRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.msgId = options.longs === String ? "0" : 0;
                    object.count = 0;
                    object.withLastOne = false;
                    object.workerId = 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (typeof message.msgId === "number")
                        object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                    else
                        object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                if (message.withLastOne != null && message.hasOwnProperty("withLastOne"))
                    object.withLastOne = message.withLastOne;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this MessageSyncRequest to JSON.
             * @function toJSON
             * @memberof api.core.MessageSyncRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageSyncRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageSyncRequest
             * @function getTypeUrl
             * @memberof api.core.MessageSyncRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageSyncRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.MessageSyncRequest";
            };

            return MessageSyncRequest;
        })();

        core.MessageSyncResponse = (function() {

            /**
             * Properties of a MessageSyncResponse.
             * @memberof api.core
             * @interface IMessageSyncResponse
             * @property {api.core.IMessageSyncRequest|null} [request] MessageSyncResponse request
             * @property {Array.<api.common.IMessage>|null} [list] MessageSyncResponse list
             * @property {Long|null} [lastMsgId] MessageSyncResponse lastMsgId
             */

            /**
             * Constructs a new MessageSyncResponse.
             * @memberof api.core
             * @classdesc Represents a MessageSyncResponse.
             * @implements IMessageSyncResponse
             * @constructor
             * @param {api.core.IMessageSyncResponse=} [properties] Properties to set
             */
            function MessageSyncResponse(properties) {
                this.list = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageSyncResponse request.
             * @member {api.core.IMessageSyncRequest|null|undefined} request
             * @memberof api.core.MessageSyncResponse
             * @instance
             */
            MessageSyncResponse.prototype.request = null;

            /**
             * MessageSyncResponse list.
             * @member {Array.<api.common.IMessage>} list
             * @memberof api.core.MessageSyncResponse
             * @instance
             */
            MessageSyncResponse.prototype.list = $util.emptyArray;

            /**
             * MessageSyncResponse lastMsgId.
             * @member {Long} lastMsgId
             * @memberof api.core.MessageSyncResponse
             * @instance
             */
            MessageSyncResponse.prototype.lastMsgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new MessageSyncResponse instance using the specified properties.
             * @function create
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {api.core.IMessageSyncResponse=} [properties] Properties to set
             * @returns {api.core.MessageSyncResponse} MessageSyncResponse instance
             */
            MessageSyncResponse.create = function create(properties) {
                return new MessageSyncResponse(properties);
            };

            /**
             * Encodes the specified MessageSyncResponse message. Does not implicitly {@link api.core.MessageSyncResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {api.core.IMessageSyncResponse} message MessageSyncResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageSyncResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.request != null && Object.hasOwnProperty.call(message, "request"))
                    $root.api.core.MessageSyncRequest.encode(message.request, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.list != null && message.list.length)
                    for (let i = 0; i < message.list.length; ++i)
                        $root.api.common.Message.encode(message.list[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastMsgId != null && Object.hasOwnProperty.call(message, "lastMsgId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.lastMsgId);
                return writer;
            };

            /**
             * Encodes the specified MessageSyncResponse message, length delimited. Does not implicitly {@link api.core.MessageSyncResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {api.core.IMessageSyncResponse} message MessageSyncResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageSyncResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageSyncResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.MessageSyncResponse} MessageSyncResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageSyncResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.MessageSyncResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.request = $root.api.core.MessageSyncRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.list && message.list.length))
                                message.list = [];
                            message.list.push($root.api.common.Message.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.lastMsgId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageSyncResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.MessageSyncResponse} MessageSyncResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageSyncResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageSyncResponse message.
             * @function verify
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageSyncResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.request != null && message.hasOwnProperty("request")) {
                    let error = $root.api.core.MessageSyncRequest.verify(message.request);
                    if (error)
                        return "request." + error;
                }
                if (message.list != null && message.hasOwnProperty("list")) {
                    if (!Array.isArray(message.list))
                        return "list: array expected";
                    for (let i = 0; i < message.list.length; ++i) {
                        let error = $root.api.common.Message.verify(message.list[i]);
                        if (error)
                            return "list." + error;
                    }
                }
                if (message.lastMsgId != null && message.hasOwnProperty("lastMsgId"))
                    if (!$util.isInteger(message.lastMsgId) && !(message.lastMsgId && $util.isInteger(message.lastMsgId.low) && $util.isInteger(message.lastMsgId.high)))
                        return "lastMsgId: integer|Long expected";
                return null;
            };

            /**
             * Creates a MessageSyncResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.MessageSyncResponse} MessageSyncResponse
             */
            MessageSyncResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.MessageSyncResponse)
                    return object;
                let message = new $root.api.core.MessageSyncResponse();
                if (object.request != null) {
                    if (typeof object.request !== "object")
                        throw TypeError(".api.core.MessageSyncResponse.request: object expected");
                    message.request = $root.api.core.MessageSyncRequest.fromObject(object.request);
                }
                if (object.list) {
                    if (!Array.isArray(object.list))
                        throw TypeError(".api.core.MessageSyncResponse.list: array expected");
                    message.list = [];
                    for (let i = 0; i < object.list.length; ++i) {
                        if (typeof object.list[i] !== "object")
                            throw TypeError(".api.core.MessageSyncResponse.list: object expected");
                        message.list[i] = $root.api.common.Message.fromObject(object.list[i]);
                    }
                }
                if (object.lastMsgId != null)
                    if ($util.Long)
                        (message.lastMsgId = $util.Long.fromValue(object.lastMsgId)).unsigned = false;
                    else if (typeof object.lastMsgId === "string")
                        message.lastMsgId = parseInt(object.lastMsgId, 10);
                    else if (typeof object.lastMsgId === "number")
                        message.lastMsgId = object.lastMsgId;
                    else if (typeof object.lastMsgId === "object")
                        message.lastMsgId = new $util.LongBits(object.lastMsgId.low >>> 0, object.lastMsgId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a MessageSyncResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {api.core.MessageSyncResponse} message MessageSyncResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageSyncResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.list = [];
                if (options.defaults) {
                    object.request = null;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.lastMsgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastMsgId = options.longs === String ? "0" : 0;
                }
                if (message.request != null && message.hasOwnProperty("request"))
                    object.request = $root.api.core.MessageSyncRequest.toObject(message.request, options);
                if (message.list && message.list.length) {
                    object.list = [];
                    for (let j = 0; j < message.list.length; ++j)
                        object.list[j] = $root.api.common.Message.toObject(message.list[j], options);
                }
                if (message.lastMsgId != null && message.hasOwnProperty("lastMsgId"))
                    if (typeof message.lastMsgId === "number")
                        object.lastMsgId = options.longs === String ? String(message.lastMsgId) : message.lastMsgId;
                    else
                        object.lastMsgId = options.longs === String ? $util.Long.prototype.toString.call(message.lastMsgId) : options.longs === Number ? new $util.LongBits(message.lastMsgId.low >>> 0, message.lastMsgId.high >>> 0).toNumber() : message.lastMsgId;
                return object;
            };

            /**
             * Converts this MessageSyncResponse to JSON.
             * @function toJSON
             * @memberof api.core.MessageSyncResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageSyncResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageSyncResponse
             * @function getTypeUrl
             * @memberof api.core.MessageSyncResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageSyncResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.MessageSyncResponse";
            };

            return MessageSyncResponse;
        })();

        core.MessageSearchRequest = (function() {

            /**
             * Properties of a MessageSearchRequest.
             * @memberof api.core
             * @interface IMessageSearchRequest
             * @property {string|null} [content] MessageSearchRequest content
             * @property {Long|null} [chatId] MessageSearchRequest chatId
             * @property {Long|null} [msgId] MessageSearchRequest msgId
             * @property {google.protobuf.ITimestamp|null} [start] MessageSearchRequest start
             * @property {google.protobuf.ITimestamp|null} [end] MessageSearchRequest end
             * @property {number|null} [count] MessageSearchRequest count
             */

            /**
             * Constructs a new MessageSearchRequest.
             * @memberof api.core
             * @classdesc Represents a MessageSearchRequest.
             * @implements IMessageSearchRequest
             * @constructor
             * @param {api.core.IMessageSearchRequest=} [properties] Properties to set
             */
            function MessageSearchRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageSearchRequest content.
             * @member {string} content
             * @memberof api.core.MessageSearchRequest
             * @instance
             */
            MessageSearchRequest.prototype.content = "";

            /**
             * MessageSearchRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.MessageSearchRequest
             * @instance
             */
            MessageSearchRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MessageSearchRequest msgId.
             * @member {Long} msgId
             * @memberof api.core.MessageSearchRequest
             * @instance
             */
            MessageSearchRequest.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MessageSearchRequest start.
             * @member {google.protobuf.ITimestamp|null|undefined} start
             * @memberof api.core.MessageSearchRequest
             * @instance
             */
            MessageSearchRequest.prototype.start = null;

            /**
             * MessageSearchRequest end.
             * @member {google.protobuf.ITimestamp|null|undefined} end
             * @memberof api.core.MessageSearchRequest
             * @instance
             */
            MessageSearchRequest.prototype.end = null;

            /**
             * MessageSearchRequest count.
             * @member {number} count
             * @memberof api.core.MessageSearchRequest
             * @instance
             */
            MessageSearchRequest.prototype.count = 0;

            /**
             * Creates a new MessageSearchRequest instance using the specified properties.
             * @function create
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {api.core.IMessageSearchRequest=} [properties] Properties to set
             * @returns {api.core.MessageSearchRequest} MessageSearchRequest instance
             */
            MessageSearchRequest.create = function create(properties) {
                return new MessageSearchRequest(properties);
            };

            /**
             * Encodes the specified MessageSearchRequest message. Does not implicitly {@link api.core.MessageSearchRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {api.core.IMessageSearchRequest} message MessageSearchRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageSearchRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.content);
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.chatId);
                if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.msgId);
                if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                    $root.google.protobuf.Timestamp.encode(message.start, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                    $root.google.protobuf.Timestamp.encode(message.end, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.count);
                return writer;
            };

            /**
             * Encodes the specified MessageSearchRequest message, length delimited. Does not implicitly {@link api.core.MessageSearchRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {api.core.IMessageSearchRequest} message MessageSearchRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageSearchRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageSearchRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.MessageSearchRequest} MessageSearchRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageSearchRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.MessageSearchRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.content = reader.string();
                            break;
                        }
                    case 2: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 3: {
                            message.msgId = reader.int64();
                            break;
                        }
                    case 4: {
                            message.start = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.end = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.count = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageSearchRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.MessageSearchRequest} MessageSearchRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageSearchRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageSearchRequest message.
             * @function verify
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageSearchRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                        return "msgId: integer|Long expected";
                if (message.start != null && message.hasOwnProperty("start")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.start);
                    if (error)
                        return "start." + error;
                }
                if (message.end != null && message.hasOwnProperty("end")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.end);
                    if (error)
                        return "end." + error;
                }
                if (message.count != null && message.hasOwnProperty("count"))
                    if (!$util.isInteger(message.count))
                        return "count: integer expected";
                return null;
            };

            /**
             * Creates a MessageSearchRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.MessageSearchRequest} MessageSearchRequest
             */
            MessageSearchRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.MessageSearchRequest)
                    return object;
                let message = new $root.api.core.MessageSearchRequest();
                if (object.content != null)
                    message.content = String(object.content);
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.msgId != null)
                    if ($util.Long)
                        (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                    else if (typeof object.msgId === "string")
                        message.msgId = parseInt(object.msgId, 10);
                    else if (typeof object.msgId === "number")
                        message.msgId = object.msgId;
                    else if (typeof object.msgId === "object")
                        message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
                if (object.start != null) {
                    if (typeof object.start !== "object")
                        throw TypeError(".api.core.MessageSearchRequest.start: object expected");
                    message.start = $root.google.protobuf.Timestamp.fromObject(object.start);
                }
                if (object.end != null) {
                    if (typeof object.end !== "object")
                        throw TypeError(".api.core.MessageSearchRequest.end: object expected");
                    message.end = $root.google.protobuf.Timestamp.fromObject(object.end);
                }
                if (object.count != null)
                    message.count = object.count | 0;
                return message;
            };

            /**
             * Creates a plain object from a MessageSearchRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {api.core.MessageSearchRequest} message MessageSearchRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageSearchRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.content = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.msgId = options.longs === String ? "0" : 0;
                    object.start = null;
                    object.end = null;
                    object.count = 0;
                }
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (typeof message.msgId === "number")
                        object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                    else
                        object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
                if (message.start != null && message.hasOwnProperty("start"))
                    object.start = $root.google.protobuf.Timestamp.toObject(message.start, options);
                if (message.end != null && message.hasOwnProperty("end"))
                    object.end = $root.google.protobuf.Timestamp.toObject(message.end, options);
                if (message.count != null && message.hasOwnProperty("count"))
                    object.count = message.count;
                return object;
            };

            /**
             * Converts this MessageSearchRequest to JSON.
             * @function toJSON
             * @memberof api.core.MessageSearchRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageSearchRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageSearchRequest
             * @function getTypeUrl
             * @memberof api.core.MessageSearchRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageSearchRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.MessageSearchRequest";
            };

            return MessageSearchRequest;
        })();

        core.MessageSearchResponse = (function() {

            /**
             * Properties of a MessageSearchResponse.
             * @memberof api.core
             * @interface IMessageSearchResponse
             * @property {api.core.IMessageSearchRequest|null} [request] MessageSearchResponse request
             * @property {Array.<api.common.IMessage>|null} [list] MessageSearchResponse list
             * @property {Long|null} [lastMsgId] MessageSearchResponse lastMsgId
             * @property {Object.<string,api.common.IChatDetail>|null} [detail] MessageSearchResponse detail
             */

            /**
             * Constructs a new MessageSearchResponse.
             * @memberof api.core
             * @classdesc Represents a MessageSearchResponse.
             * @implements IMessageSearchResponse
             * @constructor
             * @param {api.core.IMessageSearchResponse=} [properties] Properties to set
             */
            function MessageSearchResponse(properties) {
                this.list = [];
                this.detail = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageSearchResponse request.
             * @member {api.core.IMessageSearchRequest|null|undefined} request
             * @memberof api.core.MessageSearchResponse
             * @instance
             */
            MessageSearchResponse.prototype.request = null;

            /**
             * MessageSearchResponse list.
             * @member {Array.<api.common.IMessage>} list
             * @memberof api.core.MessageSearchResponse
             * @instance
             */
            MessageSearchResponse.prototype.list = $util.emptyArray;

            /**
             * MessageSearchResponse lastMsgId.
             * @member {Long} lastMsgId
             * @memberof api.core.MessageSearchResponse
             * @instance
             */
            MessageSearchResponse.prototype.lastMsgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MessageSearchResponse detail.
             * @member {Object.<string,api.common.IChatDetail>} detail
             * @memberof api.core.MessageSearchResponse
             * @instance
             */
            MessageSearchResponse.prototype.detail = $util.emptyObject;

            /**
             * Creates a new MessageSearchResponse instance using the specified properties.
             * @function create
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {api.core.IMessageSearchResponse=} [properties] Properties to set
             * @returns {api.core.MessageSearchResponse} MessageSearchResponse instance
             */
            MessageSearchResponse.create = function create(properties) {
                return new MessageSearchResponse(properties);
            };

            /**
             * Encodes the specified MessageSearchResponse message. Does not implicitly {@link api.core.MessageSearchResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {api.core.IMessageSearchResponse} message MessageSearchResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageSearchResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.request != null && Object.hasOwnProperty.call(message, "request"))
                    $root.api.core.MessageSearchRequest.encode(message.request, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.list != null && message.list.length)
                    for (let i = 0; i < message.list.length; ++i)
                        $root.api.common.Message.encode(message.list[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastMsgId != null && Object.hasOwnProperty.call(message, "lastMsgId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.lastMsgId);
                if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                    for (let keys = Object.keys(message.detail), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                        $root.api.common.ChatDetail.encode(message.detail[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                return writer;
            };

            /**
             * Encodes the specified MessageSearchResponse message, length delimited. Does not implicitly {@link api.core.MessageSearchResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {api.core.IMessageSearchResponse} message MessageSearchResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageSearchResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageSearchResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.MessageSearchResponse} MessageSearchResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageSearchResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.MessageSearchResponse(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.request = $root.api.core.MessageSearchRequest.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.list && message.list.length))
                                message.list = [];
                            message.list.push($root.api.common.Message.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.lastMsgId = reader.int64();
                            break;
                        }
                    case 4: {
                            if (message.detail === $util.emptyObject)
                                message.detail = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = 0;
                            value = null;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.int64();
                                    break;
                                case 2:
                                    value = $root.api.common.ChatDetail.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.detail[typeof key === "object" ? $util.longToHash(key) : key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageSearchResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.MessageSearchResponse} MessageSearchResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageSearchResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageSearchResponse message.
             * @function verify
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageSearchResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.request != null && message.hasOwnProperty("request")) {
                    let error = $root.api.core.MessageSearchRequest.verify(message.request);
                    if (error)
                        return "request." + error;
                }
                if (message.list != null && message.hasOwnProperty("list")) {
                    if (!Array.isArray(message.list))
                        return "list: array expected";
                    for (let i = 0; i < message.list.length; ++i) {
                        let error = $root.api.common.Message.verify(message.list[i]);
                        if (error)
                            return "list." + error;
                    }
                }
                if (message.lastMsgId != null && message.hasOwnProperty("lastMsgId"))
                    if (!$util.isInteger(message.lastMsgId) && !(message.lastMsgId && $util.isInteger(message.lastMsgId.low) && $util.isInteger(message.lastMsgId.high)))
                        return "lastMsgId: integer|Long expected";
                if (message.detail != null && message.hasOwnProperty("detail")) {
                    if (!$util.isObject(message.detail))
                        return "detail: object expected";
                    let key = Object.keys(message.detail);
                    for (let i = 0; i < key.length; ++i) {
                        if (!$util.key64Re.test(key[i]))
                            return "detail: integer|Long key{k:int64} expected";
                        {
                            let error = $root.api.common.ChatDetail.verify(message.detail[key[i]]);
                            if (error)
                                return "detail." + error;
                        }
                    }
                }
                return null;
            };

            /**
             * Creates a MessageSearchResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.MessageSearchResponse} MessageSearchResponse
             */
            MessageSearchResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.MessageSearchResponse)
                    return object;
                let message = new $root.api.core.MessageSearchResponse();
                if (object.request != null) {
                    if (typeof object.request !== "object")
                        throw TypeError(".api.core.MessageSearchResponse.request: object expected");
                    message.request = $root.api.core.MessageSearchRequest.fromObject(object.request);
                }
                if (object.list) {
                    if (!Array.isArray(object.list))
                        throw TypeError(".api.core.MessageSearchResponse.list: array expected");
                    message.list = [];
                    for (let i = 0; i < object.list.length; ++i) {
                        if (typeof object.list[i] !== "object")
                            throw TypeError(".api.core.MessageSearchResponse.list: object expected");
                        message.list[i] = $root.api.common.Message.fromObject(object.list[i]);
                    }
                }
                if (object.lastMsgId != null)
                    if ($util.Long)
                        (message.lastMsgId = $util.Long.fromValue(object.lastMsgId)).unsigned = false;
                    else if (typeof object.lastMsgId === "string")
                        message.lastMsgId = parseInt(object.lastMsgId, 10);
                    else if (typeof object.lastMsgId === "number")
                        message.lastMsgId = object.lastMsgId;
                    else if (typeof object.lastMsgId === "object")
                        message.lastMsgId = new $util.LongBits(object.lastMsgId.low >>> 0, object.lastMsgId.high >>> 0).toNumber();
                if (object.detail) {
                    if (typeof object.detail !== "object")
                        throw TypeError(".api.core.MessageSearchResponse.detail: object expected");
                    message.detail = {};
                    for (let keys = Object.keys(object.detail), i = 0; i < keys.length; ++i) {
                        if (typeof object.detail[keys[i]] !== "object")
                            throw TypeError(".api.core.MessageSearchResponse.detail: object expected");
                        message.detail[keys[i]] = $root.api.common.ChatDetail.fromObject(object.detail[keys[i]]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageSearchResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {api.core.MessageSearchResponse} message MessageSearchResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageSearchResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.list = [];
                if (options.objects || options.defaults)
                    object.detail = {};
                if (options.defaults) {
                    object.request = null;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.lastMsgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastMsgId = options.longs === String ? "0" : 0;
                }
                if (message.request != null && message.hasOwnProperty("request"))
                    object.request = $root.api.core.MessageSearchRequest.toObject(message.request, options);
                if (message.list && message.list.length) {
                    object.list = [];
                    for (let j = 0; j < message.list.length; ++j)
                        object.list[j] = $root.api.common.Message.toObject(message.list[j], options);
                }
                if (message.lastMsgId != null && message.hasOwnProperty("lastMsgId"))
                    if (typeof message.lastMsgId === "number")
                        object.lastMsgId = options.longs === String ? String(message.lastMsgId) : message.lastMsgId;
                    else
                        object.lastMsgId = options.longs === String ? $util.Long.prototype.toString.call(message.lastMsgId) : options.longs === Number ? new $util.LongBits(message.lastMsgId.low >>> 0, message.lastMsgId.high >>> 0).toNumber() : message.lastMsgId;
                let keys2;
                if (message.detail && (keys2 = Object.keys(message.detail)).length) {
                    object.detail = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.detail[keys2[j]] = $root.api.common.ChatDetail.toObject(message.detail[keys2[j]], options);
                }
                return object;
            };

            /**
             * Converts this MessageSearchResponse to JSON.
             * @function toJSON
             * @memberof api.core.MessageSearchResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageSearchResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageSearchResponse
             * @function getTypeUrl
             * @memberof api.core.MessageSearchResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageSearchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.MessageSearchResponse";
            };

            return MessageSearchResponse;
        })();

        core.QuerySpecMsgRequest = (function() {

            /**
             * Properties of a QuerySpecMsgRequest.
             * @memberof api.core
             * @interface IQuerySpecMsgRequest
             * @property {Long|null} [chatId] QuerySpecMsgRequest chatId
             * @property {Long|null} [msgId] QuerySpecMsgRequest msgId
             */

            /**
             * Constructs a new QuerySpecMsgRequest.
             * @memberof api.core
             * @classdesc Represents a QuerySpecMsgRequest.
             * @implements IQuerySpecMsgRequest
             * @constructor
             * @param {api.core.IQuerySpecMsgRequest=} [properties] Properties to set
             */
            function QuerySpecMsgRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuerySpecMsgRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.QuerySpecMsgRequest
             * @instance
             */
            QuerySpecMsgRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * QuerySpecMsgRequest msgId.
             * @member {Long} msgId
             * @memberof api.core.QuerySpecMsgRequest
             * @instance
             */
            QuerySpecMsgRequest.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new QuerySpecMsgRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {api.core.IQuerySpecMsgRequest=} [properties] Properties to set
             * @returns {api.core.QuerySpecMsgRequest} QuerySpecMsgRequest instance
             */
            QuerySpecMsgRequest.create = function create(properties) {
                return new QuerySpecMsgRequest(properties);
            };

            /**
             * Encodes the specified QuerySpecMsgRequest message. Does not implicitly {@link api.core.QuerySpecMsgRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {api.core.IQuerySpecMsgRequest} message QuerySpecMsgRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySpecMsgRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.msgId);
                return writer;
            };

            /**
             * Encodes the specified QuerySpecMsgRequest message, length delimited. Does not implicitly {@link api.core.QuerySpecMsgRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {api.core.IQuerySpecMsgRequest} message QuerySpecMsgRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySpecMsgRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuerySpecMsgRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuerySpecMsgRequest} QuerySpecMsgRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySpecMsgRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuerySpecMsgRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.msgId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuerySpecMsgRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuerySpecMsgRequest} QuerySpecMsgRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySpecMsgRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuerySpecMsgRequest message.
             * @function verify
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuerySpecMsgRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                        return "msgId: integer|Long expected";
                return null;
            };

            /**
             * Creates a QuerySpecMsgRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuerySpecMsgRequest} QuerySpecMsgRequest
             */
            QuerySpecMsgRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuerySpecMsgRequest)
                    return object;
                let message = new $root.api.core.QuerySpecMsgRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.msgId != null)
                    if ($util.Long)
                        (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = false;
                    else if (typeof object.msgId === "string")
                        message.msgId = parseInt(object.msgId, 10);
                    else if (typeof object.msgId === "number")
                        message.msgId = object.msgId;
                    else if (typeof object.msgId === "object")
                        message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a QuerySpecMsgRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {api.core.QuerySpecMsgRequest} message QuerySpecMsgRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuerySpecMsgRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.msgId = options.longs === String ? "0" : 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (typeof message.msgId === "number")
                        object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                    else
                        object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber() : message.msgId;
                return object;
            };

            /**
             * Converts this QuerySpecMsgRequest to JSON.
             * @function toJSON
             * @memberof api.core.QuerySpecMsgRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuerySpecMsgRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuerySpecMsgRequest
             * @function getTypeUrl
             * @memberof api.core.QuerySpecMsgRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuerySpecMsgRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuerySpecMsgRequest";
            };

            return QuerySpecMsgRequest;
        })();

        core.QuerySpecMsgResponse = (function() {

            /**
             * Properties of a QuerySpecMsgResponse.
             * @memberof api.core
             * @interface IQuerySpecMsgResponse
             * @property {api.common.IMessage|null} [msg] QuerySpecMsgResponse msg
             */

            /**
             * Constructs a new QuerySpecMsgResponse.
             * @memberof api.core
             * @classdesc Represents a QuerySpecMsgResponse.
             * @implements IQuerySpecMsgResponse
             * @constructor
             * @param {api.core.IQuerySpecMsgResponse=} [properties] Properties to set
             */
            function QuerySpecMsgResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuerySpecMsgResponse msg.
             * @member {api.common.IMessage|null|undefined} msg
             * @memberof api.core.QuerySpecMsgResponse
             * @instance
             */
            QuerySpecMsgResponse.prototype.msg = null;

            /**
             * Creates a new QuerySpecMsgResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {api.core.IQuerySpecMsgResponse=} [properties] Properties to set
             * @returns {api.core.QuerySpecMsgResponse} QuerySpecMsgResponse instance
             */
            QuerySpecMsgResponse.create = function create(properties) {
                return new QuerySpecMsgResponse(properties);
            };

            /**
             * Encodes the specified QuerySpecMsgResponse message. Does not implicitly {@link api.core.QuerySpecMsgResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {api.core.IQuerySpecMsgResponse} message QuerySpecMsgResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySpecMsgResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                    $root.api.common.Message.encode(message.msg, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuerySpecMsgResponse message, length delimited. Does not implicitly {@link api.core.QuerySpecMsgResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {api.core.IQuerySpecMsgResponse} message QuerySpecMsgResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySpecMsgResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuerySpecMsgResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuerySpecMsgResponse} QuerySpecMsgResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySpecMsgResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuerySpecMsgResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.msg = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuerySpecMsgResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuerySpecMsgResponse} QuerySpecMsgResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySpecMsgResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuerySpecMsgResponse message.
             * @function verify
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuerySpecMsgResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.msg != null && message.hasOwnProperty("msg")) {
                    let error = $root.api.common.Message.verify(message.msg);
                    if (error)
                        return "msg." + error;
                }
                return null;
            };

            /**
             * Creates a QuerySpecMsgResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuerySpecMsgResponse} QuerySpecMsgResponse
             */
            QuerySpecMsgResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuerySpecMsgResponse)
                    return object;
                let message = new $root.api.core.QuerySpecMsgResponse();
                if (object.msg != null) {
                    if (typeof object.msg !== "object")
                        throw TypeError(".api.core.QuerySpecMsgResponse.msg: object expected");
                    message.msg = $root.api.common.Message.fromObject(object.msg);
                }
                return message;
            };

            /**
             * Creates a plain object from a QuerySpecMsgResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {api.core.QuerySpecMsgResponse} message QuerySpecMsgResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuerySpecMsgResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.msg = null;
                if (message.msg != null && message.hasOwnProperty("msg"))
                    object.msg = $root.api.common.Message.toObject(message.msg, options);
                return object;
            };

            /**
             * Converts this QuerySpecMsgResponse to JSON.
             * @function toJSON
             * @memberof api.core.QuerySpecMsgResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuerySpecMsgResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuerySpecMsgResponse
             * @function getTypeUrl
             * @memberof api.core.QuerySpecMsgResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuerySpecMsgResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuerySpecMsgResponse";
            };

            return QuerySpecMsgResponse;
        })();

        core.MessageClientService = (function() {

            /**
             * Constructs a new MessageClientService service.
             * @memberof api.core
             * @classdesc Represents a MessageClientService
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function MessageClientService(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (MessageClientService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MessageClientService;

            /**
             * Creates new MessageClientService service using the specified rpc implementation.
             * @function create
             * @memberof api.core.MessageClientService
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {MessageClientService} RPC service. Useful where requests and/or responses are streamed.
             */
            MessageClientService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.MessageClientService#sync}.
             * @memberof api.core.MessageClientService
             * @typedef syncCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.MessageSyncResponse} [response] MessageSyncResponse
             */

            /**
             * Calls sync.
             * @function sync
             * @memberof api.core.MessageClientService
             * @instance
             * @param {api.core.IMessageSyncRequest} request MessageSyncRequest message or plain object
             * @param {api.core.MessageClientService.syncCallback} callback Node-style callback called with the error, if any, and MessageSyncResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(MessageClientService.prototype.sync = function sync(request, callback) {
                return this.rpcCall(sync, $root.api.core.MessageSyncRequest, $root.api.core.MessageSyncResponse, request, callback);
            }, "name", { value: "sync" });

            /**
             * Calls sync.
             * @function sync
             * @memberof api.core.MessageClientService
             * @instance
             * @param {api.core.IMessageSyncRequest} request MessageSyncRequest message or plain object
             * @returns {Promise<api.core.MessageSyncResponse>} Promise
             * @variation 2
             */

            return MessageClientService;
        })();

        core.MessageTenantService = (function() {

            /**
             * Constructs a new MessageTenantService service.
             * @memberof api.core
             * @classdesc Represents a MessageTenantService
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function MessageTenantService(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (MessageTenantService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MessageTenantService;

            /**
             * Creates new MessageTenantService service using the specified rpc implementation.
             * @function create
             * @memberof api.core.MessageTenantService
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {MessageTenantService} RPC service. Useful where requests and/or responses are streamed.
             */
            MessageTenantService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.MessageTenantService#sync}.
             * @memberof api.core.MessageTenantService
             * @typedef syncCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.MessageSyncResponse} [response] MessageSyncResponse
             */

            /**
             * Calls sync.
             * @function sync
             * @memberof api.core.MessageTenantService
             * @instance
             * @param {api.core.IMessageSyncRequest} request MessageSyncRequest message or plain object
             * @param {api.core.MessageTenantService.syncCallback} callback Node-style callback called with the error, if any, and MessageSyncResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(MessageTenantService.prototype.sync = function sync(request, callback) {
                return this.rpcCall(sync, $root.api.core.MessageSyncRequest, $root.api.core.MessageSyncResponse, request, callback);
            }, "name", { value: "sync" });

            /**
             * Calls sync.
             * @function sync
             * @memberof api.core.MessageTenantService
             * @instance
             * @param {api.core.IMessageSyncRequest} request MessageSyncRequest message or plain object
             * @returns {Promise<api.core.MessageSyncResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.MessageTenantService#search}.
             * @memberof api.core.MessageTenantService
             * @typedef searchCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.MessageSearchResponse} [response] MessageSearchResponse
             */

            /**
             * Calls search.
             * @function search
             * @memberof api.core.MessageTenantService
             * @instance
             * @param {api.core.IMessageSearchRequest} request MessageSearchRequest message or plain object
             * @param {api.core.MessageTenantService.searchCallback} callback Node-style callback called with the error, if any, and MessageSearchResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(MessageTenantService.prototype.search = function search(request, callback) {
                return this.rpcCall(search, $root.api.core.MessageSearchRequest, $root.api.core.MessageSearchResponse, request, callback);
            }, "name", { value: "search" });

            /**
             * Calls search.
             * @function search
             * @memberof api.core.MessageTenantService
             * @instance
             * @param {api.core.IMessageSearchRequest} request MessageSearchRequest message or plain object
             * @returns {Promise<api.core.MessageSearchResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.MessageTenantService#querySpec}.
             * @memberof api.core.MessageTenantService
             * @typedef QuerySpecCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QuerySpecMsgResponse} [response] QuerySpecMsgResponse
             */

            /**
             * Calls QuerySpec.
             * @function querySpec
             * @memberof api.core.MessageTenantService
             * @instance
             * @param {api.core.IQuerySpecMsgRequest} request QuerySpecMsgRequest message or plain object
             * @param {api.core.MessageTenantService.QuerySpecCallback} callback Node-style callback called with the error, if any, and QuerySpecMsgResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(MessageTenantService.prototype.querySpec = function querySpec(request, callback) {
                return this.rpcCall(querySpec, $root.api.core.QuerySpecMsgRequest, $root.api.core.QuerySpecMsgResponse, request, callback);
            }, "name", { value: "QuerySpec" });

            /**
             * Calls QuerySpec.
             * @function querySpec
             * @memberof api.core.MessageTenantService
             * @instance
             * @param {api.core.IQuerySpecMsgRequest} request QuerySpecMsgRequest message or plain object
             * @returns {Promise<api.core.QuerySpecMsgResponse>} Promise
             * @variation 2
             */

            return MessageTenantService;
        })();

        core.WorkerMetric = (function() {

            /**
             * Properties of a WorkerMetric.
             * @memberof api.core
             * @interface IWorkerMetric
             * @property {number|null} [replyPercent_3Min] WorkerMetric replyPercent_3Min
             * @property {number|null} [todayService] WorkerMetric todayService
             * @property {number|null} [avgReplyInterval] WorkerMetric avgReplyInterval
             * @property {number|null} [transferTo] WorkerMetric transferTo
             * @property {number|null} [transferFrom] WorkerMetric transferFrom
             */

            /**
             * Constructs a new WorkerMetric.
             * @memberof api.core
             * @classdesc Represents a WorkerMetric.
             * @implements IWorkerMetric
             * @constructor
             * @param {api.core.IWorkerMetric=} [properties] Properties to set
             */
            function WorkerMetric(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerMetric replyPercent_3Min.
             * @member {number} replyPercent_3Min
             * @memberof api.core.WorkerMetric
             * @instance
             */
            WorkerMetric.prototype.replyPercent_3Min = 0;

            /**
             * WorkerMetric todayService.
             * @member {number} todayService
             * @memberof api.core.WorkerMetric
             * @instance
             */
            WorkerMetric.prototype.todayService = 0;

            /**
             * WorkerMetric avgReplyInterval.
             * @member {number} avgReplyInterval
             * @memberof api.core.WorkerMetric
             * @instance
             */
            WorkerMetric.prototype.avgReplyInterval = 0;

            /**
             * WorkerMetric transferTo.
             * @member {number} transferTo
             * @memberof api.core.WorkerMetric
             * @instance
             */
            WorkerMetric.prototype.transferTo = 0;

            /**
             * WorkerMetric transferFrom.
             * @member {number} transferFrom
             * @memberof api.core.WorkerMetric
             * @instance
             */
            WorkerMetric.prototype.transferFrom = 0;

            /**
             * Creates a new WorkerMetric instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerMetric
             * @static
             * @param {api.core.IWorkerMetric=} [properties] Properties to set
             * @returns {api.core.WorkerMetric} WorkerMetric instance
             */
            WorkerMetric.create = function create(properties) {
                return new WorkerMetric(properties);
            };

            /**
             * Encodes the specified WorkerMetric message. Does not implicitly {@link api.core.WorkerMetric.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerMetric
             * @static
             * @param {api.core.IWorkerMetric} message WorkerMetric message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerMetric.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.replyPercent_3Min != null && Object.hasOwnProperty.call(message, "replyPercent_3Min"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.replyPercent_3Min);
                if (message.todayService != null && Object.hasOwnProperty.call(message, "todayService"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.todayService);
                if (message.avgReplyInterval != null && Object.hasOwnProperty.call(message, "avgReplyInterval"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.avgReplyInterval);
                if (message.transferTo != null && Object.hasOwnProperty.call(message, "transferTo"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.transferTo);
                if (message.transferFrom != null && Object.hasOwnProperty.call(message, "transferFrom"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.transferFrom);
                return writer;
            };

            /**
             * Encodes the specified WorkerMetric message, length delimited. Does not implicitly {@link api.core.WorkerMetric.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerMetric
             * @static
             * @param {api.core.IWorkerMetric} message WorkerMetric message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerMetric.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerMetric message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerMetric
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerMetric} WorkerMetric
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerMetric.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerMetric();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.replyPercent_3Min = reader.int32();
                            break;
                        }
                    case 2: {
                            message.todayService = reader.int32();
                            break;
                        }
                    case 3: {
                            message.avgReplyInterval = reader.int32();
                            break;
                        }
                    case 4: {
                            message.transferTo = reader.int32();
                            break;
                        }
                    case 5: {
                            message.transferFrom = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerMetric message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerMetric
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerMetric} WorkerMetric
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerMetric.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerMetric message.
             * @function verify
             * @memberof api.core.WorkerMetric
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerMetric.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.replyPercent_3Min != null && message.hasOwnProperty("replyPercent_3Min"))
                    if (!$util.isInteger(message.replyPercent_3Min))
                        return "replyPercent_3Min: integer expected";
                if (message.todayService != null && message.hasOwnProperty("todayService"))
                    if (!$util.isInteger(message.todayService))
                        return "todayService: integer expected";
                if (message.avgReplyInterval != null && message.hasOwnProperty("avgReplyInterval"))
                    if (!$util.isInteger(message.avgReplyInterval))
                        return "avgReplyInterval: integer expected";
                if (message.transferTo != null && message.hasOwnProperty("transferTo"))
                    if (!$util.isInteger(message.transferTo))
                        return "transferTo: integer expected";
                if (message.transferFrom != null && message.hasOwnProperty("transferFrom"))
                    if (!$util.isInteger(message.transferFrom))
                        return "transferFrom: integer expected";
                return null;
            };

            /**
             * Creates a WorkerMetric message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerMetric
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerMetric} WorkerMetric
             */
            WorkerMetric.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerMetric)
                    return object;
                let message = new $root.api.core.WorkerMetric();
                if (object.replyPercent_3Min != null)
                    message.replyPercent_3Min = object.replyPercent_3Min | 0;
                if (object.todayService != null)
                    message.todayService = object.todayService | 0;
                if (object.avgReplyInterval != null)
                    message.avgReplyInterval = object.avgReplyInterval | 0;
                if (object.transferTo != null)
                    message.transferTo = object.transferTo | 0;
                if (object.transferFrom != null)
                    message.transferFrom = object.transferFrom | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerMetric message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerMetric
             * @static
             * @param {api.core.WorkerMetric} message WorkerMetric
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerMetric.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.replyPercent_3Min = 0;
                    object.todayService = 0;
                    object.avgReplyInterval = 0;
                    object.transferTo = 0;
                    object.transferFrom = 0;
                }
                if (message.replyPercent_3Min != null && message.hasOwnProperty("replyPercent_3Min"))
                    object.replyPercent_3Min = message.replyPercent_3Min;
                if (message.todayService != null && message.hasOwnProperty("todayService"))
                    object.todayService = message.todayService;
                if (message.avgReplyInterval != null && message.hasOwnProperty("avgReplyInterval"))
                    object.avgReplyInterval = message.avgReplyInterval;
                if (message.transferTo != null && message.hasOwnProperty("transferTo"))
                    object.transferTo = message.transferTo;
                if (message.transferFrom != null && message.hasOwnProperty("transferFrom"))
                    object.transferFrom = message.transferFrom;
                return object;
            };

            /**
             * Converts this WorkerMetric to JSON.
             * @function toJSON
             * @memberof api.core.WorkerMetric
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerMetric.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerMetric
             * @function getTypeUrl
             * @memberof api.core.WorkerMetric
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerMetric.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerMetric";
            };

            return WorkerMetric;
        })();

        core.QuerySelfResponse = (function() {

            /**
             * Properties of a QuerySelfResponse.
             * @memberof api.core
             * @interface IQuerySelfResponse
             * @property {api.core.IWorkerMetric|null} [metric] QuerySelfResponse metric
             */

            /**
             * Constructs a new QuerySelfResponse.
             * @memberof api.core
             * @classdesc Represents a QuerySelfResponse.
             * @implements IQuerySelfResponse
             * @constructor
             * @param {api.core.IQuerySelfResponse=} [properties] Properties to set
             */
            function QuerySelfResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuerySelfResponse metric.
             * @member {api.core.IWorkerMetric|null|undefined} metric
             * @memberof api.core.QuerySelfResponse
             * @instance
             */
            QuerySelfResponse.prototype.metric = null;

            /**
             * Creates a new QuerySelfResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {api.core.IQuerySelfResponse=} [properties] Properties to set
             * @returns {api.core.QuerySelfResponse} QuerySelfResponse instance
             */
            QuerySelfResponse.create = function create(properties) {
                return new QuerySelfResponse(properties);
            };

            /**
             * Encodes the specified QuerySelfResponse message. Does not implicitly {@link api.core.QuerySelfResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {api.core.IQuerySelfResponse} message QuerySelfResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySelfResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.metric != null && Object.hasOwnProperty.call(message, "metric"))
                    $root.api.core.WorkerMetric.encode(message.metric, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuerySelfResponse message, length delimited. Does not implicitly {@link api.core.QuerySelfResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {api.core.IQuerySelfResponse} message QuerySelfResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySelfResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuerySelfResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuerySelfResponse} QuerySelfResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySelfResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuerySelfResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.metric = $root.api.core.WorkerMetric.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuerySelfResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuerySelfResponse} QuerySelfResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySelfResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuerySelfResponse message.
             * @function verify
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuerySelfResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.metric != null && message.hasOwnProperty("metric")) {
                    let error = $root.api.core.WorkerMetric.verify(message.metric);
                    if (error)
                        return "metric." + error;
                }
                return null;
            };

            /**
             * Creates a QuerySelfResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuerySelfResponse} QuerySelfResponse
             */
            QuerySelfResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuerySelfResponse)
                    return object;
                let message = new $root.api.core.QuerySelfResponse();
                if (object.metric != null) {
                    if (typeof object.metric !== "object")
                        throw TypeError(".api.core.QuerySelfResponse.metric: object expected");
                    message.metric = $root.api.core.WorkerMetric.fromObject(object.metric);
                }
                return message;
            };

            /**
             * Creates a plain object from a QuerySelfResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {api.core.QuerySelfResponse} message QuerySelfResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuerySelfResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.metric = null;
                if (message.metric != null && message.hasOwnProperty("metric"))
                    object.metric = $root.api.core.WorkerMetric.toObject(message.metric, options);
                return object;
            };

            /**
             * Converts this QuerySelfResponse to JSON.
             * @function toJSON
             * @memberof api.core.QuerySelfResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuerySelfResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuerySelfResponse
             * @function getTypeUrl
             * @memberof api.core.QuerySelfResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuerySelfResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuerySelfResponse";
            };

            return QuerySelfResponse;
        })();

        core.Metric = (function() {

            /**
             * Constructs a new Metric service.
             * @memberof api.core
             * @classdesc Represents a Metric
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Metric(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Metric.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Metric;

            /**
             * Creates new Metric service using the specified rpc implementation.
             * @function create
             * @memberof api.core.Metric
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Metric} RPC service. Useful where requests and/or responses are streamed.
             */
            Metric.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.Metric#querySelf}.
             * @memberof api.core.Metric
             * @typedef QuerySelfCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QuerySelfResponse} [response] QuerySelfResponse
             */

            /**
             * Calls QuerySelf.
             * @function querySelf
             * @memberof api.core.Metric
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Metric.QuerySelfCallback} callback Node-style callback called with the error, if any, and QuerySelfResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Metric.prototype.querySelf = function querySelf(request, callback) {
                return this.rpcCall(querySelf, $root.google.protobuf.Empty, $root.api.core.QuerySelfResponse, request, callback);
            }, "name", { value: "QuerySelf" });

            /**
             * Calls QuerySelf.
             * @function querySelf
             * @memberof api.core.Metric
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.QuerySelfResponse>} Promise
             * @variation 2
             */

            return Metric;
        })();

        core.NoteItem = (function() {

            /**
             * Properties of a NoteItem.
             * @memberof api.core
             * @interface INoteItem
             * @property {number|null} [workerId] NoteItem workerId
             * @property {string|null} [workerName] NoteItem workerName
             * @property {google.protobuf.ITimestamp|null} [createAt] NoteItem createAt
             * @property {api.common.IMessage|null} [noteMsg] NoteItem noteMsg
             */

            /**
             * Constructs a new NoteItem.
             * @memberof api.core
             * @classdesc Represents a NoteItem.
             * @implements INoteItem
             * @constructor
             * @param {api.core.INoteItem=} [properties] Properties to set
             */
            function NoteItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NoteItem workerId.
             * @member {number} workerId
             * @memberof api.core.NoteItem
             * @instance
             */
            NoteItem.prototype.workerId = 0;

            /**
             * NoteItem workerName.
             * @member {string} workerName
             * @memberof api.core.NoteItem
             * @instance
             */
            NoteItem.prototype.workerName = "";

            /**
             * NoteItem createAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createAt
             * @memberof api.core.NoteItem
             * @instance
             */
            NoteItem.prototype.createAt = null;

            /**
             * NoteItem noteMsg.
             * @member {api.common.IMessage|null|undefined} noteMsg
             * @memberof api.core.NoteItem
             * @instance
             */
            NoteItem.prototype.noteMsg = null;

            /**
             * Creates a new NoteItem instance using the specified properties.
             * @function create
             * @memberof api.core.NoteItem
             * @static
             * @param {api.core.INoteItem=} [properties] Properties to set
             * @returns {api.core.NoteItem} NoteItem instance
             */
            NoteItem.create = function create(properties) {
                return new NoteItem(properties);
            };

            /**
             * Encodes the specified NoteItem message. Does not implicitly {@link api.core.NoteItem.verify|verify} messages.
             * @function encode
             * @memberof api.core.NoteItem
             * @static
             * @param {api.core.INoteItem} message NoteItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NoteItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                if (message.workerName != null && Object.hasOwnProperty.call(message, "workerName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.workerName);
                if (message.createAt != null && Object.hasOwnProperty.call(message, "createAt"))
                    $root.google.protobuf.Timestamp.encode(message.createAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.noteMsg != null && Object.hasOwnProperty.call(message, "noteMsg"))
                    $root.api.common.Message.encode(message.noteMsg, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified NoteItem message, length delimited. Does not implicitly {@link api.core.NoteItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.NoteItem
             * @static
             * @param {api.core.INoteItem} message NoteItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NoteItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NoteItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.NoteItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.NoteItem} NoteItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NoteItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.NoteItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.workerName = reader.string();
                            break;
                        }
                    case 3: {
                            message.createAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.noteMsg = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NoteItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.NoteItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.NoteItem} NoteItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NoteItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NoteItem message.
             * @function verify
             * @memberof api.core.NoteItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NoteItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                if (message.workerName != null && message.hasOwnProperty("workerName"))
                    if (!$util.isString(message.workerName))
                        return "workerName: string expected";
                if (message.createAt != null && message.hasOwnProperty("createAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.createAt);
                    if (error)
                        return "createAt." + error;
                }
                if (message.noteMsg != null && message.hasOwnProperty("noteMsg")) {
                    let error = $root.api.common.Message.verify(message.noteMsg);
                    if (error)
                        return "noteMsg." + error;
                }
                return null;
            };

            /**
             * Creates a NoteItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.NoteItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.NoteItem} NoteItem
             */
            NoteItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.NoteItem)
                    return object;
                let message = new $root.api.core.NoteItem();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                if (object.workerName != null)
                    message.workerName = String(object.workerName);
                if (object.createAt != null) {
                    if (typeof object.createAt !== "object")
                        throw TypeError(".api.core.NoteItem.createAt: object expected");
                    message.createAt = $root.google.protobuf.Timestamp.fromObject(object.createAt);
                }
                if (object.noteMsg != null) {
                    if (typeof object.noteMsg !== "object")
                        throw TypeError(".api.core.NoteItem.noteMsg: object expected");
                    message.noteMsg = $root.api.common.Message.fromObject(object.noteMsg);
                }
                return message;
            };

            /**
             * Creates a plain object from a NoteItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.NoteItem
             * @static
             * @param {api.core.NoteItem} message NoteItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NoteItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.workerId = 0;
                    object.workerName = "";
                    object.createAt = null;
                    object.noteMsg = null;
                }
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                if (message.workerName != null && message.hasOwnProperty("workerName"))
                    object.workerName = message.workerName;
                if (message.createAt != null && message.hasOwnProperty("createAt"))
                    object.createAt = $root.google.protobuf.Timestamp.toObject(message.createAt, options);
                if (message.noteMsg != null && message.hasOwnProperty("noteMsg"))
                    object.noteMsg = $root.api.common.Message.toObject(message.noteMsg, options);
                return object;
            };

            /**
             * Converts this NoteItem to JSON.
             * @function toJSON
             * @memberof api.core.NoteItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NoteItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for NoteItem
             * @function getTypeUrl
             * @memberof api.core.NoteItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            NoteItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.NoteItem";
            };

            return NoteItem;
        })();

        core.Note = (function() {

            /**
             * Properties of a Note.
             * @memberof api.core
             * @interface INote
             * @property {Long|null} [chatNoteMsgId] Note chatNoteMsgId
             * @property {api.common.IMessage|null} [src] Note src
             * @property {Array.<api.core.INoteItem>|null} [notes] Note notes
             */

            /**
             * Constructs a new Note.
             * @memberof api.core
             * @classdesc Represents a Note.
             * @implements INote
             * @constructor
             * @param {api.core.INote=} [properties] Properties to set
             */
            function Note(properties) {
                this.notes = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Note chatNoteMsgId.
             * @member {Long} chatNoteMsgId
             * @memberof api.core.Note
             * @instance
             */
            Note.prototype.chatNoteMsgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Note src.
             * @member {api.common.IMessage|null|undefined} src
             * @memberof api.core.Note
             * @instance
             */
            Note.prototype.src = null;

            /**
             * Note notes.
             * @member {Array.<api.core.INoteItem>} notes
             * @memberof api.core.Note
             * @instance
             */
            Note.prototype.notes = $util.emptyArray;

            /**
             * Creates a new Note instance using the specified properties.
             * @function create
             * @memberof api.core.Note
             * @static
             * @param {api.core.INote=} [properties] Properties to set
             * @returns {api.core.Note} Note instance
             */
            Note.create = function create(properties) {
                return new Note(properties);
            };

            /**
             * Encodes the specified Note message. Does not implicitly {@link api.core.Note.verify|verify} messages.
             * @function encode
             * @memberof api.core.Note
             * @static
             * @param {api.core.INote} message Note message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Note.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatNoteMsgId != null && Object.hasOwnProperty.call(message, "chatNoteMsgId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatNoteMsgId);
                if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                    $root.api.common.Message.encode(message.src, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.notes != null && message.notes.length)
                    for (let i = 0; i < message.notes.length; ++i)
                        $root.api.core.NoteItem.encode(message.notes[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Note message, length delimited. Does not implicitly {@link api.core.Note.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.Note
             * @static
             * @param {api.core.INote} message Note message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Note.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Note message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.Note
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.Note} Note
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Note.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.Note();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatNoteMsgId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.src = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            if (!(message.notes && message.notes.length))
                                message.notes = [];
                            message.notes.push($root.api.core.NoteItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Note message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.Note
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.Note} Note
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Note.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Note message.
             * @function verify
             * @memberof api.core.Note
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Note.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatNoteMsgId != null && message.hasOwnProperty("chatNoteMsgId"))
                    if (!$util.isInteger(message.chatNoteMsgId) && !(message.chatNoteMsgId && $util.isInteger(message.chatNoteMsgId.low) && $util.isInteger(message.chatNoteMsgId.high)))
                        return "chatNoteMsgId: integer|Long expected";
                if (message.src != null && message.hasOwnProperty("src")) {
                    let error = $root.api.common.Message.verify(message.src);
                    if (error)
                        return "src." + error;
                }
                if (message.notes != null && message.hasOwnProperty("notes")) {
                    if (!Array.isArray(message.notes))
                        return "notes: array expected";
                    for (let i = 0; i < message.notes.length; ++i) {
                        let error = $root.api.core.NoteItem.verify(message.notes[i]);
                        if (error)
                            return "notes." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Note message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.Note
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.Note} Note
             */
            Note.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.Note)
                    return object;
                let message = new $root.api.core.Note();
                if (object.chatNoteMsgId != null)
                    if ($util.Long)
                        (message.chatNoteMsgId = $util.Long.fromValue(object.chatNoteMsgId)).unsigned = false;
                    else if (typeof object.chatNoteMsgId === "string")
                        message.chatNoteMsgId = parseInt(object.chatNoteMsgId, 10);
                    else if (typeof object.chatNoteMsgId === "number")
                        message.chatNoteMsgId = object.chatNoteMsgId;
                    else if (typeof object.chatNoteMsgId === "object")
                        message.chatNoteMsgId = new $util.LongBits(object.chatNoteMsgId.low >>> 0, object.chatNoteMsgId.high >>> 0).toNumber();
                if (object.src != null) {
                    if (typeof object.src !== "object")
                        throw TypeError(".api.core.Note.src: object expected");
                    message.src = $root.api.common.Message.fromObject(object.src);
                }
                if (object.notes) {
                    if (!Array.isArray(object.notes))
                        throw TypeError(".api.core.Note.notes: array expected");
                    message.notes = [];
                    for (let i = 0; i < object.notes.length; ++i) {
                        if (typeof object.notes[i] !== "object")
                            throw TypeError(".api.core.Note.notes: object expected");
                        message.notes[i] = $root.api.core.NoteItem.fromObject(object.notes[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Note message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.Note
             * @static
             * @param {api.core.Note} message Note
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Note.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.notes = [];
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatNoteMsgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatNoteMsgId = options.longs === String ? "0" : 0;
                    object.src = null;
                }
                if (message.chatNoteMsgId != null && message.hasOwnProperty("chatNoteMsgId"))
                    if (typeof message.chatNoteMsgId === "number")
                        object.chatNoteMsgId = options.longs === String ? String(message.chatNoteMsgId) : message.chatNoteMsgId;
                    else
                        object.chatNoteMsgId = options.longs === String ? $util.Long.prototype.toString.call(message.chatNoteMsgId) : options.longs === Number ? new $util.LongBits(message.chatNoteMsgId.low >>> 0, message.chatNoteMsgId.high >>> 0).toNumber() : message.chatNoteMsgId;
                if (message.src != null && message.hasOwnProperty("src"))
                    object.src = $root.api.common.Message.toObject(message.src, options);
                if (message.notes && message.notes.length) {
                    object.notes = [];
                    for (let j = 0; j < message.notes.length; ++j)
                        object.notes[j] = $root.api.core.NoteItem.toObject(message.notes[j], options);
                }
                return object;
            };

            /**
             * Converts this Note to JSON.
             * @function toJSON
             * @memberof api.core.Note
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Note.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Note
             * @function getTypeUrl
             * @memberof api.core.Note
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Note.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.Note";
            };

            return Note;
        })();

        core.QueryNoteRequest = (function() {

            /**
             * Properties of a QueryNoteRequest.
             * @memberof api.core
             * @interface IQueryNoteRequest
             * @property {Long|null} [chatId] QueryNoteRequest chatId
             * @property {api.common.IBatch|null} [batch] QueryNoteRequest batch
             */

            /**
             * Constructs a new QueryNoteRequest.
             * @memberof api.core
             * @classdesc Represents a QueryNoteRequest.
             * @implements IQueryNoteRequest
             * @constructor
             * @param {api.core.IQueryNoteRequest=} [properties] Properties to set
             */
            function QueryNoteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryNoteRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.QueryNoteRequest
             * @instance
             */
            QueryNoteRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * QueryNoteRequest batch.
             * @member {api.common.IBatch|null|undefined} batch
             * @memberof api.core.QueryNoteRequest
             * @instance
             */
            QueryNoteRequest.prototype.batch = null;

            /**
             * Creates a new QueryNoteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {api.core.IQueryNoteRequest=} [properties] Properties to set
             * @returns {api.core.QueryNoteRequest} QueryNoteRequest instance
             */
            QueryNoteRequest.create = function create(properties) {
                return new QueryNoteRequest(properties);
            };

            /**
             * Encodes the specified QueryNoteRequest message. Does not implicitly {@link api.core.QueryNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {api.core.IQueryNoteRequest} message QueryNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryNoteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.batch != null && Object.hasOwnProperty.call(message, "batch"))
                    $root.api.common.Batch.encode(message.batch, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryNoteRequest message, length delimited. Does not implicitly {@link api.core.QueryNoteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {api.core.IQueryNoteRequest} message QueryNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryNoteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryNoteRequest} QueryNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryNoteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryNoteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.batch = $root.api.common.Batch.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryNoteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryNoteRequest} QueryNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryNoteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryNoteRequest message.
             * @function verify
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryNoteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.batch != null && message.hasOwnProperty("batch")) {
                    let error = $root.api.common.Batch.verify(message.batch);
                    if (error)
                        return "batch." + error;
                }
                return null;
            };

            /**
             * Creates a QueryNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryNoteRequest} QueryNoteRequest
             */
            QueryNoteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryNoteRequest)
                    return object;
                let message = new $root.api.core.QueryNoteRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.batch != null) {
                    if (typeof object.batch !== "object")
                        throw TypeError(".api.core.QueryNoteRequest.batch: object expected");
                    message.batch = $root.api.common.Batch.fromObject(object.batch);
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {api.core.QueryNoteRequest} message QueryNoteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryNoteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.batch = null;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.batch != null && message.hasOwnProperty("batch"))
                    object.batch = $root.api.common.Batch.toObject(message.batch, options);
                return object;
            };

            /**
             * Converts this QueryNoteRequest to JSON.
             * @function toJSON
             * @memberof api.core.QueryNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryNoteRequest
             * @function getTypeUrl
             * @memberof api.core.QueryNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryNoteRequest";
            };

            return QueryNoteRequest;
        })();

        core.QueryNoteResponse = (function() {

            /**
             * Properties of a QueryNoteResponse.
             * @memberof api.core
             * @interface IQueryNoteResponse
             * @property {Long|null} [chatId] QueryNoteResponse chatId
             * @property {Long|null} [total] QueryNoteResponse total
             * @property {api.common.IBatch|null} [batch] QueryNoteResponse batch
             * @property {Array.<api.core.INote>|null} [notes] QueryNoteResponse notes
             */

            /**
             * Constructs a new QueryNoteResponse.
             * @memberof api.core
             * @classdesc Represents a QueryNoteResponse.
             * @implements IQueryNoteResponse
             * @constructor
             * @param {api.core.IQueryNoteResponse=} [properties] Properties to set
             */
            function QueryNoteResponse(properties) {
                this.notes = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryNoteResponse chatId.
             * @member {Long} chatId
             * @memberof api.core.QueryNoteResponse
             * @instance
             */
            QueryNoteResponse.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * QueryNoteResponse total.
             * @member {Long} total
             * @memberof api.core.QueryNoteResponse
             * @instance
             */
            QueryNoteResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * QueryNoteResponse batch.
             * @member {api.common.IBatch|null|undefined} batch
             * @memberof api.core.QueryNoteResponse
             * @instance
             */
            QueryNoteResponse.prototype.batch = null;

            /**
             * QueryNoteResponse notes.
             * @member {Array.<api.core.INote>} notes
             * @memberof api.core.QueryNoteResponse
             * @instance
             */
            QueryNoteResponse.prototype.notes = $util.emptyArray;

            /**
             * Creates a new QueryNoteResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {api.core.IQueryNoteResponse=} [properties] Properties to set
             * @returns {api.core.QueryNoteResponse} QueryNoteResponse instance
             */
            QueryNoteResponse.create = function create(properties) {
                return new QueryNoteResponse(properties);
            };

            /**
             * Encodes the specified QueryNoteResponse message. Does not implicitly {@link api.core.QueryNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {api.core.IQueryNoteResponse} message QueryNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryNoteResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.total);
                if (message.batch != null && Object.hasOwnProperty.call(message, "batch"))
                    $root.api.common.Batch.encode(message.batch, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.notes != null && message.notes.length)
                    for (let i = 0; i < message.notes.length; ++i)
                        $root.api.core.Note.encode(message.notes[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryNoteResponse message, length delimited. Does not implicitly {@link api.core.QueryNoteResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {api.core.IQueryNoteResponse} message QueryNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryNoteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryNoteResponse} QueryNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryNoteResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryNoteResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.total = reader.int64();
                            break;
                        }
                    case 3: {
                            message.batch = $root.api.common.Batch.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            if (!(message.notes && message.notes.length))
                                message.notes = [];
                            message.notes.push($root.api.core.Note.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryNoteResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryNoteResponse} QueryNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryNoteResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryNoteResponse message.
             * @function verify
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryNoteResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                        return "total: integer|Long expected";
                if (message.batch != null && message.hasOwnProperty("batch")) {
                    let error = $root.api.common.Batch.verify(message.batch);
                    if (error)
                        return "batch." + error;
                }
                if (message.notes != null && message.hasOwnProperty("notes")) {
                    if (!Array.isArray(message.notes))
                        return "notes: array expected";
                    for (let i = 0; i < message.notes.length; ++i) {
                        let error = $root.api.core.Note.verify(message.notes[i]);
                        if (error)
                            return "notes." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QueryNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryNoteResponse} QueryNoteResponse
             */
            QueryNoteResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryNoteResponse)
                    return object;
                let message = new $root.api.core.QueryNoteResponse();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.total != null)
                    if ($util.Long)
                        (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                    else if (typeof object.total === "string")
                        message.total = parseInt(object.total, 10);
                    else if (typeof object.total === "number")
                        message.total = object.total;
                    else if (typeof object.total === "object")
                        message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                if (object.batch != null) {
                    if (typeof object.batch !== "object")
                        throw TypeError(".api.core.QueryNoteResponse.batch: object expected");
                    message.batch = $root.api.common.Batch.fromObject(object.batch);
                }
                if (object.notes) {
                    if (!Array.isArray(object.notes))
                        throw TypeError(".api.core.QueryNoteResponse.notes: array expected");
                    message.notes = [];
                    for (let i = 0; i < object.notes.length; ++i) {
                        if (typeof object.notes[i] !== "object")
                            throw TypeError(".api.core.QueryNoteResponse.notes: object expected");
                        message.notes[i] = $root.api.core.Note.fromObject(object.notes[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {api.core.QueryNoteResponse} message QueryNoteResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryNoteResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.notes = [];
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.total = options.longs === String ? "0" : 0;
                    object.batch = null;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.total != null && message.hasOwnProperty("total"))
                    if (typeof message.total === "number")
                        object.total = options.longs === String ? String(message.total) : message.total;
                    else
                        object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                if (message.batch != null && message.hasOwnProperty("batch"))
                    object.batch = $root.api.common.Batch.toObject(message.batch, options);
                if (message.notes && message.notes.length) {
                    object.notes = [];
                    for (let j = 0; j < message.notes.length; ++j)
                        object.notes[j] = $root.api.core.Note.toObject(message.notes[j], options);
                }
                return object;
            };

            /**
             * Converts this QueryNoteResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryNoteResponse
             * @function getTypeUrl
             * @memberof api.core.QueryNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryNoteResponse";
            };

            return QueryNoteResponse;
        })();

        core.CreateNoteRequest = (function() {

            /**
             * Properties of a CreateNoteRequest.
             * @memberof api.core
             * @interface ICreateNoteRequest
             * @property {Long|null} [chatId] CreateNoteRequest chatId
             * @property {api.common.IMessage|null} [src] CreateNoteRequest src
             * @property {api.common.IMessage|null} [noteMsg] CreateNoteRequest noteMsg
             */

            /**
             * Constructs a new CreateNoteRequest.
             * @memberof api.core
             * @classdesc Represents a CreateNoteRequest.
             * @implements ICreateNoteRequest
             * @constructor
             * @param {api.core.ICreateNoteRequest=} [properties] Properties to set
             */
            function CreateNoteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateNoteRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.CreateNoteRequest
             * @instance
             */
            CreateNoteRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CreateNoteRequest src.
             * @member {api.common.IMessage|null|undefined} src
             * @memberof api.core.CreateNoteRequest
             * @instance
             */
            CreateNoteRequest.prototype.src = null;

            /**
             * CreateNoteRequest noteMsg.
             * @member {api.common.IMessage|null|undefined} noteMsg
             * @memberof api.core.CreateNoteRequest
             * @instance
             */
            CreateNoteRequest.prototype.noteMsg = null;

            /**
             * Creates a new CreateNoteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {api.core.ICreateNoteRequest=} [properties] Properties to set
             * @returns {api.core.CreateNoteRequest} CreateNoteRequest instance
             */
            CreateNoteRequest.create = function create(properties) {
                return new CreateNoteRequest(properties);
            };

            /**
             * Encodes the specified CreateNoteRequest message. Does not implicitly {@link api.core.CreateNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {api.core.ICreateNoteRequest} message CreateNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateNoteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                    $root.api.common.Message.encode(message.src, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.noteMsg != null && Object.hasOwnProperty.call(message, "noteMsg"))
                    $root.api.common.Message.encode(message.noteMsg, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CreateNoteRequest message, length delimited. Does not implicitly {@link api.core.CreateNoteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {api.core.ICreateNoteRequest} message CreateNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateNoteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateNoteRequest} CreateNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateNoteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateNoteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.src = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.noteMsg = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateNoteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateNoteRequest} CreateNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateNoteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateNoteRequest message.
             * @function verify
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateNoteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.src != null && message.hasOwnProperty("src")) {
                    let error = $root.api.common.Message.verify(message.src);
                    if (error)
                        return "src." + error;
                }
                if (message.noteMsg != null && message.hasOwnProperty("noteMsg")) {
                    let error = $root.api.common.Message.verify(message.noteMsg);
                    if (error)
                        return "noteMsg." + error;
                }
                return null;
            };

            /**
             * Creates a CreateNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateNoteRequest} CreateNoteRequest
             */
            CreateNoteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateNoteRequest)
                    return object;
                let message = new $root.api.core.CreateNoteRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.src != null) {
                    if (typeof object.src !== "object")
                        throw TypeError(".api.core.CreateNoteRequest.src: object expected");
                    message.src = $root.api.common.Message.fromObject(object.src);
                }
                if (object.noteMsg != null) {
                    if (typeof object.noteMsg !== "object")
                        throw TypeError(".api.core.CreateNoteRequest.noteMsg: object expected");
                    message.noteMsg = $root.api.common.Message.fromObject(object.noteMsg);
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {api.core.CreateNoteRequest} message CreateNoteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateNoteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.src = null;
                    object.noteMsg = null;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.src != null && message.hasOwnProperty("src"))
                    object.src = $root.api.common.Message.toObject(message.src, options);
                if (message.noteMsg != null && message.hasOwnProperty("noteMsg"))
                    object.noteMsg = $root.api.common.Message.toObject(message.noteMsg, options);
                return object;
            };

            /**
             * Converts this CreateNoteRequest to JSON.
             * @function toJSON
             * @memberof api.core.CreateNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateNoteRequest
             * @function getTypeUrl
             * @memberof api.core.CreateNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateNoteRequest";
            };

            return CreateNoteRequest;
        })();

        core.CreateNoteResponse = (function() {

            /**
             * Properties of a CreateNoteResponse.
             * @memberof api.core
             * @interface ICreateNoteResponse
             * @property {api.core.INote|null} [note] CreateNoteResponse note
             */

            /**
             * Constructs a new CreateNoteResponse.
             * @memberof api.core
             * @classdesc Represents a CreateNoteResponse.
             * @implements ICreateNoteResponse
             * @constructor
             * @param {api.core.ICreateNoteResponse=} [properties] Properties to set
             */
            function CreateNoteResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateNoteResponse note.
             * @member {api.core.INote|null|undefined} note
             * @memberof api.core.CreateNoteResponse
             * @instance
             */
            CreateNoteResponse.prototype.note = null;

            /**
             * Creates a new CreateNoteResponse instance using the specified properties.
             * @function create
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {api.core.ICreateNoteResponse=} [properties] Properties to set
             * @returns {api.core.CreateNoteResponse} CreateNoteResponse instance
             */
            CreateNoteResponse.create = function create(properties) {
                return new CreateNoteResponse(properties);
            };

            /**
             * Encodes the specified CreateNoteResponse message. Does not implicitly {@link api.core.CreateNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {api.core.ICreateNoteResponse} message CreateNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateNoteResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                    $root.api.core.Note.encode(message.note, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CreateNoteResponse message, length delimited. Does not implicitly {@link api.core.CreateNoteResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {api.core.ICreateNoteResponse} message CreateNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateNoteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateNoteResponse} CreateNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateNoteResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateNoteResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.note = $root.api.core.Note.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateNoteResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateNoteResponse} CreateNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateNoteResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateNoteResponse message.
             * @function verify
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateNoteResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.note != null && message.hasOwnProperty("note")) {
                    let error = $root.api.core.Note.verify(message.note);
                    if (error)
                        return "note." + error;
                }
                return null;
            };

            /**
             * Creates a CreateNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateNoteResponse} CreateNoteResponse
             */
            CreateNoteResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateNoteResponse)
                    return object;
                let message = new $root.api.core.CreateNoteResponse();
                if (object.note != null) {
                    if (typeof object.note !== "object")
                        throw TypeError(".api.core.CreateNoteResponse.note: object expected");
                    message.note = $root.api.core.Note.fromObject(object.note);
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {api.core.CreateNoteResponse} message CreateNoteResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateNoteResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.note = null;
                if (message.note != null && message.hasOwnProperty("note"))
                    object.note = $root.api.core.Note.toObject(message.note, options);
                return object;
            };

            /**
             * Converts this CreateNoteResponse to JSON.
             * @function toJSON
             * @memberof api.core.CreateNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateNoteResponse
             * @function getTypeUrl
             * @memberof api.core.CreateNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateNoteResponse";
            };

            return CreateNoteResponse;
        })();

        core.UpdateNoteRequest = (function() {

            /**
             * Properties of an UpdateNoteRequest.
             * @memberof api.core
             * @interface IUpdateNoteRequest
             * @property {Long|null} [chatId] UpdateNoteRequest chatId
             * @property {Long|null} [chatNoteMsgId] UpdateNoteRequest chatNoteMsgId
             * @property {google.protobuf.ITimestamp|null} [createAt] UpdateNoteRequest createAt
             * @property {api.common.IMessage|null} [noteMsg] UpdateNoteRequest noteMsg
             */

            /**
             * Constructs a new UpdateNoteRequest.
             * @memberof api.core
             * @classdesc Represents an UpdateNoteRequest.
             * @implements IUpdateNoteRequest
             * @constructor
             * @param {api.core.IUpdateNoteRequest=} [properties] Properties to set
             */
            function UpdateNoteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateNoteRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.UpdateNoteRequest
             * @instance
             */
            UpdateNoteRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateNoteRequest chatNoteMsgId.
             * @member {Long} chatNoteMsgId
             * @memberof api.core.UpdateNoteRequest
             * @instance
             */
            UpdateNoteRequest.prototype.chatNoteMsgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateNoteRequest createAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createAt
             * @memberof api.core.UpdateNoteRequest
             * @instance
             */
            UpdateNoteRequest.prototype.createAt = null;

            /**
             * UpdateNoteRequest noteMsg.
             * @member {api.common.IMessage|null|undefined} noteMsg
             * @memberof api.core.UpdateNoteRequest
             * @instance
             */
            UpdateNoteRequest.prototype.noteMsg = null;

            /**
             * Creates a new UpdateNoteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {api.core.IUpdateNoteRequest=} [properties] Properties to set
             * @returns {api.core.UpdateNoteRequest} UpdateNoteRequest instance
             */
            UpdateNoteRequest.create = function create(properties) {
                return new UpdateNoteRequest(properties);
            };

            /**
             * Encodes the specified UpdateNoteRequest message. Does not implicitly {@link api.core.UpdateNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {api.core.IUpdateNoteRequest} message UpdateNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateNoteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.chatNoteMsgId != null && Object.hasOwnProperty.call(message, "chatNoteMsgId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.chatNoteMsgId);
                if (message.createAt != null && Object.hasOwnProperty.call(message, "createAt"))
                    $root.google.protobuf.Timestamp.encode(message.createAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.noteMsg != null && Object.hasOwnProperty.call(message, "noteMsg"))
                    $root.api.common.Message.encode(message.noteMsg, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateNoteRequest message, length delimited. Does not implicitly {@link api.core.UpdateNoteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {api.core.IUpdateNoteRequest} message UpdateNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateNoteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.UpdateNoteRequest} UpdateNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateNoteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.UpdateNoteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.chatNoteMsgId = reader.int64();
                            break;
                        }
                    case 3: {
                            message.createAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.noteMsg = $root.api.common.Message.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateNoteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.UpdateNoteRequest} UpdateNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateNoteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateNoteRequest message.
             * @function verify
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateNoteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.chatNoteMsgId != null && message.hasOwnProperty("chatNoteMsgId"))
                    if (!$util.isInteger(message.chatNoteMsgId) && !(message.chatNoteMsgId && $util.isInteger(message.chatNoteMsgId.low) && $util.isInteger(message.chatNoteMsgId.high)))
                        return "chatNoteMsgId: integer|Long expected";
                if (message.createAt != null && message.hasOwnProperty("createAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.createAt);
                    if (error)
                        return "createAt." + error;
                }
                if (message.noteMsg != null && message.hasOwnProperty("noteMsg")) {
                    let error = $root.api.common.Message.verify(message.noteMsg);
                    if (error)
                        return "noteMsg." + error;
                }
                return null;
            };

            /**
             * Creates an UpdateNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.UpdateNoteRequest} UpdateNoteRequest
             */
            UpdateNoteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.UpdateNoteRequest)
                    return object;
                let message = new $root.api.core.UpdateNoteRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.chatNoteMsgId != null)
                    if ($util.Long)
                        (message.chatNoteMsgId = $util.Long.fromValue(object.chatNoteMsgId)).unsigned = false;
                    else if (typeof object.chatNoteMsgId === "string")
                        message.chatNoteMsgId = parseInt(object.chatNoteMsgId, 10);
                    else if (typeof object.chatNoteMsgId === "number")
                        message.chatNoteMsgId = object.chatNoteMsgId;
                    else if (typeof object.chatNoteMsgId === "object")
                        message.chatNoteMsgId = new $util.LongBits(object.chatNoteMsgId.low >>> 0, object.chatNoteMsgId.high >>> 0).toNumber();
                if (object.createAt != null) {
                    if (typeof object.createAt !== "object")
                        throw TypeError(".api.core.UpdateNoteRequest.createAt: object expected");
                    message.createAt = $root.google.protobuf.Timestamp.fromObject(object.createAt);
                }
                if (object.noteMsg != null) {
                    if (typeof object.noteMsg !== "object")
                        throw TypeError(".api.core.UpdateNoteRequest.noteMsg: object expected");
                    message.noteMsg = $root.api.common.Message.fromObject(object.noteMsg);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {api.core.UpdateNoteRequest} message UpdateNoteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateNoteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatNoteMsgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatNoteMsgId = options.longs === String ? "0" : 0;
                    object.createAt = null;
                    object.noteMsg = null;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.chatNoteMsgId != null && message.hasOwnProperty("chatNoteMsgId"))
                    if (typeof message.chatNoteMsgId === "number")
                        object.chatNoteMsgId = options.longs === String ? String(message.chatNoteMsgId) : message.chatNoteMsgId;
                    else
                        object.chatNoteMsgId = options.longs === String ? $util.Long.prototype.toString.call(message.chatNoteMsgId) : options.longs === Number ? new $util.LongBits(message.chatNoteMsgId.low >>> 0, message.chatNoteMsgId.high >>> 0).toNumber() : message.chatNoteMsgId;
                if (message.createAt != null && message.hasOwnProperty("createAt"))
                    object.createAt = $root.google.protobuf.Timestamp.toObject(message.createAt, options);
                if (message.noteMsg != null && message.hasOwnProperty("noteMsg"))
                    object.noteMsg = $root.api.common.Message.toObject(message.noteMsg, options);
                return object;
            };

            /**
             * Converts this UpdateNoteRequest to JSON.
             * @function toJSON
             * @memberof api.core.UpdateNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateNoteRequest
             * @function getTypeUrl
             * @memberof api.core.UpdateNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.UpdateNoteRequest";
            };

            return UpdateNoteRequest;
        })();

        core.UpdateNoteResponse = (function() {

            /**
             * Properties of an UpdateNoteResponse.
             * @memberof api.core
             * @interface IUpdateNoteResponse
             * @property {api.core.INote|null} [note] UpdateNoteResponse note
             */

            /**
             * Constructs a new UpdateNoteResponse.
             * @memberof api.core
             * @classdesc Represents an UpdateNoteResponse.
             * @implements IUpdateNoteResponse
             * @constructor
             * @param {api.core.IUpdateNoteResponse=} [properties] Properties to set
             */
            function UpdateNoteResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateNoteResponse note.
             * @member {api.core.INote|null|undefined} note
             * @memberof api.core.UpdateNoteResponse
             * @instance
             */
            UpdateNoteResponse.prototype.note = null;

            /**
             * Creates a new UpdateNoteResponse instance using the specified properties.
             * @function create
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {api.core.IUpdateNoteResponse=} [properties] Properties to set
             * @returns {api.core.UpdateNoteResponse} UpdateNoteResponse instance
             */
            UpdateNoteResponse.create = function create(properties) {
                return new UpdateNoteResponse(properties);
            };

            /**
             * Encodes the specified UpdateNoteResponse message. Does not implicitly {@link api.core.UpdateNoteResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {api.core.IUpdateNoteResponse} message UpdateNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateNoteResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.note != null && Object.hasOwnProperty.call(message, "note"))
                    $root.api.core.Note.encode(message.note, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UpdateNoteResponse message, length delimited. Does not implicitly {@link api.core.UpdateNoteResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {api.core.IUpdateNoteResponse} message UpdateNoteResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateNoteResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateNoteResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.UpdateNoteResponse} UpdateNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateNoteResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.UpdateNoteResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.note = $root.api.core.Note.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateNoteResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.UpdateNoteResponse} UpdateNoteResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateNoteResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateNoteResponse message.
             * @function verify
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateNoteResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.note != null && message.hasOwnProperty("note")) {
                    let error = $root.api.core.Note.verify(message.note);
                    if (error)
                        return "note." + error;
                }
                return null;
            };

            /**
             * Creates an UpdateNoteResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.UpdateNoteResponse} UpdateNoteResponse
             */
            UpdateNoteResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.UpdateNoteResponse)
                    return object;
                let message = new $root.api.core.UpdateNoteResponse();
                if (object.note != null) {
                    if (typeof object.note !== "object")
                        throw TypeError(".api.core.UpdateNoteResponse.note: object expected");
                    message.note = $root.api.core.Note.fromObject(object.note);
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateNoteResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {api.core.UpdateNoteResponse} message UpdateNoteResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateNoteResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.note = null;
                if (message.note != null && message.hasOwnProperty("note"))
                    object.note = $root.api.core.Note.toObject(message.note, options);
                return object;
            };

            /**
             * Converts this UpdateNoteResponse to JSON.
             * @function toJSON
             * @memberof api.core.UpdateNoteResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateNoteResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateNoteResponse
             * @function getTypeUrl
             * @memberof api.core.UpdateNoteResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateNoteResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.UpdateNoteResponse";
            };

            return UpdateNoteResponse;
        })();

        core.DeleteNoteRequest = (function() {

            /**
             * Properties of a DeleteNoteRequest.
             * @memberof api.core
             * @interface IDeleteNoteRequest
             * @property {Long|null} [chatId] DeleteNoteRequest chatId
             * @property {Long|null} [chatNoteMsgId] DeleteNoteRequest chatNoteMsgId
             * @property {google.protobuf.ITimestamp|null} [createAt] DeleteNoteRequest createAt
             */

            /**
             * Constructs a new DeleteNoteRequest.
             * @memberof api.core
             * @classdesc Represents a DeleteNoteRequest.
             * @implements IDeleteNoteRequest
             * @constructor
             * @param {api.core.IDeleteNoteRequest=} [properties] Properties to set
             */
            function DeleteNoteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteNoteRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.DeleteNoteRequest
             * @instance
             */
            DeleteNoteRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DeleteNoteRequest chatNoteMsgId.
             * @member {Long} chatNoteMsgId
             * @memberof api.core.DeleteNoteRequest
             * @instance
             */
            DeleteNoteRequest.prototype.chatNoteMsgId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DeleteNoteRequest createAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createAt
             * @memberof api.core.DeleteNoteRequest
             * @instance
             */
            DeleteNoteRequest.prototype.createAt = null;

            /**
             * Creates a new DeleteNoteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {api.core.IDeleteNoteRequest=} [properties] Properties to set
             * @returns {api.core.DeleteNoteRequest} DeleteNoteRequest instance
             */
            DeleteNoteRequest.create = function create(properties) {
                return new DeleteNoteRequest(properties);
            };

            /**
             * Encodes the specified DeleteNoteRequest message. Does not implicitly {@link api.core.DeleteNoteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {api.core.IDeleteNoteRequest} message DeleteNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteNoteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.chatNoteMsgId != null && Object.hasOwnProperty.call(message, "chatNoteMsgId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.chatNoteMsgId);
                if (message.createAt != null && Object.hasOwnProperty.call(message, "createAt"))
                    $root.google.protobuf.Timestamp.encode(message.createAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DeleteNoteRequest message, length delimited. Does not implicitly {@link api.core.DeleteNoteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {api.core.IDeleteNoteRequest} message DeleteNoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteNoteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteNoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DeleteNoteRequest} DeleteNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteNoteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DeleteNoteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.chatNoteMsgId = reader.int64();
                            break;
                        }
                    case 3: {
                            message.createAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteNoteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DeleteNoteRequest} DeleteNoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteNoteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteNoteRequest message.
             * @function verify
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteNoteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.chatNoteMsgId != null && message.hasOwnProperty("chatNoteMsgId"))
                    if (!$util.isInteger(message.chatNoteMsgId) && !(message.chatNoteMsgId && $util.isInteger(message.chatNoteMsgId.low) && $util.isInteger(message.chatNoteMsgId.high)))
                        return "chatNoteMsgId: integer|Long expected";
                if (message.createAt != null && message.hasOwnProperty("createAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.createAt);
                    if (error)
                        return "createAt." + error;
                }
                return null;
            };

            /**
             * Creates a DeleteNoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DeleteNoteRequest} DeleteNoteRequest
             */
            DeleteNoteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DeleteNoteRequest)
                    return object;
                let message = new $root.api.core.DeleteNoteRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.chatNoteMsgId != null)
                    if ($util.Long)
                        (message.chatNoteMsgId = $util.Long.fromValue(object.chatNoteMsgId)).unsigned = false;
                    else if (typeof object.chatNoteMsgId === "string")
                        message.chatNoteMsgId = parseInt(object.chatNoteMsgId, 10);
                    else if (typeof object.chatNoteMsgId === "number")
                        message.chatNoteMsgId = object.chatNoteMsgId;
                    else if (typeof object.chatNoteMsgId === "object")
                        message.chatNoteMsgId = new $util.LongBits(object.chatNoteMsgId.low >>> 0, object.chatNoteMsgId.high >>> 0).toNumber();
                if (object.createAt != null) {
                    if (typeof object.createAt !== "object")
                        throw TypeError(".api.core.DeleteNoteRequest.createAt: object expected");
                    message.createAt = $root.google.protobuf.Timestamp.fromObject(object.createAt);
                }
                return message;
            };

            /**
             * Creates a plain object from a DeleteNoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {api.core.DeleteNoteRequest} message DeleteNoteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteNoteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatNoteMsgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatNoteMsgId = options.longs === String ? "0" : 0;
                    object.createAt = null;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.chatNoteMsgId != null && message.hasOwnProperty("chatNoteMsgId"))
                    if (typeof message.chatNoteMsgId === "number")
                        object.chatNoteMsgId = options.longs === String ? String(message.chatNoteMsgId) : message.chatNoteMsgId;
                    else
                        object.chatNoteMsgId = options.longs === String ? $util.Long.prototype.toString.call(message.chatNoteMsgId) : options.longs === Number ? new $util.LongBits(message.chatNoteMsgId.low >>> 0, message.chatNoteMsgId.high >>> 0).toNumber() : message.chatNoteMsgId;
                if (message.createAt != null && message.hasOwnProperty("createAt"))
                    object.createAt = $root.google.protobuf.Timestamp.toObject(message.createAt, options);
                return object;
            };

            /**
             * Converts this DeleteNoteRequest to JSON.
             * @function toJSON
             * @memberof api.core.DeleteNoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteNoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteNoteRequest
             * @function getTypeUrl
             * @memberof api.core.DeleteNoteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteNoteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DeleteNoteRequest";
            };

            return DeleteNoteRequest;
        })();

        core.NoteSrv = (function() {

            /**
             * Constructs a new NoteSrv service.
             * @memberof api.core
             * @classdesc Represents a NoteSrv
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function NoteSrv(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (NoteSrv.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = NoteSrv;

            /**
             * Creates new NoteSrv service using the specified rpc implementation.
             * @function create
             * @memberof api.core.NoteSrv
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {NoteSrv} RPC service. Useful where requests and/or responses are streamed.
             */
            NoteSrv.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.NoteSrv#queryNote}.
             * @memberof api.core.NoteSrv
             * @typedef QueryNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryNoteResponse} [response] QueryNoteResponse
             */

            /**
             * Calls QueryNote.
             * @function queryNote
             * @memberof api.core.NoteSrv
             * @instance
             * @param {api.core.IQueryNoteRequest} request QueryNoteRequest message or plain object
             * @param {api.core.NoteSrv.QueryNoteCallback} callback Node-style callback called with the error, if any, and QueryNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(NoteSrv.prototype.queryNote = function queryNote(request, callback) {
                return this.rpcCall(queryNote, $root.api.core.QueryNoteRequest, $root.api.core.QueryNoteResponse, request, callback);
            }, "name", { value: "QueryNote" });

            /**
             * Calls QueryNote.
             * @function queryNote
             * @memberof api.core.NoteSrv
             * @instance
             * @param {api.core.IQueryNoteRequest} request QueryNoteRequest message or plain object
             * @returns {Promise<api.core.QueryNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.NoteSrv#createNote}.
             * @memberof api.core.NoteSrv
             * @typedef CreateNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.CreateNoteResponse} [response] CreateNoteResponse
             */

            /**
             * Calls CreateNote.
             * @function createNote
             * @memberof api.core.NoteSrv
             * @instance
             * @param {api.core.ICreateNoteRequest} request CreateNoteRequest message or plain object
             * @param {api.core.NoteSrv.CreateNoteCallback} callback Node-style callback called with the error, if any, and CreateNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(NoteSrv.prototype.createNote = function createNote(request, callback) {
                return this.rpcCall(createNote, $root.api.core.CreateNoteRequest, $root.api.core.CreateNoteResponse, request, callback);
            }, "name", { value: "CreateNote" });

            /**
             * Calls CreateNote.
             * @function createNote
             * @memberof api.core.NoteSrv
             * @instance
             * @param {api.core.ICreateNoteRequest} request CreateNoteRequest message or plain object
             * @returns {Promise<api.core.CreateNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.NoteSrv#updateNote}.
             * @memberof api.core.NoteSrv
             * @typedef UpdateNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.UpdateNoteResponse} [response] UpdateNoteResponse
             */

            /**
             * Calls UpdateNote.
             * @function updateNote
             * @memberof api.core.NoteSrv
             * @instance
             * @param {api.core.IUpdateNoteRequest} request UpdateNoteRequest message or plain object
             * @param {api.core.NoteSrv.UpdateNoteCallback} callback Node-style callback called with the error, if any, and UpdateNoteResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(NoteSrv.prototype.updateNote = function updateNote(request, callback) {
                return this.rpcCall(updateNote, $root.api.core.UpdateNoteRequest, $root.api.core.UpdateNoteResponse, request, callback);
            }, "name", { value: "UpdateNote" });

            /**
             * Calls UpdateNote.
             * @function updateNote
             * @memberof api.core.NoteSrv
             * @instance
             * @param {api.core.IUpdateNoteRequest} request UpdateNoteRequest message or plain object
             * @returns {Promise<api.core.UpdateNoteResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.NoteSrv#deleteNote}.
             * @memberof api.core.NoteSrv
             * @typedef DeleteNoteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteNote.
             * @function deleteNote
             * @memberof api.core.NoteSrv
             * @instance
             * @param {api.core.IDeleteNoteRequest} request DeleteNoteRequest message or plain object
             * @param {api.core.NoteSrv.DeleteNoteCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(NoteSrv.prototype.deleteNote = function deleteNote(request, callback) {
                return this.rpcCall(deleteNote, $root.api.core.DeleteNoteRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteNote" });

            /**
             * Calls DeleteNote.
             * @function deleteNote
             * @memberof api.core.NoteSrv
             * @instance
             * @param {api.core.IDeleteNoteRequest} request DeleteNoteRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return NoteSrv;
        })();

        core.QuickReplyGroupItem = (function() {

            /**
             * Properties of a QuickReplyGroupItem.
             * @memberof api.core
             * @interface IQuickReplyGroupItem
             * @property {string|null} [name] QuickReplyGroupItem name
             * @property {number|null} [priority] QuickReplyGroupItem priority
             */

            /**
             * Constructs a new QuickReplyGroupItem.
             * @memberof api.core
             * @classdesc Represents a QuickReplyGroupItem.
             * @implements IQuickReplyGroupItem
             * @constructor
             * @param {api.core.IQuickReplyGroupItem=} [properties] Properties to set
             */
            function QuickReplyGroupItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyGroupItem name.
             * @member {string} name
             * @memberof api.core.QuickReplyGroupItem
             * @instance
             */
            QuickReplyGroupItem.prototype.name = "";

            /**
             * QuickReplyGroupItem priority.
             * @member {number} priority
             * @memberof api.core.QuickReplyGroupItem
             * @instance
             */
            QuickReplyGroupItem.prototype.priority = 0;

            /**
             * Creates a new QuickReplyGroupItem instance using the specified properties.
             * @function create
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {api.core.IQuickReplyGroupItem=} [properties] Properties to set
             * @returns {api.core.QuickReplyGroupItem} QuickReplyGroupItem instance
             */
            QuickReplyGroupItem.create = function create(properties) {
                return new QuickReplyGroupItem(properties);
            };

            /**
             * Encodes the specified QuickReplyGroupItem message. Does not implicitly {@link api.core.QuickReplyGroupItem.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {api.core.IQuickReplyGroupItem} message QuickReplyGroupItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified QuickReplyGroupItem message, length delimited. Does not implicitly {@link api.core.QuickReplyGroupItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {api.core.IQuickReplyGroupItem} message QuickReplyGroupItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyGroupItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuickReplyGroupItem} QuickReplyGroupItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuickReplyGroupItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyGroupItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuickReplyGroupItem} QuickReplyGroupItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyGroupItem message.
             * @function verify
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyGroupItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a QuickReplyGroupItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuickReplyGroupItem} QuickReplyGroupItem
             */
            QuickReplyGroupItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuickReplyGroupItem)
                    return object;
                let message = new $root.api.core.QuickReplyGroupItem();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyGroupItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {api.core.QuickReplyGroupItem} message QuickReplyGroupItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyGroupItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.priority = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this QuickReplyGroupItem to JSON.
             * @function toJSON
             * @memberof api.core.QuickReplyGroupItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyGroupItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyGroupItem
             * @function getTypeUrl
             * @memberof api.core.QuickReplyGroupItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyGroupItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuickReplyGroupItem";
            };

            return QuickReplyGroupItem;
        })();

        core.QuickReplyGroupQueryResponse = (function() {

            /**
             * Properties of a QuickReplyGroupQueryResponse.
             * @memberof api.core
             * @interface IQuickReplyGroupQueryResponse
             * @property {Array.<api.core.IQuickReplyGroupItem>|null} [items] QuickReplyGroupQueryResponse items
             */

            /**
             * Constructs a new QuickReplyGroupQueryResponse.
             * @memberof api.core
             * @classdesc Represents a QuickReplyGroupQueryResponse.
             * @implements IQuickReplyGroupQueryResponse
             * @constructor
             * @param {api.core.IQuickReplyGroupQueryResponse=} [properties] Properties to set
             */
            function QuickReplyGroupQueryResponse(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyGroupQueryResponse items.
             * @member {Array.<api.core.IQuickReplyGroupItem>} items
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @instance
             */
            QuickReplyGroupQueryResponse.prototype.items = $util.emptyArray;

            /**
             * Creates a new QuickReplyGroupQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {api.core.IQuickReplyGroupQueryResponse=} [properties] Properties to set
             * @returns {api.core.QuickReplyGroupQueryResponse} QuickReplyGroupQueryResponse instance
             */
            QuickReplyGroupQueryResponse.create = function create(properties) {
                return new QuickReplyGroupQueryResponse(properties);
            };

            /**
             * Encodes the specified QuickReplyGroupQueryResponse message. Does not implicitly {@link api.core.QuickReplyGroupQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {api.core.IQuickReplyGroupQueryResponse} message QuickReplyGroupQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.core.QuickReplyGroupItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuickReplyGroupQueryResponse message, length delimited. Does not implicitly {@link api.core.QuickReplyGroupQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {api.core.IQuickReplyGroupQueryResponse} message QuickReplyGroupQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyGroupQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuickReplyGroupQueryResponse} QuickReplyGroupQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuickReplyGroupQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.core.QuickReplyGroupItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyGroupQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuickReplyGroupQueryResponse} QuickReplyGroupQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyGroupQueryResponse message.
             * @function verify
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyGroupQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.core.QuickReplyGroupItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QuickReplyGroupQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuickReplyGroupQueryResponse} QuickReplyGroupQueryResponse
             */
            QuickReplyGroupQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuickReplyGroupQueryResponse)
                    return object;
                let message = new $root.api.core.QuickReplyGroupQueryResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.core.QuickReplyGroupQueryResponse.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.core.QuickReplyGroupQueryResponse.items: object expected");
                        message.items[i] = $root.api.core.QuickReplyGroupItem.fromObject(object.items[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyGroupQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {api.core.QuickReplyGroupQueryResponse} message QuickReplyGroupQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyGroupQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.core.QuickReplyGroupItem.toObject(message.items[j], options);
                }
                return object;
            };

            /**
             * Converts this QuickReplyGroupQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyGroupQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyGroupQueryResponse
             * @function getTypeUrl
             * @memberof api.core.QuickReplyGroupQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyGroupQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuickReplyGroupQueryResponse";
            };

            return QuickReplyGroupQueryResponse;
        })();

        core.QuickReplyGroupCreateRequest = (function() {

            /**
             * Properties of a QuickReplyGroupCreateRequest.
             * @memberof api.core
             * @interface IQuickReplyGroupCreateRequest
             * @property {string|null} [name] QuickReplyGroupCreateRequest name
             * @property {number|null} [priority] QuickReplyGroupCreateRequest priority
             */

            /**
             * Constructs a new QuickReplyGroupCreateRequest.
             * @memberof api.core
             * @classdesc Represents a QuickReplyGroupCreateRequest.
             * @implements IQuickReplyGroupCreateRequest
             * @constructor
             * @param {api.core.IQuickReplyGroupCreateRequest=} [properties] Properties to set
             */
            function QuickReplyGroupCreateRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyGroupCreateRequest name.
             * @member {string} name
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @instance
             */
            QuickReplyGroupCreateRequest.prototype.name = "";

            /**
             * QuickReplyGroupCreateRequest priority.
             * @member {number} priority
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @instance
             */
            QuickReplyGroupCreateRequest.prototype.priority = 0;

            /**
             * Creates a new QuickReplyGroupCreateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {api.core.IQuickReplyGroupCreateRequest=} [properties] Properties to set
             * @returns {api.core.QuickReplyGroupCreateRequest} QuickReplyGroupCreateRequest instance
             */
            QuickReplyGroupCreateRequest.create = function create(properties) {
                return new QuickReplyGroupCreateRequest(properties);
            };

            /**
             * Encodes the specified QuickReplyGroupCreateRequest message. Does not implicitly {@link api.core.QuickReplyGroupCreateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {api.core.IQuickReplyGroupCreateRequest} message QuickReplyGroupCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupCreateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified QuickReplyGroupCreateRequest message, length delimited. Does not implicitly {@link api.core.QuickReplyGroupCreateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {api.core.IQuickReplyGroupCreateRequest} message QuickReplyGroupCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupCreateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyGroupCreateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuickReplyGroupCreateRequest} QuickReplyGroupCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupCreateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuickReplyGroupCreateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyGroupCreateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuickReplyGroupCreateRequest} QuickReplyGroupCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupCreateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyGroupCreateRequest message.
             * @function verify
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyGroupCreateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a QuickReplyGroupCreateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuickReplyGroupCreateRequest} QuickReplyGroupCreateRequest
             */
            QuickReplyGroupCreateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuickReplyGroupCreateRequest)
                    return object;
                let message = new $root.api.core.QuickReplyGroupCreateRequest();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyGroupCreateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {api.core.QuickReplyGroupCreateRequest} message QuickReplyGroupCreateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyGroupCreateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.priority = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this QuickReplyGroupCreateRequest to JSON.
             * @function toJSON
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyGroupCreateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyGroupCreateRequest
             * @function getTypeUrl
             * @memberof api.core.QuickReplyGroupCreateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyGroupCreateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuickReplyGroupCreateRequest";
            };

            return QuickReplyGroupCreateRequest;
        })();

        core.QuickReplyGroupUpdateRequest = (function() {

            /**
             * Properties of a QuickReplyGroupUpdateRequest.
             * @memberof api.core
             * @interface IQuickReplyGroupUpdateRequest
             * @property {string|null} [oldName] QuickReplyGroupUpdateRequest oldName
             * @property {string|null} [newName] QuickReplyGroupUpdateRequest newName
             * @property {number|null} [priority] QuickReplyGroupUpdateRequest priority
             */

            /**
             * Constructs a new QuickReplyGroupUpdateRequest.
             * @memberof api.core
             * @classdesc Represents a QuickReplyGroupUpdateRequest.
             * @implements IQuickReplyGroupUpdateRequest
             * @constructor
             * @param {api.core.IQuickReplyGroupUpdateRequest=} [properties] Properties to set
             */
            function QuickReplyGroupUpdateRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyGroupUpdateRequest oldName.
             * @member {string} oldName
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @instance
             */
            QuickReplyGroupUpdateRequest.prototype.oldName = "";

            /**
             * QuickReplyGroupUpdateRequest newName.
             * @member {string} newName
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @instance
             */
            QuickReplyGroupUpdateRequest.prototype.newName = "";

            /**
             * QuickReplyGroupUpdateRequest priority.
             * @member {number} priority
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @instance
             */
            QuickReplyGroupUpdateRequest.prototype.priority = 0;

            /**
             * Creates a new QuickReplyGroupUpdateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {api.core.IQuickReplyGroupUpdateRequest=} [properties] Properties to set
             * @returns {api.core.QuickReplyGroupUpdateRequest} QuickReplyGroupUpdateRequest instance
             */
            QuickReplyGroupUpdateRequest.create = function create(properties) {
                return new QuickReplyGroupUpdateRequest(properties);
            };

            /**
             * Encodes the specified QuickReplyGroupUpdateRequest message. Does not implicitly {@link api.core.QuickReplyGroupUpdateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {api.core.IQuickReplyGroupUpdateRequest} message QuickReplyGroupUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupUpdateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.oldName != null && Object.hasOwnProperty.call(message, "oldName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.oldName);
                if (message.newName != null && Object.hasOwnProperty.call(message, "newName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.newName);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified QuickReplyGroupUpdateRequest message, length delimited. Does not implicitly {@link api.core.QuickReplyGroupUpdateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {api.core.IQuickReplyGroupUpdateRequest} message QuickReplyGroupUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyGroupUpdateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuickReplyGroupUpdateRequest} QuickReplyGroupUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupUpdateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuickReplyGroupUpdateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.oldName = reader.string();
                            break;
                        }
                    case 2: {
                            message.newName = reader.string();
                            break;
                        }
                    case 3: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyGroupUpdateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuickReplyGroupUpdateRequest} QuickReplyGroupUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyGroupUpdateRequest message.
             * @function verify
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyGroupUpdateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.oldName != null && message.hasOwnProperty("oldName"))
                    if (!$util.isString(message.oldName))
                        return "oldName: string expected";
                if (message.newName != null && message.hasOwnProperty("newName"))
                    if (!$util.isString(message.newName))
                        return "newName: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a QuickReplyGroupUpdateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuickReplyGroupUpdateRequest} QuickReplyGroupUpdateRequest
             */
            QuickReplyGroupUpdateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuickReplyGroupUpdateRequest)
                    return object;
                let message = new $root.api.core.QuickReplyGroupUpdateRequest();
                if (object.oldName != null)
                    message.oldName = String(object.oldName);
                if (object.newName != null)
                    message.newName = String(object.newName);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyGroupUpdateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {api.core.QuickReplyGroupUpdateRequest} message QuickReplyGroupUpdateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyGroupUpdateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.oldName = "";
                    object.newName = "";
                    object.priority = 0;
                }
                if (message.oldName != null && message.hasOwnProperty("oldName"))
                    object.oldName = message.oldName;
                if (message.newName != null && message.hasOwnProperty("newName"))
                    object.newName = message.newName;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this QuickReplyGroupUpdateRequest to JSON.
             * @function toJSON
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyGroupUpdateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyGroupUpdateRequest
             * @function getTypeUrl
             * @memberof api.core.QuickReplyGroupUpdateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyGroupUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuickReplyGroupUpdateRequest";
            };

            return QuickReplyGroupUpdateRequest;
        })();

        core.QuickReplyGroupDeleteRequest = (function() {

            /**
             * Properties of a QuickReplyGroupDeleteRequest.
             * @memberof api.core
             * @interface IQuickReplyGroupDeleteRequest
             * @property {string|null} [name] QuickReplyGroupDeleteRequest name
             */

            /**
             * Constructs a new QuickReplyGroupDeleteRequest.
             * @memberof api.core
             * @classdesc Represents a QuickReplyGroupDeleteRequest.
             * @implements IQuickReplyGroupDeleteRequest
             * @constructor
             * @param {api.core.IQuickReplyGroupDeleteRequest=} [properties] Properties to set
             */
            function QuickReplyGroupDeleteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyGroupDeleteRequest name.
             * @member {string} name
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @instance
             */
            QuickReplyGroupDeleteRequest.prototype.name = "";

            /**
             * Creates a new QuickReplyGroupDeleteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {api.core.IQuickReplyGroupDeleteRequest=} [properties] Properties to set
             * @returns {api.core.QuickReplyGroupDeleteRequest} QuickReplyGroupDeleteRequest instance
             */
            QuickReplyGroupDeleteRequest.create = function create(properties) {
                return new QuickReplyGroupDeleteRequest(properties);
            };

            /**
             * Encodes the specified QuickReplyGroupDeleteRequest message. Does not implicitly {@link api.core.QuickReplyGroupDeleteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {api.core.IQuickReplyGroupDeleteRequest} message QuickReplyGroupDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupDeleteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified QuickReplyGroupDeleteRequest message, length delimited. Does not implicitly {@link api.core.QuickReplyGroupDeleteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {api.core.IQuickReplyGroupDeleteRequest} message QuickReplyGroupDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyGroupDeleteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyGroupDeleteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuickReplyGroupDeleteRequest} QuickReplyGroupDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupDeleteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuickReplyGroupDeleteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyGroupDeleteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuickReplyGroupDeleteRequest} QuickReplyGroupDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyGroupDeleteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyGroupDeleteRequest message.
             * @function verify
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyGroupDeleteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a QuickReplyGroupDeleteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuickReplyGroupDeleteRequest} QuickReplyGroupDeleteRequest
             */
            QuickReplyGroupDeleteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuickReplyGroupDeleteRequest)
                    return object;
                let message = new $root.api.core.QuickReplyGroupDeleteRequest();
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyGroupDeleteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {api.core.QuickReplyGroupDeleteRequest} message QuickReplyGroupDeleteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyGroupDeleteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.name = "";
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this QuickReplyGroupDeleteRequest to JSON.
             * @function toJSON
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyGroupDeleteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyGroupDeleteRequest
             * @function getTypeUrl
             * @memberof api.core.QuickReplyGroupDeleteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyGroupDeleteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuickReplyGroupDeleteRequest";
            };

            return QuickReplyGroupDeleteRequest;
        })();

        core.QuickReplyItem = (function() {

            /**
             * Properties of a QuickReplyItem.
             * @memberof api.core
             * @interface IQuickReplyItem
             * @property {Long|null} [id] QuickReplyItem id
             * @property {string|null} [name] QuickReplyItem name
             * @property {string|null} [groupName] QuickReplyItem groupName
             * @property {number|null} [priority] QuickReplyItem priority
             * @property {string|null} [content] QuickReplyItem content
             * @property {Array.<api.common.IMessage>|null} [items] QuickReplyItem items
             * @property {boolean|null} [isCustom] QuickReplyItem isCustom
             */

            /**
             * Constructs a new QuickReplyItem.
             * @memberof api.core
             * @classdesc Represents a QuickReplyItem.
             * @implements IQuickReplyItem
             * @constructor
             * @param {api.core.IQuickReplyItem=} [properties] Properties to set
             */
            function QuickReplyItem(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyItem id.
             * @member {Long} id
             * @memberof api.core.QuickReplyItem
             * @instance
             */
            QuickReplyItem.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * QuickReplyItem name.
             * @member {string} name
             * @memberof api.core.QuickReplyItem
             * @instance
             */
            QuickReplyItem.prototype.name = "";

            /**
             * QuickReplyItem groupName.
             * @member {string} groupName
             * @memberof api.core.QuickReplyItem
             * @instance
             */
            QuickReplyItem.prototype.groupName = "";

            /**
             * QuickReplyItem priority.
             * @member {number} priority
             * @memberof api.core.QuickReplyItem
             * @instance
             */
            QuickReplyItem.prototype.priority = 0;

            /**
             * QuickReplyItem content.
             * @member {string} content
             * @memberof api.core.QuickReplyItem
             * @instance
             */
            QuickReplyItem.prototype.content = "";

            /**
             * QuickReplyItem items.
             * @member {Array.<api.common.IMessage>} items
             * @memberof api.core.QuickReplyItem
             * @instance
             */
            QuickReplyItem.prototype.items = $util.emptyArray;

            /**
             * QuickReplyItem isCustom.
             * @member {boolean} isCustom
             * @memberof api.core.QuickReplyItem
             * @instance
             */
            QuickReplyItem.prototype.isCustom = false;

            /**
             * Creates a new QuickReplyItem instance using the specified properties.
             * @function create
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {api.core.IQuickReplyItem=} [properties] Properties to set
             * @returns {api.core.QuickReplyItem} QuickReplyItem instance
             */
            QuickReplyItem.create = function create(properties) {
                return new QuickReplyItem(properties);
            };

            /**
             * Encodes the specified QuickReplyItem message. Does not implicitly {@link api.core.QuickReplyItem.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {api.core.IQuickReplyItem} message QuickReplyItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.groupName);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.priority);
                if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.content);
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.common.Message.encode(message.items[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.isCustom != null && Object.hasOwnProperty.call(message, "isCustom"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isCustom);
                return writer;
            };

            /**
             * Encodes the specified QuickReplyItem message, length delimited. Does not implicitly {@link api.core.QuickReplyItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {api.core.IQuickReplyItem} message QuickReplyItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuickReplyItem} QuickReplyItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuickReplyItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 4: {
                            message.priority = reader.int32();
                            break;
                        }
                    case 5: {
                            message.content = reader.string();
                            break;
                        }
                    case 6: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.common.Message.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            message.isCustom = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuickReplyItem} QuickReplyItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyItem message.
             * @function verify
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                if (message.content != null && message.hasOwnProperty("content"))
                    if (!$util.isString(message.content))
                        return "content: string expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.common.Message.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.isCustom != null && message.hasOwnProperty("isCustom"))
                    if (typeof message.isCustom !== "boolean")
                        return "isCustom: boolean expected";
                return null;
            };

            /**
             * Creates a QuickReplyItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuickReplyItem} QuickReplyItem
             */
            QuickReplyItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuickReplyItem)
                    return object;
                let message = new $root.api.core.QuickReplyItem();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                if (object.content != null)
                    message.content = String(object.content);
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.core.QuickReplyItem.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.core.QuickReplyItem.items: object expected");
                        message.items[i] = $root.api.common.Message.fromObject(object.items[i]);
                    }
                }
                if (object.isCustom != null)
                    message.isCustom = Boolean(object.isCustom);
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {api.core.QuickReplyItem} message QuickReplyItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.groupName = "";
                    object.priority = 0;
                    object.content = "";
                    object.isCustom = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                if (message.content != null && message.hasOwnProperty("content"))
                    object.content = message.content;
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.common.Message.toObject(message.items[j], options);
                }
                if (message.isCustom != null && message.hasOwnProperty("isCustom"))
                    object.isCustom = message.isCustom;
                return object;
            };

            /**
             * Converts this QuickReplyItem to JSON.
             * @function toJSON
             * @memberof api.core.QuickReplyItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyItem
             * @function getTypeUrl
             * @memberof api.core.QuickReplyItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuickReplyItem";
            };

            return QuickReplyItem;
        })();

        core.QuickReplyQueryResponse = (function() {

            /**
             * Properties of a QuickReplyQueryResponse.
             * @memberof api.core
             * @interface IQuickReplyQueryResponse
             * @property {Array.<api.core.IQuickReplyItem>|null} [items] QuickReplyQueryResponse items
             */

            /**
             * Constructs a new QuickReplyQueryResponse.
             * @memberof api.core
             * @classdesc Represents a QuickReplyQueryResponse.
             * @implements IQuickReplyQueryResponse
             * @constructor
             * @param {api.core.IQuickReplyQueryResponse=} [properties] Properties to set
             */
            function QuickReplyQueryResponse(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyQueryResponse items.
             * @member {Array.<api.core.IQuickReplyItem>} items
             * @memberof api.core.QuickReplyQueryResponse
             * @instance
             */
            QuickReplyQueryResponse.prototype.items = $util.emptyArray;

            /**
             * Creates a new QuickReplyQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {api.core.IQuickReplyQueryResponse=} [properties] Properties to set
             * @returns {api.core.QuickReplyQueryResponse} QuickReplyQueryResponse instance
             */
            QuickReplyQueryResponse.create = function create(properties) {
                return new QuickReplyQueryResponse(properties);
            };

            /**
             * Encodes the specified QuickReplyQueryResponse message. Does not implicitly {@link api.core.QuickReplyQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {api.core.IQuickReplyQueryResponse} message QuickReplyQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.core.QuickReplyItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuickReplyQueryResponse message, length delimited. Does not implicitly {@link api.core.QuickReplyQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {api.core.IQuickReplyQueryResponse} message QuickReplyQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuickReplyQueryResponse} QuickReplyQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuickReplyQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.core.QuickReplyItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuickReplyQueryResponse} QuickReplyQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyQueryResponse message.
             * @function verify
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.core.QuickReplyItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QuickReplyQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuickReplyQueryResponse} QuickReplyQueryResponse
             */
            QuickReplyQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuickReplyQueryResponse)
                    return object;
                let message = new $root.api.core.QuickReplyQueryResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.core.QuickReplyQueryResponse.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.core.QuickReplyQueryResponse.items: object expected");
                        message.items[i] = $root.api.core.QuickReplyItem.fromObject(object.items[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {api.core.QuickReplyQueryResponse} message QuickReplyQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.core.QuickReplyItem.toObject(message.items[j], options);
                }
                return object;
            };

            /**
             * Converts this QuickReplyQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.QuickReplyQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyQueryResponse
             * @function getTypeUrl
             * @memberof api.core.QuickReplyQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuickReplyQueryResponse";
            };

            return QuickReplyQueryResponse;
        })();

        core.CreateQuickReplyRequest = (function() {

            /**
             * Properties of a CreateQuickReplyRequest.
             * @memberof api.core
             * @interface ICreateQuickReplyRequest
             * @property {api.core.IQuickReplyItem|null} [reply] CreateQuickReplyRequest reply
             */

            /**
             * Constructs a new CreateQuickReplyRequest.
             * @memberof api.core
             * @classdesc Represents a CreateQuickReplyRequest.
             * @implements ICreateQuickReplyRequest
             * @constructor
             * @param {api.core.ICreateQuickReplyRequest=} [properties] Properties to set
             */
            function CreateQuickReplyRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateQuickReplyRequest reply.
             * @member {api.core.IQuickReplyItem|null|undefined} reply
             * @memberof api.core.CreateQuickReplyRequest
             * @instance
             */
            CreateQuickReplyRequest.prototype.reply = null;

            /**
             * Creates a new CreateQuickReplyRequest instance using the specified properties.
             * @function create
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {api.core.ICreateQuickReplyRequest=} [properties] Properties to set
             * @returns {api.core.CreateQuickReplyRequest} CreateQuickReplyRequest instance
             */
            CreateQuickReplyRequest.create = function create(properties) {
                return new CreateQuickReplyRequest(properties);
            };

            /**
             * Encodes the specified CreateQuickReplyRequest message. Does not implicitly {@link api.core.CreateQuickReplyRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {api.core.ICreateQuickReplyRequest} message CreateQuickReplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateQuickReplyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.reply != null && Object.hasOwnProperty.call(message, "reply"))
                    $root.api.core.QuickReplyItem.encode(message.reply, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CreateQuickReplyRequest message, length delimited. Does not implicitly {@link api.core.CreateQuickReplyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {api.core.ICreateQuickReplyRequest} message CreateQuickReplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateQuickReplyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateQuickReplyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateQuickReplyRequest} CreateQuickReplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateQuickReplyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateQuickReplyRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.reply = $root.api.core.QuickReplyItem.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateQuickReplyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateQuickReplyRequest} CreateQuickReplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateQuickReplyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateQuickReplyRequest message.
             * @function verify
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateQuickReplyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.reply != null && message.hasOwnProperty("reply")) {
                    let error = $root.api.core.QuickReplyItem.verify(message.reply);
                    if (error)
                        return "reply." + error;
                }
                return null;
            };

            /**
             * Creates a CreateQuickReplyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateQuickReplyRequest} CreateQuickReplyRequest
             */
            CreateQuickReplyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateQuickReplyRequest)
                    return object;
                let message = new $root.api.core.CreateQuickReplyRequest();
                if (object.reply != null) {
                    if (typeof object.reply !== "object")
                        throw TypeError(".api.core.CreateQuickReplyRequest.reply: object expected");
                    message.reply = $root.api.core.QuickReplyItem.fromObject(object.reply);
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateQuickReplyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {api.core.CreateQuickReplyRequest} message CreateQuickReplyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateQuickReplyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.reply = null;
                if (message.reply != null && message.hasOwnProperty("reply"))
                    object.reply = $root.api.core.QuickReplyItem.toObject(message.reply, options);
                return object;
            };

            /**
             * Converts this CreateQuickReplyRequest to JSON.
             * @function toJSON
             * @memberof api.core.CreateQuickReplyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateQuickReplyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateQuickReplyRequest
             * @function getTypeUrl
             * @memberof api.core.CreateQuickReplyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateQuickReplyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateQuickReplyRequest";
            };

            return CreateQuickReplyRequest;
        })();

        core.CreateQuickReplyResponse = (function() {

            /**
             * Properties of a CreateQuickReplyResponse.
             * @memberof api.core
             * @interface ICreateQuickReplyResponse
             * @property {api.core.IQuickReplyItem|null} [reply] CreateQuickReplyResponse reply
             */

            /**
             * Constructs a new CreateQuickReplyResponse.
             * @memberof api.core
             * @classdesc Represents a CreateQuickReplyResponse.
             * @implements ICreateQuickReplyResponse
             * @constructor
             * @param {api.core.ICreateQuickReplyResponse=} [properties] Properties to set
             */
            function CreateQuickReplyResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateQuickReplyResponse reply.
             * @member {api.core.IQuickReplyItem|null|undefined} reply
             * @memberof api.core.CreateQuickReplyResponse
             * @instance
             */
            CreateQuickReplyResponse.prototype.reply = null;

            /**
             * Creates a new CreateQuickReplyResponse instance using the specified properties.
             * @function create
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {api.core.ICreateQuickReplyResponse=} [properties] Properties to set
             * @returns {api.core.CreateQuickReplyResponse} CreateQuickReplyResponse instance
             */
            CreateQuickReplyResponse.create = function create(properties) {
                return new CreateQuickReplyResponse(properties);
            };

            /**
             * Encodes the specified CreateQuickReplyResponse message. Does not implicitly {@link api.core.CreateQuickReplyResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {api.core.ICreateQuickReplyResponse} message CreateQuickReplyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateQuickReplyResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.reply != null && Object.hasOwnProperty.call(message, "reply"))
                    $root.api.core.QuickReplyItem.encode(message.reply, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CreateQuickReplyResponse message, length delimited. Does not implicitly {@link api.core.CreateQuickReplyResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {api.core.ICreateQuickReplyResponse} message CreateQuickReplyResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateQuickReplyResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateQuickReplyResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CreateQuickReplyResponse} CreateQuickReplyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateQuickReplyResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CreateQuickReplyResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.reply = $root.api.core.QuickReplyItem.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateQuickReplyResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CreateQuickReplyResponse} CreateQuickReplyResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateQuickReplyResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateQuickReplyResponse message.
             * @function verify
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateQuickReplyResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.reply != null && message.hasOwnProperty("reply")) {
                    let error = $root.api.core.QuickReplyItem.verify(message.reply);
                    if (error)
                        return "reply." + error;
                }
                return null;
            };

            /**
             * Creates a CreateQuickReplyResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CreateQuickReplyResponse} CreateQuickReplyResponse
             */
            CreateQuickReplyResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CreateQuickReplyResponse)
                    return object;
                let message = new $root.api.core.CreateQuickReplyResponse();
                if (object.reply != null) {
                    if (typeof object.reply !== "object")
                        throw TypeError(".api.core.CreateQuickReplyResponse.reply: object expected");
                    message.reply = $root.api.core.QuickReplyItem.fromObject(object.reply);
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateQuickReplyResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {api.core.CreateQuickReplyResponse} message CreateQuickReplyResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateQuickReplyResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.reply = null;
                if (message.reply != null && message.hasOwnProperty("reply"))
                    object.reply = $root.api.core.QuickReplyItem.toObject(message.reply, options);
                return object;
            };

            /**
             * Converts this CreateQuickReplyResponse to JSON.
             * @function toJSON
             * @memberof api.core.CreateQuickReplyResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateQuickReplyResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateQuickReplyResponse
             * @function getTypeUrl
             * @memberof api.core.CreateQuickReplyResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateQuickReplyResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CreateQuickReplyResponse";
            };

            return CreateQuickReplyResponse;
        })();

        core.UpdateQuickReplyRequest = (function() {

            /**
             * Properties of an UpdateQuickReplyRequest.
             * @memberof api.core
             * @interface IUpdateQuickReplyRequest
             * @property {api.core.IQuickReplyItem|null} [reply] UpdateQuickReplyRequest reply
             * @property {string|null} [oldGroupName] UpdateQuickReplyRequest oldGroupName
             */

            /**
             * Constructs a new UpdateQuickReplyRequest.
             * @memberof api.core
             * @classdesc Represents an UpdateQuickReplyRequest.
             * @implements IUpdateQuickReplyRequest
             * @constructor
             * @param {api.core.IUpdateQuickReplyRequest=} [properties] Properties to set
             */
            function UpdateQuickReplyRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateQuickReplyRequest reply.
             * @member {api.core.IQuickReplyItem|null|undefined} reply
             * @memberof api.core.UpdateQuickReplyRequest
             * @instance
             */
            UpdateQuickReplyRequest.prototype.reply = null;

            /**
             * UpdateQuickReplyRequest oldGroupName.
             * @member {string} oldGroupName
             * @memberof api.core.UpdateQuickReplyRequest
             * @instance
             */
            UpdateQuickReplyRequest.prototype.oldGroupName = "";

            /**
             * Creates a new UpdateQuickReplyRequest instance using the specified properties.
             * @function create
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {api.core.IUpdateQuickReplyRequest=} [properties] Properties to set
             * @returns {api.core.UpdateQuickReplyRequest} UpdateQuickReplyRequest instance
             */
            UpdateQuickReplyRequest.create = function create(properties) {
                return new UpdateQuickReplyRequest(properties);
            };

            /**
             * Encodes the specified UpdateQuickReplyRequest message. Does not implicitly {@link api.core.UpdateQuickReplyRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {api.core.IUpdateQuickReplyRequest} message UpdateQuickReplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateQuickReplyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.reply != null && Object.hasOwnProperty.call(message, "reply"))
                    $root.api.core.QuickReplyItem.encode(message.reply, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.oldGroupName != null && Object.hasOwnProperty.call(message, "oldGroupName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.oldGroupName);
                return writer;
            };

            /**
             * Encodes the specified UpdateQuickReplyRequest message, length delimited. Does not implicitly {@link api.core.UpdateQuickReplyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {api.core.IUpdateQuickReplyRequest} message UpdateQuickReplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateQuickReplyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateQuickReplyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.UpdateQuickReplyRequest} UpdateQuickReplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateQuickReplyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.UpdateQuickReplyRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.reply = $root.api.core.QuickReplyItem.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.oldGroupName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateQuickReplyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.UpdateQuickReplyRequest} UpdateQuickReplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateQuickReplyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateQuickReplyRequest message.
             * @function verify
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateQuickReplyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.reply != null && message.hasOwnProperty("reply")) {
                    let error = $root.api.core.QuickReplyItem.verify(message.reply);
                    if (error)
                        return "reply." + error;
                }
                if (message.oldGroupName != null && message.hasOwnProperty("oldGroupName"))
                    if (!$util.isString(message.oldGroupName))
                        return "oldGroupName: string expected";
                return null;
            };

            /**
             * Creates an UpdateQuickReplyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.UpdateQuickReplyRequest} UpdateQuickReplyRequest
             */
            UpdateQuickReplyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.UpdateQuickReplyRequest)
                    return object;
                let message = new $root.api.core.UpdateQuickReplyRequest();
                if (object.reply != null) {
                    if (typeof object.reply !== "object")
                        throw TypeError(".api.core.UpdateQuickReplyRequest.reply: object expected");
                    message.reply = $root.api.core.QuickReplyItem.fromObject(object.reply);
                }
                if (object.oldGroupName != null)
                    message.oldGroupName = String(object.oldGroupName);
                return message;
            };

            /**
             * Creates a plain object from an UpdateQuickReplyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {api.core.UpdateQuickReplyRequest} message UpdateQuickReplyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateQuickReplyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.reply = null;
                    object.oldGroupName = "";
                }
                if (message.reply != null && message.hasOwnProperty("reply"))
                    object.reply = $root.api.core.QuickReplyItem.toObject(message.reply, options);
                if (message.oldGroupName != null && message.hasOwnProperty("oldGroupName"))
                    object.oldGroupName = message.oldGroupName;
                return object;
            };

            /**
             * Converts this UpdateQuickReplyRequest to JSON.
             * @function toJSON
             * @memberof api.core.UpdateQuickReplyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateQuickReplyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateQuickReplyRequest
             * @function getTypeUrl
             * @memberof api.core.UpdateQuickReplyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateQuickReplyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.UpdateQuickReplyRequest";
            };

            return UpdateQuickReplyRequest;
        })();

        core.DeleteQuickReplyRequest = (function() {

            /**
             * Properties of a DeleteQuickReplyRequest.
             * @memberof api.core
             * @interface IDeleteQuickReplyRequest
             * @property {Long|null} [quickReplyId] DeleteQuickReplyRequest quickReplyId
             * @property {string|null} [name] DeleteQuickReplyRequest name
             */

            /**
             * Constructs a new DeleteQuickReplyRequest.
             * @memberof api.core
             * @classdesc Represents a DeleteQuickReplyRequest.
             * @implements IDeleteQuickReplyRequest
             * @constructor
             * @param {api.core.IDeleteQuickReplyRequest=} [properties] Properties to set
             */
            function DeleteQuickReplyRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteQuickReplyRequest quickReplyId.
             * @member {Long} quickReplyId
             * @memberof api.core.DeleteQuickReplyRequest
             * @instance
             */
            DeleteQuickReplyRequest.prototype.quickReplyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DeleteQuickReplyRequest name.
             * @member {string} name
             * @memberof api.core.DeleteQuickReplyRequest
             * @instance
             */
            DeleteQuickReplyRequest.prototype.name = "";

            /**
             * Creates a new DeleteQuickReplyRequest instance using the specified properties.
             * @function create
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {api.core.IDeleteQuickReplyRequest=} [properties] Properties to set
             * @returns {api.core.DeleteQuickReplyRequest} DeleteQuickReplyRequest instance
             */
            DeleteQuickReplyRequest.create = function create(properties) {
                return new DeleteQuickReplyRequest(properties);
            };

            /**
             * Encodes the specified DeleteQuickReplyRequest message. Does not implicitly {@link api.core.DeleteQuickReplyRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {api.core.IDeleteQuickReplyRequest} message DeleteQuickReplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteQuickReplyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.quickReplyId != null && Object.hasOwnProperty.call(message, "quickReplyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.quickReplyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified DeleteQuickReplyRequest message, length delimited. Does not implicitly {@link api.core.DeleteQuickReplyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {api.core.IDeleteQuickReplyRequest} message DeleteQuickReplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteQuickReplyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteQuickReplyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DeleteQuickReplyRequest} DeleteQuickReplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteQuickReplyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DeleteQuickReplyRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.quickReplyId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteQuickReplyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DeleteQuickReplyRequest} DeleteQuickReplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteQuickReplyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteQuickReplyRequest message.
             * @function verify
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteQuickReplyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.quickReplyId != null && message.hasOwnProperty("quickReplyId"))
                    if (!$util.isInteger(message.quickReplyId) && !(message.quickReplyId && $util.isInteger(message.quickReplyId.low) && $util.isInteger(message.quickReplyId.high)))
                        return "quickReplyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a DeleteQuickReplyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DeleteQuickReplyRequest} DeleteQuickReplyRequest
             */
            DeleteQuickReplyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DeleteQuickReplyRequest)
                    return object;
                let message = new $root.api.core.DeleteQuickReplyRequest();
                if (object.quickReplyId != null)
                    if ($util.Long)
                        (message.quickReplyId = $util.Long.fromValue(object.quickReplyId)).unsigned = false;
                    else if (typeof object.quickReplyId === "string")
                        message.quickReplyId = parseInt(object.quickReplyId, 10);
                    else if (typeof object.quickReplyId === "number")
                        message.quickReplyId = object.quickReplyId;
                    else if (typeof object.quickReplyId === "object")
                        message.quickReplyId = new $util.LongBits(object.quickReplyId.low >>> 0, object.quickReplyId.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a DeleteQuickReplyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {api.core.DeleteQuickReplyRequest} message DeleteQuickReplyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteQuickReplyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.quickReplyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.quickReplyId = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.quickReplyId != null && message.hasOwnProperty("quickReplyId"))
                    if (typeof message.quickReplyId === "number")
                        object.quickReplyId = options.longs === String ? String(message.quickReplyId) : message.quickReplyId;
                    else
                        object.quickReplyId = options.longs === String ? $util.Long.prototype.toString.call(message.quickReplyId) : options.longs === Number ? new $util.LongBits(message.quickReplyId.low >>> 0, message.quickReplyId.high >>> 0).toNumber() : message.quickReplyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this DeleteQuickReplyRequest to JSON.
             * @function toJSON
             * @memberof api.core.DeleteQuickReplyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteQuickReplyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteQuickReplyRequest
             * @function getTypeUrl
             * @memberof api.core.DeleteQuickReplyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteQuickReplyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DeleteQuickReplyRequest";
            };

            return DeleteQuickReplyRequest;
        })();

        core.PushCommonRequest = (function() {

            /**
             * Properties of a PushCommonRequest.
             * @memberof api.core
             * @interface IPushCommonRequest
             * @property {Long|null} [quickReplyId] PushCommonRequest quickReplyId
             */

            /**
             * Constructs a new PushCommonRequest.
             * @memberof api.core
             * @classdesc Represents a PushCommonRequest.
             * @implements IPushCommonRequest
             * @constructor
             * @param {api.core.IPushCommonRequest=} [properties] Properties to set
             */
            function PushCommonRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PushCommonRequest quickReplyId.
             * @member {Long} quickReplyId
             * @memberof api.core.PushCommonRequest
             * @instance
             */
            PushCommonRequest.prototype.quickReplyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new PushCommonRequest instance using the specified properties.
             * @function create
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {api.core.IPushCommonRequest=} [properties] Properties to set
             * @returns {api.core.PushCommonRequest} PushCommonRequest instance
             */
            PushCommonRequest.create = function create(properties) {
                return new PushCommonRequest(properties);
            };

            /**
             * Encodes the specified PushCommonRequest message. Does not implicitly {@link api.core.PushCommonRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {api.core.IPushCommonRequest} message PushCommonRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushCommonRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.quickReplyId != null && Object.hasOwnProperty.call(message, "quickReplyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.quickReplyId);
                return writer;
            };

            /**
             * Encodes the specified PushCommonRequest message, length delimited. Does not implicitly {@link api.core.PushCommonRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {api.core.IPushCommonRequest} message PushCommonRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PushCommonRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PushCommonRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.PushCommonRequest} PushCommonRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushCommonRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.PushCommonRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.quickReplyId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PushCommonRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.PushCommonRequest} PushCommonRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PushCommonRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PushCommonRequest message.
             * @function verify
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PushCommonRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.quickReplyId != null && message.hasOwnProperty("quickReplyId"))
                    if (!$util.isInteger(message.quickReplyId) && !(message.quickReplyId && $util.isInteger(message.quickReplyId.low) && $util.isInteger(message.quickReplyId.high)))
                        return "quickReplyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a PushCommonRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.PushCommonRequest} PushCommonRequest
             */
            PushCommonRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.PushCommonRequest)
                    return object;
                let message = new $root.api.core.PushCommonRequest();
                if (object.quickReplyId != null)
                    if ($util.Long)
                        (message.quickReplyId = $util.Long.fromValue(object.quickReplyId)).unsigned = false;
                    else if (typeof object.quickReplyId === "string")
                        message.quickReplyId = parseInt(object.quickReplyId, 10);
                    else if (typeof object.quickReplyId === "number")
                        message.quickReplyId = object.quickReplyId;
                    else if (typeof object.quickReplyId === "object")
                        message.quickReplyId = new $util.LongBits(object.quickReplyId.low >>> 0, object.quickReplyId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a PushCommonRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {api.core.PushCommonRequest} message PushCommonRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PushCommonRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.quickReplyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.quickReplyId = options.longs === String ? "0" : 0;
                if (message.quickReplyId != null && message.hasOwnProperty("quickReplyId"))
                    if (typeof message.quickReplyId === "number")
                        object.quickReplyId = options.longs === String ? String(message.quickReplyId) : message.quickReplyId;
                    else
                        object.quickReplyId = options.longs === String ? $util.Long.prototype.toString.call(message.quickReplyId) : options.longs === Number ? new $util.LongBits(message.quickReplyId.low >>> 0, message.quickReplyId.high >>> 0).toNumber() : message.quickReplyId;
                return object;
            };

            /**
             * Converts this PushCommonRequest to JSON.
             * @function toJSON
             * @memberof api.core.PushCommonRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PushCommonRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PushCommonRequest
             * @function getTypeUrl
             * @memberof api.core.PushCommonRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PushCommonRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.PushCommonRequest";
            };

            return PushCommonRequest;
        })();

        core.PopCommonRequest = (function() {

            /**
             * Properties of a PopCommonRequest.
             * @memberof api.core
             * @interface IPopCommonRequest
             * @property {Long|null} [quickReplyId] PopCommonRequest quickReplyId
             */

            /**
             * Constructs a new PopCommonRequest.
             * @memberof api.core
             * @classdesc Represents a PopCommonRequest.
             * @implements IPopCommonRequest
             * @constructor
             * @param {api.core.IPopCommonRequest=} [properties] Properties to set
             */
            function PopCommonRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PopCommonRequest quickReplyId.
             * @member {Long} quickReplyId
             * @memberof api.core.PopCommonRequest
             * @instance
             */
            PopCommonRequest.prototype.quickReplyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new PopCommonRequest instance using the specified properties.
             * @function create
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {api.core.IPopCommonRequest=} [properties] Properties to set
             * @returns {api.core.PopCommonRequest} PopCommonRequest instance
             */
            PopCommonRequest.create = function create(properties) {
                return new PopCommonRequest(properties);
            };

            /**
             * Encodes the specified PopCommonRequest message. Does not implicitly {@link api.core.PopCommonRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {api.core.IPopCommonRequest} message PopCommonRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PopCommonRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.quickReplyId != null && Object.hasOwnProperty.call(message, "quickReplyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.quickReplyId);
                return writer;
            };

            /**
             * Encodes the specified PopCommonRequest message, length delimited. Does not implicitly {@link api.core.PopCommonRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {api.core.IPopCommonRequest} message PopCommonRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PopCommonRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PopCommonRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.PopCommonRequest} PopCommonRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PopCommonRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.PopCommonRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.quickReplyId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PopCommonRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.PopCommonRequest} PopCommonRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PopCommonRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PopCommonRequest message.
             * @function verify
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PopCommonRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.quickReplyId != null && message.hasOwnProperty("quickReplyId"))
                    if (!$util.isInteger(message.quickReplyId) && !(message.quickReplyId && $util.isInteger(message.quickReplyId.low) && $util.isInteger(message.quickReplyId.high)))
                        return "quickReplyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a PopCommonRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.PopCommonRequest} PopCommonRequest
             */
            PopCommonRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.PopCommonRequest)
                    return object;
                let message = new $root.api.core.PopCommonRequest();
                if (object.quickReplyId != null)
                    if ($util.Long)
                        (message.quickReplyId = $util.Long.fromValue(object.quickReplyId)).unsigned = false;
                    else if (typeof object.quickReplyId === "string")
                        message.quickReplyId = parseInt(object.quickReplyId, 10);
                    else if (typeof object.quickReplyId === "number")
                        message.quickReplyId = object.quickReplyId;
                    else if (typeof object.quickReplyId === "object")
                        message.quickReplyId = new $util.LongBits(object.quickReplyId.low >>> 0, object.quickReplyId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a PopCommonRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {api.core.PopCommonRequest} message PopCommonRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PopCommonRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.quickReplyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.quickReplyId = options.longs === String ? "0" : 0;
                if (message.quickReplyId != null && message.hasOwnProperty("quickReplyId"))
                    if (typeof message.quickReplyId === "number")
                        object.quickReplyId = options.longs === String ? String(message.quickReplyId) : message.quickReplyId;
                    else
                        object.quickReplyId = options.longs === String ? $util.Long.prototype.toString.call(message.quickReplyId) : options.longs === Number ? new $util.LongBits(message.quickReplyId.low >>> 0, message.quickReplyId.high >>> 0).toNumber() : message.quickReplyId;
                return object;
            };

            /**
             * Converts this PopCommonRequest to JSON.
             * @function toJSON
             * @memberof api.core.PopCommonRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PopCommonRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PopCommonRequest
             * @function getTypeUrl
             * @memberof api.core.PopCommonRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PopCommonRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.PopCommonRequest";
            };

            return PopCommonRequest;
        })();

        core.QueryCommonResponse = (function() {

            /**
             * Properties of a QueryCommonResponse.
             * @memberof api.core
             * @interface IQueryCommonResponse
             * @property {Array.<api.core.IQuickReplyItem>|null} [quickReply] QueryCommonResponse quickReply
             */

            /**
             * Constructs a new QueryCommonResponse.
             * @memberof api.core
             * @classdesc Represents a QueryCommonResponse.
             * @implements IQueryCommonResponse
             * @constructor
             * @param {api.core.IQueryCommonResponse=} [properties] Properties to set
             */
            function QueryCommonResponse(properties) {
                this.quickReply = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryCommonResponse quickReply.
             * @member {Array.<api.core.IQuickReplyItem>} quickReply
             * @memberof api.core.QueryCommonResponse
             * @instance
             */
            QueryCommonResponse.prototype.quickReply = $util.emptyArray;

            /**
             * Creates a new QueryCommonResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {api.core.IQueryCommonResponse=} [properties] Properties to set
             * @returns {api.core.QueryCommonResponse} QueryCommonResponse instance
             */
            QueryCommonResponse.create = function create(properties) {
                return new QueryCommonResponse(properties);
            };

            /**
             * Encodes the specified QueryCommonResponse message. Does not implicitly {@link api.core.QueryCommonResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {api.core.IQueryCommonResponse} message QueryCommonResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryCommonResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.quickReply != null && message.quickReply.length)
                    for (let i = 0; i < message.quickReply.length; ++i)
                        $root.api.core.QuickReplyItem.encode(message.quickReply[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryCommonResponse message, length delimited. Does not implicitly {@link api.core.QueryCommonResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {api.core.IQueryCommonResponse} message QueryCommonResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryCommonResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryCommonResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryCommonResponse} QueryCommonResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryCommonResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryCommonResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.quickReply && message.quickReply.length))
                                message.quickReply = [];
                            message.quickReply.push($root.api.core.QuickReplyItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryCommonResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryCommonResponse} QueryCommonResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryCommonResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryCommonResponse message.
             * @function verify
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryCommonResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.quickReply != null && message.hasOwnProperty("quickReply")) {
                    if (!Array.isArray(message.quickReply))
                        return "quickReply: array expected";
                    for (let i = 0; i < message.quickReply.length; ++i) {
                        let error = $root.api.core.QuickReplyItem.verify(message.quickReply[i]);
                        if (error)
                            return "quickReply." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QueryCommonResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryCommonResponse} QueryCommonResponse
             */
            QueryCommonResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryCommonResponse)
                    return object;
                let message = new $root.api.core.QueryCommonResponse();
                if (object.quickReply) {
                    if (!Array.isArray(object.quickReply))
                        throw TypeError(".api.core.QueryCommonResponse.quickReply: array expected");
                    message.quickReply = [];
                    for (let i = 0; i < object.quickReply.length; ++i) {
                        if (typeof object.quickReply[i] !== "object")
                            throw TypeError(".api.core.QueryCommonResponse.quickReply: object expected");
                        message.quickReply[i] = $root.api.core.QuickReplyItem.fromObject(object.quickReply[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryCommonResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {api.core.QueryCommonResponse} message QueryCommonResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryCommonResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.quickReply = [];
                if (message.quickReply && message.quickReply.length) {
                    object.quickReply = [];
                    for (let j = 0; j < message.quickReply.length; ++j)
                        object.quickReply[j] = $root.api.core.QuickReplyItem.toObject(message.quickReply[j], options);
                }
                return object;
            };

            /**
             * Converts this QueryCommonResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryCommonResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryCommonResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryCommonResponse
             * @function getTypeUrl
             * @memberof api.core.QueryCommonResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryCommonResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryCommonResponse";
            };

            return QueryCommonResponse;
        })();

        core.QuickReplyItemGroup = (function() {

            /**
             * Properties of a QuickReplyItemGroup.
             * @memberof api.core
             * @interface IQuickReplyItemGroup
             * @property {string|null} [groupName] QuickReplyItemGroup groupName
             * @property {number|null} [priority] QuickReplyItemGroup priority
             * @property {Array.<api.core.IQuickReplyItem>|null} [items] QuickReplyItemGroup items
             */

            /**
             * Constructs a new QuickReplyItemGroup.
             * @memberof api.core
             * @classdesc Represents a QuickReplyItemGroup.
             * @implements IQuickReplyItemGroup
             * @constructor
             * @param {api.core.IQuickReplyItemGroup=} [properties] Properties to set
             */
            function QuickReplyItemGroup(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuickReplyItemGroup groupName.
             * @member {string} groupName
             * @memberof api.core.QuickReplyItemGroup
             * @instance
             */
            QuickReplyItemGroup.prototype.groupName = "";

            /**
             * QuickReplyItemGroup priority.
             * @member {number} priority
             * @memberof api.core.QuickReplyItemGroup
             * @instance
             */
            QuickReplyItemGroup.prototype.priority = 0;

            /**
             * QuickReplyItemGroup items.
             * @member {Array.<api.core.IQuickReplyItem>} items
             * @memberof api.core.QuickReplyItemGroup
             * @instance
             */
            QuickReplyItemGroup.prototype.items = $util.emptyArray;

            /**
             * Creates a new QuickReplyItemGroup instance using the specified properties.
             * @function create
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {api.core.IQuickReplyItemGroup=} [properties] Properties to set
             * @returns {api.core.QuickReplyItemGroup} QuickReplyItemGroup instance
             */
            QuickReplyItemGroup.create = function create(properties) {
                return new QuickReplyItemGroup(properties);
            };

            /**
             * Encodes the specified QuickReplyItemGroup message. Does not implicitly {@link api.core.QuickReplyItemGroup.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {api.core.IQuickReplyItemGroup} message QuickReplyItemGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyItemGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupName);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.priority);
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.core.QuickReplyItem.encode(message.items[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuickReplyItemGroup message, length delimited. Does not implicitly {@link api.core.QuickReplyItemGroup.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {api.core.IQuickReplyItemGroup} message QuickReplyItemGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuickReplyItemGroup.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuickReplyItemGroup message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuickReplyItemGroup} QuickReplyItemGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyItemGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuickReplyItemGroup();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.groupName = reader.string();
                            break;
                        }
                    case 2: {
                            message.priority = reader.int32();
                            break;
                        }
                    case 3: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.core.QuickReplyItem.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuickReplyItemGroup message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuickReplyItemGroup} QuickReplyItemGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuickReplyItemGroup.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuickReplyItemGroup message.
             * @function verify
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuickReplyItemGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.core.QuickReplyItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QuickReplyItemGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuickReplyItemGroup} QuickReplyItemGroup
             */
            QuickReplyItemGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuickReplyItemGroup)
                    return object;
                let message = new $root.api.core.QuickReplyItemGroup();
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.core.QuickReplyItemGroup.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.core.QuickReplyItemGroup.items: object expected");
                        message.items[i] = $root.api.core.QuickReplyItem.fromObject(object.items[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QuickReplyItemGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {api.core.QuickReplyItemGroup} message QuickReplyItemGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuickReplyItemGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults) {
                    object.groupName = "";
                    object.priority = 0;
                }
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.core.QuickReplyItem.toObject(message.items[j], options);
                }
                return object;
            };

            /**
             * Converts this QuickReplyItemGroup to JSON.
             * @function toJSON
             * @memberof api.core.QuickReplyItemGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuickReplyItemGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuickReplyItemGroup
             * @function getTypeUrl
             * @memberof api.core.QuickReplyItemGroup
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuickReplyItemGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuickReplyItemGroup";
            };

            return QuickReplyItemGroup;
        })();

        core.QueryByWorkerResponse = (function() {

            /**
             * Properties of a QueryByWorkerResponse.
             * @memberof api.core
             * @interface IQueryByWorkerResponse
             * @property {Array.<api.core.IQuickReplyItemGroup>|null} [group] QueryByWorkerResponse group
             */

            /**
             * Constructs a new QueryByWorkerResponse.
             * @memberof api.core
             * @classdesc Represents a QueryByWorkerResponse.
             * @implements IQueryByWorkerResponse
             * @constructor
             * @param {api.core.IQueryByWorkerResponse=} [properties] Properties to set
             */
            function QueryByWorkerResponse(properties) {
                this.group = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryByWorkerResponse group.
             * @member {Array.<api.core.IQuickReplyItemGroup>} group
             * @memberof api.core.QueryByWorkerResponse
             * @instance
             */
            QueryByWorkerResponse.prototype.group = $util.emptyArray;

            /**
             * Creates a new QueryByWorkerResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {api.core.IQueryByWorkerResponse=} [properties] Properties to set
             * @returns {api.core.QueryByWorkerResponse} QueryByWorkerResponse instance
             */
            QueryByWorkerResponse.create = function create(properties) {
                return new QueryByWorkerResponse(properties);
            };

            /**
             * Encodes the specified QueryByWorkerResponse message. Does not implicitly {@link api.core.QueryByWorkerResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {api.core.IQueryByWorkerResponse} message QueryByWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryByWorkerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.group != null && message.group.length)
                    for (let i = 0; i < message.group.length; ++i)
                        $root.api.core.QuickReplyItemGroup.encode(message.group[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryByWorkerResponse message, length delimited. Does not implicitly {@link api.core.QueryByWorkerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {api.core.IQueryByWorkerResponse} message QueryByWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryByWorkerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryByWorkerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryByWorkerResponse} QueryByWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryByWorkerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryByWorkerResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.group && message.group.length))
                                message.group = [];
                            message.group.push($root.api.core.QuickReplyItemGroup.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryByWorkerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryByWorkerResponse} QueryByWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryByWorkerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryByWorkerResponse message.
             * @function verify
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryByWorkerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.group != null && message.hasOwnProperty("group")) {
                    if (!Array.isArray(message.group))
                        return "group: array expected";
                    for (let i = 0; i < message.group.length; ++i) {
                        let error = $root.api.core.QuickReplyItemGroup.verify(message.group[i]);
                        if (error)
                            return "group." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QueryByWorkerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryByWorkerResponse} QueryByWorkerResponse
             */
            QueryByWorkerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryByWorkerResponse)
                    return object;
                let message = new $root.api.core.QueryByWorkerResponse();
                if (object.group) {
                    if (!Array.isArray(object.group))
                        throw TypeError(".api.core.QueryByWorkerResponse.group: array expected");
                    message.group = [];
                    for (let i = 0; i < object.group.length; ++i) {
                        if (typeof object.group[i] !== "object")
                            throw TypeError(".api.core.QueryByWorkerResponse.group: object expected");
                        message.group[i] = $root.api.core.QuickReplyItemGroup.fromObject(object.group[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryByWorkerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {api.core.QueryByWorkerResponse} message QueryByWorkerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryByWorkerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.group = [];
                if (message.group && message.group.length) {
                    object.group = [];
                    for (let j = 0; j < message.group.length; ++j)
                        object.group[j] = $root.api.core.QuickReplyItemGroup.toObject(message.group[j], options);
                }
                return object;
            };

            /**
             * Converts this QueryByWorkerResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryByWorkerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryByWorkerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryByWorkerResponse
             * @function getTypeUrl
             * @memberof api.core.QueryByWorkerResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryByWorkerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryByWorkerResponse";
            };

            return QueryByWorkerResponse;
        })();

        core.QuickReply = (function() {

            /**
             * Constructs a new QuickReply service.
             * @memberof api.core
             * @classdesc Represents a QuickReply
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function QuickReply(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (QuickReply.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = QuickReply;

            /**
             * Creates new QuickReply service using the specified rpc implementation.
             * @function create
             * @memberof api.core.QuickReply
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {QuickReply} RPC service. Useful where requests and/or responses are streamed.
             */
            QuickReply.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.QuickReply#queryGroup}.
             * @memberof api.core.QuickReply
             * @typedef QueryGroupCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QuickReplyGroupQueryResponse} [response] QuickReplyGroupQueryResponse
             */

            /**
             * Calls QueryGroup.
             * @function queryGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.QuickReply.QueryGroupCallback} callback Node-style callback called with the error, if any, and QuickReplyGroupQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.queryGroup = function queryGroup(request, callback) {
                return this.rpcCall(queryGroup, $root.google.protobuf.Empty, $root.api.core.QuickReplyGroupQueryResponse, request, callback);
            }, "name", { value: "QueryGroup" });

            /**
             * Calls QueryGroup.
             * @function queryGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.QuickReplyGroupQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#createGroup}.
             * @memberof api.core.QuickReply
             * @typedef CreateGroupCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls CreateGroup.
             * @function createGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IQuickReplyGroupCreateRequest} request QuickReplyGroupCreateRequest message or plain object
             * @param {api.core.QuickReply.CreateGroupCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.createGroup = function createGroup(request, callback) {
                return this.rpcCall(createGroup, $root.api.core.QuickReplyGroupCreateRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "CreateGroup" });

            /**
             * Calls CreateGroup.
             * @function createGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IQuickReplyGroupCreateRequest} request QuickReplyGroupCreateRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#updateGroup}.
             * @memberof api.core.QuickReply
             * @typedef UpdateGroupCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls UpdateGroup.
             * @function updateGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IQuickReplyGroupUpdateRequest} request QuickReplyGroupUpdateRequest message or plain object
             * @param {api.core.QuickReply.UpdateGroupCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.updateGroup = function updateGroup(request, callback) {
                return this.rpcCall(updateGroup, $root.api.core.QuickReplyGroupUpdateRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "UpdateGroup" });

            /**
             * Calls UpdateGroup.
             * @function updateGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IQuickReplyGroupUpdateRequest} request QuickReplyGroupUpdateRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#deleteGroup}.
             * @memberof api.core.QuickReply
             * @typedef DeleteGroupCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteGroup.
             * @function deleteGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IQuickReplyGroupDeleteRequest} request QuickReplyGroupDeleteRequest message or plain object
             * @param {api.core.QuickReply.DeleteGroupCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.deleteGroup = function deleteGroup(request, callback) {
                return this.rpcCall(deleteGroup, $root.api.core.QuickReplyGroupDeleteRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteGroup" });

            /**
             * Calls DeleteGroup.
             * @function deleteGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IQuickReplyGroupDeleteRequest} request QuickReplyGroupDeleteRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#queryReply}.
             * @memberof api.core.QuickReply
             * @typedef QueryReplyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QuickReplyQueryResponse} [response] QuickReplyQueryResponse
             */

            /**
             * Calls QueryReply.
             * @function queryReply
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.QuickReply.QueryReplyCallback} callback Node-style callback called with the error, if any, and QuickReplyQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.queryReply = function queryReply(request, callback) {
                return this.rpcCall(queryReply, $root.google.protobuf.Empty, $root.api.core.QuickReplyQueryResponse, request, callback);
            }, "name", { value: "QueryReply" });

            /**
             * Calls QueryReply.
             * @function queryReply
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.QuickReplyQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#createReply}.
             * @memberof api.core.QuickReply
             * @typedef CreateReplyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.CreateQuickReplyResponse} [response] CreateQuickReplyResponse
             */

            /**
             * Calls CreateReply.
             * @function createReply
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.ICreateQuickReplyRequest} request CreateQuickReplyRequest message or plain object
             * @param {api.core.QuickReply.CreateReplyCallback} callback Node-style callback called with the error, if any, and CreateQuickReplyResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.createReply = function createReply(request, callback) {
                return this.rpcCall(createReply, $root.api.core.CreateQuickReplyRequest, $root.api.core.CreateQuickReplyResponse, request, callback);
            }, "name", { value: "CreateReply" });

            /**
             * Calls CreateReply.
             * @function createReply
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.ICreateQuickReplyRequest} request CreateQuickReplyRequest message or plain object
             * @returns {Promise<api.core.CreateQuickReplyResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#updateReply}.
             * @memberof api.core.QuickReply
             * @typedef UpdateReplyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls UpdateReply.
             * @function updateReply
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IUpdateQuickReplyRequest} request UpdateQuickReplyRequest message or plain object
             * @param {api.core.QuickReply.UpdateReplyCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.updateReply = function updateReply(request, callback) {
                return this.rpcCall(updateReply, $root.api.core.UpdateQuickReplyRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "UpdateReply" });

            /**
             * Calls UpdateReply.
             * @function updateReply
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IUpdateQuickReplyRequest} request UpdateQuickReplyRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#deleteReply}.
             * @memberof api.core.QuickReply
             * @typedef DeleteReplyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteReply.
             * @function deleteReply
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IDeleteQuickReplyRequest} request DeleteQuickReplyRequest message or plain object
             * @param {api.core.QuickReply.DeleteReplyCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.deleteReply = function deleteReply(request, callback) {
                return this.rpcCall(deleteReply, $root.api.core.DeleteQuickReplyRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteReply" });

            /**
             * Calls DeleteReply.
             * @function deleteReply
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IDeleteQuickReplyRequest} request DeleteQuickReplyRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#initGroup}.
             * @memberof api.core.QuickReply
             * @typedef InitGroupCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls InitGroup.
             * @function initGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.QuickReply.InitGroupCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.initGroup = function initGroup(request, callback) {
                return this.rpcCall(initGroup, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "InitGroup" });

            /**
             * Calls InitGroup.
             * @function initGroup
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#pushCommon}.
             * @memberof api.core.QuickReply
             * @typedef PushCommonCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls PushCommon.
             * @function pushCommon
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IPushCommonRequest} request PushCommonRequest message or plain object
             * @param {api.core.QuickReply.PushCommonCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.pushCommon = function pushCommon(request, callback) {
                return this.rpcCall(pushCommon, $root.api.core.PushCommonRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "PushCommon" });

            /**
             * Calls PushCommon.
             * @function pushCommon
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IPushCommonRequest} request PushCommonRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#popCommon}.
             * @memberof api.core.QuickReply
             * @typedef PopCommonCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls PopCommon.
             * @function popCommon
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IPopCommonRequest} request PopCommonRequest message or plain object
             * @param {api.core.QuickReply.PopCommonCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.popCommon = function popCommon(request, callback) {
                return this.rpcCall(popCommon, $root.api.core.PopCommonRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "PopCommon" });

            /**
             * Calls PopCommon.
             * @function popCommon
             * @memberof api.core.QuickReply
             * @instance
             * @param {api.core.IPopCommonRequest} request PopCommonRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#queryCommon}.
             * @memberof api.core.QuickReply
             * @typedef QueryCommonCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryCommonResponse} [response] QueryCommonResponse
             */

            /**
             * Calls QueryCommon.
             * @function queryCommon
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.QuickReply.QueryCommonCallback} callback Node-style callback called with the error, if any, and QueryCommonResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.queryCommon = function queryCommon(request, callback) {
                return this.rpcCall(queryCommon, $root.google.protobuf.Empty, $root.api.core.QueryCommonResponse, request, callback);
            }, "name", { value: "QueryCommon" });

            /**
             * Calls QueryCommon.
             * @function queryCommon
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.QueryCommonResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.QuickReply#queryByWorker}.
             * @memberof api.core.QuickReply
             * @typedef QueryByWorkerCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryByWorkerResponse} [response] QueryByWorkerResponse
             */

            /**
             * Calls QueryByWorker.
             * @function queryByWorker
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.QuickReply.QueryByWorkerCallback} callback Node-style callback called with the error, if any, and QueryByWorkerResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(QuickReply.prototype.queryByWorker = function queryByWorker(request, callback) {
                return this.rpcCall(queryByWorker, $root.google.protobuf.Empty, $root.api.core.QueryByWorkerResponse, request, callback);
            }, "name", { value: "QueryByWorker" });

            /**
             * Calls QueryByWorker.
             * @function queryByWorker
             * @memberof api.core.QuickReply
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.QueryByWorkerResponse>} Promise
             * @variation 2
             */

            return QuickReply;
        })();

        core.LoginRequest = (function() {

            /**
             * Properties of a LoginRequest.
             * @memberof api.core
             * @interface ILoginRequest
             * @property {string|null} [account] LoginRequest account
             * @property {string|null} [password] LoginRequest password
             */

            /**
             * Constructs a new LoginRequest.
             * @memberof api.core
             * @classdesc Represents a LoginRequest.
             * @implements ILoginRequest
             * @constructor
             * @param {api.core.ILoginRequest=} [properties] Properties to set
             */
            function LoginRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LoginRequest account.
             * @member {string} account
             * @memberof api.core.LoginRequest
             * @instance
             */
            LoginRequest.prototype.account = "";

            /**
             * LoginRequest password.
             * @member {string} password
             * @memberof api.core.LoginRequest
             * @instance
             */
            LoginRequest.prototype.password = "";

            /**
             * Creates a new LoginRequest instance using the specified properties.
             * @function create
             * @memberof api.core.LoginRequest
             * @static
             * @param {api.core.ILoginRequest=} [properties] Properties to set
             * @returns {api.core.LoginRequest} LoginRequest instance
             */
            LoginRequest.create = function create(properties) {
                return new LoginRequest(properties);
            };

            /**
             * Encodes the specified LoginRequest message. Does not implicitly {@link api.core.LoginRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.LoginRequest
             * @static
             * @param {api.core.ILoginRequest} message LoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.account);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                return writer;
            };

            /**
             * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link api.core.LoginRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.LoginRequest
             * @static
             * @param {api.core.ILoginRequest} message LoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LoginRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.LoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.LoginRequest} LoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.LoginRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.account = reader.string();
                            break;
                        }
                    case 2: {
                            message.password = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.LoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.LoginRequest} LoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LoginRequest message.
             * @function verify
             * @memberof api.core.LoginRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LoginRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.account != null && message.hasOwnProperty("account"))
                    if (!$util.isString(message.account))
                        return "account: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                return null;
            };

            /**
             * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.LoginRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.LoginRequest} LoginRequest
             */
            LoginRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.LoginRequest)
                    return object;
                let message = new $root.api.core.LoginRequest();
                if (object.account != null)
                    message.account = String(object.account);
                if (object.password != null)
                    message.password = String(object.password);
                return message;
            };

            /**
             * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.LoginRequest
             * @static
             * @param {api.core.LoginRequest} message LoginRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LoginRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.account = "";
                    object.password = "";
                }
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = message.account;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                return object;
            };

            /**
             * Converts this LoginRequest to JSON.
             * @function toJSON
             * @memberof api.core.LoginRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LoginRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LoginRequest
             * @function getTypeUrl
             * @memberof api.core.LoginRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.LoginRequest";
            };

            return LoginRequest;
        })();

        core.LoginResponse = (function() {

            /**
             * Properties of a LoginResponse.
             * @memberof api.core
             * @interface ILoginResponse
             * @property {string|null} [token] LoginResponse token
             * @property {api.common.IWorker|null} [items] LoginResponse items
             */

            /**
             * Constructs a new LoginResponse.
             * @memberof api.core
             * @classdesc Represents a LoginResponse.
             * @implements ILoginResponse
             * @constructor
             * @param {api.core.ILoginResponse=} [properties] Properties to set
             */
            function LoginResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LoginResponse token.
             * @member {string} token
             * @memberof api.core.LoginResponse
             * @instance
             */
            LoginResponse.prototype.token = "";

            /**
             * LoginResponse items.
             * @member {api.common.IWorker|null|undefined} items
             * @memberof api.core.LoginResponse
             * @instance
             */
            LoginResponse.prototype.items = null;

            /**
             * Creates a new LoginResponse instance using the specified properties.
             * @function create
             * @memberof api.core.LoginResponse
             * @static
             * @param {api.core.ILoginResponse=} [properties] Properties to set
             * @returns {api.core.LoginResponse} LoginResponse instance
             */
            LoginResponse.create = function create(properties) {
                return new LoginResponse(properties);
            };

            /**
             * Encodes the specified LoginResponse message. Does not implicitly {@link api.core.LoginResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.LoginResponse
             * @static
             * @param {api.core.ILoginResponse} message LoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                if (message.items != null && Object.hasOwnProperty.call(message, "items"))
                    $root.api.common.Worker.encode(message.items, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link api.core.LoginResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.LoginResponse
             * @static
             * @param {api.core.ILoginResponse} message LoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LoginResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.LoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.LoginResponse} LoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.LoginResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.token = reader.string();
                            break;
                        }
                    case 2: {
                            message.items = $root.api.common.Worker.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.LoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.LoginResponse} LoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LoginResponse message.
             * @function verify
             * @memberof api.core.LoginResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LoginResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    let error = $root.api.common.Worker.verify(message.items);
                    if (error)
                        return "items." + error;
                }
                return null;
            };

            /**
             * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.LoginResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.LoginResponse} LoginResponse
             */
            LoginResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.LoginResponse)
                    return object;
                let message = new $root.api.core.LoginResponse();
                if (object.token != null)
                    message.token = String(object.token);
                if (object.items != null) {
                    if (typeof object.items !== "object")
                        throw TypeError(".api.core.LoginResponse.items: object expected");
                    message.items = $root.api.common.Worker.fromObject(object.items);
                }
                return message;
            };

            /**
             * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.LoginResponse
             * @static
             * @param {api.core.LoginResponse} message LoginResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LoginResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.token = "";
                    object.items = null;
                }
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.items != null && message.hasOwnProperty("items"))
                    object.items = $root.api.common.Worker.toObject(message.items, options);
                return object;
            };

            /**
             * Converts this LoginResponse to JSON.
             * @function toJSON
             * @memberof api.core.LoginResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LoginResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LoginResponse
             * @function getTypeUrl
             * @memberof api.core.LoginResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.LoginResponse";
            };

            return LoginResponse;
        })();

        core.ManagerLoginRequest = (function() {

            /**
             * Properties of a ManagerLoginRequest.
             * @memberof api.core
             * @interface IManagerLoginRequest
             * @property {number|null} [workerId] ManagerLoginRequest workerId
             */

            /**
             * Constructs a new ManagerLoginRequest.
             * @memberof api.core
             * @classdesc Represents a ManagerLoginRequest.
             * @implements IManagerLoginRequest
             * @constructor
             * @param {api.core.IManagerLoginRequest=} [properties] Properties to set
             */
            function ManagerLoginRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ManagerLoginRequest workerId.
             * @member {number} workerId
             * @memberof api.core.ManagerLoginRequest
             * @instance
             */
            ManagerLoginRequest.prototype.workerId = 0;

            /**
             * Creates a new ManagerLoginRequest instance using the specified properties.
             * @function create
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {api.core.IManagerLoginRequest=} [properties] Properties to set
             * @returns {api.core.ManagerLoginRequest} ManagerLoginRequest instance
             */
            ManagerLoginRequest.create = function create(properties) {
                return new ManagerLoginRequest(properties);
            };

            /**
             * Encodes the specified ManagerLoginRequest message. Does not implicitly {@link api.core.ManagerLoginRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {api.core.IManagerLoginRequest} message ManagerLoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ManagerLoginRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified ManagerLoginRequest message, length delimited. Does not implicitly {@link api.core.ManagerLoginRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {api.core.IManagerLoginRequest} message ManagerLoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ManagerLoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ManagerLoginRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.ManagerLoginRequest} ManagerLoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ManagerLoginRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.ManagerLoginRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ManagerLoginRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.ManagerLoginRequest} ManagerLoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ManagerLoginRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ManagerLoginRequest message.
             * @function verify
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ManagerLoginRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a ManagerLoginRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.ManagerLoginRequest} ManagerLoginRequest
             */
            ManagerLoginRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.ManagerLoginRequest)
                    return object;
                let message = new $root.api.core.ManagerLoginRequest();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a ManagerLoginRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {api.core.ManagerLoginRequest} message ManagerLoginRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ManagerLoginRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.workerId = 0;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this ManagerLoginRequest to JSON.
             * @function toJSON
             * @memberof api.core.ManagerLoginRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ManagerLoginRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ManagerLoginRequest
             * @function getTypeUrl
             * @memberof api.core.ManagerLoginRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ManagerLoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.ManagerLoginRequest";
            };

            return ManagerLoginRequest;
        })();

        core.ManagerLoginResponse = (function() {

            /**
             * Properties of a ManagerLoginResponse.
             * @memberof api.core
             * @interface IManagerLoginResponse
             * @property {string|null} [token] ManagerLoginResponse token
             * @property {api.common.IWorker|null} [items] ManagerLoginResponse items
             */

            /**
             * Constructs a new ManagerLoginResponse.
             * @memberof api.core
             * @classdesc Represents a ManagerLoginResponse.
             * @implements IManagerLoginResponse
             * @constructor
             * @param {api.core.IManagerLoginResponse=} [properties] Properties to set
             */
            function ManagerLoginResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ManagerLoginResponse token.
             * @member {string} token
             * @memberof api.core.ManagerLoginResponse
             * @instance
             */
            ManagerLoginResponse.prototype.token = "";

            /**
             * ManagerLoginResponse items.
             * @member {api.common.IWorker|null|undefined} items
             * @memberof api.core.ManagerLoginResponse
             * @instance
             */
            ManagerLoginResponse.prototype.items = null;

            /**
             * Creates a new ManagerLoginResponse instance using the specified properties.
             * @function create
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {api.core.IManagerLoginResponse=} [properties] Properties to set
             * @returns {api.core.ManagerLoginResponse} ManagerLoginResponse instance
             */
            ManagerLoginResponse.create = function create(properties) {
                return new ManagerLoginResponse(properties);
            };

            /**
             * Encodes the specified ManagerLoginResponse message. Does not implicitly {@link api.core.ManagerLoginResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {api.core.IManagerLoginResponse} message ManagerLoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ManagerLoginResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                if (message.items != null && Object.hasOwnProperty.call(message, "items"))
                    $root.api.common.Worker.encode(message.items, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ManagerLoginResponse message, length delimited. Does not implicitly {@link api.core.ManagerLoginResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {api.core.IManagerLoginResponse} message ManagerLoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ManagerLoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ManagerLoginResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.ManagerLoginResponse} ManagerLoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ManagerLoginResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.ManagerLoginResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.token = reader.string();
                            break;
                        }
                    case 2: {
                            message.items = $root.api.common.Worker.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ManagerLoginResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.ManagerLoginResponse} ManagerLoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ManagerLoginResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ManagerLoginResponse message.
             * @function verify
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ManagerLoginResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    let error = $root.api.common.Worker.verify(message.items);
                    if (error)
                        return "items." + error;
                }
                return null;
            };

            /**
             * Creates a ManagerLoginResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.ManagerLoginResponse} ManagerLoginResponse
             */
            ManagerLoginResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.ManagerLoginResponse)
                    return object;
                let message = new $root.api.core.ManagerLoginResponse();
                if (object.token != null)
                    message.token = String(object.token);
                if (object.items != null) {
                    if (typeof object.items !== "object")
                        throw TypeError(".api.core.ManagerLoginResponse.items: object expected");
                    message.items = $root.api.common.Worker.fromObject(object.items);
                }
                return message;
            };

            /**
             * Creates a plain object from a ManagerLoginResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {api.core.ManagerLoginResponse} message ManagerLoginResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ManagerLoginResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.token = "";
                    object.items = null;
                }
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.items != null && message.hasOwnProperty("items"))
                    object.items = $root.api.common.Worker.toObject(message.items, options);
                return object;
            };

            /**
             * Converts this ManagerLoginResponse to JSON.
             * @function toJSON
             * @memberof api.core.ManagerLoginResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ManagerLoginResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ManagerLoginResponse
             * @function getTypeUrl
             * @memberof api.core.ManagerLoginResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ManagerLoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.ManagerLoginResponse";
            };

            return ManagerLoginResponse;
        })();

        core.TenantCreateRequest = (function() {

            /**
             * Properties of a TenantCreateRequest.
             * @memberof api.core
             * @interface ITenantCreateRequest
             * @property {string|null} [name] TenantCreateRequest name
             * @property {string|null} [account] TenantCreateRequest account
             * @property {string|null} [password] TenantCreateRequest password
             * @property {number|null} [workerCapacity] TenantCreateRequest workerCapacity
             * @property {number|null} [dailyServiceCapacity] TenantCreateRequest dailyServiceCapacity
             * @property {number|null} [cliqueId] TenantCreateRequest cliqueId
             */

            /**
             * Constructs a new TenantCreateRequest.
             * @memberof api.core
             * @classdesc Represents a TenantCreateRequest.
             * @implements ITenantCreateRequest
             * @constructor
             * @param {api.core.ITenantCreateRequest=} [properties] Properties to set
             */
            function TenantCreateRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantCreateRequest name.
             * @member {string} name
             * @memberof api.core.TenantCreateRequest
             * @instance
             */
            TenantCreateRequest.prototype.name = "";

            /**
             * TenantCreateRequest account.
             * @member {string} account
             * @memberof api.core.TenantCreateRequest
             * @instance
             */
            TenantCreateRequest.prototype.account = "";

            /**
             * TenantCreateRequest password.
             * @member {string} password
             * @memberof api.core.TenantCreateRequest
             * @instance
             */
            TenantCreateRequest.prototype.password = "";

            /**
             * TenantCreateRequest workerCapacity.
             * @member {number} workerCapacity
             * @memberof api.core.TenantCreateRequest
             * @instance
             */
            TenantCreateRequest.prototype.workerCapacity = 0;

            /**
             * TenantCreateRequest dailyServiceCapacity.
             * @member {number} dailyServiceCapacity
             * @memberof api.core.TenantCreateRequest
             * @instance
             */
            TenantCreateRequest.prototype.dailyServiceCapacity = 0;

            /**
             * TenantCreateRequest cliqueId.
             * @member {number} cliqueId
             * @memberof api.core.TenantCreateRequest
             * @instance
             */
            TenantCreateRequest.prototype.cliqueId = 0;

            /**
             * Creates a new TenantCreateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {api.core.ITenantCreateRequest=} [properties] Properties to set
             * @returns {api.core.TenantCreateRequest} TenantCreateRequest instance
             */
            TenantCreateRequest.create = function create(properties) {
                return new TenantCreateRequest(properties);
            };

            /**
             * Encodes the specified TenantCreateRequest message. Does not implicitly {@link api.core.TenantCreateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {api.core.ITenantCreateRequest} message TenantCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCreateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.account);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
                if (message.workerCapacity != null && Object.hasOwnProperty.call(message, "workerCapacity"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.workerCapacity);
                if (message.dailyServiceCapacity != null && Object.hasOwnProperty.call(message, "dailyServiceCapacity"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.dailyServiceCapacity);
                if (message.cliqueId != null && Object.hasOwnProperty.call(message, "cliqueId"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.cliqueId);
                return writer;
            };

            /**
             * Encodes the specified TenantCreateRequest message, length delimited. Does not implicitly {@link api.core.TenantCreateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {api.core.ITenantCreateRequest} message TenantCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCreateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantCreateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantCreateRequest} TenantCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCreateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantCreateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.account = reader.string();
                            break;
                        }
                    case 3: {
                            message.password = reader.string();
                            break;
                        }
                    case 4: {
                            message.workerCapacity = reader.int32();
                            break;
                        }
                    case 5: {
                            message.dailyServiceCapacity = reader.int32();
                            break;
                        }
                    case 6: {
                            message.cliqueId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantCreateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantCreateRequest} TenantCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCreateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantCreateRequest message.
             * @function verify
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantCreateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.account != null && message.hasOwnProperty("account"))
                    if (!$util.isString(message.account))
                        return "account: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.workerCapacity != null && message.hasOwnProperty("workerCapacity"))
                    if (!$util.isInteger(message.workerCapacity))
                        return "workerCapacity: integer expected";
                if (message.dailyServiceCapacity != null && message.hasOwnProperty("dailyServiceCapacity"))
                    if (!$util.isInteger(message.dailyServiceCapacity))
                        return "dailyServiceCapacity: integer expected";
                if (message.cliqueId != null && message.hasOwnProperty("cliqueId"))
                    if (!$util.isInteger(message.cliqueId))
                        return "cliqueId: integer expected";
                return null;
            };

            /**
             * Creates a TenantCreateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantCreateRequest} TenantCreateRequest
             */
            TenantCreateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantCreateRequest)
                    return object;
                let message = new $root.api.core.TenantCreateRequest();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.account != null)
                    message.account = String(object.account);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.workerCapacity != null)
                    message.workerCapacity = object.workerCapacity | 0;
                if (object.dailyServiceCapacity != null)
                    message.dailyServiceCapacity = object.dailyServiceCapacity | 0;
                if (object.cliqueId != null)
                    message.cliqueId = object.cliqueId | 0;
                return message;
            };

            /**
             * Creates a plain object from a TenantCreateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {api.core.TenantCreateRequest} message TenantCreateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantCreateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.account = "";
                    object.password = "";
                    object.workerCapacity = 0;
                    object.dailyServiceCapacity = 0;
                    object.cliqueId = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = message.account;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.workerCapacity != null && message.hasOwnProperty("workerCapacity"))
                    object.workerCapacity = message.workerCapacity;
                if (message.dailyServiceCapacity != null && message.hasOwnProperty("dailyServiceCapacity"))
                    object.dailyServiceCapacity = message.dailyServiceCapacity;
                if (message.cliqueId != null && message.hasOwnProperty("cliqueId"))
                    object.cliqueId = message.cliqueId;
                return object;
            };

            /**
             * Converts this TenantCreateRequest to JSON.
             * @function toJSON
             * @memberof api.core.TenantCreateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantCreateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantCreateRequest
             * @function getTypeUrl
             * @memberof api.core.TenantCreateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantCreateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantCreateRequest";
            };

            return TenantCreateRequest;
        })();

        core.TenantQueryRequest = (function() {

            /**
             * Properties of a TenantQueryRequest.
             * @memberof api.core
             * @interface ITenantQueryRequest
             * @property {api.common.IBatch|null} [batch] TenantQueryRequest batch
             * @property {number|null} [groupId] TenantQueryRequest groupId
             * @property {string|null} [keyword] TenantQueryRequest keyword
             */

            /**
             * Constructs a new TenantQueryRequest.
             * @memberof api.core
             * @classdesc Represents a TenantQueryRequest.
             * @implements ITenantQueryRequest
             * @constructor
             * @param {api.core.ITenantQueryRequest=} [properties] Properties to set
             */
            function TenantQueryRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantQueryRequest batch.
             * @member {api.common.IBatch|null|undefined} batch
             * @memberof api.core.TenantQueryRequest
             * @instance
             */
            TenantQueryRequest.prototype.batch = null;

            /**
             * TenantQueryRequest groupId.
             * @member {number} groupId
             * @memberof api.core.TenantQueryRequest
             * @instance
             */
            TenantQueryRequest.prototype.groupId = 0;

            /**
             * TenantQueryRequest keyword.
             * @member {string} keyword
             * @memberof api.core.TenantQueryRequest
             * @instance
             */
            TenantQueryRequest.prototype.keyword = "";

            /**
             * Creates a new TenantQueryRequest instance using the specified properties.
             * @function create
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {api.core.ITenantQueryRequest=} [properties] Properties to set
             * @returns {api.core.TenantQueryRequest} TenantQueryRequest instance
             */
            TenantQueryRequest.create = function create(properties) {
                return new TenantQueryRequest(properties);
            };

            /**
             * Encodes the specified TenantQueryRequest message. Does not implicitly {@link api.core.TenantQueryRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {api.core.ITenantQueryRequest} message TenantQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantQueryRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batch != null && Object.hasOwnProperty.call(message, "batch"))
                    $root.api.common.Batch.encode(message.batch, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.groupId);
                if (message.keyword != null && Object.hasOwnProperty.call(message, "keyword"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.keyword);
                return writer;
            };

            /**
             * Encodes the specified TenantQueryRequest message, length delimited. Does not implicitly {@link api.core.TenantQueryRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {api.core.ITenantQueryRequest} message TenantQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantQueryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantQueryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantQueryRequest} TenantQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantQueryRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantQueryRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.batch = $root.api.common.Batch.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.groupId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.keyword = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantQueryRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantQueryRequest} TenantQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantQueryRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantQueryRequest message.
             * @function verify
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantQueryRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.batch != null && message.hasOwnProperty("batch")) {
                    let error = $root.api.common.Batch.verify(message.batch);
                    if (error)
                        return "batch." + error;
                }
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId))
                        return "groupId: integer expected";
                if (message.keyword != null && message.hasOwnProperty("keyword"))
                    if (!$util.isString(message.keyword))
                        return "keyword: string expected";
                return null;
            };

            /**
             * Creates a TenantQueryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantQueryRequest} TenantQueryRequest
             */
            TenantQueryRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantQueryRequest)
                    return object;
                let message = new $root.api.core.TenantQueryRequest();
                if (object.batch != null) {
                    if (typeof object.batch !== "object")
                        throw TypeError(".api.core.TenantQueryRequest.batch: object expected");
                    message.batch = $root.api.common.Batch.fromObject(object.batch);
                }
                if (object.groupId != null)
                    message.groupId = object.groupId | 0;
                if (object.keyword != null)
                    message.keyword = String(object.keyword);
                return message;
            };

            /**
             * Creates a plain object from a TenantQueryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {api.core.TenantQueryRequest} message TenantQueryRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantQueryRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.batch = null;
                    object.groupId = 0;
                    object.keyword = "";
                }
                if (message.batch != null && message.hasOwnProperty("batch"))
                    object.batch = $root.api.common.Batch.toObject(message.batch, options);
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    object.groupId = message.groupId;
                if (message.keyword != null && message.hasOwnProperty("keyword"))
                    object.keyword = message.keyword;
                return object;
            };

            /**
             * Converts this TenantQueryRequest to JSON.
             * @function toJSON
             * @memberof api.core.TenantQueryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantQueryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantQueryRequest
             * @function getTypeUrl
             * @memberof api.core.TenantQueryRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantQueryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantQueryRequest";
            };

            return TenantQueryRequest;
        })();

        core.TenantQueryResponse = (function() {

            /**
             * Properties of a TenantQueryResponse.
             * @memberof api.core
             * @interface ITenantQueryResponse
             * @property {api.common.IBatch|null} [batch] TenantQueryResponse batch
             * @property {number|null} [groupId] TenantQueryResponse groupId
             * @property {string|null} [keyword] TenantQueryResponse keyword
             * @property {Array.<api.core.ITenantItem>|null} [tenantItem] TenantQueryResponse tenantItem
             * @property {number|null} [total] TenantQueryResponse total
             */

            /**
             * Constructs a new TenantQueryResponse.
             * @memberof api.core
             * @classdesc Represents a TenantQueryResponse.
             * @implements ITenantQueryResponse
             * @constructor
             * @param {api.core.ITenantQueryResponse=} [properties] Properties to set
             */
            function TenantQueryResponse(properties) {
                this.tenantItem = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantQueryResponse batch.
             * @member {api.common.IBatch|null|undefined} batch
             * @memberof api.core.TenantQueryResponse
             * @instance
             */
            TenantQueryResponse.prototype.batch = null;

            /**
             * TenantQueryResponse groupId.
             * @member {number} groupId
             * @memberof api.core.TenantQueryResponse
             * @instance
             */
            TenantQueryResponse.prototype.groupId = 0;

            /**
             * TenantQueryResponse keyword.
             * @member {string} keyword
             * @memberof api.core.TenantQueryResponse
             * @instance
             */
            TenantQueryResponse.prototype.keyword = "";

            /**
             * TenantQueryResponse tenantItem.
             * @member {Array.<api.core.ITenantItem>} tenantItem
             * @memberof api.core.TenantQueryResponse
             * @instance
             */
            TenantQueryResponse.prototype.tenantItem = $util.emptyArray;

            /**
             * TenantQueryResponse total.
             * @member {number} total
             * @memberof api.core.TenantQueryResponse
             * @instance
             */
            TenantQueryResponse.prototype.total = 0;

            /**
             * Creates a new TenantQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {api.core.ITenantQueryResponse=} [properties] Properties to set
             * @returns {api.core.TenantQueryResponse} TenantQueryResponse instance
             */
            TenantQueryResponse.create = function create(properties) {
                return new TenantQueryResponse(properties);
            };

            /**
             * Encodes the specified TenantQueryResponse message. Does not implicitly {@link api.core.TenantQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {api.core.ITenantQueryResponse} message TenantQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batch != null && Object.hasOwnProperty.call(message, "batch"))
                    $root.api.common.Batch.encode(message.batch, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.groupId);
                if (message.keyword != null && Object.hasOwnProperty.call(message, "keyword"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.keyword);
                if (message.tenantItem != null && message.tenantItem.length)
                    for (let i = 0; i < message.tenantItem.length; ++i)
                        $root.api.core.TenantItem.encode(message.tenantItem[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.total);
                return writer;
            };

            /**
             * Encodes the specified TenantQueryResponse message, length delimited. Does not implicitly {@link api.core.TenantQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {api.core.ITenantQueryResponse} message TenantQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantQueryResponse} TenantQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.batch = $root.api.common.Batch.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.groupId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.keyword = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.tenantItem && message.tenantItem.length))
                                message.tenantItem = [];
                            message.tenantItem.push($root.api.core.TenantItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            message.total = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantQueryResponse} TenantQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantQueryResponse message.
             * @function verify
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.batch != null && message.hasOwnProperty("batch")) {
                    let error = $root.api.common.Batch.verify(message.batch);
                    if (error)
                        return "batch." + error;
                }
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId))
                        return "groupId: integer expected";
                if (message.keyword != null && message.hasOwnProperty("keyword"))
                    if (!$util.isString(message.keyword))
                        return "keyword: string expected";
                if (message.tenantItem != null && message.hasOwnProperty("tenantItem")) {
                    if (!Array.isArray(message.tenantItem))
                        return "tenantItem: array expected";
                    for (let i = 0; i < message.tenantItem.length; ++i) {
                        let error = $root.api.core.TenantItem.verify(message.tenantItem[i]);
                        if (error)
                            return "tenantItem." + error;
                    }
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total))
                        return "total: integer expected";
                return null;
            };

            /**
             * Creates a TenantQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantQueryResponse} TenantQueryResponse
             */
            TenantQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantQueryResponse)
                    return object;
                let message = new $root.api.core.TenantQueryResponse();
                if (object.batch != null) {
                    if (typeof object.batch !== "object")
                        throw TypeError(".api.core.TenantQueryResponse.batch: object expected");
                    message.batch = $root.api.common.Batch.fromObject(object.batch);
                }
                if (object.groupId != null)
                    message.groupId = object.groupId | 0;
                if (object.keyword != null)
                    message.keyword = String(object.keyword);
                if (object.tenantItem) {
                    if (!Array.isArray(object.tenantItem))
                        throw TypeError(".api.core.TenantQueryResponse.tenantItem: array expected");
                    message.tenantItem = [];
                    for (let i = 0; i < object.tenantItem.length; ++i) {
                        if (typeof object.tenantItem[i] !== "object")
                            throw TypeError(".api.core.TenantQueryResponse.tenantItem: object expected");
                        message.tenantItem[i] = $root.api.core.TenantItem.fromObject(object.tenantItem[i]);
                    }
                }
                if (object.total != null)
                    message.total = object.total | 0;
                return message;
            };

            /**
             * Creates a plain object from a TenantQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {api.core.TenantQueryResponse} message TenantQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.tenantItem = [];
                if (options.defaults) {
                    object.batch = null;
                    object.groupId = 0;
                    object.keyword = "";
                    object.total = 0;
                }
                if (message.batch != null && message.hasOwnProperty("batch"))
                    object.batch = $root.api.common.Batch.toObject(message.batch, options);
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    object.groupId = message.groupId;
                if (message.keyword != null && message.hasOwnProperty("keyword"))
                    object.keyword = message.keyword;
                if (message.tenantItem && message.tenantItem.length) {
                    object.tenantItem = [];
                    for (let j = 0; j < message.tenantItem.length; ++j)
                        object.tenantItem[j] = $root.api.core.TenantItem.toObject(message.tenantItem[j], options);
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = message.total;
                return object;
            };

            /**
             * Converts this TenantQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.TenantQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantQueryResponse
             * @function getTypeUrl
             * @memberof api.core.TenantQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantQueryResponse";
            };

            return TenantQueryResponse;
        })();

        core.DeleteTenantRequest = (function() {

            /**
             * Properties of a DeleteTenantRequest.
             * @memberof api.core
             * @interface IDeleteTenantRequest
             * @property {number|null} [tenantId] DeleteTenantRequest tenantId
             * @property {string|null} [password] DeleteTenantRequest password
             */

            /**
             * Constructs a new DeleteTenantRequest.
             * @memberof api.core
             * @classdesc Represents a DeleteTenantRequest.
             * @implements IDeleteTenantRequest
             * @constructor
             * @param {api.core.IDeleteTenantRequest=} [properties] Properties to set
             */
            function DeleteTenantRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteTenantRequest tenantId.
             * @member {number} tenantId
             * @memberof api.core.DeleteTenantRequest
             * @instance
             */
            DeleteTenantRequest.prototype.tenantId = 0;

            /**
             * DeleteTenantRequest password.
             * @member {string} password
             * @memberof api.core.DeleteTenantRequest
             * @instance
             */
            DeleteTenantRequest.prototype.password = "";

            /**
             * Creates a new DeleteTenantRequest instance using the specified properties.
             * @function create
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {api.core.IDeleteTenantRequest=} [properties] Properties to set
             * @returns {api.core.DeleteTenantRequest} DeleteTenantRequest instance
             */
            DeleteTenantRequest.create = function create(properties) {
                return new DeleteTenantRequest(properties);
            };

            /**
             * Encodes the specified DeleteTenantRequest message. Does not implicitly {@link api.core.DeleteTenantRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {api.core.IDeleteTenantRequest} message DeleteTenantRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteTenantRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.tenantId);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                return writer;
            };

            /**
             * Encodes the specified DeleteTenantRequest message, length delimited. Does not implicitly {@link api.core.DeleteTenantRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {api.core.IDeleteTenantRequest} message DeleteTenantRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteTenantRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteTenantRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DeleteTenantRequest} DeleteTenantRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteTenantRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DeleteTenantRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tenantId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.password = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteTenantRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DeleteTenantRequest} DeleteTenantRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteTenantRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteTenantRequest message.
             * @function verify
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteTenantRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    if (!$util.isInteger(message.tenantId))
                        return "tenantId: integer expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                return null;
            };

            /**
             * Creates a DeleteTenantRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DeleteTenantRequest} DeleteTenantRequest
             */
            DeleteTenantRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DeleteTenantRequest)
                    return object;
                let message = new $root.api.core.DeleteTenantRequest();
                if (object.tenantId != null)
                    message.tenantId = object.tenantId >>> 0;
                if (object.password != null)
                    message.password = String(object.password);
                return message;
            };

            /**
             * Creates a plain object from a DeleteTenantRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {api.core.DeleteTenantRequest} message DeleteTenantRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteTenantRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.tenantId = 0;
                    object.password = "";
                }
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    object.tenantId = message.tenantId;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                return object;
            };

            /**
             * Converts this DeleteTenantRequest to JSON.
             * @function toJSON
             * @memberof api.core.DeleteTenantRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteTenantRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteTenantRequest
             * @function getTypeUrl
             * @memberof api.core.DeleteTenantRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteTenantRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DeleteTenantRequest";
            };

            return DeleteTenantRequest;
        })();

        core.TenantItem = (function() {

            /**
             * Properties of a TenantItem.
             * @memberof api.core
             * @interface ITenantItem
             * @property {number|null} [tenantId] TenantItem tenantId
             * @property {string|null} [tenantPublicKey] TenantItem tenantPublicKey
             * @property {string|null} [name] TenantItem name
             * @property {number|null} [workersCapacity] TenantItem workersCapacity
             * @property {number|null} [dailyServiceCapacity] TenantItem dailyServiceCapacity
             * @property {number|null} [entranceNum] TenantItem entranceNum
             * @property {string|null} [userName] TenantItem userName
             * @property {number|null} [averageMonthly] TenantItem averageMonthly
             * @property {number|null} [cumulativeVolume] TenantItem cumulativeVolume
             * @property {string|null} [password] TenantItem password
             * @property {google.protobuf.ITimestamp|null} [createAt] TenantItem createAt
             * @property {google.protobuf.ITimestamp|null} [deleteAt] TenantItem deleteAt
             * @property {string|null} [groupName] TenantItem groupName
             */

            /**
             * Constructs a new TenantItem.
             * @memberof api.core
             * @classdesc Represents a TenantItem.
             * @implements ITenantItem
             * @constructor
             * @param {api.core.ITenantItem=} [properties] Properties to set
             */
            function TenantItem(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantItem tenantId.
             * @member {number} tenantId
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.tenantId = 0;

            /**
             * TenantItem tenantPublicKey.
             * @member {string} tenantPublicKey
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.tenantPublicKey = "";

            /**
             * TenantItem name.
             * @member {string} name
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.name = "";

            /**
             * TenantItem workersCapacity.
             * @member {number} workersCapacity
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.workersCapacity = 0;

            /**
             * TenantItem dailyServiceCapacity.
             * @member {number} dailyServiceCapacity
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.dailyServiceCapacity = 0;

            /**
             * TenantItem entranceNum.
             * @member {number} entranceNum
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.entranceNum = 0;

            /**
             * TenantItem userName.
             * @member {string} userName
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.userName = "";

            /**
             * TenantItem averageMonthly.
             * @member {number} averageMonthly
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.averageMonthly = 0;

            /**
             * TenantItem cumulativeVolume.
             * @member {number} cumulativeVolume
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.cumulativeVolume = 0;

            /**
             * TenantItem password.
             * @member {string} password
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.password = "";

            /**
             * TenantItem createAt.
             * @member {google.protobuf.ITimestamp|null|undefined} createAt
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.createAt = null;

            /**
             * TenantItem deleteAt.
             * @member {google.protobuf.ITimestamp|null|undefined} deleteAt
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.deleteAt = null;

            /**
             * TenantItem groupName.
             * @member {string} groupName
             * @memberof api.core.TenantItem
             * @instance
             */
            TenantItem.prototype.groupName = "";

            /**
             * Creates a new TenantItem instance using the specified properties.
             * @function create
             * @memberof api.core.TenantItem
             * @static
             * @param {api.core.ITenantItem=} [properties] Properties to set
             * @returns {api.core.TenantItem} TenantItem instance
             */
            TenantItem.create = function create(properties) {
                return new TenantItem(properties);
            };

            /**
             * Encodes the specified TenantItem message. Does not implicitly {@link api.core.TenantItem.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantItem
             * @static
             * @param {api.core.ITenantItem} message TenantItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.tenantId);
                if (message.tenantPublicKey != null && Object.hasOwnProperty.call(message, "tenantPublicKey"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.tenantPublicKey);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.workersCapacity != null && Object.hasOwnProperty.call(message, "workersCapacity"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.workersCapacity);
                if (message.dailyServiceCapacity != null && Object.hasOwnProperty.call(message, "dailyServiceCapacity"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.dailyServiceCapacity);
                if (message.entranceNum != null && Object.hasOwnProperty.call(message, "entranceNum"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.entranceNum);
                if (message.userName != null && Object.hasOwnProperty.call(message, "userName"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.userName);
                if (message.averageMonthly != null && Object.hasOwnProperty.call(message, "averageMonthly"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.averageMonthly);
                if (message.cumulativeVolume != null && Object.hasOwnProperty.call(message, "cumulativeVolume"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.cumulativeVolume);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.password);
                if (message.createAt != null && Object.hasOwnProperty.call(message, "createAt"))
                    $root.google.protobuf.Timestamp.encode(message.createAt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.deleteAt != null && Object.hasOwnProperty.call(message, "deleteAt"))
                    $root.google.protobuf.Timestamp.encode(message.deleteAt, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.groupName);
                return writer;
            };

            /**
             * Encodes the specified TenantItem message, length delimited. Does not implicitly {@link api.core.TenantItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantItem
             * @static
             * @param {api.core.ITenantItem} message TenantItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantItem} TenantItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tenantId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.tenantPublicKey = reader.string();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.workersCapacity = reader.int32();
                            break;
                        }
                    case 5: {
                            message.dailyServiceCapacity = reader.int32();
                            break;
                        }
                    case 6: {
                            message.entranceNum = reader.int32();
                            break;
                        }
                    case 7: {
                            message.userName = reader.string();
                            break;
                        }
                    case 8: {
                            message.averageMonthly = reader.int32();
                            break;
                        }
                    case 9: {
                            message.cumulativeVolume = reader.int32();
                            break;
                        }
                    case 10: {
                            message.password = reader.string();
                            break;
                        }
                    case 11: {
                            message.createAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.deleteAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.groupName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantItem} TenantItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantItem message.
             * @function verify
             * @memberof api.core.TenantItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    if (!$util.isInteger(message.tenantId))
                        return "tenantId: integer expected";
                if (message.tenantPublicKey != null && message.hasOwnProperty("tenantPublicKey"))
                    if (!$util.isString(message.tenantPublicKey))
                        return "tenantPublicKey: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.workersCapacity != null && message.hasOwnProperty("workersCapacity"))
                    if (!$util.isInteger(message.workersCapacity))
                        return "workersCapacity: integer expected";
                if (message.dailyServiceCapacity != null && message.hasOwnProperty("dailyServiceCapacity"))
                    if (!$util.isInteger(message.dailyServiceCapacity))
                        return "dailyServiceCapacity: integer expected";
                if (message.entranceNum != null && message.hasOwnProperty("entranceNum"))
                    if (!$util.isInteger(message.entranceNum))
                        return "entranceNum: integer expected";
                if (message.userName != null && message.hasOwnProperty("userName"))
                    if (!$util.isString(message.userName))
                        return "userName: string expected";
                if (message.averageMonthly != null && message.hasOwnProperty("averageMonthly"))
                    if (!$util.isInteger(message.averageMonthly))
                        return "averageMonthly: integer expected";
                if (message.cumulativeVolume != null && message.hasOwnProperty("cumulativeVolume"))
                    if (!$util.isInteger(message.cumulativeVolume))
                        return "cumulativeVolume: integer expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.createAt != null && message.hasOwnProperty("createAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.createAt);
                    if (error)
                        return "createAt." + error;
                }
                if (message.deleteAt != null && message.hasOwnProperty("deleteAt")) {
                    let error = $root.google.protobuf.Timestamp.verify(message.deleteAt);
                    if (error)
                        return "deleteAt." + error;
                }
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    if (!$util.isString(message.groupName))
                        return "groupName: string expected";
                return null;
            };

            /**
             * Creates a TenantItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantItem} TenantItem
             */
            TenantItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantItem)
                    return object;
                let message = new $root.api.core.TenantItem();
                if (object.tenantId != null)
                    message.tenantId = object.tenantId >>> 0;
                if (object.tenantPublicKey != null)
                    message.tenantPublicKey = String(object.tenantPublicKey);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.workersCapacity != null)
                    message.workersCapacity = object.workersCapacity | 0;
                if (object.dailyServiceCapacity != null)
                    message.dailyServiceCapacity = object.dailyServiceCapacity | 0;
                if (object.entranceNum != null)
                    message.entranceNum = object.entranceNum | 0;
                if (object.userName != null)
                    message.userName = String(object.userName);
                if (object.averageMonthly != null)
                    message.averageMonthly = object.averageMonthly | 0;
                if (object.cumulativeVolume != null)
                    message.cumulativeVolume = object.cumulativeVolume | 0;
                if (object.password != null)
                    message.password = String(object.password);
                if (object.createAt != null) {
                    if (typeof object.createAt !== "object")
                        throw TypeError(".api.core.TenantItem.createAt: object expected");
                    message.createAt = $root.google.protobuf.Timestamp.fromObject(object.createAt);
                }
                if (object.deleteAt != null) {
                    if (typeof object.deleteAt !== "object")
                        throw TypeError(".api.core.TenantItem.deleteAt: object expected");
                    message.deleteAt = $root.google.protobuf.Timestamp.fromObject(object.deleteAt);
                }
                if (object.groupName != null)
                    message.groupName = String(object.groupName);
                return message;
            };

            /**
             * Creates a plain object from a TenantItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantItem
             * @static
             * @param {api.core.TenantItem} message TenantItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.tenantId = 0;
                    object.tenantPublicKey = "";
                    object.name = "";
                    object.workersCapacity = 0;
                    object.dailyServiceCapacity = 0;
                    object.entranceNum = 0;
                    object.userName = "";
                    object.averageMonthly = 0;
                    object.cumulativeVolume = 0;
                    object.password = "";
                    object.createAt = null;
                    object.deleteAt = null;
                    object.groupName = "";
                }
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    object.tenantId = message.tenantId;
                if (message.tenantPublicKey != null && message.hasOwnProperty("tenantPublicKey"))
                    object.tenantPublicKey = message.tenantPublicKey;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.workersCapacity != null && message.hasOwnProperty("workersCapacity"))
                    object.workersCapacity = message.workersCapacity;
                if (message.dailyServiceCapacity != null && message.hasOwnProperty("dailyServiceCapacity"))
                    object.dailyServiceCapacity = message.dailyServiceCapacity;
                if (message.entranceNum != null && message.hasOwnProperty("entranceNum"))
                    object.entranceNum = message.entranceNum;
                if (message.userName != null && message.hasOwnProperty("userName"))
                    object.userName = message.userName;
                if (message.averageMonthly != null && message.hasOwnProperty("averageMonthly"))
                    object.averageMonthly = message.averageMonthly;
                if (message.cumulativeVolume != null && message.hasOwnProperty("cumulativeVolume"))
                    object.cumulativeVolume = message.cumulativeVolume;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.createAt != null && message.hasOwnProperty("createAt"))
                    object.createAt = $root.google.protobuf.Timestamp.toObject(message.createAt, options);
                if (message.deleteAt != null && message.hasOwnProperty("deleteAt"))
                    object.deleteAt = $root.google.protobuf.Timestamp.toObject(message.deleteAt, options);
                if (message.groupName != null && message.hasOwnProperty("groupName"))
                    object.groupName = message.groupName;
                return object;
            };

            /**
             * Converts this TenantItem to JSON.
             * @function toJSON
             * @memberof api.core.TenantItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantItem
             * @function getTypeUrl
             * @memberof api.core.TenantItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantItem";
            };

            return TenantItem;
        })();

        core.AdminLoginRequest = (function() {

            /**
             * Properties of an AdminLoginRequest.
             * @memberof api.core
             * @interface IAdminLoginRequest
             * @property {string|null} [account] AdminLoginRequest account
             * @property {string|null} [password] AdminLoginRequest password
             */

            /**
             * Constructs a new AdminLoginRequest.
             * @memberof api.core
             * @classdesc Represents an AdminLoginRequest.
             * @implements IAdminLoginRequest
             * @constructor
             * @param {api.core.IAdminLoginRequest=} [properties] Properties to set
             */
            function AdminLoginRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AdminLoginRequest account.
             * @member {string} account
             * @memberof api.core.AdminLoginRequest
             * @instance
             */
            AdminLoginRequest.prototype.account = "";

            /**
             * AdminLoginRequest password.
             * @member {string} password
             * @memberof api.core.AdminLoginRequest
             * @instance
             */
            AdminLoginRequest.prototype.password = "";

            /**
             * Creates a new AdminLoginRequest instance using the specified properties.
             * @function create
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {api.core.IAdminLoginRequest=} [properties] Properties to set
             * @returns {api.core.AdminLoginRequest} AdminLoginRequest instance
             */
            AdminLoginRequest.create = function create(properties) {
                return new AdminLoginRequest(properties);
            };

            /**
             * Encodes the specified AdminLoginRequest message. Does not implicitly {@link api.core.AdminLoginRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {api.core.IAdminLoginRequest} message AdminLoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminLoginRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.account);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                return writer;
            };

            /**
             * Encodes the specified AdminLoginRequest message, length delimited. Does not implicitly {@link api.core.AdminLoginRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {api.core.IAdminLoginRequest} message AdminLoginRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminLoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AdminLoginRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AdminLoginRequest} AdminLoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminLoginRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AdminLoginRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.account = reader.string();
                            break;
                        }
                    case 2: {
                            message.password = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AdminLoginRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AdminLoginRequest} AdminLoginRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminLoginRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AdminLoginRequest message.
             * @function verify
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminLoginRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.account != null && message.hasOwnProperty("account"))
                    if (!$util.isString(message.account))
                        return "account: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                return null;
            };

            /**
             * Creates an AdminLoginRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AdminLoginRequest} AdminLoginRequest
             */
            AdminLoginRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AdminLoginRequest)
                    return object;
                let message = new $root.api.core.AdminLoginRequest();
                if (object.account != null)
                    message.account = String(object.account);
                if (object.password != null)
                    message.password = String(object.password);
                return message;
            };

            /**
             * Creates a plain object from an AdminLoginRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {api.core.AdminLoginRequest} message AdminLoginRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminLoginRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.account = "";
                    object.password = "";
                }
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = message.account;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                return object;
            };

            /**
             * Converts this AdminLoginRequest to JSON.
             * @function toJSON
             * @memberof api.core.AdminLoginRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminLoginRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AdminLoginRequest
             * @function getTypeUrl
             * @memberof api.core.AdminLoginRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AdminLoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AdminLoginRequest";
            };

            return AdminLoginRequest;
        })();

        core.AdminLoginResponse = (function() {

            /**
             * Properties of an AdminLoginResponse.
             * @memberof api.core
             * @interface IAdminLoginResponse
             * @property {string|null} [token] AdminLoginResponse token
             */

            /**
             * Constructs a new AdminLoginResponse.
             * @memberof api.core
             * @classdesc Represents an AdminLoginResponse.
             * @implements IAdminLoginResponse
             * @constructor
             * @param {api.core.IAdminLoginResponse=} [properties] Properties to set
             */
            function AdminLoginResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AdminLoginResponse token.
             * @member {string} token
             * @memberof api.core.AdminLoginResponse
             * @instance
             */
            AdminLoginResponse.prototype.token = "";

            /**
             * Creates a new AdminLoginResponse instance using the specified properties.
             * @function create
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {api.core.IAdminLoginResponse=} [properties] Properties to set
             * @returns {api.core.AdminLoginResponse} AdminLoginResponse instance
             */
            AdminLoginResponse.create = function create(properties) {
                return new AdminLoginResponse(properties);
            };

            /**
             * Encodes the specified AdminLoginResponse message. Does not implicitly {@link api.core.AdminLoginResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {api.core.IAdminLoginResponse} message AdminLoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminLoginResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                return writer;
            };

            /**
             * Encodes the specified AdminLoginResponse message, length delimited. Does not implicitly {@link api.core.AdminLoginResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {api.core.IAdminLoginResponse} message AdminLoginResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminLoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AdminLoginResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.AdminLoginResponse} AdminLoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminLoginResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.AdminLoginResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.token = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AdminLoginResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.AdminLoginResponse} AdminLoginResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminLoginResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AdminLoginResponse message.
             * @function verify
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminLoginResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };

            /**
             * Creates an AdminLoginResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.AdminLoginResponse} AdminLoginResponse
             */
            AdminLoginResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.AdminLoginResponse)
                    return object;
                let message = new $root.api.core.AdminLoginResponse();
                if (object.token != null)
                    message.token = String(object.token);
                return message;
            };

            /**
             * Creates a plain object from an AdminLoginResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {api.core.AdminLoginResponse} message AdminLoginResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminLoginResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.token = "";
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                return object;
            };

            /**
             * Converts this AdminLoginResponse to JSON.
             * @function toJSON
             * @memberof api.core.AdminLoginResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminLoginResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AdminLoginResponse
             * @function getTypeUrl
             * @memberof api.core.AdminLoginResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AdminLoginResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.AdminLoginResponse";
            };

            return AdminLoginResponse;
        })();

        core.UpdateTenantRequest = (function() {

            /**
             * Properties of an UpdateTenantRequest.
             * @memberof api.core
             * @interface IUpdateTenantRequest
             * @property {number|null} [tenantId] UpdateTenantRequest tenantId
             * @property {string|null} [name] UpdateTenantRequest name
             * @property {number|null} [workerCapacity] UpdateTenantRequest workerCapacity
             * @property {number|null} [dailyServiceCapacity] UpdateTenantRequest dailyServiceCapacity
             * @property {string|null} [password] UpdateTenantRequest password
             * @property {number|null} [cliqueId] UpdateTenantRequest cliqueId
             */

            /**
             * Constructs a new UpdateTenantRequest.
             * @memberof api.core
             * @classdesc Represents an UpdateTenantRequest.
             * @implements IUpdateTenantRequest
             * @constructor
             * @param {api.core.IUpdateTenantRequest=} [properties] Properties to set
             */
            function UpdateTenantRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateTenantRequest tenantId.
             * @member {number} tenantId
             * @memberof api.core.UpdateTenantRequest
             * @instance
             */
            UpdateTenantRequest.prototype.tenantId = 0;

            /**
             * UpdateTenantRequest name.
             * @member {string} name
             * @memberof api.core.UpdateTenantRequest
             * @instance
             */
            UpdateTenantRequest.prototype.name = "";

            /**
             * UpdateTenantRequest workerCapacity.
             * @member {number} workerCapacity
             * @memberof api.core.UpdateTenantRequest
             * @instance
             */
            UpdateTenantRequest.prototype.workerCapacity = 0;

            /**
             * UpdateTenantRequest dailyServiceCapacity.
             * @member {number} dailyServiceCapacity
             * @memberof api.core.UpdateTenantRequest
             * @instance
             */
            UpdateTenantRequest.prototype.dailyServiceCapacity = 0;

            /**
             * UpdateTenantRequest password.
             * @member {string} password
             * @memberof api.core.UpdateTenantRequest
             * @instance
             */
            UpdateTenantRequest.prototype.password = "";

            /**
             * UpdateTenantRequest cliqueId.
             * @member {number} cliqueId
             * @memberof api.core.UpdateTenantRequest
             * @instance
             */
            UpdateTenantRequest.prototype.cliqueId = 0;

            /**
             * Creates a new UpdateTenantRequest instance using the specified properties.
             * @function create
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {api.core.IUpdateTenantRequest=} [properties] Properties to set
             * @returns {api.core.UpdateTenantRequest} UpdateTenantRequest instance
             */
            UpdateTenantRequest.create = function create(properties) {
                return new UpdateTenantRequest(properties);
            };

            /**
             * Encodes the specified UpdateTenantRequest message. Does not implicitly {@link api.core.UpdateTenantRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {api.core.IUpdateTenantRequest} message UpdateTenantRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateTenantRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.tenantId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.workerCapacity != null && Object.hasOwnProperty.call(message, "workerCapacity"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.workerCapacity);
                if (message.dailyServiceCapacity != null && Object.hasOwnProperty.call(message, "dailyServiceCapacity"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dailyServiceCapacity);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.password);
                if (message.cliqueId != null && Object.hasOwnProperty.call(message, "cliqueId"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.cliqueId);
                return writer;
            };

            /**
             * Encodes the specified UpdateTenantRequest message, length delimited. Does not implicitly {@link api.core.UpdateTenantRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {api.core.IUpdateTenantRequest} message UpdateTenantRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateTenantRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateTenantRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.UpdateTenantRequest} UpdateTenantRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateTenantRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.UpdateTenantRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tenantId = reader.uint32();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.workerCapacity = reader.int32();
                            break;
                        }
                    case 4: {
                            message.dailyServiceCapacity = reader.int32();
                            break;
                        }
                    case 5: {
                            message.password = reader.string();
                            break;
                        }
                    case 6: {
                            message.cliqueId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateTenantRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.UpdateTenantRequest} UpdateTenantRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateTenantRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateTenantRequest message.
             * @function verify
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateTenantRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    if (!$util.isInteger(message.tenantId))
                        return "tenantId: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.workerCapacity != null && message.hasOwnProperty("workerCapacity"))
                    if (!$util.isInteger(message.workerCapacity))
                        return "workerCapacity: integer expected";
                if (message.dailyServiceCapacity != null && message.hasOwnProperty("dailyServiceCapacity"))
                    if (!$util.isInteger(message.dailyServiceCapacity))
                        return "dailyServiceCapacity: integer expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.cliqueId != null && message.hasOwnProperty("cliqueId"))
                    if (!$util.isInteger(message.cliqueId))
                        return "cliqueId: integer expected";
                return null;
            };

            /**
             * Creates an UpdateTenantRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.UpdateTenantRequest} UpdateTenantRequest
             */
            UpdateTenantRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.UpdateTenantRequest)
                    return object;
                let message = new $root.api.core.UpdateTenantRequest();
                if (object.tenantId != null)
                    message.tenantId = object.tenantId >>> 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.workerCapacity != null)
                    message.workerCapacity = object.workerCapacity | 0;
                if (object.dailyServiceCapacity != null)
                    message.dailyServiceCapacity = object.dailyServiceCapacity | 0;
                if (object.password != null)
                    message.password = String(object.password);
                if (object.cliqueId != null)
                    message.cliqueId = object.cliqueId | 0;
                return message;
            };

            /**
             * Creates a plain object from an UpdateTenantRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {api.core.UpdateTenantRequest} message UpdateTenantRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateTenantRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.tenantId = 0;
                    object.name = "";
                    object.workerCapacity = 0;
                    object.dailyServiceCapacity = 0;
                    object.password = "";
                    object.cliqueId = 0;
                }
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    object.tenantId = message.tenantId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.workerCapacity != null && message.hasOwnProperty("workerCapacity"))
                    object.workerCapacity = message.workerCapacity;
                if (message.dailyServiceCapacity != null && message.hasOwnProperty("dailyServiceCapacity"))
                    object.dailyServiceCapacity = message.dailyServiceCapacity;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.cliqueId != null && message.hasOwnProperty("cliqueId"))
                    object.cliqueId = message.cliqueId;
                return object;
            };

            /**
             * Converts this UpdateTenantRequest to JSON.
             * @function toJSON
             * @memberof api.core.UpdateTenantRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateTenantRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateTenantRequest
             * @function getTypeUrl
             * @memberof api.core.UpdateTenantRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateTenantRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.UpdateTenantRequest";
            };

            return UpdateTenantRequest;
        })();

        core.Tenant = (function() {

            /**
             * Constructs a new Tenant service.
             * @memberof api.core
             * @classdesc Represents a Tenant
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Tenant(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Tenant.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Tenant;

            /**
             * Creates new Tenant service using the specified rpc implementation.
             * @function create
             * @memberof api.core.Tenant
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Tenant} RPC service. Useful where requests and/or responses are streamed.
             */
            Tenant.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.Tenant#adminLogin}.
             * @memberof api.core.Tenant
             * @typedef AdminLoginCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.AdminLoginResponse} [response] AdminLoginResponse
             */

            /**
             * Calls AdminLogin.
             * @function adminLogin
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.IAdminLoginRequest} request AdminLoginRequest message or plain object
             * @param {api.core.Tenant.AdminLoginCallback} callback Node-style callback called with the error, if any, and AdminLoginResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tenant.prototype.adminLogin = function adminLogin(request, callback) {
                return this.rpcCall(adminLogin, $root.api.core.AdminLoginRequest, $root.api.core.AdminLoginResponse, request, callback);
            }, "name", { value: "AdminLogin" });

            /**
             * Calls AdminLogin.
             * @function adminLogin
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.IAdminLoginRequest} request AdminLoginRequest message or plain object
             * @returns {Promise<api.core.AdminLoginResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Tenant#login}.
             * @memberof api.core.Tenant
             * @typedef LoginCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.LoginResponse} [response] LoginResponse
             */

            /**
             * Calls Login.
             * @function login
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.ILoginRequest} request LoginRequest message or plain object
             * @param {api.core.Tenant.LoginCallback} callback Node-style callback called with the error, if any, and LoginResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tenant.prototype.login = function login(request, callback) {
                return this.rpcCall(login, $root.api.core.LoginRequest, $root.api.core.LoginResponse, request, callback);
            }, "name", { value: "Login" });

            /**
             * Calls Login.
             * @function login
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.ILoginRequest} request LoginRequest message or plain object
             * @returns {Promise<api.core.LoginResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Tenant#logout}.
             * @memberof api.core.Tenant
             * @typedef LogoutCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Logout.
             * @function logout
             * @memberof api.core.Tenant
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Tenant.LogoutCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tenant.prototype.logout = function logout(request, callback) {
                return this.rpcCall(logout, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Logout" });

            /**
             * Calls Logout.
             * @function logout
             * @memberof api.core.Tenant
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Tenant#create}.
             * @memberof api.core.Tenant
             * @typedef CreateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.ITenantCreateRequest} request TenantCreateRequest message or plain object
             * @param {api.core.Tenant.CreateCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tenant.prototype.create = function create(request, callback) {
                return this.rpcCall(create, $root.api.core.TenantCreateRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Create" });

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.ITenantCreateRequest} request TenantCreateRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Tenant#query}.
             * @memberof api.core.Tenant
             * @typedef QueryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.TenantQueryResponse} [response] TenantQueryResponse
             */

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.ITenantQueryRequest} request TenantQueryRequest message or plain object
             * @param {api.core.Tenant.QueryCallback} callback Node-style callback called with the error, if any, and TenantQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tenant.prototype.query = function query(request, callback) {
                return this.rpcCall(query, $root.api.core.TenantQueryRequest, $root.api.core.TenantQueryResponse, request, callback);
            }, "name", { value: "Query" });

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.ITenantQueryRequest} request TenantQueryRequest message or plain object
             * @returns {Promise<api.core.TenantQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Tenant#updateTenant}.
             * @memberof api.core.Tenant
             * @typedef UpdateTenantCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls UpdateTenant.
             * @function updateTenant
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.IUpdateTenantRequest} request UpdateTenantRequest message or plain object
             * @param {api.core.Tenant.UpdateTenantCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tenant.prototype.updateTenant = function updateTenant(request, callback) {
                return this.rpcCall(updateTenant, $root.api.core.UpdateTenantRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "UpdateTenant" });

            /**
             * Calls UpdateTenant.
             * @function updateTenant
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.IUpdateTenantRequest} request UpdateTenantRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Tenant#deleteTenant}.
             * @memberof api.core.Tenant
             * @typedef DeleteTenantCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls DeleteTenant.
             * @function deleteTenant
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.IDeleteTenantRequest} request DeleteTenantRequest message or plain object
             * @param {api.core.Tenant.DeleteTenantCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tenant.prototype.deleteTenant = function deleteTenant(request, callback) {
                return this.rpcCall(deleteTenant, $root.api.core.DeleteTenantRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "DeleteTenant" });

            /**
             * Calls DeleteTenant.
             * @function deleteTenant
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.IDeleteTenantRequest} request DeleteTenantRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Tenant#managerLogin}.
             * @memberof api.core.Tenant
             * @typedef ManagerLoginCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.ManagerLoginResponse} [response] ManagerLoginResponse
             */

            /**
             * Calls ManagerLogin.
             * @function managerLogin
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.IManagerLoginRequest} request ManagerLoginRequest message or plain object
             * @param {api.core.Tenant.ManagerLoginCallback} callback Node-style callback called with the error, if any, and ManagerLoginResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Tenant.prototype.managerLogin = function managerLogin(request, callback) {
                return this.rpcCall(managerLogin, $root.api.core.ManagerLoginRequest, $root.api.core.ManagerLoginResponse, request, callback);
            }, "name", { value: "ManagerLogin" });

            /**
             * Calls ManagerLogin.
             * @function managerLogin
             * @memberof api.core.Tenant
             * @instance
             * @param {api.core.IManagerLoginRequest} request ManagerLoginRequest message or plain object
             * @returns {Promise<api.core.ManagerLoginResponse>} Promise
             * @variation 2
             */

            return Tenant;
        })();

        core.TenantCliqueQueryResponse = (function() {

            /**
             * Properties of a TenantCliqueQueryResponse.
             * @memberof api.core
             * @interface ITenantCliqueQueryResponse
             * @property {Array.<api.common.ITenantClique>|null} [clique] TenantCliqueQueryResponse clique
             */

            /**
             * Constructs a new TenantCliqueQueryResponse.
             * @memberof api.core
             * @classdesc Represents a TenantCliqueQueryResponse.
             * @implements ITenantCliqueQueryResponse
             * @constructor
             * @param {api.core.ITenantCliqueQueryResponse=} [properties] Properties to set
             */
            function TenantCliqueQueryResponse(properties) {
                this.clique = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantCliqueQueryResponse clique.
             * @member {Array.<api.common.ITenantClique>} clique
             * @memberof api.core.TenantCliqueQueryResponse
             * @instance
             */
            TenantCliqueQueryResponse.prototype.clique = $util.emptyArray;

            /**
             * Creates a new TenantCliqueQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {api.core.ITenantCliqueQueryResponse=} [properties] Properties to set
             * @returns {api.core.TenantCliqueQueryResponse} TenantCliqueQueryResponse instance
             */
            TenantCliqueQueryResponse.create = function create(properties) {
                return new TenantCliqueQueryResponse(properties);
            };

            /**
             * Encodes the specified TenantCliqueQueryResponse message. Does not implicitly {@link api.core.TenantCliqueQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {api.core.ITenantCliqueQueryResponse} message TenantCliqueQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clique != null && message.clique.length)
                    for (let i = 0; i < message.clique.length; ++i)
                        $root.api.common.TenantClique.encode(message.clique[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TenantCliqueQueryResponse message, length delimited. Does not implicitly {@link api.core.TenantCliqueQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {api.core.ITenantCliqueQueryResponse} message TenantCliqueQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantCliqueQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantCliqueQueryResponse} TenantCliqueQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantCliqueQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.clique && message.clique.length))
                                message.clique = [];
                            message.clique.push($root.api.common.TenantClique.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantCliqueQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantCliqueQueryResponse} TenantCliqueQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantCliqueQueryResponse message.
             * @function verify
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantCliqueQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clique != null && message.hasOwnProperty("clique")) {
                    if (!Array.isArray(message.clique))
                        return "clique: array expected";
                    for (let i = 0; i < message.clique.length; ++i) {
                        let error = $root.api.common.TenantClique.verify(message.clique[i]);
                        if (error)
                            return "clique." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TenantCliqueQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantCliqueQueryResponse} TenantCliqueQueryResponse
             */
            TenantCliqueQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantCliqueQueryResponse)
                    return object;
                let message = new $root.api.core.TenantCliqueQueryResponse();
                if (object.clique) {
                    if (!Array.isArray(object.clique))
                        throw TypeError(".api.core.TenantCliqueQueryResponse.clique: array expected");
                    message.clique = [];
                    for (let i = 0; i < object.clique.length; ++i) {
                        if (typeof object.clique[i] !== "object")
                            throw TypeError(".api.core.TenantCliqueQueryResponse.clique: object expected");
                        message.clique[i] = $root.api.common.TenantClique.fromObject(object.clique[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a TenantCliqueQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {api.core.TenantCliqueQueryResponse} message TenantCliqueQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantCliqueQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.clique = [];
                if (message.clique && message.clique.length) {
                    object.clique = [];
                    for (let j = 0; j < message.clique.length; ++j)
                        object.clique[j] = $root.api.common.TenantClique.toObject(message.clique[j], options);
                }
                return object;
            };

            /**
             * Converts this TenantCliqueQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.TenantCliqueQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantCliqueQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantCliqueQueryResponse
             * @function getTypeUrl
             * @memberof api.core.TenantCliqueQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantCliqueQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantCliqueQueryResponse";
            };

            return TenantCliqueQueryResponse;
        })();

        core.TenantCliqueCreateRequest = (function() {

            /**
             * Properties of a TenantCliqueCreateRequest.
             * @memberof api.core
             * @interface ITenantCliqueCreateRequest
             * @property {api.common.ITenantClique|null} [clique] TenantCliqueCreateRequest clique
             */

            /**
             * Constructs a new TenantCliqueCreateRequest.
             * @memberof api.core
             * @classdesc Represents a TenantCliqueCreateRequest.
             * @implements ITenantCliqueCreateRequest
             * @constructor
             * @param {api.core.ITenantCliqueCreateRequest=} [properties] Properties to set
             */
            function TenantCliqueCreateRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantCliqueCreateRequest clique.
             * @member {api.common.ITenantClique|null|undefined} clique
             * @memberof api.core.TenantCliqueCreateRequest
             * @instance
             */
            TenantCliqueCreateRequest.prototype.clique = null;

            /**
             * Creates a new TenantCliqueCreateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {api.core.ITenantCliqueCreateRequest=} [properties] Properties to set
             * @returns {api.core.TenantCliqueCreateRequest} TenantCliqueCreateRequest instance
             */
            TenantCliqueCreateRequest.create = function create(properties) {
                return new TenantCliqueCreateRequest(properties);
            };

            /**
             * Encodes the specified TenantCliqueCreateRequest message. Does not implicitly {@link api.core.TenantCliqueCreateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {api.core.ITenantCliqueCreateRequest} message TenantCliqueCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueCreateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clique != null && Object.hasOwnProperty.call(message, "clique"))
                    $root.api.common.TenantClique.encode(message.clique, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TenantCliqueCreateRequest message, length delimited. Does not implicitly {@link api.core.TenantCliqueCreateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {api.core.ITenantCliqueCreateRequest} message TenantCliqueCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueCreateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantCliqueCreateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantCliqueCreateRequest} TenantCliqueCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueCreateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantCliqueCreateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.clique = $root.api.common.TenantClique.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantCliqueCreateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantCliqueCreateRequest} TenantCliqueCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueCreateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantCliqueCreateRequest message.
             * @function verify
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantCliqueCreateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clique != null && message.hasOwnProperty("clique")) {
                    let error = $root.api.common.TenantClique.verify(message.clique);
                    if (error)
                        return "clique." + error;
                }
                return null;
            };

            /**
             * Creates a TenantCliqueCreateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantCliqueCreateRequest} TenantCliqueCreateRequest
             */
            TenantCliqueCreateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantCliqueCreateRequest)
                    return object;
                let message = new $root.api.core.TenantCliqueCreateRequest();
                if (object.clique != null) {
                    if (typeof object.clique !== "object")
                        throw TypeError(".api.core.TenantCliqueCreateRequest.clique: object expected");
                    message.clique = $root.api.common.TenantClique.fromObject(object.clique);
                }
                return message;
            };

            /**
             * Creates a plain object from a TenantCliqueCreateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {api.core.TenantCliqueCreateRequest} message TenantCliqueCreateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantCliqueCreateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.clique = null;
                if (message.clique != null && message.hasOwnProperty("clique"))
                    object.clique = $root.api.common.TenantClique.toObject(message.clique, options);
                return object;
            };

            /**
             * Converts this TenantCliqueCreateRequest to JSON.
             * @function toJSON
             * @memberof api.core.TenantCliqueCreateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantCliqueCreateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantCliqueCreateRequest
             * @function getTypeUrl
             * @memberof api.core.TenantCliqueCreateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantCliqueCreateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantCliqueCreateRequest";
            };

            return TenantCliqueCreateRequest;
        })();

        core.TenantCliqueCreateResponse = (function() {

            /**
             * Properties of a TenantCliqueCreateResponse.
             * @memberof api.core
             * @interface ITenantCliqueCreateResponse
             * @property {api.common.ITenantClique|null} [clique] TenantCliqueCreateResponse clique
             */

            /**
             * Constructs a new TenantCliqueCreateResponse.
             * @memberof api.core
             * @classdesc Represents a TenantCliqueCreateResponse.
             * @implements ITenantCliqueCreateResponse
             * @constructor
             * @param {api.core.ITenantCliqueCreateResponse=} [properties] Properties to set
             */
            function TenantCliqueCreateResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantCliqueCreateResponse clique.
             * @member {api.common.ITenantClique|null|undefined} clique
             * @memberof api.core.TenantCliqueCreateResponse
             * @instance
             */
            TenantCliqueCreateResponse.prototype.clique = null;

            /**
             * Creates a new TenantCliqueCreateResponse instance using the specified properties.
             * @function create
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {api.core.ITenantCliqueCreateResponse=} [properties] Properties to set
             * @returns {api.core.TenantCliqueCreateResponse} TenantCliqueCreateResponse instance
             */
            TenantCliqueCreateResponse.create = function create(properties) {
                return new TenantCliqueCreateResponse(properties);
            };

            /**
             * Encodes the specified TenantCliqueCreateResponse message. Does not implicitly {@link api.core.TenantCliqueCreateResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {api.core.ITenantCliqueCreateResponse} message TenantCliqueCreateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueCreateResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clique != null && Object.hasOwnProperty.call(message, "clique"))
                    $root.api.common.TenantClique.encode(message.clique, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TenantCliqueCreateResponse message, length delimited. Does not implicitly {@link api.core.TenantCliqueCreateResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {api.core.ITenantCliqueCreateResponse} message TenantCliqueCreateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueCreateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantCliqueCreateResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantCliqueCreateResponse} TenantCliqueCreateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueCreateResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantCliqueCreateResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.clique = $root.api.common.TenantClique.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantCliqueCreateResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantCliqueCreateResponse} TenantCliqueCreateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueCreateResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantCliqueCreateResponse message.
             * @function verify
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantCliqueCreateResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clique != null && message.hasOwnProperty("clique")) {
                    let error = $root.api.common.TenantClique.verify(message.clique);
                    if (error)
                        return "clique." + error;
                }
                return null;
            };

            /**
             * Creates a TenantCliqueCreateResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantCliqueCreateResponse} TenantCliqueCreateResponse
             */
            TenantCliqueCreateResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantCliqueCreateResponse)
                    return object;
                let message = new $root.api.core.TenantCliqueCreateResponse();
                if (object.clique != null) {
                    if (typeof object.clique !== "object")
                        throw TypeError(".api.core.TenantCliqueCreateResponse.clique: object expected");
                    message.clique = $root.api.common.TenantClique.fromObject(object.clique);
                }
                return message;
            };

            /**
             * Creates a plain object from a TenantCliqueCreateResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {api.core.TenantCliqueCreateResponse} message TenantCliqueCreateResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantCliqueCreateResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.clique = null;
                if (message.clique != null && message.hasOwnProperty("clique"))
                    object.clique = $root.api.common.TenantClique.toObject(message.clique, options);
                return object;
            };

            /**
             * Converts this TenantCliqueCreateResponse to JSON.
             * @function toJSON
             * @memberof api.core.TenantCliqueCreateResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantCliqueCreateResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantCliqueCreateResponse
             * @function getTypeUrl
             * @memberof api.core.TenantCliqueCreateResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantCliqueCreateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantCliqueCreateResponse";
            };

            return TenantCliqueCreateResponse;
        })();

        core.TenantCliqueUpdateRequest = (function() {

            /**
             * Properties of a TenantCliqueUpdateRequest.
             * @memberof api.core
             * @interface ITenantCliqueUpdateRequest
             * @property {api.common.ITenantClique|null} [clique] TenantCliqueUpdateRequest clique
             */

            /**
             * Constructs a new TenantCliqueUpdateRequest.
             * @memberof api.core
             * @classdesc Represents a TenantCliqueUpdateRequest.
             * @implements ITenantCliqueUpdateRequest
             * @constructor
             * @param {api.core.ITenantCliqueUpdateRequest=} [properties] Properties to set
             */
            function TenantCliqueUpdateRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantCliqueUpdateRequest clique.
             * @member {api.common.ITenantClique|null|undefined} clique
             * @memberof api.core.TenantCliqueUpdateRequest
             * @instance
             */
            TenantCliqueUpdateRequest.prototype.clique = null;

            /**
             * Creates a new TenantCliqueUpdateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {api.core.ITenantCliqueUpdateRequest=} [properties] Properties to set
             * @returns {api.core.TenantCliqueUpdateRequest} TenantCliqueUpdateRequest instance
             */
            TenantCliqueUpdateRequest.create = function create(properties) {
                return new TenantCliqueUpdateRequest(properties);
            };

            /**
             * Encodes the specified TenantCliqueUpdateRequest message. Does not implicitly {@link api.core.TenantCliqueUpdateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {api.core.ITenantCliqueUpdateRequest} message TenantCliqueUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueUpdateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clique != null && Object.hasOwnProperty.call(message, "clique"))
                    $root.api.common.TenantClique.encode(message.clique, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TenantCliqueUpdateRequest message, length delimited. Does not implicitly {@link api.core.TenantCliqueUpdateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {api.core.ITenantCliqueUpdateRequest} message TenantCliqueUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantCliqueUpdateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantCliqueUpdateRequest} TenantCliqueUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueUpdateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantCliqueUpdateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.clique = $root.api.common.TenantClique.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantCliqueUpdateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantCliqueUpdateRequest} TenantCliqueUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantCliqueUpdateRequest message.
             * @function verify
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantCliqueUpdateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clique != null && message.hasOwnProperty("clique")) {
                    let error = $root.api.common.TenantClique.verify(message.clique);
                    if (error)
                        return "clique." + error;
                }
                return null;
            };

            /**
             * Creates a TenantCliqueUpdateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantCliqueUpdateRequest} TenantCliqueUpdateRequest
             */
            TenantCliqueUpdateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantCliqueUpdateRequest)
                    return object;
                let message = new $root.api.core.TenantCliqueUpdateRequest();
                if (object.clique != null) {
                    if (typeof object.clique !== "object")
                        throw TypeError(".api.core.TenantCliqueUpdateRequest.clique: object expected");
                    message.clique = $root.api.common.TenantClique.fromObject(object.clique);
                }
                return message;
            };

            /**
             * Creates a plain object from a TenantCliqueUpdateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {api.core.TenantCliqueUpdateRequest} message TenantCliqueUpdateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantCliqueUpdateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.clique = null;
                if (message.clique != null && message.hasOwnProperty("clique"))
                    object.clique = $root.api.common.TenantClique.toObject(message.clique, options);
                return object;
            };

            /**
             * Converts this TenantCliqueUpdateRequest to JSON.
             * @function toJSON
             * @memberof api.core.TenantCliqueUpdateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantCliqueUpdateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantCliqueUpdateRequest
             * @function getTypeUrl
             * @memberof api.core.TenantCliqueUpdateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantCliqueUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantCliqueUpdateRequest";
            };

            return TenantCliqueUpdateRequest;
        })();

        core.TenantCliqueDeleteRequest = (function() {

            /**
             * Properties of a TenantCliqueDeleteRequest.
             * @memberof api.core
             * @interface ITenantCliqueDeleteRequest
             * @property {number|null} [cliqueId] TenantCliqueDeleteRequest cliqueId
             */

            /**
             * Constructs a new TenantCliqueDeleteRequest.
             * @memberof api.core
             * @classdesc Represents a TenantCliqueDeleteRequest.
             * @implements ITenantCliqueDeleteRequest
             * @constructor
             * @param {api.core.ITenantCliqueDeleteRequest=} [properties] Properties to set
             */
            function TenantCliqueDeleteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TenantCliqueDeleteRequest cliqueId.
             * @member {number} cliqueId
             * @memberof api.core.TenantCliqueDeleteRequest
             * @instance
             */
            TenantCliqueDeleteRequest.prototype.cliqueId = 0;

            /**
             * Creates a new TenantCliqueDeleteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {api.core.ITenantCliqueDeleteRequest=} [properties] Properties to set
             * @returns {api.core.TenantCliqueDeleteRequest} TenantCliqueDeleteRequest instance
             */
            TenantCliqueDeleteRequest.create = function create(properties) {
                return new TenantCliqueDeleteRequest(properties);
            };

            /**
             * Encodes the specified TenantCliqueDeleteRequest message. Does not implicitly {@link api.core.TenantCliqueDeleteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {api.core.ITenantCliqueDeleteRequest} message TenantCliqueDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueDeleteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.cliqueId != null && Object.hasOwnProperty.call(message, "cliqueId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.cliqueId);
                return writer;
            };

            /**
             * Encodes the specified TenantCliqueDeleteRequest message, length delimited. Does not implicitly {@link api.core.TenantCliqueDeleteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {api.core.ITenantCliqueDeleteRequest} message TenantCliqueDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TenantCliqueDeleteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TenantCliqueDeleteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TenantCliqueDeleteRequest} TenantCliqueDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueDeleteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TenantCliqueDeleteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.cliqueId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TenantCliqueDeleteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TenantCliqueDeleteRequest} TenantCliqueDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TenantCliqueDeleteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TenantCliqueDeleteRequest message.
             * @function verify
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TenantCliqueDeleteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cliqueId != null && message.hasOwnProperty("cliqueId"))
                    if (!$util.isInteger(message.cliqueId))
                        return "cliqueId: integer expected";
                return null;
            };

            /**
             * Creates a TenantCliqueDeleteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TenantCliqueDeleteRequest} TenantCliqueDeleteRequest
             */
            TenantCliqueDeleteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TenantCliqueDeleteRequest)
                    return object;
                let message = new $root.api.core.TenantCliqueDeleteRequest();
                if (object.cliqueId != null)
                    message.cliqueId = object.cliqueId | 0;
                return message;
            };

            /**
             * Creates a plain object from a TenantCliqueDeleteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {api.core.TenantCliqueDeleteRequest} message TenantCliqueDeleteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TenantCliqueDeleteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.cliqueId = 0;
                if (message.cliqueId != null && message.hasOwnProperty("cliqueId"))
                    object.cliqueId = message.cliqueId;
                return object;
            };

            /**
             * Converts this TenantCliqueDeleteRequest to JSON.
             * @function toJSON
             * @memberof api.core.TenantCliqueDeleteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TenantCliqueDeleteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TenantCliqueDeleteRequest
             * @function getTypeUrl
             * @memberof api.core.TenantCliqueDeleteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TenantCliqueDeleteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TenantCliqueDeleteRequest";
            };

            return TenantCliqueDeleteRequest;
        })();

        core.TenantClique = (function() {

            /**
             * Constructs a new TenantClique service.
             * @memberof api.core
             * @classdesc Represents a TenantClique
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function TenantClique(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (TenantClique.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TenantClique;

            /**
             * Creates new TenantClique service using the specified rpc implementation.
             * @function create
             * @memberof api.core.TenantClique
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {TenantClique} RPC service. Useful where requests and/or responses are streamed.
             */
            TenantClique.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.TenantClique#query}.
             * @memberof api.core.TenantClique
             * @typedef QueryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.TenantCliqueQueryResponse} [response] TenantCliqueQueryResponse
             */

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.TenantClique
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.TenantClique.QueryCallback} callback Node-style callback called with the error, if any, and TenantCliqueQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(TenantClique.prototype.query = function query(request, callback) {
                return this.rpcCall(query, $root.google.protobuf.Empty, $root.api.core.TenantCliqueQueryResponse, request, callback);
            }, "name", { value: "Query" });

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.TenantClique
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.TenantCliqueQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.TenantClique#create}.
             * @memberof api.core.TenantClique
             * @typedef CreateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.TenantCliqueCreateResponse} [response] TenantCliqueCreateResponse
             */

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.TenantClique
             * @instance
             * @param {api.core.ITenantCliqueCreateRequest} request TenantCliqueCreateRequest message or plain object
             * @param {api.core.TenantClique.CreateCallback} callback Node-style callback called with the error, if any, and TenantCliqueCreateResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(TenantClique.prototype.create = function create(request, callback) {
                return this.rpcCall(create, $root.api.core.TenantCliqueCreateRequest, $root.api.core.TenantCliqueCreateResponse, request, callback);
            }, "name", { value: "Create" });

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.TenantClique
             * @instance
             * @param {api.core.ITenantCliqueCreateRequest} request TenantCliqueCreateRequest message or plain object
             * @returns {Promise<api.core.TenantCliqueCreateResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.TenantClique#update}.
             * @memberof api.core.TenantClique
             * @typedef UpdateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Update.
             * @function update
             * @memberof api.core.TenantClique
             * @instance
             * @param {api.core.ITenantCliqueUpdateRequest} request TenantCliqueUpdateRequest message or plain object
             * @param {api.core.TenantClique.UpdateCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(TenantClique.prototype.update = function update(request, callback) {
                return this.rpcCall(update, $root.api.core.TenantCliqueUpdateRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Update" });

            /**
             * Calls Update.
             * @function update
             * @memberof api.core.TenantClique
             * @instance
             * @param {api.core.ITenantCliqueUpdateRequest} request TenantCliqueUpdateRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.TenantClique#delete_}.
             * @memberof api.core.TenantClique
             * @typedef DeleteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Delete.
             * @function delete
             * @memberof api.core.TenantClique
             * @instance
             * @param {api.core.ITenantCliqueDeleteRequest} request TenantCliqueDeleteRequest message or plain object
             * @param {api.core.TenantClique.DeleteCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(TenantClique.prototype["delete"] = function delete_(request, callback) {
                return this.rpcCall(delete_, $root.api.core.TenantCliqueDeleteRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Delete" });

            /**
             * Calls Delete.
             * @function delete
             * @memberof api.core.TenantClique
             * @instance
             * @param {api.core.ITenantCliqueDeleteRequest} request TenantCliqueDeleteRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            return TenantClique;
        })();

        core.WorkerQueryRequest = (function() {

            /**
             * Properties of a WorkerQueryRequest.
             * @memberof api.core
             * @interface IWorkerQueryRequest
             * @property {api.common.IBatch|null} [batch] WorkerQueryRequest batch
             * @property {string|null} [name] WorkerQueryRequest name
             * @property {number|null} [queryType] WorkerQueryRequest queryType
             */

            /**
             * Constructs a new WorkerQueryRequest.
             * @memberof api.core
             * @classdesc Represents a WorkerQueryRequest.
             * @implements IWorkerQueryRequest
             * @constructor
             * @param {api.core.IWorkerQueryRequest=} [properties] Properties to set
             */
            function WorkerQueryRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerQueryRequest batch.
             * @member {api.common.IBatch|null|undefined} batch
             * @memberof api.core.WorkerQueryRequest
             * @instance
             */
            WorkerQueryRequest.prototype.batch = null;

            /**
             * WorkerQueryRequest name.
             * @member {string|null|undefined} name
             * @memberof api.core.WorkerQueryRequest
             * @instance
             */
            WorkerQueryRequest.prototype.name = null;

            /**
             * WorkerQueryRequest queryType.
             * @member {number|null|undefined} queryType
             * @memberof api.core.WorkerQueryRequest
             * @instance
             */
            WorkerQueryRequest.prototype.queryType = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * WorkerQueryRequest _name.
             * @member {"name"|undefined} _name
             * @memberof api.core.WorkerQueryRequest
             * @instance
             */
            Object.defineProperty(WorkerQueryRequest.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * WorkerQueryRequest _queryType.
             * @member {"queryType"|undefined} _queryType
             * @memberof api.core.WorkerQueryRequest
             * @instance
             */
            Object.defineProperty(WorkerQueryRequest.prototype, "_queryType", {
                get: $util.oneOfGetter($oneOfFields = ["queryType"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new WorkerQueryRequest instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {api.core.IWorkerQueryRequest=} [properties] Properties to set
             * @returns {api.core.WorkerQueryRequest} WorkerQueryRequest instance
             */
            WorkerQueryRequest.create = function create(properties) {
                return new WorkerQueryRequest(properties);
            };

            /**
             * Encodes the specified WorkerQueryRequest message. Does not implicitly {@link api.core.WorkerQueryRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {api.core.IWorkerQueryRequest} message WorkerQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerQueryRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.batch != null && Object.hasOwnProperty.call(message, "batch"))
                    $root.api.common.Batch.encode(message.batch, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.queryType != null && Object.hasOwnProperty.call(message, "queryType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.queryType);
                return writer;
            };

            /**
             * Encodes the specified WorkerQueryRequest message, length delimited. Does not implicitly {@link api.core.WorkerQueryRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {api.core.IWorkerQueryRequest} message WorkerQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerQueryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerQueryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerQueryRequest} WorkerQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerQueryRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerQueryRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.batch = $root.api.common.Batch.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.queryType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerQueryRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerQueryRequest} WorkerQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerQueryRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerQueryRequest message.
             * @function verify
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerQueryRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.batch != null && message.hasOwnProperty("batch")) {
                    let error = $root.api.common.Batch.verify(message.batch);
                    if (error)
                        return "batch." + error;
                }
                if (message.name != null && message.hasOwnProperty("name")) {
                    properties._name = 1;
                    if (!$util.isString(message.name))
                        return "name: string expected";
                }
                if (message.queryType != null && message.hasOwnProperty("queryType")) {
                    properties._queryType = 1;
                    if (!$util.isInteger(message.queryType))
                        return "queryType: integer expected";
                }
                return null;
            };

            /**
             * Creates a WorkerQueryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerQueryRequest} WorkerQueryRequest
             */
            WorkerQueryRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerQueryRequest)
                    return object;
                let message = new $root.api.core.WorkerQueryRequest();
                if (object.batch != null) {
                    if (typeof object.batch !== "object")
                        throw TypeError(".api.core.WorkerQueryRequest.batch: object expected");
                    message.batch = $root.api.common.Batch.fromObject(object.batch);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.queryType != null)
                    message.queryType = object.queryType | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerQueryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {api.core.WorkerQueryRequest} message WorkerQueryRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerQueryRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.batch = null;
                if (message.batch != null && message.hasOwnProperty("batch"))
                    object.batch = $root.api.common.Batch.toObject(message.batch, options);
                if (message.name != null && message.hasOwnProperty("name")) {
                    object.name = message.name;
                    if (options.oneofs)
                        object._name = "name";
                }
                if (message.queryType != null && message.hasOwnProperty("queryType")) {
                    object.queryType = message.queryType;
                    if (options.oneofs)
                        object._queryType = "queryType";
                }
                return object;
            };

            /**
             * Converts this WorkerQueryRequest to JSON.
             * @function toJSON
             * @memberof api.core.WorkerQueryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerQueryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerQueryRequest
             * @function getTypeUrl
             * @memberof api.core.WorkerQueryRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerQueryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerQueryRequest";
            };

            return WorkerQueryRequest;
        })();

        core.WorkerQueryResponse = (function() {

            /**
             * Properties of a WorkerQueryResponse.
             * @memberof api.core
             * @interface IWorkerQueryResponse
             * @property {Array.<api.common.IWorker>|null} [items] WorkerQueryResponse items
             * @property {api.common.IBatch|null} [batch] WorkerQueryResponse batch
             * @property {number|null} [total] WorkerQueryResponse total
             */

            /**
             * Constructs a new WorkerQueryResponse.
             * @memberof api.core
             * @classdesc Represents a WorkerQueryResponse.
             * @implements IWorkerQueryResponse
             * @constructor
             * @param {api.core.IWorkerQueryResponse=} [properties] Properties to set
             */
            function WorkerQueryResponse(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerQueryResponse items.
             * @member {Array.<api.common.IWorker>} items
             * @memberof api.core.WorkerQueryResponse
             * @instance
             */
            WorkerQueryResponse.prototype.items = $util.emptyArray;

            /**
             * WorkerQueryResponse batch.
             * @member {api.common.IBatch|null|undefined} batch
             * @memberof api.core.WorkerQueryResponse
             * @instance
             */
            WorkerQueryResponse.prototype.batch = null;

            /**
             * WorkerQueryResponse total.
             * @member {number} total
             * @memberof api.core.WorkerQueryResponse
             * @instance
             */
            WorkerQueryResponse.prototype.total = 0;

            /**
             * Creates a new WorkerQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {api.core.IWorkerQueryResponse=} [properties] Properties to set
             * @returns {api.core.WorkerQueryResponse} WorkerQueryResponse instance
             */
            WorkerQueryResponse.create = function create(properties) {
                return new WorkerQueryResponse(properties);
            };

            /**
             * Encodes the specified WorkerQueryResponse message. Does not implicitly {@link api.core.WorkerQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {api.core.IWorkerQueryResponse} message WorkerQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.common.Worker.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.batch != null && Object.hasOwnProperty.call(message, "batch"))
                    $root.api.common.Batch.encode(message.batch, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.total);
                return writer;
            };

            /**
             * Encodes the specified WorkerQueryResponse message, length delimited. Does not implicitly {@link api.core.WorkerQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {api.core.IWorkerQueryResponse} message WorkerQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerQueryResponse} WorkerQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.common.Worker.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.batch = $root.api.common.Batch.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.total = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerQueryResponse} WorkerQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerQueryResponse message.
             * @function verify
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.common.Worker.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.batch != null && message.hasOwnProperty("batch")) {
                    let error = $root.api.common.Batch.verify(message.batch);
                    if (error)
                        return "batch." + error;
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total))
                        return "total: integer expected";
                return null;
            };

            /**
             * Creates a WorkerQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerQueryResponse} WorkerQueryResponse
             */
            WorkerQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerQueryResponse)
                    return object;
                let message = new $root.api.core.WorkerQueryResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.core.WorkerQueryResponse.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.core.WorkerQueryResponse.items: object expected");
                        message.items[i] = $root.api.common.Worker.fromObject(object.items[i]);
                    }
                }
                if (object.batch != null) {
                    if (typeof object.batch !== "object")
                        throw TypeError(".api.core.WorkerQueryResponse.batch: object expected");
                    message.batch = $root.api.common.Batch.fromObject(object.batch);
                }
                if (object.total != null)
                    message.total = object.total | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {api.core.WorkerQueryResponse} message WorkerQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults) {
                    object.batch = null;
                    object.total = 0;
                }
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.common.Worker.toObject(message.items[j], options);
                }
                if (message.batch != null && message.hasOwnProperty("batch"))
                    object.batch = $root.api.common.Batch.toObject(message.batch, options);
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = message.total;
                return object;
            };

            /**
             * Converts this WorkerQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.WorkerQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerQueryResponse
             * @function getTypeUrl
             * @memberof api.core.WorkerQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerQueryResponse";
            };

            return WorkerQueryResponse;
        })();

        core.WorkerQuerySelfResponse = (function() {

            /**
             * Properties of a WorkerQuerySelfResponse.
             * @memberof api.core
             * @interface IWorkerQuerySelfResponse
             * @property {api.common.IWorker|null} [self] WorkerQuerySelfResponse self
             */

            /**
             * Constructs a new WorkerQuerySelfResponse.
             * @memberof api.core
             * @classdesc Represents a WorkerQuerySelfResponse.
             * @implements IWorkerQuerySelfResponse
             * @constructor
             * @param {api.core.IWorkerQuerySelfResponse=} [properties] Properties to set
             */
            function WorkerQuerySelfResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerQuerySelfResponse self.
             * @member {api.common.IWorker|null|undefined} self
             * @memberof api.core.WorkerQuerySelfResponse
             * @instance
             */
            WorkerQuerySelfResponse.prototype.self = null;

            /**
             * Creates a new WorkerQuerySelfResponse instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {api.core.IWorkerQuerySelfResponse=} [properties] Properties to set
             * @returns {api.core.WorkerQuerySelfResponse} WorkerQuerySelfResponse instance
             */
            WorkerQuerySelfResponse.create = function create(properties) {
                return new WorkerQuerySelfResponse(properties);
            };

            /**
             * Encodes the specified WorkerQuerySelfResponse message. Does not implicitly {@link api.core.WorkerQuerySelfResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {api.core.IWorkerQuerySelfResponse} message WorkerQuerySelfResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerQuerySelfResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.self != null && Object.hasOwnProperty.call(message, "self"))
                    $root.api.common.Worker.encode(message.self, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified WorkerQuerySelfResponse message, length delimited. Does not implicitly {@link api.core.WorkerQuerySelfResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {api.core.IWorkerQuerySelfResponse} message WorkerQuerySelfResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerQuerySelfResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerQuerySelfResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerQuerySelfResponse} WorkerQuerySelfResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerQuerySelfResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerQuerySelfResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.self = $root.api.common.Worker.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerQuerySelfResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerQuerySelfResponse} WorkerQuerySelfResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerQuerySelfResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerQuerySelfResponse message.
             * @function verify
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerQuerySelfResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.self != null && message.hasOwnProperty("self")) {
                    let error = $root.api.common.Worker.verify(message.self);
                    if (error)
                        return "self." + error;
                }
                return null;
            };

            /**
             * Creates a WorkerQuerySelfResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerQuerySelfResponse} WorkerQuerySelfResponse
             */
            WorkerQuerySelfResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerQuerySelfResponse)
                    return object;
                let message = new $root.api.core.WorkerQuerySelfResponse();
                if (object.self != null) {
                    if (typeof object.self !== "object")
                        throw TypeError(".api.core.WorkerQuerySelfResponse.self: object expected");
                    message.self = $root.api.common.Worker.fromObject(object.self);
                }
                return message;
            };

            /**
             * Creates a plain object from a WorkerQuerySelfResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {api.core.WorkerQuerySelfResponse} message WorkerQuerySelfResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerQuerySelfResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.self = null;
                if (message.self != null && message.hasOwnProperty("self"))
                    object.self = $root.api.common.Worker.toObject(message.self, options);
                return object;
            };

            /**
             * Converts this WorkerQuerySelfResponse to JSON.
             * @function toJSON
             * @memberof api.core.WorkerQuerySelfResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerQuerySelfResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerQuerySelfResponse
             * @function getTypeUrl
             * @memberof api.core.WorkerQuerySelfResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerQuerySelfResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerQuerySelfResponse";
            };

            return WorkerQuerySelfResponse;
        })();

        core.WorkerCreateRequest = (function() {

            /**
             * Properties of a WorkerCreateRequest.
             * @memberof api.core
             * @interface IWorkerCreateRequest
             * @property {string|null} [account] WorkerCreateRequest account
             * @property {string|null} [password] WorkerCreateRequest password
             * @property {Array.<Long>|null} [groupIds] WorkerCreateRequest groupIds
             * @property {number|null} [permMask] WorkerCreateRequest permMask
             * @property {string|null} [name] WorkerCreateRequest name
             * @property {string|null} [avatar] WorkerCreateRequest avatar
             */

            /**
             * Constructs a new WorkerCreateRequest.
             * @memberof api.core
             * @classdesc Represents a WorkerCreateRequest.
             * @implements IWorkerCreateRequest
             * @constructor
             * @param {api.core.IWorkerCreateRequest=} [properties] Properties to set
             */
            function WorkerCreateRequest(properties) {
                this.groupIds = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerCreateRequest account.
             * @member {string} account
             * @memberof api.core.WorkerCreateRequest
             * @instance
             */
            WorkerCreateRequest.prototype.account = "";

            /**
             * WorkerCreateRequest password.
             * @member {string} password
             * @memberof api.core.WorkerCreateRequest
             * @instance
             */
            WorkerCreateRequest.prototype.password = "";

            /**
             * WorkerCreateRequest groupIds.
             * @member {Array.<Long>} groupIds
             * @memberof api.core.WorkerCreateRequest
             * @instance
             */
            WorkerCreateRequest.prototype.groupIds = $util.emptyArray;

            /**
             * WorkerCreateRequest permMask.
             * @member {number} permMask
             * @memberof api.core.WorkerCreateRequest
             * @instance
             */
            WorkerCreateRequest.prototype.permMask = 0;

            /**
             * WorkerCreateRequest name.
             * @member {string} name
             * @memberof api.core.WorkerCreateRequest
             * @instance
             */
            WorkerCreateRequest.prototype.name = "";

            /**
             * WorkerCreateRequest avatar.
             * @member {string} avatar
             * @memberof api.core.WorkerCreateRequest
             * @instance
             */
            WorkerCreateRequest.prototype.avatar = "";

            /**
             * Creates a new WorkerCreateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {api.core.IWorkerCreateRequest=} [properties] Properties to set
             * @returns {api.core.WorkerCreateRequest} WorkerCreateRequest instance
             */
            WorkerCreateRequest.create = function create(properties) {
                return new WorkerCreateRequest(properties);
            };

            /**
             * Encodes the specified WorkerCreateRequest message. Does not implicitly {@link api.core.WorkerCreateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {api.core.IWorkerCreateRequest} message WorkerCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerCreateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.account);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                if (message.groupIds != null && message.groupIds.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (let i = 0; i < message.groupIds.length; ++i)
                        writer.int64(message.groupIds[i]);
                    writer.ldelim();
                }
                if (message.permMask != null && Object.hasOwnProperty.call(message, "permMask"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.permMask);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.avatar);
                return writer;
            };

            /**
             * Encodes the specified WorkerCreateRequest message, length delimited. Does not implicitly {@link api.core.WorkerCreateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {api.core.IWorkerCreateRequest} message WorkerCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerCreateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerCreateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerCreateRequest} WorkerCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerCreateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerCreateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.account = reader.string();
                            break;
                        }
                    case 2: {
                            message.password = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.groupIds && message.groupIds.length))
                                message.groupIds = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.groupIds.push(reader.int64());
                            } else
                                message.groupIds.push(reader.int64());
                            break;
                        }
                    case 4: {
                            message.permMask = reader.int32();
                            break;
                        }
                    case 6: {
                            message.name = reader.string();
                            break;
                        }
                    case 7: {
                            message.avatar = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerCreateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerCreateRequest} WorkerCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerCreateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerCreateRequest message.
             * @function verify
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerCreateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.account != null && message.hasOwnProperty("account"))
                    if (!$util.isString(message.account))
                        return "account: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.groupIds != null && message.hasOwnProperty("groupIds")) {
                    if (!Array.isArray(message.groupIds))
                        return "groupIds: array expected";
                    for (let i = 0; i < message.groupIds.length; ++i)
                        if (!$util.isInteger(message.groupIds[i]) && !(message.groupIds[i] && $util.isInteger(message.groupIds[i].low) && $util.isInteger(message.groupIds[i].high)))
                            return "groupIds: integer|Long[] expected";
                }
                if (message.permMask != null && message.hasOwnProperty("permMask"))
                    if (!$util.isInteger(message.permMask))
                        return "permMask: integer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                return null;
            };

            /**
             * Creates a WorkerCreateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerCreateRequest} WorkerCreateRequest
             */
            WorkerCreateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerCreateRequest)
                    return object;
                let message = new $root.api.core.WorkerCreateRequest();
                if (object.account != null)
                    message.account = String(object.account);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.groupIds) {
                    if (!Array.isArray(object.groupIds))
                        throw TypeError(".api.core.WorkerCreateRequest.groupIds: array expected");
                    message.groupIds = [];
                    for (let i = 0; i < object.groupIds.length; ++i)
                        if ($util.Long)
                            (message.groupIds[i] = $util.Long.fromValue(object.groupIds[i])).unsigned = false;
                        else if (typeof object.groupIds[i] === "string")
                            message.groupIds[i] = parseInt(object.groupIds[i], 10);
                        else if (typeof object.groupIds[i] === "number")
                            message.groupIds[i] = object.groupIds[i];
                        else if (typeof object.groupIds[i] === "object")
                            message.groupIds[i] = new $util.LongBits(object.groupIds[i].low >>> 0, object.groupIds[i].high >>> 0).toNumber();
                }
                if (object.permMask != null)
                    message.permMask = object.permMask | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                return message;
            };

            /**
             * Creates a plain object from a WorkerCreateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {api.core.WorkerCreateRequest} message WorkerCreateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerCreateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.groupIds = [];
                if (options.defaults) {
                    object.account = "";
                    object.password = "";
                    object.permMask = 0;
                    object.name = "";
                    object.avatar = "";
                }
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = message.account;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.groupIds && message.groupIds.length) {
                    object.groupIds = [];
                    for (let j = 0; j < message.groupIds.length; ++j)
                        if (typeof message.groupIds[j] === "number")
                            object.groupIds[j] = options.longs === String ? String(message.groupIds[j]) : message.groupIds[j];
                        else
                            object.groupIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.groupIds[j]) : options.longs === Number ? new $util.LongBits(message.groupIds[j].low >>> 0, message.groupIds[j].high >>> 0).toNumber() : message.groupIds[j];
                }
                if (message.permMask != null && message.hasOwnProperty("permMask"))
                    object.permMask = message.permMask;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.avatar != null && message.hasOwnProperty("avatar"))
                    object.avatar = message.avatar;
                return object;
            };

            /**
             * Converts this WorkerCreateRequest to JSON.
             * @function toJSON
             * @memberof api.core.WorkerCreateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerCreateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerCreateRequest
             * @function getTypeUrl
             * @memberof api.core.WorkerCreateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerCreateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerCreateRequest";
            };

            return WorkerCreateRequest;
        })();

        core.WorkerUpdateRequest = (function() {

            /**
             * Properties of a WorkerUpdateRequest.
             * @memberof api.core
             * @interface IWorkerUpdateRequest
             * @property {number|null} [workerId] WorkerUpdateRequest workerId
             * @property {string|null} [password] WorkerUpdateRequest password
             * @property {api.common.ISetInt64|null} [groupIds] WorkerUpdateRequest groupIds
             * @property {number|null} [permMask] WorkerUpdateRequest permMask
             * @property {string|null} [name] WorkerUpdateRequest name
             * @property {string|null} [avatar] WorkerUpdateRequest avatar
             */

            /**
             * Constructs a new WorkerUpdateRequest.
             * @memberof api.core
             * @classdesc Represents a WorkerUpdateRequest.
             * @implements IWorkerUpdateRequest
             * @constructor
             * @param {api.core.IWorkerUpdateRequest=} [properties] Properties to set
             */
            function WorkerUpdateRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerUpdateRequest workerId.
             * @member {number} workerId
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            WorkerUpdateRequest.prototype.workerId = 0;

            /**
             * WorkerUpdateRequest password.
             * @member {string|null|undefined} password
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            WorkerUpdateRequest.prototype.password = null;

            /**
             * WorkerUpdateRequest groupIds.
             * @member {api.common.ISetInt64|null|undefined} groupIds
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            WorkerUpdateRequest.prototype.groupIds = null;

            /**
             * WorkerUpdateRequest permMask.
             * @member {number|null|undefined} permMask
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            WorkerUpdateRequest.prototype.permMask = null;

            /**
             * WorkerUpdateRequest name.
             * @member {string|null|undefined} name
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            WorkerUpdateRequest.prototype.name = null;

            /**
             * WorkerUpdateRequest avatar.
             * @member {string|null|undefined} avatar
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            WorkerUpdateRequest.prototype.avatar = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * WorkerUpdateRequest _password.
             * @member {"password"|undefined} _password
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            Object.defineProperty(WorkerUpdateRequest.prototype, "_password", {
                get: $util.oneOfGetter($oneOfFields = ["password"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * WorkerUpdateRequest _groupIds.
             * @member {"groupIds"|undefined} _groupIds
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            Object.defineProperty(WorkerUpdateRequest.prototype, "_groupIds", {
                get: $util.oneOfGetter($oneOfFields = ["groupIds"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * WorkerUpdateRequest _permMask.
             * @member {"permMask"|undefined} _permMask
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            Object.defineProperty(WorkerUpdateRequest.prototype, "_permMask", {
                get: $util.oneOfGetter($oneOfFields = ["permMask"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * WorkerUpdateRequest _name.
             * @member {"name"|undefined} _name
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            Object.defineProperty(WorkerUpdateRequest.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * WorkerUpdateRequest _avatar.
             * @member {"avatar"|undefined} _avatar
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             */
            Object.defineProperty(WorkerUpdateRequest.prototype, "_avatar", {
                get: $util.oneOfGetter($oneOfFields = ["avatar"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new WorkerUpdateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {api.core.IWorkerUpdateRequest=} [properties] Properties to set
             * @returns {api.core.WorkerUpdateRequest} WorkerUpdateRequest instance
             */
            WorkerUpdateRequest.create = function create(properties) {
                return new WorkerUpdateRequest(properties);
            };

            /**
             * Encodes the specified WorkerUpdateRequest message. Does not implicitly {@link api.core.WorkerUpdateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {api.core.IWorkerUpdateRequest} message WorkerUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerUpdateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                if (message.groupIds != null && Object.hasOwnProperty.call(message, "groupIds"))
                    $root.api.common.SetInt64.encode(message.groupIds, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.permMask != null && Object.hasOwnProperty.call(message, "permMask"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.permMask);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
                if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.avatar);
                return writer;
            };

            /**
             * Encodes the specified WorkerUpdateRequest message, length delimited. Does not implicitly {@link api.core.WorkerUpdateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {api.core.IWorkerUpdateRequest} message WorkerUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerUpdateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerUpdateRequest} WorkerUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerUpdateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerUpdateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.password = reader.string();
                            break;
                        }
                    case 3: {
                            message.groupIds = $root.api.common.SetInt64.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.permMask = reader.int32();
                            break;
                        }
                    case 6: {
                            message.name = reader.string();
                            break;
                        }
                    case 7: {
                            message.avatar = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerUpdateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerUpdateRequest} WorkerUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerUpdateRequest message.
             * @function verify
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerUpdateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                if (message.password != null && message.hasOwnProperty("password")) {
                    properties._password = 1;
                    if (!$util.isString(message.password))
                        return "password: string expected";
                }
                if (message.groupIds != null && message.hasOwnProperty("groupIds")) {
                    properties._groupIds = 1;
                    {
                        let error = $root.api.common.SetInt64.verify(message.groupIds);
                        if (error)
                            return "groupIds." + error;
                    }
                }
                if (message.permMask != null && message.hasOwnProperty("permMask")) {
                    properties._permMask = 1;
                    if (!$util.isInteger(message.permMask))
                        return "permMask: integer expected";
                }
                if (message.name != null && message.hasOwnProperty("name")) {
                    properties._name = 1;
                    if (!$util.isString(message.name))
                        return "name: string expected";
                }
                if (message.avatar != null && message.hasOwnProperty("avatar")) {
                    properties._avatar = 1;
                    if (!$util.isString(message.avatar))
                        return "avatar: string expected";
                }
                return null;
            };

            /**
             * Creates a WorkerUpdateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerUpdateRequest} WorkerUpdateRequest
             */
            WorkerUpdateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerUpdateRequest)
                    return object;
                let message = new $root.api.core.WorkerUpdateRequest();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                if (object.password != null)
                    message.password = String(object.password);
                if (object.groupIds != null) {
                    if (typeof object.groupIds !== "object")
                        throw TypeError(".api.core.WorkerUpdateRequest.groupIds: object expected");
                    message.groupIds = $root.api.common.SetInt64.fromObject(object.groupIds);
                }
                if (object.permMask != null)
                    message.permMask = object.permMask | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.avatar != null)
                    message.avatar = String(object.avatar);
                return message;
            };

            /**
             * Creates a plain object from a WorkerUpdateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {api.core.WorkerUpdateRequest} message WorkerUpdateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerUpdateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.workerId = 0;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                if (message.password != null && message.hasOwnProperty("password")) {
                    object.password = message.password;
                    if (options.oneofs)
                        object._password = "password";
                }
                if (message.groupIds != null && message.hasOwnProperty("groupIds")) {
                    object.groupIds = $root.api.common.SetInt64.toObject(message.groupIds, options);
                    if (options.oneofs)
                        object._groupIds = "groupIds";
                }
                if (message.permMask != null && message.hasOwnProperty("permMask")) {
                    object.permMask = message.permMask;
                    if (options.oneofs)
                        object._permMask = "permMask";
                }
                if (message.name != null && message.hasOwnProperty("name")) {
                    object.name = message.name;
                    if (options.oneofs)
                        object._name = "name";
                }
                if (message.avatar != null && message.hasOwnProperty("avatar")) {
                    object.avatar = message.avatar;
                    if (options.oneofs)
                        object._avatar = "avatar";
                }
                return object;
            };

            /**
             * Converts this WorkerUpdateRequest to JSON.
             * @function toJSON
             * @memberof api.core.WorkerUpdateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerUpdateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerUpdateRequest
             * @function getTypeUrl
             * @memberof api.core.WorkerUpdateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerUpdateRequest";
            };

            return WorkerUpdateRequest;
        })();

        core.WorkerSession = (function() {

            /**
             * Properties of a WorkerSession.
             * @memberof api.core
             * @interface IWorkerSession
             * @property {number|null} [workerId] WorkerSession workerId
             * @property {number|null} [sessionId] WorkerSession sessionId
             * @property {api.common.ConnectState|null} [connectState] WorkerSession connectState
             * @property {api.common.OnlineState|null} [onlineState] WorkerSession onlineState
             */

            /**
             * Constructs a new WorkerSession.
             * @memberof api.core
             * @classdesc Represents a WorkerSession.
             * @implements IWorkerSession
             * @constructor
             * @param {api.core.IWorkerSession=} [properties] Properties to set
             */
            function WorkerSession(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerSession workerId.
             * @member {number} workerId
             * @memberof api.core.WorkerSession
             * @instance
             */
            WorkerSession.prototype.workerId = 0;

            /**
             * WorkerSession sessionId.
             * @member {number} sessionId
             * @memberof api.core.WorkerSession
             * @instance
             */
            WorkerSession.prototype.sessionId = 0;

            /**
             * WorkerSession connectState.
             * @member {api.common.ConnectState} connectState
             * @memberof api.core.WorkerSession
             * @instance
             */
            WorkerSession.prototype.connectState = 0;

            /**
             * WorkerSession onlineState.
             * @member {api.common.OnlineState} onlineState
             * @memberof api.core.WorkerSession
             * @instance
             */
            WorkerSession.prototype.onlineState = 0;

            /**
             * Creates a new WorkerSession instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerSession
             * @static
             * @param {api.core.IWorkerSession=} [properties] Properties to set
             * @returns {api.core.WorkerSession} WorkerSession instance
             */
            WorkerSession.create = function create(properties) {
                return new WorkerSession(properties);
            };

            /**
             * Encodes the specified WorkerSession message. Does not implicitly {@link api.core.WorkerSession.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerSession
             * @static
             * @param {api.core.IWorkerSession} message WorkerSession message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerSession.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.sessionId);
                if (message.connectState != null && Object.hasOwnProperty.call(message, "connectState"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.connectState);
                if (message.onlineState != null && Object.hasOwnProperty.call(message, "onlineState"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.onlineState);
                return writer;
            };

            /**
             * Encodes the specified WorkerSession message, length delimited. Does not implicitly {@link api.core.WorkerSession.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerSession
             * @static
             * @param {api.core.IWorkerSession} message WorkerSession message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerSession.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerSession message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerSession
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerSession} WorkerSession
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerSession.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerSession();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.sessionId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.connectState = reader.int32();
                            break;
                        }
                    case 4: {
                            message.onlineState = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerSession message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerSession
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerSession} WorkerSession
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerSession.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerSession message.
             * @function verify
             * @memberof api.core.WorkerSession
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerSession.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (!$util.isInteger(message.sessionId))
                        return "sessionId: integer expected";
                if (message.connectState != null && message.hasOwnProperty("connectState"))
                    switch (message.connectState) {
                    default:
                        return "connectState: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.onlineState != null && message.hasOwnProperty("onlineState"))
                    switch (message.onlineState) {
                    default:
                        return "onlineState: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a WorkerSession message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerSession
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerSession} WorkerSession
             */
            WorkerSession.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerSession)
                    return object;
                let message = new $root.api.core.WorkerSession();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                if (object.sessionId != null)
                    message.sessionId = object.sessionId | 0;
                switch (object.connectState) {
                default:
                    if (typeof object.connectState === "number") {
                        message.connectState = object.connectState;
                        break;
                    }
                    break;
                case "CONNECT_STATE_OFFLINE":
                case 0:
                    message.connectState = 0;
                    break;
                case "CONNECT_STATE_ONLINE":
                case 1:
                    message.connectState = 1;
                    break;
                }
                switch (object.onlineState) {
                default:
                    if (typeof object.onlineState === "number") {
                        message.onlineState = object.onlineState;
                        break;
                    }
                    break;
                case "ONLINE_STATE_IDLE":
                case 0:
                    message.onlineState = 0;
                    break;
                case "ONLINE_STATE_BUSY":
                case 1:
                    message.onlineState = 1;
                    break;
                case "ONLINE_STATE_AFK":
                case 2:
                    message.onlineState = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a WorkerSession message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerSession
             * @static
             * @param {api.core.WorkerSession} message WorkerSession
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerSession.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.workerId = 0;
                    object.sessionId = 0;
                    object.connectState = options.enums === String ? "CONNECT_STATE_OFFLINE" : 0;
                    object.onlineState = options.enums === String ? "ONLINE_STATE_IDLE" : 0;
                }
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    object.sessionId = message.sessionId;
                if (message.connectState != null && message.hasOwnProperty("connectState"))
                    object.connectState = options.enums === String ? $root.api.common.ConnectState[message.connectState] === undefined ? message.connectState : $root.api.common.ConnectState[message.connectState] : message.connectState;
                if (message.onlineState != null && message.hasOwnProperty("onlineState"))
                    object.onlineState = options.enums === String ? $root.api.common.OnlineState[message.onlineState] === undefined ? message.onlineState : $root.api.common.OnlineState[message.onlineState] : message.onlineState;
                return object;
            };

            /**
             * Converts this WorkerSession to JSON.
             * @function toJSON
             * @memberof api.core.WorkerSession
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerSession.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerSession
             * @function getTypeUrl
             * @memberof api.core.WorkerSession
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerSession";
            };

            return WorkerSession;
        })();

        core.WorkerDeleteRequest = (function() {

            /**
             * Properties of a WorkerDeleteRequest.
             * @memberof api.core
             * @interface IWorkerDeleteRequest
             * @property {number|null} [workerId] WorkerDeleteRequest workerId
             */

            /**
             * Constructs a new WorkerDeleteRequest.
             * @memberof api.core
             * @classdesc Represents a WorkerDeleteRequest.
             * @implements IWorkerDeleteRequest
             * @constructor
             * @param {api.core.IWorkerDeleteRequest=} [properties] Properties to set
             */
            function WorkerDeleteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerDeleteRequest workerId.
             * @member {number} workerId
             * @memberof api.core.WorkerDeleteRequest
             * @instance
             */
            WorkerDeleteRequest.prototype.workerId = 0;

            /**
             * Creates a new WorkerDeleteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {api.core.IWorkerDeleteRequest=} [properties] Properties to set
             * @returns {api.core.WorkerDeleteRequest} WorkerDeleteRequest instance
             */
            WorkerDeleteRequest.create = function create(properties) {
                return new WorkerDeleteRequest(properties);
            };

            /**
             * Encodes the specified WorkerDeleteRequest message. Does not implicitly {@link api.core.WorkerDeleteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {api.core.IWorkerDeleteRequest} message WorkerDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerDeleteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified WorkerDeleteRequest message, length delimited. Does not implicitly {@link api.core.WorkerDeleteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {api.core.IWorkerDeleteRequest} message WorkerDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerDeleteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerDeleteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerDeleteRequest} WorkerDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerDeleteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerDeleteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerDeleteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerDeleteRequest} WorkerDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerDeleteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerDeleteRequest message.
             * @function verify
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerDeleteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a WorkerDeleteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerDeleteRequest} WorkerDeleteRequest
             */
            WorkerDeleteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerDeleteRequest)
                    return object;
                let message = new $root.api.core.WorkerDeleteRequest();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerDeleteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {api.core.WorkerDeleteRequest} message WorkerDeleteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerDeleteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.workerId = 0;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this WorkerDeleteRequest to JSON.
             * @function toJSON
             * @memberof api.core.WorkerDeleteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerDeleteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerDeleteRequest
             * @function getTypeUrl
             * @memberof api.core.WorkerDeleteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerDeleteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerDeleteRequest";
            };

            return WorkerDeleteRequest;
        })();

        core.WorkerTransferRequest = (function() {

            /**
             * Properties of a WorkerTransferRequest.
             * @memberof api.core
             * @interface IWorkerTransferRequest
             * @property {Long|null} [chatId] WorkerTransferRequest chatId
             * @property {number|null} [workerId] WorkerTransferRequest workerId
             */

            /**
             * Constructs a new WorkerTransferRequest.
             * @memberof api.core
             * @classdesc Represents a WorkerTransferRequest.
             * @implements IWorkerTransferRequest
             * @constructor
             * @param {api.core.IWorkerTransferRequest=} [properties] Properties to set
             */
            function WorkerTransferRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerTransferRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.WorkerTransferRequest
             * @instance
             */
            WorkerTransferRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * WorkerTransferRequest workerId.
             * @member {number} workerId
             * @memberof api.core.WorkerTransferRequest
             * @instance
             */
            WorkerTransferRequest.prototype.workerId = 0;

            /**
             * Creates a new WorkerTransferRequest instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {api.core.IWorkerTransferRequest=} [properties] Properties to set
             * @returns {api.core.WorkerTransferRequest} WorkerTransferRequest instance
             */
            WorkerTransferRequest.create = function create(properties) {
                return new WorkerTransferRequest(properties);
            };

            /**
             * Encodes the specified WorkerTransferRequest message. Does not implicitly {@link api.core.WorkerTransferRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {api.core.IWorkerTransferRequest} message WorkerTransferRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerTransferRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified WorkerTransferRequest message, length delimited. Does not implicitly {@link api.core.WorkerTransferRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {api.core.IWorkerTransferRequest} message WorkerTransferRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerTransferRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerTransferRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerTransferRequest} WorkerTransferRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerTransferRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerTransferRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerTransferRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerTransferRequest} WorkerTransferRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerTransferRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerTransferRequest message.
             * @function verify
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerTransferRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a WorkerTransferRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerTransferRequest} WorkerTransferRequest
             */
            WorkerTransferRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerTransferRequest)
                    return object;
                let message = new $root.api.core.WorkerTransferRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerTransferRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {api.core.WorkerTransferRequest} message WorkerTransferRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerTransferRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.workerId = 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this WorkerTransferRequest to JSON.
             * @function toJSON
             * @memberof api.core.WorkerTransferRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerTransferRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerTransferRequest
             * @function getTypeUrl
             * @memberof api.core.WorkerTransferRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerTransferRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerTransferRequest";
            };

            return WorkerTransferRequest;
        })();

        core.BlacklistApplyRequest = (function() {

            /**
             * Properties of a BlacklistApplyRequest.
             * @memberof api.core
             * @interface IBlacklistApplyRequest
             * @property {Long|null} [chatId] BlacklistApplyRequest chatId
             * @property {number|null} [workerId] BlacklistApplyRequest workerId
             */

            /**
             * Constructs a new BlacklistApplyRequest.
             * @memberof api.core
             * @classdesc Represents a BlacklistApplyRequest.
             * @implements IBlacklistApplyRequest
             * @constructor
             * @param {api.core.IBlacklistApplyRequest=} [properties] Properties to set
             */
            function BlacklistApplyRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BlacklistApplyRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.BlacklistApplyRequest
             * @instance
             */
            BlacklistApplyRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * BlacklistApplyRequest workerId.
             * @member {number} workerId
             * @memberof api.core.BlacklistApplyRequest
             * @instance
             */
            BlacklistApplyRequest.prototype.workerId = 0;

            /**
             * Creates a new BlacklistApplyRequest instance using the specified properties.
             * @function create
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {api.core.IBlacklistApplyRequest=} [properties] Properties to set
             * @returns {api.core.BlacklistApplyRequest} BlacklistApplyRequest instance
             */
            BlacklistApplyRequest.create = function create(properties) {
                return new BlacklistApplyRequest(properties);
            };

            /**
             * Encodes the specified BlacklistApplyRequest message. Does not implicitly {@link api.core.BlacklistApplyRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {api.core.IBlacklistApplyRequest} message BlacklistApplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlacklistApplyRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified BlacklistApplyRequest message, length delimited. Does not implicitly {@link api.core.BlacklistApplyRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {api.core.IBlacklistApplyRequest} message BlacklistApplyRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlacklistApplyRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BlacklistApplyRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.BlacklistApplyRequest} BlacklistApplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlacklistApplyRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.BlacklistApplyRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    case 2: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BlacklistApplyRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.BlacklistApplyRequest} BlacklistApplyRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlacklistApplyRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BlacklistApplyRequest message.
             * @function verify
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BlacklistApplyRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a BlacklistApplyRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.BlacklistApplyRequest} BlacklistApplyRequest
             */
            BlacklistApplyRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.BlacklistApplyRequest)
                    return object;
                let message = new $root.api.core.BlacklistApplyRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a BlacklistApplyRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {api.core.BlacklistApplyRequest} message BlacklistApplyRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BlacklistApplyRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                    object.workerId = 0;
                }
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this BlacklistApplyRequest to JSON.
             * @function toJSON
             * @memberof api.core.BlacklistApplyRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BlacklistApplyRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BlacklistApplyRequest
             * @function getTypeUrl
             * @memberof api.core.BlacklistApplyRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BlacklistApplyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.BlacklistApplyRequest";
            };

            return BlacklistApplyRequest;
        })();

        core.BlacklistConfirmRequest = (function() {

            /**
             * Properties of a BlacklistConfirmRequest.
             * @memberof api.core
             * @interface IBlacklistConfirmRequest
             * @property {Long|null} [chatId] BlacklistConfirmRequest chatId
             */

            /**
             * Constructs a new BlacklistConfirmRequest.
             * @memberof api.core
             * @classdesc Represents a BlacklistConfirmRequest.
             * @implements IBlacklistConfirmRequest
             * @constructor
             * @param {api.core.IBlacklistConfirmRequest=} [properties] Properties to set
             */
            function BlacklistConfirmRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BlacklistConfirmRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.BlacklistConfirmRequest
             * @instance
             */
            BlacklistConfirmRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new BlacklistConfirmRequest instance using the specified properties.
             * @function create
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {api.core.IBlacklistConfirmRequest=} [properties] Properties to set
             * @returns {api.core.BlacklistConfirmRequest} BlacklistConfirmRequest instance
             */
            BlacklistConfirmRequest.create = function create(properties) {
                return new BlacklistConfirmRequest(properties);
            };

            /**
             * Encodes the specified BlacklistConfirmRequest message. Does not implicitly {@link api.core.BlacklistConfirmRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {api.core.IBlacklistConfirmRequest} message BlacklistConfirmRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlacklistConfirmRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                return writer;
            };

            /**
             * Encodes the specified BlacklistConfirmRequest message, length delimited. Does not implicitly {@link api.core.BlacklistConfirmRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {api.core.IBlacklistConfirmRequest} message BlacklistConfirmRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlacklistConfirmRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BlacklistConfirmRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.BlacklistConfirmRequest} BlacklistConfirmRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlacklistConfirmRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.BlacklistConfirmRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BlacklistConfirmRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.BlacklistConfirmRequest} BlacklistConfirmRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlacklistConfirmRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BlacklistConfirmRequest message.
             * @function verify
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BlacklistConfirmRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a BlacklistConfirmRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.BlacklistConfirmRequest} BlacklistConfirmRequest
             */
            BlacklistConfirmRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.BlacklistConfirmRequest)
                    return object;
                let message = new $root.api.core.BlacklistConfirmRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a BlacklistConfirmRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {api.core.BlacklistConfirmRequest} message BlacklistConfirmRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BlacklistConfirmRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                return object;
            };

            /**
             * Converts this BlacklistConfirmRequest to JSON.
             * @function toJSON
             * @memberof api.core.BlacklistConfirmRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BlacklistConfirmRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BlacklistConfirmRequest
             * @function getTypeUrl
             * @memberof api.core.BlacklistConfirmRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BlacklistConfirmRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.BlacklistConfirmRequest";
            };

            return BlacklistConfirmRequest;
        })();

        core.BlacklistRecoverRequest = (function() {

            /**
             * Properties of a BlacklistRecoverRequest.
             * @memberof api.core
             * @interface IBlacklistRecoverRequest
             * @property {Long|null} [chatId] BlacklistRecoverRequest chatId
             */

            /**
             * Constructs a new BlacklistRecoverRequest.
             * @memberof api.core
             * @classdesc Represents a BlacklistRecoverRequest.
             * @implements IBlacklistRecoverRequest
             * @constructor
             * @param {api.core.IBlacklistRecoverRequest=} [properties] Properties to set
             */
            function BlacklistRecoverRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BlacklistRecoverRequest chatId.
             * @member {Long} chatId
             * @memberof api.core.BlacklistRecoverRequest
             * @instance
             */
            BlacklistRecoverRequest.prototype.chatId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new BlacklistRecoverRequest instance using the specified properties.
             * @function create
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {api.core.IBlacklistRecoverRequest=} [properties] Properties to set
             * @returns {api.core.BlacklistRecoverRequest} BlacklistRecoverRequest instance
             */
            BlacklistRecoverRequest.create = function create(properties) {
                return new BlacklistRecoverRequest(properties);
            };

            /**
             * Encodes the specified BlacklistRecoverRequest message. Does not implicitly {@link api.core.BlacklistRecoverRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {api.core.IBlacklistRecoverRequest} message BlacklistRecoverRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlacklistRecoverRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chatId != null && Object.hasOwnProperty.call(message, "chatId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.chatId);
                return writer;
            };

            /**
             * Encodes the specified BlacklistRecoverRequest message, length delimited. Does not implicitly {@link api.core.BlacklistRecoverRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {api.core.IBlacklistRecoverRequest} message BlacklistRecoverRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BlacklistRecoverRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BlacklistRecoverRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.BlacklistRecoverRequest} BlacklistRecoverRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlacklistRecoverRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.BlacklistRecoverRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.chatId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BlacklistRecoverRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.BlacklistRecoverRequest} BlacklistRecoverRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BlacklistRecoverRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BlacklistRecoverRequest message.
             * @function verify
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BlacklistRecoverRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (!$util.isInteger(message.chatId) && !(message.chatId && $util.isInteger(message.chatId.low) && $util.isInteger(message.chatId.high)))
                        return "chatId: integer|Long expected";
                return null;
            };

            /**
             * Creates a BlacklistRecoverRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.BlacklistRecoverRequest} BlacklistRecoverRequest
             */
            BlacklistRecoverRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.BlacklistRecoverRequest)
                    return object;
                let message = new $root.api.core.BlacklistRecoverRequest();
                if (object.chatId != null)
                    if ($util.Long)
                        (message.chatId = $util.Long.fromValue(object.chatId)).unsigned = false;
                    else if (typeof object.chatId === "string")
                        message.chatId = parseInt(object.chatId, 10);
                    else if (typeof object.chatId === "number")
                        message.chatId = object.chatId;
                    else if (typeof object.chatId === "object")
                        message.chatId = new $util.LongBits(object.chatId.low >>> 0, object.chatId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a BlacklistRecoverRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {api.core.BlacklistRecoverRequest} message BlacklistRecoverRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BlacklistRecoverRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.chatId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.chatId = options.longs === String ? "0" : 0;
                if (message.chatId != null && message.hasOwnProperty("chatId"))
                    if (typeof message.chatId === "number")
                        object.chatId = options.longs === String ? String(message.chatId) : message.chatId;
                    else
                        object.chatId = options.longs === String ? $util.Long.prototype.toString.call(message.chatId) : options.longs === Number ? new $util.LongBits(message.chatId.low >>> 0, message.chatId.high >>> 0).toNumber() : message.chatId;
                return object;
            };

            /**
             * Converts this BlacklistRecoverRequest to JSON.
             * @function toJSON
             * @memberof api.core.BlacklistRecoverRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BlacklistRecoverRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BlacklistRecoverRequest
             * @function getTypeUrl
             * @memberof api.core.BlacklistRecoverRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BlacklistRecoverRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.BlacklistRecoverRequest";
            };

            return BlacklistRecoverRequest;
        })();

        core.CheckWorkerStatusResponse = (function() {

            /**
             * Properties of a CheckWorkerStatusResponse.
             * @memberof api.core
             * @interface ICheckWorkerStatusResponse
             * @property {api.common.IWorker|null} [worker] CheckWorkerStatusResponse worker
             */

            /**
             * Constructs a new CheckWorkerStatusResponse.
             * @memberof api.core
             * @classdesc Represents a CheckWorkerStatusResponse.
             * @implements ICheckWorkerStatusResponse
             * @constructor
             * @param {api.core.ICheckWorkerStatusResponse=} [properties] Properties to set
             */
            function CheckWorkerStatusResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CheckWorkerStatusResponse worker.
             * @member {api.common.IWorker|null|undefined} worker
             * @memberof api.core.CheckWorkerStatusResponse
             * @instance
             */
            CheckWorkerStatusResponse.prototype.worker = null;

            /**
             * Creates a new CheckWorkerStatusResponse instance using the specified properties.
             * @function create
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {api.core.ICheckWorkerStatusResponse=} [properties] Properties to set
             * @returns {api.core.CheckWorkerStatusResponse} CheckWorkerStatusResponse instance
             */
            CheckWorkerStatusResponse.create = function create(properties) {
                return new CheckWorkerStatusResponse(properties);
            };

            /**
             * Encodes the specified CheckWorkerStatusResponse message. Does not implicitly {@link api.core.CheckWorkerStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {api.core.ICheckWorkerStatusResponse} message CheckWorkerStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckWorkerStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.worker != null && Object.hasOwnProperty.call(message, "worker"))
                    $root.api.common.Worker.encode(message.worker, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CheckWorkerStatusResponse message, length delimited. Does not implicitly {@link api.core.CheckWorkerStatusResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {api.core.ICheckWorkerStatusResponse} message CheckWorkerStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckWorkerStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckWorkerStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CheckWorkerStatusResponse} CheckWorkerStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckWorkerStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CheckWorkerStatusResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.worker = $root.api.common.Worker.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckWorkerStatusResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CheckWorkerStatusResponse} CheckWorkerStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckWorkerStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckWorkerStatusResponse message.
             * @function verify
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckWorkerStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.worker != null && message.hasOwnProperty("worker")) {
                    let error = $root.api.common.Worker.verify(message.worker);
                    if (error)
                        return "worker." + error;
                }
                return null;
            };

            /**
             * Creates a CheckWorkerStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CheckWorkerStatusResponse} CheckWorkerStatusResponse
             */
            CheckWorkerStatusResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CheckWorkerStatusResponse)
                    return object;
                let message = new $root.api.core.CheckWorkerStatusResponse();
                if (object.worker != null) {
                    if (typeof object.worker !== "object")
                        throw TypeError(".api.core.CheckWorkerStatusResponse.worker: object expected");
                    message.worker = $root.api.common.Worker.fromObject(object.worker);
                }
                return message;
            };

            /**
             * Creates a plain object from a CheckWorkerStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {api.core.CheckWorkerStatusResponse} message CheckWorkerStatusResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckWorkerStatusResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.worker = null;
                if (message.worker != null && message.hasOwnProperty("worker"))
                    object.worker = $root.api.common.Worker.toObject(message.worker, options);
                return object;
            };

            /**
             * Converts this CheckWorkerStatusResponse to JSON.
             * @function toJSON
             * @memberof api.core.CheckWorkerStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckWorkerStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckWorkerStatusResponse
             * @function getTypeUrl
             * @memberof api.core.CheckWorkerStatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckWorkerStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CheckWorkerStatusResponse";
            };

            return CheckWorkerStatusResponse;
        })();

        core.CheckWorkerStatusRequest = (function() {

            /**
             * Properties of a CheckWorkerStatusRequest.
             * @memberof api.core
             * @interface ICheckWorkerStatusRequest
             * @property {number|null} [consultId] CheckWorkerStatusRequest consultId
             */

            /**
             * Constructs a new CheckWorkerStatusRequest.
             * @memberof api.core
             * @classdesc Represents a CheckWorkerStatusRequest.
             * @implements ICheckWorkerStatusRequest
             * @constructor
             * @param {api.core.ICheckWorkerStatusRequest=} [properties] Properties to set
             */
            function CheckWorkerStatusRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CheckWorkerStatusRequest consultId.
             * @member {number} consultId
             * @memberof api.core.CheckWorkerStatusRequest
             * @instance
             */
            CheckWorkerStatusRequest.prototype.consultId = 0;

            /**
             * Creates a new CheckWorkerStatusRequest instance using the specified properties.
             * @function create
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {api.core.ICheckWorkerStatusRequest=} [properties] Properties to set
             * @returns {api.core.CheckWorkerStatusRequest} CheckWorkerStatusRequest instance
             */
            CheckWorkerStatusRequest.create = function create(properties) {
                return new CheckWorkerStatusRequest(properties);
            };

            /**
             * Encodes the specified CheckWorkerStatusRequest message. Does not implicitly {@link api.core.CheckWorkerStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {api.core.ICheckWorkerStatusRequest} message CheckWorkerStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckWorkerStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.consultId != null && Object.hasOwnProperty.call(message, "consultId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.consultId);
                return writer;
            };

            /**
             * Encodes the specified CheckWorkerStatusRequest message, length delimited. Does not implicitly {@link api.core.CheckWorkerStatusRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {api.core.ICheckWorkerStatusRequest} message CheckWorkerStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckWorkerStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckWorkerStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.CheckWorkerStatusRequest} CheckWorkerStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckWorkerStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.CheckWorkerStatusRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.consultId = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckWorkerStatusRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.CheckWorkerStatusRequest} CheckWorkerStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckWorkerStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckWorkerStatusRequest message.
             * @function verify
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckWorkerStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.consultId != null && message.hasOwnProperty("consultId"))
                    if (!$util.isInteger(message.consultId))
                        return "consultId: integer expected";
                return null;
            };

            /**
             * Creates a CheckWorkerStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.CheckWorkerStatusRequest} CheckWorkerStatusRequest
             */
            CheckWorkerStatusRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.CheckWorkerStatusRequest)
                    return object;
                let message = new $root.api.core.CheckWorkerStatusRequest();
                if (object.consultId != null)
                    message.consultId = object.consultId >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a CheckWorkerStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {api.core.CheckWorkerStatusRequest} message CheckWorkerStatusRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckWorkerStatusRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.consultId = 0;
                if (message.consultId != null && message.hasOwnProperty("consultId"))
                    object.consultId = message.consultId;
                return object;
            };

            /**
             * Converts this CheckWorkerStatusRequest to JSON.
             * @function toJSON
             * @memberof api.core.CheckWorkerStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckWorkerStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckWorkerStatusRequest
             * @function getTypeUrl
             * @memberof api.core.CheckWorkerStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckWorkerStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.CheckWorkerStatusRequest";
            };

            return CheckWorkerStatusRequest;
        })();

        core.GetWorkerStatusRequest = (function() {

            /**
             * Properties of a GetWorkerStatusRequest.
             * @memberof api.core
             * @interface IGetWorkerStatusRequest
             * @property {number|null} [workerId] GetWorkerStatusRequest workerId
             * @property {api.common.ConnectState|null} [connectState] GetWorkerStatusRequest connectState
             * @property {api.common.OnlineState|null} [onlineState] GetWorkerStatusRequest onlineState
             */

            /**
             * Constructs a new GetWorkerStatusRequest.
             * @memberof api.core
             * @classdesc Represents a GetWorkerStatusRequest.
             * @implements IGetWorkerStatusRequest
             * @constructor
             * @param {api.core.IGetWorkerStatusRequest=} [properties] Properties to set
             */
            function GetWorkerStatusRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetWorkerStatusRequest workerId.
             * @member {number} workerId
             * @memberof api.core.GetWorkerStatusRequest
             * @instance
             */
            GetWorkerStatusRequest.prototype.workerId = 0;

            /**
             * GetWorkerStatusRequest connectState.
             * @member {api.common.ConnectState|null|undefined} connectState
             * @memberof api.core.GetWorkerStatusRequest
             * @instance
             */
            GetWorkerStatusRequest.prototype.connectState = null;

            /**
             * GetWorkerStatusRequest onlineState.
             * @member {api.common.OnlineState|null|undefined} onlineState
             * @memberof api.core.GetWorkerStatusRequest
             * @instance
             */
            GetWorkerStatusRequest.prototype.onlineState = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * GetWorkerStatusRequest _connectState.
             * @member {"connectState"|undefined} _connectState
             * @memberof api.core.GetWorkerStatusRequest
             * @instance
             */
            Object.defineProperty(GetWorkerStatusRequest.prototype, "_connectState", {
                get: $util.oneOfGetter($oneOfFields = ["connectState"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * GetWorkerStatusRequest _onlineState.
             * @member {"onlineState"|undefined} _onlineState
             * @memberof api.core.GetWorkerStatusRequest
             * @instance
             */
            Object.defineProperty(GetWorkerStatusRequest.prototype, "_onlineState", {
                get: $util.oneOfGetter($oneOfFields = ["onlineState"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetWorkerStatusRequest instance using the specified properties.
             * @function create
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {api.core.IGetWorkerStatusRequest=} [properties] Properties to set
             * @returns {api.core.GetWorkerStatusRequest} GetWorkerStatusRequest instance
             */
            GetWorkerStatusRequest.create = function create(properties) {
                return new GetWorkerStatusRequest(properties);
            };

            /**
             * Encodes the specified GetWorkerStatusRequest message. Does not implicitly {@link api.core.GetWorkerStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {api.core.IGetWorkerStatusRequest} message GetWorkerStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWorkerStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.workerId);
                if (message.connectState != null && Object.hasOwnProperty.call(message, "connectState"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.connectState);
                if (message.onlineState != null && Object.hasOwnProperty.call(message, "onlineState"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.onlineState);
                return writer;
            };

            /**
             * Encodes the specified GetWorkerStatusRequest message, length delimited. Does not implicitly {@link api.core.GetWorkerStatusRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {api.core.IGetWorkerStatusRequest} message GetWorkerStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWorkerStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetWorkerStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.GetWorkerStatusRequest} GetWorkerStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWorkerStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.GetWorkerStatusRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.workerId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.connectState = reader.int32();
                            break;
                        }
                    case 3: {
                            message.onlineState = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetWorkerStatusRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.GetWorkerStatusRequest} GetWorkerStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWorkerStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetWorkerStatusRequest message.
             * @function verify
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetWorkerStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                if (message.connectState != null && message.hasOwnProperty("connectState")) {
                    properties._connectState = 1;
                    switch (message.connectState) {
                    default:
                        return "connectState: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                }
                if (message.onlineState != null && message.hasOwnProperty("onlineState")) {
                    properties._onlineState = 1;
                    switch (message.onlineState) {
                    default:
                        return "onlineState: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                }
                return null;
            };

            /**
             * Creates a GetWorkerStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.GetWorkerStatusRequest} GetWorkerStatusRequest
             */
            GetWorkerStatusRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.GetWorkerStatusRequest)
                    return object;
                let message = new $root.api.core.GetWorkerStatusRequest();
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                switch (object.connectState) {
                default:
                    if (typeof object.connectState === "number") {
                        message.connectState = object.connectState;
                        break;
                    }
                    break;
                case "CONNECT_STATE_OFFLINE":
                case 0:
                    message.connectState = 0;
                    break;
                case "CONNECT_STATE_ONLINE":
                case 1:
                    message.connectState = 1;
                    break;
                }
                switch (object.onlineState) {
                default:
                    if (typeof object.onlineState === "number") {
                        message.onlineState = object.onlineState;
                        break;
                    }
                    break;
                case "ONLINE_STATE_IDLE":
                case 0:
                    message.onlineState = 0;
                    break;
                case "ONLINE_STATE_BUSY":
                case 1:
                    message.onlineState = 1;
                    break;
                case "ONLINE_STATE_AFK":
                case 2:
                    message.onlineState = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetWorkerStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {api.core.GetWorkerStatusRequest} message GetWorkerStatusRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetWorkerStatusRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.workerId = 0;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                if (message.connectState != null && message.hasOwnProperty("connectState")) {
                    object.connectState = options.enums === String ? $root.api.common.ConnectState[message.connectState] === undefined ? message.connectState : $root.api.common.ConnectState[message.connectState] : message.connectState;
                    if (options.oneofs)
                        object._connectState = "connectState";
                }
                if (message.onlineState != null && message.hasOwnProperty("onlineState")) {
                    object.onlineState = options.enums === String ? $root.api.common.OnlineState[message.onlineState] === undefined ? message.onlineState : $root.api.common.OnlineState[message.onlineState] : message.onlineState;
                    if (options.oneofs)
                        object._onlineState = "onlineState";
                }
                return object;
            };

            /**
             * Converts this GetWorkerStatusRequest to JSON.
             * @function toJSON
             * @memberof api.core.GetWorkerStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetWorkerStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetWorkerStatusRequest
             * @function getTypeUrl
             * @memberof api.core.GetWorkerStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetWorkerStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.GetWorkerStatusRequest";
            };

            return GetWorkerStatusRequest;
        })();

        core.GetWorkerStatusResponse = (function() {

            /**
             * Properties of a GetWorkerStatusResponse.
             * @memberof api.core
             * @interface IGetWorkerStatusResponse
             * @property {Array.<api.core.IWorkerSession>|null} [workerSession] GetWorkerStatusResponse workerSession
             */

            /**
             * Constructs a new GetWorkerStatusResponse.
             * @memberof api.core
             * @classdesc Represents a GetWorkerStatusResponse.
             * @implements IGetWorkerStatusResponse
             * @constructor
             * @param {api.core.IGetWorkerStatusResponse=} [properties] Properties to set
             */
            function GetWorkerStatusResponse(properties) {
                this.workerSession = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetWorkerStatusResponse workerSession.
             * @member {Array.<api.core.IWorkerSession>} workerSession
             * @memberof api.core.GetWorkerStatusResponse
             * @instance
             */
            GetWorkerStatusResponse.prototype.workerSession = $util.emptyArray;

            /**
             * Creates a new GetWorkerStatusResponse instance using the specified properties.
             * @function create
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {api.core.IGetWorkerStatusResponse=} [properties] Properties to set
             * @returns {api.core.GetWorkerStatusResponse} GetWorkerStatusResponse instance
             */
            GetWorkerStatusResponse.create = function create(properties) {
                return new GetWorkerStatusResponse(properties);
            };

            /**
             * Encodes the specified GetWorkerStatusResponse message. Does not implicitly {@link api.core.GetWorkerStatusResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {api.core.IGetWorkerStatusResponse} message GetWorkerStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWorkerStatusResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workerSession != null && message.workerSession.length)
                    for (let i = 0; i < message.workerSession.length; ++i)
                        $root.api.core.WorkerSession.encode(message.workerSession[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GetWorkerStatusResponse message, length delimited. Does not implicitly {@link api.core.GetWorkerStatusResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {api.core.IGetWorkerStatusResponse} message GetWorkerStatusResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWorkerStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetWorkerStatusResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.GetWorkerStatusResponse} GetWorkerStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWorkerStatusResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.GetWorkerStatusResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.workerSession && message.workerSession.length))
                                message.workerSession = [];
                            message.workerSession.push($root.api.core.WorkerSession.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetWorkerStatusResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.GetWorkerStatusResponse} GetWorkerStatusResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWorkerStatusResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetWorkerStatusResponse message.
             * @function verify
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetWorkerStatusResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workerSession != null && message.hasOwnProperty("workerSession")) {
                    if (!Array.isArray(message.workerSession))
                        return "workerSession: array expected";
                    for (let i = 0; i < message.workerSession.length; ++i) {
                        let error = $root.api.core.WorkerSession.verify(message.workerSession[i]);
                        if (error)
                            return "workerSession." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetWorkerStatusResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.GetWorkerStatusResponse} GetWorkerStatusResponse
             */
            GetWorkerStatusResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.GetWorkerStatusResponse)
                    return object;
                let message = new $root.api.core.GetWorkerStatusResponse();
                if (object.workerSession) {
                    if (!Array.isArray(object.workerSession))
                        throw TypeError(".api.core.GetWorkerStatusResponse.workerSession: array expected");
                    message.workerSession = [];
                    for (let i = 0; i < object.workerSession.length; ++i) {
                        if (typeof object.workerSession[i] !== "object")
                            throw TypeError(".api.core.GetWorkerStatusResponse.workerSession: object expected");
                        message.workerSession[i] = $root.api.core.WorkerSession.fromObject(object.workerSession[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GetWorkerStatusResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {api.core.GetWorkerStatusResponse} message GetWorkerStatusResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetWorkerStatusResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.workerSession = [];
                if (message.workerSession && message.workerSession.length) {
                    object.workerSession = [];
                    for (let j = 0; j < message.workerSession.length; ++j)
                        object.workerSession[j] = $root.api.core.WorkerSession.toObject(message.workerSession[j], options);
                }
                return object;
            };

            /**
             * Converts this GetWorkerStatusResponse to JSON.
             * @function toJSON
             * @memberof api.core.GetWorkerStatusResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetWorkerStatusResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetWorkerStatusResponse
             * @function getTypeUrl
             * @memberof api.core.GetWorkerStatusResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetWorkerStatusResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.GetWorkerStatusResponse";
            };

            return GetWorkerStatusResponse;
        })();

        core.SetWorkerStatusRequest = (function() {

            /**
             * Properties of a SetWorkerStatusRequest.
             * @memberof api.core
             * @interface ISetWorkerStatusRequest
             * @property {api.common.OnlineState|null} [onlineState] SetWorkerStatusRequest onlineState
             */

            /**
             * Constructs a new SetWorkerStatusRequest.
             * @memberof api.core
             * @classdesc Represents a SetWorkerStatusRequest.
             * @implements ISetWorkerStatusRequest
             * @constructor
             * @param {api.core.ISetWorkerStatusRequest=} [properties] Properties to set
             */
            function SetWorkerStatusRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetWorkerStatusRequest onlineState.
             * @member {api.common.OnlineState} onlineState
             * @memberof api.core.SetWorkerStatusRequest
             * @instance
             */
            SetWorkerStatusRequest.prototype.onlineState = 0;

            /**
             * Creates a new SetWorkerStatusRequest instance using the specified properties.
             * @function create
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {api.core.ISetWorkerStatusRequest=} [properties] Properties to set
             * @returns {api.core.SetWorkerStatusRequest} SetWorkerStatusRequest instance
             */
            SetWorkerStatusRequest.create = function create(properties) {
                return new SetWorkerStatusRequest(properties);
            };

            /**
             * Encodes the specified SetWorkerStatusRequest message. Does not implicitly {@link api.core.SetWorkerStatusRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {api.core.ISetWorkerStatusRequest} message SetWorkerStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetWorkerStatusRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.onlineState != null && Object.hasOwnProperty.call(message, "onlineState"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.onlineState);
                return writer;
            };

            /**
             * Encodes the specified SetWorkerStatusRequest message, length delimited. Does not implicitly {@link api.core.SetWorkerStatusRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {api.core.ISetWorkerStatusRequest} message SetWorkerStatusRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetWorkerStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SetWorkerStatusRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.SetWorkerStatusRequest} SetWorkerStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetWorkerStatusRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.SetWorkerStatusRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.onlineState = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SetWorkerStatusRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.SetWorkerStatusRequest} SetWorkerStatusRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetWorkerStatusRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SetWorkerStatusRequest message.
             * @function verify
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetWorkerStatusRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.onlineState != null && message.hasOwnProperty("onlineState"))
                    switch (message.onlineState) {
                    default:
                        return "onlineState: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a SetWorkerStatusRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.SetWorkerStatusRequest} SetWorkerStatusRequest
             */
            SetWorkerStatusRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.SetWorkerStatusRequest)
                    return object;
                let message = new $root.api.core.SetWorkerStatusRequest();
                switch (object.onlineState) {
                default:
                    if (typeof object.onlineState === "number") {
                        message.onlineState = object.onlineState;
                        break;
                    }
                    break;
                case "ONLINE_STATE_IDLE":
                case 0:
                    message.onlineState = 0;
                    break;
                case "ONLINE_STATE_BUSY":
                case 1:
                    message.onlineState = 1;
                    break;
                case "ONLINE_STATE_AFK":
                case 2:
                    message.onlineState = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a SetWorkerStatusRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {api.core.SetWorkerStatusRequest} message SetWorkerStatusRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetWorkerStatusRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.onlineState = options.enums === String ? "ONLINE_STATE_IDLE" : 0;
                if (message.onlineState != null && message.hasOwnProperty("onlineState"))
                    object.onlineState = options.enums === String ? $root.api.common.OnlineState[message.onlineState] === undefined ? message.onlineState : $root.api.common.OnlineState[message.onlineState] : message.onlineState;
                return object;
            };

            /**
             * Converts this SetWorkerStatusRequest to JSON.
             * @function toJSON
             * @memberof api.core.SetWorkerStatusRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetWorkerStatusRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SetWorkerStatusRequest
             * @function getTypeUrl
             * @memberof api.core.SetWorkerStatusRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SetWorkerStatusRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.SetWorkerStatusRequest";
            };

            return SetWorkerStatusRequest;
        })();

        core.WorkerTransferResponse = (function() {

            /**
             * Properties of a WorkerTransferResponse.
             * @memberof api.core
             * @interface IWorkerTransferResponse
             * @property {api.common.IWorker|null} [worker] WorkerTransferResponse worker
             */

            /**
             * Constructs a new WorkerTransferResponse.
             * @memberof api.core
             * @classdesc Represents a WorkerTransferResponse.
             * @implements IWorkerTransferResponse
             * @constructor
             * @param {api.core.IWorkerTransferResponse=} [properties] Properties to set
             */
            function WorkerTransferResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerTransferResponse worker.
             * @member {api.common.IWorker|null|undefined} worker
             * @memberof api.core.WorkerTransferResponse
             * @instance
             */
            WorkerTransferResponse.prototype.worker = null;

            /**
             * Creates a new WorkerTransferResponse instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {api.core.IWorkerTransferResponse=} [properties] Properties to set
             * @returns {api.core.WorkerTransferResponse} WorkerTransferResponse instance
             */
            WorkerTransferResponse.create = function create(properties) {
                return new WorkerTransferResponse(properties);
            };

            /**
             * Encodes the specified WorkerTransferResponse message. Does not implicitly {@link api.core.WorkerTransferResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {api.core.IWorkerTransferResponse} message WorkerTransferResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerTransferResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.worker != null && Object.hasOwnProperty.call(message, "worker"))
                    $root.api.common.Worker.encode(message.worker, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified WorkerTransferResponse message, length delimited. Does not implicitly {@link api.core.WorkerTransferResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {api.core.IWorkerTransferResponse} message WorkerTransferResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerTransferResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerTransferResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerTransferResponse} WorkerTransferResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerTransferResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerTransferResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.worker = $root.api.common.Worker.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerTransferResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerTransferResponse} WorkerTransferResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerTransferResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerTransferResponse message.
             * @function verify
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerTransferResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.worker != null && message.hasOwnProperty("worker")) {
                    let error = $root.api.common.Worker.verify(message.worker);
                    if (error)
                        return "worker." + error;
                }
                return null;
            };

            /**
             * Creates a WorkerTransferResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerTransferResponse} WorkerTransferResponse
             */
            WorkerTransferResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerTransferResponse)
                    return object;
                let message = new $root.api.core.WorkerTransferResponse();
                if (object.worker != null) {
                    if (typeof object.worker !== "object")
                        throw TypeError(".api.core.WorkerTransferResponse.worker: object expected");
                    message.worker = $root.api.common.Worker.fromObject(object.worker);
                }
                return message;
            };

            /**
             * Creates a plain object from a WorkerTransferResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {api.core.WorkerTransferResponse} message WorkerTransferResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerTransferResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.worker = null;
                if (message.worker != null && message.hasOwnProperty("worker"))
                    object.worker = $root.api.common.Worker.toObject(message.worker, options);
                return object;
            };

            /**
             * Converts this WorkerTransferResponse to JSON.
             * @function toJSON
             * @memberof api.core.WorkerTransferResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerTransferResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerTransferResponse
             * @function getTypeUrl
             * @memberof api.core.WorkerTransferResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerTransferResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerTransferResponse";
            };

            return WorkerTransferResponse;
        })();

        core.PasswordRequest = (function() {

            /**
             * Properties of a PasswordRequest.
             * @memberof api.core
             * @interface IPasswordRequest
             * @property {string|null} [oldPassword] PasswordRequest oldPassword
             * @property {string|null} [newPassword] PasswordRequest newPassword
             */

            /**
             * Constructs a new PasswordRequest.
             * @memberof api.core
             * @classdesc Represents a PasswordRequest.
             * @implements IPasswordRequest
             * @constructor
             * @param {api.core.IPasswordRequest=} [properties] Properties to set
             */
            function PasswordRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PasswordRequest oldPassword.
             * @member {string} oldPassword
             * @memberof api.core.PasswordRequest
             * @instance
             */
            PasswordRequest.prototype.oldPassword = "";

            /**
             * PasswordRequest newPassword.
             * @member {string} newPassword
             * @memberof api.core.PasswordRequest
             * @instance
             */
            PasswordRequest.prototype.newPassword = "";

            /**
             * Creates a new PasswordRequest instance using the specified properties.
             * @function create
             * @memberof api.core.PasswordRequest
             * @static
             * @param {api.core.IPasswordRequest=} [properties] Properties to set
             * @returns {api.core.PasswordRequest} PasswordRequest instance
             */
            PasswordRequest.create = function create(properties) {
                return new PasswordRequest(properties);
            };

            /**
             * Encodes the specified PasswordRequest message. Does not implicitly {@link api.core.PasswordRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.PasswordRequest
             * @static
             * @param {api.core.IPasswordRequest} message PasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PasswordRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.oldPassword != null && Object.hasOwnProperty.call(message, "oldPassword"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.oldPassword);
                if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.newPassword);
                return writer;
            };

            /**
             * Encodes the specified PasswordRequest message, length delimited. Does not implicitly {@link api.core.PasswordRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.PasswordRequest
             * @static
             * @param {api.core.IPasswordRequest} message PasswordRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PasswordRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.PasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.PasswordRequest} PasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PasswordRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.PasswordRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.oldPassword = reader.string();
                            break;
                        }
                    case 2: {
                            message.newPassword = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PasswordRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.PasswordRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.PasswordRequest} PasswordRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PasswordRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PasswordRequest message.
             * @function verify
             * @memberof api.core.PasswordRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PasswordRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.oldPassword != null && message.hasOwnProperty("oldPassword"))
                    if (!$util.isString(message.oldPassword))
                        return "oldPassword: string expected";
                if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                    if (!$util.isString(message.newPassword))
                        return "newPassword: string expected";
                return null;
            };

            /**
             * Creates a PasswordRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.PasswordRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.PasswordRequest} PasswordRequest
             */
            PasswordRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.PasswordRequest)
                    return object;
                let message = new $root.api.core.PasswordRequest();
                if (object.oldPassword != null)
                    message.oldPassword = String(object.oldPassword);
                if (object.newPassword != null)
                    message.newPassword = String(object.newPassword);
                return message;
            };

            /**
             * Creates a plain object from a PasswordRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.PasswordRequest
             * @static
             * @param {api.core.PasswordRequest} message PasswordRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PasswordRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.oldPassword = "";
                    object.newPassword = "";
                }
                if (message.oldPassword != null && message.hasOwnProperty("oldPassword"))
                    object.oldPassword = message.oldPassword;
                if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                    object.newPassword = message.newPassword;
                return object;
            };

            /**
             * Converts this PasswordRequest to JSON.
             * @function toJSON
             * @memberof api.core.PasswordRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PasswordRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PasswordRequest
             * @function getTypeUrl
             * @memberof api.core.PasswordRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.PasswordRequest";
            };

            return PasswordRequest;
        })();

        core.QueryFronterRequest = (function() {

            /**
             * Properties of a QueryFronterRequest.
             * @memberof api.core
             * @interface IQueryFronterRequest
             * @property {string|null} [name] QueryFronterRequest name
             * @property {api.common.IBatch|null} [batch] QueryFronterRequest batch
             */

            /**
             * Constructs a new QueryFronterRequest.
             * @memberof api.core
             * @classdesc Represents a QueryFronterRequest.
             * @implements IQueryFronterRequest
             * @constructor
             * @param {api.core.IQueryFronterRequest=} [properties] Properties to set
             */
            function QueryFronterRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryFronterRequest name.
             * @member {string|null|undefined} name
             * @memberof api.core.QueryFronterRequest
             * @instance
             */
            QueryFronterRequest.prototype.name = null;

            /**
             * QueryFronterRequest batch.
             * @member {api.common.IBatch|null|undefined} batch
             * @memberof api.core.QueryFronterRequest
             * @instance
             */
            QueryFronterRequest.prototype.batch = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * QueryFronterRequest _name.
             * @member {"name"|undefined} _name
             * @memberof api.core.QueryFronterRequest
             * @instance
             */
            Object.defineProperty(QueryFronterRequest.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new QueryFronterRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {api.core.IQueryFronterRequest=} [properties] Properties to set
             * @returns {api.core.QueryFronterRequest} QueryFronterRequest instance
             */
            QueryFronterRequest.create = function create(properties) {
                return new QueryFronterRequest(properties);
            };

            /**
             * Encodes the specified QueryFronterRequest message. Does not implicitly {@link api.core.QueryFronterRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {api.core.IQueryFronterRequest} message QueryFronterRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryFronterRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.batch != null && Object.hasOwnProperty.call(message, "batch"))
                    $root.api.common.Batch.encode(message.batch, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QueryFronterRequest message, length delimited. Does not implicitly {@link api.core.QueryFronterRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {api.core.IQueryFronterRequest} message QueryFronterRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryFronterRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryFronterRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryFronterRequest} QueryFronterRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryFronterRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryFronterRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.batch = $root.api.common.Batch.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryFronterRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryFronterRequest} QueryFronterRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryFronterRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryFronterRequest message.
             * @function verify
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryFronterRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.name != null && message.hasOwnProperty("name")) {
                    properties._name = 1;
                    if (!$util.isString(message.name))
                        return "name: string expected";
                }
                if (message.batch != null && message.hasOwnProperty("batch")) {
                    let error = $root.api.common.Batch.verify(message.batch);
                    if (error)
                        return "batch." + error;
                }
                return null;
            };

            /**
             * Creates a QueryFronterRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryFronterRequest} QueryFronterRequest
             */
            QueryFronterRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryFronterRequest)
                    return object;
                let message = new $root.api.core.QueryFronterRequest();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.batch != null) {
                    if (typeof object.batch !== "object")
                        throw TypeError(".api.core.QueryFronterRequest.batch: object expected");
                    message.batch = $root.api.common.Batch.fromObject(object.batch);
                }
                return message;
            };

            /**
             * Creates a plain object from a QueryFronterRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {api.core.QueryFronterRequest} message QueryFronterRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryFronterRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.batch = null;
                if (message.name != null && message.hasOwnProperty("name")) {
                    object.name = message.name;
                    if (options.oneofs)
                        object._name = "name";
                }
                if (message.batch != null && message.hasOwnProperty("batch"))
                    object.batch = $root.api.common.Batch.toObject(message.batch, options);
                return object;
            };

            /**
             * Converts this QueryFronterRequest to JSON.
             * @function toJSON
             * @memberof api.core.QueryFronterRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryFronterRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryFronterRequest
             * @function getTypeUrl
             * @memberof api.core.QueryFronterRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryFronterRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryFronterRequest";
            };

            return QueryFronterRequest;
        })();

        core.QueryFronterResponse = (function() {

            /**
             * Properties of a QueryFronterResponse.
             * @memberof api.core
             * @interface IQueryFronterResponse
             * @property {Array.<api.core.IQueryFronterItem>|null} [items] QueryFronterResponse items
             * @property {number|null} [total] QueryFronterResponse total
             */

            /**
             * Constructs a new QueryFronterResponse.
             * @memberof api.core
             * @classdesc Represents a QueryFronterResponse.
             * @implements IQueryFronterResponse
             * @constructor
             * @param {api.core.IQueryFronterResponse=} [properties] Properties to set
             */
            function QueryFronterResponse(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryFronterResponse items.
             * @member {Array.<api.core.IQueryFronterItem>} items
             * @memberof api.core.QueryFronterResponse
             * @instance
             */
            QueryFronterResponse.prototype.items = $util.emptyArray;

            /**
             * QueryFronterResponse total.
             * @member {number} total
             * @memberof api.core.QueryFronterResponse
             * @instance
             */
            QueryFronterResponse.prototype.total = 0;

            /**
             * Creates a new QueryFronterResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {api.core.IQueryFronterResponse=} [properties] Properties to set
             * @returns {api.core.QueryFronterResponse} QueryFronterResponse instance
             */
            QueryFronterResponse.create = function create(properties) {
                return new QueryFronterResponse(properties);
            };

            /**
             * Encodes the specified QueryFronterResponse message. Does not implicitly {@link api.core.QueryFronterResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {api.core.IQueryFronterResponse} message QueryFronterResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryFronterResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.core.QueryFronterItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
                return writer;
            };

            /**
             * Encodes the specified QueryFronterResponse message, length delimited. Does not implicitly {@link api.core.QueryFronterResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {api.core.IQueryFronterResponse} message QueryFronterResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryFronterResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryFronterResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryFronterResponse} QueryFronterResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryFronterResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryFronterResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.core.QueryFronterItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.total = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryFronterResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryFronterResponse} QueryFronterResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryFronterResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryFronterResponse message.
             * @function verify
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryFronterResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.core.QueryFronterItem.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total))
                        return "total: integer expected";
                return null;
            };

            /**
             * Creates a QueryFronterResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryFronterResponse} QueryFronterResponse
             */
            QueryFronterResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryFronterResponse)
                    return object;
                let message = new $root.api.core.QueryFronterResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.core.QueryFronterResponse.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.core.QueryFronterResponse.items: object expected");
                        message.items[i] = $root.api.core.QueryFronterItem.fromObject(object.items[i]);
                    }
                }
                if (object.total != null)
                    message.total = object.total | 0;
                return message;
            };

            /**
             * Creates a plain object from a QueryFronterResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {api.core.QueryFronterResponse} message QueryFronterResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryFronterResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (options.defaults)
                    object.total = 0;
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.core.QueryFronterItem.toObject(message.items[j], options);
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = message.total;
                return object;
            };

            /**
             * Converts this QueryFronterResponse to JSON.
             * @function toJSON
             * @memberof api.core.QueryFronterResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryFronterResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryFronterResponse
             * @function getTypeUrl
             * @memberof api.core.QueryFronterResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryFronterResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryFronterResponse";
            };

            return QueryFronterResponse;
        })();

        core.QueryFronterItem = (function() {

            /**
             * Properties of a QueryFronterItem.
             * @memberof api.core
             * @interface IQueryFronterItem
             * @property {string|null} [name] QueryFronterItem name
             * @property {string|null} [account] QueryFronterItem account
             * @property {Array.<string>|null} [group] QueryFronterItem group
             * @property {boolean|null} [isManager] QueryFronterItem isManager
             * @property {number|null} [workerId] QueryFronterItem workerId
             */

            /**
             * Constructs a new QueryFronterItem.
             * @memberof api.core
             * @classdesc Represents a QueryFronterItem.
             * @implements IQueryFronterItem
             * @constructor
             * @param {api.core.IQueryFronterItem=} [properties] Properties to set
             */
            function QueryFronterItem(properties) {
                this.group = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QueryFronterItem name.
             * @member {string} name
             * @memberof api.core.QueryFronterItem
             * @instance
             */
            QueryFronterItem.prototype.name = "";

            /**
             * QueryFronterItem account.
             * @member {string} account
             * @memberof api.core.QueryFronterItem
             * @instance
             */
            QueryFronterItem.prototype.account = "";

            /**
             * QueryFronterItem group.
             * @member {Array.<string>} group
             * @memberof api.core.QueryFronterItem
             * @instance
             */
            QueryFronterItem.prototype.group = $util.emptyArray;

            /**
             * QueryFronterItem isManager.
             * @member {boolean} isManager
             * @memberof api.core.QueryFronterItem
             * @instance
             */
            QueryFronterItem.prototype.isManager = false;

            /**
             * QueryFronterItem workerId.
             * @member {number} workerId
             * @memberof api.core.QueryFronterItem
             * @instance
             */
            QueryFronterItem.prototype.workerId = 0;

            /**
             * Creates a new QueryFronterItem instance using the specified properties.
             * @function create
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {api.core.IQueryFronterItem=} [properties] Properties to set
             * @returns {api.core.QueryFronterItem} QueryFronterItem instance
             */
            QueryFronterItem.create = function create(properties) {
                return new QueryFronterItem(properties);
            };

            /**
             * Encodes the specified QueryFronterItem message. Does not implicitly {@link api.core.QueryFronterItem.verify|verify} messages.
             * @function encode
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {api.core.IQueryFronterItem} message QueryFronterItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryFronterItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.account);
                if (message.group != null && message.group.length)
                    for (let i = 0; i < message.group.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.group[i]);
                if (message.isManager != null && Object.hasOwnProperty.call(message, "isManager"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isManager);
                if (message.workerId != null && Object.hasOwnProperty.call(message, "workerId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.workerId);
                return writer;
            };

            /**
             * Encodes the specified QueryFronterItem message, length delimited. Does not implicitly {@link api.core.QueryFronterItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {api.core.IQueryFronterItem} message QueryFronterItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QueryFronterItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QueryFronterItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QueryFronterItem} QueryFronterItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryFronterItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QueryFronterItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.account = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.group && message.group.length))
                                message.group = [];
                            message.group.push(reader.string());
                            break;
                        }
                    case 4: {
                            message.isManager = reader.bool();
                            break;
                        }
                    case 5: {
                            message.workerId = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QueryFronterItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QueryFronterItem} QueryFronterItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QueryFronterItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QueryFronterItem message.
             * @function verify
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QueryFronterItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.account != null && message.hasOwnProperty("account"))
                    if (!$util.isString(message.account))
                        return "account: string expected";
                if (message.group != null && message.hasOwnProperty("group")) {
                    if (!Array.isArray(message.group))
                        return "group: array expected";
                    for (let i = 0; i < message.group.length; ++i)
                        if (!$util.isString(message.group[i]))
                            return "group: string[] expected";
                }
                if (message.isManager != null && message.hasOwnProperty("isManager"))
                    if (typeof message.isManager !== "boolean")
                        return "isManager: boolean expected";
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    if (!$util.isInteger(message.workerId))
                        return "workerId: integer expected";
                return null;
            };

            /**
             * Creates a QueryFronterItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QueryFronterItem} QueryFronterItem
             */
            QueryFronterItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QueryFronterItem)
                    return object;
                let message = new $root.api.core.QueryFronterItem();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.account != null)
                    message.account = String(object.account);
                if (object.group) {
                    if (!Array.isArray(object.group))
                        throw TypeError(".api.core.QueryFronterItem.group: array expected");
                    message.group = [];
                    for (let i = 0; i < object.group.length; ++i)
                        message.group[i] = String(object.group[i]);
                }
                if (object.isManager != null)
                    message.isManager = Boolean(object.isManager);
                if (object.workerId != null)
                    message.workerId = object.workerId | 0;
                return message;
            };

            /**
             * Creates a plain object from a QueryFronterItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {api.core.QueryFronterItem} message QueryFronterItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QueryFronterItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.group = [];
                if (options.defaults) {
                    object.name = "";
                    object.account = "";
                    object.isManager = false;
                    object.workerId = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.account != null && message.hasOwnProperty("account"))
                    object.account = message.account;
                if (message.group && message.group.length) {
                    object.group = [];
                    for (let j = 0; j < message.group.length; ++j)
                        object.group[j] = message.group[j];
                }
                if (message.isManager != null && message.hasOwnProperty("isManager"))
                    object.isManager = message.isManager;
                if (message.workerId != null && message.hasOwnProperty("workerId"))
                    object.workerId = message.workerId;
                return object;
            };

            /**
             * Converts this QueryFronterItem to JSON.
             * @function toJSON
             * @memberof api.core.QueryFronterItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QueryFronterItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QueryFronterItem
             * @function getTypeUrl
             * @memberof api.core.QueryFronterItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QueryFronterItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QueryFronterItem";
            };

            return QueryFronterItem;
        })();

        core.DataQueryRequest = (function() {

            /**
             * Properties of a DataQueryRequest.
             * @memberof api.core
             * @interface IDataQueryRequest
             * @property {string|null} [startTime] DataQueryRequest startTime
             * @property {string|null} [endTime] DataQueryRequest endTime
             * @property {Array.<number>|null} [groupId] DataQueryRequest groupId
             * @property {api.common.WorkerPermission|null} [workerIdentity] DataQueryRequest workerIdentity
             * @property {api.common.IPage|null} [page] DataQueryRequest page
             */

            /**
             * Constructs a new DataQueryRequest.
             * @memberof api.core
             * @classdesc Represents a DataQueryRequest.
             * @implements IDataQueryRequest
             * @constructor
             * @param {api.core.IDataQueryRequest=} [properties] Properties to set
             */
            function DataQueryRequest(properties) {
                this.groupId = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DataQueryRequest startTime.
             * @member {string|null|undefined} startTime
             * @memberof api.core.DataQueryRequest
             * @instance
             */
            DataQueryRequest.prototype.startTime = null;

            /**
             * DataQueryRequest endTime.
             * @member {string|null|undefined} endTime
             * @memberof api.core.DataQueryRequest
             * @instance
             */
            DataQueryRequest.prototype.endTime = null;

            /**
             * DataQueryRequest groupId.
             * @member {Array.<number>} groupId
             * @memberof api.core.DataQueryRequest
             * @instance
             */
            DataQueryRequest.prototype.groupId = $util.emptyArray;

            /**
             * DataQueryRequest workerIdentity.
             * @member {api.common.WorkerPermission|null|undefined} workerIdentity
             * @memberof api.core.DataQueryRequest
             * @instance
             */
            DataQueryRequest.prototype.workerIdentity = null;

            /**
             * DataQueryRequest page.
             * @member {api.common.IPage|null|undefined} page
             * @memberof api.core.DataQueryRequest
             * @instance
             */
            DataQueryRequest.prototype.page = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * DataQueryRequest _startTime.
             * @member {"startTime"|undefined} _startTime
             * @memberof api.core.DataQueryRequest
             * @instance
             */
            Object.defineProperty(DataQueryRequest.prototype, "_startTime", {
                get: $util.oneOfGetter($oneOfFields = ["startTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * DataQueryRequest _endTime.
             * @member {"endTime"|undefined} _endTime
             * @memberof api.core.DataQueryRequest
             * @instance
             */
            Object.defineProperty(DataQueryRequest.prototype, "_endTime", {
                get: $util.oneOfGetter($oneOfFields = ["endTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * DataQueryRequest _workerIdentity.
             * @member {"workerIdentity"|undefined} _workerIdentity
             * @memberof api.core.DataQueryRequest
             * @instance
             */
            Object.defineProperty(DataQueryRequest.prototype, "_workerIdentity", {
                get: $util.oneOfGetter($oneOfFields = ["workerIdentity"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new DataQueryRequest instance using the specified properties.
             * @function create
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {api.core.IDataQueryRequest=} [properties] Properties to set
             * @returns {api.core.DataQueryRequest} DataQueryRequest instance
             */
            DataQueryRequest.create = function create(properties) {
                return new DataQueryRequest(properties);
            };

            /**
             * Encodes the specified DataQueryRequest message. Does not implicitly {@link api.core.DataQueryRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {api.core.IDataQueryRequest} message DataQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataQueryRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.startTime);
                if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.endTime);
                if (message.groupId != null && message.groupId.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (let i = 0; i < message.groupId.length; ++i)
                        writer.int32(message.groupId[i]);
                    writer.ldelim();
                }
                if (message.workerIdentity != null && Object.hasOwnProperty.call(message, "workerIdentity"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.workerIdentity);
                if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                    $root.api.common.Page.encode(message.page, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DataQueryRequest message, length delimited. Does not implicitly {@link api.core.DataQueryRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {api.core.IDataQueryRequest} message DataQueryRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataQueryRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DataQueryRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DataQueryRequest} DataQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataQueryRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DataQueryRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.startTime = reader.string();
                            break;
                        }
                    case 2: {
                            message.endTime = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.groupId && message.groupId.length))
                                message.groupId = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.groupId.push(reader.int32());
                            } else
                                message.groupId.push(reader.int32());
                            break;
                        }
                    case 4: {
                            message.workerIdentity = reader.int32();
                            break;
                        }
                    case 5: {
                            message.page = $root.api.common.Page.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DataQueryRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DataQueryRequest} DataQueryRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataQueryRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DataQueryRequest message.
             * @function verify
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DataQueryRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.startTime != null && message.hasOwnProperty("startTime")) {
                    properties._startTime = 1;
                    if (!$util.isString(message.startTime))
                        return "startTime: string expected";
                }
                if (message.endTime != null && message.hasOwnProperty("endTime")) {
                    properties._endTime = 1;
                    if (!$util.isString(message.endTime))
                        return "endTime: string expected";
                }
                if (message.groupId != null && message.hasOwnProperty("groupId")) {
                    if (!Array.isArray(message.groupId))
                        return "groupId: array expected";
                    for (let i = 0; i < message.groupId.length; ++i)
                        if (!$util.isInteger(message.groupId[i]))
                            return "groupId: integer[] expected";
                }
                if (message.workerIdentity != null && message.hasOwnProperty("workerIdentity")) {
                    properties._workerIdentity = 1;
                    switch (message.workerIdentity) {
                    default:
                        return "workerIdentity: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                    case 8:
                    case 16:
                    case 32:
                    case 64:
                        break;
                    }
                }
                if (message.page != null && message.hasOwnProperty("page")) {
                    let error = $root.api.common.Page.verify(message.page);
                    if (error)
                        return "page." + error;
                }
                return null;
            };

            /**
             * Creates a DataQueryRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DataQueryRequest} DataQueryRequest
             */
            DataQueryRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DataQueryRequest)
                    return object;
                let message = new $root.api.core.DataQueryRequest();
                if (object.startTime != null)
                    message.startTime = String(object.startTime);
                if (object.endTime != null)
                    message.endTime = String(object.endTime);
                if (object.groupId) {
                    if (!Array.isArray(object.groupId))
                        throw TypeError(".api.core.DataQueryRequest.groupId: array expected");
                    message.groupId = [];
                    for (let i = 0; i < object.groupId.length; ++i)
                        message.groupId[i] = object.groupId[i] | 0;
                }
                switch (object.workerIdentity) {
                default:
                    if (typeof object.workerIdentity === "number") {
                        message.workerIdentity = object.workerIdentity;
                        break;
                    }
                    break;
                case "WORKER_PERM_NONE":
                case 0:
                    message.workerIdentity = 0;
                    break;
                case "WORKER_PERM_TOP":
                case 1:
                    message.workerIdentity = 1;
                    break;
                case "WORKER_PERM_ADMIN":
                case 2:
                    message.workerIdentity = 2;
                    break;
                case "WORKER_PERM_LEADER":
                case 4:
                    message.workerIdentity = 4;
                    break;
                case "WORKER_PERM_FRONTER":
                case 8:
                    message.workerIdentity = 8;
                    break;
                case "WORKER_PERM_TRANSFER":
                case 16:
                    message.workerIdentity = 16;
                    break;
                case "WORKER_PERM_SEARCHER":
                case 32:
                    message.workerIdentity = 32;
                    break;
                case "WORKER_PERM_BLACKER":
                case 64:
                    message.workerIdentity = 64;
                    break;
                }
                if (object.page != null) {
                    if (typeof object.page !== "object")
                        throw TypeError(".api.core.DataQueryRequest.page: object expected");
                    message.page = $root.api.common.Page.fromObject(object.page);
                }
                return message;
            };

            /**
             * Creates a plain object from a DataQueryRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {api.core.DataQueryRequest} message DataQueryRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataQueryRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.groupId = [];
                if (options.defaults)
                    object.page = null;
                if (message.startTime != null && message.hasOwnProperty("startTime")) {
                    object.startTime = message.startTime;
                    if (options.oneofs)
                        object._startTime = "startTime";
                }
                if (message.endTime != null && message.hasOwnProperty("endTime")) {
                    object.endTime = message.endTime;
                    if (options.oneofs)
                        object._endTime = "endTime";
                }
                if (message.groupId && message.groupId.length) {
                    object.groupId = [];
                    for (let j = 0; j < message.groupId.length; ++j)
                        object.groupId[j] = message.groupId[j];
                }
                if (message.workerIdentity != null && message.hasOwnProperty("workerIdentity")) {
                    object.workerIdentity = options.enums === String ? $root.api.common.WorkerPermission[message.workerIdentity] === undefined ? message.workerIdentity : $root.api.common.WorkerPermission[message.workerIdentity] : message.workerIdentity;
                    if (options.oneofs)
                        object._workerIdentity = "workerIdentity";
                }
                if (message.page != null && message.hasOwnProperty("page"))
                    object.page = $root.api.common.Page.toObject(message.page, options);
                return object;
            };

            /**
             * Converts this DataQueryRequest to JSON.
             * @function toJSON
             * @memberof api.core.DataQueryRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataQueryRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DataQueryRequest
             * @function getTypeUrl
             * @memberof api.core.DataQueryRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DataQueryRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DataQueryRequest";
            };

            return DataQueryRequest;
        })();

        core.DataQueryResponse = (function() {

            /**
             * Properties of a DataQueryResponse.
             * @memberof api.core
             * @interface IDataQueryResponse
             * @property {Array.<api.core.IDataQueryItem>|null} [item] DataQueryResponse item
             * @property {number|null} [total] DataQueryResponse total
             * @property {number|null} [countPlayer] DataQueryResponse countPlayer
             * @property {number|null} [threeRate] DataQueryResponse threeRate
             * @property {number|null} [responseTime] DataQueryResponse responseTime
             * @property {number|null} [serverTime] DataQueryResponse serverTime
             * @property {number|null} [countOnlineTime] DataQueryResponse countOnlineTime
             * @property {number|null} [acceptedNum] DataQueryResponse acceptedNum
             * @property {number|null} [transferTask] DataQueryResponse transferTask
             * @property {number|null} [workerLimit] DataQueryResponse workerLimit
             * @property {number|null} [currentWorkers] DataQueryResponse currentWorkers
             */

            /**
             * Constructs a new DataQueryResponse.
             * @memberof api.core
             * @classdesc Represents a DataQueryResponse.
             * @implements IDataQueryResponse
             * @constructor
             * @param {api.core.IDataQueryResponse=} [properties] Properties to set
             */
            function DataQueryResponse(properties) {
                this.item = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DataQueryResponse item.
             * @member {Array.<api.core.IDataQueryItem>} item
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.item = $util.emptyArray;

            /**
             * DataQueryResponse total.
             * @member {number} total
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.total = 0;

            /**
             * DataQueryResponse countPlayer.
             * @member {number} countPlayer
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.countPlayer = 0;

            /**
             * DataQueryResponse threeRate.
             * @member {number} threeRate
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.threeRate = 0;

            /**
             * DataQueryResponse responseTime.
             * @member {number} responseTime
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.responseTime = 0;

            /**
             * DataQueryResponse serverTime.
             * @member {number} serverTime
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.serverTime = 0;

            /**
             * DataQueryResponse countOnlineTime.
             * @member {number} countOnlineTime
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.countOnlineTime = 0;

            /**
             * DataQueryResponse acceptedNum.
             * @member {number} acceptedNum
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.acceptedNum = 0;

            /**
             * DataQueryResponse transferTask.
             * @member {number} transferTask
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.transferTask = 0;

            /**
             * DataQueryResponse workerLimit.
             * @member {number} workerLimit
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.workerLimit = 0;

            /**
             * DataQueryResponse currentWorkers.
             * @member {number} currentWorkers
             * @memberof api.core.DataQueryResponse
             * @instance
             */
            DataQueryResponse.prototype.currentWorkers = 0;

            /**
             * Creates a new DataQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {api.core.IDataQueryResponse=} [properties] Properties to set
             * @returns {api.core.DataQueryResponse} DataQueryResponse instance
             */
            DataQueryResponse.create = function create(properties) {
                return new DataQueryResponse(properties);
            };

            /**
             * Encodes the specified DataQueryResponse message. Does not implicitly {@link api.core.DataQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {api.core.IDataQueryResponse} message DataQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.item != null && message.item.length)
                    for (let i = 0; i < message.item.length; ++i)
                        $root.api.core.DataQueryItem.encode(message.item[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
                if (message.countPlayer != null && Object.hasOwnProperty.call(message, "countPlayer"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.countPlayer);
                if (message.threeRate != null && Object.hasOwnProperty.call(message, "threeRate"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.threeRate);
                if (message.responseTime != null && Object.hasOwnProperty.call(message, "responseTime"))
                    writer.uint32(/* id 5, wireType 5 =*/45).float(message.responseTime);
                if (message.serverTime != null && Object.hasOwnProperty.call(message, "serverTime"))
                    writer.uint32(/* id 6, wireType 5 =*/53).float(message.serverTime);
                if (message.countOnlineTime != null && Object.hasOwnProperty.call(message, "countOnlineTime"))
                    writer.uint32(/* id 7, wireType 5 =*/61).float(message.countOnlineTime);
                if (message.acceptedNum != null && Object.hasOwnProperty.call(message, "acceptedNum"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.acceptedNum);
                if (message.transferTask != null && Object.hasOwnProperty.call(message, "transferTask"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.transferTask);
                if (message.workerLimit != null && Object.hasOwnProperty.call(message, "workerLimit"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.workerLimit);
                if (message.currentWorkers != null && Object.hasOwnProperty.call(message, "currentWorkers"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.currentWorkers);
                return writer;
            };

            /**
             * Encodes the specified DataQueryResponse message, length delimited. Does not implicitly {@link api.core.DataQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {api.core.IDataQueryResponse} message DataQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DataQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DataQueryResponse} DataQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DataQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.item && message.item.length))
                                message.item = [];
                            message.item.push($root.api.core.DataQueryItem.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.total = reader.int32();
                            break;
                        }
                    case 3: {
                            message.countPlayer = reader.int32();
                            break;
                        }
                    case 4: {
                            message.threeRate = reader.float();
                            break;
                        }
                    case 5: {
                            message.responseTime = reader.float();
                            break;
                        }
                    case 6: {
                            message.serverTime = reader.float();
                            break;
                        }
                    case 7: {
                            message.countOnlineTime = reader.float();
                            break;
                        }
                    case 8: {
                            message.acceptedNum = reader.int32();
                            break;
                        }
                    case 9: {
                            message.transferTask = reader.int32();
                            break;
                        }
                    case 10: {
                            message.workerLimit = reader.int32();
                            break;
                        }
                    case 11: {
                            message.currentWorkers = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DataQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DataQueryResponse} DataQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DataQueryResponse message.
             * @function verify
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DataQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.item != null && message.hasOwnProperty("item")) {
                    if (!Array.isArray(message.item))
                        return "item: array expected";
                    for (let i = 0; i < message.item.length; ++i) {
                        let error = $root.api.core.DataQueryItem.verify(message.item[i]);
                        if (error)
                            return "item." + error;
                    }
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    if (!$util.isInteger(message.total))
                        return "total: integer expected";
                if (message.countPlayer != null && message.hasOwnProperty("countPlayer"))
                    if (!$util.isInteger(message.countPlayer))
                        return "countPlayer: integer expected";
                if (message.threeRate != null && message.hasOwnProperty("threeRate"))
                    if (typeof message.threeRate !== "number")
                        return "threeRate: number expected";
                if (message.responseTime != null && message.hasOwnProperty("responseTime"))
                    if (typeof message.responseTime !== "number")
                        return "responseTime: number expected";
                if (message.serverTime != null && message.hasOwnProperty("serverTime"))
                    if (typeof message.serverTime !== "number")
                        return "serverTime: number expected";
                if (message.countOnlineTime != null && message.hasOwnProperty("countOnlineTime"))
                    if (typeof message.countOnlineTime !== "number")
                        return "countOnlineTime: number expected";
                if (message.acceptedNum != null && message.hasOwnProperty("acceptedNum"))
                    if (!$util.isInteger(message.acceptedNum))
                        return "acceptedNum: integer expected";
                if (message.transferTask != null && message.hasOwnProperty("transferTask"))
                    if (!$util.isInteger(message.transferTask))
                        return "transferTask: integer expected";
                if (message.workerLimit != null && message.hasOwnProperty("workerLimit"))
                    if (!$util.isInteger(message.workerLimit))
                        return "workerLimit: integer expected";
                if (message.currentWorkers != null && message.hasOwnProperty("currentWorkers"))
                    if (!$util.isInteger(message.currentWorkers))
                        return "currentWorkers: integer expected";
                return null;
            };

            /**
             * Creates a DataQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DataQueryResponse} DataQueryResponse
             */
            DataQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DataQueryResponse)
                    return object;
                let message = new $root.api.core.DataQueryResponse();
                if (object.item) {
                    if (!Array.isArray(object.item))
                        throw TypeError(".api.core.DataQueryResponse.item: array expected");
                    message.item = [];
                    for (let i = 0; i < object.item.length; ++i) {
                        if (typeof object.item[i] !== "object")
                            throw TypeError(".api.core.DataQueryResponse.item: object expected");
                        message.item[i] = $root.api.core.DataQueryItem.fromObject(object.item[i]);
                    }
                }
                if (object.total != null)
                    message.total = object.total | 0;
                if (object.countPlayer != null)
                    message.countPlayer = object.countPlayer | 0;
                if (object.threeRate != null)
                    message.threeRate = Number(object.threeRate);
                if (object.responseTime != null)
                    message.responseTime = Number(object.responseTime);
                if (object.serverTime != null)
                    message.serverTime = Number(object.serverTime);
                if (object.countOnlineTime != null)
                    message.countOnlineTime = Number(object.countOnlineTime);
                if (object.acceptedNum != null)
                    message.acceptedNum = object.acceptedNum | 0;
                if (object.transferTask != null)
                    message.transferTask = object.transferTask | 0;
                if (object.workerLimit != null)
                    message.workerLimit = object.workerLimit | 0;
                if (object.currentWorkers != null)
                    message.currentWorkers = object.currentWorkers | 0;
                return message;
            };

            /**
             * Creates a plain object from a DataQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {api.core.DataQueryResponse} message DataQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.item = [];
                if (options.defaults) {
                    object.total = 0;
                    object.countPlayer = 0;
                    object.threeRate = 0;
                    object.responseTime = 0;
                    object.serverTime = 0;
                    object.countOnlineTime = 0;
                    object.acceptedNum = 0;
                    object.transferTask = 0;
                    object.workerLimit = 0;
                    object.currentWorkers = 0;
                }
                if (message.item && message.item.length) {
                    object.item = [];
                    for (let j = 0; j < message.item.length; ++j)
                        object.item[j] = $root.api.core.DataQueryItem.toObject(message.item[j], options);
                }
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = message.total;
                if (message.countPlayer != null && message.hasOwnProperty("countPlayer"))
                    object.countPlayer = message.countPlayer;
                if (message.threeRate != null && message.hasOwnProperty("threeRate"))
                    object.threeRate = options.json && !isFinite(message.threeRate) ? String(message.threeRate) : message.threeRate;
                if (message.responseTime != null && message.hasOwnProperty("responseTime"))
                    object.responseTime = options.json && !isFinite(message.responseTime) ? String(message.responseTime) : message.responseTime;
                if (message.serverTime != null && message.hasOwnProperty("serverTime"))
                    object.serverTime = options.json && !isFinite(message.serverTime) ? String(message.serverTime) : message.serverTime;
                if (message.countOnlineTime != null && message.hasOwnProperty("countOnlineTime"))
                    object.countOnlineTime = options.json && !isFinite(message.countOnlineTime) ? String(message.countOnlineTime) : message.countOnlineTime;
                if (message.acceptedNum != null && message.hasOwnProperty("acceptedNum"))
                    object.acceptedNum = message.acceptedNum;
                if (message.transferTask != null && message.hasOwnProperty("transferTask"))
                    object.transferTask = message.transferTask;
                if (message.workerLimit != null && message.hasOwnProperty("workerLimit"))
                    object.workerLimit = message.workerLimit;
                if (message.currentWorkers != null && message.hasOwnProperty("currentWorkers"))
                    object.currentWorkers = message.currentWorkers;
                return object;
            };

            /**
             * Converts this DataQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.DataQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DataQueryResponse
             * @function getTypeUrl
             * @memberof api.core.DataQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DataQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DataQueryResponse";
            };

            return DataQueryResponse;
        })();

        core.DataQueryItem = (function() {

            /**
             * Properties of a DataQueryItem.
             * @memberof api.core
             * @interface IDataQueryItem
             * @property {string|null} [name] DataQueryItem name
             * @property {number|null} [matchNum] DataQueryItem matchNum
             * @property {string|null} [threeResponseRate] DataQueryItem threeResponseRate
             * @property {number|null} [switchingTask] DataQueryItem switchingTask
             * @property {number|null} [acceptAssignment] DataQueryItem acceptAssignment
             * @property {number|null} [correspondingTime] DataQueryItem correspondingTime
             * @property {Array.<string>|null} [belongGroup] DataQueryItem belongGroup
             * @property {boolean|null} [firstLine] DataQueryItem firstLine
             * @property {boolean|null} [isManager] DataQueryItem isManager
             * @property {number|null} [averageTime] DataQueryItem averageTime
             * @property {number|null} [accumulatedOnlineDuration] DataQueryItem accumulatedOnlineDuration
             */

            /**
             * Constructs a new DataQueryItem.
             * @memberof api.core
             * @classdesc Represents a DataQueryItem.
             * @implements IDataQueryItem
             * @constructor
             * @param {api.core.IDataQueryItem=} [properties] Properties to set
             */
            function DataQueryItem(properties) {
                this.belongGroup = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DataQueryItem name.
             * @member {string} name
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.name = "";

            /**
             * DataQueryItem matchNum.
             * @member {number} matchNum
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.matchNum = 0;

            /**
             * DataQueryItem threeResponseRate.
             * @member {string} threeResponseRate
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.threeResponseRate = "";

            /**
             * DataQueryItem switchingTask.
             * @member {number} switchingTask
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.switchingTask = 0;

            /**
             * DataQueryItem acceptAssignment.
             * @member {number} acceptAssignment
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.acceptAssignment = 0;

            /**
             * DataQueryItem correspondingTime.
             * @member {number} correspondingTime
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.correspondingTime = 0;

            /**
             * DataQueryItem belongGroup.
             * @member {Array.<string>} belongGroup
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.belongGroup = $util.emptyArray;

            /**
             * DataQueryItem firstLine.
             * @member {boolean} firstLine
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.firstLine = false;

            /**
             * DataQueryItem isManager.
             * @member {boolean} isManager
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.isManager = false;

            /**
             * DataQueryItem averageTime.
             * @member {number} averageTime
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.averageTime = 0;

            /**
             * DataQueryItem accumulatedOnlineDuration.
             * @member {number} accumulatedOnlineDuration
             * @memberof api.core.DataQueryItem
             * @instance
             */
            DataQueryItem.prototype.accumulatedOnlineDuration = 0;

            /**
             * Creates a new DataQueryItem instance using the specified properties.
             * @function create
             * @memberof api.core.DataQueryItem
             * @static
             * @param {api.core.IDataQueryItem=} [properties] Properties to set
             * @returns {api.core.DataQueryItem} DataQueryItem instance
             */
            DataQueryItem.create = function create(properties) {
                return new DataQueryItem(properties);
            };

            /**
             * Encodes the specified DataQueryItem message. Does not implicitly {@link api.core.DataQueryItem.verify|verify} messages.
             * @function encode
             * @memberof api.core.DataQueryItem
             * @static
             * @param {api.core.IDataQueryItem} message DataQueryItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataQueryItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.matchNum != null && Object.hasOwnProperty.call(message, "matchNum"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.matchNum);
                if (message.threeResponseRate != null && Object.hasOwnProperty.call(message, "threeResponseRate"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.threeResponseRate);
                if (message.switchingTask != null && Object.hasOwnProperty.call(message, "switchingTask"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.switchingTask);
                if (message.acceptAssignment != null && Object.hasOwnProperty.call(message, "acceptAssignment"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.acceptAssignment);
                if (message.correspondingTime != null && Object.hasOwnProperty.call(message, "correspondingTime"))
                    writer.uint32(/* id 6, wireType 5 =*/53).float(message.correspondingTime);
                if (message.belongGroup != null && message.belongGroup.length)
                    for (let i = 0; i < message.belongGroup.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.belongGroup[i]);
                if (message.firstLine != null && Object.hasOwnProperty.call(message, "firstLine"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.firstLine);
                if (message.isManager != null && Object.hasOwnProperty.call(message, "isManager"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isManager);
                if (message.averageTime != null && Object.hasOwnProperty.call(message, "averageTime"))
                    writer.uint32(/* id 10, wireType 5 =*/85).float(message.averageTime);
                if (message.accumulatedOnlineDuration != null && Object.hasOwnProperty.call(message, "accumulatedOnlineDuration"))
                    writer.uint32(/* id 11, wireType 5 =*/93).float(message.accumulatedOnlineDuration);
                return writer;
            };

            /**
             * Encodes the specified DataQueryItem message, length delimited. Does not implicitly {@link api.core.DataQueryItem.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.DataQueryItem
             * @static
             * @param {api.core.IDataQueryItem} message DataQueryItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DataQueryItem.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DataQueryItem message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.DataQueryItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.DataQueryItem} DataQueryItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataQueryItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.DataQueryItem();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.matchNum = reader.int32();
                            break;
                        }
                    case 3: {
                            message.threeResponseRate = reader.string();
                            break;
                        }
                    case 4: {
                            message.switchingTask = reader.int32();
                            break;
                        }
                    case 5: {
                            message.acceptAssignment = reader.int32();
                            break;
                        }
                    case 6: {
                            message.correspondingTime = reader.float();
                            break;
                        }
                    case 7: {
                            if (!(message.belongGroup && message.belongGroup.length))
                                message.belongGroup = [];
                            message.belongGroup.push(reader.string());
                            break;
                        }
                    case 8: {
                            message.firstLine = reader.bool();
                            break;
                        }
                    case 9: {
                            message.isManager = reader.bool();
                            break;
                        }
                    case 10: {
                            message.averageTime = reader.float();
                            break;
                        }
                    case 11: {
                            message.accumulatedOnlineDuration = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DataQueryItem message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.DataQueryItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.DataQueryItem} DataQueryItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DataQueryItem.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DataQueryItem message.
             * @function verify
             * @memberof api.core.DataQueryItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DataQueryItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.matchNum != null && message.hasOwnProperty("matchNum"))
                    if (!$util.isInteger(message.matchNum))
                        return "matchNum: integer expected";
                if (message.threeResponseRate != null && message.hasOwnProperty("threeResponseRate"))
                    if (!$util.isString(message.threeResponseRate))
                        return "threeResponseRate: string expected";
                if (message.switchingTask != null && message.hasOwnProperty("switchingTask"))
                    if (!$util.isInteger(message.switchingTask))
                        return "switchingTask: integer expected";
                if (message.acceptAssignment != null && message.hasOwnProperty("acceptAssignment"))
                    if (!$util.isInteger(message.acceptAssignment))
                        return "acceptAssignment: integer expected";
                if (message.correspondingTime != null && message.hasOwnProperty("correspondingTime"))
                    if (typeof message.correspondingTime !== "number")
                        return "correspondingTime: number expected";
                if (message.belongGroup != null && message.hasOwnProperty("belongGroup")) {
                    if (!Array.isArray(message.belongGroup))
                        return "belongGroup: array expected";
                    for (let i = 0; i < message.belongGroup.length; ++i)
                        if (!$util.isString(message.belongGroup[i]))
                            return "belongGroup: string[] expected";
                }
                if (message.firstLine != null && message.hasOwnProperty("firstLine"))
                    if (typeof message.firstLine !== "boolean")
                        return "firstLine: boolean expected";
                if (message.isManager != null && message.hasOwnProperty("isManager"))
                    if (typeof message.isManager !== "boolean")
                        return "isManager: boolean expected";
                if (message.averageTime != null && message.hasOwnProperty("averageTime"))
                    if (typeof message.averageTime !== "number")
                        return "averageTime: number expected";
                if (message.accumulatedOnlineDuration != null && message.hasOwnProperty("accumulatedOnlineDuration"))
                    if (typeof message.accumulatedOnlineDuration !== "number")
                        return "accumulatedOnlineDuration: number expected";
                return null;
            };

            /**
             * Creates a DataQueryItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.DataQueryItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.DataQueryItem} DataQueryItem
             */
            DataQueryItem.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.DataQueryItem)
                    return object;
                let message = new $root.api.core.DataQueryItem();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.matchNum != null)
                    message.matchNum = object.matchNum | 0;
                if (object.threeResponseRate != null)
                    message.threeResponseRate = String(object.threeResponseRate);
                if (object.switchingTask != null)
                    message.switchingTask = object.switchingTask | 0;
                if (object.acceptAssignment != null)
                    message.acceptAssignment = object.acceptAssignment | 0;
                if (object.correspondingTime != null)
                    message.correspondingTime = Number(object.correspondingTime);
                if (object.belongGroup) {
                    if (!Array.isArray(object.belongGroup))
                        throw TypeError(".api.core.DataQueryItem.belongGroup: array expected");
                    message.belongGroup = [];
                    for (let i = 0; i < object.belongGroup.length; ++i)
                        message.belongGroup[i] = String(object.belongGroup[i]);
                }
                if (object.firstLine != null)
                    message.firstLine = Boolean(object.firstLine);
                if (object.isManager != null)
                    message.isManager = Boolean(object.isManager);
                if (object.averageTime != null)
                    message.averageTime = Number(object.averageTime);
                if (object.accumulatedOnlineDuration != null)
                    message.accumulatedOnlineDuration = Number(object.accumulatedOnlineDuration);
                return message;
            };

            /**
             * Creates a plain object from a DataQueryItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.DataQueryItem
             * @static
             * @param {api.core.DataQueryItem} message DataQueryItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DataQueryItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.belongGroup = [];
                if (options.defaults) {
                    object.name = "";
                    object.matchNum = 0;
                    object.threeResponseRate = "";
                    object.switchingTask = 0;
                    object.acceptAssignment = 0;
                    object.correspondingTime = 0;
                    object.firstLine = false;
                    object.isManager = false;
                    object.averageTime = 0;
                    object.accumulatedOnlineDuration = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.matchNum != null && message.hasOwnProperty("matchNum"))
                    object.matchNum = message.matchNum;
                if (message.threeResponseRate != null && message.hasOwnProperty("threeResponseRate"))
                    object.threeResponseRate = message.threeResponseRate;
                if (message.switchingTask != null && message.hasOwnProperty("switchingTask"))
                    object.switchingTask = message.switchingTask;
                if (message.acceptAssignment != null && message.hasOwnProperty("acceptAssignment"))
                    object.acceptAssignment = message.acceptAssignment;
                if (message.correspondingTime != null && message.hasOwnProperty("correspondingTime"))
                    object.correspondingTime = options.json && !isFinite(message.correspondingTime) ? String(message.correspondingTime) : message.correspondingTime;
                if (message.belongGroup && message.belongGroup.length) {
                    object.belongGroup = [];
                    for (let j = 0; j < message.belongGroup.length; ++j)
                        object.belongGroup[j] = message.belongGroup[j];
                }
                if (message.firstLine != null && message.hasOwnProperty("firstLine"))
                    object.firstLine = message.firstLine;
                if (message.isManager != null && message.hasOwnProperty("isManager"))
                    object.isManager = message.isManager;
                if (message.averageTime != null && message.hasOwnProperty("averageTime"))
                    object.averageTime = options.json && !isFinite(message.averageTime) ? String(message.averageTime) : message.averageTime;
                if (message.accumulatedOnlineDuration != null && message.hasOwnProperty("accumulatedOnlineDuration"))
                    object.accumulatedOnlineDuration = options.json && !isFinite(message.accumulatedOnlineDuration) ? String(message.accumulatedOnlineDuration) : message.accumulatedOnlineDuration;
                return object;
            };

            /**
             * Converts this DataQueryItem to JSON.
             * @function toJSON
             * @memberof api.core.DataQueryItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DataQueryItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DataQueryItem
             * @function getTypeUrl
             * @memberof api.core.DataQueryItem
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DataQueryItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.DataQueryItem";
            };

            return DataQueryItem;
        })();

        core.QuerySpecPermWorkerRequest = (function() {

            /**
             * Properties of a QuerySpecPermWorkerRequest.
             * @memberof api.core
             * @interface IQuerySpecPermWorkerRequest
             * @property {api.common.WorkerPermission|null} [perm] QuerySpecPermWorkerRequest perm
             */

            /**
             * Constructs a new QuerySpecPermWorkerRequest.
             * @memberof api.core
             * @classdesc Represents a QuerySpecPermWorkerRequest.
             * @implements IQuerySpecPermWorkerRequest
             * @constructor
             * @param {api.core.IQuerySpecPermWorkerRequest=} [properties] Properties to set
             */
            function QuerySpecPermWorkerRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuerySpecPermWorkerRequest perm.
             * @member {api.common.WorkerPermission} perm
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @instance
             */
            QuerySpecPermWorkerRequest.prototype.perm = 0;

            /**
             * Creates a new QuerySpecPermWorkerRequest instance using the specified properties.
             * @function create
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {api.core.IQuerySpecPermWorkerRequest=} [properties] Properties to set
             * @returns {api.core.QuerySpecPermWorkerRequest} QuerySpecPermWorkerRequest instance
             */
            QuerySpecPermWorkerRequest.create = function create(properties) {
                return new QuerySpecPermWorkerRequest(properties);
            };

            /**
             * Encodes the specified QuerySpecPermWorkerRequest message. Does not implicitly {@link api.core.QuerySpecPermWorkerRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {api.core.IQuerySpecPermWorkerRequest} message QuerySpecPermWorkerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySpecPermWorkerRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.perm != null && Object.hasOwnProperty.call(message, "perm"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.perm);
                return writer;
            };

            /**
             * Encodes the specified QuerySpecPermWorkerRequest message, length delimited. Does not implicitly {@link api.core.QuerySpecPermWorkerRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {api.core.IQuerySpecPermWorkerRequest} message QuerySpecPermWorkerRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySpecPermWorkerRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuerySpecPermWorkerRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuerySpecPermWorkerRequest} QuerySpecPermWorkerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySpecPermWorkerRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuerySpecPermWorkerRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.perm = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuerySpecPermWorkerRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuerySpecPermWorkerRequest} QuerySpecPermWorkerRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySpecPermWorkerRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuerySpecPermWorkerRequest message.
             * @function verify
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuerySpecPermWorkerRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.perm != null && message.hasOwnProperty("perm"))
                    switch (message.perm) {
                    default:
                        return "perm: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                    case 8:
                    case 16:
                    case 32:
                    case 64:
                        break;
                    }
                return null;
            };

            /**
             * Creates a QuerySpecPermWorkerRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuerySpecPermWorkerRequest} QuerySpecPermWorkerRequest
             */
            QuerySpecPermWorkerRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuerySpecPermWorkerRequest)
                    return object;
                let message = new $root.api.core.QuerySpecPermWorkerRequest();
                switch (object.perm) {
                default:
                    if (typeof object.perm === "number") {
                        message.perm = object.perm;
                        break;
                    }
                    break;
                case "WORKER_PERM_NONE":
                case 0:
                    message.perm = 0;
                    break;
                case "WORKER_PERM_TOP":
                case 1:
                    message.perm = 1;
                    break;
                case "WORKER_PERM_ADMIN":
                case 2:
                    message.perm = 2;
                    break;
                case "WORKER_PERM_LEADER":
                case 4:
                    message.perm = 4;
                    break;
                case "WORKER_PERM_FRONTER":
                case 8:
                    message.perm = 8;
                    break;
                case "WORKER_PERM_TRANSFER":
                case 16:
                    message.perm = 16;
                    break;
                case "WORKER_PERM_SEARCHER":
                case 32:
                    message.perm = 32;
                    break;
                case "WORKER_PERM_BLACKER":
                case 64:
                    message.perm = 64;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a QuerySpecPermWorkerRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {api.core.QuerySpecPermWorkerRequest} message QuerySpecPermWorkerRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuerySpecPermWorkerRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.perm = options.enums === String ? "WORKER_PERM_NONE" : 0;
                if (message.perm != null && message.hasOwnProperty("perm"))
                    object.perm = options.enums === String ? $root.api.common.WorkerPermission[message.perm] === undefined ? message.perm : $root.api.common.WorkerPermission[message.perm] : message.perm;
                return object;
            };

            /**
             * Converts this QuerySpecPermWorkerRequest to JSON.
             * @function toJSON
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuerySpecPermWorkerRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuerySpecPermWorkerRequest
             * @function getTypeUrl
             * @memberof api.core.QuerySpecPermWorkerRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuerySpecPermWorkerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuerySpecPermWorkerRequest";
            };

            return QuerySpecPermWorkerRequest;
        })();

        core.WorkerListByGroup = (function() {

            /**
             * Properties of a WorkerListByGroup.
             * @memberof api.core
             * @interface IWorkerListByGroup
             * @property {api.common.IWorkerGroup|null} [group] WorkerListByGroup group
             * @property {Array.<api.common.IWorker>|null} [worker] WorkerListByGroup worker
             */

            /**
             * Constructs a new WorkerListByGroup.
             * @memberof api.core
             * @classdesc Represents a WorkerListByGroup.
             * @implements IWorkerListByGroup
             * @constructor
             * @param {api.core.IWorkerListByGroup=} [properties] Properties to set
             */
            function WorkerListByGroup(properties) {
                this.worker = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerListByGroup group.
             * @member {api.common.IWorkerGroup|null|undefined} group
             * @memberof api.core.WorkerListByGroup
             * @instance
             */
            WorkerListByGroup.prototype.group = null;

            /**
             * WorkerListByGroup worker.
             * @member {Array.<api.common.IWorker>} worker
             * @memberof api.core.WorkerListByGroup
             * @instance
             */
            WorkerListByGroup.prototype.worker = $util.emptyArray;

            /**
             * Creates a new WorkerListByGroup instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {api.core.IWorkerListByGroup=} [properties] Properties to set
             * @returns {api.core.WorkerListByGroup} WorkerListByGroup instance
             */
            WorkerListByGroup.create = function create(properties) {
                return new WorkerListByGroup(properties);
            };

            /**
             * Encodes the specified WorkerListByGroup message. Does not implicitly {@link api.core.WorkerListByGroup.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {api.core.IWorkerListByGroup} message WorkerListByGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerListByGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                    $root.api.common.WorkerGroup.encode(message.group, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.worker != null && message.worker.length)
                    for (let i = 0; i < message.worker.length; ++i)
                        $root.api.common.Worker.encode(message.worker[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified WorkerListByGroup message, length delimited. Does not implicitly {@link api.core.WorkerListByGroup.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {api.core.IWorkerListByGroup} message WorkerListByGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerListByGroup.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerListByGroup message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerListByGroup} WorkerListByGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerListByGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerListByGroup();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.group = $root.api.common.WorkerGroup.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.worker && message.worker.length))
                                message.worker = [];
                            message.worker.push($root.api.common.Worker.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerListByGroup message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerListByGroup} WorkerListByGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerListByGroup.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerListByGroup message.
             * @function verify
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerListByGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.group != null && message.hasOwnProperty("group")) {
                    let error = $root.api.common.WorkerGroup.verify(message.group);
                    if (error)
                        return "group." + error;
                }
                if (message.worker != null && message.hasOwnProperty("worker")) {
                    if (!Array.isArray(message.worker))
                        return "worker: array expected";
                    for (let i = 0; i < message.worker.length; ++i) {
                        let error = $root.api.common.Worker.verify(message.worker[i]);
                        if (error)
                            return "worker." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a WorkerListByGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerListByGroup} WorkerListByGroup
             */
            WorkerListByGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerListByGroup)
                    return object;
                let message = new $root.api.core.WorkerListByGroup();
                if (object.group != null) {
                    if (typeof object.group !== "object")
                        throw TypeError(".api.core.WorkerListByGroup.group: object expected");
                    message.group = $root.api.common.WorkerGroup.fromObject(object.group);
                }
                if (object.worker) {
                    if (!Array.isArray(object.worker))
                        throw TypeError(".api.core.WorkerListByGroup.worker: array expected");
                    message.worker = [];
                    for (let i = 0; i < object.worker.length; ++i) {
                        if (typeof object.worker[i] !== "object")
                            throw TypeError(".api.core.WorkerListByGroup.worker: object expected");
                        message.worker[i] = $root.api.common.Worker.fromObject(object.worker[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a WorkerListByGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {api.core.WorkerListByGroup} message WorkerListByGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerListByGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.worker = [];
                if (options.defaults)
                    object.group = null;
                if (message.group != null && message.hasOwnProperty("group"))
                    object.group = $root.api.common.WorkerGroup.toObject(message.group, options);
                if (message.worker && message.worker.length) {
                    object.worker = [];
                    for (let j = 0; j < message.worker.length; ++j)
                        object.worker[j] = $root.api.common.Worker.toObject(message.worker[j], options);
                }
                return object;
            };

            /**
             * Converts this WorkerListByGroup to JSON.
             * @function toJSON
             * @memberof api.core.WorkerListByGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerListByGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerListByGroup
             * @function getTypeUrl
             * @memberof api.core.WorkerListByGroup
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerListByGroup.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerListByGroup";
            };

            return WorkerListByGroup;
        })();

        core.QuerySpecPermWorkerResponse = (function() {

            /**
             * Properties of a QuerySpecPermWorkerResponse.
             * @memberof api.core
             * @interface IQuerySpecPermWorkerResponse
             * @property {Array.<api.core.IWorkerListByGroup>|null} [groups] QuerySpecPermWorkerResponse groups
             */

            /**
             * Constructs a new QuerySpecPermWorkerResponse.
             * @memberof api.core
             * @classdesc Represents a QuerySpecPermWorkerResponse.
             * @implements IQuerySpecPermWorkerResponse
             * @constructor
             * @param {api.core.IQuerySpecPermWorkerResponse=} [properties] Properties to set
             */
            function QuerySpecPermWorkerResponse(properties) {
                this.groups = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuerySpecPermWorkerResponse groups.
             * @member {Array.<api.core.IWorkerListByGroup>} groups
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @instance
             */
            QuerySpecPermWorkerResponse.prototype.groups = $util.emptyArray;

            /**
             * Creates a new QuerySpecPermWorkerResponse instance using the specified properties.
             * @function create
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {api.core.IQuerySpecPermWorkerResponse=} [properties] Properties to set
             * @returns {api.core.QuerySpecPermWorkerResponse} QuerySpecPermWorkerResponse instance
             */
            QuerySpecPermWorkerResponse.create = function create(properties) {
                return new QuerySpecPermWorkerResponse(properties);
            };

            /**
             * Encodes the specified QuerySpecPermWorkerResponse message. Does not implicitly {@link api.core.QuerySpecPermWorkerResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {api.core.IQuerySpecPermWorkerResponse} message QuerySpecPermWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySpecPermWorkerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groups != null && message.groups.length)
                    for (let i = 0; i < message.groups.length; ++i)
                        $root.api.core.WorkerListByGroup.encode(message.groups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified QuerySpecPermWorkerResponse message, length delimited. Does not implicitly {@link api.core.QuerySpecPermWorkerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {api.core.IQuerySpecPermWorkerResponse} message QuerySpecPermWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuerySpecPermWorkerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuerySpecPermWorkerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.QuerySpecPermWorkerResponse} QuerySpecPermWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySpecPermWorkerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.QuerySpecPermWorkerResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.groups && message.groups.length))
                                message.groups = [];
                            message.groups.push($root.api.core.WorkerListByGroup.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QuerySpecPermWorkerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.QuerySpecPermWorkerResponse} QuerySpecPermWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuerySpecPermWorkerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QuerySpecPermWorkerResponse message.
             * @function verify
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuerySpecPermWorkerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groups != null && message.hasOwnProperty("groups")) {
                    if (!Array.isArray(message.groups))
                        return "groups: array expected";
                    for (let i = 0; i < message.groups.length; ++i) {
                        let error = $root.api.core.WorkerListByGroup.verify(message.groups[i]);
                        if (error)
                            return "groups." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a QuerySpecPermWorkerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.QuerySpecPermWorkerResponse} QuerySpecPermWorkerResponse
             */
            QuerySpecPermWorkerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.QuerySpecPermWorkerResponse)
                    return object;
                let message = new $root.api.core.QuerySpecPermWorkerResponse();
                if (object.groups) {
                    if (!Array.isArray(object.groups))
                        throw TypeError(".api.core.QuerySpecPermWorkerResponse.groups: array expected");
                    message.groups = [];
                    for (let i = 0; i < object.groups.length; ++i) {
                        if (typeof object.groups[i] !== "object")
                            throw TypeError(".api.core.QuerySpecPermWorkerResponse.groups: object expected");
                        message.groups[i] = $root.api.core.WorkerListByGroup.fromObject(object.groups[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a QuerySpecPermWorkerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {api.core.QuerySpecPermWorkerResponse} message QuerySpecPermWorkerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuerySpecPermWorkerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.groups = [];
                if (message.groups && message.groups.length) {
                    object.groups = [];
                    for (let j = 0; j < message.groups.length; ++j)
                        object.groups[j] = $root.api.core.WorkerListByGroup.toObject(message.groups[j], options);
                }
                return object;
            };

            /**
             * Converts this QuerySpecPermWorkerResponse to JSON.
             * @function toJSON
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuerySpecPermWorkerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QuerySpecPermWorkerResponse
             * @function getTypeUrl
             * @memberof api.core.QuerySpecPermWorkerResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QuerySpecPermWorkerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.QuerySpecPermWorkerResponse";
            };

            return QuerySpecPermWorkerResponse;
        })();

        core.TryCleanupAssignedWorkerResponse = (function() {

            /**
             * Properties of a TryCleanupAssignedWorkerResponse.
             * @memberof api.core
             * @interface ITryCleanupAssignedWorkerResponse
             * @property {boolean|null} [cleaned] TryCleanupAssignedWorkerResponse cleaned
             */

            /**
             * Constructs a new TryCleanupAssignedWorkerResponse.
             * @memberof api.core
             * @classdesc Represents a TryCleanupAssignedWorkerResponse.
             * @implements ITryCleanupAssignedWorkerResponse
             * @constructor
             * @param {api.core.ITryCleanupAssignedWorkerResponse=} [properties] Properties to set
             */
            function TryCleanupAssignedWorkerResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TryCleanupAssignedWorkerResponse cleaned.
             * @member {boolean} cleaned
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @instance
             */
            TryCleanupAssignedWorkerResponse.prototype.cleaned = false;

            /**
             * Creates a new TryCleanupAssignedWorkerResponse instance using the specified properties.
             * @function create
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {api.core.ITryCleanupAssignedWorkerResponse=} [properties] Properties to set
             * @returns {api.core.TryCleanupAssignedWorkerResponse} TryCleanupAssignedWorkerResponse instance
             */
            TryCleanupAssignedWorkerResponse.create = function create(properties) {
                return new TryCleanupAssignedWorkerResponse(properties);
            };

            /**
             * Encodes the specified TryCleanupAssignedWorkerResponse message. Does not implicitly {@link api.core.TryCleanupAssignedWorkerResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {api.core.ITryCleanupAssignedWorkerResponse} message TryCleanupAssignedWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TryCleanupAssignedWorkerResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.cleaned != null && Object.hasOwnProperty.call(message, "cleaned"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.cleaned);
                return writer;
            };

            /**
             * Encodes the specified TryCleanupAssignedWorkerResponse message, length delimited. Does not implicitly {@link api.core.TryCleanupAssignedWorkerResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {api.core.ITryCleanupAssignedWorkerResponse} message TryCleanupAssignedWorkerResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TryCleanupAssignedWorkerResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TryCleanupAssignedWorkerResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.TryCleanupAssignedWorkerResponse} TryCleanupAssignedWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TryCleanupAssignedWorkerResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.TryCleanupAssignedWorkerResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.cleaned = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TryCleanupAssignedWorkerResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.TryCleanupAssignedWorkerResponse} TryCleanupAssignedWorkerResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TryCleanupAssignedWorkerResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TryCleanupAssignedWorkerResponse message.
             * @function verify
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TryCleanupAssignedWorkerResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cleaned != null && message.hasOwnProperty("cleaned"))
                    if (typeof message.cleaned !== "boolean")
                        return "cleaned: boolean expected";
                return null;
            };

            /**
             * Creates a TryCleanupAssignedWorkerResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.TryCleanupAssignedWorkerResponse} TryCleanupAssignedWorkerResponse
             */
            TryCleanupAssignedWorkerResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.TryCleanupAssignedWorkerResponse)
                    return object;
                let message = new $root.api.core.TryCleanupAssignedWorkerResponse();
                if (object.cleaned != null)
                    message.cleaned = Boolean(object.cleaned);
                return message;
            };

            /**
             * Creates a plain object from a TryCleanupAssignedWorkerResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {api.core.TryCleanupAssignedWorkerResponse} message TryCleanupAssignedWorkerResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TryCleanupAssignedWorkerResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.cleaned = false;
                if (message.cleaned != null && message.hasOwnProperty("cleaned"))
                    object.cleaned = message.cleaned;
                return object;
            };

            /**
             * Converts this TryCleanupAssignedWorkerResponse to JSON.
             * @function toJSON
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TryCleanupAssignedWorkerResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TryCleanupAssignedWorkerResponse
             * @function getTypeUrl
             * @memberof api.core.TryCleanupAssignedWorkerResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TryCleanupAssignedWorkerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.TryCleanupAssignedWorkerResponse";
            };

            return TryCleanupAssignedWorkerResponse;
        })();

        core.Worker = (function() {

            /**
             * Constructs a new Worker service.
             * @memberof api.core
             * @classdesc Represents a Worker
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function Worker(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (Worker.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Worker;

            /**
             * Creates new Worker service using the specified rpc implementation.
             * @function create
             * @memberof api.core.Worker
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {Worker} RPC service. Useful where requests and/or responses are streamed.
             */
            Worker.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.Worker#dataQuery}.
             * @memberof api.core.Worker
             * @typedef DataQueryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.DataQueryResponse} [response] DataQueryResponse
             */

            /**
             * Calls DataQuery.
             * @function dataQuery
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IDataQueryRequest} request DataQueryRequest message or plain object
             * @param {api.core.Worker.DataQueryCallback} callback Node-style callback called with the error, if any, and DataQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.dataQuery = function dataQuery(request, callback) {
                return this.rpcCall(dataQuery, $root.api.core.DataQueryRequest, $root.api.core.DataQueryResponse, request, callback);
            }, "name", { value: "DataQuery" });

            /**
             * Calls DataQuery.
             * @function dataQuery
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IDataQueryRequest} request DataQueryRequest message or plain object
             * @returns {Promise<api.core.DataQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#queryFronter}.
             * @memberof api.core.Worker
             * @typedef QueryFronterCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QueryFronterResponse} [response] QueryFronterResponse
             */

            /**
             * Calls QueryFronter.
             * @function queryFronter
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IQueryFronterRequest} request QueryFronterRequest message or plain object
             * @param {api.core.Worker.QueryFronterCallback} callback Node-style callback called with the error, if any, and QueryFronterResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.queryFronter = function queryFronter(request, callback) {
                return this.rpcCall(queryFronter, $root.api.core.QueryFronterRequest, $root.api.core.QueryFronterResponse, request, callback);
            }, "name", { value: "QueryFronter" });

            /**
             * Calls QueryFronter.
             * @function queryFronter
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IQueryFronterRequest} request QueryFronterRequest message or plain object
             * @returns {Promise<api.core.QueryFronterResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#query}.
             * @memberof api.core.Worker
             * @typedef QueryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.WorkerQueryResponse} [response] WorkerQueryResponse
             */

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerQueryRequest} request WorkerQueryRequest message or plain object
             * @param {api.core.Worker.QueryCallback} callback Node-style callback called with the error, if any, and WorkerQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.query = function query(request, callback) {
                return this.rpcCall(query, $root.api.core.WorkerQueryRequest, $root.api.core.WorkerQueryResponse, request, callback);
            }, "name", { value: "Query" });

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerQueryRequest} request WorkerQueryRequest message or plain object
             * @returns {Promise<api.core.WorkerQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#create}.
             * @memberof api.core.Worker
             * @typedef CreateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerCreateRequest} request WorkerCreateRequest message or plain object
             * @param {api.core.Worker.CreateCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.create = function create(request, callback) {
                return this.rpcCall(create, $root.api.core.WorkerCreateRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Create" });

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerCreateRequest} request WorkerCreateRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#update}.
             * @memberof api.core.Worker
             * @typedef UpdateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Update.
             * @function update
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerUpdateRequest} request WorkerUpdateRequest message or plain object
             * @param {api.core.Worker.UpdateCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.update = function update(request, callback) {
                return this.rpcCall(update, $root.api.core.WorkerUpdateRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Update" });

            /**
             * Calls Update.
             * @function update
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerUpdateRequest} request WorkerUpdateRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#delete_}.
             * @memberof api.core.Worker
             * @typedef DeleteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Delete.
             * @function delete
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerDeleteRequest} request WorkerDeleteRequest message or plain object
             * @param {api.core.Worker.DeleteCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype["delete"] = function delete_(request, callback) {
                return this.rpcCall(delete_, $root.api.core.WorkerDeleteRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Delete" });

            /**
             * Calls Delete.
             * @function delete
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerDeleteRequest} request WorkerDeleteRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#transfer}.
             * @memberof api.core.Worker
             * @typedef TransferCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Transfer.
             * @function transfer
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerTransferRequest} request WorkerTransferRequest message or plain object
             * @param {api.core.Worker.TransferCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.transfer = function transfer(request, callback) {
                return this.rpcCall(transfer, $root.api.core.WorkerTransferRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Transfer" });

            /**
             * Calls Transfer.
             * @function transfer
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IWorkerTransferRequest} request WorkerTransferRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#getWorkerStatus}.
             * @memberof api.core.Worker
             * @typedef GetWorkerStatusCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.GetWorkerStatusResponse} [response] GetWorkerStatusResponse
             */

            /**
             * Calls GetWorkerStatus.
             * @function getWorkerStatus
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IGetWorkerStatusRequest} request GetWorkerStatusRequest message or plain object
             * @param {api.core.Worker.GetWorkerStatusCallback} callback Node-style callback called with the error, if any, and GetWorkerStatusResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.getWorkerStatus = function getWorkerStatus(request, callback) {
                return this.rpcCall(getWorkerStatus, $root.api.core.GetWorkerStatusRequest, $root.api.core.GetWorkerStatusResponse, request, callback);
            }, "name", { value: "GetWorkerStatus" });

            /**
             * Calls GetWorkerStatus.
             * @function getWorkerStatus
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IGetWorkerStatusRequest} request GetWorkerStatusRequest message or plain object
             * @returns {Promise<api.core.GetWorkerStatusResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#setWorkerStatus}.
             * @memberof api.core.Worker
             * @typedef SetWorkerStatusCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls SetWorkerStatus.
             * @function setWorkerStatus
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.ISetWorkerStatusRequest} request SetWorkerStatusRequest message or plain object
             * @param {api.core.Worker.SetWorkerStatusCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.setWorkerStatus = function setWorkerStatus(request, callback) {
                return this.rpcCall(setWorkerStatus, $root.api.core.SetWorkerStatusRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "SetWorkerStatus" });

            /**
             * Calls SetWorkerStatus.
             * @function setWorkerStatus
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.ISetWorkerStatusRequest} request SetWorkerStatusRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#checkWorkerStatus}.
             * @memberof api.core.Worker
             * @typedef CheckWorkerStatusCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls CheckWorkerStatus.
             * @function checkWorkerStatus
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.ICheckWorkerStatusRequest} request CheckWorkerStatusRequest message or plain object
             * @param {api.core.Worker.CheckWorkerStatusCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.checkWorkerStatus = function checkWorkerStatus(request, callback) {
                return this.rpcCall(checkWorkerStatus, $root.api.core.CheckWorkerStatusRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "CheckWorkerStatus" });

            /**
             * Calls CheckWorkerStatus.
             * @function checkWorkerStatus
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.ICheckWorkerStatusRequest} request CheckWorkerStatusRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#password}.
             * @memberof api.core.Worker
             * @typedef PasswordCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Password.
             * @function password
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IPasswordRequest} request PasswordRequest message or plain object
             * @param {api.core.Worker.PasswordCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.password = function password(request, callback) {
                return this.rpcCall(password, $root.api.core.PasswordRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Password" });

            /**
             * Calls Password.
             * @function password
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IPasswordRequest} request PasswordRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#blacklistApply}.
             * @memberof api.core.Worker
             * @typedef BlacklistApplyCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls BlacklistApply.
             * @function blacklistApply
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IBlacklistApplyRequest} request BlacklistApplyRequest message or plain object
             * @param {api.core.Worker.BlacklistApplyCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.blacklistApply = function blacklistApply(request, callback) {
                return this.rpcCall(blacklistApply, $root.api.core.BlacklistApplyRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "BlacklistApply" });

            /**
             * Calls BlacklistApply.
             * @function blacklistApply
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IBlacklistApplyRequest} request BlacklistApplyRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#blacklistConfirm}.
             * @memberof api.core.Worker
             * @typedef BlacklistConfirmCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls BlacklistConfirm.
             * @function blacklistConfirm
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IBlacklistConfirmRequest} request BlacklistConfirmRequest message or plain object
             * @param {api.core.Worker.BlacklistConfirmCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.blacklistConfirm = function blacklistConfirm(request, callback) {
                return this.rpcCall(blacklistConfirm, $root.api.core.BlacklistConfirmRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "BlacklistConfirm" });

            /**
             * Calls BlacklistConfirm.
             * @function blacklistConfirm
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IBlacklistConfirmRequest} request BlacklistConfirmRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#blacklistRecover}.
             * @memberof api.core.Worker
             * @typedef BlacklistRecoverCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls BlacklistRecover.
             * @function blacklistRecover
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IBlacklistRecoverRequest} request BlacklistRecoverRequest message or plain object
             * @param {api.core.Worker.BlacklistRecoverCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.blacklistRecover = function blacklistRecover(request, callback) {
                return this.rpcCall(blacklistRecover, $root.api.core.BlacklistRecoverRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "BlacklistRecover" });

            /**
             * Calls BlacklistRecover.
             * @function blacklistRecover
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IBlacklistRecoverRequest} request BlacklistRecoverRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#queryByPerm}.
             * @memberof api.core.Worker
             * @typedef QueryByPermCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.QuerySpecPermWorkerResponse} [response] QuerySpecPermWorkerResponse
             */

            /**
             * Calls QueryByPerm.
             * @function queryByPerm
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IQuerySpecPermWorkerRequest} request QuerySpecPermWorkerRequest message or plain object
             * @param {api.core.Worker.QueryByPermCallback} callback Node-style callback called with the error, if any, and QuerySpecPermWorkerResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.queryByPerm = function queryByPerm(request, callback) {
                return this.rpcCall(queryByPerm, $root.api.core.QuerySpecPermWorkerRequest, $root.api.core.QuerySpecPermWorkerResponse, request, callback);
            }, "name", { value: "QueryByPerm" });

            /**
             * Calls QueryByPerm.
             * @function queryByPerm
             * @memberof api.core.Worker
             * @instance
             * @param {api.core.IQuerySpecPermWorkerRequest} request QuerySpecPermWorkerRequest message or plain object
             * @returns {Promise<api.core.QuerySpecPermWorkerResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#tryCleanupAssignedWorker}.
             * @memberof api.core.Worker
             * @typedef TryCleanupAssignedWorkerCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.TryCleanupAssignedWorkerResponse} [response] TryCleanupAssignedWorkerResponse
             */

            /**
             * Calls TryCleanupAssignedWorker.
             * @function tryCleanupAssignedWorker
             * @memberof api.core.Worker
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Worker.TryCleanupAssignedWorkerCallback} callback Node-style callback called with the error, if any, and TryCleanupAssignedWorkerResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.tryCleanupAssignedWorker = function tryCleanupAssignedWorker(request, callback) {
                return this.rpcCall(tryCleanupAssignedWorker, $root.google.protobuf.Empty, $root.api.core.TryCleanupAssignedWorkerResponse, request, callback);
            }, "name", { value: "TryCleanupAssignedWorker" });

            /**
             * Calls TryCleanupAssignedWorker.
             * @function tryCleanupAssignedWorker
             * @memberof api.core.Worker
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.TryCleanupAssignedWorkerResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.Worker#querySelf}.
             * @memberof api.core.Worker
             * @typedef QuerySelfCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.WorkerQuerySelfResponse} [response] WorkerQuerySelfResponse
             */

            /**
             * Calls QuerySelf.
             * @function querySelf
             * @memberof api.core.Worker
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.Worker.QuerySelfCallback} callback Node-style callback called with the error, if any, and WorkerQuerySelfResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(Worker.prototype.querySelf = function querySelf(request, callback) {
                return this.rpcCall(querySelf, $root.google.protobuf.Empty, $root.api.core.WorkerQuerySelfResponse, request, callback);
            }, "name", { value: "QuerySelf" });

            /**
             * Calls QuerySelf.
             * @function querySelf
             * @memberof api.core.Worker
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.WorkerQuerySelfResponse>} Promise
             * @variation 2
             */

            return Worker;
        })();

        core.WorkerGroupQueryResponse = (function() {

            /**
             * Properties of a WorkerGroupQueryResponse.
             * @memberof api.core
             * @interface IWorkerGroupQueryResponse
             * @property {Array.<api.common.IWorkerGroup>|null} [items] WorkerGroupQueryResponse items
             */

            /**
             * Constructs a new WorkerGroupQueryResponse.
             * @memberof api.core
             * @classdesc Represents a WorkerGroupQueryResponse.
             * @implements IWorkerGroupQueryResponse
             * @constructor
             * @param {api.core.IWorkerGroupQueryResponse=} [properties] Properties to set
             */
            function WorkerGroupQueryResponse(properties) {
                this.items = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerGroupQueryResponse items.
             * @member {Array.<api.common.IWorkerGroup>} items
             * @memberof api.core.WorkerGroupQueryResponse
             * @instance
             */
            WorkerGroupQueryResponse.prototype.items = $util.emptyArray;

            /**
             * Creates a new WorkerGroupQueryResponse instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {api.core.IWorkerGroupQueryResponse=} [properties] Properties to set
             * @returns {api.core.WorkerGroupQueryResponse} WorkerGroupQueryResponse instance
             */
            WorkerGroupQueryResponse.create = function create(properties) {
                return new WorkerGroupQueryResponse(properties);
            };

            /**
             * Encodes the specified WorkerGroupQueryResponse message. Does not implicitly {@link api.core.WorkerGroupQueryResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {api.core.IWorkerGroupQueryResponse} message WorkerGroupQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupQueryResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.items != null && message.items.length)
                    for (let i = 0; i < message.items.length; ++i)
                        $root.api.common.WorkerGroup.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified WorkerGroupQueryResponse message, length delimited. Does not implicitly {@link api.core.WorkerGroupQueryResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {api.core.IWorkerGroupQueryResponse} message WorkerGroupQueryResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupQueryResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerGroupQueryResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerGroupQueryResponse} WorkerGroupQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupQueryResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerGroupQueryResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.api.common.WorkerGroup.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerGroupQueryResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerGroupQueryResponse} WorkerGroupQueryResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupQueryResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerGroupQueryResponse message.
             * @function verify
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerGroupQueryResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (let i = 0; i < message.items.length; ++i) {
                        let error = $root.api.common.WorkerGroup.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a WorkerGroupQueryResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerGroupQueryResponse} WorkerGroupQueryResponse
             */
            WorkerGroupQueryResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerGroupQueryResponse)
                    return object;
                let message = new $root.api.core.WorkerGroupQueryResponse();
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".api.core.WorkerGroupQueryResponse.items: array expected");
                    message.items = [];
                    for (let i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".api.core.WorkerGroupQueryResponse.items: object expected");
                        message.items[i] = $root.api.common.WorkerGroup.fromObject(object.items[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a WorkerGroupQueryResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {api.core.WorkerGroupQueryResponse} message WorkerGroupQueryResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerGroupQueryResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.items = [];
                if (message.items && message.items.length) {
                    object.items = [];
                    for (let j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.api.common.WorkerGroup.toObject(message.items[j], options);
                }
                return object;
            };

            /**
             * Converts this WorkerGroupQueryResponse to JSON.
             * @function toJSON
             * @memberof api.core.WorkerGroupQueryResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerGroupQueryResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerGroupQueryResponse
             * @function getTypeUrl
             * @memberof api.core.WorkerGroupQueryResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerGroupQueryResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerGroupQueryResponse";
            };

            return WorkerGroupQueryResponse;
        })();

        core.WorkerGroupCreateRequest = (function() {

            /**
             * Properties of a WorkerGroupCreateRequest.
             * @memberof api.core
             * @interface IWorkerGroupCreateRequest
             * @property {string|null} [name] WorkerGroupCreateRequest name
             * @property {number|null} [priority] WorkerGroupCreateRequest priority
             */

            /**
             * Constructs a new WorkerGroupCreateRequest.
             * @memberof api.core
             * @classdesc Represents a WorkerGroupCreateRequest.
             * @implements IWorkerGroupCreateRequest
             * @constructor
             * @param {api.core.IWorkerGroupCreateRequest=} [properties] Properties to set
             */
            function WorkerGroupCreateRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerGroupCreateRequest name.
             * @member {string} name
             * @memberof api.core.WorkerGroupCreateRequest
             * @instance
             */
            WorkerGroupCreateRequest.prototype.name = "";

            /**
             * WorkerGroupCreateRequest priority.
             * @member {number} priority
             * @memberof api.core.WorkerGroupCreateRequest
             * @instance
             */
            WorkerGroupCreateRequest.prototype.priority = 0;

            /**
             * Creates a new WorkerGroupCreateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {api.core.IWorkerGroupCreateRequest=} [properties] Properties to set
             * @returns {api.core.WorkerGroupCreateRequest} WorkerGroupCreateRequest instance
             */
            WorkerGroupCreateRequest.create = function create(properties) {
                return new WorkerGroupCreateRequest(properties);
            };

            /**
             * Encodes the specified WorkerGroupCreateRequest message. Does not implicitly {@link api.core.WorkerGroupCreateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {api.core.IWorkerGroupCreateRequest} message WorkerGroupCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupCreateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified WorkerGroupCreateRequest message, length delimited. Does not implicitly {@link api.core.WorkerGroupCreateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {api.core.IWorkerGroupCreateRequest} message WorkerGroupCreateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupCreateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerGroupCreateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerGroupCreateRequest} WorkerGroupCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupCreateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerGroupCreateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerGroupCreateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerGroupCreateRequest} WorkerGroupCreateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupCreateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerGroupCreateRequest message.
             * @function verify
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerGroupCreateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a WorkerGroupCreateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerGroupCreateRequest} WorkerGroupCreateRequest
             */
            WorkerGroupCreateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerGroupCreateRequest)
                    return object;
                let message = new $root.api.core.WorkerGroupCreateRequest();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerGroupCreateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {api.core.WorkerGroupCreateRequest} message WorkerGroupCreateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerGroupCreateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.priority = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this WorkerGroupCreateRequest to JSON.
             * @function toJSON
             * @memberof api.core.WorkerGroupCreateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerGroupCreateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerGroupCreateRequest
             * @function getTypeUrl
             * @memberof api.core.WorkerGroupCreateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerGroupCreateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerGroupCreateRequest";
            };

            return WorkerGroupCreateRequest;
        })();

        core.WorkerGroupCreateResponse = (function() {

            /**
             * Properties of a WorkerGroupCreateResponse.
             * @memberof api.core
             * @interface IWorkerGroupCreateResponse
             * @property {Long|null} [id] WorkerGroupCreateResponse id
             * @property {string|null} [name] WorkerGroupCreateResponse name
             * @property {number|null} [priority] WorkerGroupCreateResponse priority
             */

            /**
             * Constructs a new WorkerGroupCreateResponse.
             * @memberof api.core
             * @classdesc Represents a WorkerGroupCreateResponse.
             * @implements IWorkerGroupCreateResponse
             * @constructor
             * @param {api.core.IWorkerGroupCreateResponse=} [properties] Properties to set
             */
            function WorkerGroupCreateResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerGroupCreateResponse id.
             * @member {Long} id
             * @memberof api.core.WorkerGroupCreateResponse
             * @instance
             */
            WorkerGroupCreateResponse.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * WorkerGroupCreateResponse name.
             * @member {string} name
             * @memberof api.core.WorkerGroupCreateResponse
             * @instance
             */
            WorkerGroupCreateResponse.prototype.name = "";

            /**
             * WorkerGroupCreateResponse priority.
             * @member {number} priority
             * @memberof api.core.WorkerGroupCreateResponse
             * @instance
             */
            WorkerGroupCreateResponse.prototype.priority = 0;

            /**
             * Creates a new WorkerGroupCreateResponse instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {api.core.IWorkerGroupCreateResponse=} [properties] Properties to set
             * @returns {api.core.WorkerGroupCreateResponse} WorkerGroupCreateResponse instance
             */
            WorkerGroupCreateResponse.create = function create(properties) {
                return new WorkerGroupCreateResponse(properties);
            };

            /**
             * Encodes the specified WorkerGroupCreateResponse message. Does not implicitly {@link api.core.WorkerGroupCreateResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {api.core.IWorkerGroupCreateResponse} message WorkerGroupCreateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupCreateResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified WorkerGroupCreateResponse message, length delimited. Does not implicitly {@link api.core.WorkerGroupCreateResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {api.core.IWorkerGroupCreateResponse} message WorkerGroupCreateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupCreateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerGroupCreateResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerGroupCreateResponse} WorkerGroupCreateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupCreateResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerGroupCreateResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerGroupCreateResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerGroupCreateResponse} WorkerGroupCreateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupCreateResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerGroupCreateResponse message.
             * @function verify
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerGroupCreateResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a WorkerGroupCreateResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerGroupCreateResponse} WorkerGroupCreateResponse
             */
            WorkerGroupCreateResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerGroupCreateResponse)
                    return object;
                let message = new $root.api.core.WorkerGroupCreateResponse();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerGroupCreateResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {api.core.WorkerGroupCreateResponse} message WorkerGroupCreateResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerGroupCreateResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.priority = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this WorkerGroupCreateResponse to JSON.
             * @function toJSON
             * @memberof api.core.WorkerGroupCreateResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerGroupCreateResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerGroupCreateResponse
             * @function getTypeUrl
             * @memberof api.core.WorkerGroupCreateResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerGroupCreateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerGroupCreateResponse";
            };

            return WorkerGroupCreateResponse;
        })();

        core.WorkerGroupUpdateRequest = (function() {

            /**
             * Properties of a WorkerGroupUpdateRequest.
             * @memberof api.core
             * @interface IWorkerGroupUpdateRequest
             * @property {Long|null} [id] WorkerGroupUpdateRequest id
             * @property {string|null} [name] WorkerGroupUpdateRequest name
             * @property {number|null} [priority] WorkerGroupUpdateRequest priority
             */

            /**
             * Constructs a new WorkerGroupUpdateRequest.
             * @memberof api.core
             * @classdesc Represents a WorkerGroupUpdateRequest.
             * @implements IWorkerGroupUpdateRequest
             * @constructor
             * @param {api.core.IWorkerGroupUpdateRequest=} [properties] Properties to set
             */
            function WorkerGroupUpdateRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerGroupUpdateRequest id.
             * @member {Long} id
             * @memberof api.core.WorkerGroupUpdateRequest
             * @instance
             */
            WorkerGroupUpdateRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * WorkerGroupUpdateRequest name.
             * @member {string} name
             * @memberof api.core.WorkerGroupUpdateRequest
             * @instance
             */
            WorkerGroupUpdateRequest.prototype.name = "";

            /**
             * WorkerGroupUpdateRequest priority.
             * @member {number} priority
             * @memberof api.core.WorkerGroupUpdateRequest
             * @instance
             */
            WorkerGroupUpdateRequest.prototype.priority = 0;

            /**
             * Creates a new WorkerGroupUpdateRequest instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {api.core.IWorkerGroupUpdateRequest=} [properties] Properties to set
             * @returns {api.core.WorkerGroupUpdateRequest} WorkerGroupUpdateRequest instance
             */
            WorkerGroupUpdateRequest.create = function create(properties) {
                return new WorkerGroupUpdateRequest(properties);
            };

            /**
             * Encodes the specified WorkerGroupUpdateRequest message. Does not implicitly {@link api.core.WorkerGroupUpdateRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {api.core.IWorkerGroupUpdateRequest} message WorkerGroupUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupUpdateRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified WorkerGroupUpdateRequest message, length delimited. Does not implicitly {@link api.core.WorkerGroupUpdateRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {api.core.IWorkerGroupUpdateRequest} message WorkerGroupUpdateRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerGroupUpdateRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerGroupUpdateRequest} WorkerGroupUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupUpdateRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerGroupUpdateRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerGroupUpdateRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerGroupUpdateRequest} WorkerGroupUpdateRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerGroupUpdateRequest message.
             * @function verify
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerGroupUpdateRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a WorkerGroupUpdateRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerGroupUpdateRequest} WorkerGroupUpdateRequest
             */
            WorkerGroupUpdateRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerGroupUpdateRequest)
                    return object;
                let message = new $root.api.core.WorkerGroupUpdateRequest();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerGroupUpdateRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {api.core.WorkerGroupUpdateRequest} message WorkerGroupUpdateRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerGroupUpdateRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.priority = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this WorkerGroupUpdateRequest to JSON.
             * @function toJSON
             * @memberof api.core.WorkerGroupUpdateRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerGroupUpdateRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerGroupUpdateRequest
             * @function getTypeUrl
             * @memberof api.core.WorkerGroupUpdateRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerGroupUpdateRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerGroupUpdateRequest";
            };

            return WorkerGroupUpdateRequest;
        })();

        core.WorkerGroupUpdateResponse = (function() {

            /**
             * Properties of a WorkerGroupUpdateResponse.
             * @memberof api.core
             * @interface IWorkerGroupUpdateResponse
             * @property {Long|null} [id] WorkerGroupUpdateResponse id
             * @property {string|null} [name] WorkerGroupUpdateResponse name
             * @property {number|null} [priority] WorkerGroupUpdateResponse priority
             */

            /**
             * Constructs a new WorkerGroupUpdateResponse.
             * @memberof api.core
             * @classdesc Represents a WorkerGroupUpdateResponse.
             * @implements IWorkerGroupUpdateResponse
             * @constructor
             * @param {api.core.IWorkerGroupUpdateResponse=} [properties] Properties to set
             */
            function WorkerGroupUpdateResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerGroupUpdateResponse id.
             * @member {Long} id
             * @memberof api.core.WorkerGroupUpdateResponse
             * @instance
             */
            WorkerGroupUpdateResponse.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * WorkerGroupUpdateResponse name.
             * @member {string} name
             * @memberof api.core.WorkerGroupUpdateResponse
             * @instance
             */
            WorkerGroupUpdateResponse.prototype.name = "";

            /**
             * WorkerGroupUpdateResponse priority.
             * @member {number} priority
             * @memberof api.core.WorkerGroupUpdateResponse
             * @instance
             */
            WorkerGroupUpdateResponse.prototype.priority = 0;

            /**
             * Creates a new WorkerGroupUpdateResponse instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {api.core.IWorkerGroupUpdateResponse=} [properties] Properties to set
             * @returns {api.core.WorkerGroupUpdateResponse} WorkerGroupUpdateResponse instance
             */
            WorkerGroupUpdateResponse.create = function create(properties) {
                return new WorkerGroupUpdateResponse(properties);
            };

            /**
             * Encodes the specified WorkerGroupUpdateResponse message. Does not implicitly {@link api.core.WorkerGroupUpdateResponse.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {api.core.IWorkerGroupUpdateResponse} message WorkerGroupUpdateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupUpdateResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
                return writer;
            };

            /**
             * Encodes the specified WorkerGroupUpdateResponse message, length delimited. Does not implicitly {@link api.core.WorkerGroupUpdateResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {api.core.IWorkerGroupUpdateResponse} message WorkerGroupUpdateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerGroupUpdateResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerGroupUpdateResponse} WorkerGroupUpdateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupUpdateResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerGroupUpdateResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.priority = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerGroupUpdateResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerGroupUpdateResponse} WorkerGroupUpdateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerGroupUpdateResponse message.
             * @function verify
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerGroupUpdateResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.priority != null && message.hasOwnProperty("priority"))
                    if (!$util.isInteger(message.priority))
                        return "priority: integer expected";
                return null;
            };

            /**
             * Creates a WorkerGroupUpdateResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerGroupUpdateResponse} WorkerGroupUpdateResponse
             */
            WorkerGroupUpdateResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerGroupUpdateResponse)
                    return object;
                let message = new $root.api.core.WorkerGroupUpdateResponse();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.priority != null)
                    message.priority = object.priority | 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkerGroupUpdateResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {api.core.WorkerGroupUpdateResponse} message WorkerGroupUpdateResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerGroupUpdateResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.priority = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = message.priority;
                return object;
            };

            /**
             * Converts this WorkerGroupUpdateResponse to JSON.
             * @function toJSON
             * @memberof api.core.WorkerGroupUpdateResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerGroupUpdateResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerGroupUpdateResponse
             * @function getTypeUrl
             * @memberof api.core.WorkerGroupUpdateResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerGroupUpdateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerGroupUpdateResponse";
            };

            return WorkerGroupUpdateResponse;
        })();

        core.WorkerGroupDeleteRequest = (function() {

            /**
             * Properties of a WorkerGroupDeleteRequest.
             * @memberof api.core
             * @interface IWorkerGroupDeleteRequest
             * @property {Long|null} [id] WorkerGroupDeleteRequest id
             */

            /**
             * Constructs a new WorkerGroupDeleteRequest.
             * @memberof api.core
             * @classdesc Represents a WorkerGroupDeleteRequest.
             * @implements IWorkerGroupDeleteRequest
             * @constructor
             * @param {api.core.IWorkerGroupDeleteRequest=} [properties] Properties to set
             */
            function WorkerGroupDeleteRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkerGroupDeleteRequest id.
             * @member {Long} id
             * @memberof api.core.WorkerGroupDeleteRequest
             * @instance
             */
            WorkerGroupDeleteRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new WorkerGroupDeleteRequest instance using the specified properties.
             * @function create
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {api.core.IWorkerGroupDeleteRequest=} [properties] Properties to set
             * @returns {api.core.WorkerGroupDeleteRequest} WorkerGroupDeleteRequest instance
             */
            WorkerGroupDeleteRequest.create = function create(properties) {
                return new WorkerGroupDeleteRequest(properties);
            };

            /**
             * Encodes the specified WorkerGroupDeleteRequest message. Does not implicitly {@link api.core.WorkerGroupDeleteRequest.verify|verify} messages.
             * @function encode
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {api.core.IWorkerGroupDeleteRequest} message WorkerGroupDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupDeleteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                return writer;
            };

            /**
             * Encodes the specified WorkerGroupDeleteRequest message, length delimited. Does not implicitly {@link api.core.WorkerGroupDeleteRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {api.core.IWorkerGroupDeleteRequest} message WorkerGroupDeleteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkerGroupDeleteRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkerGroupDeleteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.core.WorkerGroupDeleteRequest} WorkerGroupDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupDeleteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.core.WorkerGroupDeleteRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkerGroupDeleteRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.core.WorkerGroupDeleteRequest} WorkerGroupDeleteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkerGroupDeleteRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkerGroupDeleteRequest message.
             * @function verify
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkerGroupDeleteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                return null;
            };

            /**
             * Creates a WorkerGroupDeleteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.core.WorkerGroupDeleteRequest} WorkerGroupDeleteRequest
             */
            WorkerGroupDeleteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.core.WorkerGroupDeleteRequest)
                    return object;
                let message = new $root.api.core.WorkerGroupDeleteRequest();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a WorkerGroupDeleteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {api.core.WorkerGroupDeleteRequest} message WorkerGroupDeleteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkerGroupDeleteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                return object;
            };

            /**
             * Converts this WorkerGroupDeleteRequest to JSON.
             * @function toJSON
             * @memberof api.core.WorkerGroupDeleteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkerGroupDeleteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for WorkerGroupDeleteRequest
             * @function getTypeUrl
             * @memberof api.core.WorkerGroupDeleteRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            WorkerGroupDeleteRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.core.WorkerGroupDeleteRequest";
            };

            return WorkerGroupDeleteRequest;
        })();

        core.WorkerGroup = (function() {

            /**
             * Constructs a new WorkerGroup service.
             * @memberof api.core
             * @classdesc Represents a WorkerGroup
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function WorkerGroup(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (WorkerGroup.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WorkerGroup;

            /**
             * Creates new WorkerGroup service using the specified rpc implementation.
             * @function create
             * @memberof api.core.WorkerGroup
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {WorkerGroup} RPC service. Useful where requests and/or responses are streamed.
             */
            WorkerGroup.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link api.core.WorkerGroup#query}.
             * @memberof api.core.WorkerGroup
             * @typedef QueryCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.WorkerGroupQueryResponse} [response] WorkerGroupQueryResponse
             */

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.WorkerGroup.QueryCallback} callback Node-style callback called with the error, if any, and WorkerGroupQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(WorkerGroup.prototype.query = function query(request, callback) {
                return this.rpcCall(query, $root.google.protobuf.Empty, $root.api.core.WorkerGroupQueryResponse, request, callback);
            }, "name", { value: "Query" });

            /**
             * Calls Query.
             * @function query
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.WorkerGroupQueryResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.WorkerGroup#create}.
             * @memberof api.core.WorkerGroup
             * @typedef CreateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.WorkerGroupCreateResponse} [response] WorkerGroupCreateResponse
             */

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {api.core.IWorkerGroupCreateRequest} request WorkerGroupCreateRequest message or plain object
             * @param {api.core.WorkerGroup.CreateCallback} callback Node-style callback called with the error, if any, and WorkerGroupCreateResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(WorkerGroup.prototype.create = function create(request, callback) {
                return this.rpcCall(create, $root.api.core.WorkerGroupCreateRequest, $root.api.core.WorkerGroupCreateResponse, request, callback);
            }, "name", { value: "Create" });

            /**
             * Calls Create.
             * @function create
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {api.core.IWorkerGroupCreateRequest} request WorkerGroupCreateRequest message or plain object
             * @returns {Promise<api.core.WorkerGroupCreateResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.WorkerGroup#update}.
             * @memberof api.core.WorkerGroup
             * @typedef UpdateCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.WorkerGroupUpdateResponse} [response] WorkerGroupUpdateResponse
             */

            /**
             * Calls Update.
             * @function update
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {api.core.IWorkerGroupUpdateRequest} request WorkerGroupUpdateRequest message or plain object
             * @param {api.core.WorkerGroup.UpdateCallback} callback Node-style callback called with the error, if any, and WorkerGroupUpdateResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(WorkerGroup.prototype.update = function update(request, callback) {
                return this.rpcCall(update, $root.api.core.WorkerGroupUpdateRequest, $root.api.core.WorkerGroupUpdateResponse, request, callback);
            }, "name", { value: "Update" });

            /**
             * Calls Update.
             * @function update
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {api.core.IWorkerGroupUpdateRequest} request WorkerGroupUpdateRequest message or plain object
             * @returns {Promise<api.core.WorkerGroupUpdateResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.WorkerGroup#delete_}.
             * @memberof api.core.WorkerGroup
             * @typedef DeleteCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {google.protobuf.Empty} [response] Empty
             */

            /**
             * Calls Delete.
             * @function delete
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {api.core.IWorkerGroupDeleteRequest} request WorkerGroupDeleteRequest message or plain object
             * @param {api.core.WorkerGroup.DeleteCallback} callback Node-style callback called with the error, if any, and Empty
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(WorkerGroup.prototype["delete"] = function delete_(request, callback) {
                return this.rpcCall(delete_, $root.api.core.WorkerGroupDeleteRequest, $root.google.protobuf.Empty, request, callback);
            }, "name", { value: "Delete" });

            /**
             * Calls Delete.
             * @function delete
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {api.core.IWorkerGroupDeleteRequest} request WorkerGroupDeleteRequest message or plain object
             * @returns {Promise<google.protobuf.Empty>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link api.core.WorkerGroup#queryByPerm}.
             * @memberof api.core.WorkerGroup
             * @typedef QueryByPermCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {api.core.WorkerGroupQueryResponse} [response] WorkerGroupQueryResponse
             */

            /**
             * Calls QueryByPerm.
             * @function queryByPerm
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @param {api.core.WorkerGroup.QueryByPermCallback} callback Node-style callback called with the error, if any, and WorkerGroupQueryResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(WorkerGroup.prototype.queryByPerm = function queryByPerm(request, callback) {
                return this.rpcCall(queryByPerm, $root.google.protobuf.Empty, $root.api.core.WorkerGroupQueryResponse, request, callback);
            }, "name", { value: "QueryByPerm" });

            /**
             * Calls QueryByPerm.
             * @function queryByPerm
             * @memberof api.core.WorkerGroup
             * @instance
             * @param {google.protobuf.IEmpty} request Empty message or plain object
             * @returns {Promise<api.core.WorkerGroupQueryResponse>} Promise
             * @variation 2
             */

            return WorkerGroup;
        })();

        return core;
    })();

    api.sentry = (function() {

        /**
         * Namespace sentry.
         * @memberof api
         * @namespace
         */
        const sentry = {};

        /**
         * AuthRole enum.
         * @name api.sentry.AuthRole
         * @enum {number}
         * @property {number} ROLE_NONE=0 ROLE_NONE value
         * @property {number} ROLE_ANONYMOUS=1 ROLE_ANONYMOUS value
         * @property {number} ROLE_CUSTOMER=2 ROLE_CUSTOMER value
         * @property {number} ROLE_WORKER=3 ROLE_WORKER value
         * @property {number} ROLE_SYSTEM=4 ROLE_SYSTEM value
         * @property {number} ROLE_ENTRANCE=5 ROLE_ENTRANCE value
         * @property {number} ROLE_ADMINISTRATOR=6 ROLE_ADMINISTRATOR value
         */
        sentry.AuthRole = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ROLE_NONE"] = 0;
            values[valuesById[1] = "ROLE_ANONYMOUS"] = 1;
            values[valuesById[2] = "ROLE_CUSTOMER"] = 2;
            values[valuesById[3] = "ROLE_WORKER"] = 3;
            values[valuesById[4] = "ROLE_SYSTEM"] = 4;
            values[valuesById[5] = "ROLE_ENTRANCE"] = 5;
            values[valuesById[6] = "ROLE_ADMINISTRATOR"] = 6;
            return values;
        })();

        sentry.AuthToken = (function() {

            /**
             * Properties of an AuthToken.
             * @memberof api.sentry
             * @interface IAuthToken
             * @property {number|null} [tenantId] AuthToken tenantId
             * @property {api.sentry.AuthRole|null} [ownerRole] AuthToken ownerRole
             * @property {number|null} [ownerId] AuthToken ownerId
             * @property {number|null} [ownerSession] AuthToken ownerSession
             * @property {Long|null} [ownerSessionTime] AuthToken ownerSessionTime
             * @property {Long|null} [ownerDevice] AuthToken ownerDevice
             */

            /**
             * Constructs a new AuthToken.
             * @memberof api.sentry
             * @classdesc Represents an AuthToken.
             * @implements IAuthToken
             * @constructor
             * @param {api.sentry.IAuthToken=} [properties] Properties to set
             */
            function AuthToken(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AuthToken tenantId.
             * @member {number} tenantId
             * @memberof api.sentry.AuthToken
             * @instance
             */
            AuthToken.prototype.tenantId = 0;

            /**
             * AuthToken ownerRole.
             * @member {api.sentry.AuthRole} ownerRole
             * @memberof api.sentry.AuthToken
             * @instance
             */
            AuthToken.prototype.ownerRole = 0;

            /**
             * AuthToken ownerId.
             * @member {number} ownerId
             * @memberof api.sentry.AuthToken
             * @instance
             */
            AuthToken.prototype.ownerId = 0;

            /**
             * AuthToken ownerSession.
             * @member {number} ownerSession
             * @memberof api.sentry.AuthToken
             * @instance
             */
            AuthToken.prototype.ownerSession = 0;

            /**
             * AuthToken ownerSessionTime.
             * @member {Long} ownerSessionTime
             * @memberof api.sentry.AuthToken
             * @instance
             */
            AuthToken.prototype.ownerSessionTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * AuthToken ownerDevice.
             * @member {Long} ownerDevice
             * @memberof api.sentry.AuthToken
             * @instance
             */
            AuthToken.prototype.ownerDevice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new AuthToken instance using the specified properties.
             * @function create
             * @memberof api.sentry.AuthToken
             * @static
             * @param {api.sentry.IAuthToken=} [properties] Properties to set
             * @returns {api.sentry.AuthToken} AuthToken instance
             */
            AuthToken.create = function create(properties) {
                return new AuthToken(properties);
            };

            /**
             * Encodes the specified AuthToken message. Does not implicitly {@link api.sentry.AuthToken.verify|verify} messages.
             * @function encode
             * @memberof api.sentry.AuthToken
             * @static
             * @param {api.sentry.IAuthToken} message AuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tenantId);
                if (message.ownerRole != null && Object.hasOwnProperty.call(message, "ownerRole"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ownerRole);
                if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ownerId);
                if (message.ownerSession != null && Object.hasOwnProperty.call(message, "ownerSession"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ownerSession);
                if (message.ownerSessionTime != null && Object.hasOwnProperty.call(message, "ownerSessionTime"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.ownerSessionTime);
                if (message.ownerDevice != null && Object.hasOwnProperty.call(message, "ownerDevice"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.ownerDevice);
                return writer;
            };

            /**
             * Encodes the specified AuthToken message, length delimited. Does not implicitly {@link api.sentry.AuthToken.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.sentry.AuthToken
             * @static
             * @param {api.sentry.IAuthToken} message AuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthToken.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof api.sentry.AuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.sentry.AuthToken} AuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.sentry.AuthToken();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.tenantId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.ownerRole = reader.int32();
                            break;
                        }
                    case 3: {
                            message.ownerId = reader.int32();
                            break;
                        }
                    case 4: {
                            message.ownerSession = reader.int32();
                            break;
                        }
                    case 5: {
                            message.ownerSessionTime = reader.int64();
                            break;
                        }
                    case 6: {
                            message.ownerDevice = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AuthToken message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.sentry.AuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.sentry.AuthToken} AuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthToken.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AuthToken message.
             * @function verify
             * @memberof api.sentry.AuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    if (!$util.isInteger(message.tenantId))
                        return "tenantId: integer expected";
                if (message.ownerRole != null && message.hasOwnProperty("ownerRole"))
                    switch (message.ownerRole) {
                    default:
                        return "ownerRole: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                    if (!$util.isInteger(message.ownerId))
                        return "ownerId: integer expected";
                if (message.ownerSession != null && message.hasOwnProperty("ownerSession"))
                    if (!$util.isInteger(message.ownerSession))
                        return "ownerSession: integer expected";
                if (message.ownerSessionTime != null && message.hasOwnProperty("ownerSessionTime"))
                    if (!$util.isInteger(message.ownerSessionTime) && !(message.ownerSessionTime && $util.isInteger(message.ownerSessionTime.low) && $util.isInteger(message.ownerSessionTime.high)))
                        return "ownerSessionTime: integer|Long expected";
                if (message.ownerDevice != null && message.hasOwnProperty("ownerDevice"))
                    if (!$util.isInteger(message.ownerDevice) && !(message.ownerDevice && $util.isInteger(message.ownerDevice.low) && $util.isInteger(message.ownerDevice.high)))
                        return "ownerDevice: integer|Long expected";
                return null;
            };

            /**
             * Creates an AuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.sentry.AuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.sentry.AuthToken} AuthToken
             */
            AuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.api.sentry.AuthToken)
                    return object;
                let message = new $root.api.sentry.AuthToken();
                if (object.tenantId != null)
                    message.tenantId = object.tenantId | 0;
                switch (object.ownerRole) {
                default:
                    if (typeof object.ownerRole === "number") {
                        message.ownerRole = object.ownerRole;
                        break;
                    }
                    break;
                case "ROLE_NONE":
                case 0:
                    message.ownerRole = 0;
                    break;
                case "ROLE_ANONYMOUS":
                case 1:
                    message.ownerRole = 1;
                    break;
                case "ROLE_CUSTOMER":
                case 2:
                    message.ownerRole = 2;
                    break;
                case "ROLE_WORKER":
                case 3:
                    message.ownerRole = 3;
                    break;
                case "ROLE_SYSTEM":
                case 4:
                    message.ownerRole = 4;
                    break;
                case "ROLE_ENTRANCE":
                case 5:
                    message.ownerRole = 5;
                    break;
                case "ROLE_ADMINISTRATOR":
                case 6:
                    message.ownerRole = 6;
                    break;
                }
                if (object.ownerId != null)
                    message.ownerId = object.ownerId | 0;
                if (object.ownerSession != null)
                    message.ownerSession = object.ownerSession | 0;
                if (object.ownerSessionTime != null)
                    if ($util.Long)
                        (message.ownerSessionTime = $util.Long.fromValue(object.ownerSessionTime)).unsigned = false;
                    else if (typeof object.ownerSessionTime === "string")
                        message.ownerSessionTime = parseInt(object.ownerSessionTime, 10);
                    else if (typeof object.ownerSessionTime === "number")
                        message.ownerSessionTime = object.ownerSessionTime;
                    else if (typeof object.ownerSessionTime === "object")
                        message.ownerSessionTime = new $util.LongBits(object.ownerSessionTime.low >>> 0, object.ownerSessionTime.high >>> 0).toNumber();
                if (object.ownerDevice != null)
                    if ($util.Long)
                        (message.ownerDevice = $util.Long.fromValue(object.ownerDevice)).unsigned = false;
                    else if (typeof object.ownerDevice === "string")
                        message.ownerDevice = parseInt(object.ownerDevice, 10);
                    else if (typeof object.ownerDevice === "number")
                        message.ownerDevice = object.ownerDevice;
                    else if (typeof object.ownerDevice === "object")
                        message.ownerDevice = new $util.LongBits(object.ownerDevice.low >>> 0, object.ownerDevice.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an AuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.sentry.AuthToken
             * @static
             * @param {api.sentry.AuthToken} message AuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.tenantId = 0;
                    object.ownerRole = options.enums === String ? "ROLE_NONE" : 0;
                    object.ownerId = 0;
                    object.ownerSession = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.ownerSessionTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.ownerSessionTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.ownerDevice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.ownerDevice = options.longs === String ? "0" : 0;
                }
                if (message.tenantId != null && message.hasOwnProperty("tenantId"))
                    object.tenantId = message.tenantId;
                if (message.ownerRole != null && message.hasOwnProperty("ownerRole"))
                    object.ownerRole = options.enums === String ? $root.api.sentry.AuthRole[message.ownerRole] === undefined ? message.ownerRole : $root.api.sentry.AuthRole[message.ownerRole] : message.ownerRole;
                if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                    object.ownerId = message.ownerId;
                if (message.ownerSession != null && message.hasOwnProperty("ownerSession"))
                    object.ownerSession = message.ownerSession;
                if (message.ownerSessionTime != null && message.hasOwnProperty("ownerSessionTime"))
                    if (typeof message.ownerSessionTime === "number")
                        object.ownerSessionTime = options.longs === String ? String(message.ownerSessionTime) : message.ownerSessionTime;
                    else
                        object.ownerSessionTime = options.longs === String ? $util.Long.prototype.toString.call(message.ownerSessionTime) : options.longs === Number ? new $util.LongBits(message.ownerSessionTime.low >>> 0, message.ownerSessionTime.high >>> 0).toNumber() : message.ownerSessionTime;
                if (message.ownerDevice != null && message.hasOwnProperty("ownerDevice"))
                    if (typeof message.ownerDevice === "number")
                        object.ownerDevice = options.longs === String ? String(message.ownerDevice) : message.ownerDevice;
                    else
                        object.ownerDevice = options.longs === String ? $util.Long.prototype.toString.call(message.ownerDevice) : options.longs === Number ? new $util.LongBits(message.ownerDevice.low >>> 0, message.ownerDevice.high >>> 0).toNumber() : message.ownerDevice;
                return object;
            };

            /**
             * Converts this AuthToken to JSON.
             * @function toJSON
             * @memberof api.sentry.AuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AuthToken
             * @function getTypeUrl
             * @memberof api.sentry.AuthToken
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AuthToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.sentry.AuthToken";
            };

            return AuthToken;
        })();

        sentry.AuthRequest = (function() {

            /**
             * Properties of an AuthRequest.
             * @memberof api.sentry
             * @interface IAuthRequest
             * @property {string|null} [path] AuthRequest path
             * @property {string|null} [ip] AuthRequest ip
             * @property {string|null} [token] AuthRequest token
             * @property {number|null} [auth] AuthRequest auth
             * @property {boolean|null} [readonly] AuthRequest readonly
             * @property {number|null} [qos] AuthRequest qos
             */

            /**
             * Constructs a new AuthRequest.
             * @memberof api.sentry
             * @classdesc Represents an AuthRequest.
             * @implements IAuthRequest
             * @constructor
             * @param {api.sentry.IAuthRequest=} [properties] Properties to set
             */
            function AuthRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AuthRequest path.
             * @member {string} path
             * @memberof api.sentry.AuthRequest
             * @instance
             */
            AuthRequest.prototype.path = "";

            /**
             * AuthRequest ip.
             * @member {string} ip
             * @memberof api.sentry.AuthRequest
             * @instance
             */
            AuthRequest.prototype.ip = "";

            /**
             * AuthRequest token.
             * @member {string} token
             * @memberof api.sentry.AuthRequest
             * @instance
             */
            AuthRequest.prototype.token = "";

            /**
             * AuthRequest auth.
             * @member {number} auth
             * @memberof api.sentry.AuthRequest
             * @instance
             */
            AuthRequest.prototype.auth = 0;

            /**
             * AuthRequest readonly.
             * @member {boolean} readonly
             * @memberof api.sentry.AuthRequest
             * @instance
             */
            AuthRequest.prototype.readonly = false;

            /**
             * AuthRequest qos.
             * @member {number} qos
             * @memberof api.sentry.AuthRequest
             * @instance
             */
            AuthRequest.prototype.qos = 0;

            /**
             * Creates a new AuthRequest instance using the specified properties.
             * @function create
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {api.sentry.IAuthRequest=} [properties] Properties to set
             * @returns {api.sentry.AuthRequest} AuthRequest instance
             */
            AuthRequest.create = function create(properties) {
                return new AuthRequest(properties);
            };

            /**
             * Encodes the specified AuthRequest message. Does not implicitly {@link api.sentry.AuthRequest.verify|verify} messages.
             * @function encode
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {api.sentry.IAuthRequest} message AuthRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
                if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.ip);
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.token);
                if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.auth);
                if (message.readonly != null && Object.hasOwnProperty.call(message, "readonly"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.readonly);
                if (message.qos != null && Object.hasOwnProperty.call(message, "qos"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.qos);
                return writer;
            };

            /**
             * Encodes the specified AuthRequest message, length delimited. Does not implicitly {@link api.sentry.AuthRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {api.sentry.IAuthRequest} message AuthRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AuthRequest message from the specified reader or buffer.
             * @function decode
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.sentry.AuthRequest} AuthRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.sentry.AuthRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.path = reader.string();
                            break;
                        }
                    case 2: {
                            message.ip = reader.string();
                            break;
                        }
                    case 3: {
                            message.token = reader.string();
                            break;
                        }
                    case 4: {
                            message.auth = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.readonly = reader.bool();
                            break;
                        }
                    case 6: {
                            message.qos = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AuthRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.sentry.AuthRequest} AuthRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AuthRequest message.
             * @function verify
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.path != null && message.hasOwnProperty("path"))
                    if (!$util.isString(message.path))
                        return "path: string expected";
                if (message.ip != null && message.hasOwnProperty("ip"))
                    if (!$util.isString(message.ip))
                        return "ip: string expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.auth != null && message.hasOwnProperty("auth"))
                    if (!$util.isInteger(message.auth))
                        return "auth: integer expected";
                if (message.readonly != null && message.hasOwnProperty("readonly"))
                    if (typeof message.readonly !== "boolean")
                        return "readonly: boolean expected";
                if (message.qos != null && message.hasOwnProperty("qos"))
                    if (!$util.isInteger(message.qos))
                        return "qos: integer expected";
                return null;
            };

            /**
             * Creates an AuthRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.sentry.AuthRequest} AuthRequest
             */
            AuthRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.api.sentry.AuthRequest)
                    return object;
                let message = new $root.api.sentry.AuthRequest();
                if (object.path != null)
                    message.path = String(object.path);
                if (object.ip != null)
                    message.ip = String(object.ip);
                if (object.token != null)
                    message.token = String(object.token);
                if (object.auth != null)
                    message.auth = object.auth >>> 0;
                if (object.readonly != null)
                    message.readonly = Boolean(object.readonly);
                if (object.qos != null)
                    message.qos = object.qos >>> 0;
                return message;
            };

            /**
             * Creates a plain object from an AuthRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {api.sentry.AuthRequest} message AuthRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.path = "";
                    object.ip = "";
                    object.token = "";
                    object.auth = 0;
                    object.readonly = false;
                    object.qos = 0;
                }
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                if (message.ip != null && message.hasOwnProperty("ip"))
                    object.ip = message.ip;
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.auth != null && message.hasOwnProperty("auth"))
                    object.auth = message.auth;
                if (message.readonly != null && message.hasOwnProperty("readonly"))
                    object.readonly = message.readonly;
                if (message.qos != null && message.hasOwnProperty("qos"))
                    object.qos = message.qos;
                return object;
            };

            /**
             * Converts this AuthRequest to JSON.
             * @function toJSON
             * @memberof api.sentry.AuthRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AuthRequest
             * @function getTypeUrl
             * @memberof api.sentry.AuthRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AuthRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.sentry.AuthRequest";
            };

            return AuthRequest;
        })();

        sentry.AuthResponse = (function() {

            /**
             * Properties of an AuthResponse.
             * @memberof api.sentry
             * @interface IAuthResponse
             * @property {api.common.DeviceType|null} [deviceType] AuthResponse deviceType
             * @property {number|null} [userId] AuthResponse userId
             * @property {number|null} [sessionId] AuthResponse sessionId
             * @property {number|null} [deviceId] AuthResponse deviceId
             * @property {number|null} [externalId] AuthResponse externalId
             * @property {Long|null} [uniqueId] AuthResponse uniqueId
             * @property {Uint8Array|null} [uniqueKey] AuthResponse uniqueKey
             * @property {boolean|null} [isCommand] AuthResponse isCommand
             * @property {number|null} [errCode] AuthResponse errCode
             * @property {string|null} [errMsg] AuthResponse errMsg
             * @property {string|null} [jwtToken] AuthResponse jwtToken
             * @property {string|null} [cloudflareIp] AuthResponse cloudflareIp
             * @property {string|null} [realIp] AuthResponse realIp
             */

            /**
             * Constructs a new AuthResponse.
             * @memberof api.sentry
             * @classdesc Represents an AuthResponse.
             * @implements IAuthResponse
             * @constructor
             * @param {api.sentry.IAuthResponse=} [properties] Properties to set
             */
            function AuthResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AuthResponse deviceType.
             * @member {api.common.DeviceType} deviceType
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.deviceType = 0;

            /**
             * AuthResponse userId.
             * @member {number} userId
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.userId = 0;

            /**
             * AuthResponse sessionId.
             * @member {number} sessionId
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.sessionId = 0;

            /**
             * AuthResponse deviceId.
             * @member {number} deviceId
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.deviceId = 0;

            /**
             * AuthResponse externalId.
             * @member {number} externalId
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.externalId = 0;

            /**
             * AuthResponse uniqueId.
             * @member {Long} uniqueId
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.uniqueId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * AuthResponse uniqueKey.
             * @member {Uint8Array} uniqueKey
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.uniqueKey = $util.newBuffer([]);

            /**
             * AuthResponse isCommand.
             * @member {boolean} isCommand
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.isCommand = false;

            /**
             * AuthResponse errCode.
             * @member {number} errCode
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.errCode = 0;

            /**
             * AuthResponse errMsg.
             * @member {string} errMsg
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.errMsg = "";

            /**
             * AuthResponse jwtToken.
             * @member {string} jwtToken
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.jwtToken = "";

            /**
             * AuthResponse cloudflareIp.
             * @member {string} cloudflareIp
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.cloudflareIp = "";

            /**
             * AuthResponse realIp.
             * @member {string} realIp
             * @memberof api.sentry.AuthResponse
             * @instance
             */
            AuthResponse.prototype.realIp = "";

            /**
             * Creates a new AuthResponse instance using the specified properties.
             * @function create
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {api.sentry.IAuthResponse=} [properties] Properties to set
             * @returns {api.sentry.AuthResponse} AuthResponse instance
             */
            AuthResponse.create = function create(properties) {
                return new AuthResponse(properties);
            };

            /**
             * Encodes the specified AuthResponse message. Does not implicitly {@link api.sentry.AuthResponse.verify|verify} messages.
             * @function encode
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {api.sentry.IAuthResponse} message AuthResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deviceType != null && Object.hasOwnProperty.call(message, "deviceType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deviceType);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userId);
                if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sessionId);
                if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.deviceId);
                if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.externalId);
                if (message.uniqueId != null && Object.hasOwnProperty.call(message, "uniqueId"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.uniqueId);
                if (message.uniqueKey != null && Object.hasOwnProperty.call(message, "uniqueKey"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.uniqueKey);
                if (message.isCommand != null && Object.hasOwnProperty.call(message, "isCommand"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isCommand);
                if (message.errCode != null && Object.hasOwnProperty.call(message, "errCode"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.errCode);
                if (message.errMsg != null && Object.hasOwnProperty.call(message, "errMsg"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.errMsg);
                if (message.jwtToken != null && Object.hasOwnProperty.call(message, "jwtToken"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.jwtToken);
                if (message.cloudflareIp != null && Object.hasOwnProperty.call(message, "cloudflareIp"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.cloudflareIp);
                if (message.realIp != null && Object.hasOwnProperty.call(message, "realIp"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.realIp);
                return writer;
            };

            /**
             * Encodes the specified AuthResponse message, length delimited. Does not implicitly {@link api.sentry.AuthResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {api.sentry.IAuthResponse} message AuthResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AuthResponse message from the specified reader or buffer.
             * @function decode
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {api.sentry.AuthResponse} AuthResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.sentry.AuthResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deviceType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.userId = reader.int32();
                            break;
                        }
                    case 3: {
                            message.sessionId = reader.int32();
                            break;
                        }
                    case 4: {
                            message.deviceId = reader.int32();
                            break;
                        }
                    case 5: {
                            message.externalId = reader.int32();
                            break;
                        }
                    case 6: {
                            message.uniqueId = reader.int64();
                            break;
                        }
                    case 7: {
                            message.uniqueKey = reader.bytes();
                            break;
                        }
                    case 8: {
                            message.isCommand = reader.bool();
                            break;
                        }
                    case 9: {
                            message.errCode = reader.int32();
                            break;
                        }
                    case 10: {
                            message.errMsg = reader.string();
                            break;
                        }
                    case 11: {
                            message.jwtToken = reader.string();
                            break;
                        }
                    case 12: {
                            message.cloudflareIp = reader.string();
                            break;
                        }
                    case 13: {
                            message.realIp = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AuthResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {api.sentry.AuthResponse} AuthResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AuthResponse message.
             * @function verify
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                    switch (message.deviceType) {
                    default:
                        return "deviceType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isInteger(message.userId))
                        return "userId: integer expected";
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    if (!$util.isInteger(message.sessionId))
                        return "sessionId: integer expected";
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    if (!$util.isInteger(message.deviceId))
                        return "deviceId: integer expected";
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    if (!$util.isInteger(message.externalId))
                        return "externalId: integer expected";
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    if (!$util.isInteger(message.uniqueId) && !(message.uniqueId && $util.isInteger(message.uniqueId.low) && $util.isInteger(message.uniqueId.high)))
                        return "uniqueId: integer|Long expected";
                if (message.uniqueKey != null && message.hasOwnProperty("uniqueKey"))
                    if (!(message.uniqueKey && typeof message.uniqueKey.length === "number" || $util.isString(message.uniqueKey)))
                        return "uniqueKey: buffer expected";
                if (message.isCommand != null && message.hasOwnProperty("isCommand"))
                    if (typeof message.isCommand !== "boolean")
                        return "isCommand: boolean expected";
                if (message.errCode != null && message.hasOwnProperty("errCode"))
                    if (!$util.isInteger(message.errCode))
                        return "errCode: integer expected";
                if (message.errMsg != null && message.hasOwnProperty("errMsg"))
                    if (!$util.isString(message.errMsg))
                        return "errMsg: string expected";
                if (message.jwtToken != null && message.hasOwnProperty("jwtToken"))
                    if (!$util.isString(message.jwtToken))
                        return "jwtToken: string expected";
                if (message.cloudflareIp != null && message.hasOwnProperty("cloudflareIp"))
                    if (!$util.isString(message.cloudflareIp))
                        return "cloudflareIp: string expected";
                if (message.realIp != null && message.hasOwnProperty("realIp"))
                    if (!$util.isString(message.realIp))
                        return "realIp: string expected";
                return null;
            };

            /**
             * Creates an AuthResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {api.sentry.AuthResponse} AuthResponse
             */
            AuthResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.api.sentry.AuthResponse)
                    return object;
                let message = new $root.api.sentry.AuthResponse();
                switch (object.deviceType) {
                default:
                    if (typeof object.deviceType === "number") {
                        message.deviceType = object.deviceType;
                        break;
                    }
                    break;
                case "System":
                case 0:
                    message.deviceType = 0;
                    break;
                case "Desktop":
                case 1:
                    message.deviceType = 1;
                    break;
                case "Ios":
                case 2:
                    message.deviceType = 2;
                    break;
                case "Android":
                case 3:
                    message.deviceType = 3;
                    break;
                }
                if (object.userId != null)
                    message.userId = object.userId | 0;
                if (object.sessionId != null)
                    message.sessionId = object.sessionId | 0;
                if (object.deviceId != null)
                    message.deviceId = object.deviceId | 0;
                if (object.externalId != null)
                    message.externalId = object.externalId | 0;
                if (object.uniqueId != null)
                    if ($util.Long)
                        (message.uniqueId = $util.Long.fromValue(object.uniqueId)).unsigned = false;
                    else if (typeof object.uniqueId === "string")
                        message.uniqueId = parseInt(object.uniqueId, 10);
                    else if (typeof object.uniqueId === "number")
                        message.uniqueId = object.uniqueId;
                    else if (typeof object.uniqueId === "object")
                        message.uniqueId = new $util.LongBits(object.uniqueId.low >>> 0, object.uniqueId.high >>> 0).toNumber();
                if (object.uniqueKey != null)
                    if (typeof object.uniqueKey === "string")
                        $util.base64.decode(object.uniqueKey, message.uniqueKey = $util.newBuffer($util.base64.length(object.uniqueKey)), 0);
                    else if (object.uniqueKey.length >= 0)
                        message.uniqueKey = object.uniqueKey;
                if (object.isCommand != null)
                    message.isCommand = Boolean(object.isCommand);
                if (object.errCode != null)
                    message.errCode = object.errCode | 0;
                if (object.errMsg != null)
                    message.errMsg = String(object.errMsg);
                if (object.jwtToken != null)
                    message.jwtToken = String(object.jwtToken);
                if (object.cloudflareIp != null)
                    message.cloudflareIp = String(object.cloudflareIp);
                if (object.realIp != null)
                    message.realIp = String(object.realIp);
                return message;
            };

            /**
             * Creates a plain object from an AuthResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {api.sentry.AuthResponse} message AuthResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.deviceType = options.enums === String ? "System" : 0;
                    object.userId = 0;
                    object.sessionId = 0;
                    object.deviceId = 0;
                    object.externalId = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.uniqueId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.uniqueId = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.uniqueKey = "";
                    else {
                        object.uniqueKey = [];
                        if (options.bytes !== Array)
                            object.uniqueKey = $util.newBuffer(object.uniqueKey);
                    }
                    object.isCommand = false;
                    object.errCode = 0;
                    object.errMsg = "";
                    object.jwtToken = "";
                    object.cloudflareIp = "";
                    object.realIp = "";
                }
                if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                    object.deviceType = options.enums === String ? $root.api.common.DeviceType[message.deviceType] === undefined ? message.deviceType : $root.api.common.DeviceType[message.deviceType] : message.deviceType;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                    object.sessionId = message.sessionId;
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    object.deviceId = message.deviceId;
                if (message.externalId != null && message.hasOwnProperty("externalId"))
                    object.externalId = message.externalId;
                if (message.uniqueId != null && message.hasOwnProperty("uniqueId"))
                    if (typeof message.uniqueId === "number")
                        object.uniqueId = options.longs === String ? String(message.uniqueId) : message.uniqueId;
                    else
                        object.uniqueId = options.longs === String ? $util.Long.prototype.toString.call(message.uniqueId) : options.longs === Number ? new $util.LongBits(message.uniqueId.low >>> 0, message.uniqueId.high >>> 0).toNumber() : message.uniqueId;
                if (message.uniqueKey != null && message.hasOwnProperty("uniqueKey"))
                    object.uniqueKey = options.bytes === String ? $util.base64.encode(message.uniqueKey, 0, message.uniqueKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.uniqueKey) : message.uniqueKey;
                if (message.isCommand != null && message.hasOwnProperty("isCommand"))
                    object.isCommand = message.isCommand;
                if (message.errCode != null && message.hasOwnProperty("errCode"))
                    object.errCode = message.errCode;
                if (message.errMsg != null && message.hasOwnProperty("errMsg"))
                    object.errMsg = message.errMsg;
                if (message.jwtToken != null && message.hasOwnProperty("jwtToken"))
                    object.jwtToken = message.jwtToken;
                if (message.cloudflareIp != null && message.hasOwnProperty("cloudflareIp"))
                    object.cloudflareIp = message.cloudflareIp;
                if (message.realIp != null && message.hasOwnProperty("realIp"))
                    object.realIp = message.realIp;
                return object;
            };

            /**
             * Converts this AuthResponse to JSON.
             * @function toJSON
             * @memberof api.sentry.AuthResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AuthResponse
             * @function getTypeUrl
             * @memberof api.sentry.AuthResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AuthResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/api.sentry.AuthResponse";
            };

            return AuthResponse;
        })();

        return sentry;
    })();

    return api;
})();

export const validate = $root.validate = (() => {

    /**
     * Namespace validate.
     * @exports validate
     * @namespace
     */
    const validate = {};

    validate.FieldRules = (function() {

        /**
         * Properties of a FieldRules.
         * @memberof validate
         * @interface IFieldRules
         * @property {validate.IMessageRules|null} [message] FieldRules message
         * @property {validate.IFloatRules|null} [float] FieldRules float
         * @property {validate.IDoubleRules|null} [double] FieldRules double
         * @property {validate.IInt32Rules|null} [int32] FieldRules int32
         * @property {validate.IInt64Rules|null} [int64] FieldRules int64
         * @property {validate.IUInt32Rules|null} [uint32] FieldRules uint32
         * @property {validate.IUInt64Rules|null} [uint64] FieldRules uint64
         * @property {validate.ISInt32Rules|null} [sint32] FieldRules sint32
         * @property {validate.ISInt64Rules|null} [sint64] FieldRules sint64
         * @property {validate.IFixed32Rules|null} [fixed32] FieldRules fixed32
         * @property {validate.IFixed64Rules|null} [fixed64] FieldRules fixed64
         * @property {validate.ISFixed32Rules|null} [sfixed32] FieldRules sfixed32
         * @property {validate.ISFixed64Rules|null} [sfixed64] FieldRules sfixed64
         * @property {validate.IBoolRules|null} [bool] FieldRules bool
         * @property {validate.IStringRules|null} [string] FieldRules string
         * @property {validate.IBytesRules|null} [bytes] FieldRules bytes
         * @property {validate.IEnumRules|null} ["enum"] FieldRules enum
         * @property {validate.IRepeatedRules|null} [repeated] FieldRules repeated
         * @property {validate.IMapRules|null} [map] FieldRules map
         * @property {validate.IAnyRules|null} [any] FieldRules any
         * @property {validate.IDurationRules|null} [duration] FieldRules duration
         * @property {validate.ITimestampRules|null} [timestamp] FieldRules timestamp
         */

        /**
         * Constructs a new FieldRules.
         * @memberof validate
         * @classdesc Represents a FieldRules.
         * @implements IFieldRules
         * @constructor
         * @param {validate.IFieldRules=} [properties] Properties to set
         */
        function FieldRules(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FieldRules message.
         * @member {validate.IMessageRules|null|undefined} message
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.message = null;

        /**
         * FieldRules float.
         * @member {validate.IFloatRules|null|undefined} float
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.float = null;

        /**
         * FieldRules double.
         * @member {validate.IDoubleRules|null|undefined} double
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.double = null;

        /**
         * FieldRules int32.
         * @member {validate.IInt32Rules|null|undefined} int32
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.int32 = null;

        /**
         * FieldRules int64.
         * @member {validate.IInt64Rules|null|undefined} int64
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.int64 = null;

        /**
         * FieldRules uint32.
         * @member {validate.IUInt32Rules|null|undefined} uint32
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.uint32 = null;

        /**
         * FieldRules uint64.
         * @member {validate.IUInt64Rules|null|undefined} uint64
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.uint64 = null;

        /**
         * FieldRules sint32.
         * @member {validate.ISInt32Rules|null|undefined} sint32
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.sint32 = null;

        /**
         * FieldRules sint64.
         * @member {validate.ISInt64Rules|null|undefined} sint64
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.sint64 = null;

        /**
         * FieldRules fixed32.
         * @member {validate.IFixed32Rules|null|undefined} fixed32
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.fixed32 = null;

        /**
         * FieldRules fixed64.
         * @member {validate.IFixed64Rules|null|undefined} fixed64
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.fixed64 = null;

        /**
         * FieldRules sfixed32.
         * @member {validate.ISFixed32Rules|null|undefined} sfixed32
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.sfixed32 = null;

        /**
         * FieldRules sfixed64.
         * @member {validate.ISFixed64Rules|null|undefined} sfixed64
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.sfixed64 = null;

        /**
         * FieldRules bool.
         * @member {validate.IBoolRules|null|undefined} bool
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.bool = null;

        /**
         * FieldRules string.
         * @member {validate.IStringRules|null|undefined} string
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.string = null;

        /**
         * FieldRules bytes.
         * @member {validate.IBytesRules|null|undefined} bytes
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.bytes = null;

        /**
         * FieldRules enum.
         * @member {validate.IEnumRules|null|undefined} enum
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype["enum"] = null;

        /**
         * FieldRules repeated.
         * @member {validate.IRepeatedRules|null|undefined} repeated
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.repeated = null;

        /**
         * FieldRules map.
         * @member {validate.IMapRules|null|undefined} map
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.map = null;

        /**
         * FieldRules any.
         * @member {validate.IAnyRules|null|undefined} any
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.any = null;

        /**
         * FieldRules duration.
         * @member {validate.IDurationRules|null|undefined} duration
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.duration = null;

        /**
         * FieldRules timestamp.
         * @member {validate.ITimestampRules|null|undefined} timestamp
         * @memberof validate.FieldRules
         * @instance
         */
        FieldRules.prototype.timestamp = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * FieldRules type.
         * @member {"float"|"double"|"int32"|"int64"|"uint32"|"uint64"|"sint32"|"sint64"|"fixed32"|"fixed64"|"sfixed32"|"sfixed64"|"bool"|"string"|"bytes"|"enum"|"repeated"|"map"|"any"|"duration"|"timestamp"|undefined} type
         * @memberof validate.FieldRules
         * @instance
         */
        Object.defineProperty(FieldRules.prototype, "type", {
            get: $util.oneOfGetter($oneOfFields = ["float", "double", "int32", "int64", "uint32", "uint64", "sint32", "sint64", "fixed32", "fixed64", "sfixed32", "sfixed64", "bool", "string", "bytes", "enum", "repeated", "map", "any", "duration", "timestamp"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FieldRules instance using the specified properties.
         * @function create
         * @memberof validate.FieldRules
         * @static
         * @param {validate.IFieldRules=} [properties] Properties to set
         * @returns {validate.FieldRules} FieldRules instance
         */
        FieldRules.create = function create(properties) {
            return new FieldRules(properties);
        };

        /**
         * Encodes the specified FieldRules message. Does not implicitly {@link validate.FieldRules.verify|verify} messages.
         * @function encode
         * @memberof validate.FieldRules
         * @static
         * @param {validate.IFieldRules} message FieldRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FieldRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.float != null && Object.hasOwnProperty.call(message, "float"))
                $root.validate.FloatRules.encode(message.float, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.double != null && Object.hasOwnProperty.call(message, "double"))
                $root.validate.DoubleRules.encode(message.double, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.int32 != null && Object.hasOwnProperty.call(message, "int32"))
                $root.validate.Int32Rules.encode(message.int32, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.int64 != null && Object.hasOwnProperty.call(message, "int64"))
                $root.validate.Int64Rules.encode(message.int64, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.uint32 != null && Object.hasOwnProperty.call(message, "uint32"))
                $root.validate.UInt32Rules.encode(message.uint32, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.uint64 != null && Object.hasOwnProperty.call(message, "uint64"))
                $root.validate.UInt64Rules.encode(message.uint64, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.sint32 != null && Object.hasOwnProperty.call(message, "sint32"))
                $root.validate.SInt32Rules.encode(message.sint32, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.sint64 != null && Object.hasOwnProperty.call(message, "sint64"))
                $root.validate.SInt64Rules.encode(message.sint64, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.fixed32 != null && Object.hasOwnProperty.call(message, "fixed32"))
                $root.validate.Fixed32Rules.encode(message.fixed32, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.fixed64 != null && Object.hasOwnProperty.call(message, "fixed64"))
                $root.validate.Fixed64Rules.encode(message.fixed64, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.sfixed32 != null && Object.hasOwnProperty.call(message, "sfixed32"))
                $root.validate.SFixed32Rules.encode(message.sfixed32, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.sfixed64 != null && Object.hasOwnProperty.call(message, "sfixed64"))
                $root.validate.SFixed64Rules.encode(message.sfixed64, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.bool != null && Object.hasOwnProperty.call(message, "bool"))
                $root.validate.BoolRules.encode(message.bool, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.string != null && Object.hasOwnProperty.call(message, "string"))
                $root.validate.StringRules.encode(message.string, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.bytes != null && Object.hasOwnProperty.call(message, "bytes"))
                $root.validate.BytesRules.encode(message.bytes, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message["enum"] != null && Object.hasOwnProperty.call(message, "enum"))
                $root.validate.EnumRules.encode(message["enum"], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.validate.MessageRules.encode(message.message, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.repeated != null && Object.hasOwnProperty.call(message, "repeated"))
                $root.validate.RepeatedRules.encode(message.repeated, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.map != null && Object.hasOwnProperty.call(message, "map"))
                $root.validate.MapRules.encode(message.map, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.any != null && Object.hasOwnProperty.call(message, "any"))
                $root.validate.AnyRules.encode(message.any, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                $root.validate.DurationRules.encode(message.duration, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                $root.validate.TimestampRules.encode(message.timestamp, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FieldRules message, length delimited. Does not implicitly {@link validate.FieldRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.FieldRules
         * @static
         * @param {validate.IFieldRules} message FieldRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FieldRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FieldRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.FieldRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.FieldRules} FieldRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FieldRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.FieldRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 17: {
                        message.message = $root.validate.MessageRules.decode(reader, reader.uint32());
                        break;
                    }
                case 1: {
                        message.float = $root.validate.FloatRules.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.double = $root.validate.DoubleRules.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.int32 = $root.validate.Int32Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.int64 = $root.validate.Int64Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.uint32 = $root.validate.UInt32Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.uint64 = $root.validate.UInt64Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.sint32 = $root.validate.SInt32Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.sint64 = $root.validate.SInt64Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.fixed32 = $root.validate.Fixed32Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.fixed64 = $root.validate.Fixed64Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.sfixed32 = $root.validate.SFixed32Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.sfixed64 = $root.validate.SFixed64Rules.decode(reader, reader.uint32());
                        break;
                    }
                case 13: {
                        message.bool = $root.validate.BoolRules.decode(reader, reader.uint32());
                        break;
                    }
                case 14: {
                        message.string = $root.validate.StringRules.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.bytes = $root.validate.BytesRules.decode(reader, reader.uint32());
                        break;
                    }
                case 16: {
                        message["enum"] = $root.validate.EnumRules.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.repeated = $root.validate.RepeatedRules.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.map = $root.validate.MapRules.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.any = $root.validate.AnyRules.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.duration = $root.validate.DurationRules.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.timestamp = $root.validate.TimestampRules.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FieldRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.FieldRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.FieldRules} FieldRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FieldRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FieldRules message.
         * @function verify
         * @memberof validate.FieldRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FieldRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.message != null && message.hasOwnProperty("message")) {
                let error = $root.validate.MessageRules.verify(message.message);
                if (error)
                    return "message." + error;
            }
            if (message.float != null && message.hasOwnProperty("float")) {
                properties.type = 1;
                {
                    let error = $root.validate.FloatRules.verify(message.float);
                    if (error)
                        return "float." + error;
                }
            }
            if (message.double != null && message.hasOwnProperty("double")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.DoubleRules.verify(message.double);
                    if (error)
                        return "double." + error;
                }
            }
            if (message.int32 != null && message.hasOwnProperty("int32")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.Int32Rules.verify(message.int32);
                    if (error)
                        return "int32." + error;
                }
            }
            if (message.int64 != null && message.hasOwnProperty("int64")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.Int64Rules.verify(message.int64);
                    if (error)
                        return "int64." + error;
                }
            }
            if (message.uint32 != null && message.hasOwnProperty("uint32")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.UInt32Rules.verify(message.uint32);
                    if (error)
                        return "uint32." + error;
                }
            }
            if (message.uint64 != null && message.hasOwnProperty("uint64")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.UInt64Rules.verify(message.uint64);
                    if (error)
                        return "uint64." + error;
                }
            }
            if (message.sint32 != null && message.hasOwnProperty("sint32")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.SInt32Rules.verify(message.sint32);
                    if (error)
                        return "sint32." + error;
                }
            }
            if (message.sint64 != null && message.hasOwnProperty("sint64")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.SInt64Rules.verify(message.sint64);
                    if (error)
                        return "sint64." + error;
                }
            }
            if (message.fixed32 != null && message.hasOwnProperty("fixed32")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.Fixed32Rules.verify(message.fixed32);
                    if (error)
                        return "fixed32." + error;
                }
            }
            if (message.fixed64 != null && message.hasOwnProperty("fixed64")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.Fixed64Rules.verify(message.fixed64);
                    if (error)
                        return "fixed64." + error;
                }
            }
            if (message.sfixed32 != null && message.hasOwnProperty("sfixed32")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.SFixed32Rules.verify(message.sfixed32);
                    if (error)
                        return "sfixed32." + error;
                }
            }
            if (message.sfixed64 != null && message.hasOwnProperty("sfixed64")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.SFixed64Rules.verify(message.sfixed64);
                    if (error)
                        return "sfixed64." + error;
                }
            }
            if (message.bool != null && message.hasOwnProperty("bool")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.BoolRules.verify(message.bool);
                    if (error)
                        return "bool." + error;
                }
            }
            if (message.string != null && message.hasOwnProperty("string")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.StringRules.verify(message.string);
                    if (error)
                        return "string." + error;
                }
            }
            if (message.bytes != null && message.hasOwnProperty("bytes")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.BytesRules.verify(message.bytes);
                    if (error)
                        return "bytes." + error;
                }
            }
            if (message["enum"] != null && message.hasOwnProperty("enum")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.EnumRules.verify(message["enum"]);
                    if (error)
                        return "enum." + error;
                }
            }
            if (message.repeated != null && message.hasOwnProperty("repeated")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.RepeatedRules.verify(message.repeated);
                    if (error)
                        return "repeated." + error;
                }
            }
            if (message.map != null && message.hasOwnProperty("map")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.MapRules.verify(message.map);
                    if (error)
                        return "map." + error;
                }
            }
            if (message.any != null && message.hasOwnProperty("any")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.AnyRules.verify(message.any);
                    if (error)
                        return "any." + error;
                }
            }
            if (message.duration != null && message.hasOwnProperty("duration")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.DurationRules.verify(message.duration);
                    if (error)
                        return "duration." + error;
                }
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                if (properties.type === 1)
                    return "type: multiple values";
                properties.type = 1;
                {
                    let error = $root.validate.TimestampRules.verify(message.timestamp);
                    if (error)
                        return "timestamp." + error;
                }
            }
            return null;
        };

        /**
         * Creates a FieldRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.FieldRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.FieldRules} FieldRules
         */
        FieldRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.FieldRules)
                return object;
            let message = new $root.validate.FieldRules();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".validate.FieldRules.message: object expected");
                message.message = $root.validate.MessageRules.fromObject(object.message);
            }
            if (object.float != null) {
                if (typeof object.float !== "object")
                    throw TypeError(".validate.FieldRules.float: object expected");
                message.float = $root.validate.FloatRules.fromObject(object.float);
            }
            if (object.double != null) {
                if (typeof object.double !== "object")
                    throw TypeError(".validate.FieldRules.double: object expected");
                message.double = $root.validate.DoubleRules.fromObject(object.double);
            }
            if (object.int32 != null) {
                if (typeof object.int32 !== "object")
                    throw TypeError(".validate.FieldRules.int32: object expected");
                message.int32 = $root.validate.Int32Rules.fromObject(object.int32);
            }
            if (object.int64 != null) {
                if (typeof object.int64 !== "object")
                    throw TypeError(".validate.FieldRules.int64: object expected");
                message.int64 = $root.validate.Int64Rules.fromObject(object.int64);
            }
            if (object.uint32 != null) {
                if (typeof object.uint32 !== "object")
                    throw TypeError(".validate.FieldRules.uint32: object expected");
                message.uint32 = $root.validate.UInt32Rules.fromObject(object.uint32);
            }
            if (object.uint64 != null) {
                if (typeof object.uint64 !== "object")
                    throw TypeError(".validate.FieldRules.uint64: object expected");
                message.uint64 = $root.validate.UInt64Rules.fromObject(object.uint64);
            }
            if (object.sint32 != null) {
                if (typeof object.sint32 !== "object")
                    throw TypeError(".validate.FieldRules.sint32: object expected");
                message.sint32 = $root.validate.SInt32Rules.fromObject(object.sint32);
            }
            if (object.sint64 != null) {
                if (typeof object.sint64 !== "object")
                    throw TypeError(".validate.FieldRules.sint64: object expected");
                message.sint64 = $root.validate.SInt64Rules.fromObject(object.sint64);
            }
            if (object.fixed32 != null) {
                if (typeof object.fixed32 !== "object")
                    throw TypeError(".validate.FieldRules.fixed32: object expected");
                message.fixed32 = $root.validate.Fixed32Rules.fromObject(object.fixed32);
            }
            if (object.fixed64 != null) {
                if (typeof object.fixed64 !== "object")
                    throw TypeError(".validate.FieldRules.fixed64: object expected");
                message.fixed64 = $root.validate.Fixed64Rules.fromObject(object.fixed64);
            }
            if (object.sfixed32 != null) {
                if (typeof object.sfixed32 !== "object")
                    throw TypeError(".validate.FieldRules.sfixed32: object expected");
                message.sfixed32 = $root.validate.SFixed32Rules.fromObject(object.sfixed32);
            }
            if (object.sfixed64 != null) {
                if (typeof object.sfixed64 !== "object")
                    throw TypeError(".validate.FieldRules.sfixed64: object expected");
                message.sfixed64 = $root.validate.SFixed64Rules.fromObject(object.sfixed64);
            }
            if (object.bool != null) {
                if (typeof object.bool !== "object")
                    throw TypeError(".validate.FieldRules.bool: object expected");
                message.bool = $root.validate.BoolRules.fromObject(object.bool);
            }
            if (object.string != null) {
                if (typeof object.string !== "object")
                    throw TypeError(".validate.FieldRules.string: object expected");
                message.string = $root.validate.StringRules.fromObject(object.string);
            }
            if (object.bytes != null) {
                if (typeof object.bytes !== "object")
                    throw TypeError(".validate.FieldRules.bytes: object expected");
                message.bytes = $root.validate.BytesRules.fromObject(object.bytes);
            }
            if (object["enum"] != null) {
                if (typeof object["enum"] !== "object")
                    throw TypeError(".validate.FieldRules.enum: object expected");
                message["enum"] = $root.validate.EnumRules.fromObject(object["enum"]);
            }
            if (object.repeated != null) {
                if (typeof object.repeated !== "object")
                    throw TypeError(".validate.FieldRules.repeated: object expected");
                message.repeated = $root.validate.RepeatedRules.fromObject(object.repeated);
            }
            if (object.map != null) {
                if (typeof object.map !== "object")
                    throw TypeError(".validate.FieldRules.map: object expected");
                message.map = $root.validate.MapRules.fromObject(object.map);
            }
            if (object.any != null) {
                if (typeof object.any !== "object")
                    throw TypeError(".validate.FieldRules.any: object expected");
                message.any = $root.validate.AnyRules.fromObject(object.any);
            }
            if (object.duration != null) {
                if (typeof object.duration !== "object")
                    throw TypeError(".validate.FieldRules.duration: object expected");
                message.duration = $root.validate.DurationRules.fromObject(object.duration);
            }
            if (object.timestamp != null) {
                if (typeof object.timestamp !== "object")
                    throw TypeError(".validate.FieldRules.timestamp: object expected");
                message.timestamp = $root.validate.TimestampRules.fromObject(object.timestamp);
            }
            return message;
        };

        /**
         * Creates a plain object from a FieldRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.FieldRules
         * @static
         * @param {validate.FieldRules} message FieldRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FieldRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.message = null;
            if (message.float != null && message.hasOwnProperty("float")) {
                object.float = $root.validate.FloatRules.toObject(message.float, options);
                if (options.oneofs)
                    object.type = "float";
            }
            if (message.double != null && message.hasOwnProperty("double")) {
                object.double = $root.validate.DoubleRules.toObject(message.double, options);
                if (options.oneofs)
                    object.type = "double";
            }
            if (message.int32 != null && message.hasOwnProperty("int32")) {
                object.int32 = $root.validate.Int32Rules.toObject(message.int32, options);
                if (options.oneofs)
                    object.type = "int32";
            }
            if (message.int64 != null && message.hasOwnProperty("int64")) {
                object.int64 = $root.validate.Int64Rules.toObject(message.int64, options);
                if (options.oneofs)
                    object.type = "int64";
            }
            if (message.uint32 != null && message.hasOwnProperty("uint32")) {
                object.uint32 = $root.validate.UInt32Rules.toObject(message.uint32, options);
                if (options.oneofs)
                    object.type = "uint32";
            }
            if (message.uint64 != null && message.hasOwnProperty("uint64")) {
                object.uint64 = $root.validate.UInt64Rules.toObject(message.uint64, options);
                if (options.oneofs)
                    object.type = "uint64";
            }
            if (message.sint32 != null && message.hasOwnProperty("sint32")) {
                object.sint32 = $root.validate.SInt32Rules.toObject(message.sint32, options);
                if (options.oneofs)
                    object.type = "sint32";
            }
            if (message.sint64 != null && message.hasOwnProperty("sint64")) {
                object.sint64 = $root.validate.SInt64Rules.toObject(message.sint64, options);
                if (options.oneofs)
                    object.type = "sint64";
            }
            if (message.fixed32 != null && message.hasOwnProperty("fixed32")) {
                object.fixed32 = $root.validate.Fixed32Rules.toObject(message.fixed32, options);
                if (options.oneofs)
                    object.type = "fixed32";
            }
            if (message.fixed64 != null && message.hasOwnProperty("fixed64")) {
                object.fixed64 = $root.validate.Fixed64Rules.toObject(message.fixed64, options);
                if (options.oneofs)
                    object.type = "fixed64";
            }
            if (message.sfixed32 != null && message.hasOwnProperty("sfixed32")) {
                object.sfixed32 = $root.validate.SFixed32Rules.toObject(message.sfixed32, options);
                if (options.oneofs)
                    object.type = "sfixed32";
            }
            if (message.sfixed64 != null && message.hasOwnProperty("sfixed64")) {
                object.sfixed64 = $root.validate.SFixed64Rules.toObject(message.sfixed64, options);
                if (options.oneofs)
                    object.type = "sfixed64";
            }
            if (message.bool != null && message.hasOwnProperty("bool")) {
                object.bool = $root.validate.BoolRules.toObject(message.bool, options);
                if (options.oneofs)
                    object.type = "bool";
            }
            if (message.string != null && message.hasOwnProperty("string")) {
                object.string = $root.validate.StringRules.toObject(message.string, options);
                if (options.oneofs)
                    object.type = "string";
            }
            if (message.bytes != null && message.hasOwnProperty("bytes")) {
                object.bytes = $root.validate.BytesRules.toObject(message.bytes, options);
                if (options.oneofs)
                    object.type = "bytes";
            }
            if (message["enum"] != null && message.hasOwnProperty("enum")) {
                object["enum"] = $root.validate.EnumRules.toObject(message["enum"], options);
                if (options.oneofs)
                    object.type = "enum";
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.validate.MessageRules.toObject(message.message, options);
            if (message.repeated != null && message.hasOwnProperty("repeated")) {
                object.repeated = $root.validate.RepeatedRules.toObject(message.repeated, options);
                if (options.oneofs)
                    object.type = "repeated";
            }
            if (message.map != null && message.hasOwnProperty("map")) {
                object.map = $root.validate.MapRules.toObject(message.map, options);
                if (options.oneofs)
                    object.type = "map";
            }
            if (message.any != null && message.hasOwnProperty("any")) {
                object.any = $root.validate.AnyRules.toObject(message.any, options);
                if (options.oneofs)
                    object.type = "any";
            }
            if (message.duration != null && message.hasOwnProperty("duration")) {
                object.duration = $root.validate.DurationRules.toObject(message.duration, options);
                if (options.oneofs)
                    object.type = "duration";
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                object.timestamp = $root.validate.TimestampRules.toObject(message.timestamp, options);
                if (options.oneofs)
                    object.type = "timestamp";
            }
            return object;
        };

        /**
         * Converts this FieldRules to JSON.
         * @function toJSON
         * @memberof validate.FieldRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FieldRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FieldRules
         * @function getTypeUrl
         * @memberof validate.FieldRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FieldRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.FieldRules";
        };

        return FieldRules;
    })();

    validate.FloatRules = (function() {

        /**
         * Properties of a FloatRules.
         * @memberof validate
         * @interface IFloatRules
         * @property {number|null} ["const"] FloatRules const
         * @property {number|null} [lt] FloatRules lt
         * @property {number|null} [lte] FloatRules lte
         * @property {number|null} [gt] FloatRules gt
         * @property {number|null} [gte] FloatRules gte
         * @property {Array.<number>|null} ["in"] FloatRules in
         * @property {Array.<number>|null} [notIn] FloatRules notIn
         * @property {boolean|null} [ignoreEmpty] FloatRules ignoreEmpty
         */

        /**
         * Constructs a new FloatRules.
         * @memberof validate
         * @classdesc Represents a FloatRules.
         * @implements IFloatRules
         * @constructor
         * @param {validate.IFloatRules=} [properties] Properties to set
         */
        function FloatRules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FloatRules const.
         * @member {number} const
         * @memberof validate.FloatRules
         * @instance
         */
        FloatRules.prototype["const"] = 0;

        /**
         * FloatRules lt.
         * @member {number} lt
         * @memberof validate.FloatRules
         * @instance
         */
        FloatRules.prototype.lt = 0;

        /**
         * FloatRules lte.
         * @member {number} lte
         * @memberof validate.FloatRules
         * @instance
         */
        FloatRules.prototype.lte = 0;

        /**
         * FloatRules gt.
         * @member {number} gt
         * @memberof validate.FloatRules
         * @instance
         */
        FloatRules.prototype.gt = 0;

        /**
         * FloatRules gte.
         * @member {number} gte
         * @memberof validate.FloatRules
         * @instance
         */
        FloatRules.prototype.gte = 0;

        /**
         * FloatRules in.
         * @member {Array.<number>} in
         * @memberof validate.FloatRules
         * @instance
         */
        FloatRules.prototype["in"] = $util.emptyArray;

        /**
         * FloatRules notIn.
         * @member {Array.<number>} notIn
         * @memberof validate.FloatRules
         * @instance
         */
        FloatRules.prototype.notIn = $util.emptyArray;

        /**
         * FloatRules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.FloatRules
         * @instance
         */
        FloatRules.prototype.ignoreEmpty = false;

        /**
         * Creates a new FloatRules instance using the specified properties.
         * @function create
         * @memberof validate.FloatRules
         * @static
         * @param {validate.IFloatRules=} [properties] Properties to set
         * @returns {validate.FloatRules} FloatRules instance
         */
        FloatRules.create = function create(properties) {
            return new FloatRules(properties);
        };

        /**
         * Encodes the specified FloatRules message. Does not implicitly {@link validate.FloatRules.verify|verify} messages.
         * @function encode
         * @memberof validate.FloatRules
         * @static
         * @param {validate.IFloatRules} message FloatRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FloatRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 5 =*/13).float(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 5 =*/53).float(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 5 =*/61).float(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified FloatRules message, length delimited. Does not implicitly {@link validate.FloatRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.FloatRules
         * @static
         * @param {validate.IFloatRules} message FloatRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FloatRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FloatRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.FloatRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.FloatRules} FloatRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FloatRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.FloatRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.float();
                        break;
                    }
                case 2: {
                        message.lt = reader.float();
                        break;
                    }
                case 3: {
                        message.lte = reader.float();
                        break;
                    }
                case 4: {
                        message.gt = reader.float();
                        break;
                    }
                case 5: {
                        message.gte = reader.float();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.float());
                        } else
                            message["in"].push(reader.float());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.float());
                        } else
                            message.notIn.push(reader.float());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FloatRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.FloatRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.FloatRules} FloatRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FloatRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FloatRules message.
         * @function verify
         * @memberof validate.FloatRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FloatRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (typeof message["const"] !== "number")
                    return "const: number expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (typeof message.lt !== "number")
                    return "lt: number expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (typeof message.lte !== "number")
                    return "lte: number expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (typeof message.gt !== "number")
                    return "gt: number expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (typeof message.gte !== "number")
                    return "gte: number expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (typeof message["in"][i] !== "number")
                        return "in: number[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (typeof message.notIn[i] !== "number")
                        return "notIn: number[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a FloatRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.FloatRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.FloatRules} FloatRules
         */
        FloatRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.FloatRules)
                return object;
            let message = new $root.validate.FloatRules();
            if (object["const"] != null)
                message["const"] = Number(object["const"]);
            if (object.lt != null)
                message.lt = Number(object.lt);
            if (object.lte != null)
                message.lte = Number(object.lte);
            if (object.gt != null)
                message.gt = Number(object.gt);
            if (object.gte != null)
                message.gte = Number(object.gte);
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.FloatRules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = Number(object["in"][i]);
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.FloatRules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = Number(object.notIn[i]);
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a FloatRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.FloatRules
         * @static
         * @param {validate.FloatRules} message FloatRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FloatRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = 0;
                object.lt = 0;
                object.lte = 0;
                object.gt = 0;
                object.gte = 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = options.json && !isFinite(message["const"]) ? String(message["const"]) : message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = options.json && !isFinite(message.lt) ? String(message.lt) : message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = options.json && !isFinite(message.lte) ? String(message.lte) : message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = options.json && !isFinite(message.gt) ? String(message.gt) : message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = options.json && !isFinite(message.gte) ? String(message.gte) : message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = options.json && !isFinite(message["in"][j]) ? String(message["in"][j]) : message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = options.json && !isFinite(message.notIn[j]) ? String(message.notIn[j]) : message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this FloatRules to JSON.
         * @function toJSON
         * @memberof validate.FloatRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FloatRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FloatRules
         * @function getTypeUrl
         * @memberof validate.FloatRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FloatRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.FloatRules";
        };

        return FloatRules;
    })();

    validate.DoubleRules = (function() {

        /**
         * Properties of a DoubleRules.
         * @memberof validate
         * @interface IDoubleRules
         * @property {number|null} ["const"] DoubleRules const
         * @property {number|null} [lt] DoubleRules lt
         * @property {number|null} [lte] DoubleRules lte
         * @property {number|null} [gt] DoubleRules gt
         * @property {number|null} [gte] DoubleRules gte
         * @property {Array.<number>|null} ["in"] DoubleRules in
         * @property {Array.<number>|null} [notIn] DoubleRules notIn
         * @property {boolean|null} [ignoreEmpty] DoubleRules ignoreEmpty
         */

        /**
         * Constructs a new DoubleRules.
         * @memberof validate
         * @classdesc Represents a DoubleRules.
         * @implements IDoubleRules
         * @constructor
         * @param {validate.IDoubleRules=} [properties] Properties to set
         */
        function DoubleRules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DoubleRules const.
         * @member {number} const
         * @memberof validate.DoubleRules
         * @instance
         */
        DoubleRules.prototype["const"] = 0;

        /**
         * DoubleRules lt.
         * @member {number} lt
         * @memberof validate.DoubleRules
         * @instance
         */
        DoubleRules.prototype.lt = 0;

        /**
         * DoubleRules lte.
         * @member {number} lte
         * @memberof validate.DoubleRules
         * @instance
         */
        DoubleRules.prototype.lte = 0;

        /**
         * DoubleRules gt.
         * @member {number} gt
         * @memberof validate.DoubleRules
         * @instance
         */
        DoubleRules.prototype.gt = 0;

        /**
         * DoubleRules gte.
         * @member {number} gte
         * @memberof validate.DoubleRules
         * @instance
         */
        DoubleRules.prototype.gte = 0;

        /**
         * DoubleRules in.
         * @member {Array.<number>} in
         * @memberof validate.DoubleRules
         * @instance
         */
        DoubleRules.prototype["in"] = $util.emptyArray;

        /**
         * DoubleRules notIn.
         * @member {Array.<number>} notIn
         * @memberof validate.DoubleRules
         * @instance
         */
        DoubleRules.prototype.notIn = $util.emptyArray;

        /**
         * DoubleRules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.DoubleRules
         * @instance
         */
        DoubleRules.prototype.ignoreEmpty = false;

        /**
         * Creates a new DoubleRules instance using the specified properties.
         * @function create
         * @memberof validate.DoubleRules
         * @static
         * @param {validate.IDoubleRules=} [properties] Properties to set
         * @returns {validate.DoubleRules} DoubleRules instance
         */
        DoubleRules.create = function create(properties) {
            return new DoubleRules(properties);
        };

        /**
         * Encodes the specified DoubleRules message. Does not implicitly {@link validate.DoubleRules.verify|verify} messages.
         * @function encode
         * @memberof validate.DoubleRules
         * @static
         * @param {validate.IDoubleRules} message DoubleRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DoubleRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 1 =*/41).double(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 1 =*/57).double(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified DoubleRules message, length delimited. Does not implicitly {@link validate.DoubleRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.DoubleRules
         * @static
         * @param {validate.IDoubleRules} message DoubleRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DoubleRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DoubleRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.DoubleRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.DoubleRules} DoubleRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DoubleRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.DoubleRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.double();
                        break;
                    }
                case 2: {
                        message.lt = reader.double();
                        break;
                    }
                case 3: {
                        message.lte = reader.double();
                        break;
                    }
                case 4: {
                        message.gt = reader.double();
                        break;
                    }
                case 5: {
                        message.gte = reader.double();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.double());
                        } else
                            message["in"].push(reader.double());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.double());
                        } else
                            message.notIn.push(reader.double());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DoubleRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.DoubleRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.DoubleRules} DoubleRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DoubleRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DoubleRules message.
         * @function verify
         * @memberof validate.DoubleRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DoubleRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (typeof message["const"] !== "number")
                    return "const: number expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (typeof message.lt !== "number")
                    return "lt: number expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (typeof message.lte !== "number")
                    return "lte: number expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (typeof message.gt !== "number")
                    return "gt: number expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (typeof message.gte !== "number")
                    return "gte: number expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (typeof message["in"][i] !== "number")
                        return "in: number[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (typeof message.notIn[i] !== "number")
                        return "notIn: number[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a DoubleRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.DoubleRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.DoubleRules} DoubleRules
         */
        DoubleRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.DoubleRules)
                return object;
            let message = new $root.validate.DoubleRules();
            if (object["const"] != null)
                message["const"] = Number(object["const"]);
            if (object.lt != null)
                message.lt = Number(object.lt);
            if (object.lte != null)
                message.lte = Number(object.lte);
            if (object.gt != null)
                message.gt = Number(object.gt);
            if (object.gte != null)
                message.gte = Number(object.gte);
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.DoubleRules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = Number(object["in"][i]);
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.DoubleRules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = Number(object.notIn[i]);
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a DoubleRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.DoubleRules
         * @static
         * @param {validate.DoubleRules} message DoubleRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DoubleRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = 0;
                object.lt = 0;
                object.lte = 0;
                object.gt = 0;
                object.gte = 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = options.json && !isFinite(message["const"]) ? String(message["const"]) : message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = options.json && !isFinite(message.lt) ? String(message.lt) : message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = options.json && !isFinite(message.lte) ? String(message.lte) : message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = options.json && !isFinite(message.gt) ? String(message.gt) : message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = options.json && !isFinite(message.gte) ? String(message.gte) : message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = options.json && !isFinite(message["in"][j]) ? String(message["in"][j]) : message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = options.json && !isFinite(message.notIn[j]) ? String(message.notIn[j]) : message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this DoubleRules to JSON.
         * @function toJSON
         * @memberof validate.DoubleRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DoubleRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DoubleRules
         * @function getTypeUrl
         * @memberof validate.DoubleRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DoubleRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.DoubleRules";
        };

        return DoubleRules;
    })();

    validate.Int32Rules = (function() {

        /**
         * Properties of an Int32Rules.
         * @memberof validate
         * @interface IInt32Rules
         * @property {number|null} ["const"] Int32Rules const
         * @property {number|null} [lt] Int32Rules lt
         * @property {number|null} [lte] Int32Rules lte
         * @property {number|null} [gt] Int32Rules gt
         * @property {number|null} [gte] Int32Rules gte
         * @property {Array.<number>|null} ["in"] Int32Rules in
         * @property {Array.<number>|null} [notIn] Int32Rules notIn
         * @property {boolean|null} [ignoreEmpty] Int32Rules ignoreEmpty
         */

        /**
         * Constructs a new Int32Rules.
         * @memberof validate
         * @classdesc Represents an Int32Rules.
         * @implements IInt32Rules
         * @constructor
         * @param {validate.IInt32Rules=} [properties] Properties to set
         */
        function Int32Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Int32Rules const.
         * @member {number} const
         * @memberof validate.Int32Rules
         * @instance
         */
        Int32Rules.prototype["const"] = 0;

        /**
         * Int32Rules lt.
         * @member {number} lt
         * @memberof validate.Int32Rules
         * @instance
         */
        Int32Rules.prototype.lt = 0;

        /**
         * Int32Rules lte.
         * @member {number} lte
         * @memberof validate.Int32Rules
         * @instance
         */
        Int32Rules.prototype.lte = 0;

        /**
         * Int32Rules gt.
         * @member {number} gt
         * @memberof validate.Int32Rules
         * @instance
         */
        Int32Rules.prototype.gt = 0;

        /**
         * Int32Rules gte.
         * @member {number} gte
         * @memberof validate.Int32Rules
         * @instance
         */
        Int32Rules.prototype.gte = 0;

        /**
         * Int32Rules in.
         * @member {Array.<number>} in
         * @memberof validate.Int32Rules
         * @instance
         */
        Int32Rules.prototype["in"] = $util.emptyArray;

        /**
         * Int32Rules notIn.
         * @member {Array.<number>} notIn
         * @memberof validate.Int32Rules
         * @instance
         */
        Int32Rules.prototype.notIn = $util.emptyArray;

        /**
         * Int32Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.Int32Rules
         * @instance
         */
        Int32Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new Int32Rules instance using the specified properties.
         * @function create
         * @memberof validate.Int32Rules
         * @static
         * @param {validate.IInt32Rules=} [properties] Properties to set
         * @returns {validate.Int32Rules} Int32Rules instance
         */
        Int32Rules.create = function create(properties) {
            return new Int32Rules(properties);
        };

        /**
         * Encodes the specified Int32Rules message. Does not implicitly {@link validate.Int32Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.Int32Rules
         * @static
         * @param {validate.IInt32Rules} message Int32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Int32Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified Int32Rules message, length delimited. Does not implicitly {@link validate.Int32Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.Int32Rules
         * @static
         * @param {validate.IInt32Rules} message Int32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Int32Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Int32Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.Int32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.Int32Rules} Int32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Int32Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.Int32Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.int32();
                        break;
                    }
                case 2: {
                        message.lt = reader.int32();
                        break;
                    }
                case 3: {
                        message.lte = reader.int32();
                        break;
                    }
                case 4: {
                        message.gt = reader.int32();
                        break;
                    }
                case 5: {
                        message.gte = reader.int32();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.int32());
                        } else
                            message["in"].push(reader.int32());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.int32());
                        } else
                            message.notIn.push(reader.int32());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Int32Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.Int32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.Int32Rules} Int32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Int32Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Int32Rules message.
         * @function verify
         * @memberof validate.Int32Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Int32Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]))
                    return "const: integer expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt))
                    return "lt: integer expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte))
                    return "lte: integer expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt))
                    return "gt: integer expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte))
                    return "gte: integer expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]))
                        return "in: integer[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]))
                        return "notIn: integer[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates an Int32Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.Int32Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.Int32Rules} Int32Rules
         */
        Int32Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.Int32Rules)
                return object;
            let message = new $root.validate.Int32Rules();
            if (object["const"] != null)
                message["const"] = object["const"] | 0;
            if (object.lt != null)
                message.lt = object.lt | 0;
            if (object.lte != null)
                message.lte = object.lte | 0;
            if (object.gt != null)
                message.gt = object.gt | 0;
            if (object.gte != null)
                message.gte = object.gte | 0;
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.Int32Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = object["in"][i] | 0;
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.Int32Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = object.notIn[i] | 0;
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from an Int32Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.Int32Rules
         * @static
         * @param {validate.Int32Rules} message Int32Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Int32Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = 0;
                object.lt = 0;
                object.lte = 0;
                object.gt = 0;
                object.gte = 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this Int32Rules to JSON.
         * @function toJSON
         * @memberof validate.Int32Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Int32Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Int32Rules
         * @function getTypeUrl
         * @memberof validate.Int32Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Int32Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.Int32Rules";
        };

        return Int32Rules;
    })();

    validate.Int64Rules = (function() {

        /**
         * Properties of an Int64Rules.
         * @memberof validate
         * @interface IInt64Rules
         * @property {Long|null} ["const"] Int64Rules const
         * @property {Long|null} [lt] Int64Rules lt
         * @property {Long|null} [lte] Int64Rules lte
         * @property {Long|null} [gt] Int64Rules gt
         * @property {Long|null} [gte] Int64Rules gte
         * @property {Array.<Long>|null} ["in"] Int64Rules in
         * @property {Array.<Long>|null} [notIn] Int64Rules notIn
         * @property {boolean|null} [ignoreEmpty] Int64Rules ignoreEmpty
         */

        /**
         * Constructs a new Int64Rules.
         * @memberof validate
         * @classdesc Represents an Int64Rules.
         * @implements IInt64Rules
         * @constructor
         * @param {validate.IInt64Rules=} [properties] Properties to set
         */
        function Int64Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Int64Rules const.
         * @member {Long} const
         * @memberof validate.Int64Rules
         * @instance
         */
        Int64Rules.prototype["const"] = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Int64Rules lt.
         * @member {Long} lt
         * @memberof validate.Int64Rules
         * @instance
         */
        Int64Rules.prototype.lt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Int64Rules lte.
         * @member {Long} lte
         * @memberof validate.Int64Rules
         * @instance
         */
        Int64Rules.prototype.lte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Int64Rules gt.
         * @member {Long} gt
         * @memberof validate.Int64Rules
         * @instance
         */
        Int64Rules.prototype.gt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Int64Rules gte.
         * @member {Long} gte
         * @memberof validate.Int64Rules
         * @instance
         */
        Int64Rules.prototype.gte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Int64Rules in.
         * @member {Array.<Long>} in
         * @memberof validate.Int64Rules
         * @instance
         */
        Int64Rules.prototype["in"] = $util.emptyArray;

        /**
         * Int64Rules notIn.
         * @member {Array.<Long>} notIn
         * @memberof validate.Int64Rules
         * @instance
         */
        Int64Rules.prototype.notIn = $util.emptyArray;

        /**
         * Int64Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.Int64Rules
         * @instance
         */
        Int64Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new Int64Rules instance using the specified properties.
         * @function create
         * @memberof validate.Int64Rules
         * @static
         * @param {validate.IInt64Rules=} [properties] Properties to set
         * @returns {validate.Int64Rules} Int64Rules instance
         */
        Int64Rules.create = function create(properties) {
            return new Int64Rules(properties);
        };

        /**
         * Encodes the specified Int64Rules message. Does not implicitly {@link validate.Int64Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.Int64Rules
         * @static
         * @param {validate.IInt64Rules} message Int64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Int64Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified Int64Rules message, length delimited. Does not implicitly {@link validate.Int64Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.Int64Rules
         * @static
         * @param {validate.IInt64Rules} message Int64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Int64Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Int64Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.Int64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.Int64Rules} Int64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Int64Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.Int64Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.int64();
                        break;
                    }
                case 2: {
                        message.lt = reader.int64();
                        break;
                    }
                case 3: {
                        message.lte = reader.int64();
                        break;
                    }
                case 4: {
                        message.gt = reader.int64();
                        break;
                    }
                case 5: {
                        message.gte = reader.int64();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.int64());
                        } else
                            message["in"].push(reader.int64());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.int64());
                        } else
                            message.notIn.push(reader.int64());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Int64Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.Int64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.Int64Rules} Int64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Int64Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Int64Rules message.
         * @function verify
         * @memberof validate.Int64Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Int64Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]) && !(message["const"] && $util.isInteger(message["const"].low) && $util.isInteger(message["const"].high)))
                    return "const: integer|Long expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt) && !(message.lt && $util.isInteger(message.lt.low) && $util.isInteger(message.lt.high)))
                    return "lt: integer|Long expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte) && !(message.lte && $util.isInteger(message.lte.low) && $util.isInteger(message.lte.high)))
                    return "lte: integer|Long expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt) && !(message.gt && $util.isInteger(message.gt.low) && $util.isInteger(message.gt.high)))
                    return "gt: integer|Long expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte) && !(message.gte && $util.isInteger(message.gte.low) && $util.isInteger(message.gte.high)))
                    return "gte: integer|Long expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]) && !(message["in"][i] && $util.isInteger(message["in"][i].low) && $util.isInteger(message["in"][i].high)))
                        return "in: integer|Long[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]) && !(message.notIn[i] && $util.isInteger(message.notIn[i].low) && $util.isInteger(message.notIn[i].high)))
                        return "notIn: integer|Long[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates an Int64Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.Int64Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.Int64Rules} Int64Rules
         */
        Int64Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.Int64Rules)
                return object;
            let message = new $root.validate.Int64Rules();
            if (object["const"] != null)
                if ($util.Long)
                    (message["const"] = $util.Long.fromValue(object["const"])).unsigned = false;
                else if (typeof object["const"] === "string")
                    message["const"] = parseInt(object["const"], 10);
                else if (typeof object["const"] === "number")
                    message["const"] = object["const"];
                else if (typeof object["const"] === "object")
                    message["const"] = new $util.LongBits(object["const"].low >>> 0, object["const"].high >>> 0).toNumber();
            if (object.lt != null)
                if ($util.Long)
                    (message.lt = $util.Long.fromValue(object.lt)).unsigned = false;
                else if (typeof object.lt === "string")
                    message.lt = parseInt(object.lt, 10);
                else if (typeof object.lt === "number")
                    message.lt = object.lt;
                else if (typeof object.lt === "object")
                    message.lt = new $util.LongBits(object.lt.low >>> 0, object.lt.high >>> 0).toNumber();
            if (object.lte != null)
                if ($util.Long)
                    (message.lte = $util.Long.fromValue(object.lte)).unsigned = false;
                else if (typeof object.lte === "string")
                    message.lte = parseInt(object.lte, 10);
                else if (typeof object.lte === "number")
                    message.lte = object.lte;
                else if (typeof object.lte === "object")
                    message.lte = new $util.LongBits(object.lte.low >>> 0, object.lte.high >>> 0).toNumber();
            if (object.gt != null)
                if ($util.Long)
                    (message.gt = $util.Long.fromValue(object.gt)).unsigned = false;
                else if (typeof object.gt === "string")
                    message.gt = parseInt(object.gt, 10);
                else if (typeof object.gt === "number")
                    message.gt = object.gt;
                else if (typeof object.gt === "object")
                    message.gt = new $util.LongBits(object.gt.low >>> 0, object.gt.high >>> 0).toNumber();
            if (object.gte != null)
                if ($util.Long)
                    (message.gte = $util.Long.fromValue(object.gte)).unsigned = false;
                else if (typeof object.gte === "string")
                    message.gte = parseInt(object.gte, 10);
                else if (typeof object.gte === "number")
                    message.gte = object.gte;
                else if (typeof object.gte === "object")
                    message.gte = new $util.LongBits(object.gte.low >>> 0, object.gte.high >>> 0).toNumber();
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.Int64Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    if ($util.Long)
                        (message["in"][i] = $util.Long.fromValue(object["in"][i])).unsigned = false;
                    else if (typeof object["in"][i] === "string")
                        message["in"][i] = parseInt(object["in"][i], 10);
                    else if (typeof object["in"][i] === "number")
                        message["in"][i] = object["in"][i];
                    else if (typeof object["in"][i] === "object")
                        message["in"][i] = new $util.LongBits(object["in"][i].low >>> 0, object["in"][i].high >>> 0).toNumber();
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.Int64Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    if ($util.Long)
                        (message.notIn[i] = $util.Long.fromValue(object.notIn[i])).unsigned = false;
                    else if (typeof object.notIn[i] === "string")
                        message.notIn[i] = parseInt(object.notIn[i], 10);
                    else if (typeof object.notIn[i] === "number")
                        message.notIn[i] = object.notIn[i];
                    else if (typeof object.notIn[i] === "object")
                        message.notIn[i] = new $util.LongBits(object.notIn[i].low >>> 0, object.notIn[i].high >>> 0).toNumber();
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from an Int64Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.Int64Rules
         * @static
         * @param {validate.Int64Rules} message Int64Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Int64Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object["const"] = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object["const"] = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.gt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.gte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gte = options.longs === String ? "0" : 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (typeof message["const"] === "number")
                    object["const"] = options.longs === String ? String(message["const"]) : message["const"];
                else
                    object["const"] = options.longs === String ? $util.Long.prototype.toString.call(message["const"]) : options.longs === Number ? new $util.LongBits(message["const"].low >>> 0, message["const"].high >>> 0).toNumber() : message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (typeof message.lt === "number")
                    object.lt = options.longs === String ? String(message.lt) : message.lt;
                else
                    object.lt = options.longs === String ? $util.Long.prototype.toString.call(message.lt) : options.longs === Number ? new $util.LongBits(message.lt.low >>> 0, message.lt.high >>> 0).toNumber() : message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (typeof message.lte === "number")
                    object.lte = options.longs === String ? String(message.lte) : message.lte;
                else
                    object.lte = options.longs === String ? $util.Long.prototype.toString.call(message.lte) : options.longs === Number ? new $util.LongBits(message.lte.low >>> 0, message.lte.high >>> 0).toNumber() : message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (typeof message.gt === "number")
                    object.gt = options.longs === String ? String(message.gt) : message.gt;
                else
                    object.gt = options.longs === String ? $util.Long.prototype.toString.call(message.gt) : options.longs === Number ? new $util.LongBits(message.gt.low >>> 0, message.gt.high >>> 0).toNumber() : message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (typeof message.gte === "number")
                    object.gte = options.longs === String ? String(message.gte) : message.gte;
                else
                    object.gte = options.longs === String ? $util.Long.prototype.toString.call(message.gte) : options.longs === Number ? new $util.LongBits(message.gte.low >>> 0, message.gte.high >>> 0).toNumber() : message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    if (typeof message["in"][j] === "number")
                        object["in"][j] = options.longs === String ? String(message["in"][j]) : message["in"][j];
                    else
                        object["in"][j] = options.longs === String ? $util.Long.prototype.toString.call(message["in"][j]) : options.longs === Number ? new $util.LongBits(message["in"][j].low >>> 0, message["in"][j].high >>> 0).toNumber() : message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    if (typeof message.notIn[j] === "number")
                        object.notIn[j] = options.longs === String ? String(message.notIn[j]) : message.notIn[j];
                    else
                        object.notIn[j] = options.longs === String ? $util.Long.prototype.toString.call(message.notIn[j]) : options.longs === Number ? new $util.LongBits(message.notIn[j].low >>> 0, message.notIn[j].high >>> 0).toNumber() : message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this Int64Rules to JSON.
         * @function toJSON
         * @memberof validate.Int64Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Int64Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Int64Rules
         * @function getTypeUrl
         * @memberof validate.Int64Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Int64Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.Int64Rules";
        };

        return Int64Rules;
    })();

    validate.UInt32Rules = (function() {

        /**
         * Properties of a UInt32Rules.
         * @memberof validate
         * @interface IUInt32Rules
         * @property {number|null} ["const"] UInt32Rules const
         * @property {number|null} [lt] UInt32Rules lt
         * @property {number|null} [lte] UInt32Rules lte
         * @property {number|null} [gt] UInt32Rules gt
         * @property {number|null} [gte] UInt32Rules gte
         * @property {Array.<number>|null} ["in"] UInt32Rules in
         * @property {Array.<number>|null} [notIn] UInt32Rules notIn
         * @property {boolean|null} [ignoreEmpty] UInt32Rules ignoreEmpty
         */

        /**
         * Constructs a new UInt32Rules.
         * @memberof validate
         * @classdesc Represents a UInt32Rules.
         * @implements IUInt32Rules
         * @constructor
         * @param {validate.IUInt32Rules=} [properties] Properties to set
         */
        function UInt32Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UInt32Rules const.
         * @member {number} const
         * @memberof validate.UInt32Rules
         * @instance
         */
        UInt32Rules.prototype["const"] = 0;

        /**
         * UInt32Rules lt.
         * @member {number} lt
         * @memberof validate.UInt32Rules
         * @instance
         */
        UInt32Rules.prototype.lt = 0;

        /**
         * UInt32Rules lte.
         * @member {number} lte
         * @memberof validate.UInt32Rules
         * @instance
         */
        UInt32Rules.prototype.lte = 0;

        /**
         * UInt32Rules gt.
         * @member {number} gt
         * @memberof validate.UInt32Rules
         * @instance
         */
        UInt32Rules.prototype.gt = 0;

        /**
         * UInt32Rules gte.
         * @member {number} gte
         * @memberof validate.UInt32Rules
         * @instance
         */
        UInt32Rules.prototype.gte = 0;

        /**
         * UInt32Rules in.
         * @member {Array.<number>} in
         * @memberof validate.UInt32Rules
         * @instance
         */
        UInt32Rules.prototype["in"] = $util.emptyArray;

        /**
         * UInt32Rules notIn.
         * @member {Array.<number>} notIn
         * @memberof validate.UInt32Rules
         * @instance
         */
        UInt32Rules.prototype.notIn = $util.emptyArray;

        /**
         * UInt32Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.UInt32Rules
         * @instance
         */
        UInt32Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new UInt32Rules instance using the specified properties.
         * @function create
         * @memberof validate.UInt32Rules
         * @static
         * @param {validate.IUInt32Rules=} [properties] Properties to set
         * @returns {validate.UInt32Rules} UInt32Rules instance
         */
        UInt32Rules.create = function create(properties) {
            return new UInt32Rules(properties);
        };

        /**
         * Encodes the specified UInt32Rules message. Does not implicitly {@link validate.UInt32Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.UInt32Rules
         * @static
         * @param {validate.IUInt32Rules} message UInt32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UInt32Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified UInt32Rules message, length delimited. Does not implicitly {@link validate.UInt32Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.UInt32Rules
         * @static
         * @param {validate.IUInt32Rules} message UInt32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UInt32Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UInt32Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.UInt32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.UInt32Rules} UInt32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UInt32Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.UInt32Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.uint32();
                        break;
                    }
                case 2: {
                        message.lt = reader.uint32();
                        break;
                    }
                case 3: {
                        message.lte = reader.uint32();
                        break;
                    }
                case 4: {
                        message.gt = reader.uint32();
                        break;
                    }
                case 5: {
                        message.gte = reader.uint32();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.uint32());
                        } else
                            message["in"].push(reader.uint32());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.uint32());
                        } else
                            message.notIn.push(reader.uint32());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UInt32Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.UInt32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.UInt32Rules} UInt32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UInt32Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UInt32Rules message.
         * @function verify
         * @memberof validate.UInt32Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UInt32Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]))
                    return "const: integer expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt))
                    return "lt: integer expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte))
                    return "lte: integer expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt))
                    return "gt: integer expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte))
                    return "gte: integer expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]))
                        return "in: integer[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]))
                        return "notIn: integer[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a UInt32Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.UInt32Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.UInt32Rules} UInt32Rules
         */
        UInt32Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.UInt32Rules)
                return object;
            let message = new $root.validate.UInt32Rules();
            if (object["const"] != null)
                message["const"] = object["const"] >>> 0;
            if (object.lt != null)
                message.lt = object.lt >>> 0;
            if (object.lte != null)
                message.lte = object.lte >>> 0;
            if (object.gt != null)
                message.gt = object.gt >>> 0;
            if (object.gte != null)
                message.gte = object.gte >>> 0;
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.UInt32Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = object["in"][i] >>> 0;
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.UInt32Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = object.notIn[i] >>> 0;
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a UInt32Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.UInt32Rules
         * @static
         * @param {validate.UInt32Rules} message UInt32Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UInt32Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = 0;
                object.lt = 0;
                object.lte = 0;
                object.gt = 0;
                object.gte = 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this UInt32Rules to JSON.
         * @function toJSON
         * @memberof validate.UInt32Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UInt32Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UInt32Rules
         * @function getTypeUrl
         * @memberof validate.UInt32Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UInt32Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.UInt32Rules";
        };

        return UInt32Rules;
    })();

    validate.UInt64Rules = (function() {

        /**
         * Properties of a UInt64Rules.
         * @memberof validate
         * @interface IUInt64Rules
         * @property {Long|null} ["const"] UInt64Rules const
         * @property {Long|null} [lt] UInt64Rules lt
         * @property {Long|null} [lte] UInt64Rules lte
         * @property {Long|null} [gt] UInt64Rules gt
         * @property {Long|null} [gte] UInt64Rules gte
         * @property {Array.<Long>|null} ["in"] UInt64Rules in
         * @property {Array.<Long>|null} [notIn] UInt64Rules notIn
         * @property {boolean|null} [ignoreEmpty] UInt64Rules ignoreEmpty
         */

        /**
         * Constructs a new UInt64Rules.
         * @memberof validate
         * @classdesc Represents a UInt64Rules.
         * @implements IUInt64Rules
         * @constructor
         * @param {validate.IUInt64Rules=} [properties] Properties to set
         */
        function UInt64Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UInt64Rules const.
         * @member {Long} const
         * @memberof validate.UInt64Rules
         * @instance
         */
        UInt64Rules.prototype["const"] = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UInt64Rules lt.
         * @member {Long} lt
         * @memberof validate.UInt64Rules
         * @instance
         */
        UInt64Rules.prototype.lt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UInt64Rules lte.
         * @member {Long} lte
         * @memberof validate.UInt64Rules
         * @instance
         */
        UInt64Rules.prototype.lte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UInt64Rules gt.
         * @member {Long} gt
         * @memberof validate.UInt64Rules
         * @instance
         */
        UInt64Rules.prototype.gt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UInt64Rules gte.
         * @member {Long} gte
         * @memberof validate.UInt64Rules
         * @instance
         */
        UInt64Rules.prototype.gte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UInt64Rules in.
         * @member {Array.<Long>} in
         * @memberof validate.UInt64Rules
         * @instance
         */
        UInt64Rules.prototype["in"] = $util.emptyArray;

        /**
         * UInt64Rules notIn.
         * @member {Array.<Long>} notIn
         * @memberof validate.UInt64Rules
         * @instance
         */
        UInt64Rules.prototype.notIn = $util.emptyArray;

        /**
         * UInt64Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.UInt64Rules
         * @instance
         */
        UInt64Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new UInt64Rules instance using the specified properties.
         * @function create
         * @memberof validate.UInt64Rules
         * @static
         * @param {validate.IUInt64Rules=} [properties] Properties to set
         * @returns {validate.UInt64Rules} UInt64Rules instance
         */
        UInt64Rules.create = function create(properties) {
            return new UInt64Rules(properties);
        };

        /**
         * Encodes the specified UInt64Rules message. Does not implicitly {@link validate.UInt64Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.UInt64Rules
         * @static
         * @param {validate.IUInt64Rules} message UInt64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UInt64Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified UInt64Rules message, length delimited. Does not implicitly {@link validate.UInt64Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.UInt64Rules
         * @static
         * @param {validate.IUInt64Rules} message UInt64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UInt64Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UInt64Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.UInt64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.UInt64Rules} UInt64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UInt64Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.UInt64Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.uint64();
                        break;
                    }
                case 2: {
                        message.lt = reader.uint64();
                        break;
                    }
                case 3: {
                        message.lte = reader.uint64();
                        break;
                    }
                case 4: {
                        message.gt = reader.uint64();
                        break;
                    }
                case 5: {
                        message.gte = reader.uint64();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.uint64());
                        } else
                            message["in"].push(reader.uint64());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.uint64());
                        } else
                            message.notIn.push(reader.uint64());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UInt64Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.UInt64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.UInt64Rules} UInt64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UInt64Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UInt64Rules message.
         * @function verify
         * @memberof validate.UInt64Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UInt64Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]) && !(message["const"] && $util.isInteger(message["const"].low) && $util.isInteger(message["const"].high)))
                    return "const: integer|Long expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt) && !(message.lt && $util.isInteger(message.lt.low) && $util.isInteger(message.lt.high)))
                    return "lt: integer|Long expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte) && !(message.lte && $util.isInteger(message.lte.low) && $util.isInteger(message.lte.high)))
                    return "lte: integer|Long expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt) && !(message.gt && $util.isInteger(message.gt.low) && $util.isInteger(message.gt.high)))
                    return "gt: integer|Long expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte) && !(message.gte && $util.isInteger(message.gte.low) && $util.isInteger(message.gte.high)))
                    return "gte: integer|Long expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]) && !(message["in"][i] && $util.isInteger(message["in"][i].low) && $util.isInteger(message["in"][i].high)))
                        return "in: integer|Long[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]) && !(message.notIn[i] && $util.isInteger(message.notIn[i].low) && $util.isInteger(message.notIn[i].high)))
                        return "notIn: integer|Long[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a UInt64Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.UInt64Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.UInt64Rules} UInt64Rules
         */
        UInt64Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.UInt64Rules)
                return object;
            let message = new $root.validate.UInt64Rules();
            if (object["const"] != null)
                if ($util.Long)
                    (message["const"] = $util.Long.fromValue(object["const"])).unsigned = true;
                else if (typeof object["const"] === "string")
                    message["const"] = parseInt(object["const"], 10);
                else if (typeof object["const"] === "number")
                    message["const"] = object["const"];
                else if (typeof object["const"] === "object")
                    message["const"] = new $util.LongBits(object["const"].low >>> 0, object["const"].high >>> 0).toNumber(true);
            if (object.lt != null)
                if ($util.Long)
                    (message.lt = $util.Long.fromValue(object.lt)).unsigned = true;
                else if (typeof object.lt === "string")
                    message.lt = parseInt(object.lt, 10);
                else if (typeof object.lt === "number")
                    message.lt = object.lt;
                else if (typeof object.lt === "object")
                    message.lt = new $util.LongBits(object.lt.low >>> 0, object.lt.high >>> 0).toNumber(true);
            if (object.lte != null)
                if ($util.Long)
                    (message.lte = $util.Long.fromValue(object.lte)).unsigned = true;
                else if (typeof object.lte === "string")
                    message.lte = parseInt(object.lte, 10);
                else if (typeof object.lte === "number")
                    message.lte = object.lte;
                else if (typeof object.lte === "object")
                    message.lte = new $util.LongBits(object.lte.low >>> 0, object.lte.high >>> 0).toNumber(true);
            if (object.gt != null)
                if ($util.Long)
                    (message.gt = $util.Long.fromValue(object.gt)).unsigned = true;
                else if (typeof object.gt === "string")
                    message.gt = parseInt(object.gt, 10);
                else if (typeof object.gt === "number")
                    message.gt = object.gt;
                else if (typeof object.gt === "object")
                    message.gt = new $util.LongBits(object.gt.low >>> 0, object.gt.high >>> 0).toNumber(true);
            if (object.gte != null)
                if ($util.Long)
                    (message.gte = $util.Long.fromValue(object.gte)).unsigned = true;
                else if (typeof object.gte === "string")
                    message.gte = parseInt(object.gte, 10);
                else if (typeof object.gte === "number")
                    message.gte = object.gte;
                else if (typeof object.gte === "object")
                    message.gte = new $util.LongBits(object.gte.low >>> 0, object.gte.high >>> 0).toNumber(true);
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.UInt64Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    if ($util.Long)
                        (message["in"][i] = $util.Long.fromValue(object["in"][i])).unsigned = true;
                    else if (typeof object["in"][i] === "string")
                        message["in"][i] = parseInt(object["in"][i], 10);
                    else if (typeof object["in"][i] === "number")
                        message["in"][i] = object["in"][i];
                    else if (typeof object["in"][i] === "object")
                        message["in"][i] = new $util.LongBits(object["in"][i].low >>> 0, object["in"][i].high >>> 0).toNumber(true);
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.UInt64Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    if ($util.Long)
                        (message.notIn[i] = $util.Long.fromValue(object.notIn[i])).unsigned = true;
                    else if (typeof object.notIn[i] === "string")
                        message.notIn[i] = parseInt(object.notIn[i], 10);
                    else if (typeof object.notIn[i] === "number")
                        message.notIn[i] = object.notIn[i];
                    else if (typeof object.notIn[i] === "object")
                        message.notIn[i] = new $util.LongBits(object.notIn[i].low >>> 0, object.notIn[i].high >>> 0).toNumber(true);
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a UInt64Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.UInt64Rules
         * @static
         * @param {validate.UInt64Rules} message UInt64Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UInt64Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object["const"] = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object["const"] = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.lt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.lte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.gt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.gte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gte = options.longs === String ? "0" : 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (typeof message["const"] === "number")
                    object["const"] = options.longs === String ? String(message["const"]) : message["const"];
                else
                    object["const"] = options.longs === String ? $util.Long.prototype.toString.call(message["const"]) : options.longs === Number ? new $util.LongBits(message["const"].low >>> 0, message["const"].high >>> 0).toNumber(true) : message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (typeof message.lt === "number")
                    object.lt = options.longs === String ? String(message.lt) : message.lt;
                else
                    object.lt = options.longs === String ? $util.Long.prototype.toString.call(message.lt) : options.longs === Number ? new $util.LongBits(message.lt.low >>> 0, message.lt.high >>> 0).toNumber(true) : message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (typeof message.lte === "number")
                    object.lte = options.longs === String ? String(message.lte) : message.lte;
                else
                    object.lte = options.longs === String ? $util.Long.prototype.toString.call(message.lte) : options.longs === Number ? new $util.LongBits(message.lte.low >>> 0, message.lte.high >>> 0).toNumber(true) : message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (typeof message.gt === "number")
                    object.gt = options.longs === String ? String(message.gt) : message.gt;
                else
                    object.gt = options.longs === String ? $util.Long.prototype.toString.call(message.gt) : options.longs === Number ? new $util.LongBits(message.gt.low >>> 0, message.gt.high >>> 0).toNumber(true) : message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (typeof message.gte === "number")
                    object.gte = options.longs === String ? String(message.gte) : message.gte;
                else
                    object.gte = options.longs === String ? $util.Long.prototype.toString.call(message.gte) : options.longs === Number ? new $util.LongBits(message.gte.low >>> 0, message.gte.high >>> 0).toNumber(true) : message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    if (typeof message["in"][j] === "number")
                        object["in"][j] = options.longs === String ? String(message["in"][j]) : message["in"][j];
                    else
                        object["in"][j] = options.longs === String ? $util.Long.prototype.toString.call(message["in"][j]) : options.longs === Number ? new $util.LongBits(message["in"][j].low >>> 0, message["in"][j].high >>> 0).toNumber(true) : message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    if (typeof message.notIn[j] === "number")
                        object.notIn[j] = options.longs === String ? String(message.notIn[j]) : message.notIn[j];
                    else
                        object.notIn[j] = options.longs === String ? $util.Long.prototype.toString.call(message.notIn[j]) : options.longs === Number ? new $util.LongBits(message.notIn[j].low >>> 0, message.notIn[j].high >>> 0).toNumber(true) : message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this UInt64Rules to JSON.
         * @function toJSON
         * @memberof validate.UInt64Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UInt64Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UInt64Rules
         * @function getTypeUrl
         * @memberof validate.UInt64Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UInt64Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.UInt64Rules";
        };

        return UInt64Rules;
    })();

    validate.SInt32Rules = (function() {

        /**
         * Properties of a SInt32Rules.
         * @memberof validate
         * @interface ISInt32Rules
         * @property {number|null} ["const"] SInt32Rules const
         * @property {number|null} [lt] SInt32Rules lt
         * @property {number|null} [lte] SInt32Rules lte
         * @property {number|null} [gt] SInt32Rules gt
         * @property {number|null} [gte] SInt32Rules gte
         * @property {Array.<number>|null} ["in"] SInt32Rules in
         * @property {Array.<number>|null} [notIn] SInt32Rules notIn
         * @property {boolean|null} [ignoreEmpty] SInt32Rules ignoreEmpty
         */

        /**
         * Constructs a new SInt32Rules.
         * @memberof validate
         * @classdesc Represents a SInt32Rules.
         * @implements ISInt32Rules
         * @constructor
         * @param {validate.ISInt32Rules=} [properties] Properties to set
         */
        function SInt32Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SInt32Rules const.
         * @member {number} const
         * @memberof validate.SInt32Rules
         * @instance
         */
        SInt32Rules.prototype["const"] = 0;

        /**
         * SInt32Rules lt.
         * @member {number} lt
         * @memberof validate.SInt32Rules
         * @instance
         */
        SInt32Rules.prototype.lt = 0;

        /**
         * SInt32Rules lte.
         * @member {number} lte
         * @memberof validate.SInt32Rules
         * @instance
         */
        SInt32Rules.prototype.lte = 0;

        /**
         * SInt32Rules gt.
         * @member {number} gt
         * @memberof validate.SInt32Rules
         * @instance
         */
        SInt32Rules.prototype.gt = 0;

        /**
         * SInt32Rules gte.
         * @member {number} gte
         * @memberof validate.SInt32Rules
         * @instance
         */
        SInt32Rules.prototype.gte = 0;

        /**
         * SInt32Rules in.
         * @member {Array.<number>} in
         * @memberof validate.SInt32Rules
         * @instance
         */
        SInt32Rules.prototype["in"] = $util.emptyArray;

        /**
         * SInt32Rules notIn.
         * @member {Array.<number>} notIn
         * @memberof validate.SInt32Rules
         * @instance
         */
        SInt32Rules.prototype.notIn = $util.emptyArray;

        /**
         * SInt32Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.SInt32Rules
         * @instance
         */
        SInt32Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new SInt32Rules instance using the specified properties.
         * @function create
         * @memberof validate.SInt32Rules
         * @static
         * @param {validate.ISInt32Rules=} [properties] Properties to set
         * @returns {validate.SInt32Rules} SInt32Rules instance
         */
        SInt32Rules.create = function create(properties) {
            return new SInt32Rules(properties);
        };

        /**
         * Encodes the specified SInt32Rules message. Does not implicitly {@link validate.SInt32Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.SInt32Rules
         * @static
         * @param {validate.ISInt32Rules} message SInt32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SInt32Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 0 =*/8).sint32(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 0 =*/16).sint32(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 0 =*/24).sint32(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 0 =*/32).sint32(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 0 =*/40).sint32(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 0 =*/48).sint32(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 0 =*/56).sint32(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified SInt32Rules message, length delimited. Does not implicitly {@link validate.SInt32Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.SInt32Rules
         * @static
         * @param {validate.ISInt32Rules} message SInt32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SInt32Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SInt32Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.SInt32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.SInt32Rules} SInt32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SInt32Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.SInt32Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.sint32();
                        break;
                    }
                case 2: {
                        message.lt = reader.sint32();
                        break;
                    }
                case 3: {
                        message.lte = reader.sint32();
                        break;
                    }
                case 4: {
                        message.gt = reader.sint32();
                        break;
                    }
                case 5: {
                        message.gte = reader.sint32();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.sint32());
                        } else
                            message["in"].push(reader.sint32());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.sint32());
                        } else
                            message.notIn.push(reader.sint32());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SInt32Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.SInt32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.SInt32Rules} SInt32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SInt32Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SInt32Rules message.
         * @function verify
         * @memberof validate.SInt32Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SInt32Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]))
                    return "const: integer expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt))
                    return "lt: integer expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte))
                    return "lte: integer expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt))
                    return "gt: integer expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte))
                    return "gte: integer expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]))
                        return "in: integer[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]))
                        return "notIn: integer[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a SInt32Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.SInt32Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.SInt32Rules} SInt32Rules
         */
        SInt32Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.SInt32Rules)
                return object;
            let message = new $root.validate.SInt32Rules();
            if (object["const"] != null)
                message["const"] = object["const"] | 0;
            if (object.lt != null)
                message.lt = object.lt | 0;
            if (object.lte != null)
                message.lte = object.lte | 0;
            if (object.gt != null)
                message.gt = object.gt | 0;
            if (object.gte != null)
                message.gte = object.gte | 0;
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.SInt32Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = object["in"][i] | 0;
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.SInt32Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = object.notIn[i] | 0;
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a SInt32Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.SInt32Rules
         * @static
         * @param {validate.SInt32Rules} message SInt32Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SInt32Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = 0;
                object.lt = 0;
                object.lte = 0;
                object.gt = 0;
                object.gte = 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this SInt32Rules to JSON.
         * @function toJSON
         * @memberof validate.SInt32Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SInt32Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SInt32Rules
         * @function getTypeUrl
         * @memberof validate.SInt32Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SInt32Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.SInt32Rules";
        };

        return SInt32Rules;
    })();

    validate.SInt64Rules = (function() {

        /**
         * Properties of a SInt64Rules.
         * @memberof validate
         * @interface ISInt64Rules
         * @property {Long|null} ["const"] SInt64Rules const
         * @property {Long|null} [lt] SInt64Rules lt
         * @property {Long|null} [lte] SInt64Rules lte
         * @property {Long|null} [gt] SInt64Rules gt
         * @property {Long|null} [gte] SInt64Rules gte
         * @property {Array.<Long>|null} ["in"] SInt64Rules in
         * @property {Array.<Long>|null} [notIn] SInt64Rules notIn
         * @property {boolean|null} [ignoreEmpty] SInt64Rules ignoreEmpty
         */

        /**
         * Constructs a new SInt64Rules.
         * @memberof validate
         * @classdesc Represents a SInt64Rules.
         * @implements ISInt64Rules
         * @constructor
         * @param {validate.ISInt64Rules=} [properties] Properties to set
         */
        function SInt64Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SInt64Rules const.
         * @member {Long} const
         * @memberof validate.SInt64Rules
         * @instance
         */
        SInt64Rules.prototype["const"] = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SInt64Rules lt.
         * @member {Long} lt
         * @memberof validate.SInt64Rules
         * @instance
         */
        SInt64Rules.prototype.lt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SInt64Rules lte.
         * @member {Long} lte
         * @memberof validate.SInt64Rules
         * @instance
         */
        SInt64Rules.prototype.lte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SInt64Rules gt.
         * @member {Long} gt
         * @memberof validate.SInt64Rules
         * @instance
         */
        SInt64Rules.prototype.gt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SInt64Rules gte.
         * @member {Long} gte
         * @memberof validate.SInt64Rules
         * @instance
         */
        SInt64Rules.prototype.gte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SInt64Rules in.
         * @member {Array.<Long>} in
         * @memberof validate.SInt64Rules
         * @instance
         */
        SInt64Rules.prototype["in"] = $util.emptyArray;

        /**
         * SInt64Rules notIn.
         * @member {Array.<Long>} notIn
         * @memberof validate.SInt64Rules
         * @instance
         */
        SInt64Rules.prototype.notIn = $util.emptyArray;

        /**
         * SInt64Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.SInt64Rules
         * @instance
         */
        SInt64Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new SInt64Rules instance using the specified properties.
         * @function create
         * @memberof validate.SInt64Rules
         * @static
         * @param {validate.ISInt64Rules=} [properties] Properties to set
         * @returns {validate.SInt64Rules} SInt64Rules instance
         */
        SInt64Rules.create = function create(properties) {
            return new SInt64Rules(properties);
        };

        /**
         * Encodes the specified SInt64Rules message. Does not implicitly {@link validate.SInt64Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.SInt64Rules
         * @static
         * @param {validate.ISInt64Rules} message SInt64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SInt64Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 0 =*/8).sint64(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 0 =*/16).sint64(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 0 =*/24).sint64(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 0 =*/32).sint64(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 0 =*/40).sint64(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 0 =*/48).sint64(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 0 =*/56).sint64(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified SInt64Rules message, length delimited. Does not implicitly {@link validate.SInt64Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.SInt64Rules
         * @static
         * @param {validate.ISInt64Rules} message SInt64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SInt64Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SInt64Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.SInt64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.SInt64Rules} SInt64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SInt64Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.SInt64Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.sint64();
                        break;
                    }
                case 2: {
                        message.lt = reader.sint64();
                        break;
                    }
                case 3: {
                        message.lte = reader.sint64();
                        break;
                    }
                case 4: {
                        message.gt = reader.sint64();
                        break;
                    }
                case 5: {
                        message.gte = reader.sint64();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.sint64());
                        } else
                            message["in"].push(reader.sint64());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.sint64());
                        } else
                            message.notIn.push(reader.sint64());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SInt64Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.SInt64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.SInt64Rules} SInt64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SInt64Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SInt64Rules message.
         * @function verify
         * @memberof validate.SInt64Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SInt64Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]) && !(message["const"] && $util.isInteger(message["const"].low) && $util.isInteger(message["const"].high)))
                    return "const: integer|Long expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt) && !(message.lt && $util.isInteger(message.lt.low) && $util.isInteger(message.lt.high)))
                    return "lt: integer|Long expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte) && !(message.lte && $util.isInteger(message.lte.low) && $util.isInteger(message.lte.high)))
                    return "lte: integer|Long expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt) && !(message.gt && $util.isInteger(message.gt.low) && $util.isInteger(message.gt.high)))
                    return "gt: integer|Long expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte) && !(message.gte && $util.isInteger(message.gte.low) && $util.isInteger(message.gte.high)))
                    return "gte: integer|Long expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]) && !(message["in"][i] && $util.isInteger(message["in"][i].low) && $util.isInteger(message["in"][i].high)))
                        return "in: integer|Long[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]) && !(message.notIn[i] && $util.isInteger(message.notIn[i].low) && $util.isInteger(message.notIn[i].high)))
                        return "notIn: integer|Long[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a SInt64Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.SInt64Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.SInt64Rules} SInt64Rules
         */
        SInt64Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.SInt64Rules)
                return object;
            let message = new $root.validate.SInt64Rules();
            if (object["const"] != null)
                if ($util.Long)
                    (message["const"] = $util.Long.fromValue(object["const"])).unsigned = false;
                else if (typeof object["const"] === "string")
                    message["const"] = parseInt(object["const"], 10);
                else if (typeof object["const"] === "number")
                    message["const"] = object["const"];
                else if (typeof object["const"] === "object")
                    message["const"] = new $util.LongBits(object["const"].low >>> 0, object["const"].high >>> 0).toNumber();
            if (object.lt != null)
                if ($util.Long)
                    (message.lt = $util.Long.fromValue(object.lt)).unsigned = false;
                else if (typeof object.lt === "string")
                    message.lt = parseInt(object.lt, 10);
                else if (typeof object.lt === "number")
                    message.lt = object.lt;
                else if (typeof object.lt === "object")
                    message.lt = new $util.LongBits(object.lt.low >>> 0, object.lt.high >>> 0).toNumber();
            if (object.lte != null)
                if ($util.Long)
                    (message.lte = $util.Long.fromValue(object.lte)).unsigned = false;
                else if (typeof object.lte === "string")
                    message.lte = parseInt(object.lte, 10);
                else if (typeof object.lte === "number")
                    message.lte = object.lte;
                else if (typeof object.lte === "object")
                    message.lte = new $util.LongBits(object.lte.low >>> 0, object.lte.high >>> 0).toNumber();
            if (object.gt != null)
                if ($util.Long)
                    (message.gt = $util.Long.fromValue(object.gt)).unsigned = false;
                else if (typeof object.gt === "string")
                    message.gt = parseInt(object.gt, 10);
                else if (typeof object.gt === "number")
                    message.gt = object.gt;
                else if (typeof object.gt === "object")
                    message.gt = new $util.LongBits(object.gt.low >>> 0, object.gt.high >>> 0).toNumber();
            if (object.gte != null)
                if ($util.Long)
                    (message.gte = $util.Long.fromValue(object.gte)).unsigned = false;
                else if (typeof object.gte === "string")
                    message.gte = parseInt(object.gte, 10);
                else if (typeof object.gte === "number")
                    message.gte = object.gte;
                else if (typeof object.gte === "object")
                    message.gte = new $util.LongBits(object.gte.low >>> 0, object.gte.high >>> 0).toNumber();
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.SInt64Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    if ($util.Long)
                        (message["in"][i] = $util.Long.fromValue(object["in"][i])).unsigned = false;
                    else if (typeof object["in"][i] === "string")
                        message["in"][i] = parseInt(object["in"][i], 10);
                    else if (typeof object["in"][i] === "number")
                        message["in"][i] = object["in"][i];
                    else if (typeof object["in"][i] === "object")
                        message["in"][i] = new $util.LongBits(object["in"][i].low >>> 0, object["in"][i].high >>> 0).toNumber();
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.SInt64Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    if ($util.Long)
                        (message.notIn[i] = $util.Long.fromValue(object.notIn[i])).unsigned = false;
                    else if (typeof object.notIn[i] === "string")
                        message.notIn[i] = parseInt(object.notIn[i], 10);
                    else if (typeof object.notIn[i] === "number")
                        message.notIn[i] = object.notIn[i];
                    else if (typeof object.notIn[i] === "object")
                        message.notIn[i] = new $util.LongBits(object.notIn[i].low >>> 0, object.notIn[i].high >>> 0).toNumber();
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a SInt64Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.SInt64Rules
         * @static
         * @param {validate.SInt64Rules} message SInt64Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SInt64Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object["const"] = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object["const"] = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.gt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.gte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gte = options.longs === String ? "0" : 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (typeof message["const"] === "number")
                    object["const"] = options.longs === String ? String(message["const"]) : message["const"];
                else
                    object["const"] = options.longs === String ? $util.Long.prototype.toString.call(message["const"]) : options.longs === Number ? new $util.LongBits(message["const"].low >>> 0, message["const"].high >>> 0).toNumber() : message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (typeof message.lt === "number")
                    object.lt = options.longs === String ? String(message.lt) : message.lt;
                else
                    object.lt = options.longs === String ? $util.Long.prototype.toString.call(message.lt) : options.longs === Number ? new $util.LongBits(message.lt.low >>> 0, message.lt.high >>> 0).toNumber() : message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (typeof message.lte === "number")
                    object.lte = options.longs === String ? String(message.lte) : message.lte;
                else
                    object.lte = options.longs === String ? $util.Long.prototype.toString.call(message.lte) : options.longs === Number ? new $util.LongBits(message.lte.low >>> 0, message.lte.high >>> 0).toNumber() : message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (typeof message.gt === "number")
                    object.gt = options.longs === String ? String(message.gt) : message.gt;
                else
                    object.gt = options.longs === String ? $util.Long.prototype.toString.call(message.gt) : options.longs === Number ? new $util.LongBits(message.gt.low >>> 0, message.gt.high >>> 0).toNumber() : message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (typeof message.gte === "number")
                    object.gte = options.longs === String ? String(message.gte) : message.gte;
                else
                    object.gte = options.longs === String ? $util.Long.prototype.toString.call(message.gte) : options.longs === Number ? new $util.LongBits(message.gte.low >>> 0, message.gte.high >>> 0).toNumber() : message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    if (typeof message["in"][j] === "number")
                        object["in"][j] = options.longs === String ? String(message["in"][j]) : message["in"][j];
                    else
                        object["in"][j] = options.longs === String ? $util.Long.prototype.toString.call(message["in"][j]) : options.longs === Number ? new $util.LongBits(message["in"][j].low >>> 0, message["in"][j].high >>> 0).toNumber() : message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    if (typeof message.notIn[j] === "number")
                        object.notIn[j] = options.longs === String ? String(message.notIn[j]) : message.notIn[j];
                    else
                        object.notIn[j] = options.longs === String ? $util.Long.prototype.toString.call(message.notIn[j]) : options.longs === Number ? new $util.LongBits(message.notIn[j].low >>> 0, message.notIn[j].high >>> 0).toNumber() : message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this SInt64Rules to JSON.
         * @function toJSON
         * @memberof validate.SInt64Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SInt64Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SInt64Rules
         * @function getTypeUrl
         * @memberof validate.SInt64Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SInt64Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.SInt64Rules";
        };

        return SInt64Rules;
    })();

    validate.Fixed32Rules = (function() {

        /**
         * Properties of a Fixed32Rules.
         * @memberof validate
         * @interface IFixed32Rules
         * @property {number|null} ["const"] Fixed32Rules const
         * @property {number|null} [lt] Fixed32Rules lt
         * @property {number|null} [lte] Fixed32Rules lte
         * @property {number|null} [gt] Fixed32Rules gt
         * @property {number|null} [gte] Fixed32Rules gte
         * @property {Array.<number>|null} ["in"] Fixed32Rules in
         * @property {Array.<number>|null} [notIn] Fixed32Rules notIn
         * @property {boolean|null} [ignoreEmpty] Fixed32Rules ignoreEmpty
         */

        /**
         * Constructs a new Fixed32Rules.
         * @memberof validate
         * @classdesc Represents a Fixed32Rules.
         * @implements IFixed32Rules
         * @constructor
         * @param {validate.IFixed32Rules=} [properties] Properties to set
         */
        function Fixed32Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Fixed32Rules const.
         * @member {number} const
         * @memberof validate.Fixed32Rules
         * @instance
         */
        Fixed32Rules.prototype["const"] = 0;

        /**
         * Fixed32Rules lt.
         * @member {number} lt
         * @memberof validate.Fixed32Rules
         * @instance
         */
        Fixed32Rules.prototype.lt = 0;

        /**
         * Fixed32Rules lte.
         * @member {number} lte
         * @memberof validate.Fixed32Rules
         * @instance
         */
        Fixed32Rules.prototype.lte = 0;

        /**
         * Fixed32Rules gt.
         * @member {number} gt
         * @memberof validate.Fixed32Rules
         * @instance
         */
        Fixed32Rules.prototype.gt = 0;

        /**
         * Fixed32Rules gte.
         * @member {number} gte
         * @memberof validate.Fixed32Rules
         * @instance
         */
        Fixed32Rules.prototype.gte = 0;

        /**
         * Fixed32Rules in.
         * @member {Array.<number>} in
         * @memberof validate.Fixed32Rules
         * @instance
         */
        Fixed32Rules.prototype["in"] = $util.emptyArray;

        /**
         * Fixed32Rules notIn.
         * @member {Array.<number>} notIn
         * @memberof validate.Fixed32Rules
         * @instance
         */
        Fixed32Rules.prototype.notIn = $util.emptyArray;

        /**
         * Fixed32Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.Fixed32Rules
         * @instance
         */
        Fixed32Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new Fixed32Rules instance using the specified properties.
         * @function create
         * @memberof validate.Fixed32Rules
         * @static
         * @param {validate.IFixed32Rules=} [properties] Properties to set
         * @returns {validate.Fixed32Rules} Fixed32Rules instance
         */
        Fixed32Rules.create = function create(properties) {
            return new Fixed32Rules(properties);
        };

        /**
         * Encodes the specified Fixed32Rules message. Does not implicitly {@link validate.Fixed32Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.Fixed32Rules
         * @static
         * @param {validate.IFixed32Rules} message Fixed32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fixed32Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 5 =*/13).fixed32(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 5 =*/21).fixed32(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 5 =*/29).fixed32(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 5 =*/37).fixed32(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 5 =*/45).fixed32(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 5 =*/53).fixed32(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 5 =*/61).fixed32(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified Fixed32Rules message, length delimited. Does not implicitly {@link validate.Fixed32Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.Fixed32Rules
         * @static
         * @param {validate.IFixed32Rules} message Fixed32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fixed32Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Fixed32Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.Fixed32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.Fixed32Rules} Fixed32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fixed32Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.Fixed32Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.fixed32();
                        break;
                    }
                case 2: {
                        message.lt = reader.fixed32();
                        break;
                    }
                case 3: {
                        message.lte = reader.fixed32();
                        break;
                    }
                case 4: {
                        message.gt = reader.fixed32();
                        break;
                    }
                case 5: {
                        message.gte = reader.fixed32();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.fixed32());
                        } else
                            message["in"].push(reader.fixed32());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.fixed32());
                        } else
                            message.notIn.push(reader.fixed32());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Fixed32Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.Fixed32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.Fixed32Rules} Fixed32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fixed32Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Fixed32Rules message.
         * @function verify
         * @memberof validate.Fixed32Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Fixed32Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]))
                    return "const: integer expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt))
                    return "lt: integer expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte))
                    return "lte: integer expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt))
                    return "gt: integer expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte))
                    return "gte: integer expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]))
                        return "in: integer[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]))
                        return "notIn: integer[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a Fixed32Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.Fixed32Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.Fixed32Rules} Fixed32Rules
         */
        Fixed32Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.Fixed32Rules)
                return object;
            let message = new $root.validate.Fixed32Rules();
            if (object["const"] != null)
                message["const"] = object["const"] >>> 0;
            if (object.lt != null)
                message.lt = object.lt >>> 0;
            if (object.lte != null)
                message.lte = object.lte >>> 0;
            if (object.gt != null)
                message.gt = object.gt >>> 0;
            if (object.gte != null)
                message.gte = object.gte >>> 0;
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.Fixed32Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = object["in"][i] >>> 0;
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.Fixed32Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = object.notIn[i] >>> 0;
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a Fixed32Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.Fixed32Rules
         * @static
         * @param {validate.Fixed32Rules} message Fixed32Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Fixed32Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = 0;
                object.lt = 0;
                object.lte = 0;
                object.gt = 0;
                object.gte = 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this Fixed32Rules to JSON.
         * @function toJSON
         * @memberof validate.Fixed32Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Fixed32Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Fixed32Rules
         * @function getTypeUrl
         * @memberof validate.Fixed32Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Fixed32Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.Fixed32Rules";
        };

        return Fixed32Rules;
    })();

    validate.Fixed64Rules = (function() {

        /**
         * Properties of a Fixed64Rules.
         * @memberof validate
         * @interface IFixed64Rules
         * @property {Long|null} ["const"] Fixed64Rules const
         * @property {Long|null} [lt] Fixed64Rules lt
         * @property {Long|null} [lte] Fixed64Rules lte
         * @property {Long|null} [gt] Fixed64Rules gt
         * @property {Long|null} [gte] Fixed64Rules gte
         * @property {Array.<Long>|null} ["in"] Fixed64Rules in
         * @property {Array.<Long>|null} [notIn] Fixed64Rules notIn
         * @property {boolean|null} [ignoreEmpty] Fixed64Rules ignoreEmpty
         */

        /**
         * Constructs a new Fixed64Rules.
         * @memberof validate
         * @classdesc Represents a Fixed64Rules.
         * @implements IFixed64Rules
         * @constructor
         * @param {validate.IFixed64Rules=} [properties] Properties to set
         */
        function Fixed64Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Fixed64Rules const.
         * @member {Long} const
         * @memberof validate.Fixed64Rules
         * @instance
         */
        Fixed64Rules.prototype["const"] = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Fixed64Rules lt.
         * @member {Long} lt
         * @memberof validate.Fixed64Rules
         * @instance
         */
        Fixed64Rules.prototype.lt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Fixed64Rules lte.
         * @member {Long} lte
         * @memberof validate.Fixed64Rules
         * @instance
         */
        Fixed64Rules.prototype.lte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Fixed64Rules gt.
         * @member {Long} gt
         * @memberof validate.Fixed64Rules
         * @instance
         */
        Fixed64Rules.prototype.gt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Fixed64Rules gte.
         * @member {Long} gte
         * @memberof validate.Fixed64Rules
         * @instance
         */
        Fixed64Rules.prototype.gte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Fixed64Rules in.
         * @member {Array.<Long>} in
         * @memberof validate.Fixed64Rules
         * @instance
         */
        Fixed64Rules.prototype["in"] = $util.emptyArray;

        /**
         * Fixed64Rules notIn.
         * @member {Array.<Long>} notIn
         * @memberof validate.Fixed64Rules
         * @instance
         */
        Fixed64Rules.prototype.notIn = $util.emptyArray;

        /**
         * Fixed64Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.Fixed64Rules
         * @instance
         */
        Fixed64Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new Fixed64Rules instance using the specified properties.
         * @function create
         * @memberof validate.Fixed64Rules
         * @static
         * @param {validate.IFixed64Rules=} [properties] Properties to set
         * @returns {validate.Fixed64Rules} Fixed64Rules instance
         */
        Fixed64Rules.create = function create(properties) {
            return new Fixed64Rules(properties);
        };

        /**
         * Encodes the specified Fixed64Rules message. Does not implicitly {@link validate.Fixed64Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.Fixed64Rules
         * @static
         * @param {validate.IFixed64Rules} message Fixed64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fixed64Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 1 =*/9).fixed64(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 1 =*/17).fixed64(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 1 =*/25).fixed64(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 1 =*/33).fixed64(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 1 =*/41).fixed64(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 1 =*/49).fixed64(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 1 =*/57).fixed64(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified Fixed64Rules message, length delimited. Does not implicitly {@link validate.Fixed64Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.Fixed64Rules
         * @static
         * @param {validate.IFixed64Rules} message Fixed64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fixed64Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Fixed64Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.Fixed64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.Fixed64Rules} Fixed64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fixed64Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.Fixed64Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.fixed64();
                        break;
                    }
                case 2: {
                        message.lt = reader.fixed64();
                        break;
                    }
                case 3: {
                        message.lte = reader.fixed64();
                        break;
                    }
                case 4: {
                        message.gt = reader.fixed64();
                        break;
                    }
                case 5: {
                        message.gte = reader.fixed64();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.fixed64());
                        } else
                            message["in"].push(reader.fixed64());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.fixed64());
                        } else
                            message.notIn.push(reader.fixed64());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Fixed64Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.Fixed64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.Fixed64Rules} Fixed64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fixed64Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Fixed64Rules message.
         * @function verify
         * @memberof validate.Fixed64Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Fixed64Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]) && !(message["const"] && $util.isInteger(message["const"].low) && $util.isInteger(message["const"].high)))
                    return "const: integer|Long expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt) && !(message.lt && $util.isInteger(message.lt.low) && $util.isInteger(message.lt.high)))
                    return "lt: integer|Long expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte) && !(message.lte && $util.isInteger(message.lte.low) && $util.isInteger(message.lte.high)))
                    return "lte: integer|Long expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt) && !(message.gt && $util.isInteger(message.gt.low) && $util.isInteger(message.gt.high)))
                    return "gt: integer|Long expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte) && !(message.gte && $util.isInteger(message.gte.low) && $util.isInteger(message.gte.high)))
                    return "gte: integer|Long expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]) && !(message["in"][i] && $util.isInteger(message["in"][i].low) && $util.isInteger(message["in"][i].high)))
                        return "in: integer|Long[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]) && !(message.notIn[i] && $util.isInteger(message.notIn[i].low) && $util.isInteger(message.notIn[i].high)))
                        return "notIn: integer|Long[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a Fixed64Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.Fixed64Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.Fixed64Rules} Fixed64Rules
         */
        Fixed64Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.Fixed64Rules)
                return object;
            let message = new $root.validate.Fixed64Rules();
            if (object["const"] != null)
                if ($util.Long)
                    (message["const"] = $util.Long.fromValue(object["const"])).unsigned = false;
                else if (typeof object["const"] === "string")
                    message["const"] = parseInt(object["const"], 10);
                else if (typeof object["const"] === "number")
                    message["const"] = object["const"];
                else if (typeof object["const"] === "object")
                    message["const"] = new $util.LongBits(object["const"].low >>> 0, object["const"].high >>> 0).toNumber();
            if (object.lt != null)
                if ($util.Long)
                    (message.lt = $util.Long.fromValue(object.lt)).unsigned = false;
                else if (typeof object.lt === "string")
                    message.lt = parseInt(object.lt, 10);
                else if (typeof object.lt === "number")
                    message.lt = object.lt;
                else if (typeof object.lt === "object")
                    message.lt = new $util.LongBits(object.lt.low >>> 0, object.lt.high >>> 0).toNumber();
            if (object.lte != null)
                if ($util.Long)
                    (message.lte = $util.Long.fromValue(object.lte)).unsigned = false;
                else if (typeof object.lte === "string")
                    message.lte = parseInt(object.lte, 10);
                else if (typeof object.lte === "number")
                    message.lte = object.lte;
                else if (typeof object.lte === "object")
                    message.lte = new $util.LongBits(object.lte.low >>> 0, object.lte.high >>> 0).toNumber();
            if (object.gt != null)
                if ($util.Long)
                    (message.gt = $util.Long.fromValue(object.gt)).unsigned = false;
                else if (typeof object.gt === "string")
                    message.gt = parseInt(object.gt, 10);
                else if (typeof object.gt === "number")
                    message.gt = object.gt;
                else if (typeof object.gt === "object")
                    message.gt = new $util.LongBits(object.gt.low >>> 0, object.gt.high >>> 0).toNumber();
            if (object.gte != null)
                if ($util.Long)
                    (message.gte = $util.Long.fromValue(object.gte)).unsigned = false;
                else if (typeof object.gte === "string")
                    message.gte = parseInt(object.gte, 10);
                else if (typeof object.gte === "number")
                    message.gte = object.gte;
                else if (typeof object.gte === "object")
                    message.gte = new $util.LongBits(object.gte.low >>> 0, object.gte.high >>> 0).toNumber();
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.Fixed64Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    if ($util.Long)
                        (message["in"][i] = $util.Long.fromValue(object["in"][i])).unsigned = false;
                    else if (typeof object["in"][i] === "string")
                        message["in"][i] = parseInt(object["in"][i], 10);
                    else if (typeof object["in"][i] === "number")
                        message["in"][i] = object["in"][i];
                    else if (typeof object["in"][i] === "object")
                        message["in"][i] = new $util.LongBits(object["in"][i].low >>> 0, object["in"][i].high >>> 0).toNumber();
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.Fixed64Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    if ($util.Long)
                        (message.notIn[i] = $util.Long.fromValue(object.notIn[i])).unsigned = false;
                    else if (typeof object.notIn[i] === "string")
                        message.notIn[i] = parseInt(object.notIn[i], 10);
                    else if (typeof object.notIn[i] === "number")
                        message.notIn[i] = object.notIn[i];
                    else if (typeof object.notIn[i] === "object")
                        message.notIn[i] = new $util.LongBits(object.notIn[i].low >>> 0, object.notIn[i].high >>> 0).toNumber();
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a Fixed64Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.Fixed64Rules
         * @static
         * @param {validate.Fixed64Rules} message Fixed64Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Fixed64Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object["const"] = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object["const"] = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.gt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.gte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gte = options.longs === String ? "0" : 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (typeof message["const"] === "number")
                    object["const"] = options.longs === String ? String(message["const"]) : message["const"];
                else
                    object["const"] = options.longs === String ? $util.Long.prototype.toString.call(message["const"]) : options.longs === Number ? new $util.LongBits(message["const"].low >>> 0, message["const"].high >>> 0).toNumber() : message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (typeof message.lt === "number")
                    object.lt = options.longs === String ? String(message.lt) : message.lt;
                else
                    object.lt = options.longs === String ? $util.Long.prototype.toString.call(message.lt) : options.longs === Number ? new $util.LongBits(message.lt.low >>> 0, message.lt.high >>> 0).toNumber() : message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (typeof message.lte === "number")
                    object.lte = options.longs === String ? String(message.lte) : message.lte;
                else
                    object.lte = options.longs === String ? $util.Long.prototype.toString.call(message.lte) : options.longs === Number ? new $util.LongBits(message.lte.low >>> 0, message.lte.high >>> 0).toNumber() : message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (typeof message.gt === "number")
                    object.gt = options.longs === String ? String(message.gt) : message.gt;
                else
                    object.gt = options.longs === String ? $util.Long.prototype.toString.call(message.gt) : options.longs === Number ? new $util.LongBits(message.gt.low >>> 0, message.gt.high >>> 0).toNumber() : message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (typeof message.gte === "number")
                    object.gte = options.longs === String ? String(message.gte) : message.gte;
                else
                    object.gte = options.longs === String ? $util.Long.prototype.toString.call(message.gte) : options.longs === Number ? new $util.LongBits(message.gte.low >>> 0, message.gte.high >>> 0).toNumber() : message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    if (typeof message["in"][j] === "number")
                        object["in"][j] = options.longs === String ? String(message["in"][j]) : message["in"][j];
                    else
                        object["in"][j] = options.longs === String ? $util.Long.prototype.toString.call(message["in"][j]) : options.longs === Number ? new $util.LongBits(message["in"][j].low >>> 0, message["in"][j].high >>> 0).toNumber() : message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    if (typeof message.notIn[j] === "number")
                        object.notIn[j] = options.longs === String ? String(message.notIn[j]) : message.notIn[j];
                    else
                        object.notIn[j] = options.longs === String ? $util.Long.prototype.toString.call(message.notIn[j]) : options.longs === Number ? new $util.LongBits(message.notIn[j].low >>> 0, message.notIn[j].high >>> 0).toNumber() : message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this Fixed64Rules to JSON.
         * @function toJSON
         * @memberof validate.Fixed64Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Fixed64Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Fixed64Rules
         * @function getTypeUrl
         * @memberof validate.Fixed64Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Fixed64Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.Fixed64Rules";
        };

        return Fixed64Rules;
    })();

    validate.SFixed32Rules = (function() {

        /**
         * Properties of a SFixed32Rules.
         * @memberof validate
         * @interface ISFixed32Rules
         * @property {number|null} ["const"] SFixed32Rules const
         * @property {number|null} [lt] SFixed32Rules lt
         * @property {number|null} [lte] SFixed32Rules lte
         * @property {number|null} [gt] SFixed32Rules gt
         * @property {number|null} [gte] SFixed32Rules gte
         * @property {Array.<number>|null} ["in"] SFixed32Rules in
         * @property {Array.<number>|null} [notIn] SFixed32Rules notIn
         * @property {boolean|null} [ignoreEmpty] SFixed32Rules ignoreEmpty
         */

        /**
         * Constructs a new SFixed32Rules.
         * @memberof validate
         * @classdesc Represents a SFixed32Rules.
         * @implements ISFixed32Rules
         * @constructor
         * @param {validate.ISFixed32Rules=} [properties] Properties to set
         */
        function SFixed32Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SFixed32Rules const.
         * @member {number} const
         * @memberof validate.SFixed32Rules
         * @instance
         */
        SFixed32Rules.prototype["const"] = 0;

        /**
         * SFixed32Rules lt.
         * @member {number} lt
         * @memberof validate.SFixed32Rules
         * @instance
         */
        SFixed32Rules.prototype.lt = 0;

        /**
         * SFixed32Rules lte.
         * @member {number} lte
         * @memberof validate.SFixed32Rules
         * @instance
         */
        SFixed32Rules.prototype.lte = 0;

        /**
         * SFixed32Rules gt.
         * @member {number} gt
         * @memberof validate.SFixed32Rules
         * @instance
         */
        SFixed32Rules.prototype.gt = 0;

        /**
         * SFixed32Rules gte.
         * @member {number} gte
         * @memberof validate.SFixed32Rules
         * @instance
         */
        SFixed32Rules.prototype.gte = 0;

        /**
         * SFixed32Rules in.
         * @member {Array.<number>} in
         * @memberof validate.SFixed32Rules
         * @instance
         */
        SFixed32Rules.prototype["in"] = $util.emptyArray;

        /**
         * SFixed32Rules notIn.
         * @member {Array.<number>} notIn
         * @memberof validate.SFixed32Rules
         * @instance
         */
        SFixed32Rules.prototype.notIn = $util.emptyArray;

        /**
         * SFixed32Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.SFixed32Rules
         * @instance
         */
        SFixed32Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new SFixed32Rules instance using the specified properties.
         * @function create
         * @memberof validate.SFixed32Rules
         * @static
         * @param {validate.ISFixed32Rules=} [properties] Properties to set
         * @returns {validate.SFixed32Rules} SFixed32Rules instance
         */
        SFixed32Rules.create = function create(properties) {
            return new SFixed32Rules(properties);
        };

        /**
         * Encodes the specified SFixed32Rules message. Does not implicitly {@link validate.SFixed32Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.SFixed32Rules
         * @static
         * @param {validate.ISFixed32Rules} message SFixed32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SFixed32Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 5 =*/13).sfixed32(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 5 =*/21).sfixed32(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 5 =*/29).sfixed32(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 5 =*/37).sfixed32(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 5 =*/45).sfixed32(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 5 =*/53).sfixed32(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 5 =*/61).sfixed32(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified SFixed32Rules message, length delimited. Does not implicitly {@link validate.SFixed32Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.SFixed32Rules
         * @static
         * @param {validate.ISFixed32Rules} message SFixed32Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SFixed32Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SFixed32Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.SFixed32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.SFixed32Rules} SFixed32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SFixed32Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.SFixed32Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.sfixed32();
                        break;
                    }
                case 2: {
                        message.lt = reader.sfixed32();
                        break;
                    }
                case 3: {
                        message.lte = reader.sfixed32();
                        break;
                    }
                case 4: {
                        message.gt = reader.sfixed32();
                        break;
                    }
                case 5: {
                        message.gte = reader.sfixed32();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.sfixed32());
                        } else
                            message["in"].push(reader.sfixed32());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.sfixed32());
                        } else
                            message.notIn.push(reader.sfixed32());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SFixed32Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.SFixed32Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.SFixed32Rules} SFixed32Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SFixed32Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SFixed32Rules message.
         * @function verify
         * @memberof validate.SFixed32Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SFixed32Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]))
                    return "const: integer expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt))
                    return "lt: integer expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte))
                    return "lte: integer expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt))
                    return "gt: integer expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte))
                    return "gte: integer expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]))
                        return "in: integer[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]))
                        return "notIn: integer[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a SFixed32Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.SFixed32Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.SFixed32Rules} SFixed32Rules
         */
        SFixed32Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.SFixed32Rules)
                return object;
            let message = new $root.validate.SFixed32Rules();
            if (object["const"] != null)
                message["const"] = object["const"] | 0;
            if (object.lt != null)
                message.lt = object.lt | 0;
            if (object.lte != null)
                message.lte = object.lte | 0;
            if (object.gt != null)
                message.gt = object.gt | 0;
            if (object.gte != null)
                message.gte = object.gte | 0;
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.SFixed32Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = object["in"][i] | 0;
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.SFixed32Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = object.notIn[i] | 0;
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a SFixed32Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.SFixed32Rules
         * @static
         * @param {validate.SFixed32Rules} message SFixed32Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SFixed32Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = 0;
                object.lt = 0;
                object.lte = 0;
                object.gt = 0;
                object.gte = 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this SFixed32Rules to JSON.
         * @function toJSON
         * @memberof validate.SFixed32Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SFixed32Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SFixed32Rules
         * @function getTypeUrl
         * @memberof validate.SFixed32Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SFixed32Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.SFixed32Rules";
        };

        return SFixed32Rules;
    })();

    validate.SFixed64Rules = (function() {

        /**
         * Properties of a SFixed64Rules.
         * @memberof validate
         * @interface ISFixed64Rules
         * @property {Long|null} ["const"] SFixed64Rules const
         * @property {Long|null} [lt] SFixed64Rules lt
         * @property {Long|null} [lte] SFixed64Rules lte
         * @property {Long|null} [gt] SFixed64Rules gt
         * @property {Long|null} [gte] SFixed64Rules gte
         * @property {Array.<Long>|null} ["in"] SFixed64Rules in
         * @property {Array.<Long>|null} [notIn] SFixed64Rules notIn
         * @property {boolean|null} [ignoreEmpty] SFixed64Rules ignoreEmpty
         */

        /**
         * Constructs a new SFixed64Rules.
         * @memberof validate
         * @classdesc Represents a SFixed64Rules.
         * @implements ISFixed64Rules
         * @constructor
         * @param {validate.ISFixed64Rules=} [properties] Properties to set
         */
        function SFixed64Rules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SFixed64Rules const.
         * @member {Long} const
         * @memberof validate.SFixed64Rules
         * @instance
         */
        SFixed64Rules.prototype["const"] = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SFixed64Rules lt.
         * @member {Long} lt
         * @memberof validate.SFixed64Rules
         * @instance
         */
        SFixed64Rules.prototype.lt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SFixed64Rules lte.
         * @member {Long} lte
         * @memberof validate.SFixed64Rules
         * @instance
         */
        SFixed64Rules.prototype.lte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SFixed64Rules gt.
         * @member {Long} gt
         * @memberof validate.SFixed64Rules
         * @instance
         */
        SFixed64Rules.prototype.gt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SFixed64Rules gte.
         * @member {Long} gte
         * @memberof validate.SFixed64Rules
         * @instance
         */
        SFixed64Rules.prototype.gte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SFixed64Rules in.
         * @member {Array.<Long>} in
         * @memberof validate.SFixed64Rules
         * @instance
         */
        SFixed64Rules.prototype["in"] = $util.emptyArray;

        /**
         * SFixed64Rules notIn.
         * @member {Array.<Long>} notIn
         * @memberof validate.SFixed64Rules
         * @instance
         */
        SFixed64Rules.prototype.notIn = $util.emptyArray;

        /**
         * SFixed64Rules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.SFixed64Rules
         * @instance
         */
        SFixed64Rules.prototype.ignoreEmpty = false;

        /**
         * Creates a new SFixed64Rules instance using the specified properties.
         * @function create
         * @memberof validate.SFixed64Rules
         * @static
         * @param {validate.ISFixed64Rules=} [properties] Properties to set
         * @returns {validate.SFixed64Rules} SFixed64Rules instance
         */
        SFixed64Rules.create = function create(properties) {
            return new SFixed64Rules(properties);
        };

        /**
         * Encodes the specified SFixed64Rules message. Does not implicitly {@link validate.SFixed64Rules.verify|verify} messages.
         * @function encode
         * @memberof validate.SFixed64Rules
         * @static
         * @param {validate.ISFixed64Rules} message SFixed64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SFixed64Rules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 1 =*/9).sfixed64(message["const"]);
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                writer.uint32(/* id 2, wireType 1 =*/17).sfixed64(message.lt);
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                writer.uint32(/* id 3, wireType 1 =*/25).sfixed64(message.lte);
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                writer.uint32(/* id 4, wireType 1 =*/33).sfixed64(message.gt);
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                writer.uint32(/* id 5, wireType 1 =*/41).sfixed64(message.gte);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 6, wireType 1 =*/49).sfixed64(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 7, wireType 1 =*/57).sfixed64(message.notIn[i]);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified SFixed64Rules message, length delimited. Does not implicitly {@link validate.SFixed64Rules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.SFixed64Rules
         * @static
         * @param {validate.ISFixed64Rules} message SFixed64Rules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SFixed64Rules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SFixed64Rules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.SFixed64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.SFixed64Rules} SFixed64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SFixed64Rules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.SFixed64Rules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.sfixed64();
                        break;
                    }
                case 2: {
                        message.lt = reader.sfixed64();
                        break;
                    }
                case 3: {
                        message.lte = reader.sfixed64();
                        break;
                    }
                case 4: {
                        message.gt = reader.sfixed64();
                        break;
                    }
                case 5: {
                        message.gte = reader.sfixed64();
                        break;
                    }
                case 6: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.sfixed64());
                        } else
                            message["in"].push(reader.sfixed64());
                        break;
                    }
                case 7: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.sfixed64());
                        } else
                            message.notIn.push(reader.sfixed64());
                        break;
                    }
                case 8: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SFixed64Rules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.SFixed64Rules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.SFixed64Rules} SFixed64Rules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SFixed64Rules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SFixed64Rules message.
         * @function verify
         * @memberof validate.SFixed64Rules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SFixed64Rules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]) && !(message["const"] && $util.isInteger(message["const"].low) && $util.isInteger(message["const"].high)))
                    return "const: integer|Long expected";
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (!$util.isInteger(message.lt) && !(message.lt && $util.isInteger(message.lt.low) && $util.isInteger(message.lt.high)))
                    return "lt: integer|Long expected";
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (!$util.isInteger(message.lte) && !(message.lte && $util.isInteger(message.lte.low) && $util.isInteger(message.lte.high)))
                    return "lte: integer|Long expected";
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (!$util.isInteger(message.gt) && !(message.gt && $util.isInteger(message.gt.low) && $util.isInteger(message.gt.high)))
                    return "gt: integer|Long expected";
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (!$util.isInteger(message.gte) && !(message.gte && $util.isInteger(message.gte.low) && $util.isInteger(message.gte.high)))
                    return "gte: integer|Long expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]) && !(message["in"][i] && $util.isInteger(message["in"][i].low) && $util.isInteger(message["in"][i].high)))
                        return "in: integer|Long[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]) && !(message.notIn[i] && $util.isInteger(message.notIn[i].low) && $util.isInteger(message.notIn[i].high)))
                        return "notIn: integer|Long[] expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a SFixed64Rules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.SFixed64Rules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.SFixed64Rules} SFixed64Rules
         */
        SFixed64Rules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.SFixed64Rules)
                return object;
            let message = new $root.validate.SFixed64Rules();
            if (object["const"] != null)
                if ($util.Long)
                    (message["const"] = $util.Long.fromValue(object["const"])).unsigned = false;
                else if (typeof object["const"] === "string")
                    message["const"] = parseInt(object["const"], 10);
                else if (typeof object["const"] === "number")
                    message["const"] = object["const"];
                else if (typeof object["const"] === "object")
                    message["const"] = new $util.LongBits(object["const"].low >>> 0, object["const"].high >>> 0).toNumber();
            if (object.lt != null)
                if ($util.Long)
                    (message.lt = $util.Long.fromValue(object.lt)).unsigned = false;
                else if (typeof object.lt === "string")
                    message.lt = parseInt(object.lt, 10);
                else if (typeof object.lt === "number")
                    message.lt = object.lt;
                else if (typeof object.lt === "object")
                    message.lt = new $util.LongBits(object.lt.low >>> 0, object.lt.high >>> 0).toNumber();
            if (object.lte != null)
                if ($util.Long)
                    (message.lte = $util.Long.fromValue(object.lte)).unsigned = false;
                else if (typeof object.lte === "string")
                    message.lte = parseInt(object.lte, 10);
                else if (typeof object.lte === "number")
                    message.lte = object.lte;
                else if (typeof object.lte === "object")
                    message.lte = new $util.LongBits(object.lte.low >>> 0, object.lte.high >>> 0).toNumber();
            if (object.gt != null)
                if ($util.Long)
                    (message.gt = $util.Long.fromValue(object.gt)).unsigned = false;
                else if (typeof object.gt === "string")
                    message.gt = parseInt(object.gt, 10);
                else if (typeof object.gt === "number")
                    message.gt = object.gt;
                else if (typeof object.gt === "object")
                    message.gt = new $util.LongBits(object.gt.low >>> 0, object.gt.high >>> 0).toNumber();
            if (object.gte != null)
                if ($util.Long)
                    (message.gte = $util.Long.fromValue(object.gte)).unsigned = false;
                else if (typeof object.gte === "string")
                    message.gte = parseInt(object.gte, 10);
                else if (typeof object.gte === "number")
                    message.gte = object.gte;
                else if (typeof object.gte === "object")
                    message.gte = new $util.LongBits(object.gte.low >>> 0, object.gte.high >>> 0).toNumber();
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.SFixed64Rules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    if ($util.Long)
                        (message["in"][i] = $util.Long.fromValue(object["in"][i])).unsigned = false;
                    else if (typeof object["in"][i] === "string")
                        message["in"][i] = parseInt(object["in"][i], 10);
                    else if (typeof object["in"][i] === "number")
                        message["in"][i] = object["in"][i];
                    else if (typeof object["in"][i] === "object")
                        message["in"][i] = new $util.LongBits(object["in"][i].low >>> 0, object["in"][i].high >>> 0).toNumber();
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.SFixed64Rules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    if ($util.Long)
                        (message.notIn[i] = $util.Long.fromValue(object.notIn[i])).unsigned = false;
                    else if (typeof object.notIn[i] === "string")
                        message.notIn[i] = parseInt(object.notIn[i], 10);
                    else if (typeof object.notIn[i] === "number")
                        message.notIn[i] = object.notIn[i];
                    else if (typeof object.notIn[i] === "object")
                        message.notIn[i] = new $util.LongBits(object.notIn[i].low >>> 0, object.notIn[i].high >>> 0).toNumber();
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a SFixed64Rules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.SFixed64Rules
         * @static
         * @param {validate.SFixed64Rules} message SFixed64Rules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SFixed64Rules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object["const"] = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object["const"] = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.gt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.gte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.gte = options.longs === String ? "0" : 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (typeof message["const"] === "number")
                    object["const"] = options.longs === String ? String(message["const"]) : message["const"];
                else
                    object["const"] = options.longs === String ? $util.Long.prototype.toString.call(message["const"]) : options.longs === Number ? new $util.LongBits(message["const"].low >>> 0, message["const"].high >>> 0).toNumber() : message["const"];
            if (message.lt != null && message.hasOwnProperty("lt"))
                if (typeof message.lt === "number")
                    object.lt = options.longs === String ? String(message.lt) : message.lt;
                else
                    object.lt = options.longs === String ? $util.Long.prototype.toString.call(message.lt) : options.longs === Number ? new $util.LongBits(message.lt.low >>> 0, message.lt.high >>> 0).toNumber() : message.lt;
            if (message.lte != null && message.hasOwnProperty("lte"))
                if (typeof message.lte === "number")
                    object.lte = options.longs === String ? String(message.lte) : message.lte;
                else
                    object.lte = options.longs === String ? $util.Long.prototype.toString.call(message.lte) : options.longs === Number ? new $util.LongBits(message.lte.low >>> 0, message.lte.high >>> 0).toNumber() : message.lte;
            if (message.gt != null && message.hasOwnProperty("gt"))
                if (typeof message.gt === "number")
                    object.gt = options.longs === String ? String(message.gt) : message.gt;
                else
                    object.gt = options.longs === String ? $util.Long.prototype.toString.call(message.gt) : options.longs === Number ? new $util.LongBits(message.gt.low >>> 0, message.gt.high >>> 0).toNumber() : message.gt;
            if (message.gte != null && message.hasOwnProperty("gte"))
                if (typeof message.gte === "number")
                    object.gte = options.longs === String ? String(message.gte) : message.gte;
                else
                    object.gte = options.longs === String ? $util.Long.prototype.toString.call(message.gte) : options.longs === Number ? new $util.LongBits(message.gte.low >>> 0, message.gte.high >>> 0).toNumber() : message.gte;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    if (typeof message["in"][j] === "number")
                        object["in"][j] = options.longs === String ? String(message["in"][j]) : message["in"][j];
                    else
                        object["in"][j] = options.longs === String ? $util.Long.prototype.toString.call(message["in"][j]) : options.longs === Number ? new $util.LongBits(message["in"][j].low >>> 0, message["in"][j].high >>> 0).toNumber() : message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    if (typeof message.notIn[j] === "number")
                        object.notIn[j] = options.longs === String ? String(message.notIn[j]) : message.notIn[j];
                    else
                        object.notIn[j] = options.longs === String ? $util.Long.prototype.toString.call(message.notIn[j]) : options.longs === Number ? new $util.LongBits(message.notIn[j].low >>> 0, message.notIn[j].high >>> 0).toNumber() : message.notIn[j];
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this SFixed64Rules to JSON.
         * @function toJSON
         * @memberof validate.SFixed64Rules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SFixed64Rules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SFixed64Rules
         * @function getTypeUrl
         * @memberof validate.SFixed64Rules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SFixed64Rules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.SFixed64Rules";
        };

        return SFixed64Rules;
    })();

    validate.BoolRules = (function() {

        /**
         * Properties of a BoolRules.
         * @memberof validate
         * @interface IBoolRules
         * @property {boolean|null} ["const"] BoolRules const
         */

        /**
         * Constructs a new BoolRules.
         * @memberof validate
         * @classdesc Represents a BoolRules.
         * @implements IBoolRules
         * @constructor
         * @param {validate.IBoolRules=} [properties] Properties to set
         */
        function BoolRules(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BoolRules const.
         * @member {boolean} const
         * @memberof validate.BoolRules
         * @instance
         */
        BoolRules.prototype["const"] = false;

        /**
         * Creates a new BoolRules instance using the specified properties.
         * @function create
         * @memberof validate.BoolRules
         * @static
         * @param {validate.IBoolRules=} [properties] Properties to set
         * @returns {validate.BoolRules} BoolRules instance
         */
        BoolRules.create = function create(properties) {
            return new BoolRules(properties);
        };

        /**
         * Encodes the specified BoolRules message. Does not implicitly {@link validate.BoolRules.verify|verify} messages.
         * @function encode
         * @memberof validate.BoolRules
         * @static
         * @param {validate.IBoolRules} message BoolRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoolRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message["const"]);
            return writer;
        };

        /**
         * Encodes the specified BoolRules message, length delimited. Does not implicitly {@link validate.BoolRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.BoolRules
         * @static
         * @param {validate.IBoolRules} message BoolRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoolRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BoolRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.BoolRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.BoolRules} BoolRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoolRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.BoolRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BoolRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.BoolRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.BoolRules} BoolRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoolRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BoolRules message.
         * @function verify
         * @memberof validate.BoolRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BoolRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (typeof message["const"] !== "boolean")
                    return "const: boolean expected";
            return null;
        };

        /**
         * Creates a BoolRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.BoolRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.BoolRules} BoolRules
         */
        BoolRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.BoolRules)
                return object;
            let message = new $root.validate.BoolRules();
            if (object["const"] != null)
                message["const"] = Boolean(object["const"]);
            return message;
        };

        /**
         * Creates a plain object from a BoolRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.BoolRules
         * @static
         * @param {validate.BoolRules} message BoolRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoolRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object["const"] = false;
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = message["const"];
            return object;
        };

        /**
         * Converts this BoolRules to JSON.
         * @function toJSON
         * @memberof validate.BoolRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoolRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BoolRules
         * @function getTypeUrl
         * @memberof validate.BoolRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BoolRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.BoolRules";
        };

        return BoolRules;
    })();

    validate.StringRules = (function() {

        /**
         * Properties of a StringRules.
         * @memberof validate
         * @interface IStringRules
         * @property {string|null} ["const"] StringRules const
         * @property {Long|null} [len] StringRules len
         * @property {Long|null} [minLen] StringRules minLen
         * @property {Long|null} [maxLen] StringRules maxLen
         * @property {Long|null} [lenBytes] StringRules lenBytes
         * @property {Long|null} [minBytes] StringRules minBytes
         * @property {Long|null} [maxBytes] StringRules maxBytes
         * @property {string|null} [pattern] StringRules pattern
         * @property {string|null} [prefix] StringRules prefix
         * @property {string|null} [suffix] StringRules suffix
         * @property {string|null} [contains] StringRules contains
         * @property {string|null} [notContains] StringRules notContains
         * @property {Array.<string>|null} ["in"] StringRules in
         * @property {Array.<string>|null} [notIn] StringRules notIn
         * @property {boolean|null} [email] StringRules email
         * @property {boolean|null} [hostname] StringRules hostname
         * @property {boolean|null} [ip] StringRules ip
         * @property {boolean|null} [ipv4] StringRules ipv4
         * @property {boolean|null} [ipv6] StringRules ipv6
         * @property {boolean|null} [uri] StringRules uri
         * @property {boolean|null} [uriRef] StringRules uriRef
         * @property {boolean|null} [address] StringRules address
         * @property {boolean|null} [uuid] StringRules uuid
         * @property {validate.KnownRegex|null} [wellKnownRegex] StringRules wellKnownRegex
         * @property {boolean|null} [strict] StringRules strict
         * @property {boolean|null} [ignoreEmpty] StringRules ignoreEmpty
         */

        /**
         * Constructs a new StringRules.
         * @memberof validate
         * @classdesc Represents a StringRules.
         * @implements IStringRules
         * @constructor
         * @param {validate.IStringRules=} [properties] Properties to set
         */
        function StringRules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringRules const.
         * @member {string} const
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype["const"] = "";

        /**
         * StringRules len.
         * @member {Long} len
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.len = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * StringRules minLen.
         * @member {Long} minLen
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.minLen = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * StringRules maxLen.
         * @member {Long} maxLen
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.maxLen = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * StringRules lenBytes.
         * @member {Long} lenBytes
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.lenBytes = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * StringRules minBytes.
         * @member {Long} minBytes
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.minBytes = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * StringRules maxBytes.
         * @member {Long} maxBytes
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.maxBytes = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * StringRules pattern.
         * @member {string} pattern
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.pattern = "";

        /**
         * StringRules prefix.
         * @member {string} prefix
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.prefix = "";

        /**
         * StringRules suffix.
         * @member {string} suffix
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.suffix = "";

        /**
         * StringRules contains.
         * @member {string} contains
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.contains = "";

        /**
         * StringRules notContains.
         * @member {string} notContains
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.notContains = "";

        /**
         * StringRules in.
         * @member {Array.<string>} in
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype["in"] = $util.emptyArray;

        /**
         * StringRules notIn.
         * @member {Array.<string>} notIn
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.notIn = $util.emptyArray;

        /**
         * StringRules email.
         * @member {boolean|null|undefined} email
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.email = null;

        /**
         * StringRules hostname.
         * @member {boolean|null|undefined} hostname
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.hostname = null;

        /**
         * StringRules ip.
         * @member {boolean|null|undefined} ip
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.ip = null;

        /**
         * StringRules ipv4.
         * @member {boolean|null|undefined} ipv4
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.ipv4 = null;

        /**
         * StringRules ipv6.
         * @member {boolean|null|undefined} ipv6
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.ipv6 = null;

        /**
         * StringRules uri.
         * @member {boolean|null|undefined} uri
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.uri = null;

        /**
         * StringRules uriRef.
         * @member {boolean|null|undefined} uriRef
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.uriRef = null;

        /**
         * StringRules address.
         * @member {boolean|null|undefined} address
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.address = null;

        /**
         * StringRules uuid.
         * @member {boolean|null|undefined} uuid
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.uuid = null;

        /**
         * StringRules wellKnownRegex.
         * @member {validate.KnownRegex|null|undefined} wellKnownRegex
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.wellKnownRegex = null;

        /**
         * StringRules strict.
         * @member {boolean} strict
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.strict = true;

        /**
         * StringRules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.StringRules
         * @instance
         */
        StringRules.prototype.ignoreEmpty = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * StringRules wellKnown.
         * @member {"email"|"hostname"|"ip"|"ipv4"|"ipv6"|"uri"|"uriRef"|"address"|"uuid"|"wellKnownRegex"|undefined} wellKnown
         * @memberof validate.StringRules
         * @instance
         */
        Object.defineProperty(StringRules.prototype, "wellKnown", {
            get: $util.oneOfGetter($oneOfFields = ["email", "hostname", "ip", "ipv4", "ipv6", "uri", "uriRef", "address", "uuid", "wellKnownRegex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new StringRules instance using the specified properties.
         * @function create
         * @memberof validate.StringRules
         * @static
         * @param {validate.IStringRules=} [properties] Properties to set
         * @returns {validate.StringRules} StringRules instance
         */
        StringRules.create = function create(properties) {
            return new StringRules(properties);
        };

        /**
         * Encodes the specified StringRules message. Does not implicitly {@link validate.StringRules.verify|verify} messages.
         * @function encode
         * @memberof validate.StringRules
         * @static
         * @param {validate.IStringRules} message StringRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message["const"]);
            if (message.minLen != null && Object.hasOwnProperty.call(message, "minLen"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.minLen);
            if (message.maxLen != null && Object.hasOwnProperty.call(message, "maxLen"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.maxLen);
            if (message.minBytes != null && Object.hasOwnProperty.call(message, "minBytes"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.minBytes);
            if (message.maxBytes != null && Object.hasOwnProperty.call(message, "maxBytes"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.maxBytes);
            if (message.pattern != null && Object.hasOwnProperty.call(message, "pattern"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.pattern);
            if (message.prefix != null && Object.hasOwnProperty.call(message, "prefix"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.prefix);
            if (message.suffix != null && Object.hasOwnProperty.call(message, "suffix"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.suffix);
            if (message.contains != null && Object.hasOwnProperty.call(message, "contains"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.contains);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.notIn[i]);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.email);
            if (message.hostname != null && Object.hasOwnProperty.call(message, "hostname"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.hostname);
            if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.ip);
            if (message.ipv4 != null && Object.hasOwnProperty.call(message, "ipv4"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.ipv4);
            if (message.ipv6 != null && Object.hasOwnProperty.call(message, "ipv6"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ipv6);
            if (message.uri != null && Object.hasOwnProperty.call(message, "uri"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.uri);
            if (message.uriRef != null && Object.hasOwnProperty.call(message, "uriRef"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.uriRef);
            if (message.len != null && Object.hasOwnProperty.call(message, "len"))
                writer.uint32(/* id 19, wireType 0 =*/152).uint64(message.len);
            if (message.lenBytes != null && Object.hasOwnProperty.call(message, "lenBytes"))
                writer.uint32(/* id 20, wireType 0 =*/160).uint64(message.lenBytes);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 21, wireType 0 =*/168).bool(message.address);
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 22, wireType 0 =*/176).bool(message.uuid);
            if (message.notContains != null && Object.hasOwnProperty.call(message, "notContains"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.notContains);
            if (message.wellKnownRegex != null && Object.hasOwnProperty.call(message, "wellKnownRegex"))
                writer.uint32(/* id 24, wireType 0 =*/192).int32(message.wellKnownRegex);
            if (message.strict != null && Object.hasOwnProperty.call(message, "strict"))
                writer.uint32(/* id 25, wireType 0 =*/200).bool(message.strict);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 26, wireType 0 =*/208).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified StringRules message, length delimited. Does not implicitly {@link validate.StringRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.StringRules
         * @static
         * @param {validate.IStringRules} message StringRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StringRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.StringRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.StringRules} StringRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.StringRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.string();
                        break;
                    }
                case 19: {
                        message.len = reader.uint64();
                        break;
                    }
                case 2: {
                        message.minLen = reader.uint64();
                        break;
                    }
                case 3: {
                        message.maxLen = reader.uint64();
                        break;
                    }
                case 20: {
                        message.lenBytes = reader.uint64();
                        break;
                    }
                case 4: {
                        message.minBytes = reader.uint64();
                        break;
                    }
                case 5: {
                        message.maxBytes = reader.uint64();
                        break;
                    }
                case 6: {
                        message.pattern = reader.string();
                        break;
                    }
                case 7: {
                        message.prefix = reader.string();
                        break;
                    }
                case 8: {
                        message.suffix = reader.string();
                        break;
                    }
                case 9: {
                        message.contains = reader.string();
                        break;
                    }
                case 23: {
                        message.notContains = reader.string();
                        break;
                    }
                case 10: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        message["in"].push(reader.string());
                        break;
                    }
                case 11: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        message.notIn.push(reader.string());
                        break;
                    }
                case 12: {
                        message.email = reader.bool();
                        break;
                    }
                case 13: {
                        message.hostname = reader.bool();
                        break;
                    }
                case 14: {
                        message.ip = reader.bool();
                        break;
                    }
                case 15: {
                        message.ipv4 = reader.bool();
                        break;
                    }
                case 16: {
                        message.ipv6 = reader.bool();
                        break;
                    }
                case 17: {
                        message.uri = reader.bool();
                        break;
                    }
                case 18: {
                        message.uriRef = reader.bool();
                        break;
                    }
                case 21: {
                        message.address = reader.bool();
                        break;
                    }
                case 22: {
                        message.uuid = reader.bool();
                        break;
                    }
                case 24: {
                        message.wellKnownRegex = reader.int32();
                        break;
                    }
                case 25: {
                        message.strict = reader.bool();
                        break;
                    }
                case 26: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StringRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.StringRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.StringRules} StringRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StringRules message.
         * @function verify
         * @memberof validate.StringRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StringRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isString(message["const"]))
                    return "const: string expected";
            if (message.len != null && message.hasOwnProperty("len"))
                if (!$util.isInteger(message.len) && !(message.len && $util.isInteger(message.len.low) && $util.isInteger(message.len.high)))
                    return "len: integer|Long expected";
            if (message.minLen != null && message.hasOwnProperty("minLen"))
                if (!$util.isInteger(message.minLen) && !(message.minLen && $util.isInteger(message.minLen.low) && $util.isInteger(message.minLen.high)))
                    return "minLen: integer|Long expected";
            if (message.maxLen != null && message.hasOwnProperty("maxLen"))
                if (!$util.isInteger(message.maxLen) && !(message.maxLen && $util.isInteger(message.maxLen.low) && $util.isInteger(message.maxLen.high)))
                    return "maxLen: integer|Long expected";
            if (message.lenBytes != null && message.hasOwnProperty("lenBytes"))
                if (!$util.isInteger(message.lenBytes) && !(message.lenBytes && $util.isInteger(message.lenBytes.low) && $util.isInteger(message.lenBytes.high)))
                    return "lenBytes: integer|Long expected";
            if (message.minBytes != null && message.hasOwnProperty("minBytes"))
                if (!$util.isInteger(message.minBytes) && !(message.minBytes && $util.isInteger(message.minBytes.low) && $util.isInteger(message.minBytes.high)))
                    return "minBytes: integer|Long expected";
            if (message.maxBytes != null && message.hasOwnProperty("maxBytes"))
                if (!$util.isInteger(message.maxBytes) && !(message.maxBytes && $util.isInteger(message.maxBytes.low) && $util.isInteger(message.maxBytes.high)))
                    return "maxBytes: integer|Long expected";
            if (message.pattern != null && message.hasOwnProperty("pattern"))
                if (!$util.isString(message.pattern))
                    return "pattern: string expected";
            if (message.prefix != null && message.hasOwnProperty("prefix"))
                if (!$util.isString(message.prefix))
                    return "prefix: string expected";
            if (message.suffix != null && message.hasOwnProperty("suffix"))
                if (!$util.isString(message.suffix))
                    return "suffix: string expected";
            if (message.contains != null && message.hasOwnProperty("contains"))
                if (!$util.isString(message.contains))
                    return "contains: string expected";
            if (message.notContains != null && message.hasOwnProperty("notContains"))
                if (!$util.isString(message.notContains))
                    return "notContains: string expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isString(message["in"][i]))
                        return "in: string[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isString(message.notIn[i]))
                        return "notIn: string[] expected";
            }
            if (message.email != null && message.hasOwnProperty("email")) {
                properties.wellKnown = 1;
                if (typeof message.email !== "boolean")
                    return "email: boolean expected";
            }
            if (message.hostname != null && message.hasOwnProperty("hostname")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.hostname !== "boolean")
                    return "hostname: boolean expected";
            }
            if (message.ip != null && message.hasOwnProperty("ip")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.ip !== "boolean")
                    return "ip: boolean expected";
            }
            if (message.ipv4 != null && message.hasOwnProperty("ipv4")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.ipv4 !== "boolean")
                    return "ipv4: boolean expected";
            }
            if (message.ipv6 != null && message.hasOwnProperty("ipv6")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.ipv6 !== "boolean")
                    return "ipv6: boolean expected";
            }
            if (message.uri != null && message.hasOwnProperty("uri")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.uri !== "boolean")
                    return "uri: boolean expected";
            }
            if (message.uriRef != null && message.hasOwnProperty("uriRef")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.uriRef !== "boolean")
                    return "uriRef: boolean expected";
            }
            if (message.address != null && message.hasOwnProperty("address")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.address !== "boolean")
                    return "address: boolean expected";
            }
            if (message.uuid != null && message.hasOwnProperty("uuid")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.uuid !== "boolean")
                    return "uuid: boolean expected";
            }
            if (message.wellKnownRegex != null && message.hasOwnProperty("wellKnownRegex")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                switch (message.wellKnownRegex) {
                default:
                    return "wellKnownRegex: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            }
            if (message.strict != null && message.hasOwnProperty("strict"))
                if (typeof message.strict !== "boolean")
                    return "strict: boolean expected";
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a StringRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.StringRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.StringRules} StringRules
         */
        StringRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.StringRules)
                return object;
            let message = new $root.validate.StringRules();
            if (object["const"] != null)
                message["const"] = String(object["const"]);
            if (object.len != null)
                if ($util.Long)
                    (message.len = $util.Long.fromValue(object.len)).unsigned = true;
                else if (typeof object.len === "string")
                    message.len = parseInt(object.len, 10);
                else if (typeof object.len === "number")
                    message.len = object.len;
                else if (typeof object.len === "object")
                    message.len = new $util.LongBits(object.len.low >>> 0, object.len.high >>> 0).toNumber(true);
            if (object.minLen != null)
                if ($util.Long)
                    (message.minLen = $util.Long.fromValue(object.minLen)).unsigned = true;
                else if (typeof object.minLen === "string")
                    message.minLen = parseInt(object.minLen, 10);
                else if (typeof object.minLen === "number")
                    message.minLen = object.minLen;
                else if (typeof object.minLen === "object")
                    message.minLen = new $util.LongBits(object.minLen.low >>> 0, object.minLen.high >>> 0).toNumber(true);
            if (object.maxLen != null)
                if ($util.Long)
                    (message.maxLen = $util.Long.fromValue(object.maxLen)).unsigned = true;
                else if (typeof object.maxLen === "string")
                    message.maxLen = parseInt(object.maxLen, 10);
                else if (typeof object.maxLen === "number")
                    message.maxLen = object.maxLen;
                else if (typeof object.maxLen === "object")
                    message.maxLen = new $util.LongBits(object.maxLen.low >>> 0, object.maxLen.high >>> 0).toNumber(true);
            if (object.lenBytes != null)
                if ($util.Long)
                    (message.lenBytes = $util.Long.fromValue(object.lenBytes)).unsigned = true;
                else if (typeof object.lenBytes === "string")
                    message.lenBytes = parseInt(object.lenBytes, 10);
                else if (typeof object.lenBytes === "number")
                    message.lenBytes = object.lenBytes;
                else if (typeof object.lenBytes === "object")
                    message.lenBytes = new $util.LongBits(object.lenBytes.low >>> 0, object.lenBytes.high >>> 0).toNumber(true);
            if (object.minBytes != null)
                if ($util.Long)
                    (message.minBytes = $util.Long.fromValue(object.minBytes)).unsigned = true;
                else if (typeof object.minBytes === "string")
                    message.minBytes = parseInt(object.minBytes, 10);
                else if (typeof object.minBytes === "number")
                    message.minBytes = object.minBytes;
                else if (typeof object.minBytes === "object")
                    message.minBytes = new $util.LongBits(object.minBytes.low >>> 0, object.minBytes.high >>> 0).toNumber(true);
            if (object.maxBytes != null)
                if ($util.Long)
                    (message.maxBytes = $util.Long.fromValue(object.maxBytes)).unsigned = true;
                else if (typeof object.maxBytes === "string")
                    message.maxBytes = parseInt(object.maxBytes, 10);
                else if (typeof object.maxBytes === "number")
                    message.maxBytes = object.maxBytes;
                else if (typeof object.maxBytes === "object")
                    message.maxBytes = new $util.LongBits(object.maxBytes.low >>> 0, object.maxBytes.high >>> 0).toNumber(true);
            if (object.pattern != null)
                message.pattern = String(object.pattern);
            if (object.prefix != null)
                message.prefix = String(object.prefix);
            if (object.suffix != null)
                message.suffix = String(object.suffix);
            if (object.contains != null)
                message.contains = String(object.contains);
            if (object.notContains != null)
                message.notContains = String(object.notContains);
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.StringRules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = String(object["in"][i]);
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.StringRules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = String(object.notIn[i]);
            }
            if (object.email != null)
                message.email = Boolean(object.email);
            if (object.hostname != null)
                message.hostname = Boolean(object.hostname);
            if (object.ip != null)
                message.ip = Boolean(object.ip);
            if (object.ipv4 != null)
                message.ipv4 = Boolean(object.ipv4);
            if (object.ipv6 != null)
                message.ipv6 = Boolean(object.ipv6);
            if (object.uri != null)
                message.uri = Boolean(object.uri);
            if (object.uriRef != null)
                message.uriRef = Boolean(object.uriRef);
            if (object.address != null)
                message.address = Boolean(object.address);
            if (object.uuid != null)
                message.uuid = Boolean(object.uuid);
            switch (object.wellKnownRegex) {
            default:
                if (typeof object.wellKnownRegex === "number") {
                    message.wellKnownRegex = object.wellKnownRegex;
                    break;
                }
                break;
            case "UNKNOWN":
            case 0:
                message.wellKnownRegex = 0;
                break;
            case "HTTP_HEADER_NAME":
            case 1:
                message.wellKnownRegex = 1;
                break;
            case "HTTP_HEADER_VALUE":
            case 2:
                message.wellKnownRegex = 2;
                break;
            }
            if (object.strict != null)
                message.strict = Boolean(object.strict);
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a StringRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.StringRules
         * @static
         * @param {validate.StringRules} message StringRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StringRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minLen = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minLen = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxLen = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxLen = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minBytes = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxBytes = options.longs === String ? "0" : 0;
                object.pattern = "";
                object.prefix = "";
                object.suffix = "";
                object.contains = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.len = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.len = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.lenBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lenBytes = options.longs === String ? "0" : 0;
                object.notContains = "";
                object.strict = true;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = message["const"];
            if (message.minLen != null && message.hasOwnProperty("minLen"))
                if (typeof message.minLen === "number")
                    object.minLen = options.longs === String ? String(message.minLen) : message.minLen;
                else
                    object.minLen = options.longs === String ? $util.Long.prototype.toString.call(message.minLen) : options.longs === Number ? new $util.LongBits(message.minLen.low >>> 0, message.minLen.high >>> 0).toNumber(true) : message.minLen;
            if (message.maxLen != null && message.hasOwnProperty("maxLen"))
                if (typeof message.maxLen === "number")
                    object.maxLen = options.longs === String ? String(message.maxLen) : message.maxLen;
                else
                    object.maxLen = options.longs === String ? $util.Long.prototype.toString.call(message.maxLen) : options.longs === Number ? new $util.LongBits(message.maxLen.low >>> 0, message.maxLen.high >>> 0).toNumber(true) : message.maxLen;
            if (message.minBytes != null && message.hasOwnProperty("minBytes"))
                if (typeof message.minBytes === "number")
                    object.minBytes = options.longs === String ? String(message.minBytes) : message.minBytes;
                else
                    object.minBytes = options.longs === String ? $util.Long.prototype.toString.call(message.minBytes) : options.longs === Number ? new $util.LongBits(message.minBytes.low >>> 0, message.minBytes.high >>> 0).toNumber(true) : message.minBytes;
            if (message.maxBytes != null && message.hasOwnProperty("maxBytes"))
                if (typeof message.maxBytes === "number")
                    object.maxBytes = options.longs === String ? String(message.maxBytes) : message.maxBytes;
                else
                    object.maxBytes = options.longs === String ? $util.Long.prototype.toString.call(message.maxBytes) : options.longs === Number ? new $util.LongBits(message.maxBytes.low >>> 0, message.maxBytes.high >>> 0).toNumber(true) : message.maxBytes;
            if (message.pattern != null && message.hasOwnProperty("pattern"))
                object.pattern = message.pattern;
            if (message.prefix != null && message.hasOwnProperty("prefix"))
                object.prefix = message.prefix;
            if (message.suffix != null && message.hasOwnProperty("suffix"))
                object.suffix = message.suffix;
            if (message.contains != null && message.hasOwnProperty("contains"))
                object.contains = message.contains;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = message.notIn[j];
            }
            if (message.email != null && message.hasOwnProperty("email")) {
                object.email = message.email;
                if (options.oneofs)
                    object.wellKnown = "email";
            }
            if (message.hostname != null && message.hasOwnProperty("hostname")) {
                object.hostname = message.hostname;
                if (options.oneofs)
                    object.wellKnown = "hostname";
            }
            if (message.ip != null && message.hasOwnProperty("ip")) {
                object.ip = message.ip;
                if (options.oneofs)
                    object.wellKnown = "ip";
            }
            if (message.ipv4 != null && message.hasOwnProperty("ipv4")) {
                object.ipv4 = message.ipv4;
                if (options.oneofs)
                    object.wellKnown = "ipv4";
            }
            if (message.ipv6 != null && message.hasOwnProperty("ipv6")) {
                object.ipv6 = message.ipv6;
                if (options.oneofs)
                    object.wellKnown = "ipv6";
            }
            if (message.uri != null && message.hasOwnProperty("uri")) {
                object.uri = message.uri;
                if (options.oneofs)
                    object.wellKnown = "uri";
            }
            if (message.uriRef != null && message.hasOwnProperty("uriRef")) {
                object.uriRef = message.uriRef;
                if (options.oneofs)
                    object.wellKnown = "uriRef";
            }
            if (message.len != null && message.hasOwnProperty("len"))
                if (typeof message.len === "number")
                    object.len = options.longs === String ? String(message.len) : message.len;
                else
                    object.len = options.longs === String ? $util.Long.prototype.toString.call(message.len) : options.longs === Number ? new $util.LongBits(message.len.low >>> 0, message.len.high >>> 0).toNumber(true) : message.len;
            if (message.lenBytes != null && message.hasOwnProperty("lenBytes"))
                if (typeof message.lenBytes === "number")
                    object.lenBytes = options.longs === String ? String(message.lenBytes) : message.lenBytes;
                else
                    object.lenBytes = options.longs === String ? $util.Long.prototype.toString.call(message.lenBytes) : options.longs === Number ? new $util.LongBits(message.lenBytes.low >>> 0, message.lenBytes.high >>> 0).toNumber(true) : message.lenBytes;
            if (message.address != null && message.hasOwnProperty("address")) {
                object.address = message.address;
                if (options.oneofs)
                    object.wellKnown = "address";
            }
            if (message.uuid != null && message.hasOwnProperty("uuid")) {
                object.uuid = message.uuid;
                if (options.oneofs)
                    object.wellKnown = "uuid";
            }
            if (message.notContains != null && message.hasOwnProperty("notContains"))
                object.notContains = message.notContains;
            if (message.wellKnownRegex != null && message.hasOwnProperty("wellKnownRegex")) {
                object.wellKnownRegex = options.enums === String ? $root.validate.KnownRegex[message.wellKnownRegex] === undefined ? message.wellKnownRegex : $root.validate.KnownRegex[message.wellKnownRegex] : message.wellKnownRegex;
                if (options.oneofs)
                    object.wellKnown = "wellKnownRegex";
            }
            if (message.strict != null && message.hasOwnProperty("strict"))
                object.strict = message.strict;
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this StringRules to JSON.
         * @function toJSON
         * @memberof validate.StringRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StringRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StringRules
         * @function getTypeUrl
         * @memberof validate.StringRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StringRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.StringRules";
        };

        return StringRules;
    })();

    /**
     * KnownRegex enum.
     * @name validate.KnownRegex
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} HTTP_HEADER_NAME=1 HTTP_HEADER_NAME value
     * @property {number} HTTP_HEADER_VALUE=2 HTTP_HEADER_VALUE value
     */
    validate.KnownRegex = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "HTTP_HEADER_NAME"] = 1;
        values[valuesById[2] = "HTTP_HEADER_VALUE"] = 2;
        return values;
    })();

    validate.BytesRules = (function() {

        /**
         * Properties of a BytesRules.
         * @memberof validate
         * @interface IBytesRules
         * @property {Uint8Array|null} ["const"] BytesRules const
         * @property {Long|null} [len] BytesRules len
         * @property {Long|null} [minLen] BytesRules minLen
         * @property {Long|null} [maxLen] BytesRules maxLen
         * @property {string|null} [pattern] BytesRules pattern
         * @property {Uint8Array|null} [prefix] BytesRules prefix
         * @property {Uint8Array|null} [suffix] BytesRules suffix
         * @property {Uint8Array|null} [contains] BytesRules contains
         * @property {Array.<Uint8Array>|null} ["in"] BytesRules in
         * @property {Array.<Uint8Array>|null} [notIn] BytesRules notIn
         * @property {boolean|null} [ip] BytesRules ip
         * @property {boolean|null} [ipv4] BytesRules ipv4
         * @property {boolean|null} [ipv6] BytesRules ipv6
         * @property {boolean|null} [ignoreEmpty] BytesRules ignoreEmpty
         */

        /**
         * Constructs a new BytesRules.
         * @memberof validate
         * @classdesc Represents a BytesRules.
         * @implements IBytesRules
         * @constructor
         * @param {validate.IBytesRules=} [properties] Properties to set
         */
        function BytesRules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BytesRules const.
         * @member {Uint8Array} const
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype["const"] = $util.newBuffer([]);

        /**
         * BytesRules len.
         * @member {Long} len
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.len = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BytesRules minLen.
         * @member {Long} minLen
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.minLen = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BytesRules maxLen.
         * @member {Long} maxLen
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.maxLen = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BytesRules pattern.
         * @member {string} pattern
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.pattern = "";

        /**
         * BytesRules prefix.
         * @member {Uint8Array} prefix
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.prefix = $util.newBuffer([]);

        /**
         * BytesRules suffix.
         * @member {Uint8Array} suffix
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.suffix = $util.newBuffer([]);

        /**
         * BytesRules contains.
         * @member {Uint8Array} contains
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.contains = $util.newBuffer([]);

        /**
         * BytesRules in.
         * @member {Array.<Uint8Array>} in
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype["in"] = $util.emptyArray;

        /**
         * BytesRules notIn.
         * @member {Array.<Uint8Array>} notIn
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.notIn = $util.emptyArray;

        /**
         * BytesRules ip.
         * @member {boolean|null|undefined} ip
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.ip = null;

        /**
         * BytesRules ipv4.
         * @member {boolean|null|undefined} ipv4
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.ipv4 = null;

        /**
         * BytesRules ipv6.
         * @member {boolean|null|undefined} ipv6
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.ipv6 = null;

        /**
         * BytesRules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.BytesRules
         * @instance
         */
        BytesRules.prototype.ignoreEmpty = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * BytesRules wellKnown.
         * @member {"ip"|"ipv4"|"ipv6"|undefined} wellKnown
         * @memberof validate.BytesRules
         * @instance
         */
        Object.defineProperty(BytesRules.prototype, "wellKnown", {
            get: $util.oneOfGetter($oneOfFields = ["ip", "ipv4", "ipv6"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new BytesRules instance using the specified properties.
         * @function create
         * @memberof validate.BytesRules
         * @static
         * @param {validate.IBytesRules=} [properties] Properties to set
         * @returns {validate.BytesRules} BytesRules instance
         */
        BytesRules.create = function create(properties) {
            return new BytesRules(properties);
        };

        /**
         * Encodes the specified BytesRules message. Does not implicitly {@link validate.BytesRules.verify|verify} messages.
         * @function encode
         * @memberof validate.BytesRules
         * @static
         * @param {validate.IBytesRules} message BytesRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BytesRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message["const"]);
            if (message.minLen != null && Object.hasOwnProperty.call(message, "minLen"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.minLen);
            if (message.maxLen != null && Object.hasOwnProperty.call(message, "maxLen"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.maxLen);
            if (message.pattern != null && Object.hasOwnProperty.call(message, "pattern"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.pattern);
            if (message.prefix != null && Object.hasOwnProperty.call(message, "prefix"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.prefix);
            if (message.suffix != null && Object.hasOwnProperty.call(message, "suffix"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.suffix);
            if (message.contains != null && Object.hasOwnProperty.call(message, "contains"))
                writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.contains);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).bytes(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.notIn[i]);
            if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.ip);
            if (message.ipv4 != null && Object.hasOwnProperty.call(message, "ipv4"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.ipv4);
            if (message.ipv6 != null && Object.hasOwnProperty.call(message, "ipv6"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.ipv6);
            if (message.len != null && Object.hasOwnProperty.call(message, "len"))
                writer.uint32(/* id 13, wireType 0 =*/104).uint64(message.len);
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified BytesRules message, length delimited. Does not implicitly {@link validate.BytesRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.BytesRules
         * @static
         * @param {validate.IBytesRules} message BytesRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BytesRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BytesRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.BytesRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.BytesRules} BytesRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BytesRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.BytesRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.bytes();
                        break;
                    }
                case 13: {
                        message.len = reader.uint64();
                        break;
                    }
                case 2: {
                        message.minLen = reader.uint64();
                        break;
                    }
                case 3: {
                        message.maxLen = reader.uint64();
                        break;
                    }
                case 4: {
                        message.pattern = reader.string();
                        break;
                    }
                case 5: {
                        message.prefix = reader.bytes();
                        break;
                    }
                case 6: {
                        message.suffix = reader.bytes();
                        break;
                    }
                case 7: {
                        message.contains = reader.bytes();
                        break;
                    }
                case 8: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        message["in"].push(reader.bytes());
                        break;
                    }
                case 9: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        message.notIn.push(reader.bytes());
                        break;
                    }
                case 10: {
                        message.ip = reader.bool();
                        break;
                    }
                case 11: {
                        message.ipv4 = reader.bool();
                        break;
                    }
                case 12: {
                        message.ipv6 = reader.bool();
                        break;
                    }
                case 14: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BytesRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.BytesRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.BytesRules} BytesRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BytesRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BytesRules message.
         * @function verify
         * @memberof validate.BytesRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BytesRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!(message["const"] && typeof message["const"].length === "number" || $util.isString(message["const"])))
                    return "const: buffer expected";
            if (message.len != null && message.hasOwnProperty("len"))
                if (!$util.isInteger(message.len) && !(message.len && $util.isInteger(message.len.low) && $util.isInteger(message.len.high)))
                    return "len: integer|Long expected";
            if (message.minLen != null && message.hasOwnProperty("minLen"))
                if (!$util.isInteger(message.minLen) && !(message.minLen && $util.isInteger(message.minLen.low) && $util.isInteger(message.minLen.high)))
                    return "minLen: integer|Long expected";
            if (message.maxLen != null && message.hasOwnProperty("maxLen"))
                if (!$util.isInteger(message.maxLen) && !(message.maxLen && $util.isInteger(message.maxLen.low) && $util.isInteger(message.maxLen.high)))
                    return "maxLen: integer|Long expected";
            if (message.pattern != null && message.hasOwnProperty("pattern"))
                if (!$util.isString(message.pattern))
                    return "pattern: string expected";
            if (message.prefix != null && message.hasOwnProperty("prefix"))
                if (!(message.prefix && typeof message.prefix.length === "number" || $util.isString(message.prefix)))
                    return "prefix: buffer expected";
            if (message.suffix != null && message.hasOwnProperty("suffix"))
                if (!(message.suffix && typeof message.suffix.length === "number" || $util.isString(message.suffix)))
                    return "suffix: buffer expected";
            if (message.contains != null && message.hasOwnProperty("contains"))
                if (!(message.contains && typeof message.contains.length === "number" || $util.isString(message.contains)))
                    return "contains: buffer expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!(message["in"][i] && typeof message["in"][i].length === "number" || $util.isString(message["in"][i])))
                        return "in: buffer[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!(message.notIn[i] && typeof message.notIn[i].length === "number" || $util.isString(message.notIn[i])))
                        return "notIn: buffer[] expected";
            }
            if (message.ip != null && message.hasOwnProperty("ip")) {
                properties.wellKnown = 1;
                if (typeof message.ip !== "boolean")
                    return "ip: boolean expected";
            }
            if (message.ipv4 != null && message.hasOwnProperty("ipv4")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.ipv4 !== "boolean")
                    return "ipv4: boolean expected";
            }
            if (message.ipv6 != null && message.hasOwnProperty("ipv6")) {
                if (properties.wellKnown === 1)
                    return "wellKnown: multiple values";
                properties.wellKnown = 1;
                if (typeof message.ipv6 !== "boolean")
                    return "ipv6: boolean expected";
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a BytesRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.BytesRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.BytesRules} BytesRules
         */
        BytesRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.BytesRules)
                return object;
            let message = new $root.validate.BytesRules();
            if (object["const"] != null)
                if (typeof object["const"] === "string")
                    $util.base64.decode(object["const"], message["const"] = $util.newBuffer($util.base64.length(object["const"])), 0);
                else if (object["const"].length >= 0)
                    message["const"] = object["const"];
            if (object.len != null)
                if ($util.Long)
                    (message.len = $util.Long.fromValue(object.len)).unsigned = true;
                else if (typeof object.len === "string")
                    message.len = parseInt(object.len, 10);
                else if (typeof object.len === "number")
                    message.len = object.len;
                else if (typeof object.len === "object")
                    message.len = new $util.LongBits(object.len.low >>> 0, object.len.high >>> 0).toNumber(true);
            if (object.minLen != null)
                if ($util.Long)
                    (message.minLen = $util.Long.fromValue(object.minLen)).unsigned = true;
                else if (typeof object.minLen === "string")
                    message.minLen = parseInt(object.minLen, 10);
                else if (typeof object.minLen === "number")
                    message.minLen = object.minLen;
                else if (typeof object.minLen === "object")
                    message.minLen = new $util.LongBits(object.minLen.low >>> 0, object.minLen.high >>> 0).toNumber(true);
            if (object.maxLen != null)
                if ($util.Long)
                    (message.maxLen = $util.Long.fromValue(object.maxLen)).unsigned = true;
                else if (typeof object.maxLen === "string")
                    message.maxLen = parseInt(object.maxLen, 10);
                else if (typeof object.maxLen === "number")
                    message.maxLen = object.maxLen;
                else if (typeof object.maxLen === "object")
                    message.maxLen = new $util.LongBits(object.maxLen.low >>> 0, object.maxLen.high >>> 0).toNumber(true);
            if (object.pattern != null)
                message.pattern = String(object.pattern);
            if (object.prefix != null)
                if (typeof object.prefix === "string")
                    $util.base64.decode(object.prefix, message.prefix = $util.newBuffer($util.base64.length(object.prefix)), 0);
                else if (object.prefix.length >= 0)
                    message.prefix = object.prefix;
            if (object.suffix != null)
                if (typeof object.suffix === "string")
                    $util.base64.decode(object.suffix, message.suffix = $util.newBuffer($util.base64.length(object.suffix)), 0);
                else if (object.suffix.length >= 0)
                    message.suffix = object.suffix;
            if (object.contains != null)
                if (typeof object.contains === "string")
                    $util.base64.decode(object.contains, message.contains = $util.newBuffer($util.base64.length(object.contains)), 0);
                else if (object.contains.length >= 0)
                    message.contains = object.contains;
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.BytesRules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    if (typeof object["in"][i] === "string")
                        $util.base64.decode(object["in"][i], message["in"][i] = $util.newBuffer($util.base64.length(object["in"][i])), 0);
                    else if (object["in"][i].length >= 0)
                        message["in"][i] = object["in"][i];
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.BytesRules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    if (typeof object.notIn[i] === "string")
                        $util.base64.decode(object.notIn[i], message.notIn[i] = $util.newBuffer($util.base64.length(object.notIn[i])), 0);
                    else if (object.notIn[i].length >= 0)
                        message.notIn[i] = object.notIn[i];
            }
            if (object.ip != null)
                message.ip = Boolean(object.ip);
            if (object.ipv4 != null)
                message.ipv4 = Boolean(object.ipv4);
            if (object.ipv6 != null)
                message.ipv6 = Boolean(object.ipv6);
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a BytesRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.BytesRules
         * @static
         * @param {validate.BytesRules} message BytesRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BytesRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                if (options.bytes === String)
                    object["const"] = "";
                else {
                    object["const"] = [];
                    if (options.bytes !== Array)
                        object["const"] = $util.newBuffer(object["const"]);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minLen = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minLen = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxLen = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxLen = options.longs === String ? "0" : 0;
                object.pattern = "";
                if (options.bytes === String)
                    object.prefix = "";
                else {
                    object.prefix = [];
                    if (options.bytes !== Array)
                        object.prefix = $util.newBuffer(object.prefix);
                }
                if (options.bytes === String)
                    object.suffix = "";
                else {
                    object.suffix = [];
                    if (options.bytes !== Array)
                        object.suffix = $util.newBuffer(object.suffix);
                }
                if (options.bytes === String)
                    object.contains = "";
                else {
                    object.contains = [];
                    if (options.bytes !== Array)
                        object.contains = $util.newBuffer(object.contains);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.len = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.len = options.longs === String ? "0" : 0;
                object.ignoreEmpty = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = options.bytes === String ? $util.base64.encode(message["const"], 0, message["const"].length) : options.bytes === Array ? Array.prototype.slice.call(message["const"]) : message["const"];
            if (message.minLen != null && message.hasOwnProperty("minLen"))
                if (typeof message.minLen === "number")
                    object.minLen = options.longs === String ? String(message.minLen) : message.minLen;
                else
                    object.minLen = options.longs === String ? $util.Long.prototype.toString.call(message.minLen) : options.longs === Number ? new $util.LongBits(message.minLen.low >>> 0, message.minLen.high >>> 0).toNumber(true) : message.minLen;
            if (message.maxLen != null && message.hasOwnProperty("maxLen"))
                if (typeof message.maxLen === "number")
                    object.maxLen = options.longs === String ? String(message.maxLen) : message.maxLen;
                else
                    object.maxLen = options.longs === String ? $util.Long.prototype.toString.call(message.maxLen) : options.longs === Number ? new $util.LongBits(message.maxLen.low >>> 0, message.maxLen.high >>> 0).toNumber(true) : message.maxLen;
            if (message.pattern != null && message.hasOwnProperty("pattern"))
                object.pattern = message.pattern;
            if (message.prefix != null && message.hasOwnProperty("prefix"))
                object.prefix = options.bytes === String ? $util.base64.encode(message.prefix, 0, message.prefix.length) : options.bytes === Array ? Array.prototype.slice.call(message.prefix) : message.prefix;
            if (message.suffix != null && message.hasOwnProperty("suffix"))
                object.suffix = options.bytes === String ? $util.base64.encode(message.suffix, 0, message.suffix.length) : options.bytes === Array ? Array.prototype.slice.call(message.suffix) : message.suffix;
            if (message.contains != null && message.hasOwnProperty("contains"))
                object.contains = options.bytes === String ? $util.base64.encode(message.contains, 0, message.contains.length) : options.bytes === Array ? Array.prototype.slice.call(message.contains) : message.contains;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = options.bytes === String ? $util.base64.encode(message["in"][j], 0, message["in"][j].length) : options.bytes === Array ? Array.prototype.slice.call(message["in"][j]) : message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = options.bytes === String ? $util.base64.encode(message.notIn[j], 0, message.notIn[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.notIn[j]) : message.notIn[j];
            }
            if (message.ip != null && message.hasOwnProperty("ip")) {
                object.ip = message.ip;
                if (options.oneofs)
                    object.wellKnown = "ip";
            }
            if (message.ipv4 != null && message.hasOwnProperty("ipv4")) {
                object.ipv4 = message.ipv4;
                if (options.oneofs)
                    object.wellKnown = "ipv4";
            }
            if (message.ipv6 != null && message.hasOwnProperty("ipv6")) {
                object.ipv6 = message.ipv6;
                if (options.oneofs)
                    object.wellKnown = "ipv6";
            }
            if (message.len != null && message.hasOwnProperty("len"))
                if (typeof message.len === "number")
                    object.len = options.longs === String ? String(message.len) : message.len;
                else
                    object.len = options.longs === String ? $util.Long.prototype.toString.call(message.len) : options.longs === Number ? new $util.LongBits(message.len.low >>> 0, message.len.high >>> 0).toNumber(true) : message.len;
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this BytesRules to JSON.
         * @function toJSON
         * @memberof validate.BytesRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BytesRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BytesRules
         * @function getTypeUrl
         * @memberof validate.BytesRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BytesRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.BytesRules";
        };

        return BytesRules;
    })();

    validate.EnumRules = (function() {

        /**
         * Properties of an EnumRules.
         * @memberof validate
         * @interface IEnumRules
         * @property {number|null} ["const"] EnumRules const
         * @property {boolean|null} [definedOnly] EnumRules definedOnly
         * @property {Array.<number>|null} ["in"] EnumRules in
         * @property {Array.<number>|null} [notIn] EnumRules notIn
         */

        /**
         * Constructs a new EnumRules.
         * @memberof validate
         * @classdesc Represents an EnumRules.
         * @implements IEnumRules
         * @constructor
         * @param {validate.IEnumRules=} [properties] Properties to set
         */
        function EnumRules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EnumRules const.
         * @member {number} const
         * @memberof validate.EnumRules
         * @instance
         */
        EnumRules.prototype["const"] = 0;

        /**
         * EnumRules definedOnly.
         * @member {boolean} definedOnly
         * @memberof validate.EnumRules
         * @instance
         */
        EnumRules.prototype.definedOnly = false;

        /**
         * EnumRules in.
         * @member {Array.<number>} in
         * @memberof validate.EnumRules
         * @instance
         */
        EnumRules.prototype["in"] = $util.emptyArray;

        /**
         * EnumRules notIn.
         * @member {Array.<number>} notIn
         * @memberof validate.EnumRules
         * @instance
         */
        EnumRules.prototype.notIn = $util.emptyArray;

        /**
         * Creates a new EnumRules instance using the specified properties.
         * @function create
         * @memberof validate.EnumRules
         * @static
         * @param {validate.IEnumRules=} [properties] Properties to set
         * @returns {validate.EnumRules} EnumRules instance
         */
        EnumRules.create = function create(properties) {
            return new EnumRules(properties);
        };

        /**
         * Encodes the specified EnumRules message. Does not implicitly {@link validate.EnumRules.verify|verify} messages.
         * @function encode
         * @memberof validate.EnumRules
         * @static
         * @param {validate.IEnumRules} message EnumRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnumRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message["const"]);
            if (message.definedOnly != null && Object.hasOwnProperty.call(message, "definedOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.definedOnly);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.notIn[i]);
            return writer;
        };

        /**
         * Encodes the specified EnumRules message, length delimited. Does not implicitly {@link validate.EnumRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.EnumRules
         * @static
         * @param {validate.IEnumRules} message EnumRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EnumRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EnumRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.EnumRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.EnumRules} EnumRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnumRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.EnumRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message["const"] = reader.int32();
                        break;
                    }
                case 2: {
                        message.definedOnly = reader.bool();
                        break;
                    }
                case 3: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message["in"].push(reader.int32());
                        } else
                            message["in"].push(reader.int32());
                        break;
                    }
                case 4: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.notIn.push(reader.int32());
                        } else
                            message.notIn.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EnumRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.EnumRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.EnumRules} EnumRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EnumRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EnumRules message.
         * @function verify
         * @memberof validate.EnumRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EnumRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message["const"] != null && message.hasOwnProperty("const"))
                if (!$util.isInteger(message["const"]))
                    return "const: integer expected";
            if (message.definedOnly != null && message.hasOwnProperty("definedOnly"))
                if (typeof message.definedOnly !== "boolean")
                    return "definedOnly: boolean expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isInteger(message["in"][i]))
                        return "in: integer[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isInteger(message.notIn[i]))
                        return "notIn: integer[] expected";
            }
            return null;
        };

        /**
         * Creates an EnumRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.EnumRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.EnumRules} EnumRules
         */
        EnumRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.EnumRules)
                return object;
            let message = new $root.validate.EnumRules();
            if (object["const"] != null)
                message["const"] = object["const"] | 0;
            if (object.definedOnly != null)
                message.definedOnly = Boolean(object.definedOnly);
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.EnumRules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = object["in"][i] | 0;
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.EnumRules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = object.notIn[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from an EnumRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.EnumRules
         * @static
         * @param {validate.EnumRules} message EnumRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EnumRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object["const"] = 0;
                object.definedOnly = false;
            }
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = message["const"];
            if (message.definedOnly != null && message.hasOwnProperty("definedOnly"))
                object.definedOnly = message.definedOnly;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = message.notIn[j];
            }
            return object;
        };

        /**
         * Converts this EnumRules to JSON.
         * @function toJSON
         * @memberof validate.EnumRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EnumRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EnumRules
         * @function getTypeUrl
         * @memberof validate.EnumRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EnumRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.EnumRules";
        };

        return EnumRules;
    })();

    validate.MessageRules = (function() {

        /**
         * Properties of a MessageRules.
         * @memberof validate
         * @interface IMessageRules
         * @property {boolean|null} [skip] MessageRules skip
         * @property {boolean|null} [required] MessageRules required
         */

        /**
         * Constructs a new MessageRules.
         * @memberof validate
         * @classdesc Represents a MessageRules.
         * @implements IMessageRules
         * @constructor
         * @param {validate.IMessageRules=} [properties] Properties to set
         */
        function MessageRules(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageRules skip.
         * @member {boolean} skip
         * @memberof validate.MessageRules
         * @instance
         */
        MessageRules.prototype.skip = false;

        /**
         * MessageRules required.
         * @member {boolean} required
         * @memberof validate.MessageRules
         * @instance
         */
        MessageRules.prototype.required = false;

        /**
         * Creates a new MessageRules instance using the specified properties.
         * @function create
         * @memberof validate.MessageRules
         * @static
         * @param {validate.IMessageRules=} [properties] Properties to set
         * @returns {validate.MessageRules} MessageRules instance
         */
        MessageRules.create = function create(properties) {
            return new MessageRules(properties);
        };

        /**
         * Encodes the specified MessageRules message. Does not implicitly {@link validate.MessageRules.verify|verify} messages.
         * @function encode
         * @memberof validate.MessageRules
         * @static
         * @param {validate.IMessageRules} message MessageRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skip != null && Object.hasOwnProperty.call(message, "skip"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.skip);
            if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.required);
            return writer;
        };

        /**
         * Encodes the specified MessageRules message, length delimited. Does not implicitly {@link validate.MessageRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.MessageRules
         * @static
         * @param {validate.IMessageRules} message MessageRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MessageRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MessageRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.MessageRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.MessageRules} MessageRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.MessageRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.skip = reader.bool();
                        break;
                    }
                case 2: {
                        message.required = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MessageRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.MessageRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.MessageRules} MessageRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MessageRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MessageRules message.
         * @function verify
         * @memberof validate.MessageRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MessageRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.skip != null && message.hasOwnProperty("skip"))
                if (typeof message.skip !== "boolean")
                    return "skip: boolean expected";
            if (message.required != null && message.hasOwnProperty("required"))
                if (typeof message.required !== "boolean")
                    return "required: boolean expected";
            return null;
        };

        /**
         * Creates a MessageRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.MessageRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.MessageRules} MessageRules
         */
        MessageRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.MessageRules)
                return object;
            let message = new $root.validate.MessageRules();
            if (object.skip != null)
                message.skip = Boolean(object.skip);
            if (object.required != null)
                message.required = Boolean(object.required);
            return message;
        };

        /**
         * Creates a plain object from a MessageRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.MessageRules
         * @static
         * @param {validate.MessageRules} message MessageRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MessageRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.skip = false;
                object.required = false;
            }
            if (message.skip != null && message.hasOwnProperty("skip"))
                object.skip = message.skip;
            if (message.required != null && message.hasOwnProperty("required"))
                object.required = message.required;
            return object;
        };

        /**
         * Converts this MessageRules to JSON.
         * @function toJSON
         * @memberof validate.MessageRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MessageRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MessageRules
         * @function getTypeUrl
         * @memberof validate.MessageRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.MessageRules";
        };

        return MessageRules;
    })();

    validate.RepeatedRules = (function() {

        /**
         * Properties of a RepeatedRules.
         * @memberof validate
         * @interface IRepeatedRules
         * @property {Long|null} [minItems] RepeatedRules minItems
         * @property {Long|null} [maxItems] RepeatedRules maxItems
         * @property {boolean|null} [unique] RepeatedRules unique
         * @property {validate.IFieldRules|null} [items] RepeatedRules items
         * @property {boolean|null} [ignoreEmpty] RepeatedRules ignoreEmpty
         */

        /**
         * Constructs a new RepeatedRules.
         * @memberof validate
         * @classdesc Represents a RepeatedRules.
         * @implements IRepeatedRules
         * @constructor
         * @param {validate.IRepeatedRules=} [properties] Properties to set
         */
        function RepeatedRules(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RepeatedRules minItems.
         * @member {Long} minItems
         * @memberof validate.RepeatedRules
         * @instance
         */
        RepeatedRules.prototype.minItems = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RepeatedRules maxItems.
         * @member {Long} maxItems
         * @memberof validate.RepeatedRules
         * @instance
         */
        RepeatedRules.prototype.maxItems = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RepeatedRules unique.
         * @member {boolean} unique
         * @memberof validate.RepeatedRules
         * @instance
         */
        RepeatedRules.prototype.unique = false;

        /**
         * RepeatedRules items.
         * @member {validate.IFieldRules|null|undefined} items
         * @memberof validate.RepeatedRules
         * @instance
         */
        RepeatedRules.prototype.items = null;

        /**
         * RepeatedRules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.RepeatedRules
         * @instance
         */
        RepeatedRules.prototype.ignoreEmpty = false;

        /**
         * Creates a new RepeatedRules instance using the specified properties.
         * @function create
         * @memberof validate.RepeatedRules
         * @static
         * @param {validate.IRepeatedRules=} [properties] Properties to set
         * @returns {validate.RepeatedRules} RepeatedRules instance
         */
        RepeatedRules.create = function create(properties) {
            return new RepeatedRules(properties);
        };

        /**
         * Encodes the specified RepeatedRules message. Does not implicitly {@link validate.RepeatedRules.verify|verify} messages.
         * @function encode
         * @memberof validate.RepeatedRules
         * @static
         * @param {validate.IRepeatedRules} message RepeatedRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RepeatedRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.minItems != null && Object.hasOwnProperty.call(message, "minItems"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.minItems);
            if (message.maxItems != null && Object.hasOwnProperty.call(message, "maxItems"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.maxItems);
            if (message.unique != null && Object.hasOwnProperty.call(message, "unique"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.unique);
            if (message.items != null && Object.hasOwnProperty.call(message, "items"))
                $root.validate.FieldRules.encode(message.items, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified RepeatedRules message, length delimited. Does not implicitly {@link validate.RepeatedRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.RepeatedRules
         * @static
         * @param {validate.IRepeatedRules} message RepeatedRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RepeatedRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RepeatedRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.RepeatedRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.RepeatedRules} RepeatedRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RepeatedRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.RepeatedRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.minItems = reader.uint64();
                        break;
                    }
                case 2: {
                        message.maxItems = reader.uint64();
                        break;
                    }
                case 3: {
                        message.unique = reader.bool();
                        break;
                    }
                case 4: {
                        message.items = $root.validate.FieldRules.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RepeatedRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.RepeatedRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.RepeatedRules} RepeatedRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RepeatedRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RepeatedRules message.
         * @function verify
         * @memberof validate.RepeatedRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RepeatedRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.minItems != null && message.hasOwnProperty("minItems"))
                if (!$util.isInteger(message.minItems) && !(message.minItems && $util.isInteger(message.minItems.low) && $util.isInteger(message.minItems.high)))
                    return "minItems: integer|Long expected";
            if (message.maxItems != null && message.hasOwnProperty("maxItems"))
                if (!$util.isInteger(message.maxItems) && !(message.maxItems && $util.isInteger(message.maxItems.low) && $util.isInteger(message.maxItems.high)))
                    return "maxItems: integer|Long expected";
            if (message.unique != null && message.hasOwnProperty("unique"))
                if (typeof message.unique !== "boolean")
                    return "unique: boolean expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                let error = $root.validate.FieldRules.verify(message.items);
                if (error)
                    return "items." + error;
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a RepeatedRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.RepeatedRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.RepeatedRules} RepeatedRules
         */
        RepeatedRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.RepeatedRules)
                return object;
            let message = new $root.validate.RepeatedRules();
            if (object.minItems != null)
                if ($util.Long)
                    (message.minItems = $util.Long.fromValue(object.minItems)).unsigned = true;
                else if (typeof object.minItems === "string")
                    message.minItems = parseInt(object.minItems, 10);
                else if (typeof object.minItems === "number")
                    message.minItems = object.minItems;
                else if (typeof object.minItems === "object")
                    message.minItems = new $util.LongBits(object.minItems.low >>> 0, object.minItems.high >>> 0).toNumber(true);
            if (object.maxItems != null)
                if ($util.Long)
                    (message.maxItems = $util.Long.fromValue(object.maxItems)).unsigned = true;
                else if (typeof object.maxItems === "string")
                    message.maxItems = parseInt(object.maxItems, 10);
                else if (typeof object.maxItems === "number")
                    message.maxItems = object.maxItems;
                else if (typeof object.maxItems === "object")
                    message.maxItems = new $util.LongBits(object.maxItems.low >>> 0, object.maxItems.high >>> 0).toNumber(true);
            if (object.unique != null)
                message.unique = Boolean(object.unique);
            if (object.items != null) {
                if (typeof object.items !== "object")
                    throw TypeError(".validate.RepeatedRules.items: object expected");
                message.items = $root.validate.FieldRules.fromObject(object.items);
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a RepeatedRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.RepeatedRules
         * @static
         * @param {validate.RepeatedRules} message RepeatedRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RepeatedRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minItems = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minItems = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxItems = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxItems = options.longs === String ? "0" : 0;
                object.unique = false;
                object.items = null;
                object.ignoreEmpty = false;
            }
            if (message.minItems != null && message.hasOwnProperty("minItems"))
                if (typeof message.minItems === "number")
                    object.minItems = options.longs === String ? String(message.minItems) : message.minItems;
                else
                    object.minItems = options.longs === String ? $util.Long.prototype.toString.call(message.minItems) : options.longs === Number ? new $util.LongBits(message.minItems.low >>> 0, message.minItems.high >>> 0).toNumber(true) : message.minItems;
            if (message.maxItems != null && message.hasOwnProperty("maxItems"))
                if (typeof message.maxItems === "number")
                    object.maxItems = options.longs === String ? String(message.maxItems) : message.maxItems;
                else
                    object.maxItems = options.longs === String ? $util.Long.prototype.toString.call(message.maxItems) : options.longs === Number ? new $util.LongBits(message.maxItems.low >>> 0, message.maxItems.high >>> 0).toNumber(true) : message.maxItems;
            if (message.unique != null && message.hasOwnProperty("unique"))
                object.unique = message.unique;
            if (message.items != null && message.hasOwnProperty("items"))
                object.items = $root.validate.FieldRules.toObject(message.items, options);
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this RepeatedRules to JSON.
         * @function toJSON
         * @memberof validate.RepeatedRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RepeatedRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RepeatedRules
         * @function getTypeUrl
         * @memberof validate.RepeatedRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RepeatedRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.RepeatedRules";
        };

        return RepeatedRules;
    })();

    validate.MapRules = (function() {

        /**
         * Properties of a MapRules.
         * @memberof validate
         * @interface IMapRules
         * @property {Long|null} [minPairs] MapRules minPairs
         * @property {Long|null} [maxPairs] MapRules maxPairs
         * @property {boolean|null} [noSparse] MapRules noSparse
         * @property {validate.IFieldRules|null} [keys] MapRules keys
         * @property {validate.IFieldRules|null} [values] MapRules values
         * @property {boolean|null} [ignoreEmpty] MapRules ignoreEmpty
         */

        /**
         * Constructs a new MapRules.
         * @memberof validate
         * @classdesc Represents a MapRules.
         * @implements IMapRules
         * @constructor
         * @param {validate.IMapRules=} [properties] Properties to set
         */
        function MapRules(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapRules minPairs.
         * @member {Long} minPairs
         * @memberof validate.MapRules
         * @instance
         */
        MapRules.prototype.minPairs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MapRules maxPairs.
         * @member {Long} maxPairs
         * @memberof validate.MapRules
         * @instance
         */
        MapRules.prototype.maxPairs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MapRules noSparse.
         * @member {boolean} noSparse
         * @memberof validate.MapRules
         * @instance
         */
        MapRules.prototype.noSparse = false;

        /**
         * MapRules keys.
         * @member {validate.IFieldRules|null|undefined} keys
         * @memberof validate.MapRules
         * @instance
         */
        MapRules.prototype.keys = null;

        /**
         * MapRules values.
         * @member {validate.IFieldRules|null|undefined} values
         * @memberof validate.MapRules
         * @instance
         */
        MapRules.prototype.values = null;

        /**
         * MapRules ignoreEmpty.
         * @member {boolean} ignoreEmpty
         * @memberof validate.MapRules
         * @instance
         */
        MapRules.prototype.ignoreEmpty = false;

        /**
         * Creates a new MapRules instance using the specified properties.
         * @function create
         * @memberof validate.MapRules
         * @static
         * @param {validate.IMapRules=} [properties] Properties to set
         * @returns {validate.MapRules} MapRules instance
         */
        MapRules.create = function create(properties) {
            return new MapRules(properties);
        };

        /**
         * Encodes the specified MapRules message. Does not implicitly {@link validate.MapRules.verify|verify} messages.
         * @function encode
         * @memberof validate.MapRules
         * @static
         * @param {validate.IMapRules} message MapRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.minPairs != null && Object.hasOwnProperty.call(message, "minPairs"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.minPairs);
            if (message.maxPairs != null && Object.hasOwnProperty.call(message, "maxPairs"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.maxPairs);
            if (message.noSparse != null && Object.hasOwnProperty.call(message, "noSparse"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.noSparse);
            if (message.keys != null && Object.hasOwnProperty.call(message, "keys"))
                $root.validate.FieldRules.encode(message.keys, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.values != null && Object.hasOwnProperty.call(message, "values"))
                $root.validate.FieldRules.encode(message.values, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.ignoreEmpty != null && Object.hasOwnProperty.call(message, "ignoreEmpty"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.ignoreEmpty);
            return writer;
        };

        /**
         * Encodes the specified MapRules message, length delimited. Does not implicitly {@link validate.MapRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.MapRules
         * @static
         * @param {validate.IMapRules} message MapRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.MapRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.MapRules} MapRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.MapRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.minPairs = reader.uint64();
                        break;
                    }
                case 2: {
                        message.maxPairs = reader.uint64();
                        break;
                    }
                case 3: {
                        message.noSparse = reader.bool();
                        break;
                    }
                case 4: {
                        message.keys = $root.validate.FieldRules.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.values = $root.validate.FieldRules.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.ignoreEmpty = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.MapRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.MapRules} MapRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapRules message.
         * @function verify
         * @memberof validate.MapRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.minPairs != null && message.hasOwnProperty("minPairs"))
                if (!$util.isInteger(message.minPairs) && !(message.minPairs && $util.isInteger(message.minPairs.low) && $util.isInteger(message.minPairs.high)))
                    return "minPairs: integer|Long expected";
            if (message.maxPairs != null && message.hasOwnProperty("maxPairs"))
                if (!$util.isInteger(message.maxPairs) && !(message.maxPairs && $util.isInteger(message.maxPairs.low) && $util.isInteger(message.maxPairs.high)))
                    return "maxPairs: integer|Long expected";
            if (message.noSparse != null && message.hasOwnProperty("noSparse"))
                if (typeof message.noSparse !== "boolean")
                    return "noSparse: boolean expected";
            if (message.keys != null && message.hasOwnProperty("keys")) {
                let error = $root.validate.FieldRules.verify(message.keys);
                if (error)
                    return "keys." + error;
            }
            if (message.values != null && message.hasOwnProperty("values")) {
                let error = $root.validate.FieldRules.verify(message.values);
                if (error)
                    return "values." + error;
            }
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                if (typeof message.ignoreEmpty !== "boolean")
                    return "ignoreEmpty: boolean expected";
            return null;
        };

        /**
         * Creates a MapRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.MapRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.MapRules} MapRules
         */
        MapRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.MapRules)
                return object;
            let message = new $root.validate.MapRules();
            if (object.minPairs != null)
                if ($util.Long)
                    (message.minPairs = $util.Long.fromValue(object.minPairs)).unsigned = true;
                else if (typeof object.minPairs === "string")
                    message.minPairs = parseInt(object.minPairs, 10);
                else if (typeof object.minPairs === "number")
                    message.minPairs = object.minPairs;
                else if (typeof object.minPairs === "object")
                    message.minPairs = new $util.LongBits(object.minPairs.low >>> 0, object.minPairs.high >>> 0).toNumber(true);
            if (object.maxPairs != null)
                if ($util.Long)
                    (message.maxPairs = $util.Long.fromValue(object.maxPairs)).unsigned = true;
                else if (typeof object.maxPairs === "string")
                    message.maxPairs = parseInt(object.maxPairs, 10);
                else if (typeof object.maxPairs === "number")
                    message.maxPairs = object.maxPairs;
                else if (typeof object.maxPairs === "object")
                    message.maxPairs = new $util.LongBits(object.maxPairs.low >>> 0, object.maxPairs.high >>> 0).toNumber(true);
            if (object.noSparse != null)
                message.noSparse = Boolean(object.noSparse);
            if (object.keys != null) {
                if (typeof object.keys !== "object")
                    throw TypeError(".validate.MapRules.keys: object expected");
                message.keys = $root.validate.FieldRules.fromObject(object.keys);
            }
            if (object.values != null) {
                if (typeof object.values !== "object")
                    throw TypeError(".validate.MapRules.values: object expected");
                message.values = $root.validate.FieldRules.fromObject(object.values);
            }
            if (object.ignoreEmpty != null)
                message.ignoreEmpty = Boolean(object.ignoreEmpty);
            return message;
        };

        /**
         * Creates a plain object from a MapRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.MapRules
         * @static
         * @param {validate.MapRules} message MapRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minPairs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minPairs = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxPairs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxPairs = options.longs === String ? "0" : 0;
                object.noSparse = false;
                object.keys = null;
                object.values = null;
                object.ignoreEmpty = false;
            }
            if (message.minPairs != null && message.hasOwnProperty("minPairs"))
                if (typeof message.minPairs === "number")
                    object.minPairs = options.longs === String ? String(message.minPairs) : message.minPairs;
                else
                    object.minPairs = options.longs === String ? $util.Long.prototype.toString.call(message.minPairs) : options.longs === Number ? new $util.LongBits(message.minPairs.low >>> 0, message.minPairs.high >>> 0).toNumber(true) : message.minPairs;
            if (message.maxPairs != null && message.hasOwnProperty("maxPairs"))
                if (typeof message.maxPairs === "number")
                    object.maxPairs = options.longs === String ? String(message.maxPairs) : message.maxPairs;
                else
                    object.maxPairs = options.longs === String ? $util.Long.prototype.toString.call(message.maxPairs) : options.longs === Number ? new $util.LongBits(message.maxPairs.low >>> 0, message.maxPairs.high >>> 0).toNumber(true) : message.maxPairs;
            if (message.noSparse != null && message.hasOwnProperty("noSparse"))
                object.noSparse = message.noSparse;
            if (message.keys != null && message.hasOwnProperty("keys"))
                object.keys = $root.validate.FieldRules.toObject(message.keys, options);
            if (message.values != null && message.hasOwnProperty("values"))
                object.values = $root.validate.FieldRules.toObject(message.values, options);
            if (message.ignoreEmpty != null && message.hasOwnProperty("ignoreEmpty"))
                object.ignoreEmpty = message.ignoreEmpty;
            return object;
        };

        /**
         * Converts this MapRules to JSON.
         * @function toJSON
         * @memberof validate.MapRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapRules
         * @function getTypeUrl
         * @memberof validate.MapRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.MapRules";
        };

        return MapRules;
    })();

    validate.AnyRules = (function() {

        /**
         * Properties of an AnyRules.
         * @memberof validate
         * @interface IAnyRules
         * @property {boolean|null} [required] AnyRules required
         * @property {Array.<string>|null} ["in"] AnyRules in
         * @property {Array.<string>|null} [notIn] AnyRules notIn
         */

        /**
         * Constructs a new AnyRules.
         * @memberof validate
         * @classdesc Represents an AnyRules.
         * @implements IAnyRules
         * @constructor
         * @param {validate.IAnyRules=} [properties] Properties to set
         */
        function AnyRules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AnyRules required.
         * @member {boolean} required
         * @memberof validate.AnyRules
         * @instance
         */
        AnyRules.prototype.required = false;

        /**
         * AnyRules in.
         * @member {Array.<string>} in
         * @memberof validate.AnyRules
         * @instance
         */
        AnyRules.prototype["in"] = $util.emptyArray;

        /**
         * AnyRules notIn.
         * @member {Array.<string>} notIn
         * @memberof validate.AnyRules
         * @instance
         */
        AnyRules.prototype.notIn = $util.emptyArray;

        /**
         * Creates a new AnyRules instance using the specified properties.
         * @function create
         * @memberof validate.AnyRules
         * @static
         * @param {validate.IAnyRules=} [properties] Properties to set
         * @returns {validate.AnyRules} AnyRules instance
         */
        AnyRules.create = function create(properties) {
            return new AnyRules(properties);
        };

        /**
         * Encodes the specified AnyRules message. Does not implicitly {@link validate.AnyRules.verify|verify} messages.
         * @function encode
         * @memberof validate.AnyRules
         * @static
         * @param {validate.IAnyRules} message AnyRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AnyRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.required);
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.notIn[i]);
            return writer;
        };

        /**
         * Encodes the specified AnyRules message, length delimited. Does not implicitly {@link validate.AnyRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.AnyRules
         * @static
         * @param {validate.IAnyRules} message AnyRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AnyRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AnyRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.AnyRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.AnyRules} AnyRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AnyRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.AnyRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.required = reader.bool();
                        break;
                    }
                case 2: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        message["in"].push(reader.string());
                        break;
                    }
                case 3: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        message.notIn.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AnyRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.AnyRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.AnyRules} AnyRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AnyRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AnyRules message.
         * @function verify
         * @memberof validate.AnyRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AnyRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.required != null && message.hasOwnProperty("required"))
                if (typeof message.required !== "boolean")
                    return "required: boolean expected";
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i)
                    if (!$util.isString(message["in"][i]))
                        return "in: string[] expected";
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i)
                    if (!$util.isString(message.notIn[i]))
                        return "notIn: string[] expected";
            }
            return null;
        };

        /**
         * Creates an AnyRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.AnyRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.AnyRules} AnyRules
         */
        AnyRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.AnyRules)
                return object;
            let message = new $root.validate.AnyRules();
            if (object.required != null)
                message.required = Boolean(object.required);
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.AnyRules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i)
                    message["in"][i] = String(object["in"][i]);
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.AnyRules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i)
                    message.notIn[i] = String(object.notIn[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an AnyRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.AnyRules
         * @static
         * @param {validate.AnyRules} message AnyRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AnyRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults)
                object.required = false;
            if (message.required != null && message.hasOwnProperty("required"))
                object.required = message.required;
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = message["in"][j];
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = message.notIn[j];
            }
            return object;
        };

        /**
         * Converts this AnyRules to JSON.
         * @function toJSON
         * @memberof validate.AnyRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AnyRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AnyRules
         * @function getTypeUrl
         * @memberof validate.AnyRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AnyRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.AnyRules";
        };

        return AnyRules;
    })();

    validate.DurationRules = (function() {

        /**
         * Properties of a DurationRules.
         * @memberof validate
         * @interface IDurationRules
         * @property {boolean|null} [required] DurationRules required
         * @property {google.protobuf.IDuration|null} ["const"] DurationRules const
         * @property {google.protobuf.IDuration|null} [lt] DurationRules lt
         * @property {google.protobuf.IDuration|null} [lte] DurationRules lte
         * @property {google.protobuf.IDuration|null} [gt] DurationRules gt
         * @property {google.protobuf.IDuration|null} [gte] DurationRules gte
         * @property {Array.<google.protobuf.IDuration>|null} ["in"] DurationRules in
         * @property {Array.<google.protobuf.IDuration>|null} [notIn] DurationRules notIn
         */

        /**
         * Constructs a new DurationRules.
         * @memberof validate
         * @classdesc Represents a DurationRules.
         * @implements IDurationRules
         * @constructor
         * @param {validate.IDurationRules=} [properties] Properties to set
         */
        function DurationRules(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DurationRules required.
         * @member {boolean} required
         * @memberof validate.DurationRules
         * @instance
         */
        DurationRules.prototype.required = false;

        /**
         * DurationRules const.
         * @member {google.protobuf.IDuration|null|undefined} const
         * @memberof validate.DurationRules
         * @instance
         */
        DurationRules.prototype["const"] = null;

        /**
         * DurationRules lt.
         * @member {google.protobuf.IDuration|null|undefined} lt
         * @memberof validate.DurationRules
         * @instance
         */
        DurationRules.prototype.lt = null;

        /**
         * DurationRules lte.
         * @member {google.protobuf.IDuration|null|undefined} lte
         * @memberof validate.DurationRules
         * @instance
         */
        DurationRules.prototype.lte = null;

        /**
         * DurationRules gt.
         * @member {google.protobuf.IDuration|null|undefined} gt
         * @memberof validate.DurationRules
         * @instance
         */
        DurationRules.prototype.gt = null;

        /**
         * DurationRules gte.
         * @member {google.protobuf.IDuration|null|undefined} gte
         * @memberof validate.DurationRules
         * @instance
         */
        DurationRules.prototype.gte = null;

        /**
         * DurationRules in.
         * @member {Array.<google.protobuf.IDuration>} in
         * @memberof validate.DurationRules
         * @instance
         */
        DurationRules.prototype["in"] = $util.emptyArray;

        /**
         * DurationRules notIn.
         * @member {Array.<google.protobuf.IDuration>} notIn
         * @memberof validate.DurationRules
         * @instance
         */
        DurationRules.prototype.notIn = $util.emptyArray;

        /**
         * Creates a new DurationRules instance using the specified properties.
         * @function create
         * @memberof validate.DurationRules
         * @static
         * @param {validate.IDurationRules=} [properties] Properties to set
         * @returns {validate.DurationRules} DurationRules instance
         */
        DurationRules.create = function create(properties) {
            return new DurationRules(properties);
        };

        /**
         * Encodes the specified DurationRules message. Does not implicitly {@link validate.DurationRules.verify|verify} messages.
         * @function encode
         * @memberof validate.DurationRules
         * @static
         * @param {validate.IDurationRules} message DurationRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DurationRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.required);
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                $root.google.protobuf.Duration.encode(message["const"], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                $root.google.protobuf.Duration.encode(message.lt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                $root.google.protobuf.Duration.encode(message.lte, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                $root.google.protobuf.Duration.encode(message.gt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                $root.google.protobuf.Duration.encode(message.gte, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message["in"] != null && message["in"].length)
                for (let i = 0; i < message["in"].length; ++i)
                    $root.google.protobuf.Duration.encode(message["in"][i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.notIn != null && message.notIn.length)
                for (let i = 0; i < message.notIn.length; ++i)
                    $root.google.protobuf.Duration.encode(message.notIn[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DurationRules message, length delimited. Does not implicitly {@link validate.DurationRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.DurationRules
         * @static
         * @param {validate.IDurationRules} message DurationRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DurationRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DurationRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.DurationRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.DurationRules} DurationRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DurationRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.DurationRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.required = reader.bool();
                        break;
                    }
                case 2: {
                        message["const"] = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.lt = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.lte = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.gt = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.gte = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        if (!(message["in"] && message["in"].length))
                            message["in"] = [];
                        message["in"].push($root.google.protobuf.Duration.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        if (!(message.notIn && message.notIn.length))
                            message.notIn = [];
                        message.notIn.push($root.google.protobuf.Duration.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DurationRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.DurationRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.DurationRules} DurationRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DurationRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DurationRules message.
         * @function verify
         * @memberof validate.DurationRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DurationRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.required != null && message.hasOwnProperty("required"))
                if (typeof message.required !== "boolean")
                    return "required: boolean expected";
            if (message["const"] != null && message.hasOwnProperty("const")) {
                let error = $root.google.protobuf.Duration.verify(message["const"]);
                if (error)
                    return "const." + error;
            }
            if (message.lt != null && message.hasOwnProperty("lt")) {
                let error = $root.google.protobuf.Duration.verify(message.lt);
                if (error)
                    return "lt." + error;
            }
            if (message.lte != null && message.hasOwnProperty("lte")) {
                let error = $root.google.protobuf.Duration.verify(message.lte);
                if (error)
                    return "lte." + error;
            }
            if (message.gt != null && message.hasOwnProperty("gt")) {
                let error = $root.google.protobuf.Duration.verify(message.gt);
                if (error)
                    return "gt." + error;
            }
            if (message.gte != null && message.hasOwnProperty("gte")) {
                let error = $root.google.protobuf.Duration.verify(message.gte);
                if (error)
                    return "gte." + error;
            }
            if (message["in"] != null && message.hasOwnProperty("in")) {
                if (!Array.isArray(message["in"]))
                    return "in: array expected";
                for (let i = 0; i < message["in"].length; ++i) {
                    let error = $root.google.protobuf.Duration.verify(message["in"][i]);
                    if (error)
                        return "in." + error;
                }
            }
            if (message.notIn != null && message.hasOwnProperty("notIn")) {
                if (!Array.isArray(message.notIn))
                    return "notIn: array expected";
                for (let i = 0; i < message.notIn.length; ++i) {
                    let error = $root.google.protobuf.Duration.verify(message.notIn[i]);
                    if (error)
                        return "notIn." + error;
                }
            }
            return null;
        };

        /**
         * Creates a DurationRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.DurationRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.DurationRules} DurationRules
         */
        DurationRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.DurationRules)
                return object;
            let message = new $root.validate.DurationRules();
            if (object.required != null)
                message.required = Boolean(object.required);
            if (object["const"] != null) {
                if (typeof object["const"] !== "object")
                    throw TypeError(".validate.DurationRules.const: object expected");
                message["const"] = $root.google.protobuf.Duration.fromObject(object["const"]);
            }
            if (object.lt != null) {
                if (typeof object.lt !== "object")
                    throw TypeError(".validate.DurationRules.lt: object expected");
                message.lt = $root.google.protobuf.Duration.fromObject(object.lt);
            }
            if (object.lte != null) {
                if (typeof object.lte !== "object")
                    throw TypeError(".validate.DurationRules.lte: object expected");
                message.lte = $root.google.protobuf.Duration.fromObject(object.lte);
            }
            if (object.gt != null) {
                if (typeof object.gt !== "object")
                    throw TypeError(".validate.DurationRules.gt: object expected");
                message.gt = $root.google.protobuf.Duration.fromObject(object.gt);
            }
            if (object.gte != null) {
                if (typeof object.gte !== "object")
                    throw TypeError(".validate.DurationRules.gte: object expected");
                message.gte = $root.google.protobuf.Duration.fromObject(object.gte);
            }
            if (object["in"]) {
                if (!Array.isArray(object["in"]))
                    throw TypeError(".validate.DurationRules.in: array expected");
                message["in"] = [];
                for (let i = 0; i < object["in"].length; ++i) {
                    if (typeof object["in"][i] !== "object")
                        throw TypeError(".validate.DurationRules.in: object expected");
                    message["in"][i] = $root.google.protobuf.Duration.fromObject(object["in"][i]);
                }
            }
            if (object.notIn) {
                if (!Array.isArray(object.notIn))
                    throw TypeError(".validate.DurationRules.notIn: array expected");
                message.notIn = [];
                for (let i = 0; i < object.notIn.length; ++i) {
                    if (typeof object.notIn[i] !== "object")
                        throw TypeError(".validate.DurationRules.notIn: object expected");
                    message.notIn[i] = $root.google.protobuf.Duration.fromObject(object.notIn[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a DurationRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.DurationRules
         * @static
         * @param {validate.DurationRules} message DurationRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DurationRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object["in"] = [];
                object.notIn = [];
            }
            if (options.defaults) {
                object.required = false;
                object["const"] = null;
                object.lt = null;
                object.lte = null;
                object.gt = null;
                object.gte = null;
            }
            if (message.required != null && message.hasOwnProperty("required"))
                object.required = message.required;
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = $root.google.protobuf.Duration.toObject(message["const"], options);
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = $root.google.protobuf.Duration.toObject(message.lt, options);
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = $root.google.protobuf.Duration.toObject(message.lte, options);
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = $root.google.protobuf.Duration.toObject(message.gt, options);
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = $root.google.protobuf.Duration.toObject(message.gte, options);
            if (message["in"] && message["in"].length) {
                object["in"] = [];
                for (let j = 0; j < message["in"].length; ++j)
                    object["in"][j] = $root.google.protobuf.Duration.toObject(message["in"][j], options);
            }
            if (message.notIn && message.notIn.length) {
                object.notIn = [];
                for (let j = 0; j < message.notIn.length; ++j)
                    object.notIn[j] = $root.google.protobuf.Duration.toObject(message.notIn[j], options);
            }
            return object;
        };

        /**
         * Converts this DurationRules to JSON.
         * @function toJSON
         * @memberof validate.DurationRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DurationRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DurationRules
         * @function getTypeUrl
         * @memberof validate.DurationRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DurationRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.DurationRules";
        };

        return DurationRules;
    })();

    validate.TimestampRules = (function() {

        /**
         * Properties of a TimestampRules.
         * @memberof validate
         * @interface ITimestampRules
         * @property {boolean|null} [required] TimestampRules required
         * @property {google.protobuf.ITimestamp|null} ["const"] TimestampRules const
         * @property {google.protobuf.ITimestamp|null} [lt] TimestampRules lt
         * @property {google.protobuf.ITimestamp|null} [lte] TimestampRules lte
         * @property {google.protobuf.ITimestamp|null} [gt] TimestampRules gt
         * @property {google.protobuf.ITimestamp|null} [gte] TimestampRules gte
         * @property {boolean|null} [ltNow] TimestampRules ltNow
         * @property {boolean|null} [gtNow] TimestampRules gtNow
         * @property {google.protobuf.IDuration|null} [within] TimestampRules within
         */

        /**
         * Constructs a new TimestampRules.
         * @memberof validate
         * @classdesc Represents a TimestampRules.
         * @implements ITimestampRules
         * @constructor
         * @param {validate.ITimestampRules=} [properties] Properties to set
         */
        function TimestampRules(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimestampRules required.
         * @member {boolean} required
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype.required = false;

        /**
         * TimestampRules const.
         * @member {google.protobuf.ITimestamp|null|undefined} const
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype["const"] = null;

        /**
         * TimestampRules lt.
         * @member {google.protobuf.ITimestamp|null|undefined} lt
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype.lt = null;

        /**
         * TimestampRules lte.
         * @member {google.protobuf.ITimestamp|null|undefined} lte
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype.lte = null;

        /**
         * TimestampRules gt.
         * @member {google.protobuf.ITimestamp|null|undefined} gt
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype.gt = null;

        /**
         * TimestampRules gte.
         * @member {google.protobuf.ITimestamp|null|undefined} gte
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype.gte = null;

        /**
         * TimestampRules ltNow.
         * @member {boolean} ltNow
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype.ltNow = false;

        /**
         * TimestampRules gtNow.
         * @member {boolean} gtNow
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype.gtNow = false;

        /**
         * TimestampRules within.
         * @member {google.protobuf.IDuration|null|undefined} within
         * @memberof validate.TimestampRules
         * @instance
         */
        TimestampRules.prototype.within = null;

        /**
         * Creates a new TimestampRules instance using the specified properties.
         * @function create
         * @memberof validate.TimestampRules
         * @static
         * @param {validate.ITimestampRules=} [properties] Properties to set
         * @returns {validate.TimestampRules} TimestampRules instance
         */
        TimestampRules.create = function create(properties) {
            return new TimestampRules(properties);
        };

        /**
         * Encodes the specified TimestampRules message. Does not implicitly {@link validate.TimestampRules.verify|verify} messages.
         * @function encode
         * @memberof validate.TimestampRules
         * @static
         * @param {validate.ITimestampRules} message TimestampRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimestampRules.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.required);
            if (message["const"] != null && Object.hasOwnProperty.call(message, "const"))
                $root.google.protobuf.Timestamp.encode(message["const"], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.lt != null && Object.hasOwnProperty.call(message, "lt"))
                $root.google.protobuf.Timestamp.encode(message.lt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.lte != null && Object.hasOwnProperty.call(message, "lte"))
                $root.google.protobuf.Timestamp.encode(message.lte, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.gt != null && Object.hasOwnProperty.call(message, "gt"))
                $root.google.protobuf.Timestamp.encode(message.gt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.gte != null && Object.hasOwnProperty.call(message, "gte"))
                $root.google.protobuf.Timestamp.encode(message.gte, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.ltNow != null && Object.hasOwnProperty.call(message, "ltNow"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.ltNow);
            if (message.gtNow != null && Object.hasOwnProperty.call(message, "gtNow"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.gtNow);
            if (message.within != null && Object.hasOwnProperty.call(message, "within"))
                $root.google.protobuf.Duration.encode(message.within, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TimestampRules message, length delimited. Does not implicitly {@link validate.TimestampRules.verify|verify} messages.
         * @function encodeDelimited
         * @memberof validate.TimestampRules
         * @static
         * @param {validate.ITimestampRules} message TimestampRules message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimestampRules.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimestampRules message from the specified reader or buffer.
         * @function decode
         * @memberof validate.TimestampRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {validate.TimestampRules} TimestampRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimestampRules.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.validate.TimestampRules();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.required = reader.bool();
                        break;
                    }
                case 2: {
                        message["const"] = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.lt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.lte = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.gt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.gte = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.ltNow = reader.bool();
                        break;
                    }
                case 8: {
                        message.gtNow = reader.bool();
                        break;
                    }
                case 9: {
                        message.within = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimestampRules message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof validate.TimestampRules
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {validate.TimestampRules} TimestampRules
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimestampRules.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimestampRules message.
         * @function verify
         * @memberof validate.TimestampRules
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimestampRules.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.required != null && message.hasOwnProperty("required"))
                if (typeof message.required !== "boolean")
                    return "required: boolean expected";
            if (message["const"] != null && message.hasOwnProperty("const")) {
                let error = $root.google.protobuf.Timestamp.verify(message["const"]);
                if (error)
                    return "const." + error;
            }
            if (message.lt != null && message.hasOwnProperty("lt")) {
                let error = $root.google.protobuf.Timestamp.verify(message.lt);
                if (error)
                    return "lt." + error;
            }
            if (message.lte != null && message.hasOwnProperty("lte")) {
                let error = $root.google.protobuf.Timestamp.verify(message.lte);
                if (error)
                    return "lte." + error;
            }
            if (message.gt != null && message.hasOwnProperty("gt")) {
                let error = $root.google.protobuf.Timestamp.verify(message.gt);
                if (error)
                    return "gt." + error;
            }
            if (message.gte != null && message.hasOwnProperty("gte")) {
                let error = $root.google.protobuf.Timestamp.verify(message.gte);
                if (error)
                    return "gte." + error;
            }
            if (message.ltNow != null && message.hasOwnProperty("ltNow"))
                if (typeof message.ltNow !== "boolean")
                    return "ltNow: boolean expected";
            if (message.gtNow != null && message.hasOwnProperty("gtNow"))
                if (typeof message.gtNow !== "boolean")
                    return "gtNow: boolean expected";
            if (message.within != null && message.hasOwnProperty("within")) {
                let error = $root.google.protobuf.Duration.verify(message.within);
                if (error)
                    return "within." + error;
            }
            return null;
        };

        /**
         * Creates a TimestampRules message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof validate.TimestampRules
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {validate.TimestampRules} TimestampRules
         */
        TimestampRules.fromObject = function fromObject(object) {
            if (object instanceof $root.validate.TimestampRules)
                return object;
            let message = new $root.validate.TimestampRules();
            if (object.required != null)
                message.required = Boolean(object.required);
            if (object["const"] != null) {
                if (typeof object["const"] !== "object")
                    throw TypeError(".validate.TimestampRules.const: object expected");
                message["const"] = $root.google.protobuf.Timestamp.fromObject(object["const"]);
            }
            if (object.lt != null) {
                if (typeof object.lt !== "object")
                    throw TypeError(".validate.TimestampRules.lt: object expected");
                message.lt = $root.google.protobuf.Timestamp.fromObject(object.lt);
            }
            if (object.lte != null) {
                if (typeof object.lte !== "object")
                    throw TypeError(".validate.TimestampRules.lte: object expected");
                message.lte = $root.google.protobuf.Timestamp.fromObject(object.lte);
            }
            if (object.gt != null) {
                if (typeof object.gt !== "object")
                    throw TypeError(".validate.TimestampRules.gt: object expected");
                message.gt = $root.google.protobuf.Timestamp.fromObject(object.gt);
            }
            if (object.gte != null) {
                if (typeof object.gte !== "object")
                    throw TypeError(".validate.TimestampRules.gte: object expected");
                message.gte = $root.google.protobuf.Timestamp.fromObject(object.gte);
            }
            if (object.ltNow != null)
                message.ltNow = Boolean(object.ltNow);
            if (object.gtNow != null)
                message.gtNow = Boolean(object.gtNow);
            if (object.within != null) {
                if (typeof object.within !== "object")
                    throw TypeError(".validate.TimestampRules.within: object expected");
                message.within = $root.google.protobuf.Duration.fromObject(object.within);
            }
            return message;
        };

        /**
         * Creates a plain object from a TimestampRules message. Also converts values to other types if specified.
         * @function toObject
         * @memberof validate.TimestampRules
         * @static
         * @param {validate.TimestampRules} message TimestampRules
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimestampRules.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.required = false;
                object["const"] = null;
                object.lt = null;
                object.lte = null;
                object.gt = null;
                object.gte = null;
                object.ltNow = false;
                object.gtNow = false;
                object.within = null;
            }
            if (message.required != null && message.hasOwnProperty("required"))
                object.required = message.required;
            if (message["const"] != null && message.hasOwnProperty("const"))
                object["const"] = $root.google.protobuf.Timestamp.toObject(message["const"], options);
            if (message.lt != null && message.hasOwnProperty("lt"))
                object.lt = $root.google.protobuf.Timestamp.toObject(message.lt, options);
            if (message.lte != null && message.hasOwnProperty("lte"))
                object.lte = $root.google.protobuf.Timestamp.toObject(message.lte, options);
            if (message.gt != null && message.hasOwnProperty("gt"))
                object.gt = $root.google.protobuf.Timestamp.toObject(message.gt, options);
            if (message.gte != null && message.hasOwnProperty("gte"))
                object.gte = $root.google.protobuf.Timestamp.toObject(message.gte, options);
            if (message.ltNow != null && message.hasOwnProperty("ltNow"))
                object.ltNow = message.ltNow;
            if (message.gtNow != null && message.hasOwnProperty("gtNow"))
                object.gtNow = message.gtNow;
            if (message.within != null && message.hasOwnProperty("within"))
                object.within = $root.google.protobuf.Duration.toObject(message.within, options);
            return object;
        };

        /**
         * Converts this TimestampRules to JSON.
         * @function toJSON
         * @memberof validate.TimestampRules
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimestampRules.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TimestampRules
         * @function getTypeUrl
         * @memberof validate.TimestampRules
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TimestampRules.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/validate.TimestampRules";
        };

        return TimestampRules;
    })();

    return validate;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file != null && message.file.length)
                    for (let i = 0; i < message.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FileDescriptorSet message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.file && message.file.length))
                                message.file = [];
                            message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileDescriptorSet message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (!Array.isArray(message.file))
                        return "file: array expected";
                    for (let i = 0; i < message.file.length; ++i) {
                        let error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                        if (error)
                            return "file." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorSet)
                    return object;
                let message = new $root.google.protobuf.FileDescriptorSet();
                if (object.file) {
                    if (!Array.isArray(object.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    message.file = [];
                    for (let i = 0; i < object.file.length; ++i) {
                        if (typeof object.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.file = [];
                if (message.file && message.file.length) {
                    object.file = [];
                    for (let j = 0; j < message.file.length; ++j)
                        object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                }
                return object;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileDescriptorSet
             * @function getTypeUrl
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileDescriptorSet.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileDescriptorSet";
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                if (message.dependency != null && message.dependency.length)
                    for (let i = 0; i < message.dependency.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                if (message.messageType != null && message.messageType.length)
                    for (let i = 0; i < message.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (let i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.service != null && message.service.length)
                    for (let i = 0; i < message.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (let i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.publicDependency != null && message.publicDependency.length)
                    for (let i = 0; i < message.publicDependency.length; ++i)
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                if (message.weakDependency != null && message.weakDependency.length)
                    for (let i = 0; i < message.weakDependency.length; ++i)
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                return writer;
            };

            /**
             * Encodes the specified FileDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message["package"] = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.dependency && message.dependency.length))
                                message.dependency = [];
                            message.dependency.push(reader.string());
                            break;
                        }
                    case 10: {
                            if (!(message.publicDependency && message.publicDependency.length))
                                message.publicDependency = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.publicDependency.push(reader.int32());
                            } else
                                message.publicDependency.push(reader.int32());
                            break;
                        }
                    case 11: {
                            if (!(message.weakDependency && message.weakDependency.length))
                                message.weakDependency = [];
                            if ((tag & 7) === 2) {
                                let end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.weakDependency.push(reader.int32());
                            } else
                                message.weakDependency.push(reader.int32());
                            break;
                        }
                    case 4: {
                            if (!(message.messageType && message.messageType.length))
                                message.messageType = [];
                            message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.service && message.service.length))
                                message.service = [];
                            message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.syntax = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message["package"] != null && message.hasOwnProperty("package"))
                    if (!$util.isString(message["package"]))
                        return "package: string expected";
                if (message.dependency != null && message.hasOwnProperty("dependency")) {
                    if (!Array.isArray(message.dependency))
                        return "dependency: array expected";
                    for (let i = 0; i < message.dependency.length; ++i)
                        if (!$util.isString(message.dependency[i]))
                            return "dependency: string[] expected";
                }
                if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                    if (!Array.isArray(message.publicDependency))
                        return "publicDependency: array expected";
                    for (let i = 0; i < message.publicDependency.length; ++i)
                        if (!$util.isInteger(message.publicDependency[i]))
                            return "publicDependency: integer[] expected";
                }
                if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                    if (!Array.isArray(message.weakDependency))
                        return "weakDependency: array expected";
                    for (let i = 0; i < message.weakDependency.length; ++i)
                        if (!$util.isInteger(message.weakDependency[i]))
                            return "weakDependency: integer[] expected";
                }
                if (message.messageType != null && message.hasOwnProperty("messageType")) {
                    if (!Array.isArray(message.messageType))
                        return "messageType: array expected";
                    for (let i = 0; i < message.messageType.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                        if (error)
                            return "messageType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (let i = 0; i < message.enumType.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.service != null && message.hasOwnProperty("service")) {
                    if (!Array.isArray(message.service))
                        return "service: array expected";
                    for (let i = 0; i < message.service.length; ++i) {
                        let error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                        if (error)
                            return "service." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (let i = 0; i < message.extension.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.FileOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                    let error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                    if (error)
                        return "sourceCodeInfo." + error;
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    if (!$util.isString(message.syntax))
                        return "syntax: string expected";
                return null;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.FileDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object["package"] != null)
                    message["package"] = String(object["package"]);
                if (object.dependency) {
                    if (!Array.isArray(object.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    message.dependency = [];
                    for (let i = 0; i < object.dependency.length; ++i)
                        message.dependency[i] = String(object.dependency[i]);
                }
                if (object.publicDependency) {
                    if (!Array.isArray(object.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    message.publicDependency = [];
                    for (let i = 0; i < object.publicDependency.length; ++i)
                        message.publicDependency[i] = object.publicDependency[i] | 0;
                }
                if (object.weakDependency) {
                    if (!Array.isArray(object.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    message.weakDependency = [];
                    for (let i = 0; i < object.weakDependency.length; ++i)
                        message.weakDependency[i] = object.weakDependency[i] | 0;
                }
                if (object.messageType) {
                    if (!Array.isArray(object.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    message.messageType = [];
                    for (let i = 0; i < object.messageType.length; ++i) {
                        if (typeof object.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (let i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.service) {
                    if (!Array.isArray(object.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    message.service = [];
                    for (let i = 0; i < object.service.length; ++i) {
                        if (typeof object.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    message.extension = [];
                    for (let i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                }
                if (object.sourceCodeInfo != null) {
                    if (typeof object.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                }
                if (object.syntax != null)
                    message.syntax = String(object.syntax);
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.dependency = [];
                    object.messageType = [];
                    object.enumType = [];
                    object.service = [];
                    object.extension = [];
                    object.publicDependency = [];
                    object.weakDependency = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object["package"] = "";
                    object.options = null;
                    object.sourceCodeInfo = null;
                    object.syntax = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message["package"] != null && message.hasOwnProperty("package"))
                    object["package"] = message["package"];
                if (message.dependency && message.dependency.length) {
                    object.dependency = [];
                    for (let j = 0; j < message.dependency.length; ++j)
                        object.dependency[j] = message.dependency[j];
                }
                if (message.messageType && message.messageType.length) {
                    object.messageType = [];
                    for (let j = 0; j < message.messageType.length; ++j)
                        object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (let j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.service && message.service.length) {
                    object.service = [];
                    for (let j = 0; j < message.service.length; ++j)
                        object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (let j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                    object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                if (message.publicDependency && message.publicDependency.length) {
                    object.publicDependency = [];
                    for (let j = 0; j < message.publicDependency.length; ++j)
                        object.publicDependency[j] = message.publicDependency[j];
                }
                if (message.weakDependency && message.weakDependency.length) {
                    object.weakDependency = [];
                    for (let j = 0; j < message.weakDependency.length; ++j)
                        object.weakDependency[j] = message.weakDependency[j];
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    object.syntax = message.syntax;
                return object;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileDescriptorProto";
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.field != null && message.field.length)
                    for (let i = 0; i < message.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.nestedType != null && message.nestedType.length)
                    for (let i = 0; i < message.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (let i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.extensionRange != null && message.extensionRange.length)
                    for (let i = 0; i < message.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (let i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.oneofDecl != null && message.oneofDecl.length)
                    for (let i = 0; i < message.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.reservedRange != null && message.reservedRange.length)
                    for (let i = 0; i < message.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.reservedName != null && message.reservedName.length)
                    for (let i = 0; i < message.reservedName.length; ++i)
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                return writer;
            };

            /**
             * Encodes the specified DescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.field && message.field.length))
                                message.field = [];
                            message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.extension && message.extension.length))
                                message.extension = [];
                            message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.nestedType && message.nestedType.length))
                                message.nestedType = [];
                            message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.enumType && message.enumType.length))
                                message.enumType = [];
                            message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.extensionRange && message.extensionRange.length))
                                message.extensionRange = [];
                            message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            if (!(message.oneofDecl && message.oneofDecl.length))
                                message.oneofDecl = [];
                            message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            if (!(message.reservedRange && message.reservedRange.length))
                                message.reservedRange = [];
                            message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                            break;
                        }
                    case 10: {
                            if (!(message.reservedName && message.reservedName.length))
                                message.reservedName = [];
                            message.reservedName.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DescriptorProto message.
             * @function verify
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.field != null && message.hasOwnProperty("field")) {
                    if (!Array.isArray(message.field))
                        return "field: array expected";
                    for (let i = 0; i < message.field.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                        if (error)
                            return "field." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (let i = 0; i < message.extension.length; ++i) {
                        let error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                    if (!Array.isArray(message.nestedType))
                        return "nestedType: array expected";
                    for (let i = 0; i < message.nestedType.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                        if (error)
                            return "nestedType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (let i = 0; i < message.enumType.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                    if (!Array.isArray(message.extensionRange))
                        return "extensionRange: array expected";
                    for (let i = 0; i < message.extensionRange.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                        if (error)
                            return "extensionRange." + error;
                    }
                }
                if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                    if (!Array.isArray(message.oneofDecl))
                        return "oneofDecl: array expected";
                    for (let i = 0; i < message.oneofDecl.length; ++i) {
                        let error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                        if (error)
                            return "oneofDecl." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.MessageOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (let i = 0; i < message.reservedRange.length; ++i) {
                        let error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (let i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DescriptorProto)
                    return object;
                let message = new $root.google.protobuf.DescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.field) {
                    if (!Array.isArray(object.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    message.field = [];
                    for (let i = 0; i < object.field.length; ++i) {
                        if (typeof object.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    message.extension = [];
                    for (let i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.nestedType) {
                    if (!Array.isArray(object.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    message.nestedType = [];
                    for (let i = 0; i < object.nestedType.length; ++i) {
                        if (typeof object.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (let i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.extensionRange) {
                    if (!Array.isArray(object.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    message.extensionRange = [];
                    for (let i = 0; i < object.extensionRange.length; ++i) {
                        if (typeof object.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                    }
                }
                if (object.oneofDecl) {
                    if (!Array.isArray(object.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    message.oneofDecl = [];
                    for (let i = 0; i < object.oneofDecl.length; ++i) {
                        if (typeof object.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (let i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (let i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} message DescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.field = [];
                    object.nestedType = [];
                    object.enumType = [];
                    object.extensionRange = [];
                    object.extension = [];
                    object.oneofDecl = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.field && message.field.length) {
                    object.field = [];
                    for (let j = 0; j < message.field.length; ++j)
                        object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                }
                if (message.nestedType && message.nestedType.length) {
                    object.nestedType = [];
                    for (let j = 0; j < message.nestedType.length; ++j)
                        object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (let j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.extensionRange && message.extensionRange.length) {
                    object.extensionRange = [];
                    for (let j = 0; j < message.extensionRange.length; ++j)
                        object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (let j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                if (message.oneofDecl && message.oneofDecl.length) {
                    object.oneofDecl = [];
                    for (let j = 0; j < message.oneofDecl.length; ++j)
                        object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                }
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (let j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (let j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DescriptorProto";
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 * @property {google.protobuf.IExtensionRangeOptions|null} [options] ExtensionRange options
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * ExtensionRange options.
                 * @member {google.protobuf.IExtensionRangeOptions|null|undefined} options
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.options = null;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                        $root.google.protobuf.ExtensionRangeOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ExtensionRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.int32();
                                break;
                            }
                        case 2: {
                                message.end = reader.int32();
                                break;
                            }
                        case 3: {
                                message.options = $root.google.protobuf.ExtensionRangeOptions.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ExtensionRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    if (message.options != null && message.hasOwnProperty("options")) {
                        let error = $root.google.protobuf.ExtensionRangeOptions.verify(message.options);
                        if (error)
                            return "options." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return object;
                    let message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    if (object.options != null) {
                        if (typeof object.options !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.ExtensionRange.options: object expected");
                        message.options = $root.google.protobuf.ExtensionRangeOptions.fromObject(object.options);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                        object.options = null;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    if (message.options != null && message.hasOwnProperty("options"))
                        object.options = $root.google.protobuf.ExtensionRangeOptions.toObject(message.options, options);
                    return object;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ExtensionRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ExtensionRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto.ExtensionRange";
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified ReservedRange message, length delimited. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.int32();
                                break;
                            }
                        case 2: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return object;
                    let message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ReservedRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ReservedRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.DescriptorProto.ReservedRange";
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.ExtensionRangeOptions = (function() {

            /**
             * Properties of an ExtensionRangeOptions.
             * @memberof google.protobuf
             * @interface IExtensionRangeOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ExtensionRangeOptions uninterpretedOption
             */

            /**
             * Constructs a new ExtensionRangeOptions.
             * @memberof google.protobuf
             * @classdesc Represents an ExtensionRangeOptions.
             * @implements IExtensionRangeOptions
             * @constructor
             * @param {google.protobuf.IExtensionRangeOptions=} [properties] Properties to set
             */
            function ExtensionRangeOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ExtensionRangeOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ExtensionRangeOptions
             * @instance
             */
            ExtensionRangeOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new ExtensionRangeOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {google.protobuf.IExtensionRangeOptions=} [properties] Properties to set
             * @returns {google.protobuf.ExtensionRangeOptions} ExtensionRangeOptions instance
             */
            ExtensionRangeOptions.create = function create(properties) {
                return new ExtensionRangeOptions(properties);
            };

            /**
             * Encodes the specified ExtensionRangeOptions message. Does not implicitly {@link google.protobuf.ExtensionRangeOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {google.protobuf.IExtensionRangeOptions} message ExtensionRangeOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExtensionRangeOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ExtensionRangeOptions message, length delimited. Does not implicitly {@link google.protobuf.ExtensionRangeOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {google.protobuf.IExtensionRangeOptions} message ExtensionRangeOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExtensionRangeOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ExtensionRangeOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ExtensionRangeOptions} ExtensionRangeOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExtensionRangeOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ExtensionRangeOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ExtensionRangeOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ExtensionRangeOptions} ExtensionRangeOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExtensionRangeOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ExtensionRangeOptions message.
             * @function verify
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExtensionRangeOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an ExtensionRangeOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ExtensionRangeOptions} ExtensionRangeOptions
             */
            ExtensionRangeOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ExtensionRangeOptions)
                    return object;
                let message = new $root.google.protobuf.ExtensionRangeOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ExtensionRangeOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ExtensionRangeOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an ExtensionRangeOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {google.protobuf.ExtensionRangeOptions} message ExtensionRangeOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExtensionRangeOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this ExtensionRangeOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ExtensionRangeOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExtensionRangeOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ExtensionRangeOptions
             * @function getTypeUrl
             * @memberof google.protobuf.ExtensionRangeOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ExtensionRangeOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ExtensionRangeOptions";
            };

            return ExtensionRangeOptions;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             * @property {boolean|null} [proto3Optional] FieldDescriptorProto proto3Optional
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * FieldDescriptorProto proto3Optional.
             * @member {boolean} proto3Optional
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.proto3Optional = false;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                if (message.proto3Optional != null && Object.hasOwnProperty.call(message, "proto3Optional"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.proto3Optional);
                return writer;
            };

            /**
             * Encodes the specified FieldDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.number = reader.int32();
                            break;
                        }
                    case 4: {
                            message.label = reader.int32();
                            break;
                        }
                    case 5: {
                            message.type = reader.int32();
                            break;
                        }
                    case 6: {
                            message.typeName = reader.string();
                            break;
                        }
                    case 2: {
                            message.extendee = reader.string();
                            break;
                        }
                    case 7: {
                            message.defaultValue = reader.string();
                            break;
                        }
                    case 9: {
                            message.oneofIndex = reader.int32();
                            break;
                        }
                    case 10: {
                            message.jsonName = reader.string();
                            break;
                        }
                    case 8: {
                            message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.proto3Optional = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    switch (message.label) {
                    default:
                        return "label: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    if (!$util.isString(message.typeName))
                        return "typeName: string expected";
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    if (!$util.isString(message.extendee))
                        return "extendee: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    if (!$util.isInteger(message.oneofIndex))
                        return "oneofIndex: integer expected";
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    if (!$util.isString(message.jsonName))
                        return "jsonName: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.FieldOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.proto3Optional != null && message.hasOwnProperty("proto3Optional"))
                    if (typeof message.proto3Optional !== "boolean")
                        return "proto3Optional: boolean expected";
                return null;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.FieldDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                switch (object.label) {
                default:
                    if (typeof object.label === "number") {
                        message.label = object.label;
                        break;
                    }
                    break;
                case "LABEL_OPTIONAL":
                case 1:
                    message.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    message.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    message.label = 3;
                    break;
                }
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "TYPE_DOUBLE":
                case 1:
                    message.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    message.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    message.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    message.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    message.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    message.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    message.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    message.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    message.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    message.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    message.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    message.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    message.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    message.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    message.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    message.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    message.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    message.type = 18;
                    break;
                }
                if (object.typeName != null)
                    message.typeName = String(object.typeName);
                if (object.extendee != null)
                    message.extendee = String(object.extendee);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.oneofIndex != null)
                    message.oneofIndex = object.oneofIndex | 0;
                if (object.jsonName != null)
                    message.jsonName = String(object.jsonName);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                }
                if (object.proto3Optional != null)
                    message.proto3Optional = Boolean(object.proto3Optional);
                return message;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.extendee = "";
                    object.number = 0;
                    object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                    object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                    object.typeName = "";
                    object.defaultValue = "";
                    object.options = null;
                    object.oneofIndex = 0;
                    object.jsonName = "";
                    object.proto3Optional = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    object.extendee = message.extendee;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] === undefined ? message.label : $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] === undefined ? message.type : $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    object.typeName = message.typeName;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    object.oneofIndex = message.oneofIndex;
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    object.jsonName = message.jsonName;
                if (message.proto3Optional != null && message.hasOwnProperty("proto3Optional"))
                    object.proto3Optional = message.proto3Optional;
                return object;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FieldDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FieldDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FieldDescriptorProto";
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OneofDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OneofDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.OneofOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.OneofDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OneofDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OneofDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.OneofDescriptorProto";
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             * @property {Array.<google.protobuf.EnumDescriptorProto.IEnumReservedRange>|null} [reservedRange] EnumDescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] EnumDescriptorProto reservedName
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * EnumDescriptorProto reservedRange.
             * @member {Array.<google.protobuf.EnumDescriptorProto.IEnumReservedRange>} reservedRange
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * EnumDescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && message.value.length)
                    for (let i = 0; i < message.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.reservedRange != null && message.reservedRange.length)
                    for (let i = 0; i < message.reservedRange.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.EnumReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.reservedName != null && message.reservedName.length)
                    for (let i = 0; i < message.reservedName.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.reservedName[i]);
                return writer;
            };

            /**
             * Encodes the specified EnumDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.value && message.value.length))
                                message.value = [];
                            message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            if (!(message.reservedRange && message.reservedRange.length))
                                message.reservedRange = [];
                            message.reservedRange.push($root.google.protobuf.EnumDescriptorProto.EnumReservedRange.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.reservedName && message.reservedName.length))
                                message.reservedName = [];
                            message.reservedName.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (let i = 0; i < message.value.length; ++i) {
                        let error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.EnumOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (let i = 0; i < message.reservedRange.length; ++i) {
                        let error = $root.google.protobuf.EnumDescriptorProto.EnumReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (let i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.EnumDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    message.value = [];
                    for (let i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (let i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.EnumDescriptorProto.EnumReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (let i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.value = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value && message.value.length) {
                    object.value = [];
                    for (let j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (let j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.EnumDescriptorProto.EnumReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (let j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumDescriptorProto";
            };

            EnumDescriptorProto.EnumReservedRange = (function() {

                /**
                 * Properties of an EnumReservedRange.
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @interface IEnumReservedRange
                 * @property {number|null} [start] EnumReservedRange start
                 * @property {number|null} [end] EnumReservedRange end
                 */

                /**
                 * Constructs a new EnumReservedRange.
                 * @memberof google.protobuf.EnumDescriptorProto
                 * @classdesc Represents an EnumReservedRange.
                 * @implements IEnumReservedRange
                 * @constructor
                 * @param {google.protobuf.EnumDescriptorProto.IEnumReservedRange=} [properties] Properties to set
                 */
                function EnumReservedRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnumReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @instance
                 */
                EnumReservedRange.prototype.start = 0;

                /**
                 * EnumReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @instance
                 */
                EnumReservedRange.prototype.end = 0;

                /**
                 * Creates a new EnumReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {google.protobuf.EnumDescriptorProto.IEnumReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.EnumDescriptorProto.EnumReservedRange} EnumReservedRange instance
                 */
                EnumReservedRange.create = function create(properties) {
                    return new EnumReservedRange(properties);
                };

                /**
                 * Encodes the specified EnumReservedRange message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.EnumReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {google.protobuf.EnumDescriptorProto.IEnumReservedRange} message EnumReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumReservedRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified EnumReservedRange message, length delimited. Does not implicitly {@link google.protobuf.EnumDescriptorProto.EnumReservedRange.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {google.protobuf.EnumDescriptorProto.IEnumReservedRange} message EnumReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnumReservedRange.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EnumReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.EnumDescriptorProto.EnumReservedRange} EnumReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumReservedRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto.EnumReservedRange();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.start = reader.int32();
                                break;
                            }
                        case 2: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EnumReservedRange message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.EnumDescriptorProto.EnumReservedRange} EnumReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnumReservedRange.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EnumReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnumReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an EnumReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.EnumDescriptorProto.EnumReservedRange} EnumReservedRange
                 */
                EnumReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.EnumDescriptorProto.EnumReservedRange)
                        return object;
                    let message = new $root.google.protobuf.EnumDescriptorProto.EnumReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an EnumReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {google.protobuf.EnumDescriptorProto.EnumReservedRange} message EnumReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnumReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this EnumReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnumReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EnumReservedRange
                 * @function getTypeUrl
                 * @memberof google.protobuf.EnumDescriptorProto.EnumReservedRange
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnumReservedRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.EnumDescriptorProto.EnumReservedRange";
                };

                return EnumReservedRange;
            })();

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.number = reader.int32();
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumValueDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.EnumValueDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.number = 0;
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumValueDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumValueDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumValueDescriptorProto";
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.method != null && message.method.length)
                    for (let i = 0; i < message.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ServiceDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.method && message.method.length))
                                message.method = [];
                            message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.method != null && message.hasOwnProperty("method")) {
                    if (!Array.isArray(message.method))
                        return "method: array expected";
                    for (let i = 0; i < message.method.length; ++i) {
                        let error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                        if (error)
                            return "method." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.ServiceOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.ServiceDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.method) {
                    if (!Array.isArray(object.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    message.method = [];
                    for (let i = 0; i < object.method.length; ++i) {
                        if (typeof object.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.method = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.method && message.method.length) {
                    object.method = [];
                    for (let j = 0; j < message.method.length; ++j)
                        object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ServiceDescriptorProto";
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                return writer;
            };

            /**
             * Encodes the specified MethodDescriptorProto message, length delimited. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.inputType = reader.string();
                            break;
                        }
                    case 3: {
                            message.outputType = reader.string();
                            break;
                        }
                    case 4: {
                            message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.clientStreaming = reader.bool();
                            break;
                        }
                    case 6: {
                            message.serverStreaming = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MethodDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    if (!$util.isString(message.inputType))
                        return "inputType: string expected";
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    if (!$util.isString(message.outputType))
                        return "outputType: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    let error = $root.google.protobuf.MethodOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    if (typeof message.clientStreaming !== "boolean")
                        return "clientStreaming: boolean expected";
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    if (typeof message.serverStreaming !== "boolean")
                        return "serverStreaming: boolean expected";
                return null;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                    return object;
                let message = new $root.google.protobuf.MethodDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inputType != null)
                    message.inputType = String(object.inputType);
                if (object.outputType != null)
                    message.outputType = String(object.outputType);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                }
                if (object.clientStreaming != null)
                    message.clientStreaming = Boolean(object.clientStreaming);
                if (object.serverStreaming != null)
                    message.serverStreaming = Boolean(object.serverStreaming);
                return message;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inputType = "";
                    object.outputType = "";
                    object.options = null;
                    object.clientStreaming = false;
                    object.serverStreaming = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    object.inputType = message.inputType;
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    object.outputType = message.outputType;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    object.clientStreaming = message.clientStreaming;
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    object.serverStreaming = message.serverStreaming;
                return object;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MethodDescriptorProto
             * @function getTypeUrl
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MethodDescriptorProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MethodDescriptorProto";
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [phpGenericServices] FileOptions phpGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {string|null} [swiftPrefix] FileOptions swiftPrefix
             * @property {string|null} [phpClassPrefix] FileOptions phpClassPrefix
             * @property {string|null} [phpNamespace] FileOptions phpNamespace
             * @property {string|null} [phpMetadataNamespace] FileOptions phpMetadataNamespace
             * @property {string|null} [rubyPackage] FileOptions rubyPackage
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             * @property {api.IInfoOptions|null} [".api.info"] FileOptions .api.info
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions phpGenericServices.
             * @member {boolean} phpGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.phpGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = true;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions swiftPrefix.
             * @member {string} swiftPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.swiftPrefix = "";

            /**
             * FileOptions phpClassPrefix.
             * @member {string} phpClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.phpClassPrefix = "";

            /**
             * FileOptions phpNamespace.
             * @member {string} phpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.phpNamespace = "";

            /**
             * FileOptions phpMetadataNamespace.
             * @member {string} phpMetadataNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.phpMetadataNamespace = "";

            /**
             * FileOptions rubyPackage.
             * @member {string} rubyPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.rubyPackage = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FileOptions .api.info.
             * @member {api.IInfoOptions|null|undefined} .api.info
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype[".api.info"] = null;

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                    writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                    writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                if (message.swiftPrefix != null && Object.hasOwnProperty.call(message, "swiftPrefix"))
                    writer.uint32(/* id 39, wireType 2 =*/314).string(message.swiftPrefix);
                if (message.phpClassPrefix != null && Object.hasOwnProperty.call(message, "phpClassPrefix"))
                    writer.uint32(/* id 40, wireType 2 =*/322).string(message.phpClassPrefix);
                if (message.phpNamespace != null && Object.hasOwnProperty.call(message, "phpNamespace"))
                    writer.uint32(/* id 41, wireType 2 =*/330).string(message.phpNamespace);
                if (message.phpGenericServices != null && Object.hasOwnProperty.call(message, "phpGenericServices"))
                    writer.uint32(/* id 42, wireType 0 =*/336).bool(message.phpGenericServices);
                if (message.phpMetadataNamespace != null && Object.hasOwnProperty.call(message, "phpMetadataNamespace"))
                    writer.uint32(/* id 44, wireType 2 =*/354).string(message.phpMetadataNamespace);
                if (message.rubyPackage != null && Object.hasOwnProperty.call(message, "rubyPackage"))
                    writer.uint32(/* id 45, wireType 2 =*/362).string(message.rubyPackage);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".api.info"] != null && Object.hasOwnProperty.call(message, ".api.info"))
                    $root.api.InfoOptions.encode(message[".api.info"], writer.uint32(/* id 52199, wireType 2 =*/417594).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FileOptions message, length delimited. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.javaPackage = reader.string();
                            break;
                        }
                    case 8: {
                            message.javaOuterClassname = reader.string();
                            break;
                        }
                    case 10: {
                            message.javaMultipleFiles = reader.bool();
                            break;
                        }
                    case 20: {
                            message.javaGenerateEqualsAndHash = reader.bool();
                            break;
                        }
                    case 27: {
                            message.javaStringCheckUtf8 = reader.bool();
                            break;
                        }
                    case 9: {
                            message.optimizeFor = reader.int32();
                            break;
                        }
                    case 11: {
                            message.goPackage = reader.string();
                            break;
                        }
                    case 16: {
                            message.ccGenericServices = reader.bool();
                            break;
                        }
                    case 17: {
                            message.javaGenericServices = reader.bool();
                            break;
                        }
                    case 18: {
                            message.pyGenericServices = reader.bool();
                            break;
                        }
                    case 42: {
                            message.phpGenericServices = reader.bool();
                            break;
                        }
                    case 23: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 31: {
                            message.ccEnableArenas = reader.bool();
                            break;
                        }
                    case 36: {
                            message.objcClassPrefix = reader.string();
                            break;
                        }
                    case 37: {
                            message.csharpNamespace = reader.string();
                            break;
                        }
                    case 39: {
                            message.swiftPrefix = reader.string();
                            break;
                        }
                    case 40: {
                            message.phpClassPrefix = reader.string();
                            break;
                        }
                    case 41: {
                            message.phpNamespace = reader.string();
                            break;
                        }
                    case 44: {
                            message.phpMetadataNamespace = reader.string();
                            break;
                        }
                    case 45: {
                            message.rubyPackage = reader.string();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 52199: {
                            message[".api.info"] = $root.api.InfoOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FileOptions message.
             * @function verify
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    if (!$util.isString(message.javaPackage))
                        return "javaPackage: string expected";
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    if (!$util.isString(message.javaOuterClassname))
                        return "javaOuterClassname: string expected";
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    if (typeof message.javaMultipleFiles !== "boolean")
                        return "javaMultipleFiles: boolean expected";
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                        return "javaGenerateEqualsAndHash: boolean expected";
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    if (typeof message.javaStringCheckUtf8 !== "boolean")
                        return "javaStringCheckUtf8: boolean expected";
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    switch (message.optimizeFor) {
                    default:
                        return "optimizeFor: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    if (!$util.isString(message.goPackage))
                        return "goPackage: string expected";
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    if (typeof message.ccGenericServices !== "boolean")
                        return "ccGenericServices: boolean expected";
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    if (typeof message.javaGenericServices !== "boolean")
                        return "javaGenericServices: boolean expected";
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    if (typeof message.pyGenericServices !== "boolean")
                        return "pyGenericServices: boolean expected";
                if (message.phpGenericServices != null && message.hasOwnProperty("phpGenericServices"))
                    if (typeof message.phpGenericServices !== "boolean")
                        return "phpGenericServices: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    if (typeof message.ccEnableArenas !== "boolean")
                        return "ccEnableArenas: boolean expected";
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    if (!$util.isString(message.objcClassPrefix))
                        return "objcClassPrefix: string expected";
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    if (!$util.isString(message.csharpNamespace))
                        return "csharpNamespace: string expected";
                if (message.swiftPrefix != null && message.hasOwnProperty("swiftPrefix"))
                    if (!$util.isString(message.swiftPrefix))
                        return "swiftPrefix: string expected";
                if (message.phpClassPrefix != null && message.hasOwnProperty("phpClassPrefix"))
                    if (!$util.isString(message.phpClassPrefix))
                        return "phpClassPrefix: string expected";
                if (message.phpNamespace != null && message.hasOwnProperty("phpNamespace"))
                    if (!$util.isString(message.phpNamespace))
                        return "phpNamespace: string expected";
                if (message.phpMetadataNamespace != null && message.hasOwnProperty("phpMetadataNamespace"))
                    if (!$util.isString(message.phpMetadataNamespace))
                        return "phpMetadataNamespace: string expected";
                if (message.rubyPackage != null && message.hasOwnProperty("rubyPackage"))
                    if (!$util.isString(message.rubyPackage))
                        return "rubyPackage: string expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".api.info"] != null && message.hasOwnProperty(".api.info")) {
                    let error = $root.api.InfoOptions.verify(message[".api.info"]);
                    if (error)
                        return ".api.info." + error;
                }
                return null;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileOptions)
                    return object;
                let message = new $root.google.protobuf.FileOptions();
                if (object.javaPackage != null)
                    message.javaPackage = String(object.javaPackage);
                if (object.javaOuterClassname != null)
                    message.javaOuterClassname = String(object.javaOuterClassname);
                if (object.javaMultipleFiles != null)
                    message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                if (object.javaGenerateEqualsAndHash != null)
                    message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                if (object.javaStringCheckUtf8 != null)
                    message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                switch (object.optimizeFor) {
                default:
                    if (typeof object.optimizeFor === "number") {
                        message.optimizeFor = object.optimizeFor;
                        break;
                    }
                    break;
                case "SPEED":
                case 1:
                    message.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    message.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    message.optimizeFor = 3;
                    break;
                }
                if (object.goPackage != null)
                    message.goPackage = String(object.goPackage);
                if (object.ccGenericServices != null)
                    message.ccGenericServices = Boolean(object.ccGenericServices);
                if (object.javaGenericServices != null)
                    message.javaGenericServices = Boolean(object.javaGenericServices);
                if (object.pyGenericServices != null)
                    message.pyGenericServices = Boolean(object.pyGenericServices);
                if (object.phpGenericServices != null)
                    message.phpGenericServices = Boolean(object.phpGenericServices);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.ccEnableArenas != null)
                    message.ccEnableArenas = Boolean(object.ccEnableArenas);
                if (object.objcClassPrefix != null)
                    message.objcClassPrefix = String(object.objcClassPrefix);
                if (object.csharpNamespace != null)
                    message.csharpNamespace = String(object.csharpNamespace);
                if (object.swiftPrefix != null)
                    message.swiftPrefix = String(object.swiftPrefix);
                if (object.phpClassPrefix != null)
                    message.phpClassPrefix = String(object.phpClassPrefix);
                if (object.phpNamespace != null)
                    message.phpNamespace = String(object.phpNamespace);
                if (object.phpMetadataNamespace != null)
                    message.phpMetadataNamespace = String(object.phpMetadataNamespace);
                if (object.rubyPackage != null)
                    message.rubyPackage = String(object.rubyPackage);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".api.info"] != null) {
                    if (typeof object[".api.info"] !== "object")
                        throw TypeError(".google.protobuf.FileOptions..api.info: object expected");
                    message[".api.info"] = $root.api.InfoOptions.fromObject(object[".api.info"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} message FileOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.javaPackage = "";
                    object.javaOuterClassname = "";
                    object.optimizeFor = options.enums === String ? "SPEED" : 1;
                    object.javaMultipleFiles = false;
                    object.goPackage = "";
                    object.ccGenericServices = false;
                    object.javaGenericServices = false;
                    object.pyGenericServices = false;
                    object.javaGenerateEqualsAndHash = false;
                    object.deprecated = false;
                    object.javaStringCheckUtf8 = false;
                    object.ccEnableArenas = true;
                    object.objcClassPrefix = "";
                    object.csharpNamespace = "";
                    object.swiftPrefix = "";
                    object.phpClassPrefix = "";
                    object.phpNamespace = "";
                    object.phpGenericServices = false;
                    object.phpMetadataNamespace = "";
                    object.rubyPackage = "";
                    object[".api.info"] = null;
                }
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    object.javaPackage = message.javaPackage;
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    object.javaOuterClassname = message.javaOuterClassname;
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] === undefined ? message.optimizeFor : $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    object.javaMultipleFiles = message.javaMultipleFiles;
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    object.goPackage = message.goPackage;
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    object.ccGenericServices = message.ccGenericServices;
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    object.javaGenericServices = message.javaGenericServices;
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    object.pyGenericServices = message.pyGenericServices;
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    object.ccEnableArenas = message.ccEnableArenas;
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    object.objcClassPrefix = message.objcClassPrefix;
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    object.csharpNamespace = message.csharpNamespace;
                if (message.swiftPrefix != null && message.hasOwnProperty("swiftPrefix"))
                    object.swiftPrefix = message.swiftPrefix;
                if (message.phpClassPrefix != null && message.hasOwnProperty("phpClassPrefix"))
                    object.phpClassPrefix = message.phpClassPrefix;
                if (message.phpNamespace != null && message.hasOwnProperty("phpNamespace"))
                    object.phpNamespace = message.phpNamespace;
                if (message.phpGenericServices != null && message.hasOwnProperty("phpGenericServices"))
                    object.phpGenericServices = message.phpGenericServices;
                if (message.phpMetadataNamespace != null && message.hasOwnProperty("phpMetadataNamespace"))
                    object.phpMetadataNamespace = message.phpMetadataNamespace;
                if (message.rubyPackage != null && message.hasOwnProperty("rubyPackage"))
                    object.rubyPackage = message.rubyPackage;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".api.info"] != null && message.hasOwnProperty(".api.info"))
                    object[".api.info"] = $root.api.InfoOptions.toObject(message[".api.info"], options);
                return object;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FileOptions
             * @function getTypeUrl
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FileOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FileOptions";
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             * @property {boolean|null} [".validate.disabled"] MessageOptions .validate.disabled
             * @property {boolean|null} [".validate.ignored"] MessageOptions .validate.ignored
             * @property {api.IMessageOptions|null} [".api.component"] MessageOptions .api.component
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MessageOptions .validate.disabled.
             * @member {boolean} .validate.disabled
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype[".validate.disabled"] = false;

            /**
             * MessageOptions .validate.ignored.
             * @member {boolean} .validate.ignored
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype[".validate.ignored"] = false;

            /**
             * MessageOptions .api.component.
             * @member {api.IMessageOptions|null|undefined} .api.component
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype[".api.component"] = null;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".validate.disabled"] != null && Object.hasOwnProperty.call(message, ".validate.disabled"))
                    writer.uint32(/* id 1071, wireType 0 =*/8568).bool(message[".validate.disabled"]);
                if (message[".validate.ignored"] != null && Object.hasOwnProperty.call(message, ".validate.ignored"))
                    writer.uint32(/* id 1072, wireType 0 =*/8576).bool(message[".validate.ignored"]);
                if (message[".api.component"] != null && Object.hasOwnProperty.call(message, ".api.component"))
                    $root.api.MessageOptions.encode(message[".api.component"], writer.uint32(/* id 52198, wireType 2 =*/417586).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MessageOptions message, length delimited. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.messageSetWireFormat = reader.bool();
                            break;
                        }
                    case 2: {
                            message.noStandardDescriptorAccessor = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 7: {
                            message.mapEntry = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1071: {
                            message[".validate.disabled"] = reader.bool();
                            break;
                        }
                    case 1072: {
                            message[".validate.ignored"] = reader.bool();
                            break;
                        }
                    case 52198: {
                            message[".api.component"] = $root.api.MessageOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MessageOptions message.
             * @function verify
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    if (typeof message.messageSetWireFormat !== "boolean")
                        return "messageSetWireFormat: boolean expected";
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    if (typeof message.noStandardDescriptorAccessor !== "boolean")
                        return "noStandardDescriptorAccessor: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    if (typeof message.mapEntry !== "boolean")
                        return "mapEntry: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".validate.disabled"] != null && message.hasOwnProperty(".validate.disabled"))
                    if (typeof message[".validate.disabled"] !== "boolean")
                        return ".validate.disabled: boolean expected";
                if (message[".validate.ignored"] != null && message.hasOwnProperty(".validate.ignored"))
                    if (typeof message[".validate.ignored"] !== "boolean")
                        return ".validate.ignored: boolean expected";
                if (message[".api.component"] != null && message.hasOwnProperty(".api.component")) {
                    let error = $root.api.MessageOptions.verify(message[".api.component"]);
                    if (error)
                        return ".api.component." + error;
                }
                return null;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MessageOptions)
                    return object;
                let message = new $root.google.protobuf.MessageOptions();
                if (object.messageSetWireFormat != null)
                    message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                if (object.noStandardDescriptorAccessor != null)
                    message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.mapEntry != null)
                    message.mapEntry = Boolean(object.mapEntry);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".validate.disabled"] != null)
                    message[".validate.disabled"] = Boolean(object[".validate.disabled"]);
                if (object[".validate.ignored"] != null)
                    message[".validate.ignored"] = Boolean(object[".validate.ignored"]);
                if (object[".api.component"] != null) {
                    if (typeof object[".api.component"] !== "object")
                        throw TypeError(".google.protobuf.MessageOptions..api.component: object expected");
                    message[".api.component"] = $root.api.MessageOptions.fromObject(object[".api.component"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} message MessageOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.messageSetWireFormat = false;
                    object.noStandardDescriptorAccessor = false;
                    object.deprecated = false;
                    object.mapEntry = false;
                    object[".validate.disabled"] = false;
                    object[".validate.ignored"] = false;
                    object[".api.component"] = null;
                }
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    object.messageSetWireFormat = message.messageSetWireFormat;
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    object.mapEntry = message.mapEntry;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".validate.disabled"] != null && message.hasOwnProperty(".validate.disabled"))
                    object[".validate.disabled"] = message[".validate.disabled"];
                if (message[".validate.ignored"] != null && message.hasOwnProperty(".validate.ignored"))
                    object[".validate.ignored"] = message[".validate.ignored"];
                if (message[".api.component"] != null && message.hasOwnProperty(".api.component"))
                    object[".api.component"] = $root.api.MessageOptions.toObject(message[".api.component"], options);
                return object;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MessageOptions
             * @function getTypeUrl
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MessageOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MessageOptions";
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [unverifiedLazy] FieldOptions unverifiedLazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {validate.IFieldRules|null} [".validate.rules"] FieldOptions .validate.rules
             * @property {api.IFieldOptions|null} [".api.schema"] FieldOptions .api.schema
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions unverifiedLazy.
             * @member {boolean} unverifiedLazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.unverifiedLazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .validate.rules.
             * @member {validate.IFieldRules|null|undefined} .validate.rules
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".validate.rules"] = null;

            /**
             * FieldOptions .api.schema.
             * @member {api.IFieldOptions|null|undefined} .api.schema
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".api.schema"] = null;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                if (message.unverifiedLazy != null && Object.hasOwnProperty.call(message, "unverifiedLazy"))
                    writer.uint32(/* id 15, wireType 0 =*/120).bool(message.unverifiedLazy);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".validate.rules"] != null && Object.hasOwnProperty.call(message, ".validate.rules"))
                    $root.validate.FieldRules.encode(message[".validate.rules"], writer.uint32(/* id 1071, wireType 2 =*/8570).fork()).ldelim();
                if (message[".api.schema"] != null && Object.hasOwnProperty.call(message, ".api.schema"))
                    $root.api.FieldOptions.encode(message[".api.schema"], writer.uint32(/* id 52199, wireType 2 =*/417594).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FieldOptions message, length delimited. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.ctype = reader.int32();
                            break;
                        }
                    case 2: {
                            message.packed = reader.bool();
                            break;
                        }
                    case 6: {
                            message.jstype = reader.int32();
                            break;
                        }
                    case 5: {
                            message.lazy = reader.bool();
                            break;
                        }
                    case 15: {
                            message.unverifiedLazy = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 10: {
                            message.weak = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1071: {
                            message[".validate.rules"] = $root.validate.FieldRules.decode(reader, reader.uint32());
                            break;
                        }
                    case 52199: {
                            message[".api.schema"] = $root.api.FieldOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldOptions message.
             * @function verify
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    switch (message.ctype) {
                    default:
                        return "ctype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.packed != null && message.hasOwnProperty("packed"))
                    if (typeof message.packed !== "boolean")
                        return "packed: boolean expected";
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    switch (message.jstype) {
                    default:
                        return "jstype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    if (typeof message.lazy !== "boolean")
                        return "lazy: boolean expected";
                if (message.unverifiedLazy != null && message.hasOwnProperty("unverifiedLazy"))
                    if (typeof message.unverifiedLazy !== "boolean")
                        return "unverifiedLazy: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.weak != null && message.hasOwnProperty("weak"))
                    if (typeof message.weak !== "boolean")
                        return "weak: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".validate.rules"] != null && message.hasOwnProperty(".validate.rules")) {
                    let error = $root.validate.FieldRules.verify(message[".validate.rules"]);
                    if (error)
                        return ".validate.rules." + error;
                }
                if (message[".api.schema"] != null && message.hasOwnProperty(".api.schema")) {
                    let error = $root.api.FieldOptions.verify(message[".api.schema"]);
                    if (error)
                        return ".api.schema." + error;
                }
                return null;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldOptions)
                    return object;
                let message = new $root.google.protobuf.FieldOptions();
                switch (object.ctype) {
                default:
                    if (typeof object.ctype === "number") {
                        message.ctype = object.ctype;
                        break;
                    }
                    break;
                case "STRING":
                case 0:
                    message.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    message.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    message.ctype = 2;
                    break;
                }
                if (object.packed != null)
                    message.packed = Boolean(object.packed);
                switch (object.jstype) {
                default:
                    if (typeof object.jstype === "number") {
                        message.jstype = object.jstype;
                        break;
                    }
                    break;
                case "JS_NORMAL":
                case 0:
                    message.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    message.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    message.jstype = 2;
                    break;
                }
                if (object.lazy != null)
                    message.lazy = Boolean(object.lazy);
                if (object.unverifiedLazy != null)
                    message.unverifiedLazy = Boolean(object.unverifiedLazy);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.weak != null)
                    message.weak = Boolean(object.weak);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".validate.rules"] != null) {
                    if (typeof object[".validate.rules"] !== "object")
                        throw TypeError(".google.protobuf.FieldOptions..validate.rules: object expected");
                    message[".validate.rules"] = $root.validate.FieldRules.fromObject(object[".validate.rules"]);
                }
                if (object[".api.schema"] != null) {
                    if (typeof object[".api.schema"] !== "object")
                        throw TypeError(".google.protobuf.FieldOptions..api.schema: object expected");
                    message[".api.schema"] = $root.api.FieldOptions.fromObject(object[".api.schema"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} message FieldOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.ctype = options.enums === String ? "STRING" : 0;
                    object.packed = false;
                    object.deprecated = false;
                    object.lazy = false;
                    object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                    object.weak = false;
                    object.unverifiedLazy = false;
                    object[".validate.rules"] = null;
                    object[".api.schema"] = null;
                }
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] === undefined ? message.ctype : $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                if (message.packed != null && message.hasOwnProperty("packed"))
                    object.packed = message.packed;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    object.lazy = message.lazy;
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] === undefined ? message.jstype : $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                if (message.weak != null && message.hasOwnProperty("weak"))
                    object.weak = message.weak;
                if (message.unverifiedLazy != null && message.hasOwnProperty("unverifiedLazy"))
                    object.unverifiedLazy = message.unverifiedLazy;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".validate.rules"] != null && message.hasOwnProperty(".validate.rules"))
                    object[".validate.rules"] = $root.validate.FieldRules.toObject(message[".validate.rules"], options);
                if (message[".api.schema"] != null && message.hasOwnProperty(".api.schema"))
                    object[".api.schema"] = $root.api.FieldOptions.toObject(message[".api.schema"], options);
                return object;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FieldOptions
             * @function getTypeUrl
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FieldOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FieldOptions";
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             * @property {boolean|null} [".validate.required"] OneofOptions .validate.required
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * OneofOptions .validate.required.
             * @member {boolean} .validate.required
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype[".validate.required"] = false;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".validate.required"] != null && Object.hasOwnProperty.call(message, ".validate.required"))
                    writer.uint32(/* id 1071, wireType 0 =*/8568).bool(message[".validate.required"]);
                return writer;
            };

            /**
             * Encodes the specified OneofOptions message, length delimited. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 1071: {
                            message[".validate.required"] = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OneofOptions message.
             * @function verify
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".validate.required"] != null && message.hasOwnProperty(".validate.required"))
                    if (typeof message[".validate.required"] !== "boolean")
                        return ".validate.required: boolean expected";
                return null;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofOptions)
                    return object;
                let message = new $root.google.protobuf.OneofOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".validate.required"] != null)
                    message[".validate.required"] = Boolean(object[".validate.required"]);
                return message;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} message OneofOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object[".validate.required"] = false;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".validate.required"] != null && message.hasOwnProperty(".validate.required"))
                    object[".validate.required"] = message[".validate.required"];
                return object;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OneofOptions
             * @function getTypeUrl
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OneofOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.OneofOptions";
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.allowAlias = reader.bool();
                            break;
                        }
                    case 3: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumOptions message.
             * @function verify
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    if (typeof message.allowAlias !== "boolean")
                        return "allowAlias: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumOptions)
                    return object;
                let message = new $root.google.protobuf.EnumOptions();
                if (object.allowAlias != null)
                    message.allowAlias = Boolean(object.allowAlias);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} message EnumOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.allowAlias = false;
                    object.deprecated = false;
                }
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    object.allowAlias = message.allowAlias;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumOptions
             * @function getTypeUrl
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumOptions";
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EnumValueOptions message, length delimited. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnumValueOptions message.
             * @function verify
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueOptions)
                    return object;
                let message = new $root.google.protobuf.EnumValueOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnumValueOptions
             * @function getTypeUrl
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnumValueOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.EnumValueOptions";
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             * @property {api.IServiceOptions|null} [".api.service"] ServiceOptions .api.service
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * ServiceOptions .api.service.
             * @member {api.IServiceOptions|null|undefined} .api.service
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype[".api.service"] = null;

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".api.service"] != null && Object.hasOwnProperty.call(message, ".api.service"))
                    $root.api.ServiceOptions.encode(message[".api.service"], writer.uint32(/* id 52199, wireType 2 =*/417594).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ServiceOptions message, length delimited. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 52199: {
                            message[".api.service"] = $root.api.ServiceOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ServiceOptions message.
             * @function verify
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".api.service"] != null && message.hasOwnProperty(".api.service")) {
                    let error = $root.api.ServiceOptions.verify(message[".api.service"]);
                    if (error)
                        return ".api.service." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceOptions)
                    return object;
                let message = new $root.google.protobuf.ServiceOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".api.service"] != null) {
                    if (typeof object[".api.service"] !== "object")
                        throw TypeError(".google.protobuf.ServiceOptions..api.service: object expected");
                    message[".api.service"] = $root.api.ServiceOptions.fromObject(object[".api.service"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} message ServiceOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.deprecated = false;
                    object[".api.service"] = null;
                }
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".api.service"] != null && message.hasOwnProperty(".api.service"))
                    object[".api.service"] = $root.api.ServiceOptions.toObject(message[".api.service"], options);
                return object;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ServiceOptions
             * @function getTypeUrl
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ServiceOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.ServiceOptions";
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {google.protobuf.MethodOptions.IdempotencyLevel|null} [idempotencyLevel] MethodOptions idempotencyLevel
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             * @property {api.IMethodOptions|null} [".api.method"] MethodOptions .api.method
             * @property {api.IPubSubOptions|null} [".api.pubSub"] MethodOptions .api.pubSub
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions idempotencyLevel.
             * @member {google.protobuf.MethodOptions.IdempotencyLevel} idempotencyLevel
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.idempotencyLevel = 0;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * MethodOptions .api.method.
             * @member {api.IMethodOptions|null|undefined} .api.method
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".api.method"] = null;

            /**
             * MethodOptions .api.pubSub.
             * @member {api.IPubSubOptions|null|undefined} .api.pubSub
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype[".api.pubSub"] = null;

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.idempotencyLevel != null && Object.hasOwnProperty.call(message, "idempotencyLevel"))
                    writer.uint32(/* id 34, wireType 0 =*/272).int32(message.idempotencyLevel);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (let i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".api.method"] != null && Object.hasOwnProperty.call(message, ".api.method"))
                    $root.api.MethodOptions.encode(message[".api.method"], writer.uint32(/* id 52199, wireType 2 =*/417594).fork()).ldelim();
                if (message[".api.pubSub"] != null && Object.hasOwnProperty.call(message, ".api.pubSub"))
                    $root.api.PubSubOptions.encode(message[".api.pubSub"], writer.uint32(/* id 52200, wireType 2 =*/417602).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MethodOptions message, length delimited. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33: {
                            message.deprecated = reader.bool();
                            break;
                        }
                    case 34: {
                            message.idempotencyLevel = reader.int32();
                            break;
                        }
                    case 999: {
                            if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                                message.uninterpretedOption = [];
                            message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                            break;
                        }
                    case 52199: {
                            message[".api.method"] = $root.api.MethodOptions.decode(reader, reader.uint32());
                            break;
                        }
                    case 52200: {
                            message[".api.pubSub"] = $root.api.PubSubOptions.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MethodOptions message.
             * @function verify
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.idempotencyLevel != null && message.hasOwnProperty("idempotencyLevel"))
                    switch (message.idempotencyLevel) {
                    default:
                        return "idempotencyLevel: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (let i = 0; i < message.uninterpretedOption.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".api.method"] != null && message.hasOwnProperty(".api.method")) {
                    let error = $root.api.MethodOptions.verify(message[".api.method"]);
                    if (error)
                        return ".api.method." + error;
                }
                if (message[".api.pubSub"] != null && message.hasOwnProperty(".api.pubSub")) {
                    let error = $root.api.PubSubOptions.verify(message[".api.pubSub"]);
                    if (error)
                        return ".api.pubSub." + error;
                }
                return null;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodOptions)
                    return object;
                let message = new $root.google.protobuf.MethodOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                switch (object.idempotencyLevel) {
                default:
                    if (typeof object.idempotencyLevel === "number") {
                        message.idempotencyLevel = object.idempotencyLevel;
                        break;
                    }
                    break;
                case "IDEMPOTENCY_UNKNOWN":
                case 0:
                    message.idempotencyLevel = 0;
                    break;
                case "NO_SIDE_EFFECTS":
                case 1:
                    message.idempotencyLevel = 1;
                    break;
                case "IDEMPOTENT":
                case 2:
                    message.idempotencyLevel = 2;
                    break;
                }
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (let i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".api.method"] != null) {
                    if (typeof object[".api.method"] !== "object")
                        throw TypeError(".google.protobuf.MethodOptions..api.method: object expected");
                    message[".api.method"] = $root.api.MethodOptions.fromObject(object[".api.method"]);
                }
                if (object[".api.pubSub"] != null) {
                    if (typeof object[".api.pubSub"] !== "object")
                        throw TypeError(".google.protobuf.MethodOptions..api.pubSub: object expected");
                    message[".api.pubSub"] = $root.api.PubSubOptions.fromObject(object[".api.pubSub"]);
                }
                return message;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} message MethodOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.deprecated = false;
                    object.idempotencyLevel = options.enums === String ? "IDEMPOTENCY_UNKNOWN" : 0;
                    object[".api.method"] = null;
                    object[".api.pubSub"] = null;
                }
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.idempotencyLevel != null && message.hasOwnProperty("idempotencyLevel"))
                    object.idempotencyLevel = options.enums === String ? $root.google.protobuf.MethodOptions.IdempotencyLevel[message.idempotencyLevel] === undefined ? message.idempotencyLevel : $root.google.protobuf.MethodOptions.IdempotencyLevel[message.idempotencyLevel] : message.idempotencyLevel;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (let j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".api.method"] != null && message.hasOwnProperty(".api.method"))
                    object[".api.method"] = $root.api.MethodOptions.toObject(message[".api.method"], options);
                if (message[".api.pubSub"] != null && message.hasOwnProperty(".api.pubSub"))
                    object[".api.pubSub"] = $root.api.PubSubOptions.toObject(message[".api.pubSub"], options);
                return object;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MethodOptions
             * @function getTypeUrl
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MethodOptions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.MethodOptions";
            };

            /**
             * IdempotencyLevel enum.
             * @name google.protobuf.MethodOptions.IdempotencyLevel
             * @enum {number}
             * @property {number} IDEMPOTENCY_UNKNOWN=0 IDEMPOTENCY_UNKNOWN value
             * @property {number} NO_SIDE_EFFECTS=1 NO_SIDE_EFFECTS value
             * @property {number} IDEMPOTENT=2 IDEMPOTENT value
             */
            MethodOptions.IdempotencyLevel = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "IDEMPOTENCY_UNKNOWN"] = 0;
                values[valuesById[1] = "NO_SIDE_EFFECTS"] = 1;
                values[valuesById[2] = "IDEMPOTENT"] = 2;
                return values;
            })();

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && message.name.length)
                    for (let i = 0; i < message.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                return writer;
            };

            /**
             * Encodes the specified UninterpretedOption message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.name && message.name.length))
                                message.name = [];
                            message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.identifierValue = reader.string();
                            break;
                        }
                    case 4: {
                            message.positiveIntValue = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.negativeIntValue = reader.int64();
                            break;
                        }
                    case 6: {
                            message.doubleValue = reader.double();
                            break;
                        }
                    case 7: {
                            message.stringValue = reader.bytes();
                            break;
                        }
                    case 8: {
                            message.aggregateValue = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UninterpretedOption message.
             * @function verify
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UninterpretedOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    if (!Array.isArray(message.name))
                        return "name: array expected";
                    for (let i = 0; i < message.name.length; ++i) {
                        let error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                        if (error)
                            return "name." + error;
                    }
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    if (!$util.isString(message.identifierValue))
                        return "identifierValue: string expected";
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                        return "positiveIntValue: integer|Long expected";
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                        return "negativeIntValue: integer|Long expected";
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    if (typeof message.doubleValue !== "number")
                        return "doubleValue: number expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                        return "stringValue: buffer expected";
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    if (!$util.isString(message.aggregateValue))
                        return "aggregateValue: string expected";
                return null;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UninterpretedOption)
                    return object;
                let message = new $root.google.protobuf.UninterpretedOption();
                if (object.name) {
                    if (!Array.isArray(object.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    message.name = [];
                    for (let i = 0; i < object.name.length; ++i) {
                        if (typeof object.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                    }
                }
                if (object.identifierValue != null)
                    message.identifierValue = String(object.identifierValue);
                if (object.positiveIntValue != null)
                    if ($util.Long)
                        (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                    else if (typeof object.positiveIntValue === "string")
                        message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                    else if (typeof object.positiveIntValue === "number")
                        message.positiveIntValue = object.positiveIntValue;
                    else if (typeof object.positiveIntValue === "object")
                        message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                if (object.negativeIntValue != null)
                    if ($util.Long)
                        (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                    else if (typeof object.negativeIntValue === "string")
                        message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                    else if (typeof object.negativeIntValue === "number")
                        message.negativeIntValue = object.negativeIntValue;
                    else if (typeof object.negativeIntValue === "object")
                        message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                if (object.doubleValue != null)
                    message.doubleValue = Number(object.doubleValue);
                if (object.stringValue != null)
                    if (typeof object.stringValue === "string")
                        $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                    else if (object.stringValue.length >= 0)
                        message.stringValue = object.stringValue;
                if (object.aggregateValue != null)
                    message.aggregateValue = String(object.aggregateValue);
                return message;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.name = [];
                if (options.defaults) {
                    object.identifierValue = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positiveIntValue = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.negativeIntValue = options.longs === String ? "0" : 0;
                    object.doubleValue = 0;
                    if (options.bytes === String)
                        object.stringValue = "";
                    else {
                        object.stringValue = [];
                        if (options.bytes !== Array)
                            object.stringValue = $util.newBuffer(object.stringValue);
                    }
                    object.aggregateValue = "";
                }
                if (message.name && message.name.length) {
                    object.name = [];
                    for (let j = 0; j < message.name.length; ++j)
                        object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    object.identifierValue = message.identifierValue;
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (typeof message.positiveIntValue === "number")
                        object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                    else
                        object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (typeof message.negativeIntValue === "number")
                        object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                    else
                        object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    object.aggregateValue = message.aggregateValue;
                return object;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UninterpretedOption
             * @function getTypeUrl
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UninterpretedOption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UninterpretedOption";
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                    return writer;
                };

                /**
                 * Encodes the specified NamePart message, length delimited. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.namePart = reader.string();
                                break;
                            }
                        case 2: {
                                message.isExtension = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    if (!message.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                    if (!message.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                    return message;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NamePart message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NamePart.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (!$util.isString(message.namePart))
                        return "namePart: string expected";
                    if (typeof message.isExtension !== "boolean")
                        return "isExtension: boolean expected";
                    return null;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return object;
                    let message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (object.namePart != null)
                        message.namePart = String(object.namePart);
                    if (object.isExtension != null)
                        message.isExtension = Boolean(object.isExtension);
                    return message;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.namePart = "";
                        object.isExtension = false;
                    }
                    if (message.namePart != null && message.hasOwnProperty("namePart"))
                        object.namePart = message.namePart;
                    if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                        object.isExtension = message.isExtension;
                    return object;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NamePart
                 * @function getTypeUrl
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NamePart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.UninterpretedOption.NamePart";
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (let i = 0; i < message.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SourceCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.location && message.location.length))
                                message.location = [];
                            message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SourceCodeInfo message.
             * @function verify
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourceCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (let i = 0; i < message.location.length; ++i) {
                        let error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.SourceCodeInfo)
                    return object;
                let message = new $root.google.protobuf.SourceCodeInfo();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    message.location = [];
                    for (let i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (message.location && message.location.length) {
                    object.location = [];
                    for (let j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                }
                return object;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SourceCodeInfo
             * @function getTypeUrl
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SourceCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.SourceCodeInfo";
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (let i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.span != null && message.span.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (let i = 0; i < message.span.length; ++i)
                            writer.int32(message.span[i]);
                        writer.ldelim();
                    }
                    if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                    if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                    if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                        for (let i = 0; i < message.leadingDetachedComments.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                    return writer;
                };

                /**
                 * Encodes the specified Location message, length delimited. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            }
                        case 2: {
                                if (!(message.span && message.span.length))
                                    message.span = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.span.push(reader.int32());
                                } else
                                    message.span.push(reader.int32());
                                break;
                            }
                        case 3: {
                                message.leadingComments = reader.string();
                                break;
                            }
                        case 4: {
                                message.trailingComments = reader.string();
                                break;
                            }
                        case 6: {
                                if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                    message.leadingDetachedComments = [];
                                message.leadingDetachedComments.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (let i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.span != null && message.hasOwnProperty("span")) {
                        if (!Array.isArray(message.span))
                            return "span: array expected";
                        for (let i = 0; i < message.span.length; ++i)
                            if (!$util.isInteger(message.span[i]))
                                return "span: integer[] expected";
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        if (!$util.isString(message.leadingComments))
                            return "leadingComments: string expected";
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        if (!$util.isString(message.trailingComments))
                            return "trailingComments: string expected";
                    if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                        if (!Array.isArray(message.leadingDetachedComments))
                            return "leadingDetachedComments: array expected";
                        for (let i = 0; i < message.leadingDetachedComments.length; ++i)
                            if (!$util.isString(message.leadingDetachedComments[i]))
                                return "leadingDetachedComments: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return object;
                    let message = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        message.path = [];
                        for (let i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.span) {
                        if (!Array.isArray(object.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        message.span = [];
                        for (let i = 0; i < object.span.length; ++i)
                            message.span[i] = object.span[i] | 0;
                    }
                    if (object.leadingComments != null)
                        message.leadingComments = String(object.leadingComments);
                    if (object.trailingComments != null)
                        message.trailingComments = String(object.trailingComments);
                    if (object.leadingDetachedComments) {
                        if (!Array.isArray(object.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        message.leadingDetachedComments = [];
                        for (let i = 0; i < object.leadingDetachedComments.length; ++i)
                            message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults) {
                        object.path = [];
                        object.span = [];
                        object.leadingDetachedComments = [];
                    }
                    if (options.defaults) {
                        object.leadingComments = "";
                        object.trailingComments = "";
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (let j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.span && message.span.length) {
                        object.span = [];
                        for (let j = 0; j < message.span.length; ++j)
                            object.span[j] = message.span[j];
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        object.leadingComments = message.leadingComments;
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        object.trailingComments = message.trailingComments;
                    if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                        object.leadingDetachedComments = [];
                        for (let j = 0; j < message.leadingDetachedComments.length; ++j)
                            object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                    }
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Location
                 * @function getTypeUrl
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Location.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.SourceCodeInfo.Location";
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.annotation != null && message.annotation.length)
                    for (let i = 0; i < message.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GeneratedCodeInfo message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.annotation && message.annotation.length))
                                message.annotation = [];
                            message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GeneratedCodeInfo message.
             * @function verify
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeneratedCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annotation != null && message.hasOwnProperty("annotation")) {
                    if (!Array.isArray(message.annotation))
                        return "annotation: array expected";
                    for (let i = 0; i < message.annotation.length; ++i) {
                        let error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                        if (error)
                            return "annotation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return object;
                let message = new $root.google.protobuf.GeneratedCodeInfo();
                if (object.annotation) {
                    if (!Array.isArray(object.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    message.annotation = [];
                    for (let i = 0; i < object.annotation.length; ++i) {
                        if (typeof object.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.annotation = [];
                if (message.annotation && message.annotation.length) {
                    object.annotation = [];
                    for (let j = 0; j < message.annotation.length; ++j)
                        object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                }
                return object;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GeneratedCodeInfo
             * @function getTypeUrl
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GeneratedCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo";
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (let i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                    if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                    return writer;
                };

                /**
                 * Encodes the specified Annotation message, length delimited. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.path && message.path.length))
                                    message.path = [];
                                if ((tag & 7) === 2) {
                                    let end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.path.push(reader.int32());
                                } else
                                    message.path.push(reader.int32());
                                break;
                            }
                        case 2: {
                                message.sourceFile = reader.string();
                                break;
                            }
                        case 3: {
                                message.begin = reader.int32();
                                break;
                            }
                        case 4: {
                                message.end = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Annotation message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Annotation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (let i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return object;
                    let message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        message.path = [];
                        for (let i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.path = [];
                    if (options.defaults) {
                        object.sourceFile = "";
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (let j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Annotation
                 * @function getTypeUrl
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Annotation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/google.protobuf.GeneratedCodeInfo.Annotation";
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        protobuf.Duration = (function() {

            /**
             * Properties of a Duration.
             * @memberof google.protobuf
             * @interface IDuration
             * @property {Long|null} [seconds] Duration seconds
             * @property {number|null} [nanos] Duration nanos
             */

            /**
             * Constructs a new Duration.
             * @memberof google.protobuf
             * @classdesc Represents a Duration.
             * @implements IDuration
             * @constructor
             * @param {google.protobuf.IDuration=} [properties] Properties to set
             */
            function Duration(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Duration seconds.
             * @member {Long} seconds
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Duration nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.nanos = 0;

            /**
             * Creates a new Duration instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration=} [properties] Properties to set
             * @returns {google.protobuf.Duration} Duration instance
             */
            Duration.create = function create(properties) {
                return new Duration(properties);
            };

            /**
             * Encodes the specified Duration message. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration} message Duration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Duration message, length delimited. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration} message Duration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Duration message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Duration();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Duration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Duration message.
             * @function verify
             * @memberof google.protobuf.Duration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Duration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Duration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Duration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Duration} Duration
             */
            Duration.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Duration)
                    return object;
                let message = new $root.google.protobuf.Duration();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Duration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.Duration} message Duration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Duration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Duration to JSON.
             * @function toJSON
             * @memberof google.protobuf.Duration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Duration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Duration
             * @function getTypeUrl
             * @memberof google.protobuf.Duration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Duration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Duration";
            };

            return Duration;
        })();

        protobuf.DoubleValue = (function() {

            /**
             * Properties of a DoubleValue.
             * @memberof google.protobuf
             * @interface IDoubleValue
             * @property {number|null} [value] DoubleValue value
             */

            /**
             * Constructs a new DoubleValue.
             * @memberof google.protobuf
             * @classdesc Represents a DoubleValue.
             * @implements IDoubleValue
             * @constructor
             * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
             */
            function DoubleValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DoubleValue value.
             * @member {number} value
             * @memberof google.protobuf.DoubleValue
             * @instance
             */
            DoubleValue.prototype.value = 0;

            /**
             * Creates a new DoubleValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
             * @returns {google.protobuf.DoubleValue} DoubleValue instance
             */
            DoubleValue.create = function create(properties) {
                return new DoubleValue(properties);
            };

            /**
             * Encodes the specified DoubleValue message. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DoubleValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
                return writer;
            };

            /**
             * Encodes the specified DoubleValue message, length delimited. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DoubleValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DoubleValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DoubleValue} DoubleValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DoubleValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DoubleValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DoubleValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.DoubleValue} DoubleValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DoubleValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DoubleValue message.
             * @function verify
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DoubleValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value !== "number")
                        return "value: number expected";
                return null;
            };

            /**
             * Creates a DoubleValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DoubleValue} DoubleValue
             */
            DoubleValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DoubleValue)
                    return object;
                let message = new $root.google.protobuf.DoubleValue();
                if (object.value != null)
                    message.value = Number(object.value);
                return message;
            };

            /**
             * Creates a plain object from a DoubleValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.DoubleValue} message DoubleValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DoubleValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                return object;
            };

            /**
             * Converts this DoubleValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.DoubleValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DoubleValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DoubleValue
             * @function getTypeUrl
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DoubleValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DoubleValue";
            };

            return DoubleValue;
        })();

        protobuf.FloatValue = (function() {

            /**
             * Properties of a FloatValue.
             * @memberof google.protobuf
             * @interface IFloatValue
             * @property {number|null} [value] FloatValue value
             */

            /**
             * Constructs a new FloatValue.
             * @memberof google.protobuf
             * @classdesc Represents a FloatValue.
             * @implements IFloatValue
             * @constructor
             * @param {google.protobuf.IFloatValue=} [properties] Properties to set
             */
            function FloatValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FloatValue value.
             * @member {number} value
             * @memberof google.protobuf.FloatValue
             * @instance
             */
            FloatValue.prototype.value = 0;

            /**
             * Creates a new FloatValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue=} [properties] Properties to set
             * @returns {google.protobuf.FloatValue} FloatValue instance
             */
            FloatValue.create = function create(properties) {
                return new FloatValue(properties);
            };

            /**
             * Encodes the specified FloatValue message. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FloatValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.value);
                return writer;
            };

            /**
             * Encodes the specified FloatValue message, length delimited. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FloatValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FloatValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FloatValue} FloatValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FloatValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FloatValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FloatValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FloatValue} FloatValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FloatValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FloatValue message.
             * @function verify
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FloatValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value !== "number")
                        return "value: number expected";
                return null;
            };

            /**
             * Creates a FloatValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FloatValue} FloatValue
             */
            FloatValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FloatValue)
                    return object;
                let message = new $root.google.protobuf.FloatValue();
                if (object.value != null)
                    message.value = Number(object.value);
                return message;
            };

            /**
             * Creates a plain object from a FloatValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.FloatValue} message FloatValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FloatValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                return object;
            };

            /**
             * Converts this FloatValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.FloatValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FloatValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FloatValue
             * @function getTypeUrl
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FloatValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FloatValue";
            };

            return FloatValue;
        })();

        protobuf.Int64Value = (function() {

            /**
             * Properties of an Int64Value.
             * @memberof google.protobuf
             * @interface IInt64Value
             * @property {Long|null} [value] Int64Value value
             */

            /**
             * Constructs a new Int64Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int64Value.
             * @implements IInt64Value
             * @constructor
             * @param {google.protobuf.IInt64Value=} [properties] Properties to set
             */
            function Int64Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Int64Value value.
             * @member {Long} value
             * @memberof google.protobuf.Int64Value
             * @instance
             */
            Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Int64Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value=} [properties] Properties to set
             * @returns {google.protobuf.Int64Value} Int64Value instance
             */
            Int64Value.create = function create(properties) {
                return new Int64Value(properties);
            };

            /**
             * Encodes the specified Int64Value message. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int64Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.value);
                return writer;
            };

            /**
             * Encodes the specified Int64Value message, length delimited. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int64Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Int64Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Int64Value} Int64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int64Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int64Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Int64Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Int64Value} Int64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int64Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Int64Value message.
             * @function verify
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Int64Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                        return "value: integer|Long expected";
                return null;
            };

            /**
             * Creates an Int64Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Int64Value} Int64Value
             */
            Int64Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Int64Value)
                    return object;
                let message = new $root.google.protobuf.Int64Value();
                if (object.value != null)
                    if ($util.Long)
                        (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                    else if (typeof object.value === "string")
                        message.value = parseInt(object.value, 10);
                    else if (typeof object.value === "number")
                        message.value = object.value;
                    else if (typeof object.value === "object")
                        message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an Int64Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.Int64Value} message Int64Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Int64Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.value = options.longs === String ? "0" : 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value === "number")
                        object.value = options.longs === String ? String(message.value) : message.value;
                    else
                        object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
                return object;
            };

            /**
             * Converts this Int64Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Int64Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Int64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Int64Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int64Value";
            };

            return Int64Value;
        })();

        protobuf.UInt64Value = (function() {

            /**
             * Properties of a UInt64Value.
             * @memberof google.protobuf
             * @interface IUInt64Value
             * @property {Long|null} [value] UInt64Value value
             */

            /**
             * Constructs a new UInt64Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt64Value.
             * @implements IUInt64Value
             * @constructor
             * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
             */
            function UInt64Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UInt64Value value.
             * @member {Long} value
             * @memberof google.protobuf.UInt64Value
             * @instance
             */
            UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new UInt64Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
             * @returns {google.protobuf.UInt64Value} UInt64Value instance
             */
            UInt64Value.create = function create(properties) {
                return new UInt64Value(properties);
            };

            /**
             * Encodes the specified UInt64Value message. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt64Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.value);
                return writer;
            };

            /**
             * Encodes the specified UInt64Value message, length delimited. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt64Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UInt64Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UInt64Value} UInt64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt64Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt64Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UInt64Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.UInt64Value} UInt64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt64Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UInt64Value message.
             * @function verify
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UInt64Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                        return "value: integer|Long expected";
                return null;
            };

            /**
             * Creates a UInt64Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UInt64Value} UInt64Value
             */
            UInt64Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UInt64Value)
                    return object;
                let message = new $root.google.protobuf.UInt64Value();
                if (object.value != null)
                    if ($util.Long)
                        (message.value = $util.Long.fromValue(object.value)).unsigned = true;
                    else if (typeof object.value === "string")
                        message.value = parseInt(object.value, 10);
                    else if (typeof object.value === "number")
                        message.value = object.value;
                    else if (typeof object.value === "object")
                        message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a UInt64Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.UInt64Value} message UInt64Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UInt64Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.value = options.longs === String ? "0" : 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value === "number")
                        object.value = options.longs === String ? String(message.value) : message.value;
                    else
                        object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber(true) : message.value;
                return object;
            };

            /**
             * Converts this UInt64Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.UInt64Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UInt64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UInt64Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt64Value";
            };

            return UInt64Value;
        })();

        protobuf.Int32Value = (function() {

            /**
             * Properties of an Int32Value.
             * @memberof google.protobuf
             * @interface IInt32Value
             * @property {number|null} [value] Int32Value value
             */

            /**
             * Constructs a new Int32Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int32Value.
             * @implements IInt32Value
             * @constructor
             * @param {google.protobuf.IInt32Value=} [properties] Properties to set
             */
            function Int32Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Int32Value value.
             * @member {number} value
             * @memberof google.protobuf.Int32Value
             * @instance
             */
            Int32Value.prototype.value = 0;

            /**
             * Creates a new Int32Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value=} [properties] Properties to set
             * @returns {google.protobuf.Int32Value} Int32Value instance
             */
            Int32Value.create = function create(properties) {
                return new Int32Value(properties);
            };

            /**
             * Encodes the specified Int32Value message. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int32Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
                return writer;
            };

            /**
             * Encodes the specified Int32Value message, length delimited. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int32Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Int32Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Int32Value} Int32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int32Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int32Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Int32Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Int32Value} Int32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int32Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Int32Value message.
             * @function verify
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Int32Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isInteger(message.value))
                        return "value: integer expected";
                return null;
            };

            /**
             * Creates an Int32Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Int32Value} Int32Value
             */
            Int32Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Int32Value)
                    return object;
                let message = new $root.google.protobuf.Int32Value();
                if (object.value != null)
                    message.value = object.value | 0;
                return message;
            };

            /**
             * Creates a plain object from an Int32Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.Int32Value} message Int32Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Int32Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this Int32Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Int32Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Int32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Int32Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int32Value";
            };

            return Int32Value;
        })();

        protobuf.UInt32Value = (function() {

            /**
             * Properties of a UInt32Value.
             * @memberof google.protobuf
             * @interface IUInt32Value
             * @property {number|null} [value] UInt32Value value
             */

            /**
             * Constructs a new UInt32Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt32Value.
             * @implements IUInt32Value
             * @constructor
             * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
             */
            function UInt32Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UInt32Value value.
             * @member {number} value
             * @memberof google.protobuf.UInt32Value
             * @instance
             */
            UInt32Value.prototype.value = 0;

            /**
             * Creates a new UInt32Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
             * @returns {google.protobuf.UInt32Value} UInt32Value instance
             */
            UInt32Value.create = function create(properties) {
                return new UInt32Value(properties);
            };

            /**
             * Encodes the specified UInt32Value message. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt32Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.value);
                return writer;
            };

            /**
             * Encodes the specified UInt32Value message, length delimited. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt32Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UInt32Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UInt32Value} UInt32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt32Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt32Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UInt32Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.UInt32Value} UInt32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt32Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UInt32Value message.
             * @function verify
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UInt32Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isInteger(message.value))
                        return "value: integer expected";
                return null;
            };

            /**
             * Creates a UInt32Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UInt32Value} UInt32Value
             */
            UInt32Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UInt32Value)
                    return object;
                let message = new $root.google.protobuf.UInt32Value();
                if (object.value != null)
                    message.value = object.value >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a UInt32Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.UInt32Value} message UInt32Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UInt32Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this UInt32Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.UInt32Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UInt32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UInt32Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt32Value";
            };

            return UInt32Value;
        })();

        protobuf.BoolValue = (function() {

            /**
             * Properties of a BoolValue.
             * @memberof google.protobuf
             * @interface IBoolValue
             * @property {boolean|null} [value] BoolValue value
             */

            /**
             * Constructs a new BoolValue.
             * @memberof google.protobuf
             * @classdesc Represents a BoolValue.
             * @implements IBoolValue
             * @constructor
             * @param {google.protobuf.IBoolValue=} [properties] Properties to set
             */
            function BoolValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BoolValue value.
             * @member {boolean} value
             * @memberof google.protobuf.BoolValue
             * @instance
             */
            BoolValue.prototype.value = false;

            /**
             * Creates a new BoolValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue=} [properties] Properties to set
             * @returns {google.protobuf.BoolValue} BoolValue instance
             */
            BoolValue.create = function create(properties) {
                return new BoolValue(properties);
            };

            /**
             * Encodes the specified BoolValue message. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BoolValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
                return writer;
            };

            /**
             * Encodes the specified BoolValue message, length delimited. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BoolValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BoolValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.BoolValue} BoolValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BoolValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BoolValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BoolValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.BoolValue} BoolValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BoolValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BoolValue message.
             * @function verify
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BoolValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value !== "boolean")
                        return "value: boolean expected";
                return null;
            };

            /**
             * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.BoolValue} BoolValue
             */
            BoolValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.BoolValue)
                    return object;
                let message = new $root.google.protobuf.BoolValue();
                if (object.value != null)
                    message.value = Boolean(object.value);
                return message;
            };

            /**
             * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.BoolValue} message BoolValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BoolValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = false;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this BoolValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.BoolValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BoolValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BoolValue
             * @function getTypeUrl
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BoolValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BoolValue";
            };

            return BoolValue;
        })();

        protobuf.StringValue = (function() {

            /**
             * Properties of a StringValue.
             * @memberof google.protobuf
             * @interface IStringValue
             * @property {string|null} [value] StringValue value
             */

            /**
             * Constructs a new StringValue.
             * @memberof google.protobuf
             * @classdesc Represents a StringValue.
             * @implements IStringValue
             * @constructor
             * @param {google.protobuf.IStringValue=} [properties] Properties to set
             */
            function StringValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StringValue value.
             * @member {string} value
             * @memberof google.protobuf.StringValue
             * @instance
             */
            StringValue.prototype.value = "";

            /**
             * Creates a new StringValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue=} [properties] Properties to set
             * @returns {google.protobuf.StringValue} StringValue instance
             */
            StringValue.create = function create(properties) {
                return new StringValue(properties);
            };

            /**
             * Encodes the specified StringValue message. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StringValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                return writer;
            };

            /**
             * Encodes the specified StringValue message, length delimited. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StringValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StringValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.StringValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.StringValue} StringValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StringValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.StringValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StringValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.StringValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.StringValue} StringValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StringValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StringValue message.
             * @function verify
             * @memberof google.protobuf.StringValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StringValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                return null;
            };

            /**
             * Creates a StringValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.StringValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.StringValue} StringValue
             */
            StringValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.StringValue)
                    return object;
                let message = new $root.google.protobuf.StringValue();
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            /**
             * Creates a plain object from a StringValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.StringValue} message StringValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StringValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = "";
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this StringValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.StringValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StringValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StringValue
             * @function getTypeUrl
             * @memberof google.protobuf.StringValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StringValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.StringValue";
            };

            return StringValue;
        })();

        protobuf.BytesValue = (function() {

            /**
             * Properties of a BytesValue.
             * @memberof google.protobuf
             * @interface IBytesValue
             * @property {Uint8Array|null} [value] BytesValue value
             */

            /**
             * Constructs a new BytesValue.
             * @memberof google.protobuf
             * @classdesc Represents a BytesValue.
             * @implements IBytesValue
             * @constructor
             * @param {google.protobuf.IBytesValue=} [properties] Properties to set
             */
            function BytesValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BytesValue value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.BytesValue
             * @instance
             */
            BytesValue.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new BytesValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue=} [properties] Properties to set
             * @returns {google.protobuf.BytesValue} BytesValue instance
             */
            BytesValue.create = function create(properties) {
                return new BytesValue(properties);
            };

            /**
             * Encodes the specified BytesValue message. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BytesValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified BytesValue message, length delimited. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BytesValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BytesValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.BytesValue} BytesValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BytesValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BytesValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BytesValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.BytesValue} BytesValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BytesValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BytesValue message.
             * @function verify
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BytesValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates a BytesValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.BytesValue} BytesValue
             */
            BytesValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.BytesValue)
                    return object;
                let message = new $root.google.protobuf.BytesValue();
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length >= 0)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from a BytesValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.BytesValue} message BytesValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BytesValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this BytesValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.BytesValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BytesValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BytesValue
             * @function getTypeUrl
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BytesValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BytesValue";
            };

            return BytesValue;
        })();

        protobuf.Empty = (function() {

            /**
             * Properties of an Empty.
             * @memberof google.protobuf
             * @interface IEmpty
             */

            /**
             * Constructs a new Empty.
             * @memberof google.protobuf
             * @classdesc Represents an Empty.
             * @implements IEmpty
             * @constructor
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             */
            function Empty(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Empty instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             * @returns {google.protobuf.Empty} Empty instance
             */
            Empty.create = function create(properties) {
                return new Empty(properties);
            };

            /**
             * Encodes the specified Empty message. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Empty message, length delimited. Does not implicitly {@link google.protobuf.Empty.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.IEmpty} message Empty message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Empty.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Empty message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Empty();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Empty message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Empty
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Empty} Empty
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Empty.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Empty message.
             * @function verify
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Empty.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an Empty message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Empty} Empty
             */
            Empty.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Empty)
                    return object;
                return new $root.google.protobuf.Empty();
            };

            /**
             * Creates a plain object from an Empty message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Empty
             * @static
             * @param {google.protobuf.Empty} message Empty
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Empty.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Empty to JSON.
             * @function toJSON
             * @memberof google.protobuf.Empty
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Empty.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Empty
             * @function getTypeUrl
             * @memberof google.protobuf.Empty
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Empty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Empty";
            };

            return Empty;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
