<template>
  <!-- <div id="app"> -->
  <Chat />
  <!-- </div> -->
</template>

<script>
import Chat from "./components/chat.vue";

export default {
  name: "App",
  components: {
    Chat,
  },
};
</script>

<style>
#app {
  color: #323232;
  width: 100%;
  height: 100vh;
  /* background: #2E2F3D; */
}

* {
  font-family: "微软雅黑";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

button {
  cursor: pointer;
}

.emoji-mart-category-label {
  display: none;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px 9px;
}

.edit img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.van-overlay {
  background: #000 !important;
  z-index: 9999 !important;
}
</style>