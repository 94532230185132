<template>
  <div :class="['container'] + [isMobile ? ' mobile-container' : '']">
    <!-- 聊天入口 -->
    <div class="entry" v-if="!showChat" @click="openChat()">
      <span class="icon-msg"></span>
      <span class="new-msg" v-if="newMsgNum > 0">{{
          newMsgNum > 99 ? "99+" : newMsgNum
        }}</span>
    </div>
    <!-- 聊天内容 -->
    <div
        :class="['chat'] + [isMobile ? ' mobile-chat' : '']"
        :style="{ height: containerHeight + 'px' }"
        v-if="showChat"
    >
      <template v-if="status == pageState.usual">
        <div class="top" ref="topRef">
          <img
              src="@/assets/img/left_arrow.svg"
              class="icon-back"
              @click="closeChat()"
              v-if="isMobile"
          />
          <!-- <template v-if="workerInfo"> -->
          <img
              :src="
              workerInfo && workerInfo.avatar
                ? workerInfo.avatar
                : require('@/assets/img/com_moren.svg')
            "
              :onerror="imgOnError"
              class="pic"
          />
          <div class="name">
            {{
              authed ? (workerInfo ? workerInfo.nick : "") : "连接客服中,请稍后"
            }}
          </div>
          <!-- <div class="name">{{ (workerInfo && workerInfo.nick) ? workerInfo.nick : '连接客服中,请稍后' }}</div> -->
          <!-- </template> -->
          <img
              src="@/assets/img/ck_guanbi.svg"
              class="icon-close"
              @click="closeChat()"
              v-if="!isMobile"
          />
        </div>

        <div
            class="chat-list"
            :style="{ height: chatListH + 'px' }"
            ref="chatListRef"
            @click="closeOther()"
            @scroll="scrollMsg"
        >
          <div
              class="sys-msg text-blue"
              v-if="showLoadMore"
              @click="loadMore()"
          >
            加载更多历史消息
          </div>
          <!-- <div class="sys-msg">暂无更多历史信息，最多保留90天以内的记录</div> -->
          <template v-for="(item, index) in chatList" :key="index + 'idx'">
            <div class="sys-msg" v-if="item.type + '' === msgType.sys + ''">
              {{ viewTime(item.time, true) }}
            </div>
            <template v-else>
              <div
                  :class="['chat-item'] + [item.isUser ? ' user' : '']"
                  :msgId="longToString(item.msgId)"
              >
                <div class="chat-time" v-if="item.type != msgType.revoke">
                  {{ viewTime2(item.time, item) }}
                </div>
                <!-- 咨询列表 -->
                <template v-if="item.type == msgType.consultList">
                  <div class="ques-box">
                    <div class="ques-title">{{ item.content.guide }}</div>
                    <ul class="ques-list">
                      <template
                          v-for="(single, i) in item.content.consults"
                          :key="i + 'idx'"
                      >
                        <li @click="handleConsult(single)">
                          {{ i + 1 }}.{{ single.guide }}
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>

                <!-- 选中的咨询 -->
                <template v-else-if="item.type == msgType.selectConsult">
                  <div class="chat-item-nav">
                    <div class="chat-con">{{ item.content.guide }}</div>
                  </div>
                </template>

                <!-- 分配的客服信息 -->
                <template v-else-if="item.type == msgType.assignWorker">
                  <div class="chat-item-nav">
                    <div class="chat-con">
                      你好，我是{{ item.content.nick }}
                    </div>
                  </div>
                </template>

                <!-- 自动回复列表 -->
                <template v-else-if="item.type == msgType.autoReplyList">
                  <div class="ques-box" v-if="item.content">
                    <div class="ques-title">{{ item.content.title }}</div>
                    <ul class="ques-list">
                      <template v-for="(single, i) in item.content.qa" :key="i">
                        <li
                            @click.stop="
                            !someArr.includes(single.id) &&
                              handleAutoReply(single, item.content.delaySec)
                          "
                            :class="someArr.includes(single.id) && 'someArrClass'"
                        >
                          {{ i + 1 }}
                          <template v-if="single.question.content"
                          >{{ single.question.content.data }}
                          </template>
                          <img
                              :src="getMediaFullUrl(single.question.image.uri)"
                              v-if="single.question.image"
                              style="
                              width: auto;
                              height: auto;
                              max-width: 268px;
                              max-height: 228px;
                            "
                              alt=""
                          />
                          <video
                              controls="controls"
                              controlsList="nodownload noremoteplayback"
                              v-if="single.question.video"
                              :src="getMediaFullUrl(single.question.video.uri)"
                              style="
                              width: auto;
                              height: auto;
                              max-width: 268px;
                              max-height: 228px;
                            "
                          ></video>
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>

                <!-- 选中的自动回复-问题 -->
                <template v-else-if="item.type == msgType.selectAutoReply">
                  <div class="chat-item-nav">
                    <!-- <div class="chat-con">{{ item.content.question.content?.data }}</div> -->
                    <div class="chat-con" v-html="item.content"></div>
                  </div>
                </template>

                <!-- 选中的自动回复-回答 -->
                <template v-else-if="item.type == msgType.autoReplyItem">
                  <div class="chat-item-nav" v-if="item.content">
                    <!-- <div class="chat-con">{{ item.content.answer[0].content?.data }}</div> -->
                    <div class="chat-con" v-html="item.content"></div>
                  </div>
                </template>

                <!--撤回消息 -->
                <template v-else-if="item.type == msgType.revoke">
                  <div class="revoke-msg">
                    <span>{{ item.content }}</span>
                    <!-- <span class="text-blue" v-if="(item.id == userId)" @click="reEdit(item)" >重新编辑</span> -->
                  </div>
                </template>
                <template v-else>
                  <div class="chat-item-nav">
                    <div
                        class="msg-item"
                        @contextmenu.prevent="handleMsgRm($event, item)"
                        @touchstart.prevent="touchstart($event)"
                        @touchend.prevent="touchend($event, item)"
                        @click.prevent="
                        openFullFile($event, item.content, item.type)
                      "
                    >
                      <template
                          v-if="
                          item.type == msgType.text ||
                          item.type == msgType.reply
                        "
                      >
                        <div
                            class="chat-con"
                            v-html="viewTextMsg(item.content)"
                        ></div>
                      </template>
                      <template v-if="item.type == msgType.pic">
                        <img
                            @load="loadPicMsg"
                            :src="item.content"
                            class="pic-msg"
                            alt="图片"
                        />
                        <img
                            src="@/assets/img/icon_download.svg"
                            class="icon-download"
                            alt="下载"
                            v-if="
                            (item.state == msgState.success && isAndroid) ||
                            (item.state == msgState.success && !isMobile)
                          "
                        />
                      </template>
                      <template v-if="item.type == msgType.video">
                        <div class="video-msg">
                          <video
                              @loadedmetadata="loadVideoMsg"
                              :src="item.content"
                              class="video-box"
                          ></video>
                          <img
                              src="@/assets/img/icon_play.svg"
                              class="icon-play"
                              alt="播放"
                          />
                        </div>
                        <img
                            src="@/assets/img/icon_download.svg"
                            class="icon-download"
                            alt="下载"
                            v-if="item.state == msgState.success"
                        />
                      </template>
                      <template v-if="item.type == msgType.file">
                        <div class="file-msg">
                          <div class="file-msg-left">
                            <div>
                              <span>{{
                                  item.content.fileName.split(".")[0]
                                }}</span>
                              <span
                              >.{{
                                  item.content.fileName.split(".")[1]
                                }}</span
                              >
                            </div>
                            <div>{{ item.content.size }}</div>
                          </div>
                          <div class="file-msg-right">
                            <img src="@/assets/img/file.svg"/>
                          </div>
                        </div>
                        <img
                            src="@/assets/img/icon_download.svg"
                            class="icon-download"
                            alt="下载"
                            v-if="item.state == msgState.success"
                        />
                      </template>
                    </div>
                    <img
                        src="@/assets/img/h5_shibai.svg"
                        class="icon-warn"
                        v-if="item.state == msgState.fail"
                        @click="reSend(item)"
                    />
                    <img
                        src="@/assets/img/h5_fszhong.svg"
                        class="icon-load"
                        v-if="item.state == msgState.sendingSlow"
                    />
                  </div>
                </template>
                <!-- 回复消息 -->
                <div class="reply-msg" v-if="item.type == msgType.reply">
                  <div :replyMsgId="item.replyInfo.msgId">
                    回复：<span v-html="item.replyInfo.content"></span>
                  </div>
                  <!-- <div>回复内容已撤回</div> -->
                </div>
              </div>
            </template>
          </template>
        </div>

        <div
            :class="
            ['btm'] +
            [
              isMobile
                ? !isAndroid
                  ? ' mobile-btm iosset'
                  : ' mobile-btm'
                : '',
            ]
          "
            ref="btmRef"
        >
          <!-- 回复消息时 -->
          <div class="reply-box" v-if="showReply">
            <label>回复：</label>
            <span v-html="replyInfo.content"></span>
            <img src="@/assets/img/ht_hfshanchu.svg" @click="closeReply"/>
          </div>

          <!-- pc端底部 -->
          <template v-if="!isMobile">
            <div
                class="edit"
                contenteditable="true"
                placeholder="请输入想咨询的问题"
                maxlength="500"
                ref="inputRef"
                @input="handleEditInput($event)"
                @blur="handleEditBlur()"
                @focus="handleEditFocus()"
                @copy="handleEditCopy($event)"
                @paste="handlePaste($event)"
            ></div>
            <div class="action">
              <div class="action-list">
                <img
                    src="@/assets/img/h5_zhaoping.svg"
                    title="相册"
                    @click="openFile"
                />
                <img
                    src="@/assets/img/h5_biaoqing.svg"
                    title="表情"
                    @click.prevent="handleEmoji"
                />
              </div>
              <span>{{ inputLen }}/{{ maxInputLen }}</span>
              <button @click="handleSend()">发送</button>
            </div>
          </template>

          <!-- 手机端底部 -->
          <template v-else>
            <div class="send-box">
              <div
                  class="edit"
                  contenteditable="true"
                  placeholder="请输入想咨询的问题"
                  ref="inputRef"
                  @input="handleEditInput($event)"
                  @blur="handleEditBlur()"
                  @focus="handleEditFocus()"
                  @copy="handleEditCopy($event)"
                  @paste="handlePaste($event)"
              ></div>
              <div class="mobile-action">
                <img
                    src="@/assets/img/ht_shuru.svg"
                    @click="handleKeyboard()"
                    v-if="showEmoji"
                />
                <img
                    src="@/assets/img/h5_zhaoping.svg"
                    @click="openFile"
                    v-if="!sendCon"
                />
                <img
                    src="@/assets/img/h5_biaoqing.svg"
                    @click.prevent="handleEmoji()"
                    v-if="!showEmoji"
                />
                <button class="btn-send" @click="handleSend()" v-if="sendCon">
                  发送
                </button>
              </div>
            </div>
          </template>

          <!-- emoji -->
          <div
              :class="isMobile ? 'mobile-emoji-box' : 'emoji-box'"
              :style="{ height: (isMobile ? mobileEmojiBoxH : emojiBoxH) + 'px' }"
              v-if="showEmoji"
          >
            <div class="emoji-nav">
              <div class="emoji-list">
                <template v-for="item in 136" :key="item">
                  <div
                      :class="['emoji '] + ['emoji-' + item]"
                      @click="selectEmoji(item)"
                  ></div>
                </template>
              </div>
              <div class="emoji-action" v-if="isMobile">
                <div class="btn-delete" @click="handleDelete">
                  <img src="@/assets/img/h5_qingchu.svg"/>
                </div>
                <div class="btn-send" @click="handleSend">发送</div>
              </div>
            </div>
          </div>
          <div
              class="iosbottom"
              v-if="iosBottom && isMobile && !isAndroid"
              style="height: 25px; width: 100%; background: #f5f5f5"
          ></div>
        </div>
      </template>
      <template v-if="status == pageState.loading">
        <div class="error">
          <div class="desc">
            <!-- <img src="" /> -->
            <van-loading/>
            <div>会话加载中...</div>
          </div>
        </div>
      </template>

      <!-- 长按 快捷操作 -->
      <div
          class="shortcuts"
          :style="{
          left: shortcutsPosition.x + 'px',
          top: shortcutsPosition.y + 'px',
        }"
          v-if="showShortcuts"
      >
        <div
            class="shortcuts-item"
            @click="chooseShortcuts(shortcutsVal.reply)"
        >
          <img src="@/assets/img/h5_huifu.svg"/>
          <div>回复</div>
        </div>
        <div
            class="shortcuts-item"
            @click="chooseShortcuts(shortcutsVal.copy)"
            v-if="msgItem.type == msgType.text || msgItem.type == msgType.reply"
        >
          <img src="@/assets/img/ht_fuzhi.svg"/>
          <div>复制</div>
        </div>
        <!-- 目前版本不需要撤回功能
        <div class="shortcuts-item" @click="chooseShortcuts(shortcutsVal.revoke)"
          v-if="msgItem.isUser && (getCurrTs() - msgItem.timeStamp < (5 * 60 * 1000))">
          <img src="@/assets/img/h5_cehui.svg" />
          <div>撤回</div>
        </div> -->
        <!-- 目前版本不允许本地删除
        <div class="shortcuts-item" @click="chooseShortcuts(shortcutsVal.delete)">
          <img src="@/assets/img/h5_btshanchu.svg" />
          <div>删除</div>
        </div> -->
      </div>

      <!-- <input ref="filElem" type="file" name="file[]" accept="image/*" multiple="multiple" size="9" @change="getFile"
        style="display: none;" /> -->
      <input
          ref="filElem"
          type="file"
          name="file[]"
          accept="image/*,video/*"
          multiple="multiple"
          size="9"
          @change="getFile"
          style="display: none"
      />
    </div>

    <!-- 查看图片/视频消息 -->
    <van-overlay :show="fullFile.show" @click="closeFullFile">
      <div class="overlay-wrapper">
        <img
            :src="fullFile.uri"
            :style="fullFile.style"
            v-if="fullFile.type == msgType.pic"
        />
        <video
            :src="fullFile.uri"
            :style="fullFile.style"
            controls
            autoplay
            v-if="fullFile.type == msgType.video"
        ></video>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "chatIndex",
};
</script>

<script setup>
import {ref, onMounted, onUnmounted, watch, nextTick, toRaw} from "vue";
import {showDialog, showToast, showLoadingToast, closeToast} from "vant";
import {Client, api, CloseReason, gateway} from "@/sdk";
import imgZip from "imgzip";
import env from "@/config/config";
import Long from "long";
import {Base64} from "js-base64";
import clipboard from "vue-clipboard3";

const {toClipboard} = clipboard();
const wsObj = ref(null);
const clientId = ref(null);
const socketToken = ref(null);
const socketCert = ref(null);
const wsHost = ref([]); //ws的host
const uploadHost = ref(""); // 上传图片域名
const viewUploadHost = ref(""); //线上图片获取的域名
const hostSign = ref("https://"); //域名标识
const ready = ref(false);
const readyCheckCounter = ref(0);
// 连接并且验证成功
const authed = ref(false);
const getListSetTime = ref(null);
const topRef = ref(null);
const btmRef = ref(null);
const chatListRef = ref(null);
const showLoadMore = ref(false);
const containerHeight = ref(540);
const topHeight = ref(62); //top高度
const sendBoxH = ref(48);
const chatListH = ref(0);
const mobileEmojiBoxH = ref(200); //emoji盒子高度（手机端）
const emojiBoxH = ref(150); //emoji盒子高度（pc端）
// const userId = ref(1);
const showChat = ref(true);
const newMsgNum = ref(0);
//userid
const userID = ref(null);
const timestamp = ref(null);
const sign = ref(null);
// const quesList = ref([
//   {"title": "这里自定义问题一位置问题一位置问题一"},
//   {"title": "这里自定义问题二位置问题二位置问题二"},
//   {"title": "这里自定义问题三位置问题三位置问题三"}
// ])
const msgState = ref({
  success: 1, //成功
  sending: 2, //发送中
  sendingSlow: 3, //慢发送, 显示圈
  fail: 4, //失败
}); //消息发送状态
const msgType = ref({
  sys: 0, //'系统'消息
  text: 1, //'文本'消息
  pic: 2, //'图片'消息
  video: 3, //'视频'消息
  reply: 4, //'回复'消息
  revoke: 5, //'撤回'消息
  consultList: 6, //'咨询列表'消息
  selectConsult: 7, //'选择咨询'消息
  assignWorker: 8, //"分配客服"消息
  autoReplyList: 9, //'自动回复列表'消息
  selectAutoReply: 10, //'选择自动回复'消息
  autoReplyItem: 11, //'自动回复'消息
  file: 12, //'文件'消息
}); //消息类型
const autoReplyMsgId = ref({});
const chatList = ref([]);
const sendCon = ref("");
const inputLen = ref(0);
const maxInputLen = ref(500); //输入最大长度
const showEmoji = ref(false);
const status = ref(1);
const pageState = ref({
  usual: 1, //正常
  loading: 2, //加载中
}); //页面状态
const iosBottom = ref(false);
const showShortcuts = ref(false);
const loop = ref(null);
const longTouch = ref(false);
const msgItem = ref(null);
const touchstartPosition = ref({x: 0, y: 0});
const shortcutsPosition = ref({x: 0, y: 0});
const shortcutsVal = ref({
  reply: 1,
  copy: 2,
  revoke: 3,
  delete: 4,
}); //快捷键操作
const inputRef = ref(null);
const filElem = ref(null);
const showReply = ref(false); //显示回复
const replyInfo = ref(null); //回复信息
const entranceInfo = ref(null); //入口信息
const consultTimer = ref(null); //咨询计时器
const workerInfo = ref(null); //分配的客服信息
const autoReplyList = ref(null); //自动回复列表
const isMobile = ref(false);
const isAndroid = ref(false);
const cookieVal = ref(null);
const fullFile = ref({
  show: false,
  uri: "",
  style: "",
  type: "",
});
const reqTimes = ref(0); //请求次数
const maxReqTimes = ref(5); //最多请求次数

onMounted(() => {
  // localStorage.clear()
  wsHost.value = env.wsHost;
  uploadHost.value = env.uploadHost;
  viewUploadHost.value = env.viewUploadHost;
  listenNetwork();
  // clearAllCookie();
  clearInterval(autoReplyIntv.value);
  // showLoadingToast({
  //   duration: 0,
  //   message: '加载中...',
  //   forbidClick: true,
  // });
  // let isNotIframe = (window.self === window.top); //false –> 说明页面被嵌套在iframe中了;true –> 说明页面并没有被嵌套在iframe中
  // if (!isNotIframe) {
  //   let iframeWindow = window.parent.document.getElementById("custorm_iframe"); //document.getElementById('custorm_iframe').contentWindow;
  // }
  initPageHeight();
  let hasCert = false;
  socketCert.value = getLocationParams("cert");
  if (!socketCert.value) {
    let cert = sessionStorage.getItem("_cert"); //在网站调用时
    if (cert) {
      hasCert = true;
      socketCert.value = cert;
    }
  } else {
    hasCert = true;
  }
  userID.value = getLocationParams("userid");   //id
  timestamp.value = getLocationParams("timestamp"); 
  sign.value = getLocationParams("sign"); //签名

  let cVal = getCookie(socketCert.value);
  workerInfo.value = getCookie("workerInfo");
  cookieVal.value = cVal == null ? {} : cVal;

  let token = getCookie("token");
  if (token) {
    socketToken.value = token;
  }

  initSocket();
  window.androidFunc = androidFunc;
});

onUnmounted(() => {
  closeSocket();
});

watch(
    () => sendCon,
    (newVal, oldVal) => {
      if (inputRef.value) {
        changeChatListH();
      }
    },
    {immediate: true, deep: true}
);

//监听网络
const listenNetwork = () => {
  window.addEventListener("offline", () => {
    status.value = pageState.value.loading;
  });
  window.addEventListener("online", () => {
    status.value = pageState.value.usual;
  });
};

const openChat = () => {
  if (!socketCert.value) return;
  showChat.value = true;
  newMsgNum.value = 0;
  scrollToBtm();
  winPostMessage();
};

const closeChat = () => {
  showChat.value = false;
  closeOther();
  winPostMessage();
};

const closeOther = () => {
  closeShortcuts();
  closeEmoji();
};

//获取地址栏参数值
const getLocationParams = (key) => {
  let href = location.href;
  let index = href.indexOf("?");
  let val = null;
  if (index !== -1) {
    let str = href.substring(index + 1);
    let arr = str.split("&");
    for (let i = 0; i < arr.length; i++) {
      let arrItem = arr[i].split("=");
      // setCookie(arrItem[0], arrItem[1])
      if (arrItem[0] == key) {
        val = arrItem[1];
        break;
      }
    }
  }
  return val;
};

//判断是否为手机端
const isMobileFn = () => {
  let flag = true;
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    flag = true;
  } else if (/(Android)/i.test(navigator.userAgent)) {
    flag = true;
    isAndroid.value = true;
  } else {
    flag = false;
  }
  return flag;
};

//初始化整个页面的高度
const initPageHeight = () => {
  isMobile.value = isMobileFn();

  if (!isMobile.value) {
    containerHeight.value = 540;
    chatListH.value = 356;
  } else {
    loadMobileHeight();

    window.addEventListener("resize", (e) => {
      loadMobileHeight();
      changeChatListH();
    });
  }
  winPostMessage();
};

const loadMobileHeight = () => {
  let winInnerHeight = window.innerHeight;
  containerHeight.value = winInnerHeight;
  chatListH.value = winInnerHeight - topHeight.value - sendBoxH.value;
};

//给其他父页面使用
const winPostMessage = () => {
  let obj = {};
  if (!showChat.value) {
    obj.showChat = false;
    obj.width = "95px";
    obj.height = "115px";
  } else {
    obj.showChat = true;
    if (!isMobile.value) {
      obj.width = "395px";
      obj.height = containerHeight.value + "px";
    } else {
      obj.width = window.innerWidth + "px";
      obj.height = window.innerHeight + "px";
    }
  }
  window.parent.postMessage(obj, "*");
};

/**
 * CryptoJS 加密
 */
const encrypt = (encryptData) => {
  return Base64.encode(encryptData);
};

/**
 * CryptoJS 解密
 */
const decrypt = (encryptData) => {
  return Base64.decode(encryptData);
};

const clearAllCookie = () => {
  let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i--;)
      document.cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString();
  }
};

const setCookie = (key, val) => {
  if (key == socketCert.value) {
    localStorage.setItem(key, encrypt(JSON.stringify(val)));
  } else {
    cookieVal.value[key] = val;
    let cookieKey = socketCert.value;
    let cookieValue = encrypt(JSON.stringify(cookieVal.value));
    localStorage.setItem(cookieKey, cookieValue);
  }

  /*
  let cookieLen = document.cookie.length;
  if (cookieLen >= 4000) { //超过4K,清除部分缓存
    if (key == 'chatList') {
      val.splice(0, 1);
    }
  }

  cookieVal.value[key] = val;

  let currDate = new Date(); //实例化日期对象
  currDate.setTime(currDate.getTime() + 30 * 24 * 60 * 60 * 1000);
  let expiresVal = currDate.toGMTString();

  let cookieKey = socketCert.value;
  let cookieValue = encrypt(JSON.stringify(cookieVal.value));
  document.cookie = `${cookieKey}=${cookieValue};expires=${expiresVal}`;
  */
};

const getCookie = (key) => {
  let _storage = localStorage.getItem(socketCert.value);
  if (_storage) {
    _storage = decrypt(_storage);
  }
  try {
    _storage = JSON.parse(_storage);
  } catch (error) {
  }
  let val = null;
  if (key == socketCert.value) {
    val = _storage;
  } else {
    for (const k in _storage) {
      if (key == k) {
        val = _storage[k];
        break;
      }
    }
  }
  /*
  let arr = document.cookie.split("; ");
  let len = arr.length;
  let item = null;
  for (var i = 0; i < len; i++) {
    let arrItem = arr[i].split("=");
    if (arrItem[0] == socketCert.value) {
      item = arrItem[1];
      break;
    }
  }

  if (item) {
    item = decrypt(item);
  }
  try {
    item = JSON.parse(item)
  } catch (error) {

  }
  let val = null;
  if (key == socketCert.value) {
    val = item;
  } else {
    for (const k in item) {
      if (key == k) {
        val = item[k]
        break;
      }
    }
  }
  */
  return val;
};

//删除缓存
const deleteCookie = (key) => {
  let _storage = localStorage.getItem(socketCert.value);
  if (_storage) {
    _storage = decrypt(_storage);
  }
  try {
    _storage = JSON.parse(_storage);
  } catch (error) {
  }
  if (key == socketCert.value) {
    localStorage.removeItem(socketCert.value);
  } else {
    for (const k in _storage) {
      if (key == k) {
        delete _storage[k];
        break;
      }
    }
    setCookie(socketCert.value, _storage);
  }
};

//初始化socket
const initSocket = async () => {

  // onOpen.on(() => {
  //   closeToast();
  // });
  let runParams = {
    schema: "wss",
    host: wsHost.value,
    path: "/v1/gateway/h5",
    cert: socketCert.value,
    heartbeatInterval: 5000,
    reconnect: 2000,
    requestTimeout: 10000,
    uploadHost: uploadHost.value,
    clientType: isMobileFn()
        ? api.common.ClientType.CLIENT_TYPE_USER_H5
        : api.common.ClientType.CLIENT_TYPE_USER_WEB,
    custom: "111",
    
  };

  if (socketToken.value) {
    runParams.token = socketToken.value;
  }
  if(sign.value) {
    runParams.userID = userID.value;
    runParams.timestamp = timestamp.value;
    runParams.sign = sign.value;
  }

  let cli = new Client(runParams);
  wsObj.value = cli;
  cli.ev.onStart.on((data) => {
    clientId.value = data.id.toString();
    
    // 对于用户(H5/web), 还将会收到以下信息
    // 匿名用户会收到最新的token, 需要缓存
    // 如果之前已经有进行过匹配客服, 将会返回对应的 workerId(部分API查询可能需要传递对应的workerId)
    if(!sign.value) {
        setCookie("sign", data.token.toString());
    }else{
        setCookie("token", data.token.toString());
    }
    

    queryHistoryMsg(20, clientId.value);
    getListSetTime.value = setInterval(() => {
      queryHistoryMsgList(50, clientId.value);
    }, 5000);
    authed.value = true;
    if (!data.workerId) {
      //未分配客服
      deleteCookie("entranceInfo");
      deleteCookie("workerInfo");
      initData();
    } else {
      //分配了客服
      queryAutoReply();
      ready.value = true;
    }
  });
  cli.ev.onOpen.on(() => {
  });
  cli.ev.onDisconnect.on(() => {
  });
  cli.ev.onReconnect.on(() => {
  });
  cli.ev.onClose.on((reason) => {
    switch (reason) {
      case CloseReason.SSO:
        return showToast("您的账号已在别处登录");
      case CloseReason.InvalidToken:
        return showToast("token 不正确");
      case CloseReason.GracefulClose:
        return showToast("您的账号已在别处登录");
      case CloseReason.UnknownSignal:
        return showToast("未知信号, 需要和服务端确认");
      case CloseReason.EntranceNotExists:
        return showToast("亲！很抱歉，该商户已搬家。不能为您提供服务！");
    }
  });
  cli.ev.onMessage.on((msg) => {
  });
  cli.ev.onSend.on((msg) => {
  });
  cli.ev.onNewChat.on((msg) => {
    if (msg.autoReply) {
      //自动回复的答案
      let autoReplyQaId = msg.autoReply.qaId;
      for (const key in autoReplyIntv.value) {
        if (key.split(`${autoReplyQaId}_`).length > 1) {
          clearInterval(autoReplyIntv.value[key]);
          break;
        }
      }
    }
    dealNewMsg(msg);
  });
  cli.ev.onUserOnline.on((chatId) => {
  });
  cli.ev.onUserOffline.on((chatId) => {
  });
  cli.ev.onWorkerChanged.on((obj) => {
    // 客服发生改变
    workerInfo.value = {
      nick: obj.workerName,
      avatar: getMediaFullUrl(obj.workerAvatar),
    };
    setCookie("workerInfo", workerInfo.value);
    // 下列原因的 客服变动, 会走自动回复流程:
    // 客服被删除, 导致会话转移到上级
    // case gateway.WorkerChangedReason.WorkerChangedReasonWorkerDeleted:
    // 会话转移
    // case gateway.WorkerChangedReason.WorkerChangedReasonTransferWorker:
    switch (obj.reason) {
        // 发消息时发现客服尚未分配, 或者意外情况找不到客服
      case gateway.WorkerChangedReason.WorkerChangedReasonMissAssignedWorker:
        queryAutoReply();
        break;
    }
  });
};

const dealNewMsg = (msg) => {
  if (!showChat.value) {
    newMsgNum.value = newMsgNum.value ? newMsgNum.value + 1 : 1;
  }
  let type = msgType.value.text;
  let content = "";
  if (msg.content) {
    //文本消息
    type = msgType.value.text;
    content = msg.content.data; //文本消息有内容
    // content = content.replace(/\[emoticon_(.*?)\]/ig, `<div class="emoji emoji-$1"></div>`);
  }
  if (msg.image) {
    //图片消息
    type = msgType.value.pic;
    let imgUrl = msg.image.uri;
    let len = imgUrl.split(hostSign.value).length;
    content = len > 1 ? imgUrl : getMediaFullUrl(imgUrl);
  }
  if (msg.video) {
    //视频消息
    type = msgType.value.video;
    let videoUrl = msg.video.uri;
    let len = videoUrl.split(hostSign.value).length;
    content = len > 1 ? videoUrl : getMediaFullUrl(videoUrl);
  }
  if (msg.file) {
    //文件消息
    type = msgType.value.file;
    let msgFile = msg.file;
    let fileSize = transSize(msgFile.size);
    let fileName = msgFile.fileName;
    fileName = fileName ? fileName : "文件名";
    content = {
      uri: msgFile.uri,
      fileName: fileName,
      size: fileSize,
    };
  }
  // if (msg.autoReply) {  //自动回复-答案
  //   if (msg.image || msg.video) { //图片消息
  //     content = content
  //   }
  // }

  let replyMsgId = null;
  if (msg.replyMsgId) {
    //'回复'消息
    type = msgType.value.reply;
    replyMsgId = longToString(msg.replyMsgId);
  }

  let sender = longToString(msg.sender);
  let time = dealMsgTime(msg.msgTime);
  let msgId = msg.msgId;
  let msgOp = msg.msgOp;
  let isUser = clientId.value != sender ? false : true;
  if (msgOp == api.common.MessageOperate.MSG_OP_EDIT) {
    //编辑消息
    editHistoryMsg(content, msgId);
  } else if (msgOp == api.common.MessageOperate.MSG_OP_DELETE) {
    //删除（撤回）消息
    deleteHistoryMsg(msgId, sender);
  } else {
    if (clientId.value != sender && sender != "123456789" && content != "") {
      //不是自己发的消息 / 临时处理
      pushMsg(
          isUser,
          content,
          type,
          msgState.value.success,
          time,
          msgId,
          replyMsgId
      );
    }
  }
};

const transSize = (num) => {
  if (typeof num != "number") return num;
  let lstVal = num;
  let mbVal = parseInt(1024 * 1024); //1MB
  if (num > 1024 && num < mbVal) {
    //1KB ~ 1MB
    lstVal = (num / 1024).toFixed(2) + " KB";
  } else if (num >= mbVal) {
    // 大于1MB
    lstVal = (num / mbVal).toFixed(2) + " MB";
  } else {
    lstVal = num + " B";
  }
  return lstVal;
};

//获取资源完路径
const getMediaFullUrl = (url) => {
  return `${viewUploadHost.value}${url}`;
};

//long数据转string
const longToString = (longVal) => {
  return new Long(longVal.low, longVal.high, longVal.unsigned).toString();
};

const dealMsgTime = (msgTime) => {
  // return getTime(ts);
  return msgTime.seconds * 1e3 + msgTime.nanos / 1e6;
};

const closeSocket = async () => {
  // await close();
  if (wsObj.value) {
    wsObj.value.close();
  }
  clearInterval(getListSetTime.value);
};

const imgOnError = (e) => {
  let img = e.srcElement;
  img.src = require("@/assets/img/com_moren.svg");
  img.onerror = null;
};

const initData = async () => {
  entranceInfo.value = getCookie("entranceInfo");
  workerInfo.value = getCookie("workerInfo");
  if (entranceInfo.value && !workerInfo.value) {
    consultCd();
  }
  if (!entranceInfo.value) {
    queryEntrance(); //请求入口（获取咨询列表）
  }
};
//时间显示处理
const viewTime = (ts, showYmd = false) => {
  if (typeof ts === "string") {
    return ts;
  }
  let str = "";
  //（时间规则：1.当天（时+分）2.超过24小时（月日时分）；4.超过一年（年月日时分））
  const date = ts == null ? new Date() : new Date(ts);
  const localStr = date.toLocaleString("chinese", {hour12: false}); //返回值 eg：2022/11/10 20:34:16
  const ymd = localStr.replace(localStr.slice(-8), "");
  const hms = localStr.slice(-8);
  const ymdArr = ymd.split("/");
  const year = ymdArr[0];
  const month = ymdArr[1];
  const day = ymdArr[2];
  const hmsArr = hms.split(":");
  const hour = hmsArr[0];
  const min = hmsArr[1];
  const seconds = hmsArr[2];

  const currDate = new Date();
  const currLocalStr = currDate.toLocaleString("chinese", {hour12: false});
  const currYmd = currLocalStr.replace(currLocalStr.slice(-8), "");
  const currHms = currLocalStr.slice(-8);
  const currYmdArr = currYmd.split("/");
  const currYear = currYmdArr[0];
  const currMonth = currYmdArr[1];
  const currDay = currYmdArr[2];
  const currHmsArr = currHms.split(":");
  const currHour = currHmsArr[0];
  const currMin = currHmsArr[1];
  const currSeconds = currHmsArr[2];

  if (showYmd) {
    if (year < currYear) {
      // 超过一年
      str += ymd + "年";
    }
    if (year == currYear) {
      //本年
      if (month < currMonth || (month == currMonth && day < currDay)) {
        str += month + "月" + day + "日";
      }
    }
  }

  str += hour + ":" + min;
  return str;
};
//时间显示处理
const viewTime2 = (ts, item) => {
  if (typeof ts == "string") {
    return ts;
  }
  let date = "";
  if (ts) {
    date = new Date(ts);
  } else {
    date = new Date(item.timeStamp);
  }
  //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM; //月补0
  let d = date.getDate();
  d = d < 10 ? "0" + d : d; //天补0
  let h = date.getHours();
  h = h < 10 ? "0" + h : h; //小时补0
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m; //分钟补0
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s; //秒补0
  return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
};

//获取当前时间戳
const getCurrTs = () => {
  return Math.ceil(new Date().getTime());
};

/**
 * 编辑历史消息
 */
const editHistoryMsg = (content, msgId) => {
  chatList.value.forEach((item) => {
    if (longToString(item.msgId) == longToString(msgId)) {
      item.content = content;
    }
  });

  setCookie("chatList", chatList.value);
};

/**
 * 删除/撤回历史消息
 */
const deleteHistoryMsg = (msgId, sender) => {
  if (clientId.value != sender) {
    //对方撤回
    chatList.value.forEach((item) => {
      if (item.type == msgType.value.reply) {
        if (longToString(item.replyInfo.msgId) == longToString(msgId)) {
          item.replyInfo.content = "内容已撤回";
        }
        if (longToString(item.msgId) == longToString(msgId)) {
          item.type = msgType.value.revoke;
          item.content = "对方撤回了一条消息";
        }
      } else {
        if (longToString(item.msgId) == longToString(msgId)) {
          item.type = msgType.value.revoke;
          item.content = "对方撤回了一条消息";
        }
      }
    });
  }
  setCookie("chatList", chatList.value);
};

//获取'自动回复'问题
const getAutoReplyQuest = (content) => {
  let str = "";
  let question = content.question;
  if (question.content) {
    str = question.content.data;
  } else if (question.image) {
    let imgFullUrl = getMediaFullUrl(question.image.uri);
    str = `<img src="${imgFullUrl}" style="width: auto;height: auto;max-width: 268px;max-height: 228px;" />`;
  } else if (question.video) {
    let videoFullUrl = getMediaFullUrl(question.video.uri);
    str = `<video controls="controls" controlsList="nodownload noremoteplayback" src="${videoFullUrl}" style="width: auto;height: auto;max-width: 268px;max-height: 228px;"></video >`;
  } else {
    str = "";
  }
  return str;
};

/**
 * 添加新消息
 * @param {number} type 消息类型，默认为文本
 */
const pushMsg = async (
    isUser,
    content,
    type,
    state = msgState.value.success,
    timeStamp = null,
    msgId = "",
    replyMsgId = null
) => {
  if (type == msgType.value.selectAutoReply) {
    //选中的自动回复-问题
    content = getAutoReplyQuest(content);
  }
  let replyInfo = null;
  if (type == msgType.value.reply) {
    let ret = await wsObj.value.API("/v1/api/message/sync", {
      msgId: replyMsgId,
      count: 1,
      withLastOne: true,
    });
    let err = ret.err;
    if (err.length > 0) return showToast(err);
    let rsp = ret.rsp;
    let replyObj = rsp.list[0];
    let replyCon = "";
    if (replyObj.content) {
      replyCon = viewTextMsg(replyObj.content.data);
    }
    if (replyObj.image) {
      replyCon = "[图片]"; //replyObj.image.uri;
    }
    if (replyObj.video) {
      replyCon = "[视频]"; //replyObj.video.uri;
    }
    if (replyObj.file) {
      replyCon = "[文件]"; // replyObj.file.uri;
    }
    replyInfo = {
      msgId: replyMsgId,
      content: replyCon,
    };
  }
  if (content == "") return;
  let chatItem = {
    isUser: isUser,
    content: content,
    type: type,
    state: state,
    timeStamp: timeStamp ? timeStamp : getCurrTs(),
    msgId: msgId,
  };
  if (type == msgType.value.reply) {
    chatItem.replyInfo = replyInfo;
  }
  addChatItem(chatItem);
};

const addChatItem = async (obj) => {
  let chatListLen = chatList.value.length;
  let lastItem = null;
  if (chatListLen >= 1) {
    lastItem = chatList.value[chatListLen - 1];
  }
  if (lastItem) {
    // let fiveMins =1000 * 60 * 5;
    // let exceedFiveMins = Math.ceil(lastItem.timeStamp + fiveMins);
    // if (obj.timeStamp > exceedFiveMins) {
    //   addSysMsg();
    // }
    let oneDay = 1000 * 60 * 60 * 24;
    let exceedOneDay = Math.ceil(lastItem.timeStamp + oneDay);
    if (obj.timeStamp > exceedOneDay) {
      addSysMsg();
    }
  }
  if (obj.type == msgType.value.pic || obj.type == msgType.value.video) {
    let objCon = obj.content;
    let len = objCon.split(hostSign.value).length;
    if (len == 1) {
      obj.content = getMediaFullUrl(obj.content);
    }
  }
  if (obj.type == msgType.value.file) {
    let objCon = obj.content;
    let objConUri = objCon.uri;
    let len = objConUri.split(hostSign.value).length;
    if (len == 1) {
      obj.content.uri = getMediaFullUrl(objCon.uri);
    }
    obj.content.size = transSize(objCon.size);
  }
  chatList.value.push(obj);
  setCookie("chatList", chatList.value);
  scrollToBtm();
};

const scrollToBtm = () => {
  nextTick(() => {
    if (chatListRef.value) {
      chatListRef.value.scrollTop = chatListRef.value.scrollHeight;
    }
  });
};

//添加系统消息
const addSysMsg = () => {
  // pushMsg(false, getCurrTs(), msgType.value.sys);

  let chatItem = {
    isUser: false,
    content: getCurrTs(),
    type: msgType.value.sys,
    state: msgState.value.success,
    timeStamp: getCurrTs(),
    msgId: "",
  };
  chatList.value.push(chatItem);
  setCookie("chatList", chatList.value);
  scrollToBtm();
};

//获取咨询列表
const queryEntrance = async () => {
  addSysMsg();
  let ret = await wsObj.value.API("/v1/api/query-entrance");
  console.log(ret)
  let err = ret.err;
  if (err.length > 0) {
    reqTimes.value++;
    if (reqTimes.value <= maxReqTimes.value) {
      setTimeout(() => {
        queryEntrance();
      }, 3000);
    }
    return showToast(err);
  }
  let rsp = ret.rsp;
  entranceInfo.value = rsp;
  setCookie("entranceInfo", rsp);
  if (rsp.consults.length > 1) {
    await pushMsg(false, rsp, msgType.value.consultList);
  }
  await nextTick(() => {
    consultCd();
  });
};

//选咨询类型倒计时
const consultCd = () => {
  let entrance = entranceInfo.value;
  let consults = entrance.consults;
  let consultLen = consults.length;
  if (consultLen === 1) {
    //咨询类型只只设置了一个,直接分配客服
    assignWorker(consults[0].consultId);
  }
  if (consultLen > 1) {
    //用户是否在设置时间内选择咨询类型
    let seconds = entranceInfo.value.changeDefaultTime * 60;
    // let seconds = entranceInfo.value.changeDefaultTime;
    if (consultTimer.value) {
      clearInterval(consultTimer.value);
    }
    consultTimer.value = setInterval(() => {
      if (seconds <= 0) {
        clearInterval(consultTimer.value);
        assignWorker(entrance.defaultConsultId);
      }
      seconds--;
    }, 1000);
  }
};

//选择咨询类型
const handleConsult = async (item) => {
  clearInterval(consultTimer.value);
  pushMsg(true, item, msgType.value.selectConsult);
  if (!workerInfo.value) {
    //用户选择了咨询类型,之后都是和该咨询类型分配的客服聊天
    await assignWorker(item.consultId);
  }
};

// 分配（客服）
const assignWorker = async (consultId) => {
  let ret = await wsObj.value.API("/v1/api/assign-worker", {
    consultId: consultId,
  });
  let err = ret.err;
  if (err.length > 0) {
    reqTimes.value++;
    if (reqTimes.value <= maxReqTimes.value) {
      setTimeout(() => {
        assignWorker();
      }, 3000);
    }
    return showToast(err);
  }
  let rsp = ret.rsp;
  workerInfo.value = rsp;
  if (rsp && rsp.avatar) {
    workerInfo.value.avatar = getMediaFullUrl(rsp.avatar);
  }
  setCookie("workerInfo", rsp);
  await pushMsg(false, rsp, msgType.value.assignWorker);
  ready.value = true;
  await queryAutoReply();
};

//获取自动回复列表
const queryAutoReply = async () => {
  let ret = await wsObj.value.API("/v1/api/query-auto-reply");
  let err = ret.err;
  if (err && err.length > 0) {
    reqTimes.value++;
    if (reqTimes.value <= maxReqTimes.value) {
      setTimeout(() => {
        queryAutoReply();
      }, 3000);
    }
    return showToast(err);
  }
  let rsp = ret.rsp;
  let autoReplyItem = rsp.autoReplyItem;
  autoReplyList.value = autoReplyItem;
  if (autoReplyItem) {
    let qa = autoReplyItem.qa;
    qa.sort((a, b) => {
      return a.id > b.id ? 1 : -1;
    });
    await pushMsg(false, autoReplyItem, msgType.value.autoReplyList);
  }
};

const autoReplyIntv = ref({}); //自动回复的定时器

//选择"自动回复"问题
let someArr = JSON.parse(window.sessionStorage.getItem("someArr")) || [];
const handleAutoReply = async (item, delaySec) => {
  someArr.push(item.id);
  someArr = [...new Set(someArr)];
  window.sessionStorage.setItem("someArr", JSON.stringify(someArr));
  let temporaryTs = getCurrTs();
  item.temporaryTs = temporaryTs;
  await sendMessage(
      msgType.value.selectAutoReply,
      getAutoReplyQuest(item),
      null,
      item
  );
  let seconds = delaySec;
  let qaId = item.id;
  let msgKey = `${qaId}_${temporaryTs}`;
  if (seconds > 0) {
    autoReplyIntv.value[msgKey] = setInterval(() => {
      if (seconds <= 0) {
        if (autoReplyMsgId.value[msgKey]) {
          getAutoReplyAnswer(autoReplyMsgId.value[msgKey]);
        }
        clearInterval(autoReplyIntv.value[msgKey]);
        delete autoReplyMsgId.value[msgKey];
      }
      seconds--;
    }, 1000);
  } else {
    await getAutoReplyAnswer(autoReplyMsgId.value[msgKey]);
    delete autoReplyMsgId.value[msgKey];
  }
};

//获取自动回复答案
const getAutoReplyAnswer = async (msgId) => {
  if (!msgId) {
    return;
  }
  let ret = await wsObj.value.API("/v1/api/reply", {
    msgId: msgId,
  });
  let err = ret.err;
  if (err.length > 0) return showToast(err);
};

//图片消息加载
const loadPicMsg = () => {
  scrollToBtm();
};

const loadVideoMsg = () => {
  scrollToBtm();
};

//查看图片/视频消息
const openFullFile = (e, content, type) => {
  if (sendCon.value) {
    return;
  }
  let clickDom = e.target;
  if (!clickDom) return;
  let cn = clickDom.getAttribute("class");
  if (cn == "icon-download") {
    let fn = null;
    let uri = content;
    if (type == msgType.value.file) {
      uri = content.uri;
      fn = content.fileName;
    }
    return downloadFile(uri, fn);
  }
  if (
      cn != "pic-msg" &&
      cn != "video-msg" &&
      cn != "icon-play" &&
      cn != "video-box"
  )
    return;

  closeShortcuts();
  if (type != msgType.value.pic && type != msgType.value.video) return;
  let style = "";
  if (isMobile.value) {
    style = "width: 100%; height: auto;";
  } else {
    style = "width: 330px; height: auto;";
  }
  fullFile.value = {
    uri: content,
    show: true,
    style: style,
    type: type,
  };
};

//关闭 查看图片/视频消息
const closeFullFile = () => {
  fullFile.value = {
    uri: "",
    show: false,
    style: "",
    type: "",
  };
};

//下载图片/视频/文件
const downloadFile = (uri, fileName = null) => {
  var form = document.createElement("form");
  form.action = uri;
  document.getElementsByTagName("body")[0].appendChild(form);
  form.submit();
};

//滚动消息
const scrollMsg = () => {
  // closeShortcuts();
  // if (chatListRef.value) {
  //   showLoadMore.value = false;
  //   if (chatListRef.value.scrollTop == 0) {
  //     showLoadMore.value = true;
  //   }
  // }
};

//加载更多
const loadMore = () => {
  let chatItem = chatList.value[3];
  let msgId = longToString(chatItem.msgId);

  // let msgs = queryHistoryMsg(msgId, 10);
};

//查询历史消息
const queryHistoryMsg = async (count = 1, chatId) => {
  let ret = await wsObj.value.API("/v1/api/message/sync", {
    chatId: chatId,
    count: 50,
  });
  ret.rsp.list = ret.rsp.list.map((msg) => {
    msg.type = msgType.value.text;
    if (msg.content) {
      //文本消息
      msg.type = msgType.value.text;
      msg.content = msg.content.data; //文本消息有内容
    }
    if (msg.image) {
      //图片消息
      msg.type = msgType.value.pic;
      let imgUrl = msg.image.uri;
      let len = imgUrl.split(hostSign.value).length;
      msg.content = len > 1 ? imgUrl : getMediaFullUrl(imgUrl);
    }
    if (msg.video) {
      //视频消息
      msg.type = msgType.value.video;
      let videoUrl = msg.video.uri;
      let len = videoUrl.split(hostSign.value).length;
      msg.content = len > 1 ? videoUrl : getMediaFullUrl(videoUrl);
    }
    if (msg.file) {
      //文件消息
      msg.type = msgType.value.file;
      let msgFile = msg.file;
      let fileSize = transSize(msgFile.size);
      let fileName = msgFile.fileName;
      fileName = fileName ? fileName : "文件名";
      msg.content = {
        uri: msgFile.uri,
        fileName: fileName,
        size: fileSize,
      };
    }

    if (msg.replyMsgId) {
      //'回复'消息
      msg.type = msgType.value.reply;
      msg.replyMsgId = longToString(msg.replyMsgId);
      msg.replyInfo = {};
      getQuerySpec(chatId, msg.replyMsgId);
    }

    msg.sender = longToString(msg.sender);
    msg.time = dealMsgTime(msg.msgTime);
    msg.isUser = clientId.value != msg.sender ? false : true;

    if (msg.msgOp == api.common.MessageOperate.MSG_OP_EDIT) {
      //编辑消息
      msg.content = content;
    }

    if (msg.msgOp == api.common.MessageOperate.MSG_OP_DELETE) {
      if (msg.isUser) {
        msg.replyInfo.content = "内容已撤回";
      } else {
        msg.content = "对方撤回了一条消息";
      }
      msg.type = msgType.value.revoke;
    }
    return msg;
  });
  ret.rsp.list.sort((a, b) => a.time - b.time);
  chatList.value = ret.rsp.list.concat(chatList.value);
  scrollToBtm();
  return ret;
};

//查询定时循环查历史消息
const queryHistoryMsgList = async (count = 1, chatId) => {
  let ret = await wsObj.value.API("/v1/api/message/sync", {
    chatId: chatId,
    count: 50,
  });
  if (!ret.rsp) {
    return;
  }
  ret.rsp.list = ret.rsp.list.map((msg) => {
    msg.type = msgType.value.text;
    if (msg.content) {
      //文本消息
      msg.type = msgType.value.text;
      msg.content = msg.content.data; //文本消息有内容
    }
    if (msg.image) {
      //图片消息
      msg.type = msgType.value.pic;
      let imgUrl = msg.image.uri;
      let len = imgUrl.split(hostSign.value).length;
      msg.content = len > 1 ? imgUrl : getMediaFullUrl(imgUrl);
    }
    if (msg.video) {
      //视频消息
      msg.type = msgType.value.video;
      let videoUrl = msg.video.uri;
      let len = videoUrl.split(hostSign.value).length;
      msg.content = len > 1 ? videoUrl : getMediaFullUrl(videoUrl);
    }
    if (msg.file) {
      //文件消息
      msg.type = msgType.value.file;
      let msgFile = msg.file;
      let fileSize = transSize(msgFile.size);
      let fileName = msgFile.fileName;
      fileName = fileName ? fileName : "文件名";
      msg.content = {
        uri: msgFile.uri,
        fileName: fileName,
        size: fileSize,
      };
    }

    if (msg.replyMsgId) {
      //'回复'消息
      msg.type = msgType.value.reply;
      msg.replyMsgId = longToString(msg.replyMsgId);
      msg.replyInfo = {};
      getQuerySpec(chatId, msg.replyMsgId);
    }

    msg.sender = longToString(msg.sender);
    msg.time = dealMsgTime(msg.msgTime);
    msg.timeStamp = dealMsgTime(msg.msgTime);
    msg.isUser = clientId.value != msg.sender ? false : true;

    if (msg.msgOp == api.common.MessageOperate.MSG_OP_EDIT) {
      //编辑消息
      msg.content = content;
    }

    if (msg.msgOp == api.common.MessageOperate.MSG_OP_DELETE) {
      if (msg.isUser) {
        msg.replyInfo.content = "内容已撤回";
      } else {
        msg.content = "对方撤回了一条消息";
      }
      msg.type = msgType.value.revoke;
    }
    return msg;
  });
  let newList = ret.rsp.list.concat(chatList.value);
  let autoreply = newList.filter((item) => item.msgId === "");
  let listhistorys = newList.filter((item) => item.msgId !== "");

  let obj = {};
  listhistorys = listhistorys.reduce((cur, next) => {
    obj[next.msgId] ? "" : (obj[next.msgId] = true && cur.push(next));
    return cur;
  }, []);
  let resultData = listhistorys.concat(autoreply);
  resultData.sort((a, b) => a.timeStamp - b.timeStamp);
  chatList.value = resultData;
  return ret;
};

const getQuerySpec = async (chatId, msgId) => {
  let {rsp} = await wsObj.value.API("/v1/tenant/message/query-spec", {
    chatId: chatId,
    msgId: msgId,
  });
  let replyInfoCon = "";
  if (rsp && rsp.msg) {
    if (rsp.msg.content) {
      replyInfoCon = viewTextMsg(rsp.msg.content.data);
    }
    if (rsp.msg.image) {
      replyInfoCon = "[图片]";
    }
    if (rsp.msg.video) {
      replyInfoCon = "[视频]";
    }
    if (rsp.msg.file) {
      replyInfoCon = "[文件]";
    }
  }
  chatList.value.map((res) => {
    if (res.replyMsgId === msgId) {
      res.replyInfo = {
        content: replyInfoCon,
      };
    }
    return res;
  });
};

// 选择表情
const selectEmoji = (emojiIndex) => {
  let oText = inputRef.value;

  let imgPath = require(`@/assets/emoji/emoticon_${emojiIndex}.png`);
  let img = document.createElement("img");
  img.setAttribute("src", imgPath);
  img.setAttribute("class", "emoji");
  let emojiStr = `[emoticon_${emojiIndex}]`;
  let emojiStrLen = emojiStr.length;
  img.setAttribute("emoji", emojiStr);
  oText.appendChild(img);
  if (parseInt(inputLen.value + emojiStrLen) > maxInputLen.value) {
    disableInput();
  } else {
    sendCon.value = inputRef.value.innerHTML;
    let inputRealStr = calcEmojiLen(sendCon.value); //emojiTransStr(sendCon.value);
    inputLen.value = inputRealStr.length;
  }

  if (isAndroid.value && isMobile.value) {
    setCursorLocation();
  }

  if (!isAndroid.value && isMobile.value) {
    inputRef.value.blur();
  }
};

const setCursorLocation = () => {
  let el = inputRef.value;
  if (
      typeof window.getSelection !== "undefined" &&
      typeof document.createRange !== "undefined"
  ) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== "undefined") {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
};

//"消息发送框"输入
const handleEditInput = (ev) => {
  let _html = inputRef.value.innerHTML;
  sendCon.value = dealTextCon(_html, false);

  let inputData = "";
  if (ev.inputType == "deleteContentBackward") {
    //回退
    let inputRealStr = calcEmojiLen(sendCon.value); //emojiTransStr(sendCon.value);
    inputLen.value = inputRealStr.length;
  } else {
    inputData = ev.data;
    if (inputData) {
      inputLen.value = calcEmojiLen(sendCon.value).length;
    }
  }
  if (inputLen.value >= maxInputLen.value) {
    disableInput();
  }
};

const disableInput = () => {
  let str = inputRef.value.innerHTML.substring(0, maxInputLen.value);
  sendCon.value = str;
  inputRef.value.innerHTML = str;
  inputLen.value = str.length;
  inputRef.value.blur();
  iosBottom.value = false;
};

//"消息发送框"获取焦点
const handleEditFocus = () => {
  changeChatListH();
  if (isMobile.value && showEmoji.value) {
    closeEmoji();
  }
};

//"消息发送框"失去焦点
const handleEditBlur = () => {
  iosBottom.value = false;
};

const handleEditCopy = (event) => {
  let copyCon = document.getSelection().getRangeAt(0).cloneContents();
  let ts = getCurrTs();
  let pDom = document.createElement("p");
  pDom.setAttribute("id", ts);
  pDom.setAttribute("style", "display:none;");
  pDom.appendChild(copyCon);
  document.body.appendChild(pDom);
  let _html = document.getElementById(ts).innerHTML;
  toClipboard(_html);
  nextTick(() => {
    document.body.removeChild(pDom);
  });

  /*
  let _html = inputRef.value.innerHTML;
  let str = emojiTransStr(_html);
  handleCopy(str);
  */
};

//"消息发送框"粘贴
const handlePaste = (event) => {
  let e = event || window.event;
  // 阻止默认粘贴
  e.preventDefault();

  let text = (e.originalEvent || e).clipboardData.getData("text/plain");
  // document.execCommand("insertText", false, text);

  let oText = inputRef.value;
  oText.innerHTML += text;

  let inputRealStr = calcEmojiLen(oText.innerHTML);
  inputLen.value = inputRealStr.length;

  setCursorLocation();
  changeChatListH();
};

const handleKeyboard = () => {
  inputRef.value.focus();
  closeEmoji();
  if (!isAndroid.value && isMobile.value) {
    setCursorLocation();
    scrollToBtm();
    iosBottom.value = true;
  }
};

//触发表情
const handleEmoji = () => {
  closeShortcuts();
  iosBottom.value = false;
  showEmoji.value = !showEmoji.value;
  if (isMobile.value && showEmoji.value) {
    //手机端打开emoji
    inputRef.value.blur();
    chatListH.value -= mobileEmojiBoxH.value;
    scrollToBtm();
  }
};

//关闭表情框
const closeEmoji = () => {
  if (showEmoji.value) {
    showEmoji.value = false;
    if (isMobile.value) {
      chatListH.value += mobileEmojiBoxH.value;
    }
  }
};

//判断返回后缀图片地址
function isAssetTypeAnImage(ext) {
 
  //获取最后一个.的位置
  var index= ext.lastIndexOf(".");
  return index !=-1

}


function androidFunc(string) {
  alert("安卓调用H5的方法");
  alert(string);
  uploadFile(string);
}

// 判断是否出于套壳页面（这个判断只能用于安卓，IOS不生效）
const openInWebview = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) === "micromessenger") {
    // 微信浏览器判断
    return false;
  } else if (ua.match(/QQ/i) === "qq") {
    // QQ浏览器判断
    return false;
  } else if (ua.match(/WeiBo/i) === "weibo") {
    return false;
  } else {
    if (ua.match(/Android/i) != null) {
      return ua.match(/browser/i) == null;
    } else if (ua.match(/iPhone/i) != null) {
      return ua.match(/safari/i) == null;
    } else {
      return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null;
    }
  }
};
//打开文件选择
const openFile = () => {
  // 安卓端新壹号任务优先，延后联调，放开注释即可
  // var ua = navigator.userAgent.toLowerCase()
  // const isAndroid = (ua.indexOf('Android') > -1  ua.indexOf('android') > -1  ua.indexOf('Adr') > -1) && (ua.indexOf('Linux') > -1 || ua.indexOf('linux') > -1) // android终端 APP
  // alert('isAndroid  ' + isAndroid)
  // const  isOpenByAdAPP = window.android && window.android.isOpenByAdAPP()
  // alert('isOpenByAdAPP ' + isOpenByAdAPP)
  // if (isAndroid && (isOpenByAdAPP && isOpenByAdAPP==1)) {
  //     alert('进入安卓套壳的判断')
  //     window.android.showFile()
  //   } else {
  //     alert('非安卓')
  iosBottom.value = false;
  filElem.value.dispatchEvent(new MouseEvent("click"));
  // }
};

//获取选择的图片
const getFile = (e) => {
  let files = filElem.value.files;
  let filesLen = files.length;
  if (filesLen > 9) return showDialog("你最多只能选择9项");
  for (const key in files) {
    let item = files[key];
    let itemType = item.type;
    let itemSize = item.size;
    if (itemType) {
      let isImg = itemType.split("image/").length > 1;
      if (isImg) {
        if (!itemSize) return showToast("图片大小不能为0KB");
        if (itemSize <= 20 * 1024 * 1024) {
          //图片限制20M
          let compress = new imgZip({quality: 0.5});
          compress.photoCompress(item, function (base64) {
            var binary = atob(base64.split(",")[1]);
            var array = new Uint8Array(binary.length);
            for (var i = 0; i < binary.length; i++) {
              array[i] = binary.charCodeAt(i);
            }
            var blob = new Blob([array], {type: "image/jpeg"});
            let file = new File(
                [blob],
                "file_" + Date.parse(new Date()) + ".jpg",
                {type: blob.type}
            );
            uploadFile(file, file.size);
          });
        } else {
          showToast("图片限制20M");
        }
      } else {
        if (itemSize <= 200 * 1024 * 1024) {
          //视频/文件限制200M
          uploadFile(item, itemSize);
        } else {
          showToast("视频/文件限制200M");
        }
      }
    }
  }
  /*
  let files = filElem.value.files;
  let filesLen = files.length;
  if (filesLen > 9) return showDialog("你最多只能选择9张照片");
  for (const key in files) {
    let item = files[key];
    let itemType = item.type;
    let itemSize = item.size;
    if ((itemType == "image/jpeg" || itemType == "image/jpg" || itemType == "image/png" || itemType == "image/gif")
      && itemSize <= (10 * 1024 * 1024)) {
      uploadFile(item);
    }
  }
  */
};

const uploadFile = async (file, itemSize) => {
  showLoadingToast({
    duration: 0,
    message: "资源上传中...",
    forbidClick: true,
  });
  let path = await wsObj.value.upload(
      file,
      itemSize,
      api.common.AssetKind.ASSET_KIND_SESSION
  );
  closeToast();
  filElem.value.value = "";
  
  if(isAssetTypeAnImage(path) == false){
    showToast("上传失败，请重试！")
    return
  }

  if (path.length > 0) {
    let type = file.type;
    let _msgType = msgType.value.pic;
    let content = path;
    if (type.split("image/").length > 1) {
      _msgType = msgType.value.pic;
      content = path;
    } else if (type.split("video/").length > 1) {
      _msgType = msgType.value.video;
      content = path;
    } else {
      _msgType = msgType.value.file;
      content = {
        fileName: file.name,
        size: file.size,
        uri: path,
      };
    }
    await sendMessage(_msgType, content);
  }
};

//删除发送内容
const handleDelete = () => {
  let childNodes = inputRef.value.childNodes;
  let childNodesLen = childNodes.length;
  if (childNodesLen < 1) return;

  let val = inputRef.value.innerHTML;
  let lastChildNode = childNodes[childNodesLen - 1];
  let nodeType = lastChildNode.nodeType;
  if (nodeType == 3) {
    //文本
    inputRef.value.innerHTML = val.slice(0, val.length - 1);
  } else {
    //其他标签
    inputRef.value.removeChild(lastChildNode);
  }
};

//emoji图片转换为字符
const emojiTransStr = (val) => {
  let str = val.replace(
      /<img src="[^"]+" class="[^"]+" emoji="(.*?)">/gi,
      "$1"
  );
  str = str.replace(
      /<input disabled="disabled" class="[^"]+" emoji="(.*?)">/gi,
      "$1"
  );
  return str;
};

//计算emoji字符长度
const calcEmojiLen = (val) => {
  let str = val.replace(/<img src="[^"]+" class="[^"]+" emoji="(.*?)">/gi, "*");
  str = str.replace(
      /<input disabled="disabled" class="[^"]+" emoji="(.*?)">/gi,
      "*"
  );
  return str;
};

const dealTextCon = (content, isDespace = true) => {
  content = content.replace(/<h1.*?>(.*?)<\/h1>/gi, "\r\n$1");
  content = content.replace(/<h2.*?>(.*?)<\/h2>/gi, "\r\n$1");
  content = content.replace(/<h3.*?>(.*?)<\/h3>/gi, "\r\n$1");
  content = content.replace(/<h4.*?>(.*?)<\/h4>/gi, "\r\n$1");
  content = content.replace(/<h5.*?>(.*?)<\/h5>/gi, "\r\n$1");
  content = content.replace(/<h6.*?>(.*?)<\/h6>/gi, "\r\n$1");
  content = content.replace(/<div.*?>(.*?)<br><\/div>/gi, "$1\r\n");
  content = content.replace(/<div.*?>(.*?)<\/div>/gi, "\r\n$1");
  content = content.replace(/<span.*?>(.*?)<\/span>/gi, "$1");
  content = content.replace(/<a.*?>(.*?)<\/a>/gi, "$1");
  content = content.replace(/<br.*?>/gi, "\r\n");
  content = content.replace(/&nbsp;/gi, "");
  if (isDespace) {
    content = content.replace(/\s/g, "");
  }
  return content;
};

//显示文本消息处理（可能包含emoji字符）
const viewTextMsg = (str) => {
  if (str != undefined && typeof str === "string") {
    str = str.replace(
        /\[emoticon_(.*?)\]/gi,
        `<div class="emoji emoji-$1"></div>`
    );
    return str;
  } else {
    return "";
  }
};

//触发"发送"
const handleSend = async () => {
  //字符长度限制 500

  let inputHtml = inputRef.value.innerHTML;
  let str = calcEmojiLen(inputHtml); //emojiTransStr(inputHtml);
  if (!dealTextCon(str)) return;
  if (inputLen.value > maxInputLen.value)
    return showToast("发送内容长度不能超过500");
  str = emojiTransStr(inputHtml);
  str = dealTextCon(str, false);

  if (showReply.value) {
    sendMessage(msgType.value.reply, str, replyInfo.value.msgId);
  } else {
    sendMessage(msgType.value.text, str);
  }
};

//重新发送（消息发送失败时）
const reSend = (item) => {
  sendMessage(item.type, item.content, item.msgId);
};

/**
 * 消息发送
 * @param {number} type 消息类型，默认为文本
 * @param {string} content 消息内容
 * @param {string} msgId
 */
const sendMessage = async (
    type = msgType.value.text,
    content,
    messageId = null,
    item
) => {
  closeShortcuts();

  // 连接并鉴权成功
  if (authed.value) {
    // 加保险: 避免ready被写错, 或者分配流程走不通, 导致一直无法发送消息
    if (!ready.value && readyCheckCounter.value === 0) {
      readyCheckCounter.value++;
      // showToast('请稍后')
      // localStorage.clear();
      // setTimeout(()=> {
      //   window.location.reload();
      // }, 1000)
      return;
    }
  }

  // if ((!workerInfo.value) && entranceInfo.value && entranceInfo.value.consults.length > 1)
  //   return showToast('请先选择咨询项目，将为您安排相应的客服。');

  if (content == undefined || content == "") return;

  let timeStamp = getCurrTs();
  let msgObj = {
    isUser: true,
    msgId: "",
    content: content,
    type: type,
    timeStamp: timeStamp,
    state: msgState.value.sending,
  };

  setTimeout(() => {
    const index = chatList.value.findIndex(
        (item) => item.timeStamp == timeStamp
    );
    if (index < 0) {
      return;
    }

    const msg = chatList.value[index];
    if (msg.state !== msgState.value.sending) {
      return;
    }

    msg.state = msgState.value.sendingSlow;
  }, 250);

  let msgCon = {};

  switch (type) {
    case msgType.value.revoke: //撤回消息
      msgCon.msgOp = api.common.MessageOperate.MSG_OP_DELETE;
      msgCon.msgId = messageId;
      break;
    case msgType.value.reply: //回复消息
      msgObj.replyInfo = {
        msgId: messageId,
        content: replyInfo.value.content,
      };
      msgCon.content = {data: content};
      msgCon.replyMsgId = messageId;
      break;
    case msgType.value.pic:
      msgCon = {image: {uri: content}};
      break;
    case msgType.value.video:
      msgCon = {video: {uri: content}};
      break;
    case msgType.value.file:
      msgCon = {
        file: {
          fileName: content.fileName,
          size: content.size,
          uri: content.uri,
        },
      };
      break;
    case msgType.value.selectAutoReply: //选择自动回复问题
      msgCon = {
        content: {data: content},
        autoReply: {
          id: autoReplyList.value.id, //自动回复列表最外层的id
          qaId: item.id, //单条回复的id
        },
      };
      break;
    default:
      msgCon = {content: {data: content}};
      break;
  }
  if (type != msgType.value.revoke) {
    await addChatItem(msgObj);
    // chatList.value.push(msgObj);
    // scrollToBtm();
  }

  resetSend();

  if (messageId) {
  }
  let msg = await wsObj.value.sendChat(msgCon);
  msg = gateway.SCSendMessage.toObject(msg, {defaults: true});
  let msgId = msg.msgId;
  if (msgId.eq(0));
  //增加错误提示
  if (msg.errMsg.length > 0) {
    showToast(msg.errMsg);
  }

  if (type == msgType.value.selectAutoReply) {
    autoReplyMsgId.value[`${msgCon.autoReply.qaId}_${item.temporaryTs}`] =
        msgId;
  }

  let index = -1;
  if (type == msgType.value.revoke) {
    index = chatList.value.findIndex((item) => item.msgId.eq(msgId));
    if (index !== -1) {
      if (msgId === 0) {
        showToast("消息撤回失败");
      } else {
        // chatList.value[index].msgId = msgId;
        chatList.value[index].type = type;
        chatList.value[index].state = msgState.value.success;
        chatList.value[index].content = content;
      }
    }
  } else {
    index = chatList.value.findIndex((item) => item.timeStamp == timeStamp);
    // index = chatList.value.length - 1;
    if (index !== -1) {
      if (msgId.eq(0)) {
        chatList.value[index].state = msgState.value.fail;
      } else {
        chatList.value[index].msgId = msgId;
        chatList.value[index].state = msgState.value.success;
        chatList.value[index].timeStamp = dealMsgTime(msg.msgTime);
      }
    }
  }
  setCookie("chatList", chatList.value);

  return msgId;
};

const resetSend = () => {
  closeEmoji();
  closeReply();
  nextTick(() => {
    inputRef.value.innerHTML = "";
    sendCon.value = "";
    inputLen.value = 0;
  });
};

//消息右键菜单
const handleMsgRm = (e, item) => {
  if (isMobile.value) return;
  msgItem.value = item;
  let touchDom = e.target;
  let clientRect = touchDom.getBoundingClientRect();
  // alert(clientRect.left + "," + clientRect.top);
  openShortcuts(clientRect.left, clientRect.top);
};

//长按消息-开始
const touchstart = (e) => {
  if (!isMobile.value) return;
  clearTimeout(loop.value); // 再次清空定时器，防止重复注册定时器（会把点击事件也阻止掉）
  longTouch.value = false; // 关键
  loop.value = setTimeout(() => {
    longTouch.value = true; // 关键
    let touchDom = e.target;
    let clientRect = touchDom.getBoundingClientRect();
    touchstartPosition.value = {
      x: clientRect.left,
      y: clientRect.top,
    };
  }, 600);
};

//长按消息-结束
const touchend = (e, item) => {
  if (!isMobile.value) return;
  clearTimeout(loop.value); // 清空定时器，防止重复注册定时器

  if (!longTouch.value) {
    openFullFile(e, item.content, item.type);
    return;
  }

  handleShortcuts();
  msgItem.value = item;
};

//长按消息-快捷操作
const handleShortcuts = () => {
  if (!isMobile.value) return;
  let clientX = touchstartPosition.value.x;
  let clientY = touchstartPosition.value.y;
  openShortcuts(clientX, clientY);
};

//获取快捷菜单的坐标
const getShortcutsPosition = (clientX, clientY) => {
  let screenW = window.innerWidth;
  let diffW = screenW - clientX;
  let screenH = window.innerHeight;
  let diffY = screenH - clientY;
  let boxW = 180;
  let boxH = 64;
  shortcutsPosition.value = {
    x: diffW > boxW ? clientX : screenW - boxW,
    y: diffY > boxH ? clientY - boxH - 8 : screenH - boxH,
  };
};

//打开消息快捷操作
const openShortcuts = (clientX, clientY) => {
  getShortcutsPosition(clientX, clientY);
  nextTick(() => {
    showShortcuts.value = true;
  });
};

//关闭消息快捷操作
const closeShortcuts = () => {
  touchstartPosition.value = {x: 0, y: 0};
  shortcutsPosition.value = {x: 0, y: 0};
  msgItem.value = null;
  showShortcuts.value = false;
};

//消息快捷操作
const chooseShortcuts = (val) => {
  switch (val) {
    case shortcutsVal.value.reply: //回复
      openReply(toRaw(msgItem.value));
      break;
    case shortcutsVal.value.copy: //复制
      handleCopy(msgItem.value.content);
      showToast("已复制");
      break;
    case shortcutsVal.value.revoke: //撤回(五分钟内可撤回)
      sendMessage(
          msgType.value.revoke,
          "你撤回了一条消息",
          msgItem.value.msgId
      );
      break;
      // case shortcutsVal.value.delete: //删除
      //   //删除本地消息，不影响对方
      //   break;
  }
  closeShortcuts();
};

//复制
const handleCopy = (content) => {
  // let str = content.replace(/\[emoticon_(.*?)\]/ig, `<img src="0" class="emoji emoji-$1 emoji-noborder" emoji="[emoticon_$1]" />`);

  let str = content.replace(/\[emoticon_(.*?)\]/gi, function () {
    let str = arguments[1];
    let imgSrc = getEmojiUrl(str);
    return `<img src="${imgSrc}" class="emoji" emoji="[emoticon_${str}]" />`;
  });

  toClipboard(str);
};

const getEmojiUrl = (emojiIndex) => {
  let url = require(`@/assets/emoji/emoticon_${emojiIndex}.png`);
  return url;
};

//聊天会话高度变化
const changeChatListH = () => {
  let screenH = window.innerHeight;
  let topH = topRef.value.clientHeight + 1;
  let btmH = btmRef.value.clientHeight;

  if (!isMobile.value) {
    screenH = 540;
  }
  if (!isAndroid.value && isMobile.value) {
    screenH = containerHeight.value;
  } //IOS手机端，保持初始的屏幕高度
  let clH = screenH - topH - btmH;

  // let replyBoxH = 22; //回复高度
  // if (showReply.value) { clH -= replyBoxH; }
  chatListH.value = clH;
  scrollToBtm();
};

//打开"回复"
const openReply = (msgItem) => {
  closeReply();

  showReply.value = true;
  replyInfo.value = msgItem;

  let replyInfoCon = replyInfo.value.content;
  if (msgItem.type == msgType.value.text) {
    replyInfoCon = viewTextMsg(replyInfoCon);
  }
  if (msgItem.type == msgType.value.pic) {
    replyInfoCon = "[图片]";
  }
  if (msgItem.type == msgType.value.video) {
    replyInfoCon = "[视频]";
  }
  if (msgItem.type == msgType.value.file) {
    replyInfoCon = "[文件]";
  }

  replyInfo.value.content = replyInfoCon;
  chatListH.value -= 22;

  scrollToBtm();
};

//关闭"回复"
const closeReply = () => {
  if (showReply.value) {
    showReply.value = false;
    replyInfo.value = {};
    chatListH.value += 22;
  }
};

//重新编辑（自己撤回的消息） - 目前版本不允许重新编辑
// const reEdit = (item) => {
// }

//获取当前时间戳
// const getCurrTs = () => {
//   return new Date().getTime()
// }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.container {
  // position: relative;
  position: fixed;
  right: 65px;
  bottom: 0;
  // width: 330px;
  // height: 540px;
  overflow: hidden;
  z-index: 999;
}

.container.mobile-container {
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
}

.entry {
  position: fixed;
  right: 20px;
  bottom: 40px;
  // right: 0;
  // bottom: 0;
  width: 82px;
  height: 80px;
  /* background-color: rgb(24, 128, 255); */
  /* border-radius: 50%; */
  /* box-shadow: rgb(0 0 0 / 16%) 0px 5px 14px; */
  text-align: center;
  cursor: pointer;
}

.icon-msg {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 82px;
  height: 80px;
  background-size: 100% 100%;
  background-image: url(@/assets/img/h5_kfrukou.svg);
}

.new-msg {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  border-radius: 100px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  background: rgb(255, 0, 0);
}

.chat {
  position: relative;
  // position: fixed;
  // right: 65px;
  // bottom: 0px;
  width: 330px;
  // height: 540px;
  height: 100%;
  background: #f7f7f7;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.chat.mobile-chat {
  right: 0;
  top: 0;
  width: 100%;
  // height: 100vh;
  border-radius: 0;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 62px;
  padding: 10px 14px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  z-index: 99;
}

.top .pic {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  // margin-right: 8px;
}

.top .name {
  width: 200px;
  line-height: 42px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon-close {
  width: 42px;
  height: 42px;
  cursor: pointer;
}

.chat-list {
  width: 100%;
  height: 356px;
  padding: 12px 12px 0 12px;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-list-ios {
  z-index: 10;
  position: relative;
}

.chat-item {
  position: relative;
  margin-bottom: 12px;
}

.chat-time {
  font-size: 12px;
  margin-bottom: 4px;
}

.chat-item.user .chat-time {
  text-align: right;
}

.chat-item-nav {
  display: flex;
  align-items: center;
}

.chat-item.user .chat-item-nav {
  flex-direction: row-reverse;
}

.msg-item {
  display: inline-flex;
  align-items: center;
}

.chat-item.user .msg-item {
  flex-direction: row-reverse;
}

.chat-item .chat-con,
.ques-box {
  max-width: 268px;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 8px;
  background: #fff;
  border-radius: 5px;
  word-break: break-all;
}

.chat-item .chat-con {
  white-space: pre-line;
}

.chat-item.user .chat-con {
  background: $bgMainColor;
  color: $textMainColor;
}

.pic-msg,
.video-msg,
.video-msg > video {
  width: auto;
  height: auto;
  max-width: 268px;
  max-height: 228px;
  cursor: pointer;
}

.video-msg {
  position: relative;
}

.video-msg > video {
  max-width: 228px;
  background-color: #000;
}

.video-msg > .icon-play {
  position: absolute;
  left: calc(50% - 27px);
  top: calc(50% - 27px);
  width: 54px;
  height: 54px;
}

.file-msg {
  display: inline-flex;
  justify-content: space-between;
  width: 218px;
  padding: 13px;
  background-color: #fff;
}

.file-msg-left {
  flex: 1;
  font-size: 12px;

  :nth-child(1) {
    display: flex;
    width: 150px;
    height: 15px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    color: #000;
    margin-bottom: 10px;

    > span {
      height: 15px;
      color: #000;
    }

    > span:nth-child(1) {
      display: inline-block;
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  :nth-child(2) {
    color: #b2b2b2;
  }
}

.file-msg-right {
  width: 40px;

  > img {
    width: 40px;
    height: 40px;
  }
}

.icon-download {
  width: 16px;
  height: 16px;
  margin-left: 9px;
  margin-right: 9px;
  cursor: pointer;
}

.ques-box {
  padding: 0 8px;
}

.ques-title {
  padding: 10px 0;
  line-height: 18px;
  font-size: 14px;
  color: $textMainColor;
}

.ques-list li {
  width: 100%;
  line-height: 18px;
  list-style: none;
  color: #1e6fff;
  padding: 10px 0;
  font-size: 14px;
  border-top: 1px solid #f5f5f5;
  cursor: pointer;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
}

.sys-msg,
.revoke-msg {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-bottom: 15px;
}

.sys-msg.text-blue,
.revoke-msg .text-blue {
  color: #4aa7ff;
  margin-left: 4px;
  cursor: pointer;
}

.icon-warn,
.icon-load {
  width: 16px;
  height: 16px;
  margin-left: 6px;
}

.icon-warn {
  cursor: pointer;
}

.user .icon-warn,
.user .icon-load {
  margin-right: 6px;
  margin-left: 0;
}

.error {
  width: 100%;
  height: 100%;
}

.desc {
  padding-top: 50%;
  font-size: 12px;
  text-align: center;
}

.desc > span {
  line-height: 40px;
}

.desc > img {
  width: 40px;
  height: 40px;
}

.desc > div {
  margin-top: 10px;
  color: #000;
  font-size: 12px;
}

.btn-reconnect {
  height: 20px;
  line-height: 20px;
  padding: 0 4px;
  color: #000;
  font-size: 12px;
  background-color: #fff;
  margin-left: 5px;
  border: 1px solid #ddd;
}

.btm {
  // position: fixed;
  // position: relative;
  // bottom: 0px;
  position: relative;
  width: 100%;
  min-height: 122px;
  max-height: 160px;
  // padding: 15px;
  border-top: 1px solid #e8e8e8;
  background: #f5f5f5;
  z-index: 99;
}

.btm .edit {
  width: calc(100% - 30px);
  height: auto;
  min-height: 40px;
  max-height: 78px;
  line-height: 28px;
  font-size: 14px;
  padding: 6px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  resize: none;
  word-break: break-all;
  margin: 15px;
}

.edit:empty::before {
  font-size: 13px;
  content: attr(placeholder);
  color: #bbb;
}

.edit:focus::before {
  content: none;
}

.action {
  display: flex;
  padding: 0 15px 16px 15px;
}

.action-list {
  flex: 1;
}

.action-list > img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 8px;
}

.action > span {
  line-height: 35px;
  font-size: 12px;
  color: #999;
  margin: 0 14px;
}

.action > button {
  width: 56px;
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  font-size: 14px;
  background: $bgMainColor;
  color: #000;
}

.icon-back {
  width: 22px;
  height: 22px;
}

.mobile-chat .top {
  padding: 10px 12px;
}

.mobile-chat .top .pic {
  margin-left: 11px;
}

.mobile-chat .top .name {
  width: auto;
  flex: 1;
  margin-left: 8px;
}

.mobile-chat .chat-list {
  padding: 15px 15px 0 15px;
}

.mobile-chat .chat-list.shorten {
  height: calc(100vh - 62px - 48px - 22px);
}

.mobile-chat .chat-item {
  margin-bottom: 15px;
}

.mobile-chat .chat-item .chat-con,
.mobile-chat .ques-box {
  border-radius: 10px;
}

.mobile-chat .pic-msg {
  max-width: 304px;
  max-height: 304px;
}

.btm.mobile-btm {
  padding: 0;
  height: auto;
  min-height: 48px;
  max-height: 248px;
  border-top: none;
}

.btm.mobile-btm .send-box {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 6px 12px;
}

.btm.mobile-btm .edit {
  flex: 1;
  line-height: 20px;
  min-height: 32px;
  max-height: 108px;
  padding: 6px 10px;
  border-radius: 15px;
  margin: 0;
}

.mobile-action {
  display: inline-flex;
  align-items: center;
}

.mobile-action > img {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.mobile-action .btn-send {
  width: 44px;
  height: 26px;
  background: $bgMainColor;
  border-radius: 100px;
  color: #2e2000;
  font-size: 12px;
  margin-left: 12px;
}

.shortcuts {
  position: fixed;
  display: flex;
  // min-width: 364px;
  height: 64px;
  background: #333;
  // box-shadow: 0px 0px 8px 0px rgb(63 63 67 / 30%);
  border-radius: 8px;
  z-index: 99;
}

.shortcuts::before {
  content: "";
  position: absolute;
  bottom: -7px;
  left: calc(50% - 3px);
  display: inline-block;
  border-top: 7px solid #333;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.shortcuts .shortcuts-item {
  width: 60px;
  height: 64px;
  padding: 13px 15px 15px 15px;
  text-align: center;
  cursor: pointer;
}

.shortcuts-item > div {
  font-size: 12px;
  vertical-align: middle;
  color: #fff;
}

.shortcuts-item > img {
  width: 16px;
  height: 16px;
  margin-bottom: 5px;
  vertical-align: middle;
}

.reply-box {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #000;
  padding: 0 12px;
  background: #ededed;
}

.reply-box label {
  color: #1b7ad3;
}

.reply-box > span,
.reply-box > div {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reply-box > img {
  cursor: pointer;
}

.reply-msg {
  color: #2e2000;
  margin-top: 4px;
}

.chat-item .reply-msg {
  display: flex;
}

.chat-item.user .reply-msg {
  justify-content: right;
}

.reply-msg > div {
  // flex: 1;
  max-width: 304px;
  line-height: 18px;
  padding: 10px 8px;
  font-size: 14px;
  border-radius: 10px;
  background: #ededed;
  word-break: break-all;
}

.emoji-box,
.mobile-emoji-box {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  padding: 3px;
  overflow-x: hidden;
  overflow-y: auto;
}

.emoji-box {
  position: absolute;
  top: -150px;
  left: 0;
  // height: 150px;
}

.mobile-emoji-box {
  // height: 200px;
  background-color: #f5f5f5;
}

.emoji-box .emoji,
.mobile-emoji-box .emoji {
  width: 30px;
  height: 30px;
  margin: 3px 4px;
}

.mobile-emoji-box .emoji {
  width: 32px;
  height: 32px;
  margin: 9px;
}

.emoji-nav {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.emoji-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.emoji-action {
  position: absolute;
  right: 0;
  bottom: 10px;
  display: flex;
}

.emoji-action > div {
  width: 44px;
  height: 36px;
  line-height: 36px;
  border-radius: 8px;
  text-align: center;
  margin-right: 10px;
}

.emoji-action .btn-delete {
  background-color: #fff;
}

.btn-delete > img {
  width: 54px;
  height: 46px;
  margin-top: -4px;
  margin-left: -4px;
}

.emoji-action .btn-send {
  background-color: $bgMainColor;
  color: #2e2000;
  font-size: 12px;
}

.overlay-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* @media screen and (max-width:960px) {
  .chat {
    width: 100%;
    right: 0px;
  }
} */
.iosset {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 200;
}

.btn-send-disabled {
  background-color: #b6b6b6;
  color: #747474;
}

.ques-list li.someArrClass {
  color: #c9ccd1;
}
</style>
