import { createApp } from 'vue'
import App from './App.vue'
import { Dialog, Toast, Loading, Overlay } from 'vant'
import './util/formdata.mini.js'
import 'vant/lib/index.css'

const app = createApp(App)

app.use(Dialog)
app.use(Toast)
app.use(Loading)
app.use(Overlay)

import '@/style/emoji.scss'

app.mount('#app')